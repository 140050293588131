import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  ActionData,
  BigidLoader,
  BigidPaper,
  EntityEvents,
  entityEventsEmitter,
  ToolbarActionType,
  BigidLayoutEmptyState,
  BigidFilterOptionValueType,
} from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { HeaderButtons } from './components/HeaderButtons/HeaderButtons';
import { useLocalTranslation } from './translations';
import {
  BigidAddCategoryIcon,
  BigidApplyIcon,
  BigidClearIcon,
  BigidDeleteIcon,
  BigidDisableIcon,
  BigidExternalLinkIcon,
  BigidFiltersIllustration,
  BigidExportIcon,
  BigidDuplicateIcon,
  BigidHelpIcon,
  BigidCheckedViewIcon,
} from '@bigid-ui/icons';
import { BigidGridQueryComponents, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { useUserPreferences } from '../../components/hooks/useUserPrefrences';
import { $state } from '../../services/angularServices';
import {
  getClassifiersInitialGridColumns,
  getInitialClassifiersFilters,
} from './utils/classifiersManagementGridConfiguration';
import {
  CLASSIFIER_TYPES,
  CREATE_CLASSIFIER_DIALOG_EVENTS,
  ClassifierGridRow,
  REGEX_FORM_TYPES,
  FILTER_KEYS,
} from './types/ClassifierTypes';
import {
  fetchClassifiers,
  fetchClassifiersToUpdate,
  exportBulkClassifiers,
  getClassifierById,
} from './services/classifiersManagementService';
import * as classifiersManagementUtils from './utils/utils';
import {
  getIsNerClassifierFFEnabled,
  isClassfierExcludeListFF,
  isRequestSucceeded,
  updateGridItemById,
  getDocAppUrl,
  getIsClassifiersAutoUpgradeDisable,
  reloadGrid,
  isRegex,
  isNer,
  isDoc,
  addGridItem,
  isCorrelation,
  getShouldShowClassifierTuning,
  goToClassifierTuning,
  getIsClassifiersTuningEnabled,
  getValueOrDefault,
  getIsClassifyFileNamesEnabled,
} from './utils/utils';
import { useExcludeList } from './hooks/useExcludeList';
import { getRegexFormSubmitFields, RegexClassifierForm } from './RegexForm/ClassifiersForm';
import { CreateClassifierDialog } from './components/Dialogs/CreateClassifierDialog';
import { ClassifiersSidePanel } from './SidePanel/ClassifiersSidePanel';
import { isEmpty, isEqual, omit, size } from 'lodash';
import {
  handleEnableDisableBulk,
  handleEnableDisableBulkParams,
  fetchChecksumValidations,
  handleDeleteClassifier,
  handleEnableDisable,
  onImportHandler,
  handleDuplicateClassifier,
} from './ClassifiersActions';
import { useFetchData } from '../../hooks/useFetchData';
import { OOTBChecksumValidation } from '../../components/ClassifiersChecksumValidation/ClassifiersChecksumValidationDialog/ChecksumValidationTypes';
import { BigidClassifierTesterDialog } from '../../components/ClassifierTester/ClassifierTesterDialog';
import { useFormActions } from './hooks/useFormActions';
import { NERForm } from './NERForm/NERForm';
import { showAssignCategoryDialog } from './components/AssignCategoryDialog/categoryAssignDialogService';
import { ASSIGN_CATEGORIES_TYPE } from './types/AssignCategories';
import { classifierManagementInitialState, classifierManagementReducer } from './reducers/classifierManagementReducer';
import { notificationService } from '../../services/notificationService';
import { openImportClassifiersDialog } from '../../../administration/classifiers/ImportClassifiersDialog/ImportClassifiersDialog';
import { useTPADetails } from './hooks/useTPADetails';
import { DOC_APP_NAME } from './constants';
import { usePermissions } from './hooks/usePermissions';
import { analyticsService } from '../../services/analyticsService';
import { ClassifiersEventsEnum, EnabledType } from './types/Events';
import { getEnabledDisabledEventName } from './utils/eventsUtils';
import { Classifier } from '../../services/classifiersService';
import { openClassifierUpdatePreviewBulkDialog } from '../../components/ClassifierUpdatePreview/classifierUpdatePreviewService';
import { openSaveChangesDialog } from './components/Dialogs/SaveChanges';
import { useRouteLeavingListener } from '../../components/RouteLeaving/hooks/useRouteLeavingListener';
import { confirmationExportDialog } from './components/Dialogs/ConfirmationExportDialog';
import { ClassifiersInfoNotification } from '../../components/ClassifiersInfoNotification/ClassifiersInfoNotification';
import { openCreateDuplicateClassifierDialog } from '../../../administration/classifiers/BigidDuplicateClassifierDialog/BigidDuplicateClassifierDialog';
import { ClassifiersManagementDialogGuide } from './DialogGuide/ClassifiersManagementDialogGuide';
import {
  BigidPageTitleHelper,
  BigidPageTitleHelperButtonType,
  BigidPageTitleHelperProps,
} from '../../components/BigidHeader/BigidPageTitle/BigidPageTitleHelper';

import { generateDataAid } from '@bigid-ui/utils';
import { docsUrls } from '../../config/publicUrls';
import { openKeepEditingDialog } from './components/Dialogs/KeepEditing';
import { getInitialData } from './RegexForm/initialFormConfig';
import { getInitialFormData } from './NERForm/initialFormConfig';
import { getListOfCountriesForFilter } from '../Scans/ScanTemplates/ScanTemplateWizard/Steps/ClassificationStep/classificationService';

export const GridWrapper = styled('div')`
  width: 100%;
  display: flex;
  position: relative;
  height: calc(100% - 24px);
  max-height: calc(100% - 24px);
  overflow: hidden;
`;

const EmptyStateWrapper = styled('div')`
  width: 100%;
  height: calc(100% - 42px);
  position: absolute;
`;

export const ClassifiersManagement: React.FC = () => {
  const { t } = useLocalTranslation();
  const [state, dispatch] = useReducer(classifierManagementReducer, classifierManagementInitialState);
  const { appData } = useTPADetails(DOC_APP_NAME);
  const { data: checksumValidations } = useFetchData<OOTBChecksumValidation[]>(fetchChecksumValidations);

  const countryFilterOptions = useMemo(getListOfCountriesForFilter, []);
  const getInitialFilterToolbarConfig = useCallback(() => getInitialClassifiersFilters(countryFilterOptions), []);
  const initialGridColumns = useMemo(() => getClassifiersInitialGridColumns(), []);

  const { isReady, gridColumns, updatePreferences, filterToolbarConfig } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns,
    getInitialFilterToolbarConfig,
  });
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(true);
  const [isClassifierTesterDialogOpen, setIsClassifierTesterDialogOpen] = useState(false);
  const [{ isOpenCreateClassifierDialog, classifierType }, setCreateClassifierDialogData] = useState({
    isOpenCreateClassifierDialog: false,
    classifierType: null,
  });
  const [classifiersToUpdateCount, setClassifiersToUpdateCount] = useState<number>(0);
  const [isClassifiersToUpdateLoading, setIsClassifiersToUpdateLoading] = useState(false);

  const [activeClassifier, setActiveClassifier] = useState({} as ClassifierGridRow);
  const activeClassifierRef = useRef<Partial<ClassifierGridRow>>();
  const [isShowGuidedTour, setIsShowGuidedTour] = useState(false);
  const [isLoadingClassifier, setIsLoadingClassifier] = useState(false);

  const {
    isDeletePermitted,
    isEditPermitted,
    isCreatePermitted,
    isImportPermitted,
    isExportPermitted,
    isDocAppPermitted,
  } = usePermissions();

  const { isNerClassifierFFEnabled, isClassifiersTuningEnabled, isClassifiersAutoUpgradeDisable } = useMemo(
    () => ({
      isNerClassifierFFEnabled: getIsNerClassifierFFEnabled(),
      isClassifiersTuningEnabled: getIsClassifiersTuningEnabled(),
      isClassifiersAutoUpgradeDisable: getIsClassifiersAutoUpgradeDisable(),
    }),
    [],
  );

  const { _id: docAppId } = appData;
  const isNerEnabled = isCreatePermitted && isNerClassifierFFEnabled;
  const isDocMenuItemVisible = docAppId && isDocAppPermitted && isNerClassifierFFEnabled;

  const handleCloseCreateClassifierDialog = async (isFromSave?: CREATE_CLASSIFIER_DIALOG_EVENTS) => {
    if (isFromSave === CREATE_CLASSIFIER_DIALOG_EVENTS.SAVE) {
      setCreateClassifierDialogData({
        isOpenCreateClassifierDialog: false,
        classifierType: null,
      });
      return;
    }

    const formValues = formControlsRef.current.getValues();

    let initialValues;
    if (classifierType === CLASSIFIER_TYPES.CONTENT) {
      initialValues = getInitialData({
        classifierType: CLASSIFIER_TYPES.CONTENT,
        isNegativeSupportTermFormVisible: false,
        isSupportTermFormVisible: false,
        formData: { attribute: { friendly_name: '' } },
        isEdit: false,
        isOOTB: false,
      }).initialValues;
    }

    if (classifierType === CLASSIFIER_TYPES.NER) {
      initialValues = getInitialFormData(
        { attribute: { friendly_name: '' } } as Partial<ClassifierGridRow>,
        false,
        false,
      ).initialValues;
    }

    const isEqualClassifierCreation = classifiersManagementUtils.isEqualClassifierCreationMode(
      formValues,
      initialValues,
    );

    if (!isEqualClassifierCreation) {
      const isKeepEditing = await openKeepEditingDialog();
      if (isKeepEditing) {
        return;
      }
    }

    setCreateClassifierDialogData({
      isOpenCreateClassifierDialog: false,
      classifierType: null,
    });
  };

  const updateActiveClassifierRef = (classifierToUpdate: ClassifierGridRow) => {
    activeClassifierRef.current = classifierToUpdate;
  };

  const getActiveClassifierRef = () => activeClassifierRef.current;

  const { isLoading, onFormSubmit, formControlsRef, onUpdateClassifier } = useFormActions(
    handleCloseCreateClassifierDialog,
    (classifier: ClassifierGridRow) => setActiveClassifier(classifier),
    updateActiveClassifierRef,
    getActiveClassifierRef,
  );

  const { excludeListHandler } = useExcludeList();

  const isReviewAvailableUpdates =
    isDeletePermitted && isClassifiersAutoUpgradeDisable && Boolean(classifiersToUpdateCount);

  useEffect(() => {
    analyticsService.trackManualEvent(ClassifiersEventsEnum.CLASSIFIER_PAGE_LOADED);
    const getClassifiersToUpdate = async () => {
      const classifiersList = await fetchClassifiersToUpdate();
      setClassifiersToUpdateCount(classifiersList?.classifications?.length);
    };

    getClassifiersToUpdate();
  }, []);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: t('header.title'),
      titleHelperComponent: <BigidPageTitleHelper {...pageTitleHelperClassifiersManagement} />,
      rightSideComponentsContainer: headerButtons,
    });
    classifiersManagementUtils.navigateToForbiddenPageIfFeatureFlagOff();
  }, [state?.query?.filter, docAppId, classifiersToUpdateCount]);

  const pageTitleHelperClassifiersManagement: BigidPageTitleHelperProps = {
    dataAid: generateDataAid('ClassifiersManagement', ['title', 'helper']),
    contentWidth: 365,
    togglerIcon: BigidHelpIcon,
    title: t('guideTour.titleHelper'),
    body: t('guideTour.bodyHelper'),
    buttons: [
      {
        type: BigidPageTitleHelperButtonType.TERTIARY,
        text: t('guideTour.viewDocumentation'),
        endIcon: <BigidExternalLinkIcon />,
        onClick: () => {
          window.open(docsUrls.CLASSIFIERS_MANAGEMENT_DOC, '_blank');
        },
      },
      {
        type: BigidPageTitleHelperButtonType.PRIMARY,
        text: t('guideTour.learnMore'),
        onClick: () => {
          setIsShowGuidedTour(true);
        },
      },
    ],
  };

  const handleImport = async () => {
    analyticsService.trackManualEvent(ClassifiersEventsEnum.IMPORT_CLASSIFIERS);
    await openImportClassifiersDialog((file: File, isImportExcludeList: boolean) =>
      onImportHandler(file, isImportExcludeList),
    );
  };

  const getIsExportDisabled = (selectedItems: ClassifierGridRow[]) => {
    const isAllSelectedClassifiersAreNotRegex = selectedItems.every(
      classifier =>
        isNer(classifier.classifierType) ||
        isDoc(classifier.classifierType) ||
        isCorrelation(classifier.classifierType),
    );

    setIsExportDisabled(isAllSelectedClassifiersAreNotRegex);
    return isAllSelectedClassifiersAreNotRegex;
  };

  const handleBulkExport = async (actionData: ActionData) => {
    const { filter, allSelected, selectedRowIds } = actionData;

    const handleExport = () => {
      const queryParams = {
        ...(filter ? { filter } : {}),
        ...(allSelected ? {} : { selectedRowIds }),
      };

      exportBulkClassifiers(queryParams);
      analyticsService.trackManualEvent(ClassifiersEventsEnum.EXPORT_CLASSIFIERS);
    };

    try {
      const isExport = await confirmationExportDialog();
      isExport && handleExport();
    } catch (e) {
      console.error('Export classifiers failed: ', e);
    }
  };

  const handleReviewAvailableUpdates = async () => {
    if (getIsClassifiersAutoUpgradeDisable()) {
      setIsClassifiersToUpdateLoading(true);

      const response = await fetchClassifiersToUpdate();
      setClassifiersToUpdateCount(response?.classifications?.length);

      setIsClassifiersToUpdateLoading(false);

      // open modal
      openClassifierUpdatePreviewBulkDialog({
        classifiers: response?.classifications as unknown as Classifier[],
        onUpdate: updatedClassifiers => {
          setClassifiersToUpdateCount(classifiersToUpdateCount - updatedClassifiers?.length);
          reloadGrid();
        },
      });
    }
  };

  const handleCreateRegex = () => {
    analyticsService.trackManualEvent(ClassifiersEventsEnum.CREATE_REGEX_CLASSIFIER_DROPDOWN_CLICK);
    setCreateClassifierDialogData({
      isOpenCreateClassifierDialog: true,
      classifierType: CLASSIFIER_TYPES.CONTENT,
    });
  };

  const handleCreateDOC = () => {
    analyticsService.trackManualEvent(ClassifiersEventsEnum.CREATE_DOC_CLASSIFIER_DROPDOWN_CLICK);
    docAppId && window.open(getDocAppUrl(docAppId), '_blank');
  };

  const handleCreateNER = () => {
    analyticsService.trackManualEvent(ClassifiersEventsEnum.CREATE_NER_CLASSIFIER_DROPDOWN_CLICK);
    setCreateClassifierDialogData({
      isOpenCreateClassifierDialog: true,
      classifierType: CLASSIFIER_TYPES.NER,
    });
  };

  const handleCloseSidePanel = async () => {
    await handleDiscardChanges();
    setIsSidePanelOpen(false);
    setActiveClassifier({} as ClassifierGridRow);
    formControlsRef.current = {};
    activeClassifierRef.current = {};
  };

  const handleCloseClassifierTesterDialog = () => {
    setIsClassifierTesterDialogOpen(false);
  };

  const actionsMenuItems = classifiersManagementUtils.getActionsMenuItems({
    handleImport,
    handleReviewAvailableUpdates,
    isImportPermitted,
    isReviewAvailableUpdates,
  });
  const addClassifierMenuItems = classifiersManagementUtils.getAddClassifierMenuItems({
    handleCreateRegex,
    handleCreateDOC,
    handleCreateNER,
    isDocMenuItemVisible,
    isCreatePermitted,
    isNerEnabled,
  });
  const isImportExportSupportedFFEnabled = classifiersManagementUtils.isImportExportSupportedFFEnabled();

  const headerButtons = (
    <HeaderButtons
      actionsMenuItems={actionsMenuItems}
      addClassifierMenuItems={addClassifierMenuItems}
      isImportExportSupportedFFEnabled={isImportExportSupportedFFEnabled}
    />
  );

  const handleGridItemEnabledDisabled = async (actionData: ActionData, enabled: boolean) => {
    const [row] = actionData.selectedRows;
    const payload = { enabled };

    await handleEnableDisable({
      classifier: row,
      payload,
      successCallback: () => {
        const eventName = getEnabledDisabledEventName(enabled, EnabledType.inline);
        analyticsService.trackManualEvent(eventName, {
          classifier: { ...row, enabled },
        });
        updateGridItemById(row._id, payload);
      },
    });

    return {};
  };

  const handleTestSubmit = async (classifierName: string, regexToUpdate: string, classifierPerformance: number) => {
    const formValues = formControlsRef.current?.getValues();

    if (REGEX_FORM_TYPES.includes(formValues.classifierType)) {
      const classifierToUpdate = {
        ...(omit(getRegexFormSubmitFields(formValues), ['selected', 'categories', 'attribute']) as ClassifierGridRow),
        classification_regex: regexToUpdate,
        performance: classifierPerformance,
        ...(formValues._id ? { _id: formValues._id } : {}),
      };

      handleCloseClassifierTesterDialog();
      analyticsService.trackManualEvent(ClassifiersEventsEnum.TESTED_CLASSIFIER_SAVED, {
        classifier: classifierToUpdate,
      });
      await onUpdateClassifier({
        payload: classifierToUpdate,
        isAssignEnable: false,
        gridClassifierData: formValues,
      });
    }
  };

  const handleAssignCategory = async (actionData: ActionData, assignType: ASSIGN_CATEGORIES_TYPE) => {
    try {
      await showAssignCategoryDialog(actionData, assignType, rows => {
        if (assignType === ASSIGN_CATEGORIES_TYPE.DEFAULT) {
          analyticsService.trackManualEvent(ClassifiersEventsEnum.ASSIGN_CATEGORIES_INLINE_ACTION, {
            classifiers: rows,
          });
        }

        if (assignType === ASSIGN_CATEGORIES_TYPE.BULK) {
          analyticsService.trackManualEvent(ClassifiersEventsEnum.ASSIGN_CATEGORIES_BULK_ACTION, {
            classifiers: rows,
          });
        }

        if (assignType === ASSIGN_CATEGORIES_TYPE.DEFAULT) {
          const [singleRow] = rows;
          classifiersManagementUtils.updateGridItemById(singleRow._id, { categories: singleRow.categories });
        }
      });
    } catch ({ message }) {
      notificationService.error(t('AssignCategoryDialog.errors.categoryAssignmentFailed'));
      console.error(`An error has occurred: ${message}`);
    } finally {
      return {};
    }
  };

  const bulkEnableDisable = async (actionData: ActionData, enabled: boolean) => {
    const payload = {
      allSelected: actionData.allSelected,
      ids: actionData.selectedRowIds,
      query: { filter: actionData.filter },
      enabled,
    } as handleEnableDisableBulkParams['payload'];

    await handleEnableDisableBulk({
      payload,
      successCallback: () => {
        const eventName = getEnabledDisabledEventName(enabled, EnabledType.bulk);
        analyticsService.trackManualEvent(eventName, {
          ids: actionData.selectedRowIds,
          enabled,
        });

        const isIdsMoreThanOne = size(payload.ids) > 1;
        classifiersManagementUtils.handleBulkNotifications(payload.enabled, isIdsMoreThanOne);
      },
    });
  };

  const getClassifierComparing = (classifier: Partial<ClassifierGridRow>) => {
    return omit(classifier, [
      '__valuesCounter',
      'isSupportTermFormVisible',
      'isNegativeSupportTermFormVisible',
      'friendly_name',
      'glossary_id',
      'attribute',
      'categories',
      'enabled',
      'id',
      'versionState',
      'version_state',
      'version',
      'isClassifierTesterSupported',
      ...(classifier?.classifierType === 'columnName'
        ? [
            'min_length',
            'max_length',
            'validation',
            'negative_support_term_value',
            'nst_proximity_after',
            'nst_proximity_before',
            'proximity_after',
            'proximity_before',
            'support_term_value',
          ]
        : []),
    ]);
  };

  const regexHandlerComparing = async (classifier: ClassifierGridRow, classifierToCompare: Record<string, any>) => {
    let isSave;

    const activeRequestedClassifier = isEmpty(activeClassifierRef.current) ? classifier : activeClassifierRef.current;

    const { isEqualAttributes, isEqualCategories } = classifiersManagementUtils.isEqualAttributesAndCategories(
      activeRequestedClassifier as ClassifierGridRow,
      classifierToCompare,
    );

    const requestedClassifier = getClassifierComparing(activeRequestedClassifier);
    if (requestedClassifier?.classifierType === null) {
      requestedClassifier.classifierType = CLASSIFIER_TYPES.CONTENT;
    }

    if (
      requestedClassifier &&
      !requestedClassifier?.isOutOfTheBox &&
      classifiersManagementUtils.isRegexNotIncludeMetadata(requestedClassifier.classifierType)
    ) {
      requestedClassifier.report_as_classification = getValueOrDefault(
        requestedClassifier.report_as_classification,
        true,
      );
      requestedClassifier.report_as_pii_finding = Boolean(requestedClassifier.report_as_pii_finding);
      requestedClassifier.ignored = Boolean(requestedClassifier.ignored);
      // This line was added to handle modal discard changes for classifiers created using the old page
      requestedClassifier.validation = getValueOrDefault(requestedClassifier.validation, null);
    }

    // This line was added to handle modal discard changes for classifiers created using the old page
    if (requestedClassifier && !requestedClassifier?.isOutOfTheBox) {
      requestedClassifier.description = getValueOrDefault(requestedClassifier.description, '');
    }

    let classifierFormValues = getClassifierComparing(classifierToCompare);
    if (classifierFormValues.classification_name !== activeRequestedClassifier.classification_name) {
      classifierFormValues = {};
    }

    const isEqualClassifiers = isEqual(requestedClassifier, classifierFormValues);

    if ((!isEqualAttributes || !isEqualCategories || !isEqualClassifiers) && !isEmpty(classifierFormValues)) {
      isSave = await openSaveChangesDialog(activeRequestedClassifier.classification_name);
      if (isSave) {
        if (classifier?.classification_name === classifierToCompare.classification_name) {
          classifier = omit(classifierToCompare, [
            'isNegativeSupportTermFormVisible',
            'attribute',
            'isSupportTermFormVisible',
          ]) as ClassifierGridRow;

          classifier.friendly_name = classifierToCompare.attribute.friendly_name;
          classifier.glossary_id = classifierToCompare.attribute.glossary_id;
        }
        await onFormSubmit(classifier);
      } else {
        formControlsRef?.current?.clear();
        const classifierType =
          activeRequestedClassifier.classifierType === null
            ? CLASSIFIER_TYPES.CONTENT
            : activeRequestedClassifier.classifierType;
        formControlsRef?.current?.setValue('classifierType')(classifierType);
      }
    }
    return isSave;
  };

  const advencedClassifiersHandlerComparing = async (
    classifier: ClassifierGridRow,
    classifierToCompare: Record<string, any>,
  ) => {
    let isSave;
    const activeRequestedClassifier = isEmpty(activeClassifierRef.current) ? classifier : activeClassifierRef.current;

    const { isEqualAttributes, isEqualCategories } = classifiersManagementUtils.isEqualAttributesAndCategories(
      activeRequestedClassifier as ClassifierGridRow,
      classifierToCompare,
    );

    let classifierFormValues = getClassifierComparing(classifierToCompare);
    if (activeRequestedClassifier?.classifierType === CLASSIFIER_TYPES.NER) {
      if (classifierFormValues.classifierName !== activeRequestedClassifier.classifierName) {
        classifierFormValues = {};
      }
    }

    if (activeRequestedClassifier?.classifierType === CLASSIFIER_TYPES.DOC) {
      if (classifierFormValues.classification_name !== activeRequestedClassifier.classification_name) {
        classifierFormValues = {};
      }
    }

    if (activeRequestedClassifier?.classifierType === CLASSIFIER_TYPES.CORRELATION) {
      if (classifierFormValues.classification_name !== activeRequestedClassifier.classification_name) {
        classifierFormValues = {};
      }
    }

    const isCorrelationAndSureMatchChanged =
      activeRequestedClassifier.classifierType === CLASSIFIER_TYPES.CORRELATION &&
      activeRequestedClassifier.isOverrideSureMatch !== classifierFormValues?.isOverrideSureMatch;

    if (
      (!isEqualAttributes || !isEqualCategories || isCorrelationAndSureMatchChanged) &&
      !isEmpty(classifierFormValues)
    ) {
      const isSave = await openSaveChangesDialog(activeRequestedClassifier.classification_name);
      if (isSave) {
        const classifierToFormSubmit: Record<string, any> = { ...classifier, isFromSaveChangesDialog: true };

        if (activeRequestedClassifier?.classifierType === CLASSIFIER_TYPES.NER) {
          if (classifierFormValues?.classifierName === classifier?.classifierName) {
            classifierToFormSubmit.categories = classifierToCompare.categories;
            classifierToFormSubmit.friendly_name = classifierToCompare.attribute.friendly_name;
            classifierToFormSubmit.glossary_id = classifierToCompare.attribute.glossary_id;
          }
        }

        if (activeRequestedClassifier?.classifierType === CLASSIFIER_TYPES.DOC) {
          if (classifierFormValues?.classification_name === classifier?.classification_name) {
            classifierToFormSubmit.categories = classifierToCompare.categories;
            classifierToFormSubmit.friendly_name = classifierToCompare.attribute.friendly_name;
            classifierToFormSubmit.glossary_id = classifierToCompare.attribute.glossary_id;
          }
        }

        await onFormSubmit(classifierToFormSubmit as ClassifierGridRow);
      } else {
        formControlsRef?.current?.clear();
      }
    }
    return isSave;
  };

  const handleDiscardChanges = async (classifier?: ClassifierGridRow) => {
    let isSave;
    const activeRequestedClassifier = isEmpty(activeClassifierRef.current) ? classifier : activeClassifierRef.current;

    if (classifiersManagementUtils.isRegex(activeRequestedClassifier?.classifierType)) {
      isSave = await regexHandlerComparing(classifier, formControlsRef.current?.getValues?.());
    }

    if (
      classifiersManagementUtils.isNer(activeRequestedClassifier?.classifierType) ||
      classifiersManagementUtils.isDoc(activeRequestedClassifier?.classifierType) ||
      activeRequestedClassifier?.classifierType === CLASSIFIER_TYPES.CORRELATION
    ) {
      isSave = await advencedClassifiersHandlerComparing(classifier, formControlsRef.current?.getValues?.());
    }
    return isSave;
  };

  const { updateIsRouteLeavingEnabled } = useRouteLeavingListener(handleDiscardChanges);

  useEffect(() => {
    updateIsRouteLeavingEnabled(true);
    return () => {
      updateIsRouteLeavingEnabled(false);
    };
  }, []);

  const getHasNoCorrelationClassifier = (selectedRows: ClassifierGridRow[]) => {
    return selectedRows.some(
      (classifier: ClassifierGridRow) => classifier.classifierType !== CLASSIFIER_TYPES.CORRELATION,
    );
  };

  const config: BigidGridWithToolbarProps<ClassifierGridRow> = useMemo(
    () => ({
      forceSelectAll: true,
      pageSize: 50,
      entityName: t('general.classifiers'),
      rowClickShouldKeepSelection: true,
      shouldKeepSelectionAfterFilter: true,
      customRowIdName: 'id',
      showSelectionColumn: true,
      showSortingControls: true,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      filterToolbarConfig,
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        // TODO: remove all these filter changes belove when BDT ready
        let filter = queryComponents?.filter;

        const isFilterIncludeEnabledTrue = queryComponents?.filter?.some(filterItem => {
          return (
            filterItem.field === FILTER_KEYS.enabled &&
            (filterItem.value as BigidFilterOptionValueType[]).includes(true)
          );
        });

        const isFilterByTypeExist = queryComponents?.filter?.some(filterItem => {
          return filterItem.field === FILTER_KEYS.type;
        });

        if (isFilterIncludeEnabledTrue) {
          filter = queryComponents?.filter.map(filterItem => {
            if (filterItem.field === FILTER_KEYS.enabled) {
              return { ...filterItem, value: [...(filterItem.value as BigidFilterOptionValueType[]), null] };
            }
            if (filterItem.field === FILTER_KEYS.type) {
              return {
                ...filterItem,
                value: (filterItem.value as BigidFilterOptionValueType[])?.filter(
                  type => type !== CLASSIFIER_TYPES.CORRELATION,
                ),
              };
            }
            return filterItem;
          });

          if (!isFilterByTypeExist) {
            const contentAndMetadata = getIsClassifyFileNamesEnabled() ? [CLASSIFIER_TYPES.CONTENT_AND_METADATA] : [];
            const docAndNer = getIsNerClassifierFFEnabled() ? [CLASSIFIER_TYPES.DOC, CLASSIFIER_TYPES.NER] : [];

            filter.push({
              field: FILTER_KEYS.type,
              value: [CLASSIFIER_TYPES.CONTENT, CLASSIFIER_TYPES.METADATA, ...contentAndMetadata, ...docAndNer],
              operator: 'in',
            });
          }
        }

        const data = await fetchClassifiers({ ...queryComponents, filter }, dispatch);
        return data;
      },
      onRowClick: async (classifier: ClassifierGridRow) => {
        await handleDiscardChanges(classifier);
        setIsSidePanelOpen(true);
        if (activeClassifierRef?.current?._id !== classifier._id) {
          setIsLoadingClassifier(true);
          const fetchClassifier = await getClassifierById(classifier._id);
          activeClassifierRef.current = fetchClassifier;
          setActiveClassifier(fetchClassifier);
          setIsLoadingClassifier(false);
        }
      },
      noDataContent: (
        <EmptyStateWrapper>
          <BigidLayoutEmptyState
            illustration={BigidFiltersIllustration}
            description={t('grid.noResult')}
            actions={[
              {
                isGlobal: true,
                label: 'Reset filters',
                execute: async () => classifiersManagementUtils.clearFilters(),
                show: () => true,
              },
            ]}
          />
        </EmptyStateWrapper>
      ),
      columns: gridColumns,
      toolbarActions: [
        {
          label: t('grid.assignToCategory'),
          icon: BigidAddCategoryIcon,
          type: ToolbarActionType.TERTIARY,
          isGlobal: false,
          execute: async actionData => {
            await handleAssignCategory(actionData, ASSIGN_CATEGORIES_TYPE.BULK);
            return {
              shouldClearSelection: true,
              shouldGridReload: true,
            };
          },
          disable: () => false,
          show: ({ selectedRowIds }) => selectedRowIds.length > 0,
        },
        {
          label: t('grid.enableGlobal'),
          icon: BigidApplyIcon,
          type: ToolbarActionType.TERTIARY,
          isGlobal: false,
          execute: async (actionData: ActionData) => {
            await bulkEnableDisable(actionData, true);
            return {
              shouldClearSelection: true,
              shouldGridReload: true,
            };
          },
          disable: ({ selectedRows }) => {
            const hasNoCorrelationClassifier = getHasNoCorrelationClassifier(selectedRows);
            return !hasNoCorrelationClassifier;
          },
          show: ({ selectedRowIds }) => {
            return isEditPermitted && selectedRowIds.length > 0;
          },
        },
        {
          label: t('grid.disableGlobal'),
          icon: BigidDisableIcon,
          type: ToolbarActionType.TERTIARY,
          isGlobal: false,
          execute: async (actionData: ActionData) => {
            await bulkEnableDisable(actionData, false);
            return {
              shouldClearSelection: true,
              shouldGridReload: true,
            };
          },
          disable: ({ selectedRows }) => {
            const hasNoCorrelationClassifier = getHasNoCorrelationClassifier(selectedRows);
            return !hasNoCorrelationClassifier;
          },
          show: ({ selectedRowIds }) => isEditPermitted && selectedRowIds.length > 0,
        },
        {
          label: t('header.export'),
          tooltip: isExportDisabled ? t('grid.exportDisabledTooltip') : undefined,
          icon: BigidExportIcon,
          type: ToolbarActionType.TERTIARY,
          isGlobal: false,
          execute: async (actionData: ActionData) => {
            await handleBulkExport(actionData);
            return {
              shouldClearSelection: true,
            };
          },
          disable: ({ selectedRows }) => {
            return getIsExportDisabled(selectedRows);
          },
          show: ({ selectedRowIds }) => isExportPermitted && selectedRowIds.length > 0,
        },
        {
          label: t('grid.delete'),
          execute: async actionData => {
            const [row] = actionData.selectedRows;
            const response = await handleDeleteClassifier(row);
            if (isRequestSucceeded(response) || response?.data?.status === 'success') {
              analyticsService.trackManualEvent(ClassifiersEventsEnum.DELETE_CLASSIFIER_INLINE_ACTION, {
                classifier: row,
              });
              entityEventsEmitter.emit(EntityEvents.DELETE, [row]);
            }

            return {};
          },
          disable: () => false,
          show: (actionData: ActionData) => {
            const [row] = actionData.selectedRows;
            return (
              isDeletePermitted &&
              row.isOutOfTheBox === false &&
              !classifiersManagementUtils.isDoc(row.classifierType) &&
              !classifiersManagementUtils.isCorrelation(row.classifierType)
            );
          },
          icon: BigidDeleteIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: t('grid.duplicate'),
          execute: async actionData => {
            const [row] = actionData.selectedRows;
            const { duplicateClassifierName: duplicateClassificationName } = await openCreateDuplicateClassifierDialog(
              'xs',
              row.classification_name,
              true,
            );

            if (duplicateClassificationName) {
              const newClassifier = await handleDuplicateClassifier(row, duplicateClassificationName);

              if (newClassifier?._id) {
                addGridItem({ ...newClassifier, id: newClassifier._id }, newClassifier._id);
              }
            }

            return {};
          },
          disable: () => false,
          show: (actionData: ActionData) => {
            const [row] = actionData.selectedRows;
            return isCreatePermitted && isRegex(row.classifierType);
          },
          icon: BigidDuplicateIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: t('grid.classifierTuning'),
          execute: async actionData => {
            const [row] = actionData.selectedRows;
            goToClassifierTuning(row.classification_name, row.attributeType, row.friendly_name);
            return {};
          },
          disable: () => false,
          show: (actionData: ActionData) => {
            const [row] = actionData.selectedRows;
            return isClassifiersTuningEnabled && getShouldShowClassifierTuning(row.totalFields);
          },
          icon: BigidCheckedViewIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: t('grid.editDoc'),
          execute: async actionData => {
            analyticsService.trackManualEvent(ClassifiersEventsEnum.EDIT_DOC_CLASSIFIER_INLINE_ACTION);
            window.open(getDocAppUrl(docAppId), '_blank');
            return {};
          },
          disable: () => false,
          show: (actionData: ActionData) => {
            const [row] = actionData.selectedRows;
            return isDocMenuItemVisible && classifiersManagementUtils.isDoc(row.classifierType);
          },
          icon: BigidExternalLinkIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: t('grid.enableGlobal'),
          execute: async actionData => handleGridItemEnabledDisabled(actionData, true),
          show: (actionData: ActionData) => {
            const [row] = actionData.selectedRows;
            return (
              isEditPermitted && row.enabled === false && !classifiersManagementUtils.isCorrelation(row.classifierType)
            );
          },
          icon: BigidApplyIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: t('grid.disableGlobal'),
          execute: async actionData => handleGridItemEnabledDisabled(actionData, false),
          show: (actionData: ActionData) => {
            const [row] = actionData.selectedRows;
            return (
              isEditPermitted && row.enabled === true && !classifiersManagementUtils.isCorrelation(row.classifierType)
            );
          },
          icon: BigidDisableIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: t('grid.excludeList'),
          execute: async actionData => {
            const [row] = actionData.selectedRows;
            analyticsService.trackManualEvent(ClassifiersEventsEnum.EXCLUDE_LIST_INLINE_ACTION, row);
            await excludeListHandler(row);
            return {};
          },
          show: (actionData: ActionData) => {
            const [row] = actionData.selectedRows;
            const isMetadata = classifiersManagementUtils.isMetadata(row.classifierType);
            const isDoc = classifiersManagementUtils.isDoc(row.classifierType);
            const isCorrelation = classifiersManagementUtils.isCorrelation(row.classifierType);
            return isEditPermitted && isClassfierExcludeListFF() && !isMetadata && !isDoc && !isCorrelation;
          },
          icon: BigidClearIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: t('grid.assignCategory'),
          execute: actionData => handleAssignCategory(actionData, ASSIGN_CATEGORIES_TYPE.DEFAULT),
          show: (actionData: ActionData) => true,
          icon: BigidAddCategoryIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
      ],
    }),
    [filterToolbarConfig, gridColumns, isExportDisabled],
  );

  const getTitleCreateClassifierDialog = (type: CLASSIFIER_TYPES) => {
    if (type === CLASSIFIER_TYPES.CONTENT) {
      return 'Add Regex Classifier';
    }

    if (type === CLASSIFIER_TYPES.NER) {
      return 'Add NLP/NER Classifier';
    }
  };

  const BigidGridWithToolbarMemo = useMemo(() => <BigidGridWithToolbar {...config} />, [config]);

  return (
    <>
      <ClassifiersInfoNotification />
      <GridWrapper>
        {(!isReady || isClassifiersToUpdateLoading) && <BigidLoader />}
        <BigidPaper>{isReady && BigidGridWithToolbarMemo}</BigidPaper>
      </GridWrapper>

      {/*Create classifier dialog*/}
      <CreateClassifierDialog
        title={getTitleCreateClassifierDialog(classifierType)}
        isOpen={isOpenCreateClassifierDialog}
        onSave={() => onFormSubmit()}
        onClose={handleCloseCreateClassifierDialog}
        isLoading={isLoading}
        classifierType={classifierType}
      >
        {classifierType === CLASSIFIER_TYPES.CONTENT && (
          <RegexClassifierForm formControlsRef={formControlsRef} checksumValidations={checksumValidations} />
        )}

        {classifierType === CLASSIFIER_TYPES.NER && <NERForm formControlsRef={formControlsRef} />}
      </CreateClassifierDialog>

      {/*Side Panel*/}
      <ClassifiersSidePanel
        isLoadingClassifier={isLoadingClassifier}
        isOpen={isSidePanelOpen}
        onClose={handleCloseSidePanel}
        setClassifiersToUpdateCount={setClassifiersToUpdateCount}
        handleOpenClassifierTesterDialogOpen={() => setIsClassifierTesterDialogOpen(true)}
        checksumValidations={checksumValidations}
        formControlsRef={formControlsRef}
        isLoading={isLoading}
        onFormSubmit={onFormSubmit}
        isDocMenuItemVisible={isDocMenuItemVisible}
        docAppId={docAppId}
        isSaveButtonVisible={isCreatePermitted && isEditPermitted}
        setActiveClassifier={(classifier: ClassifierGridRow) => setActiveClassifier(classifier)}
        activeClassifier={activeClassifier}
        updateActiveClassifierRef={updateActiveClassifierRef}
      />

      {/*Tester Dialog*/}
      <BigidClassifierTesterDialog
        isOpen={isClassifierTesterDialogOpen}
        onClose={handleCloseClassifierTesterDialog}
        classifier={activeClassifier}
        onSave={handleTestSubmit}
      />
      {/* Guide Tour */}
      <ClassifiersManagementDialogGuide setIsShowGuidedTour={setIsShowGuidedTour} manualStart={isShowGuidedTour} />
    </>
  );
};
