import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material';
import { BigidAdvancedToolbarFilter, BigidPaper, BigidSkeletonGenerator } from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { UseCatalogDiscoveryResponse } from '../useCatalogDiscovery';
import { useFilterConfig } from '../filter/useFilterConfig';
import { dataFetchingTimeoutThreshold } from '../config/common';
import { filterSkeletonConfig } from '../config/skeleton';
import { GoToCatalog } from './actions/GoToCatalog/GoToCatalog';
import { GoToInventory } from './actions/GoToInventory/GoToInventory';
import { ExportReport } from './actions/ExportReport/ExportReport';
import { DefinePolicy } from './actions/DefinePolicy/DefinePolicy';
import { SaveQuery } from './actions/SaveQuery/SaveQuery';
import { smallIdLicenseService } from '../../../services/smallIdLicenseService';
import { isMultiTenantModeEnabled } from '../../../utilities/multiTenantUtils';

export interface CatalogDiscoveryToolbarProps
  extends Pick<
    UseCatalogDiscoveryResponse,
    | 'onToolbarFilterChange'
    | 'onToolbarFilterReloaded'
    | 'onToolbarFilterInitialise'
    | 'onToolbarFilterTimeoutReached'
    | 'onDataFetchStatusChange'
    | 'query'
    | 'filter'
    | 'appliedFilter'
    | 'savedQueries'
    | 'currentSavedQuery'
    | 'onSavedQueryCreate'
    | 'onSavedQueryUpdate'
    | 'onSavedQueryDelete'
    | 'onSavedQuerySelect'
    | 'isFilterChanged'
  > {
  dataAid?: string;
  dataTourId?: string;
  isToolbarPreferencesReady: boolean;
  isPageInitialised: boolean;
  isInventoryPermitted: boolean;
}

const Root = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Toolbar = styled('div')`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
`;

const Actions = styled('div')`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

const FilterWrapper = styled('div')`
  padding: 16px;
  display: flex;
`;

const Filter = styled('div')<{ isHidden: boolean }>`
  display: flex;
  position: relative;
  flex: 1;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

export const CatalogDiscoveryToolbar: FC<CatalogDiscoveryToolbarProps> = ({
  dataAid = 'CatalogDiscoveryToolbar',
  dataTourId = 'CatalogDiscoveryToolbar',
  query,
  filter,
  appliedFilter,
  isFilterChanged,
  onDataFetchStatusChange,
  onToolbarFilterChange,
  onToolbarFilterInitialise,
  onToolbarFilterReloaded,
  onToolbarFilterTimeoutReached,
  isPageInitialised,
  isToolbarPreferencesReady,
  savedQueries,
  currentSavedQuery,
  onSavedQueryCreate,
  onSavedQuerySelect,
  onSavedQueryUpdate,
  onSavedQueryDelete,
  isInventoryPermitted,
}) => {
  const { filterConfig } = useFilterConfig();
  const asyncLoaderConfig = useMemo(() => {
    return {
      initDelay: dataFetchingTimeoutThreshold,
      reloadDelay: dataFetchingTimeoutThreshold,
      onTimeoutReached: onToolbarFilterTimeoutReached,
    };
  }, [onToolbarFilterTimeoutReached]);

  const shouldShowGoToInventory =
    !smallIdLicenseService.isSmallIDLicense() && !isMultiTenantModeEnabled() && isInventoryPermitted;

  return (
    <Root data-aid={dataAid}>
      <Toolbar>
        <SaveQuery
          dataAid={generateDataAid(dataAid, ['action', 'saveQuery'])}
          filter={filter}
          query={query}
          currentSavedQuery={currentSavedQuery}
          savedQueries={savedQueries}
          isFilterChanged={isFilterChanged}
          isPageInitialised={isPageInitialised}
          isToolbarPreferencesReady={isToolbarPreferencesReady}
          onSavedQueryCreate={onSavedQueryCreate}
          onSavedQueryDelete={onSavedQueryDelete}
          onSavedQuerySelect={onSavedQuerySelect}
          onSavedQueryUpdate={onSavedQueryUpdate}
        />
        <Actions data-tour-id={generateGuidedTourId(dataTourId, ['actions'])}>
          <ExportReport
            dataAid={generateDataAid(dataAid, ['action', 'exportReport'])}
            query={query}
            onDataFetchStatusChange={onDataFetchStatusChange}
            isPageInitialised={isPageInitialised}
          />
          {shouldShowGoToInventory && <GoToInventory dataAid={generateDataAid(dataAid, ['action', 'goToInventory'])} />}
          <DefinePolicy dataAid={generateDataAid(dataAid, ['action', 'definePolicy'])} query={query} />
          <GoToCatalog dataAid={generateDataAid(dataAid, ['action', 'goToCatalog'])} query={query} />
        </Actions>
      </Toolbar>
      <BigidPaper>
        <FilterWrapper>
          {isToolbarPreferencesReady ? (
            <Filter isHidden={!isPageInitialised}>
              <BigidAdvancedToolbarFilter
                {...filterConfig}
                dataAid={generateDataAid(dataAid, ['filter'])}
                asyncLoaderConfig={asyncLoaderConfig}
                onFiltersChange={onToolbarFilterChange}
                onInitialize={onToolbarFilterInitialise}
                onFilterReload={onToolbarFilterReloaded}
                selectedOptionsConfig={appliedFilter}
              />
            </Filter>
          ) : (
            <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...filterSkeletonConfig} />
          )}
        </FilterWrapper>
      </BigidPaper>
    </Root>
  );
};
