import { userPreferencesService } from '../../../../services/userPreferencesService';
import { activityHighlightsService } from '../../../../services/activityHighlightsService';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { CaseStatus, fetchAllCasesData } from '../../../ActionableInsights/actionableInsightsService';
import { CaseState } from '../../../../types/actionableInsights';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { notificationService } from '../../../../services/notificationService';
import { getFixedT } from '../../translations';
import { getSharedUserPreferenceValue } from '../../utils/gettingStartedUtils';
import { closeSystemDialog, openSystemDialog } from '../../../../services/systemDialogService';
import { DiscoveryInProgressModalContent } from '../components/DiscoveryInProgressModalContent';
import { AutoDiscoveryWizardContextState } from '../../../AutoDiscovery/AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { analyticsService } from '../../../../services/analyticsService';

const NUM_CASES_THRESHOLD = 9;
const GETTING_STARTED_SECURITY_PATH = '/getting-started/security';

export const isNumOpenCasesThresholdExceeded = async (): Promise<boolean> => {
  try {
    const isEnabled = getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED');
    if (!isEnabled) {
      const t = getFixedT('security.page');
      notificationService.error(t('actionableInsightsNotEnabled'));
      return false;
    }
    const gridOpenCasesQuery: BigidGridQueryComponents = {
      filter: [{ field: 'caseStatus', operator: 'in', value: CaseStatus.OPEN }],
      limit: NUM_CASES_THRESHOLD + 1,
      requireTotalCount: true,
    };
    const { totalCount } = await fetchAllCasesData(gridOpenCasesQuery, CaseState.OPEN);
    return totalCount > NUM_CASES_THRESHOLD;
  } catch (error) {
    console.error(error);
    notificationService.error(error?.message);
    return false;
  }
};

export const checkIfSecurityDashboardWasVisitedAfterCasesCreation = async (): Promise<boolean> => {
  return getSharedUserPreferenceValue(
    userPreferencesService.SHARED_PREFERENCES.SECURITY_DASHBOARD_WAS_VISITED_AFTER_CASES_CREATION,
  );
};

export const checkIfSecurityPostureCaseSidePanelWasVisited = async (): Promise<boolean> => {
  return getSharedUserPreferenceValue(
    userPreferencesService.SHARED_PREFERENCES.SECURITY_POSTURE_CASE_SIDE_PANEL_WAS_VISITED,
  );
};

interface ActivityHighlightsCard {
  description: string;
  header: string;
}

export const wasActionableInsightsVisited = async (): Promise<boolean> => {
  const { filter: value } = (await userPreferencesService.get('actionableInsightsCases.open')) || {};
  return !!value;
};

export const wasReportVisited = async (): Promise<boolean> => {
  const isEnabled = getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED');
  if (isEnabled) {
    return wasActionableInsightsVisited();
  } else {
    return wasPopulatedActivityHighlightsReportVisited();
  }
};

export const wasPopulatedActivityHighlightsReportVisited = async (): Promise<boolean> => {
  const data = await userPreferencesService.get(
    userPreferencesService.SHARED_PREFERENCES.ACTIVITY_HIGHLIGHTS_REPORT_WAS_VISITED,
  );
  if (data?.data?.value) {
    const {
      data: {
        data: [activityHighlights],
      },
    } = await activityHighlightsService.getActivityHighlights(true);
    const { highlights } = activityHighlights;
    const piRecordsCard = highlights?.overview?.cards?.find(
      (card: ActivityHighlightsCard) => card.description == 'PI records',
    );
    if (0 !== Number(piRecordsCard?.header)) {
      return true;
    }
  }
  return false;
};

export const isInGettingStartedSecurityFlow = () => {
  return window.location.href.indexOf(GETTING_STARTED_SECURITY_PATH) > -1;
};

export const applyGettingStartedDiscoveryFlow = (discoveryConfigData: AutoDiscoveryWizardContextState) => {
  if (isInGettingStartedSecurityFlow()) {
    // INFO: close the current autodiscovery modal before showing new one
    closeSystemDialog();
    openSystemDialog({
      title: '',
      content: DiscoveryInProgressModalContent,
      maxWidth: 'xs',
      onClose: () => null,
      borderTop: false,
    });
    userPreferencesService.setSharedBooleanUserPreference(
      userPreferencesService.SHARED_PREFERENCES.AUTO_DISCOVERY_TRIGGERED_BY_SECURITY_GETTING_STARTED,
      true,
    );
    const discoveryType = discoveryConfigData?.type || 'unknown';
    analyticsService.trackManualEvent('AUTODISCOVERY_TRIGGERED_FROM_GETTING_STARTED_SECURITY', {
      discovery_type: discoveryType,
    });
  }
};
