import { LegalEntitiesStatus } from '../LegalEntitiesTypes';
import { BigidStatusBadgeType } from '@bigid-ui/components';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { queryService } from '../../../services/queryService';

export const statusBadgeMapper = {
  [LegalEntitiesStatus.ACTIVE]: BigidStatusBadgeType.SUCCESS,
  [LegalEntitiesStatus.INACTIVE]: BigidStatusBadgeType.ERROR,
  [LegalEntitiesStatus.DRAFT]: BigidStatusBadgeType.PENDING,
  [LegalEntitiesStatus.UNDER_REVIEW]: BigidStatusBadgeType.GENERAL,
};

export const getSearchQuery = (searchText = '', requireTotalCount = false) => {
  const bigidGridQuery = {
    filter: [
      {
        field: 'name',
        operator: 'textSearch',
        value: searchText,
      },
    ],
    requireTotalCount: requireTotalCount,
  } as BigidGridQueryComponents;

  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);

  return gridConfigQuery;
};

const FILE_SIZE_SUFFIXES = ['Bytes', 'KB', 'MB'];

export const transformSizeToReadableString = (bytes: number) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return bytes ? (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + FILE_SIZE_SUFFIXES[i] : '0 Bytes';
};
