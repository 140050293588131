import { BigidBody1, BigidCategoryChip, BigidChip, BigidChipProps, BigidColorsV2 } from '@bigid-ui/components';
import { BigidAnyIcon, BigidAttributeIcon, BigidColumnsIcon, BigidTagIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { DataComponentProps, HighlightedType } from '../../../../types';
import { getHighlightedValue, removeHtmlTags } from '../../../../utils';
import { TagReason } from './common/TagReason';

export const ColumnsContainer = styled('div')<{ maxWidth?: string }>`
  text-align: center;
  /* max-width: calc(100% - 60px); */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  strong {
    vertical-align: bottom;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const getDataComponent = (fieldName: HighlightedType, searchTerm: string): FC<DataComponentProps> => {
  switch (fieldName) {
    case 'tags': {
      return ({ elementToShow }) => <TagReason elementToProcess={elementToShow} />;
    }

    case 'attributes': {
      return ({ elementToShow }) => {
        const sanitizedValue = removeHtmlTags(elementToShow);

        return (
          <BigidCategoryChip
            categoryColor=""
            highlight={searchTerm}
            categoryName={sanitizedValue}
            tooltipProps={{ title: '', isDisabled: true }}
          />
        );
      };
    }

    case 'category': {
      return ({ elementToShow }) => {
        const sanitizedValue = removeHtmlTags(elementToShow);

        return (
          <BigidCategoryChip
            key={elementToShow}
            categoryColor={BigidColorsV2.purple[500]}
            categoryName={sanitizedValue}
            highlight={searchTerm}
            tooltipProps={{ title: '', isDisabled: true }}
          />
        );
      };
    }

    case 'columns': {
      return ({ elementToShow }) => {
        return (
          <BigidBody1 overflow="hidden">
            <ColumnsContainer>{getHighlightedValue(elementToShow)}</ColumnsContainer>
          </BigidBody1>
        );
      };
    }

    default:
      return (): null => null;
  }
};

export const getIcon = (fieldName: HighlightedType) => {
  switch (fieldName) {
    case 'tags':
      return <BigidTagIcon size="small" />;

    case 'attributes': {
      return <BigidAttributeIcon size="small" />;
    }

    case 'category': {
      return <BigidAnyIcon size="small" />;
    }

    case 'columns': {
      return <BigidColumnsIcon size="small" />;
    }

    default:
      return null;
  }
};

export const getTooltipContent = (fieldName: HighlightedType, highlightedValue: string[]) => {
  switch (fieldName) {
    case 'tags':
      return highlightedValue.map(tag => <TagReason key={tag} elementToProcess={tag} />);
    case 'attributes':
    case 'category':
    case 'columns': {
      return highlightedValue.map(tag => {
        return <BigidBody1 key={tag}>{getHighlightedValue(tag)}</BigidBody1>;
      });
    }
    default:
      return null;
  }
};

export const GeneralShowMoreComponent: FC<BigidChipProps> = props => (
  <BigidChip
    size="small"
    outline="solid"
    outlineColor={BigidColorsV2.gray[150]}
    bgColor={BigidColorsV2.white}
    variant="outlined"
    {...props}
  />
);

export const TagsShowMoreComponent: FC<BigidChipProps> = props => <BigidChip size="small" outline="solid" {...props} />;

export const getShowMoreItemsComponent = (fieldName: HighlightedType): FC<BigidChipProps> => {
  switch (fieldName) {
    case 'tags':
      return props => <TagsShowMoreComponent {...props} />;

    case 'attributes':
    case 'category':
    case 'columns': {
      return props => <GeneralShowMoreComponent {...props} />;
    }

    default:
      return (): null => null;
  }
};
