import React, { FC, useCallback, useEffect, ChangeEvent, useState, useMemo, ReactElement } from 'react';
import {
  BigidBody1,
  BigidLoader,
  BigidPrimaryCheckbox,
  BigidSelect,
  BigidSelectOption,
  BigidSubtitle,
  objectToQueryString,
  PrimaryButton,
  SecondaryButton,
  BigidColors,
  BigidTooltip,
  BigidConditionalWrapper,
  BigidInlineNotification,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import makeStyles from '@mui/styles/makeStyles';
import {
  ColumnPreviewResults,
  deleteColumnPreview,
  getColumnPreview,
  getColumnPreviewFromCache,
} from '../../../../../../../DataCatalog/DataCatalogColumns';
import { notificationService } from '../../../../../../../../services/notificationService';
import { userPreferencesService } from '../../../../../../../../services/userPreferencesService';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { DataCatalogPreviewTableRecord } from '../../../../../../../DataCatalog/DataCatalogTablePreview/DataCatalogTablePreviewService';
import { formatNumber } from '../../../../../../../../utilities/numericDataConverter';
import { AxiosError } from 'axios';

export interface ColumnPreviewProps {
  dataAid?: string;
  preferenceId?: string;
  columnName: string;
  fullyQualifiedName: string;
  fieldType?: string;
}

interface FieldPreviewParameters {
  isDistinct: boolean;
  isNotNullOnly: boolean;
  limit: number;
}

const useStyles = makeStyles({
  root: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    position: 'relative',
  },
  preferencesText: {
    padding: '10px 0px',
  },
  limitOptions: {
    width: '85px',
  },
  previewButton: {
    width: '150px',
  },
  fetchOptions: {
    margin: '20px 0px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${BigidColors.gray[150]}`,
    paddingBottom: '10px',
    alignItems: 'center',
  },
  warning: {
    marginBottom: '24px',
  },
  warningSuggestions: {
    paddingLeft: '1rem',
  },
});

const limitOptions: BigidSelectOption[] = [
  { label: '100', value: 100 },
  { label: '500', value: 500 },
  { label: '1000', value: 1000 },
];

const getLimitOption = (limit: number): BigidSelectOption[] => {
  return [limitOptions.find(({ value }) => value === limit)];
};

export const ColumnPreview: FC<ColumnPreviewProps> = ({
  dataAid = 'ColumnPreview',
  preferenceId = 'DataCatalogColumnPreviewPreferences',
  columnName,
  fullyQualifiedName,
  fieldType,
}) => {
  const classes = useStyles({});

  const [fetchedResults, setFetchedResults] = useState<ColumnPreviewResults[]>(null);
  const [fetchFromCacheAttempt, setFetchFromCacheAttempt] = useState<boolean>(false);

  const [fieldPreviewParameters, setColumnPreviewPreferences] = useState<FieldPreviewParameters>({
    limit: 100,
    isDistinct: false,
    isNotNullOnly: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const isBlobType = fieldType === 'blob';

  const initialiseWidget = useCallback(
    async ({ columnName, fullyQualifiedName, preferenceId }: Omit<ColumnPreviewProps, 'dataAid'>) => {
      try {
        setIsLoading(true);

        const query = objectToQueryString({
          fullyQualifiedName,
          columnName,
        });
        const { data } = await getColumnPreviewFromCache(query);
        const cachedData = data.results;

        if (cachedData.length > 0) {
          setFetchedResults(cachedData);
        }

        const preferences = await userPreferencesService.get<FieldPreviewParameters>(preferenceId);
        if (preferences) {
          setColumnPreviewPreferences(preferences.data);
        }
      } catch (error) {
        handleFetchError(error);
      } finally {
        setIsLoading(false);
        setFetchFromCacheAttempt(true);
      }
    },
    [],
  );

  const fetchColumnPreviewData = useCallback(async () => {
    try {
      setIsLoading(true);

      const payload = {
        fullyQualifiedName,
        columnName,
        ...fieldPreviewParameters,
      };
      const { data } = await getColumnPreview(payload);

      setFetchedResults(data.results);
    } catch (error) {
      handleFetchError(error);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [columnName, fieldPreviewParameters, fullyQualifiedName]);

  const clearColumnPreviewCachedData = useCallback(async () => {
    try {
      setIsLoading(true);

      const query = objectToQueryString({
        fullyQualifiedName,
        columnName,
      });

      await deleteColumnPreview(query);
      notificationService.success('Cache successfully has been cleared');
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
      notificationService.error('An error has occurred');
    } finally {
      setIsLoading(false);
    }
  }, [columnName, fullyQualifiedName]);

  const handleFetchError = ({ message, response }: AxiosError<{ message: string }>): void => {
    const { status, data } = response;
    if ((status === 501 || status === 503) && data) {
      notificationService.error(data.message);
    } else {
      notificationService.error('An error has occurred');
    }
    console.error(`An error has occurred: ${message}`);
  };

  const handleIsNotNullOnlyPreferenceChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setColumnPreviewPreferences(prevPreferences => ({ ...prevPreferences, isNotNullOnly: checked }));
  };

  const handleIsDistinctPreferenceChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setColumnPreviewPreferences(prevPreferences => ({ ...prevPreferences, isDistinct: checked }));
  };

  const handleLimitPreferenceChange = (option: BigidSelectOption[]) => {
    setColumnPreviewPreferences(prevPreferences => ({ ...prevPreferences, limit: option[0].value }));
  };

  const handlePreviewDataClick = useCallback(() => {
    fetchColumnPreviewData();
  }, [fetchColumnPreviewData]);

  const handleClearCacheClick = useCallback(() => {
    clearColumnPreviewCachedData();
  }, [clearColumnPreviewCachedData]);

  useEffect(() => {
    if (columnName && fullyQualifiedName && preferenceId) {
      setFetchFromCacheAttempt(false);
      setFetchedResults(null);
      initialiseWidget({ columnName, fullyQualifiedName, preferenceId });
    }
  }, [columnName, fullyQualifiedName, initialiseWidget, preferenceId]);

  const config: BigidGridWithToolbarProps<DataCatalogPreviewTableRecord> = useMemo(
    () => ({
      columns: [
        {
          name: columnName,
          title: columnName,
          getCellValue: (row: DataCatalogPreviewTableRecord) => row[columnName],
          type: BigidGridColumnTypes.TEXT,
          sortingEnabled: false,
          isNotDraggable: true,
          disableTooltip: true,
        },
      ] as BigidGridColumn<DataCatalogPreviewTableRecord>[],
      hideColumnChooser: true,
      showResizingControls: false,
      toolbarActions: [
        {
          label: 'Clear From Cache',
          isGlobal: true,
          execute: async () => {
            try {
              const query = objectToQueryString({
                fullyQualifiedName,
                columnName,
              });

              await deleteColumnPreview(query);
              setFetchedResults(null);
              notificationService.success('Cache successfully has been cleared');
            } catch ({ message }) {
              console.error(`An error has occurred: ${message}`);
              notificationService.error('An error has occurred');
            } finally {
              return Promise.resolve({ shouldGridReload: false });
            }
          },
          show: () => true,
        },
      ],
      displayActionToolbar: false,
      fetchData: async () => {
        return {
          totalCount: fetchedResults.length,
          data: fetchedResults.map(({ fieldValue }: ColumnPreviewResults, index: number) => {
            return { id: index.toString(), [columnName]: fieldValue };
          }),
        };
      },
    }),
    [columnName, fullyQualifiedName, fetchedResults],
  );

  const isWarningShown =
    fieldPreviewParameters.limit > 100 || fieldPreviewParameters.isNotNullOnly || fieldPreviewParameters.isDistinct;

  return (
    <div className={classes.root} data-aid={dataAid}>
      {isLoading ? (
        <BigidLoader size={28} position="relative" />
      ) : (
        <>
          {fetchFromCacheAttempt && (
            <>
              {fetchedResults ? (
                <>
                  <div className={classes.header}>
                    <BigidBody1 id={`${dataAid}-rows-count`}>{formatNumber(fetchedResults.length)} Rows</BigidBody1>
                    <div>
                      <SecondaryButton
                        onClick={handleClearCacheClick}
                        size="medium"
                        dataAid={`${dataAid}-clear-cache`}
                        text="Clear From Cache"
                      />
                    </div>
                  </div>
                  <BigidGridWithToolbar {...config} />
                </>
              ) : (
                <>
                  <BigidSubtitle className={classes.preferencesText}>
                    Select the number of rows to preview
                  </BigidSubtitle>
                  <div className={classes.limitOptions}>
                    <BigidSelect
                      dataAid={`${dataAid}-rows-to-fetch-options`}
                      menuPlacement={'bottom'}
                      value={getLimitOption(fieldPreviewParameters.limit)}
                      options={limitOptions}
                      onChange={handleLimitPreferenceChange}
                    />
                  </div>
                  <div className={classes.fetchOptions}>
                    <BigidPrimaryCheckbox
                      checked={fieldPreviewParameters.isNotNullOnly}
                      label={<BigidSubtitle>Ignore null and empty values</BigidSubtitle>}
                      onChange={handleIsNotNullOnlyPreferenceChange}
                      size="small"
                      dataAid={`${dataAid}-ignore-falsy-value-checkbox`}
                    />
                    <BigidPrimaryCheckbox
                      checked={fieldPreviewParameters.isDistinct}
                      label={<BigidSubtitle>Show distinct values only</BigidSubtitle>}
                      onChange={handleIsDistinctPreferenceChange}
                      size="small"
                      dataAid={`${dataAid}-show-distinct-value-checkbox`}
                    />
                  </div>
                  {isWarningShown && (
                    <div className={classes.warning} data-aid={generateDataAid(dataAid, ['preview-warning'])}>
                      <BigidInlineNotification
                        type="info"
                        text={[
                          {
                            subText: (
                              <div>
                                <BigidBody1 data-aid={generateDataAid(dataAid, ['preview-warning', 'text'])}>
                                  If preview fails, try the following:
                                </BigidBody1>
                                <ul
                                  className={classes.warningSuggestions}
                                  data-aid={generateDataAid(dataAid, ['preview-warning', 'suggestions'])}
                                >
                                  <li>Reduce the number of rows</li>
                                  <li>Uncheck &quot;Ignore null and empty values&quot;</li>
                                  <li>Uncheck &quot;Show distinct values only&quot;</li>
                                </ul>
                              </div>
                            ),
                          },
                        ]}
                        open
                      />
                    </div>
                  )}
                  <BigidConditionalWrapper
                    condition={isBlobType}
                    wrapper={(children: ReactElement) => (
                      <BigidTooltip title={'Preview of this column type is not supported'}>{children}</BigidTooltip>
                    )}
                  >
                    <div className={classes.previewButton}>
                      <PrimaryButton
                        onClick={handlePreviewDataClick}
                        size="medium"
                        dataAid={`${dataAid}-preview-data-button`}
                        disabled={isBlobType}
                        text="Preview Data"
                      />
                    </div>
                  </BigidConditionalWrapper>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
