import { RefObject, useEffect, useRef, useState } from 'react';

export const useInputFocus = (anchorEl: RefObject<HTMLDivElement>) => {
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handleFocus = () => {
      setIsFocused(true);
    };

    const inputElement = anchorEl.current?.querySelector('input');
    inputElement?.addEventListener('focus', handleFocus);

    inputRef.current = inputElement;

    return () => {
      inputElement?.removeEventListener('focus', handleFocus);
    };
  }, [anchorEl]);

  const onClickOutsideHandleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorEl.current && anchorEl.current.contains(event.target as Node)) {
      return;
    }
    setIsFocused(false);
  };

  const handleChildClick = () => {
    setOpen(false);
    setIsFocused(false);
  };

  return { open, onClickOutsideHandleClose, handleChildClick, setOpen, isFocused };
};
