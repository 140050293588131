import React from 'react';
import { orderBy } from 'lodash';
import { BigidBody1, BigidLink, BigidWidgetContainer } from '@bigid-ui/components';
import { BigidDataFrameworksIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../../translations';
import { DescriptionWrapper, Content } from '../WidgetsStyles';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { generateDataAid } from '@bigid-ui/utils';
import { Carousel } from '../../../../../components/Carousel/Carousel';
import { CarouselItemsList } from './CarouselItemsList';

interface FailedControlsWidgetProps {
  data: any[];
  dataAid: string;
}

export const FailedControlsWidget = ({ data, dataAid }: FailedControlsWidgetProps) => {
  const { t } = useLocalTranslation('compliance.widgets');
  const preparedData = data.map(element => ({
    ...element,
    progressValue: (element.failedControlsCount / element.totalControls) * 100,
  }));
  const sortedDataByField = orderBy(preparedData, 'failedControlsCount', 'desc');

  const handleRedirect = (value: string) => {
    $state.go(value);
  };

  return (
    <BigidWidgetContainer
      dataAid={dataAid}
      titleConfig={{
        title: t('failedControls.title'),
        subtitle: (
          <DescriptionWrapper>
            <BigidBody1 fontSize={12}>{t('failedControls.compoundText.part1')}</BigidBody1>
            <BigidLink
              data-aid={generateDataAid(dataAid, ['emptyState', 'policiesLink'])}
              text={t('failedControls.compoundText.part2')}
              onClick={() => handleRedirect(CONFIG.states.POLICIES)}
              underline="hover"
            />
          </DescriptionWrapper>
        ),
      }}
      content={
        <Content>
          <Carousel data={sortedDataByField} itemsToShow={5}>
            <CarouselItemsList data={sortedDataByField} itemsToShow={5} />
          </Carousel>
        </Content>
      }
      contentHeight="143px"
      emptyStateConfig={{
        isEmpty: data.length === 0,
        illustration: BigidDataFrameworksIllustration,
        illustrationSize: 'small',
        description: (
          <DescriptionWrapper>
            <BigidBody1>{t('emptyState.compoundText.part1')}</BigidBody1>
            <BigidLink
              data-aid={generateDataAid(dataAid, ['emptyState', 'frameworkslink'])}
              text={t('emptyState.compoundText.part2')}
              onClick={() => handleRedirect(CONFIG.states.FRAMEWORKS)}
              underline="hover"
            />
            <BigidBody1>{t('emptyState.compoundText.part3')}</BigidBody1>
          </DescriptionWrapper>
        ),
      }}
    />
  );
};
