import React, { ChangeEvent, FC, useCallback, useEffect, useReducer, useState } from 'react';
import { InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidBody2,
  BigidCheckbox,
  BigidPaper,
  BigidSwitch,
  BigidTextField,
  PrimaryButton,
  SecondaryButton,
  BigidTooltip,
} from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $state, $stateParams } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import {
  checkIfQueryContainsScTag,
  checkIfQueryValid,
  ClassificationLevelButtonsStatus,
  ClassificationLevelField,
} from './ClassificationLevelField';
import { ClassificationLevel, SensitivityClassificationData } from './SensitivityClassification';
import { BigidAddIcon, BigidWarningFilledIcon } from '@bigid-ui/icons';
import { v4 as uuid } from 'uuid';
import {
  applySensitivityClassification,
  createSensitivityClassification,
  getSensitivityClassification,
  updateSensitivityClassification,
} from './SensitivityClassificationService';
import { notificationService } from '../../services/notificationService';
import {
  changeLevel,
  changeLevelPriority,
  createEmptySc,
  createNewLevel,
  deleteLevel,
  duplicateLevel,
  isNameValid,
  MIN_PRIORITY,
  MAX_PRIORITY,
} from './classificationLevelsUtils';
import { ErrorField, formErrorsReducer } from './formErrorReducer';
import { SensitivityClassificationsContext } from './SensitivityClassificationsContext';
import { showConfirmationDialog } from '../../services/confirmationDialogService';
import { QueryType } from './QuerySelector';
import { getApplicationPreference } from '../../services/appPreferencesService';

const SENSITIVITY_FOR_COLUMN_WARNING_MESSAGE =
  'Note that applying for columns will cause the sensitivity classification be applicable only for structured data sources';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '95%',
  },
  scrollContainer: {
    overflow: 'scroll',
    height: '100%',
    padding: 40,
  },
  formContainer: {
    width: '760px',
  },
  levels: {
    display: 'flex',
    flexDirection: 'column',
  },
  levelsLabel: {
    marginBottom: 10,
  },
  levelsLabelAsterisk: {
    color: '#FF805A',
  },
  formField: {
    marginBottom: 15,
  },
  levelsHelperText: {
    marginBottom: 10,
  },
  addButtonWrapper: {
    maxWidth: 45,
  },
  objectPropagationCheckboxContainer: {
    marginLeft: 15,
  },
  sensitivityForColumnWarningMessage: {
    marginLeft: 3,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  sensitivityForColumnWarningIcon: {
    marginRight: 3,
  },
});

const NEW_FORM_TITLE = 'New Sensitivity Classification';
const EDIT_FORM_TITLE = 'Edit Sensitivity Classification';

export enum PriorityChange {
  Increase,
  Decrease,
}

const isColumnSwitchDisabled = (classification: ClassificationLevel[]) => {
  return classification?.some(classification => classification.queryType === QueryType.String);
};

const getColumnSwitchTooltipMessage = (classifications: ClassificationLevel[]) => {
  const allLevelNamesWithQueryString = classifications
    ?.filter(level => level.queryType === QueryType.String)
    ?.map(levelWithQueryString => levelWithQueryString.priority + 1);
  if (allLevelNamesWithQueryString?.length !== 0) {
    return `One or more levels are using query string, Sensitivity classification with query strings cannot be applicable on columns. Please check levels: ${JSON.stringify(
      allLevelNamesWithQueryString,
    )}`;
  }
  return '';
};

export const SensitivityClassificationForm: FC = () => {
  const classes = useStyles({});
  const { id } = $stateParams;
  const [sensitivityClassification, setSensitivityClassification] = useState<SensitivityClassificationData>(null);
  const [errorState, dispatch] = useReducer(formErrorsReducer, { classifications: {} });
  const classifications = sensitivityClassification?.classifications;
  const isEventBasedScEnabled: boolean = getApplicationPreference('EVENT_BASED_SENSITIVITY_CLASSIFICATION');
  const isSensitivityV2: boolean = getApplicationPreference('SENSITIVITY_CLASSIFICATION_V2_ENABLED');

  useEffect(() => {
    const fetchSc = async (id: string) => {
      try {
        const sc = processSensitivityClassificationRes(await getSensitivityClassification(id));
        setSensitivityClassification(sc);
      } catch ({
        response: {
          data: { message },
        },
      }) {
        console.error(`An error has occurred: ${message}`);
        notificationService.error(`An error has occurred: ${message}`);
      }
    };

    if (id) {
      fetchSc(id);
    } else {
      setSensitivityClassification(createEmptySc());
    }
  }, [id]);

  const validateForm = () => {
    let result = true;
    if (!isNameValid(sensitivityClassification.name)) {
      dispatch({ type: ErrorField.NAME, payload: { name: 'Invalid name' } });
      result = false;
    }
    if (!sensitivityClassification.name) {
      dispatch({ type: ErrorField.NAME, payload: { name: 'Name is required' } });
      result = false;
    }
    const classificationResult = validateClassifications(classifications);
    return result && classificationResult;
  };

  const validateClassifications = (classifications: ClassificationLevel[]) => {
    let result = true;
    classifications.forEach(classification => {
      const { name, id, queryString, queryObject } = classification;
      if (classifications.filter(level => level.id !== id).some(level => level.name === name)) {
        dispatch({
          type: ErrorField.CLASSIFICATION,
          payload: { id: id, name: 'Level name must be unique' },
        });
        result = false;
      }
      if (!isNameValid(name)) {
        dispatch({
          type: ErrorField.CLASSIFICATION,
          payload: { id: id, name: 'Invalid Level Name' },
        });
        result = false;
      }
      if (!name) {
        dispatch({
          type: ErrorField.CLASSIFICATION,
          payload: { id: id, name: 'All levels must have a name and a query defined' },
        });
        result = false;
      }
      if (!queryString || JSON.stringify(queryObject).includes(`""`)) {
        dispatch({
          type: ErrorField.CLASSIFICATION,
          payload: { id: id, emptyQuery: 'Query is empty' },
        });
        classification.queryExpanded = true;
        classification.isQueryTouched = true;
        result = false;
      }
      if (!checkIfQueryValid(queryObject, queryString)) {
        dispatch({
          type: ErrorField.CLASSIFICATION,
          payload: { id: id, emptyQuery: 'Query is not valid' },
        });
        classification.queryExpanded = true;
        classification.isQueryTouched = true;
        result = false;
      } else if (checkIfQueryContainsScTag(queryString)) {
        dispatch({
          type: ErrorField.CLASSIFICATION,
          payload: { id: id, containsScQuery: 'Query contains Sensitivity Classification tag' },
        });
        classification.queryExpanded = true;
        classification.isQueryTouched = true;
        result = false;
      }
    });
    setSensitivityClassification({ ...sensitivityClassification, classifications });
    return result;
  };

  const setLevel = (level: ClassificationLevel) => {
    setSensitivityClassification({
      ...sensitivityClassification,
      classifications: changeLevel(level, classifications),
    });
  };

  const getLevelButtonsStatus = (level: ClassificationLevel): ClassificationLevelButtonsStatus => {
    return {
      isPriorityUpDisabled: level.priority === MAX_PRIORITY || classifications.length === 1,
      isPriorityDownDisabled: level.priority === MIN_PRIORITY || classifications.length === 1,
      isDuplicateDisabled: classifications.length === MAX_PRIORITY,
      isDeleteDisabled: classifications.length === 1,
    };
  };

  const handleLevelDelete = (level: ClassificationLevel) => {
    if (classifications.length === 1) {
      return;
    }
    setSensitivityClassification({
      ...sensitivityClassification,
      classifications: deleteLevel(level, classifications),
    });
  };

  const handleLevelPriorityChange = (currentPriority: number, direction: PriorityChange) => {
    if (
      (direction === PriorityChange.Increase && currentPriority === MAX_PRIORITY) ||
      (direction === PriorityChange.Decrease &&
        (currentPriority === MIN_PRIORITY || currentPriority === classifications.length - 1))
    ) {
      return;
    }
    setSensitivityClassification({
      ...sensitivityClassification,
      classifications: changeLevelPriority(currentPriority, direction, classifications),
    });
  };

  const handleLevelDuplicate = (level: ClassificationLevel) => {
    if (classifications.length - 1 >= MIN_PRIORITY) {
      notificationService.warning(`Number of sensitivity classification levels is limited to ${MIN_PRIORITY + 1}`);
      return;
    }
    setSensitivityClassification({
      ...sensitivityClassification,
      classifications: duplicateLevel(level, classifications),
    });
  };

  const handleNewLevel = () => {
    if (classifications.length - 1 === MIN_PRIORITY) {
      notificationService.warning(`Number of sensitivity classification levels is limited to ${MIN_PRIORITY + 1}`);
      return;
    }
    setSensitivityClassification({
      ...sensitivityClassification,
      classifications: createNewLevel(classifications),
    });
  };

  const handleFormClose = async () => {
    const isExitConfirmed = await showConfirmationDialog({
      entityNameSingular: 'Form',
      actionName: 'Exit',
      actionButtonName: 'Exit',
    });
    if (isExitConfirmed) {
      $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION);
    }
    $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION);
  };
  const handleDescriptionChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setSensitivityClassification({ ...sensitivityClassification, description: target.value });
  };

  const handleNameChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    dispatch({ type: ErrorField.NAME, payload: { name: null } });
    const newValue = target?.value?.replace(/\s{2,}/g, ' ');
    setSensitivityClassification({ ...sensitivityClassification, name: newValue });
  };

  const handleDsTaggingChange = ({ target }: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setSensitivityClassification({ ...sensitivityClassification, dsTagging: checked });
  };

  const handleColumnTaggingChange = ({ target }: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setSensitivityClassification({
      ...sensitivityClassification,
      dsTagging: false,
      columnTagging: { ...sensitivityClassification.columnTagging, isActive: checked },
    });
  };

  const handlePropagateToTableChange = ({ target }: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setSensitivityClassification({
      ...sensitivityClassification,
      dsTagging: !checked && false,
      columnTagging: { ...sensitivityClassification.columnTagging, shouldPropagateToObject: checked },
    });
  };

  const processSensitivityClassificationRes = (sc: SensitivityClassificationData) => {
    sc.classifications.forEach(classification => {
      classification.queryExpanded = false;
      classification.id = classification.levelId || uuid();
      if (classification.queryObj) {
        classification.queryType = QueryType.Object;
        classification.queryObject = classification.queryObj;
      } else {
        classification.queryType = QueryType.String;
        classification.queryObject = null;
      }
      classification.queryString = classification.query;
      classification.isQueryTouched = true;
    });
    return sc;
  };

  const handleFormSave = useCallback(async () => {
    let isSaveConfirmed;
    if (isSensitivityV2) {
      isSaveConfirmed = await showConfirmationDialog({
        entityNameSingular: 'Configuration',
        actionName: 'Save',
        actionButtonName: 'Continue',
        customDescription:
          'Saving the new configuration could lead to the reclassification of all your data sources. Are you sure you want to continue?',
      });
    }
    if (isSaveConfirmed || !isSensitivityV2) {
      try {
        const isFormValid = validateForm();
        if (isFormValid) {
          if (id) {
            const updatedSc = processSensitivityClassificationRes(
              await updateSensitivityClassification(sensitivityClassification, id),
            );
            setSensitivityClassification(updatedSc);
          } else {
            const sc = await createSensitivityClassification(sensitivityClassification);
            $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION_EDIT, { id: sc._id });
          }
          notificationService.success('Sensitivity classification configuration saved');
        }
      } catch ({
        response: {
          data: { message },
        },
      }) {
        console.error(`An error has occurred: ${message}`);
        notificationService.error(`An error has occurred: ${message}`);
      }
    }
  }, [id, isSensitivityV2, sensitivityClassification, validateForm]);

  const handleApply = async () => {
    try {
      await applySensitivityClassification(id);
      notificationService.success('Apply sensitivity classification - Started tagging ');
      $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION);
    } catch ({
      response: {
        data: { message },
      },
    }) {
      console.error(`An error has occurred: ${message}`);
      notificationService.error(`An error has occurred: ${message}`);
    }
  };

  useEffect(() => {
    pageHeaderService.setTitle({
      rightSideComponentsContainer: (
        <div>
          <SecondaryButton
            margin={'0px 5px 0px 0px'}
            onClick={handleFormClose}
            size="large"
            dataAid={'form-close-button'}
            text="Close"
          />
          {!sensitivityClassification?.columnTagging?.isActive && !isSensitivityV2 && (
            <SecondaryButton
              margin={'0px 5px 0px 0px'}
              disabled={!id}
              onClick={handleApply}
              size="large"
              dataAid={'form-apply-button'}
              text="Apply Now"
            />
          )}
          <PrimaryButton dataAid={'form-save-button'} onClick={handleFormSave} size="large" text="Save" />
        </div>
      ),
      breadcrumbs: [
        {
          label: 'Sensitivity Classification',
          onClick: () => $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION),
        },
        { label: id ? EDIT_FORM_TITLE : NEW_FORM_TITLE },
      ],
    });
  }, [handleApply, handleFormSave, id, isSensitivityV2, sensitivityClassification?.columnTagging?.isActive]);

  return (
    <SensitivityClassificationsContext.Provider
      value={{
        sc: sensitivityClassification,
        setLevel,
        errorState,
        onDeleteLevel: handleLevelDelete,
        onPriorityChangeLevel: handleLevelPriorityChange,
        onDuplicateLevel: handleLevelDuplicate,
        dispatchError: dispatch,
      }}
    >
      <BigidPaper classes={{ root: classes.root }}>
        <div className={classes.scrollContainer}>
          <div className={classes.formContainer}>
            <div className={classes.formField}>
              {isEventBasedScEnabled && !isSensitivityV2 && (
                <BigidTooltip title={getColumnSwitchTooltipMessage(classifications)}>
                  <div>
                    <BigidSwitch
                      rightLabel={'Apply for table columns'}
                      leftLabel={'Apply for objects'}
                      disabled={isColumnSwitchDisabled(sensitivityClassification?.classifications)}
                      checked={sensitivityClassification?.columnTagging?.isActive}
                      onChange={handleColumnTaggingChange}
                    />
                  </div>
                </BigidTooltip>
              )}
              {isSensitivityV2 && (
                <BigidCheckbox
                  label={'Apply for table columns'}
                  checked={sensitivityClassification?.columnTagging?.isActive}
                  onChange={handleColumnTaggingChange}
                />
              )}
              {sensitivityClassification?.columnTagging?.isActive && !isSensitivityV2 && (
                <div className={classes.sensitivityForColumnWarningMessage}>
                  <BigidWarningFilledIcon className={classes.sensitivityForColumnWarningIcon} color="warning" />
                  <BigidBody2>{SENSITIVITY_FOR_COLUMN_WARNING_MESSAGE} </BigidBody2>
                </div>
              )}
              {sensitivityClassification?.columnTagging?.isActive && (
                <BigidCheckbox
                  label={'Propagate Sensitivity Classification to tables'}
                  checked={sensitivityClassification?.columnTagging?.shouldPropagateToObject}
                  onChange={handlePropagateToTableChange}
                />
              )}
              <BigidCheckbox
                label={'Propagate Sensitivity Classification to data sources'}
                checked={sensitivityClassification?.dsTagging}
                onChange={handleDsTaggingChange}
                disabled={
                  sensitivityClassification?.columnTagging?.isActive &&
                  !sensitivityClassification.columnTagging?.shouldPropagateToObject
                }
              />
            </div>
            <div className={classes.formField}>
              <BigidTextField
                disabled={sensitivityClassification?.defaultSc}
                errorMessage={errorState.name?.name}
                onChange={handleNameChange}
                required
                label={'Classification Name'}
                value={sensitivityClassification?.name}
                placeholder="Name"
              />
            </div>
            <div className={classes.formField}>
              <BigidTextField
                multiline
                rows={3}
                onChange={handleDescriptionChange}
                label={'Description'}
                value={sensitivityClassification?.description}
                placeholder="Description"
                type="text"
              />
            </div>
            <div className={classes.levels}>
              <InputLabel classes={{ asterisk: classes.levelsLabelAsterisk, root: classes.levelsLabel }} required>
                Levels
              </InputLabel>
              <BigidBody2 className={classes.levelsHelperText}>
                Arrange the levels so that the first is the highest in the hierarchy
              </BigidBody2>
              {classifications?.map(classification => (
                <ClassificationLevelField
                  key={classification.id}
                  level={classification}
                  buttonsStatus={getLevelButtonsStatus(classification)}
                />
              ))}
              <div className={classes.addButtonWrapper}>
                <SecondaryButton
                  dataAid={'create-level-button'}
                  text="Add level"
                  Icon={BigidAddIcon}
                  onClick={handleNewLevel}
                  size="medium"
                />
              </div>
            </div>
          </div>
        </div>
      </BigidPaper>
    </SensitivityClassificationsContext.Provider>
  );
};
