import React from 'react';
import styled from '@emotion/styled';
import { BigidBody1 } from '@bigid-ui/components';
import { BigidChevronRightIcon } from '@bigid-ui/icons';
import { useTheme } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { ContentView, getPreselectedControlsLength, NormalizedMappedControlsProps } from '../../../utils';
import { FrameworkProps } from '../../../../../../react/views/Frameworks/FrameworksServices';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  padding: 12px;
`;

const DecriptionWrapper = styled.div`
  display: flex;
  width: 90%;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const DecriptionText = styled.div`
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ListItem = styled.div(({ theme }) => ({
  height: '48px',
  width: '100%',
  borderRadius: '6px',
  border: `1px solid ${theme.palette.bigid.gray200}`,
  padding: '0 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
}));

interface MappedControlsDialogListViewProps {
  frameworksList: FrameworkProps[];
  handleContentView: (value: ContentView) => void;
  handleCategory: (value: string) => void;
  mappedControls: NormalizedMappedControlsProps[];
  dataAid: string;
}

export const MappedControlsDialogListView = ({
  frameworksList,
  handleContentView,
  handleCategory,
  mappedControls,
  dataAid,
}: MappedControlsDialogListViewProps) => {
  const Theme = useTheme();
  return (
    <Main data-aid={generateDataAid(dataAid, ['view', 'list'])}>
      {frameworksList.map((control: FrameworkProps) => {
        const preselectedItems = getPreselectedControlsLength(mappedControls, control);

        return (
          <ListItem
            key={control.category}
            data-aid={generateDataAid(dataAid, ['category', control.category])}
            onClick={() => {
              handleContentView(ContentView.GRID);
              handleCategory(control.category as string);
            }}
          >
            <DecriptionWrapper>
              <DecriptionText>
                <BigidBody1 size="medium" fontWeight={600}>
                  {control.category}
                </BigidBody1>
              </DecriptionText>
              <BigidBody1 size="small" color={Theme.palette.bigid.gray500}>
                {`${preselectedItems} / ${control?.list?.length}`}
              </BigidBody1>
            </DecriptionWrapper>
            <BigidChevronRightIcon />
          </ListItem>
        );
      })}
    </Main>
  );
};
