import { useCallback, useEffect, useRef } from 'react';
import { SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../../services/sseService';
import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { sessionStorageService } from '../../../../../common/services/sessionStorageService';
import { notificationService } from '../../../../services/notificationService';
import { ACTION_SUFFIX, IMPORT_PREFIX, TEST_CONNECTION_PREFIX } from '../DataSourceConnectionTypes';

const calcProcessedRecords = (totalRecords: number, processedRecords: number, processedChunks: number) =>
  processedRecords + Math.min(totalRecords - processedRecords, Math.round(processedRecords / processedChunks));

type UseDataSourceImportAndTestOpts = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useDataSourceImportAndTest = (opts?: UseDataSourceImportAndTestOpts) => {
  const { onSuccess, onError } = opts ?? {};
  const unregisterImportHandler = useRef(null);
  const unregisterTestConnectionsHandler = useRef(null);

  const onBroadCastEventFormImport = useCallback(({ results }: SSEDataMessage) => {
    const hasProgress = !!results?.[0]?.progress;
    const { isFinished, processedRecords, totalRecords, processedChunks } = results?.[0]?.progress || {};
    const dsImportRes = results?.[0]?.results || [];

    if (isFinished) {
      const failedDs = dsImportRes.filter((ds: { isTestConnectionArchiveError: boolean }) => {
        if (ds?.isTestConnectionArchiveError === true) return ds;
      }).length;
      sessionStorageService.set('uniqueBroadcastEventForImportDataSource', '');
      let importMessage = `Data Sources imported successfully! Imported ${totalRecords} Data Sources`;
      if (failedDs > 0) {
        importMessage = `${importMessage}, of which ${failedDs} records are archived and not tested.`;
      }
      notificationService.success(importMessage);
      unregisterImportHandler.current?.();
      onSuccess();
    } else if (hasProgress) {
      const importedRecords = calcProcessedRecords(totalRecords, processedRecords, processedChunks);
      notificationService.success(`${IMPORT_PREFIX} ${importedRecords}/${totalRecords} imported`);
    } else {
      notificationService.success(`${IMPORT_PREFIX} ${ACTION_SUFFIX}`);
    }
  }, []);

  const onBroadCastEventFormTestConnection = useCallback(({ results }: SSEDataMessage) => {
    const hasProgress = !!results?.[0]?.progress;
    const {
      isFinished,
      processedRecords,
      totalRecords,
      processedChunks,
      processedRecordsWithSuccess,
      processedRecordsWithErrors,
    } = results?.[0]?.progress || {};

    if (isFinished) {
      sessionStorageService.set('uniqueBroadcastEventForTestDataSource', '');
      const message = `Data Sources test connection completed. ${processedRecordsWithSuccess} tested successfully, and ${processedRecordsWithErrors} failed.`;

      const hasErrors = processedRecordsWithErrors > 0;
      hasErrors ? notificationService.warning(message) : notificationService.success(message);
      hasErrors ? onError?.() : onSuccess?.();
      unregisterImportHandler.current?.();
    } else if (hasProgress) {
      const tested = calcProcessedRecords(totalRecords, processedRecords, processedChunks);
      notificationService.success(`${TEST_CONNECTION_PREFIX} ${tested}/${totalRecords} tested`);
    } else {
      notificationService.success(`${TEST_CONNECTION_PREFIX} ${ACTION_SUFFIX}`);
    }
  }, []);

  useEffect(() => {
    const uniqueBroadcastEventForImport: any = sessionStorageService.get('uniqueBroadcastEventForImportDataSource');
    const uniqueBroadcastEventForTest: any = sessionStorageService.get('uniqueBroadcastEventForTestDataSource');

    if (uniqueBroadcastEventForImport) {
      unregisterImportHandler.current = subscribeToRepeatedSSEEventById(
        uniqueBroadcastEventForImport,
        onBroadCastEventFormImport,
      );
    }

    if (uniqueBroadcastEventForTest) {
      unregisterTestConnectionsHandler.current = subscribeToRepeatedSSEEventById(
        uniqueBroadcastEventForTest,
        onBroadCastEventFormTestConnection,
      );
    }

    return () => {
      unregisterImportHandler.current?.();
      unregisterTestConnectionsHandler.current?.();
    };
  }, []);

  return {
    unregisterImportHandler,
    onBroadCastEventFormImport,
    onBroadCastEventFormTestConnection,
  };
};
