import React, { FC, useState } from 'react';
import { styled } from '@mui/material';
import {
  BigidBody1,
  BigidDialog,
  BigidDropdown,
  BigidDropdownOption,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { convertUserNameToString, UserModel } from './RiskRegisterService';
import { SystemUser } from '../AccessManagement/types';
import { useLocalTranslation } from './translations';

const DialogContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export interface AssignUserDialogProps {
  isOpen: boolean;
  assignedUser: UserModel;
  onDialogClose: () => void;
  onAssignButtonClickHandler: (user: UserModel) => void;
  users: SystemUser[];
}

export const AssignUserDialog: FC<AssignUserDialogProps> = ({
  isOpen,
  onDialogClose,
  onAssignButtonClickHandler,
  assignedUser,
  users,
}) => {
  const [selectedUser, setSelectedUser] = useState<BigidDropdownOption[]>([
    {
      id: assignedUser.email,
      displayValue: `${assignedUser.name} (${assignedUser.email})`,
      value: assignedUser.email,
    },
  ]);
  const { t } = useLocalTranslation('AssignUserDialog');

  return (
    <BigidDialog
      isOpen={isOpen}
      title={t('Labels.assignUser')}
      onClose={onDialogClose}
      buttons={[
        {
          onClick: onDialogClose,
          component: SecondaryButton,
          text: t('Labels.cancel'),
          size: 'medium',
          alignment: 'right',
        },
        {
          onClick: () =>
            onAssignButtonClickHandler({ email: selectedUser[0].value, name: selectedUser[0].displayValue }),
          component: PrimaryButton,
          text: t('Labels.assign'),
          disabled: selectedUser[0]?.value === assignedUser.email,
          size: 'medium',
          alignment: 'right',
        },
      ]}
    >
      <DialogContent>
        <BigidBody1 size="medium">{t('Content.responsibleUserText')}</BigidBody1>
        <BigidDropdown
          options={users.map(user => {
            return {
              id: user.name,
              displayValue: convertUserNameToString(user.name, user.firstName, user.lastName),
              value: user.name,
            };
          })}
          value={[
            {
              id: assignedUser.email,
              displayValue: `${assignedUser.name} (${assignedUser.email})`,
              value: assignedUser.email,
            },
          ]}
          onSelect={(value: BigidDropdownOption[]) => {
            setSelectedUser(value);
          }}
        />
      </DialogContent>
    </BigidDialog>
  );
};
