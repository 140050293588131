import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  fetchFrameworksControls,
  FrameworkProps,
  putMappedControls,
} from '../../../../../react/views/Frameworks/FrameworksServices';
import { notificationService } from '../../../../../react/services/notificationService';
import { formatFrameworkControls } from '../../../../../react/views/Frameworks/utils';
import { MappedControlsDialogHeader } from './MappedControlsDialogHeader';
import { BigidLoader } from '@bigid-ui/components';
import { MappedControlsDialogListView } from './MappedControlsDialogViews/MappedControlsDialogListView';
import { MappedControlsDialogGridView } from './MappedControlsDialogViews/MappedControlsDialogGridView';
import { MappedControlsDialogFooter } from './MappedControlsDialogFooter';
import {
  ContentWrapperProps,
  getNormilizedMappedSelection,
  ContentView,
  MappedControlRowProps,
  NormalizedMappedControlsProps,
  getPreselectedControlsIds,
} from '../../utils';
import { useLocalTranslation } from '../../translations';
import { generateDataAid } from '@bigid-ui/utils';

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ContentWrapper = styled.div<ContentWrapperProps>(({ isVisible = true }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '72px',
  bottom: isVisible ? '72px' : 0,
}));

interface MappedControlsDialogContentProps {
  row: MappedControlRowProps;
  dynamicMappedControls: NormalizedMappedControlsProps[];
  staticMappedControls: NormalizedMappedControlsProps[];
  policyFqdn: string;
  isContentLoading: boolean;
  handleDialogVisibility: (value: boolean) => void;
  fetchMappedControls: () => void;
  handleResetDialog: (value: boolean, frameworkName?: string) => void;
  handleDynamicControls: (dynamicControls: NormalizedMappedControlsProps[]) => void;
  handleContentLoading: (value: boolean) => void;
  dataAid: string;
}

export const MappedControlsDialogContent = ({
  row,
  dynamicMappedControls,
  staticMappedControls,
  policyFqdn,
  isContentLoading,
  handleDialogVisibility,
  fetchMappedControls,
  handleResetDialog,
  handleDynamicControls,
  handleContentLoading,
  dataAid,
}: MappedControlsDialogContentProps) => {
  const [frameworksList, setFrameworksList] = useState<FrameworkProps[]>([]);
  const [currentView, setIsCurrentView] = useState(ContentView.LIST);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const { t } = useLocalTranslation('messages');

  const preSelectedIds = getPreselectedControlsIds(dynamicMappedControls, row, selectedCategory);

  const handleContentView = useCallback((value: ContentView) => {
    setIsCurrentView(value);
  }, []);

  const handleCategory = (value: string) => {
    setSelectedCategory(value);
  };

  const handlePutMappedControls = async () => {
    handleContentLoading(true);
    const body = getNormilizedMappedSelection(dynamicMappedControls, staticMappedControls);
    try {
      {
        if (body.controlsToDisable.length > 0 || body.controlsToEnable.length > 0) {
          await putMappedControls(policyFqdn, body);
          await fetchMappedControls();
          notificationService.success(t('controlsUpdateSuccess'), { duration: 3000 });
        }
      }
    } catch {
      notificationService.error(t('controlsUpdateError'), { duration: 3000 });
    } finally {
      handleContentLoading(false);
      handleDialogVisibility(false);
    }
  };

  const getAllControls = useCallback(async () => {
    handleContentLoading(true);
    try {
      const frameworkControls = await fetchFrameworksControls(row.framework_name);
      const { data } = formatFrameworkControls(frameworkControls, dynamicMappedControls, row.framework_name);
      setIsCurrentView(ContentView.LIST);
      setFrameworksList(data);
    } catch {
      notificationService.error(t('controlsGetError'), { duration: 3000 });
    } finally {
      handleContentLoading(false);
    }
  }, [row]);

  useEffect(() => {
    if (row) {
      getAllControls();
    }
  }, [getAllControls]);

  return (
    <MainContent data-aid={generateDataAid(dataAid, ['content'])}>
      <MappedControlsDialogHeader
        selectedItem={row}
        currentView={currentView}
        selectedCategory={selectedCategory}
        handleContentView={handleContentView}
        handleResetDialog={handleResetDialog}
      />
      <ContentWrapper>
        <Content>
          {isContentLoading ? (
            <LoaderWrapper>
              <BigidLoader />
            </LoaderWrapper>
          ) : currentView === ContentView.LIST ? (
            <MappedControlsDialogListView
              frameworksList={frameworksList}
              handleContentView={handleContentView}
              handleCategory={handleCategory}
              mappedControls={dynamicMappedControls}
              dataAid={dataAid}
            />
          ) : (
            <MappedControlsDialogGridView
              frameworksList={frameworksList}
              selectedCategory={selectedCategory}
              handleDynamicControls={handleDynamicControls}
              dataAid={dataAid}
              frameworkName={row.framework_name}
              preSelectedIds={preSelectedIds}
              dynamicMappedControls={dynamicMappedControls}
            />
          )}
        </Content>
      </ContentWrapper>
      <MappedControlsDialogFooter
        handleDialogVisibility={handleDialogVisibility}
        handlePutMappedControls={handlePutMappedControls}
      />
    </MainContent>
  );
};
