import {
  Iso,
  Ccpa,
  Nist,
  Saif,
  Owasp,
  Dasf,
  ScfGdrp,
  Ofdss,
  Dsmm,
  Soc,
  Fedramp,
  Microsoft,
  Csa,
  BsiC5,
  Irap,
  SpanishEns,
  PciDss,
  Hipaa,
  NydFs,
  Cdmc,
  Generic2,
} from '@bigid-ui/icons';
import { uniqBy } from 'lodash';
import { ControlsProps, FrameworkProps, FrameworkUpdatedProps, IconsShcemaProps } from './FrameworksServices';
import { MappedControlsProps, NormalizedMappedControlsProps } from '../../../administration/policies/Widgets/utils';

const formatSortingPart = (value: string) => {
  const listOfStrings = value.split(' ');
  const firstPartIndex = listOfStrings[0]?.replace('-', '.');
  const secondPartIndex = listOfStrings[1]?.match(/\((.*?)\)/);
  if (/\d+/g.test(firstPartIndex)) {
    const firstWordTextMatch = firstPartIndex?.match(/[A-Za-z]/g) || [];
    const firstWordNumberMatch = firstPartIndex?.match(/(\d+)?/g) || [];
    const firstWordText = firstWordTextMatch?.join('');
    const firstNumberText = firstWordNumberMatch?.join('.');
    const resultString = `${firstWordText ? firstWordText + '-' : ''}${firstNumberText}${
      secondPartIndex ? '.' + secondPartIndex[1] : '.0'
    }`;
    return resultString;
  } else {
    return value;
  }
};

export function comparator(field: string) {
  return function (a: any, b: any) {
    const formattedValueA = formatSortingPart(a[field]);
    const formattedValueB = formatSortingPart(b[field]);
    const partsA = formattedValueA.split('.');
    const partsB = formattedValueB.split('.');
    const nbParts = Math.max(partsA.length, partsB.length);
    for (let i = 0; i < nbParts; ++i) {
      partsA[i] = partsA[i] ?? '0';
      partsB[i] = partsB[i] ?? '0';

      const intA = parseInt(partsA[i], 10);
      const intB = parseInt(partsB[i], 10);
      if (!isNaN(intA) && !isNaN(intB)) {
        if (intA > intB) {
          return 1;
        } else if (intA < intB) {
          return -1;
        }
      }

      const compare = partsA[i].localeCompare(partsB[i]);
      if (compare !== 0) {
        return compare;
      }
    }

    return 0;
  };
}

const calculationgAmountOfControls = (data: FrameworkProps[]) => {
  let amountOfControls = 0;
  data.map((item: FrameworkProps) => {
    amountOfControls += item.list.length;
  });
  return amountOfControls;
};

export const formatFrameworkControls = (
  frameworkControls: FrameworkProps[],
  mappedControls?: NormalizedMappedControlsProps[],
  frameworkName?: string,
): FrameworkUpdatedProps => {
  const controlsByCategory = uniqBy(frameworkControls[0].controls, 'category');
  const frameworks = controlsByCategory.map((framework: FrameworkProps) => {
    const list = [
      ...frameworkControls[0].controls.filter((control: ControlsProps) => control.category === framework.category),
    ];
    const controlsSorted = list.sort(comparator('name'));
    const frameworkMappedControls = mappedControls?.find(
      (frameworkItem: NormalizedMappedControlsProps) => frameworkItem.widgetFrameworkName === frameworkName,
    );
    const controlsSortedSelected = controlsSorted
      .filter((item: ControlsProps) =>
        frameworkMappedControls?.controls.some((el: MappedControlsProps) => item.id === el.id),
      )
      .map((item: ControlsProps) => item.id);

    return {
      ...framework,
      list: controlsSorted,
      selectedList: controlsSortedSelected,
    };
  });
  const sortedFrameworks: FrameworkProps[] = frameworks.sort(comparator('category'));

  return { data: sortedFrameworks, amountOfControls: calculationgAmountOfControls(sortedFrameworks) };
};

export const filterFrameworkControls = (frameworkControls: FrameworkProps[], value: string) => {
  const result = frameworkControls.map((listControls: FrameworkProps) => ({
    ...listControls,
    list: listControls.list.filter((control: ControlsProps) =>
      control.name.toLowerCase().includes(value.toLowerCase()),
    ),
  }));
  const filteredResults = result.filter((filteredRes: FrameworkProps) => filteredRes.list.length > 0);
  return {
    data: filteredResults,
    amountOfControls: calculationgAmountOfControls(filteredResults),
  };
};

export const iconsSchema: IconsShcemaProps = {
  ['ISO-IEC 27001 2022']: Iso,
  ['ISO-IEC 27001']: Iso,
  ['CCPA']: Ccpa,
  ['CDMC']: Cdmc,
  ['ISO-IEC 27017']: Iso,
  ['ISO-IEC 27018']: Iso,
  ['ISO-IEC 27701']: Iso,
  ['NIST Privacy Framework']: Nist,
  ['NIST CSF 2.0']: Nist,
  ['NIST CSF 1.0']: Nist,
  ['NIST 800-171']: Nist,
  ['NIST AI RMF 1.0']: Nist,
  ["SAIF (Google's Secure AI Framework)"]: Saif,
  ['DSMM']: Dsmm,
  ['OWASP Top 10:2021']: Owasp,
  ['DASF (Databricks AI Security Framework)']: Dasf,
  ['SCF - EU GDPR']: ScfGdrp,
  ['Open Finance DSS']: Ofdss,
  ['NYDFS Part 500']: NydFs,
  ['SOC 1']: Soc,
  ['NIST SSDF v1.1']: Nist,
  ['ISO 42001']: Iso,
  ['FedRAMP Rev 5 - Mod']: Fedramp,
  ['FedRAMP Rev 5 - Low']: Fedramp,
  ['FedRAMP Rev 5 - High']: Fedramp,
  ['Micorsoft SSPA DPR']: Microsoft,
  ['CSA STAR']: Csa,
  ['CSA CoC - GDPR']: Csa,
  ['BSI C5']: BsiC5,
  ['iRAP']: Irap,
  ['Spanish ENS']: SpanishEns,
  ['Microsoft SSPA DPR']: Microsoft,
  ['PCI-DSS v3.2.1']: PciDss,
  ['HIPAA']: Hipaa,
  ['PCI-DSS v4.0']: PciDss,
  ['SOC 2']: Soc,
};

export const frameworkIconGenerator = (name: string) => {
  return iconsSchema[name] || Generic2;
};
