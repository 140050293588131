import React, { useMemo } from 'react';
import { BigidBody1, BigidWidgetList, BigidWidgetContainer, BigidSeverityMarker } from '@bigid-ui/components';
import { BigidNoDataIllustration, BigidMoreActionIcon } from '@bigid-ui/icons';
import { useTheme } from '@mui/material';
import { orderBy } from 'lodash';
import { useLocalTranslation } from '../../../translations';
import {
  Content,
  WidgetContent,
  DescriptionWrapper,
  WidgetContainerWrapper,
  SeverityWrapper,
  ElipsedText,
} from '../WidgetsStyles';
import { iconsSchema } from '../../../../Frameworks/utils';
import { MenuItems } from '../WidgetsContainer';

interface TopFailedControlsWidgetProps {
  data: any[];
  dataAid: string;
}

export const TopFailedControlsWidget = ({ data, dataAid }: TopFailedControlsWidgetProps) => {
  const { t } = useLocalTranslation('compliance.widgets');
  const Theme = useTheme();

  const sortedDataByField = orderBy(data, 'failedControlsCount', 'desc').slice(0, 5);

  const WidgetList = useMemo(() => {
    return sortedDataByField.map(control => {
      const expandableList = control.policies.map((control: any) => {
        return {
          descriptionIcon: (
            <SeverityWrapper>
              <BigidSeverityMarker level={control.policySeverityLevel} />
            </SeverityWrapper>
          ),
          description: <ElipsedText>{control.policyName}</ElipsedText>,
          actionButtonIcon: BigidMoreActionIcon,
          actionMenuList: MenuItems,
        };
      });
      const Icon = iconsSchema[control.frameworkName];
      return {
        descriptionIcon: <Icon width={20} />,
        description: control.controlName,
        value: control.failedControlsCount,
        actionButtonIcon: BigidMoreActionIcon,
        actionMenuList: MenuItems,
        slide: <BigidWidgetList items={expandableList} />,
      };
    });
  }, [sortedDataByField]);

  return (
    <WidgetContainerWrapper dataAid={dataAid}>
      <BigidWidgetContainer
        dataAid={dataAid}
        titleConfig={{
          title: t('topFailed.title'),
        }}
        content={
          <Content>
            <WidgetContent>
              <BigidWidgetList items={WidgetList} />
            </WidgetContent>
          </Content>
        }
        contentHeight="190px"
        actionsSlot={<BigidBody1 color={Theme.palette.bigid.gray500}> {t('topFailed.description')}</BigidBody1>}
        emptyStateConfig={{
          isEmpty: data.length === 0,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: (
            <DescriptionWrapper rowDirection="column">
              <BigidBody1>{t('emptyState.defaultTextTitle')}</BigidBody1>
              <BigidBody1>{t('emptyState.defaultTextDescription')}</BigidBody1>
            </DescriptionWrapper>
          ),
        }}
      />
    </WidgetContainerWrapper>
  );
};
