import React, { FC, useState, useEffect } from 'react';
import { AccordionSummarySizeEnum, BigidColorsV2, BigidTooltip, PieChartData } from '@bigid-ui/components';
import {
  PieChartSectionData,
  PieChartSectionObject,
  ScanChildInfo,
  ScanDetailsParts,
  BasicPartsStates,
} from '../ScanInsightTypes';
import { generateDataAid } from '@bigid-ui/utils';
import { fetchScanChildInfo } from '../ScanInsightService';
import { PieInsightsSection } from './PieInsightsSection';
import { buildPiiChartData, aggregateParts, isScanInsightsAvailable, getNumber } from '../../ScanUtils';
import {
  BigidAccordionSummaryContainer,
  SummarySection,
  BigidAccordionContainer,
  InsightsContainer,
  BigidAccordionDetailsContainer,
  CollapseTitle,
  InsightSubTitleContainer,
  IconContainer,
} from '../ScanInsightStyles';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { BigidDonutChartProps } from '@bigid-ui/visualisation';

const THIRTY_SECONEDS = 30000;
const TOTAL_PARTS_TOOLTIP =
  'A sub-scan will be completed when all its parts (Scanned parts, Correlation, PII and Classification parts) are done.';
const SCANNED_PARTS_TOOLTIP =
  'Scanned Parts provide a count of data objects or groups of data objects to be scanned, such as tables, folders, files, mailboxes, collections, etc.';

interface InsightsProps {
  dataAid?: string;
  scanDetails: Partial<ScanChildInfo>;
  onUpdateScanDetails: (scanDetails: Partial<ScanChildInfo>) => void;
}

const useStyles = makeStyles({
  pieInsightsSectionClassNameObject: {
    borderTop: `1px solid ${BigidColorsV2.gray[300]}`,
    borderBottom: `1px solid ${BigidColorsV2.gray[300]}`,
    paddingTop: '24px',
  },
  pieInsightsSectionClassNameNoBorder: {
    paddingTop: '24px',
  },
});

enum ScanPartsStates {
  PARTS_COMPLETED = 'partsCompleted',
  PARTS_FAILED = 'partsFailed',
  PARTS_IN_PROGRESS = 'partsInProgress',
  PARTS_NEW = 'partsNew',
  PARTS_QUEUED = 'partsQueued',
  PARTS_ABORTED = 'partsAborted',
  PARTS_STOPPED = 'partsStopped',
}

enum ObjectsStates {
  OBJECTS_SCANNED = 'objectsScanned',
  OBJECTS_FAILED = 'objectsFailed',
}

enum PieChartCategories {
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  IN_PROGRESS = 'In Progress',
  QUEUED = 'Queued',
  STOPPED = 'Stopped',
}

interface InsightSection {
  title?: string;
  dataAid?: string;
  data: PieChartSectionData[];
  summary?: string;
  datAidSummary?: string;
}

interface InsightSectionWithInnerSection {
  totalObjects: InsightSection;
  totalTasks: InsightSection;
  data: InsightSection[];
}

export const Insights: FC<InsightsProps> = ({ scanDetails, onUpdateScanDetails, dataAid = 'Insights' }) => {
  const [insightSections, setInsightSections] = useState<InsightSectionWithInnerSection>();
  const classes = useStyles({});

  useEffect(() => {
    updatePartsData(scanDetails.Correlation, scanDetails.Scan, scanDetails.Summary, scanDetails.Classification);

    let interval: ReturnType<typeof setInterval>;
    const timeout = setTimeout(() => {
      interval = setInterval(handleUpdatePartsData, THIRTY_SECONEDS);
    }, THIRTY_SECONEDS);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [scanDetails]);

  const handleUpdatePartsData = async () => {
    const scanChildInfo = await fetchScanChildInfo(scanDetails._id);
    onUpdateScanDetails(scanChildInfo);
  };

  const updatePartsData = (
    correlation: BasicPartsStates,
    scan: ScanDetailsParts,
    summary: BasicPartsStates,
    classification: BasicPartsStates,
  ) => {
    const allScanPartStates = aggregateParts([correlation, summary, classification], scan);
    const classificationParts = buildPiiChartData(classification);
    const correlationParts = buildPiiChartData(correlation);
    const scannedParts = buildPiiChartData(scan);
    const piiSummaryParts = buildPiiChartData(summary);
    const allParts = buildPiiChartData(allScanPartStates);
    const objects = getObjectsDataForPiiChart(scan);
    const insightSections: PieChartSectionObject[] = [
      {
        type: 'scan',
        title: 'Scanned Parts',
        data: scannedParts,
        total: scan.totalParts,
      },
      {
        type: 'correlation',
        title: 'Correlation Parts',
        data: correlationParts,
        total: correlation.totalParts,
      },
      {
        type: 'pii',
        title: 'PII Parts',
        data: piiSummaryParts,
        total: summary.totalParts,
      },
      {
        type: 'classification',
        title: 'Classification Parts',
        data: classificationParts,
        total: classification.totalParts,
      },
    ];

    const totalObjects: InsightSection = {
      title: 'Total Objects',
      data: [
        {
          legendProps: { legendPosition: 'right' },
          data: objects,
          withTooltips: true,
          entityName: 'Total',
          title: 'Objects Scanned',
        },
      ],
    };

    const sections: InsightSection[] = insightSections.map(section => ({
      dataAid: generateDataAid(dataAid, [section.type, 'partsScanned']),
      data: [
        {
          legendProps: { legendPosition: 'right' },
          data: section.data,
          withTooltips: true,
          entityName: 'Total',
          insightSubTitleContent: (
            <InsightSubTitleContainer>
              <span>{section.title}</span>
              {section.type === 'scan' && (
                <BigidTooltip title={SCANNED_PARTS_TOOLTIP} placement="right">
                  <IconContainer>
                    <BigidHelpIcon />
                  </IconContainer>
                </BigidTooltip>
              )}
            </InsightSubTitleContainer>
          ),
        },
      ],
    }));

    const totalTasks: InsightSection = {
      dataAid: generateDataAid(dataAid, ['all', 'partsScanned']),
      data: [
        {
          legendProps: { legendPosition: 'right' },
          data: allParts,
          withTooltips: true,
          entityName: 'Total',
          insightSubTitleContent: (
            <InsightSubTitleContainer>
              <span>Total Parts</span>
              <BigidTooltip title={TOTAL_PARTS_TOOLTIP} placement="right">
                <IconContainer>
                  <BigidHelpIcon />
                </IconContainer>
              </BigidTooltip>
            </InsightSubTitleContainer>
          ),
        },
      ],
    };

    setInsightSections({ totalObjects, totalTasks, data: sections });
  };

  const getObjectsDataForPiiChart = (data: ScanDetailsParts): BigidDonutChartProps['data'] => {
    return [
      {
        category: PieChartCategories.COMPLETED,
        value: getNumber(data[ObjectsStates.OBJECTS_SCANNED]) - getNumber(data[ObjectsStates.OBJECTS_FAILED]),
        color: isScanInsightsAvailable() ? BigidColorsV2.green[300] : BigidColorsV2.green[700],
        isActive: true,
      },
      {
        category: PieChartCategories.FAILED,
        value: getNumber(data[ObjectsStates.OBJECTS_FAILED]),
        color: isScanInsightsAvailable() ? BigidColorsV2.red[300] : BigidColorsV2.red[600],
        isActive: true,
      },
    ];
  };

  return (
    <InsightsContainer>
      {insightSections && insightSections.totalTasks && (
        <PieInsightsSection data={insightSections.totalTasks.data} alignItems="center" />
      )}
      {insightSections?.data && (
        <BigidAccordionContainer>
          <BigidAccordionSummaryContainer
            size={AccordionSummarySizeEnum.normal}
            dataAid={generateDataAid(dataAid, ['BigidAccordionSummary'])}
          >
            <CollapseTitle data-aid={generateDataAid(dataAid, ['BigidAccordionSummary', 'MoreDetails'])}>
              More Details
            </CollapseTitle>
          </BigidAccordionSummaryContainer>
          <BigidAccordionDetailsContainer dataAid={generateDataAid(dataAid, ['BigidAccordionDetailsContainer'])}>
            <div>
              {insightSections.data.map((insightSection, index) => (
                <PieInsightsSection
                  key={index}
                  title={insightSection.title}
                  data={insightSection.data}
                  summary={insightSection.summary}
                  datAidSummary={insightSection.datAidSummary}
                  dataAid={insightSection.dataAid}
                  pieInsightsSectionClassName={classes.pieInsightsSectionClassNameNoBorder}
                  orientation="vertical"
                />
              ))}
            </div>
          </BigidAccordionDetailsContainer>
        </BigidAccordionContainer>
      )}
      <SummarySection>
        {insightSections && insightSections.totalObjects && (
          <PieInsightsSection
            data={insightSections.totalObjects.data}
            pieInsightsSectionClassName={classes.pieInsightsSectionClassNameObject}
            alignItems="center"
          />
        )}
      </SummarySection>
    </InsightsContainer>
  );
};
