import React from 'react';
import { BigidShareUrl } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../translations';
import { ActionsContainer, ActionsSection } from '../Actions/Actions';
import { sendDataExplorerResultsPageShareCopyBiAnalytics } from '../../../../../DataExplorer/services/analytics';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';
import { ItemsCount } from '../Actions/ItemsCount';

export const AlienEntityActions = () => {
  const {
    state: { gridConfig },
  } = useDataCatalogSearchResultsContext();
  const { t } = useLocalTranslation('actions');
  const { totalCount } = gridConfig;

  return (
    <ActionsContainer paddingLeft={12}>
      <ActionsSection>
        <ItemsCount count={totalCount} />
      </ActionsSection>
      <ActionsSection>
        <BigidShareUrl shareUrl={window.location.href} onCopy={sendDataExplorerResultsPageShareCopyBiAnalytics} />
      </ActionsSection>
    </ActionsContainer>
  );
};
