import {
  checkIfSecurityDashboardWasVisitedAfterCasesCreation,
  checkIfSecurityPostureCaseSidePanelWasVisited,
  isNumOpenCasesThresholdExceeded,
} from './utils/gettingStartedCheckingUtils';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { getFixedT } from '../translations';
import { isPermitted } from '../../../services/userPermissionsService';
import {
  ACTIONABLE_INSIGHTS_PERMISSIONS,
  DATA_SOURCES_PERMISSIONS,
  REPORTS_PERMISSIONS,
  SCANS_PERMISSIONS,
} from '@bigid/permissions';
import BigidConnectColor from '../../../assets/icons/BigidConnectColor.svg';
import BigidReportsColor from '../../../assets/icons/BigidReportsColor.svg';
import BigidReportsGrayscale from '../../../assets/icons/BigidReportsGrayscale.svg';
import BigidPoliciesColor from '../../../assets/icons/BigidPoliciesColor.svg';
import BigidPoliciesGrayscale from '../../../assets/icons/BigidPoliciesGrayscale.svg';
import { GettingStartedStep } from '../types';
import {
  getIsAutoDiscoveryAvailable,
  getIsAutoDiscoveryInProgress,
} from '../../DataSources/DataSourceConnections/hooks/useAutoDiscoveryAppStatus';
import { gettingStartedEventEmitter, GettingStartedEvents } from './utils/gettingStartedEvents';

const itemsText = getFixedT('security.items');

export enum GettingStartedStepsIds {
  DISCOVER_DATA = 'discoverData',
  SECURITY_POSTURE = 'securityPosture',
  INVESTIGATE_RISKS = 'investigateRisks',
}

export const GETTING_STARTED_STEPS: GettingStartedStep[] = [
  {
    id: GettingStartedStepsIds.DISCOVER_DATA,
    title: itemsText('discoverYourData.title'),
    description: itemsText('discoverYourData.description'),
    checkIsComplete: isNumOpenCasesThresholdExceeded,
    checkInProgress: async () => {
      const autoDiscoveryAvailable = await getIsAutoDiscoveryAvailable();
      return await getIsAutoDiscoveryInProgress(autoDiscoveryAvailable);
    },
    activeIcon: BigidConnectColor,
    disableIcon: BigidConnectColor,
    actionLabel: itemsText('discoverYourData.actionLabel'),
    actionMethod: () => {
      gettingStartedEventEmitter.emit(GettingStartedEvents.OPEN_AUTO_DISCOVERY_DIALOG);
    },
    checkIsActive: () => {
      return (
        isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name) && isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_ACTIVITY.name)
      );
    },
  },
  {
    id: GettingStartedStepsIds.SECURITY_POSTURE,
    title: itemsText('reviewYourPosture.title'),
    description: itemsText('reviewYourPosture.description'),
    checkIsComplete: checkIfSecurityDashboardWasVisitedAfterCasesCreation,
    checkInProgress: async () => {
      return false;
    },
    activeIcon: BigidReportsColor,
    disableIcon: BigidReportsGrayscale,
    actionLabel: itemsText('reviewYourPosture.actionLabel'),
    actionMethod: () => {
      $state.go(CONFIG.states.DASHBOARD_SECURITY);
    },
    checkIsActive: () => {
      return true;
    },
  },
  {
    id: GettingStartedStepsIds.INVESTIGATE_RISKS,
    title: itemsText('investigateIdentifiedRisks.title'),
    description: itemsText('investigateIdentifiedRisks.description'),
    checkIsComplete: checkIfSecurityPostureCaseSidePanelWasVisited,
    checkInProgress: async () => {
      return false;
    },
    activeIcon: BigidPoliciesColor,
    disableIcon: BigidPoliciesGrayscale,
    actionLabel: itemsText('investigateIdentifiedRisks.actionLabel'),
    actionMethod: () => {
      const isEnabled = getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED');
      $state.go(isEnabled ? CONFIG.states.ACTIONABLE_INSIGHTS : CONFIG.states.DIGEST_REPORT);
    },
    checkIsActive: () => {
      const isEnabled = getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED');
      return isPermitted(
        isEnabled ? ACTIONABLE_INSIGHTS_PERMISSIONS.ACCESS.name : REPORTS_PERMISSIONS.READ_ACTIVITY_HIGHLIGHTS.name,
      );
    },
  },
];
