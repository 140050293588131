import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import { generateDataAid } from '@bigid-ui/utils';
import {
  BigidLoader,
  BigidTabs,
  BigidSeverityBadge,
  BigidToolbar,
  BigidFilterToolbar,
  ToolbarActionType,
  BigidAccordion,
  BigidAccordionSummary,
  BigidHeading6,
  BigidAccordionDetails,
  AccordionSummarySizeEnum,
  BigidAvatar,
  BigidBody1,
  BigidChip,
  BigidFilterOptionType,
  BigidFieldFilter,
  TertiaryButton,
} from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGrid,
  BigidGridQueryComponents,
  BigidGridWithToolbarProps,
  NextGridState,
} from '@bigid-ui/grid';
import { useLocalTranslation } from './translations';
// import { generateTicketGridField } from '../ActionableInsights/actionableInsightsService';
import {
  FiltersFromServer,
  IdWithName,
  ReadPrivacyRiskCaseDto,
  RiskCasesDto,
  RiskData,
  RiskState,
  getFilterConfiguration,
  getRiskMatrix,
  getRiskStatusBadge,
  getRisks,
  searchGroupedRisks,
} from './RiskRegisterService';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { DataSourceLayoutNameCell } from '../DataSources/DataSourceConnections/DataSourceLayoutNameCell';
import { DataSourceModel } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { RiskSidePanel } from './RiskSidePanel';
import { useUserPreferences } from './useUserPreferences';
import { $stateParams } from '../../services/angularServices';
import { queryService } from '../../services/queryService';
import { debounce, noop } from 'lodash';
import { BigidListIcon } from '@bigid-ui/icons';
import { RiskMatrixMetadata } from '../Risks/RiskMatrixDefaults';

interface BigidGridGrouping {
  selector: string;
  isExpanded: boolean;
}
type SortingDirection = 'asc' | 'desc';

interface Sorting {
  /** Specifies a column's name to which the sorting is applied. */
  columnName: string;
  /** Specifies a column's sorting order. */
  direction: SortingDirection;
}

const RISK_REGISTER_PREFERENCE_IDENTIFIER = 'riskRegisterCases';

export const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

export const Wrapper = styled('div')({
  width: '100%',
  display: 'flex',
  position: 'relative',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  paddingBottom: 10,
});

export const GridWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: '100%',
  overflow: 'hidden',
});

const TabsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'space-between',
  marginBottom: '12px',
  '& .MuiTab-root': {
    width: 'auto',
  },
});
const GroupSections = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  overflow: 'auto',
});
const AvatarWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  alignItems: 'center',
});

export const IconWithLabelWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '4px',
});

const TooltipTitleWrapper = styled('div')({
  maxHeight: '200px',
  overflow: 'auto',
});

const LabelLite = styled(BigidBody1)(({ theme }) => ({
  color: theme.vars.tokens.bigid.foregroundTertiary,
}));
const AccordionSummary = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
});

const BarsPaper = styled('div', {
  shouldForwardProp: prop => prop !== 'isGrouped',
})<{ isGrouped: boolean }>(({ theme, isGrouped }) => ({
  marginBottom: isGrouped ? '12px' : 'unset',
  position: 'unset',
  border: isGrouped ? theme.vars.tokens.bigid.borderDefault : 'unset',
  backgroundColor: theme.vars.palette.background.paper,
  borderRadius: '4px',
  boxShadow: isGrouped ? 'unset' : theme.vars.tokens.bigid.shadow10,
}));

const Paper = styled('div', {
  shouldForwardProp: prop => prop !== 'isGrouped',
})<{ isGrouped: boolean }>(({ theme, isGrouped }) => ({
  position: 'unset',
  border: !isGrouped ? theme.vars.tokens.bigid.borderDefault : 'unset',
  backgroundColor: !isGrouped ? theme.vars.palette.background.paper : theme.vars.palette.bigid.gray100,
  borderRadius: '4px',
  boxShadow: !isGrouped ? 'unset' : theme.vars.tokens.bigid.shadow10,
  height: 'calc( 100% - 44px )',
}));

export const RiskRegister: FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [selectedColumnForGrouping, setSelectedColumnForGrouping] = useState('Group by');
  const [selectedRowIdForSidePanel, setSelectedRowIdForSidePanel] = useState('');
  const [isRiskSidePanelOpen, setIsRiskSidePanelOpen] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [detailedCaseResponse, setDetailedCaseResponse] = useState<ReadPrivacyRiskCaseDto[] | RiskCasesDto[]>();
  const [grouping, setGrouping] = useState<BigidGridGrouping[]>([]);
  const [statusCounters, setStatusCounters] = useState<Record<RiskState, number>>();
  const [totalCount, setTotalCount] = useState(0);
  const [expandedGroupIndex, setExpandedGroupIndex] = useState(-1);
  const [blockFilterChange, setBlockFilterChange] = useState<boolean>(false);
  const [loading, isLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [riskMatrix, setRiskMatrix] = useState<RiskMatrixMetadata>();
  const [filtersFromServer, setFiltersFromServer] = useState<Record<string, FiltersFromServer[]>>();
  const groupingSelectorRef = useRef('');

  const { caseId } = $stateParams;
  const { t } = useLocalTranslation('RiskRegisterGrid');

  const pageSize = 20;

  const theme = useTheme();

  useEffect(() => {
    const getRiskMatrixData = async () => {
      const matrix = await getRiskMatrix();
      if (matrix) setRiskMatrix(matrix);
    };

    getRiskMatrixData();
    fetchData({
      limit: pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (caseId) {
      setIsRiskSidePanelOpen(true);
    }
  }, [caseId]);

  useEffect(() => {
    pageHeaderService.setTitle({
      showBackButton: false,
      breadcrumbs: [
        {
          label: t('Common.navigation.riskRegister'),
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const riskRegisterColumns = useMemo<BigidGridColumn<ReadPrivacyRiskCaseDto>[]>(
    () => [
      {
        name: 'caseId',
        title: t('Columns.caseId'),
        getCellValue: row => row?.caseId,
        type: BigidGridColumnTypes.TEXT,
        width: 100,
        disableTooltip: true,
        isHiddenByDefault: false,
        sortingEnabled: true,
      },
      {
        name: 'caseLabel',
        title: t('Columns.case'),
        getCellValue: row => row?.caseLabel,
        type: BigidGridColumnTypes.TEXT,
        width: 400,
        disableTooltip: true,
        sortingEnabled: true,
      },
      {
        name: 'vendor.id',
        title: t('Columns.vendor'),
        getCellValue: row =>
          row?.vendors?.length ?? 0 !== 0 ? (
            <DataSourceLayoutNameCell
              row={
                {
                  id: row?.vendors?.[0]?.name,
                  type: row?.vendors?.[0]?.type,
                  displayType: row?.vendors?.[0]?.name,
                } as DataSourceModel
              }
              iconWidth={24}
              isPlainText
            />
          ) : undefined,
        type: BigidGridColumnTypes.CUSTOM,
        width: 160,
        disableTooltip: true,
        groupingEnabled: true,
      },
      {
        name: 'asset.id',
        title: t('Columns.assets'),
        getCellValue: ({ asset }) => asset?.name,
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        disableTooltip: true,
        sortingEnabled: true,
      },
      {
        name: 'severityLevel',
        title: riskMatrix ? riskMatrix.levelLabel : t('Columns.severityLevel'),
        getCellValue: row =>
          row.riskLevel ? (
            <BigidSeverityBadge
              level={'custom'}
              customColor={row.riskLevel.color}
              customLabel={row.riskLevel.name}
              hasBackground={false}
            />
          ) : undefined,
        type: BigidGridColumnTypes.CUSTOM,
        width: 120,
        disableTooltip: true,
        sortingEnabled: true,
      },
      {
        name: 'caseStatus',
        title: t('Columns.caseStatus'),
        getCellValue: row => (row.caseStatus ? getRiskStatusBadge(row.caseStatus) : undefined),
        type: BigidGridColumnTypes.CUSTOM,
        width: 140,
        disableTooltip: true,
        sortingEnabled: true,
      },
      {
        name: 'assignee',
        title: t('Columns.assignee'),
        getCellValue: ({ assignee }) =>
          assignee ? (
            <AvatarWrapper>
              <BigidAvatar size="xsmall" userName={assignee?.name ?? ''} />
              <BigidBody1>{assignee?.name}</BigidBody1>
            </AvatarWrapper>
          ) : undefined,
        type: BigidGridColumnTypes.CUSTOM,
        width: 160,
        disableTooltip: true,
      },
      {
        name: 'riskName',
        title: t('Columns.riskName'),
        getCellValue: row => row?.privacyRisk?.name,
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        disableTooltip: true,
      },
      {
        name: 'riskCategory',
        title: t('Columns.riskCategory'),
        getCellValue: row => row?.privacyRisk?.category,
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        disableTooltip: true,
        sortingEnabled: true,
        isHiddenByDefault: true,
      },
      {
        name: 'controls',
        title: t('Columns.controls'),
        getCellValue: row =>
          row.controls?.length ?? 0 > 0 ? (
            <AvatarWrapper>
              <BigidChip
                size="small"
                variant="outlined"
                bgColor={theme.vars.tokens.bigid.backgroundPrimary}
                label={row.controls[0].name}
                outline="solid"
                outlineColor={theme.vars.palette.Chip.defaultBorder}
              />
              {row.controls.length > 1 && (
                <div>
                  <BigidChip
                    size="small"
                    width={'auto'}
                    bgColor={theme.vars.tokens.bigid.backgroundPrimary}
                    variant="outlined"
                    outline="solid"
                    outlineColor={theme.vars.palette.Chip.defaultBorder}
                    label={<div style={{ minWidth: '16px' }}>{`+${row.controls.length - 1}`}</div>}
                    tooltipProps={{
                      title: (
                        <TooltipTitleWrapper>
                          {row.controls.slice(1)?.map((val, i) => (
                            <IconWithLabelWrapper key={`${val.name}-${i}`}>
                              <span>{val.name}</span>
                            </IconWithLabelWrapper>
                          ))}
                        </TooltipTitleWrapper>
                      ),
                    }}
                  />
                </div>
              )}
            </AvatarWrapper>
          ) : undefined,
        type: BigidGridColumnTypes.CUSTOM,
        width: 160,
        disableTooltip: true,
      },
      {
        name: 'sourceType',
        title: t('Columns.sourceType'),
        getCellValue: row => (row.sourceType === 'PIA' ? t('Labels.assessment') : t('Labels.businessProcess')),
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        disableTooltip: true,
        sortingEnabled: true,
      },
      {
        name: 'legalEntity.id',
        title: t('Columns.legalEntities'),
        getCellValue: row =>
          row?.legalEntities?.length ?? 0 !== 0 ? (
            <DataSourceLayoutNameCell
              row={
                {
                  id: row?.legalEntities?.[0]?.name,
                  type: row?.legalEntities?.[0]?.type,
                  displayType: row?.legalEntities?.[0]?.name,
                } as DataSourceModel
              }
              iconWidth={24}
              isPlainText
            />
          ) : undefined,
        type: BigidGridColumnTypes.CUSTOM,
        width: 160,
        disableTooltip: true,
        groupingEnabled: true,
      },
      {
        name: 'created_at',
        title: t('Columns.createdAt'),
        getCellValue: row => row.createdAt,
        type: BigidGridColumnTypes.DATE,
        width: 180,
        disableTooltip: true,
        sortingEnabled: true,
      },
      {
        name: 'assessment',
        title: t('Columns.assessment'),
        getCellValue: row =>
          row.source && parseInt(row.caseStatus?.id) !== 203 ? (
            <Link
              data-aid="privacy-app-page"
              onClick={() => {
                window.open(
                  `${window.location.origin}/#/customApp/${row?.sourceType}/workflow/${row?.source?.id}`,
                  '_blank',
                );
              }}
            >
              {`${row.source.name}`}
            </Link>
          ) : (
            `${row.source.name}`
          ),
        type: BigidGridColumnTypes.CUSTOM,
        width: 160,
        disableTooltip: true,
        sortingEnabled: true,
      },
      // {
      //   name: 'jiraTicketUrl',
      //   title: t('Columns.jiraTicketUrl'),
      //   type: BigidGridColumnTypes.LINK,
      //   formatter: {
      //     preventDefaultAndStopPropagation: false,
      //   },
      //   width: 170,
      //   disableTooltip: true,
      //   getCellValue: row => generateTicketGridField(row.ticketUrl, row.ticketMetadata),
      //   isHiddenByDefault: true
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, riskMatrix],
  );

  const getFilterConfig = useCallback(async () => {
    const filtersFromServer = (await getFilterConfiguration()).data;
    setFiltersFromServer(filtersFromServer);
    const open = filtersFromServer.caseStatus.find(status => status.value === 101)?.totalCount ?? 0;
    const close = filtersFromServer.caseStatus
      .filter(status => status.value === 201 || status.value === 202 || status.value === 203)
      .map(item => item.totalCount)
      .reduce((sum, current) => sum + current, 0);
    const resolved = filtersFromServer.caseStatus.find(status => status.value === 301)?.totalCount ?? 0;

    setStatusCounters({ [RiskState.OPEN]: open, [RiskState.CLOSED]: close, [RiskState.RESOLVED]: resolved });

    const filterConfig: BigidGridWithToolbarProps<RiskData>['filterToolbarConfig'] = {
      filters: [
        {
          field: 'asset.id',
          operator: 'in',
          options: filtersFromServer?.assets?.map(
            asset =>
              ({
                label: (asset?.value as IdWithName)?.name,
                value: (asset?.value as IdWithName)?.id,
                isSelected: false,
              } as BigidFilterOptionType),
          ),
          title: 'Assets',
          value: [],
        },
        {
          field: 'legalEntities.id',
          operator: 'in',
          options: filtersFromServer?.legalEntities?.map(
            legalEntity =>
              ({
                label: (legalEntity?.value as IdWithName)?.name,
                value: (legalEntity?.value as IdWithName)?.id,
                isSelected: false,
              } as BigidFilterOptionType),
          ),
          title: 'Legal entities',
          value: [],
          disabled: true,
        },
        {
          field: 'vendors.id',
          operator: 'in',
          options: filtersFromServer?.vendors?.map(
            vendor =>
              ({
                label: (vendor?.value as IdWithName)?.name,
                value: (vendor?.value as IdWithName)?.id,
                isSelected: false,
              } as BigidFilterOptionType),
          ),
          title: 'Vendors',
          value: [],
        },
        {
          field: 'source.id',
          operator: 'in',
          options: filtersFromServer?.source?.map(
            source =>
              ({
                label: (source?.value as IdWithName)?.name,
                value: (source?.value as IdWithName)?.id,
                isSelected: false,
              } as BigidFilterOptionType),
          ),
          title: 'Source',
          value: [],
          disabled: true,
        },
        {
          field: 'sourceType',
          operator: 'in',
          options: filtersFromServer?.sourceType?.map(
            sourceType =>
              ({
                label: sourceType?.value === 'PIA' ? t('Labels.assessmentsPlural') : t('Labels.businessProcessPlural'),
                value: sourceType?.value,
                isSelected: false,
              } as BigidFilterOptionType),
          ),
          title: 'Source type',
          value: [],
        },
        {
          field: 'assignee',
          operator: 'in',
          options: filtersFromServer?.assignee?.map(
            assignee =>
              ({
                label: assignee?.value,
                value: assignee?.value,
                isSelected: false,
              } as BigidFilterOptionType),
          ),
          title: 'Assignee',
          value: [],
          disabled: true,
        },
        {
          field: 'caseId',
          operator: 'in',
          options: filtersFromServer?.caseId?.map(
            caseId =>
              ({
                label: caseId?.value,
                value: caseId?.value,
                isSelected: false,
              } as BigidFilterOptionType),
          ),
          title: 'Case Id',
          value: [],
          disabled: true,
        },
        // {
        //   field: 'caseStatus',
        //   operator: 'in',
        //   options: filtersFromServer?.caseStatus?.map(
        //     caseStatus =>
        //       ({
        //         label: caseStatus.value,
        //         value: caseStatus.value,
        //         isSelected: false,
        //       } as BigidFilterOptionType),
        //   ),
        //   title: 'Case status',
        //   value: [],
        //   disabled: true
        // },
      ],
      searchConfig: {
        searchFilterKeys: ['caseLabel'],
        placeholder: 'Case',
        initialValue: '',
        operator: 'contains',
      },
    };
    return filterConfig;
  }, [t]);

  const {
    isReady,
    updatePreferences,
    preferences,
    gridColumns,
    filterToolbarConfig,
    updatePreferencesWithoutDebounce,
    setPreferencesState,
    key,
  } = useUserPreferences<BigidGridGrouping[], ReadPrivacyRiskCaseDto>({
    stateName: `${RISK_REGISTER_PREFERENCE_IDENTIFIER}.open`,
    initialGridColumns: riskRegisterColumns,
    getInitialFilterToolbarConfig: getFilterConfig,
  });

  const filterQueryComponents = useCallback(
    (field: string) => {
      switch (field) {
        case 'legalEntities.id':
          return !!filtersFromServer?.legalEntities?.length;
        case 'vendors.id':
          return !!filtersFromServer?.vendors?.length;
        case 'asset.id':
          return !!filtersFromServer?.assets?.length;
        case 'source.id':
          return !!filtersFromServer?.source?.length;
        case 'caseId':
        case 'sourceType':
        case 'assignee':
          return !!filtersFromServer?.[field]?.length;
      }
    },
    [filtersFromServer],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(
    debounce(async (queryComponents?: BigidGridQueryComponents) => {
      if (!queryComponents.filter) {
        queryComponents.filter = [];
      }

      queryComponents.filter = queryComponents.filter.filter(item => {
        return item.field !== 'caseStatus' && (filterQueryComponents(item.field) || item.field === 'caseLabel');
      });
      queryComponents.filter.push(getTabFilter(selectedTabIndex));
      groupingSelectorRef.current = queryComponents?.grouping?.[0]?.selector ?? '';

      const gridConfigQuery = queryService.getGridConfigQuery({ ...queryComponents, requireTotalCount: true });

      const data = queryComponents?.grouping?.length
        ? await searchGroupedRisks(gridConfigQuery)
        : await getRisks(gridConfigQuery);
      setDetailedCaseResponse(data.riskCases);
      setTotalCount(data.totalCount);
      isLoading(false);
    }, 500),
    [selectedTabIndex, filterQueryComponents],
  );

  const getTabFilter = (index: number) => {
    const values: number[] = [];
    switch (index) {
      case 0:
        values.push(101);
        break;
      case 1:
        values.push(201);
        values.push(202);
        values.push(203);
        break;
      case 2:
        values.push(301);
        break;
    }

    return {
      field: 'caseStatus',
      operator: 'in',
      value: values,
    } as BigidFieldFilter;
  };

  const onFilterChange = useCallback(
    async (filter: BigidFieldFilter[]) => {
      if (blockFilterChange) return;

      updatePreferences({
        filterState: { filter: filter.filter(f => f.field !== 'caseLabel') },
        updateState: true,
      });
      await fetchData({ filter: filter, grouping });
    },
    [fetchData, grouping, updatePreferences, blockFilterChange],
  );

  const FilterToolBar = useMemo(() => {
    return (
      <BigidFilterToolbar
        key={key}
        filters={filterToolbarConfig?.filters}
        onFiltersChange={onFilterChange}
        onFilterViabilityChanges={filter => {
          setPreferencesState(prev => ({
            ...prev,
            filterToolbarConfig: {
              ...prev.filterToolbarConfig,
              filters: prev.filterToolbarConfig.filters.map(f => ({
                ...f,
                disabled: f.field === filter.field ? filter.disabled : f.disabled,
              })),
            },
          }));
        }}
        searchConfig={filterToolbarConfig?.searchConfig}
      />
    );
  }, [filterToolbarConfig, onFilterChange, setPreferencesState, key]);

  return (
    <Wrapper data-aid={generateDataAid('RiskRegisterWrapper', [`${selectedColumnForGrouping}-view`])}>
      <MainContainer key={refresh}>
        {!isReady ? (
          <BigidLoader />
        ) : (
          <>
            <TabsContainer data-aid={generateDataAid('RiskRegisterTabs', ['container'])}>
              <BigidTabs
                variant="standard"
                selectedIndex={selectedTabIndex}
                tabs={[
                  {
                    label: t('Statuses.open'),
                    data: RiskState.OPEN,
                    counter: Number(statusCounters[RiskState.OPEN])
                      ? Number(statusCounters[RiskState.OPEN])
                      : undefined,
                  },
                  {
                    label: t('Statuses.closed'),
                    data: RiskState.CLOSED,
                    counter: Number(statusCounters[RiskState.CLOSED])
                      ? Number(statusCounters[RiskState.CLOSED])
                      : undefined,
                  },
                  {
                    label: t('Statuses.resolved'),
                    data: RiskState.RESOLVED,
                    counter: Number(statusCounters[RiskState.RESOLVED])
                      ? Number(statusCounters[RiskState.RESOLVED])
                      : undefined,
                  },
                ]}
                onChange={value => {
                  setSelectedTabIndex(value);
                }}
              />
            </TabsContainer>
            <Paper isGrouped={(grouping?.length ?? 0) !== 0} key={refresh}>
              <GridWrapper key={refresh}>
                <BarsPaper isGrouped={(grouping?.length ?? 0) !== 0}>
                  {FilterToolBar}
                  <BigidToolbar
                    columns={gridColumns?.map(column => ({
                      title: column.title,
                      name: column.name,
                      checked: preferences?.grid?.hiddenColumnNames
                        ? !preferences?.grid?.hiddenColumnNames?.includes(column.name)
                        : !column.isHiddenByDefault,
                    }))}
                    entityName={
                      selectedColumnForGrouping !== 'Group by' ? selectedColumnForGrouping : t('EntityName.entityName')
                    }
                    shouldShowColumnChooser
                    onColumnChange={columns => {
                      updatePreferencesWithoutDebounce({
                        gridState: {
                          ...preferences.grid,
                          hiddenColumnNames: columns.filter(c => !c.checked).map(c => c.name),
                        },
                      });
                    }}
                    toolbarActions={[
                      {
                        label: selectedColumnForGrouping,
                        type: ToolbarActionType.MENU,
                        placement: 'end',
                        menuProps: {
                          items: [
                            {
                              label: 'None',
                              id: 'none',
                              onClick: () => {
                                isLoading(true);
                                setSelectedColumnForGrouping('Group by');
                                setGrouping([]);
                                fetchData({ filter: preferences?.filter?.filter, grouping: undefined });
                              },
                            },
                            {
                              label: 'Assessment / Process',
                              id: 'sourceName',
                              onClick: () => {
                                isLoading(true);
                                setSelectedColumnForGrouping('Assessment / Process');
                                const group = {
                                  isExpanded: false,
                                  selector: 'source',
                                };
                                setGrouping([group]);
                                fetchData({ filter: preferences?.filter?.filter, grouping: [group] });
                              },
                            },
                            {
                              label: 'Source type',
                              id: 'sourceType',
                              onClick: () => {
                                isLoading(true);
                                setSelectedColumnForGrouping('Source type');
                                const group = {
                                  isExpanded: false,
                                  selector: 'sourceType',
                                };
                                setGrouping([group]);
                                fetchData({ filter: preferences?.filter?.filter, grouping: [group] });
                              },
                            },
                            {
                              label: 'Vendors',
                              id: 'vendors',
                              onClick: () => {
                                isLoading(true);
                                setSelectedColumnForGrouping('Vendors');
                                const group = {
                                  isExpanded: false,
                                  selector: 'vendors',
                                };
                                setGrouping([group]);
                                fetchData({ filter: preferences?.filter?.filter, grouping: [group] });
                              },
                            },
                            {
                              label: 'Legal Entities',
                              id: 'legalEntities',
                              onClick: () => {
                                isLoading(true);
                                setSelectedColumnForGrouping('Legal Entities');
                                const group = {
                                  isExpanded: false,
                                  selector: 'legalEntities',
                                };
                                setGrouping([group]);
                                fetchData({ filter: preferences?.filter?.filter, grouping: [group] });
                              },
                            },
                            {
                              label: 'Assignee',
                              id: 'assignee',
                              onClick: () => {
                                isLoading(true);
                                setSelectedColumnForGrouping('Assignee');
                                const group = {
                                  isExpanded: false,
                                  selector: 'assignee',
                                };
                                setGrouping([group]);
                                fetchData({ filter: preferences?.filter?.filter, grouping: [group] });
                              },
                            },
                            {
                              label: 'Assets',
                              id: 'asset',
                              onClick: () => {
                                isLoading(true);
                                setSelectedColumnForGrouping('Assets');
                                const group = {
                                  isExpanded: false,
                                  selector: 'asset',
                                };
                                setGrouping([group]);
                                fetchData({ filter: preferences?.filter?.filter, grouping: [group] });
                              },
                            },
                            {
                              label: 'Risk Name',
                              id: 'privacyRisk',
                              onClick: () => {
                                isLoading(true);
                                setSelectedColumnForGrouping('Risk Name');
                                const group = {
                                  isExpanded: false,
                                  selector: 'privacyRisk',
                                };
                                setGrouping([group]);
                                fetchData({ filter: preferences?.filter?.filter, grouping: [group] });
                              },
                            },
                          ],
                        },
                        menuTitle: selectedColumnForGrouping,
                        show: () => true,
                      },
                    ]}
                    totalRows={totalCount ?? 0}
                  />
                </BarsPaper>
                {loading ? (
                  <BigidLoader />
                ) : (
                  <>
                    {blockFilterChange && <BigidLoader />}
                    {(grouping?.length ?? 0) === 0 && (
                      <BigidGrid
                        columns={gridColumns}
                        rows={detailedCaseResponse}
                        totalRowsCount={totalCount}
                        pageSize={pageSize}
                        skip={skip * pageSize}
                        pagingMode
                        loading={!detailedCaseResponse}
                        hiddenColumnNames={
                          preferences?.grid?.hiddenColumnNames ??
                          riskRegisterColumns.filter(column => column.isHiddenByDefault).map(column => column.name)
                        }
                        showSortingControls={false}
                        showFilteringControls={false}
                        selectedRowIds={selectedRowIds}
                        defaultSorting={[{ field: 'caseId', order: 'asc' }]}
                        onSelectedRowIdsChanged={setSelectedRowIds}
                        onColumnsStateChanged={(next: NextGridState) => {
                          updatePreferences({
                            gridState: { ...(preferences?.grid ?? {}), ...next },
                          });
                        }}
                        onSortingChanged={(value: Sorting[]) => {
                          updatePreferences({
                            gridState: {
                              ...preferences.grid,
                              sort: value.map(val => ({ field: val.columnName, order: val.direction })),
                            },
                          });
                          fetchData({
                            filter: preferences.filter.filter,
                            sort: value.map(val => ({ field: val.columnName, order: val.direction })),
                            grouping,
                          });
                        }}
                        onPagingChanged={(skip: number) => {
                          setSkip(skip);
                          fetchData({
                            filter: preferences?.filter?.filter ?? [],
                            sort: preferences?.grid?.sort,
                            grouping,
                            skip: skip * pageSize,
                            limit: pageSize,
                          });
                        }}
                        onRowClick={row => {
                          setSelectedRowIdForSidePanel(row.caseId);
                          setIsRiskSidePanelOpen(true);
                        }}
                      />
                    )}
                    {(grouping?.length ?? 0) !== 0 && (
                      <GroupSections>
                        {(detailedCaseResponse as RiskCasesDto[]).map((group, index) => (
                          <BigidAccordion
                            expanded={expandedGroupIndex === index}
                            onChange={(_event, expanded) => setExpandedGroupIndex(expanded ? index : -1)}
                            key={index}
                            hasShadow={true}
                            hasBorder={true}
                          >
                            <BigidAccordionSummary size={AccordionSummarySizeEnum.medium}>
                              <AccordionSummary>
                                <BigidHeading6>
                                  {groupingSelectorRef.current === 'sourceType'
                                    ? group.name === 'PIA'
                                      ? t('Labels.assessmentsPlural')
                                      : t('Labels.businessProcessPlural')
                                    : group.name}
                                </BigidHeading6>
                                <LabelLite>{`(${group.numOfCases})`}</LabelLite>
                              </AccordionSummary>
                            </BigidAccordionSummary>
                            <BigidAccordionDetails noPadding>
                              {expandedGroupIndex === index && (
                                <>
                                  <BigidGrid
                                    columns={gridColumns}
                                    rows={group.cases}
                                    totalRowsCount={group?.cases.length}
                                    showSortingControls={false}
                                    showFilteringControls={false}
                                    selectedRowIds={selectedRowIds}
                                    defaultSorting={[{ field: 'caseId', order: 'asc' }]}
                                    onSelectedRowIdsChanged={setSelectedRowIds}
                                    pageSize={pageSize}
                                    skip={skip * pageSize}
                                    loading={!detailedCaseResponse}
                                    hiddenColumnNames={preferences?.grid?.hiddenColumnNames ?? []}
                                    onColumnsStateChanged={(next: NextGridState) => {
                                      updatePreferences({
                                        gridState: { ...(preferences?.grid ?? {}), ...next },
                                      });
                                    }}
                                    onSortingChanged={(value: Sorting[]) => {
                                      updatePreferences({
                                        gridState: {
                                          ...preferences.grid,
                                          sort: value.map(val => ({ field: val.columnName, order: val.direction })),
                                        },
                                      });

                                      fetchData({
                                        filter: preferences.filter.filter,
                                        sort: value.map(val => ({ field: val.columnName, order: val.direction })),
                                        grouping,
                                      });
                                    }}
                                    onPagingChanged={() => noop}
                                    onRowClick={row => {
                                      setSelectedRowIdForSidePanel(row.caseId);
                                      setIsRiskSidePanelOpen(true);
                                    }}
                                  />
                                  {group.numOfCases > 5 && (
                                    <TertiaryButton
                                      margin="12px"
                                      startIcon={<BigidListIcon />}
                                      disabled={!group.value}
                                      onClick={event => {
                                        event.stopPropagation();
                                        event.preventDefault();

                                        setBlockFilterChange(true);
                                        let fieldName = grouping[0].selector;
                                        switch (fieldName) {
                                          case 'source':
                                            fieldName = 'source.id';
                                            break;
                                          case 'vendors':
                                            fieldName = 'vendors.id';
                                            break;
                                          case 'legalEntities':
                                            fieldName = 'legalEntities.id';
                                            break;
                                          case 'asset':
                                            fieldName = 'asset.id';
                                            break;
                                          case 'privacyRisk':
                                            fieldName = 'privacyRisk.id';
                                          default:
                                            break;
                                        }

                                        updatePreferencesWithoutDebounce({
                                          filterState: {
                                            filter: [
                                              {
                                                field: fieldName,
                                                operator: 'in',
                                                value: [group.value],
                                              } as BigidFieldFilter,
                                            ],
                                          },
                                        });

                                        isLoading(true);
                                        setGrouping([]);
                                        setBlockFilterChange(false);
                                        setSelectedColumnForGrouping('Group by');
                                        setExpandedGroupIndex(-1);
                                      }}
                                      size={'small'}
                                    >
                                      {`View all ${group.numOfCases} cases`}
                                    </TertiaryButton>
                                  )}
                                </>
                              )}
                            </BigidAccordionDetails>
                          </BigidAccordion>
                        ))}
                      </GroupSections>
                    )}
                  </>
                )}
              </GridWrapper>
            </Paper>
          </>
        )}
      </MainContainer>
      {isRiskSidePanelOpen && (
        <RiskSidePanel
          isOpen={isRiskSidePanelOpen}
          onStatusChanged={() => {
            fetchData({
              filter: preferences.filter.filter,
              grouping,
            });
            getFilterConfig();
            setRefresh(prev => ++prev);
          }}
          onClose={() => {
            setSelectedRowIdForSidePanel('');
            setIsRiskSidePanelOpen(false);
            fetchData({
              filter: preferences.filter.filter,
              grouping,
            });
            getFilterConfig();
          }}
          caseId={selectedRowIdForSidePanel || caseId}
        />
      )}
    </Wrapper>
  );
};
