import { ScanCreateData } from './createScanContext';
import { createScanTemplate, getTemplateById, handleTemplateError } from '../ScanTemplates/scanTemplatesService';
import { notificationService } from '../../../services/notificationService';
import { $state, scansService } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { ScanTemplateWithoutId } from '../ScanTemplates/scanTemplateTypes';
import { dateTimeService } from '@bigid-ui/i18n';
import { openDuplicateTemplateWarningDialog } from '../ScanTemplates/DuplicateTemplateWarnning';
import { isNil } from 'lodash';

export type CreateScheduledScanFormData = Pick<
  ScanCreateData,
  'name' | 'owners' | 'dataSourcesIds' | 'schedule' | 'description'
> &
  Partial<ScanCreateData>;

interface QuickScanParams {
  dataSourcesIds: string[];
  ownerName: string;
  templateName: string;
  templateId: string;
}

export const createScheduledScan = async (
  scanWizardState: CreateScheduledScanFormData,
  scanTemplateId: string,
  isUpdate: boolean,
  isSingleRunScan?: boolean,
) => {
  let active = scanWizardState.active;

  if (isNil(scanWizardState.active) && scanWizardState.enabled) {
    active = true;
  }

  const formData = new FormData();
  formData.append(
    'data',
    JSON.stringify({
      name: scanWizardState.name,
      owners: scanWizardState.owners,
      dataSourceList: scanWizardState.dataSourcesIds,
      scanTemplateId,
      schedule: scanWizardState.schedule,
      description: scanWizardState.description,
      isCustomScanProfile: true,
      allEnabledDs: scanWizardState.allEnabledDs,
      enabled: scanWizardState.enabled,
      ...(isSingleRunScan && { isSingleRunScan }),
      ...(!isNil(active) && { active }),
    }),
  );

  return isUpdate
    ? scansService.updateScanProfile(scanWizardState.scanId, formData)
    : scansService.createScanProfile(formData);
};

export const handleSaveTemplate = async (scanTemplate: ScanTemplateWithoutId): Promise<string> => {
  try {
    const { _id, matchScanTemplateName, matchingCount } = await createScanTemplate(scanTemplate, false);
    if (_id) {
      return _id as string;
    }
    const isAllowDuplicateScanTemplate = await openDuplicateTemplateWarningDialog(matchScanTemplateName, matchingCount);
    if (!isAllowDuplicateScanTemplate) {
      return;
    }
    const createdDuplicateScanTemplate = await createScanTemplate(scanTemplate, true);
    return createdDuplicateScanTemplate._id as string;
  } catch (e) {
    handleTemplateError(e);
    return null;
  }
};

export const handleSaveScan = async (
  scanWizardState: ScanCreateData,
  isSingleRunScan?: boolean,
  setIsLoadingStep?: (state: boolean) => void,
) => {
  let scanTemplateId = scanWizardState.templateIds?.[0];
  const isUpdate = Boolean(scanWizardState.scanId);
  const actionString = isUpdate ? 'update' : 'create';
  try {
    setIsLoadingStep?.(true);
    if (scanWizardState.isTemplateCreate) {
      scanTemplateId = await handleSaveTemplate(scanWizardState.scanTemplateCreateInWizard);
      if (!scanTemplateId) {
        return null;
      }
    }
    const { data } = await createScheduledScan(scanWizardState, scanTemplateId, isUpdate, isSingleRunScan);
    if (!data) {
      return notificationService.error(`Failed to ${actionString} scan. Check the form you may be missing some fields`);
      return null;
    }
    notificationService.success(`Scan ${actionString} successfully!`);

    if (!isSingleRunScan) {
      return $state.go(CONFIG.states.SCANS_PLANNED_SCANS);
    }
    return data;
  } catch (e) {
    handleScheduledScanErrors(e, scanWizardState.name, actionString);
  } finally {
    setIsLoadingStep?.(false);
  }
};

export const fetchTemplate = async (id: string) => {
  try {
    const template = await getTemplateById(id);
    return template;
  } catch (error) {
    notificationService.error('Error getting scan template');
  }
};

export const handleScheduledScanErrors = (e: any, name: string, actionString: string) => {
  if (e?.data && typeof e.data === 'string') {
    notificationService.error(e.data);
  } else {
    notificationService.error(`failed to ${actionString} scan. See logs for more info`);
    console.error(e);
  }
};

export const getQuickScanToRun = ({
  dataSourcesIds,
  templateName,
  templateId,
  ownerName,
}: QuickScanParams): Pick<ScanCreateData, 'dataSourcesIds' | 'name' | 'owners' | 'templateIds'> &
  Partial<ScanCreateData> => {
  return {
    name: `Instant scan ${templateName} ${dateTimeService.formatDate(new Date())}`,
    owners: ownerName ? [ownerName] : [],
    dataSourcesIds,
    templateIds: [templateId],
  };
};
