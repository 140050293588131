import React, { FC, useState, useRef } from 'react';
import {
  BigidColorsV2,
  BigidBody1,
  BigidMenu,
  BigidMenuProps,
  BigidMenuItem,
  BigidButtonIcon,
  BigidTooltip,
} from '@bigid-ui/components';
import { BigidOpenTicketIcon, BigidMoreActionIcon, BigidExternalLinkIcon, BigidServiceNowIcon } from '@bigid-ui/icons';
import { styled, Divider, useTheme } from '@mui/material';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { generateDataAid } from '@bigid-ui/utils';
import { Configuration } from '../ActionCenter/ConfigurationManagement/configurationManagementTypes';
import { ServiceTicketingType } from './RiskRegisterService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { $state } from '../../services/angularServices';
import { isPermitted } from '../../services/userPermissionsService';
import { useLocalTranslation } from './translations';
import { CONFIG } from '../../../config/common';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const ProjectItem = styled('div')({
  width: '100%',
  height: '48px',
  borderRadius: '4px',
  backgroundColor: BigidColorsV2.gray[150],
  padding: '12px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& *:last-child': {
    marginLeft: 'auto',
  },
  position: 'relative',
});

const DotsIconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'transparent',
});

const FieldLabel = styled('div')({
  paddingBottom: '4px',
});

const LabelWrapper = styled(BigidBody1)({
  paddingLeft: '0.5px',
});

const ConfigurationNameTooltip = styled(BigidBody1)({
  paddingLeft: '0.5px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '200px',
  whiteSpace: 'nowrap',
});

export interface TicketServiceSelectorProps {
  configurations: Configuration[];
  selectedConfiguration: Configuration;
  updateSelectedJiraProject: (accountName: string) => void;
  tooltipText?: string;
}

export const TicketServiceSelector: FC<TicketServiceSelectorProps> = ({
  configurations,
  selectedConfiguration,
  updateSelectedJiraProject,
  tooltipText,
}) => {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuIconRef = useRef();
  const { t } = useLocalTranslation('TicketServiceSelector');

  const handleClick = () => setIsMenuOpen(!isMenuOpen);
  const handleClose = () => setIsMenuOpen(false);
  const handleRoute = () => $state.go(CONFIG.states.ACTION_CENTER_CONFIGURATIONS);

  const isServiceNowPermitted = getApplicationPreference('DSPM_SERVICE_NOW');
  const isUserHasConfigPermission = isPermitted(ACTION_CENTER_PERMISSIONS.CREATE_CONFIGURATIONS.name);
  const permittedListOfServices = configurations.filter((config: Configuration) =>
    (config?.type === ServiceTicketingType.SERVICE_NOW && !isServiceNowPermitted) || config.isOffline ? false : true,
  );

  const iconsSchema: any = {
    [ServiceTicketingType.JIRA]: <BigidOpenTicketIcon />,
    [ServiceTicketingType.SERVICE_NOW]: <BigidServiceNowIcon />,
  };

  const MenuChildren = () => (
    <>
      {permittedListOfServices.map(config => {
        return (
          <BigidMenuItem key={config.id} onClick={() => updateSelectedJiraProject(config.name)}>
            {iconsSchema[config?.type]}
            <BigidTooltip title={config.name}>
              <ConfigurationNameTooltip>{config.name}</ConfigurationNameTooltip>
            </BigidTooltip>
          </BigidMenuItem>
        );
      })}
      {isUserHasConfigPermission && (
        <>
          <Divider />
          <BigidMenuItem onClick={handleRoute}>
            <LabelWrapper>{t('Labels.addProject')}</LabelWrapper>
            <BigidExternalLinkIcon />
          </BigidMenuItem>
        </>
      )}
    </>
  );

  const menuProps: BigidMenuProps = {
    open: isMenuOpen,
    onMenuClose: handleClose,
    anchorEl: menuIconRef.current,
    children: <MenuChildren />,
  };
  return (
    <>
      <Wrapper>
        <FieldLabel>
          <BigidBody1>{t('Labels.serviceDescription')}</BigidBody1>
        </FieldLabel>
        <ProjectItem
          data-aid={generateDataAid('JiraProjectSelector', ['selected-project', selectedConfiguration?.name])}
        >
          {iconsSchema[selectedConfiguration?.type]}
          <LabelWrapper>{selectedConfiguration?.name}</LabelWrapper>
          <DotsIconContainer ref={menuIconRef}>
            <BigidButtonIcon icon={BigidMoreActionIcon} selected={false} onClick={handleClick} />
          </DotsIconContainer>
        </ProjectItem>
        <BigidMenu {...menuProps} />
      </Wrapper>
      {tooltipText && <BigidBody1 color={theme.palette.bigid.gray500}>{tooltipText}</BigidBody1>}
    </>
  );
};
