import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { BigidAddIcon, BigidChevronDownIcon } from '@bigid-ui/icons';
import {
  BigidErrorBoundary,
  PrimaryButton,
  BigidPaper,
  BigidMenu,
  BigidMenuItemProps,
  BigidPageLayout,
} from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { LegalEntitiesGrid } from './LegalEntitiesGrid';
import { LegalEntitiesEditDialog, useLegalEntitiesEditDialog } from './LegalEntitiesEditDialog';
import { useLegalEntitiesDeleteDialog, LegalEntitiesDeleteDialog } from './LegalEntitiesDeleteDialog';
import { isPermitted } from '../../services/userPermissionsService';
import { LEGAL_ENTITIES_PERMISSIONS } from '@bigid/permissions';
import { LegalEntitiesTrackingEvents, trackLegalEntities } from './utils/analytics';
import { getLevels } from './LegalEntitiesService';
import { LegalEntityLevel } from './LegalEntitiesTypes';
import { notificationService } from '../../services/notificationService';
import { BigidPageFavoriteToggler } from '../../components/BigidHeader/BigidPageTitle/BigidPageFavoriteToggler';
import { LegalEntitiesTitleMenu } from './LegalEntitiesTitleMenu';
import {
  useLegalEntitiesEditLevelsDialog,
  LegalEntitiesEditLevelsDialog,
} from './LegalEntitiesEditHierarchyLevelsDialog';

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  max-width: 100vw;
`;

export const LegalEntities = () => {
  const { openLegalEntitiesEditDialog, legalEntitiesEditDialogProps, key } = useLegalEntitiesEditDialog();
  const { openDeleteDialog, deleteDialogProps } = useLegalEntitiesDeleteDialog();
  const [levels, setLevels] = useState<LegalEntityLevel[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const menuRef = useRef();
  const { openDialog, dialogProps, key: levelsKey } = useLegalEntitiesEditLevelsDialog();

  useEffect(() => {
    pageHeaderService.setIsHidden(true);
    return () => {
      pageHeaderService.setIsHidden(false);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { legalEntitiesLevel } = await getLevels();
        setLevels(legalEntitiesLevel);
      } catch (err) {
        notificationService.error(`Failed to fetch Legal Entity levels.`);
        console.error(`Failed to fetch Legal Entity levels: ${JSON.stringify(err?.response)}`);
      }
    })();
  }, [levelsKey]);

  const onTitleMenuClick = async () => {
    trackLegalEntities(LegalEntitiesTrackingEvents.OPEN_EDIT_HIERARCHY_DIALOG_CLICK);
    await openDialog();
  };

  return (
    <BigidErrorBoundary>
      <BigidPageLayout
        actions={[
          isPermitted(LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_CREATE.name) && (
            <>
              <div ref={menuRef}>
                <PrimaryButton
                  size="medium"
                  text="Add"
                  startIcon={<BigidAddIcon />}
                  endIcon={<BigidChevronDownIcon />}
                  onClick={() => {
                    setIsMenuOpen(true);
                  }}
                />
              </div>
              <BigidMenu
                anchorEl={menuRef.current}
                items={levels.map(level => ({
                  id: level.id,
                  label: level.name,
                  level,
                }))}
                onItemSelect={(menuItem: BigidMenuItemProps & { level?: LegalEntityLevel }) => {
                  trackLegalEntities(LegalEntitiesTrackingEvents.CREATE_LEGAL_ENTITY_ACTION, { ...menuItem.level });
                  openLegalEntitiesEditDialog(false, null, menuItem.level);
                }}
                open={isMenuOpen}
                onMenuClose={() => setIsMenuOpen(false)}
                width={191}
              />
            </>
          ),
          isPermitted(LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_EDIT.name) && (
            <LegalEntitiesTitleMenu key="2" onClick={onTitleMenuClick} />
          ),
        ].filter(Boolean)}
        dataAid="legal-entities"
        titleIcons={[<BigidPageFavoriteToggler key="BigidPageFavoriteToggler" />]}
        title="Legal Entities"
        pageBackgroundColor="transparent"
        showHeaderBackground={false}
      >
        <ContentWrapper>
          <BigidPaper>
            <LegalEntitiesEditDialog {...legalEntitiesEditDialogProps} />
            <LegalEntitiesDeleteDialog {...deleteDialogProps} />
            <LegalEntitiesEditLevelsDialog {...dialogProps} />

            <LegalEntitiesGrid
              openLegalEntitiesEditDialog={openLegalEntitiesEditDialog}
              openDeleteEntityDialog={openDeleteDialog}
              forceUpdateKeys={{
                forceUpdateAssetsKey: key.assetsKey,
                forceUpdateParentsKey: key.parentsKey,
                forceUpdateLevelsKey: levelsKey,
              }}
            />
          </BigidPaper>
        </ContentWrapper>
      </BigidPageLayout>
    </BigidErrorBoundary>
  );
};
