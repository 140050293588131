import React, { useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidScheduler,
  EndingConditionType,
  FrequencyType,
  RecurringSchedule,
  convertRecurringScheduleObjectToCron,
  convertCronToRecurringScheduleObject,
  BigidLoader,
  BigidSwitch,
  BigidBody1,
} from '@bigid-ui/components';
import { CreateScanWizardContext } from '../../createScanContext';
import { generateDataAid } from '@bigid-ui/utils';
import { isNil } from 'lodash';

const useStyles = makeStyles({
  scheduler: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'scroll',
    '& [data-aid="BigidScheduler"]': {
      width: '100%',
    },
  },
  enableBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
});

export const getDefaultScheduleRecurrence: () => RecurringSchedule = () => ({
  startDate: new Date(),
  frequency: FrequencyType.Daily,
  numberOfRepetitions: 1,
  weeklyRecurrence: [],
  monthlyRecurrence: null,
  monthlyRecurrenceDate: null,
  monthlyRecurrencePatternDay: null,
  monthlyRecurrencePatternCount: null,
  endingCondition: EndingConditionType.None,
  endDate: null,
  endingOccurrencesNumber: 1,
});

interface SchedulerStepPorps {
  isLoadingStep: boolean;
  enabled: boolean;
  schedule: RecurringSchedule;
  setIsEnabled: (checked: boolean) => void;
  setSchedule: (schedule: RecurringSchedule) => void;
}

export const SchedulerStep = ({ isLoadingStep, enabled, schedule, setSchedule, setIsEnabled }: SchedulerStepPorps) => {
  const { scheduler, enableBox } = useStyles();

  const handleChange = (newSchedule: RecurringSchedule) => {
    newSchedule.startDate.setSeconds(0);
    if (!isNil(newSchedule.endDate)) {
      newSchedule.endDate = new Date(newSchedule.endDate);
      newSchedule.endDate.setHours(newSchedule.startDate.getHours());
      newSchedule.endDate.setMinutes(newSchedule.startDate.getMinutes());
    }
    setSchedule(newSchedule);
  };

  const handleEnabled = (checked: boolean) => {
    setIsEnabled(checked);
    schedule.startDate.setSeconds(0);
    setSchedule(schedule);
  };

  return (
    <div className={scheduler}>
      {isLoadingStep && <BigidLoader />}
      <div className={enableBox}>
        <BigidBody1 fontSize={14}>Scheduling</BigidBody1>
        <BigidSwitch
          dataAid={generateDataAid('SchedulerStep', ['switch'])}
          onChange={(_event, checked) => handleEnabled(checked)}
          checked={enabled}
        />
      </div>
      <BigidScheduler onChange={handleChange} scheduleData={schedule} isDisabled={!enabled} />
    </div>
  );
};
