import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  BigidDialog,
  BigidDialogProps,
  BigidDropdown,
  BigidDropdownOption,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { addRelations, getCandidates } from './dataCatalogRelationsService';
import { mapCandidatesToOptions } from './utils';
import { notificationService } from '../../../services/notificationService';

export interface BigidDialogPropsWithChildren extends BigidDialogProps {
  existingRelationsFqns: string[];
  fullyQualifiedName: string;
  dataSourceName: string;
  onSubmit?: () => void;
  children?: ReactNode;
}

export const DataCatalogRelationsAddDialog: FunctionComponent<BigidDialogPropsWithChildren> = ({
  isOpen,
  onClose,
  title,
  existingRelationsFqns,
  fullyQualifiedName,
  dataSourceName,
  onSubmit,
}) => {
  useEffect(() => {
    getOptions(fullyQualifiedName);
  }, [existingRelationsFqns, fullyQualifiedName]);

  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);
  const [options, setOptions] = useState<BigidDropdownOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnSelect = (options: BigidDropdownOption[]) => {
    const selectedValues = options.map(option => option.value);
    setSelectedObjects(selectedValues);
  };

  const handleOnAddRelation = async () => {
    try {
      setIsLoading(true);
      await addRelations(fullyQualifiedName, dataSourceName, selectedObjects);
      onSubmit();
    } catch (exception) {
      console.error(`An error has occurred: ${exception.message}`);
      notificationService.error(`An error has occurred: ${exception.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const getOptions = useCallback(async (fullyQualifiedName: string) => {
    try {
      const candidates = await getCandidates(fullyQualifiedName);
      const options = mapCandidatesToOptions(candidates);
      setOptions(options);
    } catch (exception) {
      console.error(`An error has occurred: ${exception.message}`);
      notificationService.error(`An error has occurred: ${exception.message}`);
    }
  }, []);

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      borderTop={true}
      isLoading={isLoading}
      buttons={[
        {
          component: SecondaryButton,
          isClose: true,
          onClick: onClose,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: handleOnAddRelation,
          text: 'Add',
          disabled: Boolean(selectedObjects.length == 0),
        },
      ]}
    >
      <BigidDropdown
        options={options}
        onSelect={handleOnSelect}
        isMulti
        isValueDisplayedAsChips={false}
        applyOnChange={true}
        isSearchable={true}
      />
    </BigidDialog>
  );
};
