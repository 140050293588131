import React, { FC, memo, useRef, useState } from 'react';
import {
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
  BigidExtraField,
  useExtraFieldControl,
  BigidExtraFieldLabelPosition,
  BigidSwitch,
  BigidHeading5,
  ExtraFieldItemData,
  BigidFormFieldText,
  BigidFormFieldLabelWrapper,
  BigidColorsV2,
  BigidAccordion,
  AccordionSummarySizeEnum,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidPrimaryCheckbox,
  BigidSelectOption,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { FormPasswordWithMaskField } from '../DataSourceConfiguration/fields/FormPasswordWithMaskField';
import {
  CustomDsFieldItem,
  CustomDsFieldTypeOption,
  CustomDsFieldTypeToLabel,
  CustomDsFieldTypes,
  createNewItem,
} from '../DataSourceConfiguration/fields/FormCustomParametersField';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from './translations';
import { truncate } from 'lodash';

type DataSourceCustomParametersFieldItemProps = {
  onChange: (value: unknown) => void;
  value: CustomDsFieldItem;
  id: string;
  disabled: boolean;
  className?: string;
};

const expandedStyles = {
  borderBottom: `1px solid ${BigidColorsV2.gray[200]}`,
  borderRadius: '8px 8px 0 0',
};

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& label': {
      paddingLeft: '0',
    },
  },
  headerWrapper: {
    display: 'inline-block',
  },
  fieldWrapper: {
    "& [data-aid^='BigidFormFieldLabelWrapper-bigid-form-field-custom-item-']": {
      margin: 0,
      '& > div > fieldset': {
        margin: '16px 0px 0px 0px',
      },
      '& > div > fieldset:first-of-type': {
        margin: '4px 0px 0px 0px',
      },
    },
    "& [data-aid='BigidTextField-value-text']": {
      padding: '0',
    },
    '& .MuiAccordion-root': {
      border: 'none',
      boxShadow: 'none',
      borderRadius: '8px',
      zIndex: 0,
      position: 'relative',
    },
    "& [data-aid='BigidExtraField']": {
      gap: '0.75rem',
    },
    '& .MuiAccordionSummary-content': {
      zIndex: 1,
      marginLeft: '-25px',
      maxWidth: '400px',
      '&.Mui-expanded': {
        maxWidth: '400px',
      },
    },
    "& [data-aid^='FormFieldSideLabelWrapper-bigid-form-field-FormCustomParametersFieldValue'] > div": {
      flexDirection: 'column',
      gap: '0.5rem',
    },
    "& [data-aid^='BigidExtraField-item']": {
      position: 'relative',
    },
    "& [data-aid^='BigidExtraField-item'] > div:last-of-type:not(:only-child)": {
      top: '8px',
      right: '16px',
      width: '32px',
      position: 'absolute',
      '& hr': {
        display: 'none',
      },
    },
    "& [data-aid^='BigidExtraField-item'] > div": {
      width: '100%',
    },
    "& [data-aid='BigidExtraField'] > div:last-of-type": {
      justifyContent: 'flex-start',
    },
    "& [data-aid^='BigidFormFieldSelectWrapper-formcustomparametersfieldtype']": {
      width: '100%',
    },
    "& [data-aid^='BigidFormFieldLabelWrapper-bigid-form-field-custom-item']": {
      width: '100%',
      '& fieldset': {
        width: '100%',
      },
      '& fieldset:last-of-type': {
        width: 'calc(100% - 100px)',
      },
      '& > div > .MuiFormControlLabel-root:last-child': {
        position: 'absolute',
        top: '108px',
        right: '0',
        marginRight: '0',
      },
    },
  },
  headerTitleWrapper: {
    margin: '20px 0',
  },
});

const mapBigidSelectOptionToValue = (option: BigidSelectOption[] | string) => {
  const [{ value }] = Array.isArray(option) ? option ?? [{}] : [{ value: option }];
  return value;
};

export const DataSourceCustomParametersFieldItem: FC<DataSourceCustomParametersFieldItemProps> = memo(
  ({ value, onChange, id, disabled }) => {
    const [isExpanded, setExpanded] = useState(true);
    const [passwordTouched, setPasswordTouched] = useState(false);
    const valueRef = useRef<CustomDsFieldItem>(value);
    const { wrapper } = useStyles({});
    const { t } = useLocalTranslation();

    valueRef.current = value;

    const isEncrypted =
      valueRef.current?.field_type === CustomDsFieldTypes.encrypted ||
      (valueRef.current?.field_type?.[0] as CustomDsFieldTypeOption)?.value === CustomDsFieldTypes.encrypted;

    const isChecked = mapBigidSelectOptionToValue(valueRef.current?.field_type) === CustomDsFieldTypes.encrypted;

    const ValueComponent = isEncrypted ? FormPasswordWithMaskField : BigidFormFieldText;

    const handleTabChange = (_: unknown, expanded: boolean) => setExpanded(expanded);

    return (
      <BigidAccordion
        expanded={isExpanded}
        onChange={handleTabChange}
        dataAid={generateDataAid('DataSourceCustomParametersFieldItem', ['option', id])}
        sx={{ borderRadius: '8px' }}
        key={`DataSourceCustomParametersFieldItem-option-${id}`}
      >
        <BigidAccordionSummary
          size={AccordionSummarySizeEnum.large}
          style={{
            backgroundColor: BigidColorsV2.gray[150],
            justifyContent: 'flex-end',
            ...(isExpanded && expandedStyles),
          }}
        >
          <BigidSwitch
            rightLabel={truncate(valueRef.current?.field_name, { length: 45 }) || t('newParameter')}
            checked={valueRef.current?.enable ?? true}
            disabled={disabled}
            onChange={() => {
              setExpanded(!valueRef.current?.enable);
              onChange({ ...valueRef.current, enable: !valueRef.current?.enable });
            }}
          />
        </BigidAccordionSummary>
        <BigidAccordionDetails style={{ backgroundColor: BigidColorsV2.gray[150], padding: '12px 16px' }}>
          <BigidFormFieldLabelWrapper
            id={`bigid-form-field-custom-item-${id}`}
            name={`custom-field-item${id}`}
            isRequired={false}
            noIndent
          >
            <div className={wrapper}>
              <BigidFormFieldText
                labelWidth={'60px'}
                value={valueRef.current?.field_name}
                label={t('labels.name')}
                name={`FormCustomParametersFieldName-${id}`}
                setValue={value => onChange({ ...valueRef.current, field_name: value })}
                disabled={disabled}
              />
              <ValueComponent
                labelWidth={'50px'}
                value={valueRef.current?.field_value}
                label={t('labels.value')}
                touched={passwordTouched}
                name={`FormCustomParametersFieldValue-${id}`}
                setValue={value => {
                  setPasswordTouched(true);
                  onChange({ ...valueRef.current, field_value: value });
                }}
                disabled={disabled}
                misc={{ showValueOnlyIfDirty: true, multiline: true }}
              />
              <BigidPrimaryCheckbox
                label={t('labels.encrypted')}
                disabled={disabled}
                name={`FormCustomParametersFieldType-${id}`}
                onChange={(_, checked) => {
                  onChange({
                    ...valueRef.current,
                    field_type: checked ? CustomDsFieldTypes.encrypted : CustomDsFieldTypes.clear,
                  });
                }}
                checked={isChecked}
              />
            </div>
          </BigidFormFieldLabelWrapper>
        </BigidAccordionDetails>
      </BigidAccordion>
    );
  },
  compareObjectsExceptFunctions,
);

const initCustomItem = (value: ExtraFieldItemData, index: number) => ({
  id: `${value?.field_name}_${index}`,
  value: {
    field_type: [
      {
        value: value?.field_type,
        label:
          CustomDsFieldTypeToLabel[
            value?.field_type === CustomDsFieldTypes.encrypted ? CustomDsFieldTypes.encrypted : CustomDsFieldTypes.clear
          ],
      },
    ],
    field_name: value?.field_name ?? '',
    field_value: value?.field_value ?? '',
    enable: value?.enable ?? true,
  },
});

const INIT_DATA = [createNewItem()];

export const DataSourceCustomParametersField: FC<BigidFieldRenderProps<unknown, ExtraFieldItemData[]>> = memo(
  ({ value, setValue, disabled }) => {
    const { headerWrapper, headerTitleWrapper, fieldWrapper } = useStyles({});
    const { t } = useLocalTranslation();

    const { onAddNew, onDelete, onChangeItem, data } = useExtraFieldControl({
      initialData: value?.length ? value.map(initCustomItem) : INIT_DATA,
      createNewItem: createNewItem,
      onChange: setValue,
    });

    return (
      <>
        <div className={headerWrapper}>
          <BigidHeading5 className={headerTitleWrapper}>{t('customParameters')}</BigidHeading5>
        </div>
        <div className={fieldWrapper}>
          <BigidExtraField
            disabled={disabled}
            data={data}
            onDelete={onDelete}
            onAddNew={onAddNew}
            labelPosition={BigidExtraFieldLabelPosition.top}
            fieldComponent={DataSourceCustomParametersFieldItem}
            onChangeItem={onChangeItem}
            alignCenter={true}
          />
        </div>
      </>
    );
  },
  compareObjectsExceptFunctions,
);

DataSourceCustomParametersFieldItem.displayName = 'DataSourceCustomParametersFieldItem';
DataSourceCustomParametersField.displayName = 'DataSourceCustomParametersField';
