import React, { FC, useEffect, useRef, useState } from 'react';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import { getConfigurations } from '../ActionCenter/ConfigurationManagement/configurationManagementService';
import { queryService } from '../../services/queryService';
import {
  BigidDialog,
  BigidFieldFilterOperator,
  BigidFormStateAndHandlers,
  BigidFormValues,
  BigidLoader,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { JIRA_CONFIGURATION_NAME } from '../ActionableInsights/actionableInsightsService';
import {
  ContentTypes,
  PrivacyRiskCaseResponse,
  ServiceTicketingType,
  STORED_SERVICE_NAME,
} from './RiskRegisterService';
import { NoTicketConfiguration } from '../ActionableInsights/ActionableInsightsGridViews/CaseActionsModal/ModalContent/TicketModal/TicketContent/NoTicketConfiguration';
import { styled } from '@mui/material';
import { CreateRiskTicketForm } from './CreateRiskTicketForm';
import { Configuration } from '../ActionCenter/ConfigurationManagement/configurationManagementTypes';
import { notificationService } from '../../services/notificationService';
import { getFixedT, useLocalTranslation } from './translations';

const LoaderContainer = styled('div')({
  paddingTop: 80,
  height: 200,
});

export interface CreateRiskTicketDialogProps {
  riskCaseData: PrivacyRiskCaseResponse;
  onClose: () => void;
}

export const CreateRiskTicketDialog: FC<CreateRiskTicketDialogProps> = ({ riskCaseData, onClose }) => {
  const [ticketContent, setTicketContent] = useState(ContentTypes.LOADING);
  const [configuration, setConfiguration] = useState<Configuration[]>([]);
  const [isStoredServiceOnline, setIsStoredServiceOnline] = useState(false);
  const formControls = useRef<BigidFormStateAndHandlers>();
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useLocalTranslation('CreateRiskTicket');
  const notificationMessages = getFixedT('Notifications');

  const storedSelectedService = JSON.parse(localStorage.getItem(STORED_SERVICE_NAME));
  const isUserWithPermission = isPermitted(ACTION_CENTER_PERMISSIONS.CREATE_CONFIGURATIONS.name);

  const fetchActionCenterConfigurations = async () => {
    try {
      const chosenConfigurationQuery = queryService.getGridConfigQuery({
        filter: [
          {
            field: 'type',
            operator: 'equal' as BigidFieldFilterOperator,
            value: JIRA_CONFIGURATION_NAME,
          },
        ],
      });
      const { configurations } = await getConfigurations(chosenConfigurationQuery);
      const isStoredServiceActive =
        storedSelectedService?.type === ServiceTicketingType.JIRA ||
        (storedSelectedService?.type === ServiceTicketingType.SERVICE_NOW &&
          configurations.some(config => storedSelectedService?.id === config?.id && !config?.isOffline));
      setIsStoredServiceOnline(isStoredServiceActive);
      localStorage.setItem(
        STORED_SERVICE_NAME,
        JSON.stringify(isStoredServiceActive ? storedSelectedService : configurations?.[0]),
      );
      setConfiguration(configurations);
      if (!isUserWithPermission && configurations?.length === 0) {
        setTicketContent(ContentTypes.NO_CONFIGURATION_WITHOUT_ACCESS);
      } else {
        if (configurations?.length) {
          setTicketContent(ContentTypes.TICKET_CREATION);
        } else {
          setTicketContent(ContentTypes.NO_CONFIGURATION);
        }
      }
    } catch (err: any) {
      notificationService.error(notificationMessages('errorFetchingJiraConfig'));
    }
  };

  useEffect(() => {
    fetchActionCenterConfigurations();
  }, []);

  // const onCreateButtonClick = async () => {
  //   formControls.current.validateAndSubmit(async (values: BigidFormValues) => {
  //     setIsLoading(true);
  //     try {
  //       const generatedFormValues = generateServiceTicketDataFromFormValues(values, storedSelectedService?.id);
  //       await createTicket(generatedFormValues, riskCaseData?.id, storedSelectedService?.type as ServiceTicketingType);
  //       const jiraValidation = await validateTicketCreationAndUpdateGrid(rowData);
  //       if (jiraValidation.errorType === JiraValidationErrorType.CANT_VERIFY) {
  //         notificationService.warning(notificationMessages('TicketNotVerified'), {
  //           duration: 12000,
  //           title: notificationMessages('ticketingInProgressTitle'),
  //         });
  //         onClose();
  //       }
  //       if (jiraValidation.isValid) {
  //         onClose();
  //       } else {
  //         setIsLoading(false);
  //       }
  //     } catch (e) {
  //       notificationService.error(e.message, {
  //         title: notificationMessages('ticketingFailedTitle'),
  //         duration: 3000,
  //       });
  //       setIsLoading(false);
  //     }
  //   });
  // };

  const getCreateTicketDialogContent = () => {
    switch (ticketContent) {
      case ContentTypes.TICKET_CREATION:
        return (
          <CreateRiskTicketForm
            configurations={configuration}
            currentConfiguration={isStoredServiceOnline ? storedSelectedService : configuration?.[0]}
            riskCaseData={riskCaseData}
            formControls={formControls}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        );
      case ContentTypes.NO_CONFIGURATION_WITHOUT_ACCESS:
        return <NoTicketConfiguration withPermission={false} />;
      case ContentTypes.NO_CONFIGURATION:
        return <NoTicketConfiguration withPermission={true} />;
      default:
        return (
          <LoaderContainer>
            <BigidLoader position="relative" />
          </LoaderContainer>
        );
    }
  };

  return (
    <BigidDialog
      isOpen
      onClose={onClose}
      title={t('Labels.createTicket')}
      borderBottom
      buttons={
        ticketContent === ContentTypes.TICKET_CREATION
          ? [
              {
                onClick: onClose,
                component: TertiaryButton,
                text: t('Labels.cancel'),
                size: 'medium',
                alignment: 'right',
              },
              {
                // onClick: () => onCreateButtonClick(),
                onClick: () => {},
                component: PrimaryButton,
                text: t('Labels.create'),
                size: 'medium',
                alignment: 'right',
              },
            ]
          : undefined
      }
    >
      {getCreateTicketDialogContent()}
    </BigidDialog>
  );
};
