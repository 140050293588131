import React, { FC, useContext, useEffect, useReducer } from 'react';
import { ObjectPreviewContext } from '../ActionableInsights/CaseSidePanel/ObjectPreview/hooks/ObjectPreviewContext';
import {
  ActionableInsightsTrackingEvents,
  trackActionableInsightsEvent,
} from '../ActionableInsights/actionableInsightsTrackingUtil';
import { styled } from '@mui/material';
import { ActivityMonitoringDetailsToolbar } from './ActivityMonitoringDetailsToolbar';
import { ActivityMonitoringDetailsContext } from './hooks/ActivityMonitoringDetailsContext';
import { activityMonitoringDetailsReducer } from './hooks/ActivityMonitoringDetailsReducer';
import { ActivityMonitoringDetailsGrid } from './ActivityMonitoringDetailsGrid';

const GridContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 78px);
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 4px;
`;

export const ActivityMonitoringDetailsTab: FC = () => {
  const [state, dispatch] = useReducer(activityMonitoringDetailsReducer, {
    fullyQualifiedName: '',
    filters: [],
  });
  const { objectPreviewData } = useContext(ObjectPreviewContext);
  const fullyQualifiedName = objectPreviewData.fullyQualifiedName;

  useEffect(() => {
    trackActionableInsightsEvent(ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_OBJECT_PREVIEW_ACTIVITY);
  }, []);

  return (
    <ActivityMonitoringDetailsContext.Provider
      value={{
        fullyQualifiedName,
        filters: state.filters,
        dispatch,
      }}
    >
      <GridContainer>
        <ActivityMonitoringDetailsToolbar />
        <ActivityMonitoringDetailsGrid />
      </GridContainer>
    </ActivityMonitoringDetailsContext.Provider>
  );
};
