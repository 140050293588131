import React from 'react';
import { ExcessiveNumberChip } from './ExcessiveNumberChip';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';

const Root = styled('div')`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  max-width: inherit;
`;

export type OverviewOwnersProps = {
  value: string;
  totalNumberOfItems: number;
  threshold: number;
  tooltipList?: string[];
  valueMaxWidth?: string;
};
export const OverviewOwners = ({
  threshold,
  totalNumberOfItems,
  value = '-',
  tooltipList,
  valueMaxWidth,
}: OverviewOwnersProps) => {
  return (
    <Root>
      <BigidBody1 textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" maxWidth={valueMaxWidth}>
        {value}
      </BigidBody1>
      <ExcessiveNumberChip threshold={threshold} totalNumberOfItems={totalNumberOfItems} tooltipList={tooltipList} />
    </Root>
  );
};
