import { BigidBody1, BigidButtonIcon, BigidHeading6 } from '@bigid-ui/components';
import { BigidXIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Title = styled('div')`
  padding: 12px 16px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  border-bottom: ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
`;

const Content = styled('div')`
  padding: 16px 20px;
`;

type SidePanelMasterDetailsProps = PropsWithChildren<{
  title: string;
  onClose: () => void;
}>;
export const SidePanelMasterDetails = ({ onClose, title, children }: SidePanelMasterDetailsProps) => {
  return (
    <Root>
      <Title>
        <BigidHeading6>{title}</BigidHeading6>
        <BigidButtonIcon icon={BigidXIcon} onClick={onClose} />
      </Title>
      <Content>{children}</Content>
    </Root>
  );
};
