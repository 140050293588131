import { BigidBody1, BigidBreadcrumbs, BigidButtonIcon, BigidStyledBadge, Breadcrumb } from '@bigid-ui/components';
import { styled, useTheme } from '@mui/material';
import React from 'react';
import { useGetObjectDetails } from './hooks/useGetObjectDetails';
import { useLocalTranslation } from '../../../../translations';
import { CatalogSidePanelProps } from './CatalogSidePanel';
import { BigidHideIcon, BigidViewIcon } from '@bigid-ui/icons';
import { useFollowObjectMutation } from './hooks/useFollowObjectMutation';
import { formatOwnerToDetails } from '../utils';
import { OverviewOwners } from './Overview/OverviewOwners';
import { tail } from 'lodash';

const Root = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Section = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  flex-wrap: wrap;
`;

const RightSection = styled('div')`
  align-self: flex-end;
`;

const Dot = styled('div')<{ color: string }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

type SidePanelMetadataProps = Pick<CatalogSidePanelProps, 'selectedItem'>;

const PiiBadge = () => {
  const theme = useTheme();
  const { t } = useLocalTranslation('sidePanel.metadata');
  return (
    <BigidStyledBadge
      backgroundColor={theme.vars.tokens.bigid.negativeSubtle}
      size="small"
      label={t('containsPii')}
      icon={<Dot color={theme.vars.tokens.bigid.negative} />}
    />
  );
};

const HasDuplicatesBadge = () => {
  const theme = useTheme();
  const { t } = useLocalTranslation('sidePanel.metadata');
  return (
    <BigidStyledBadge
      backgroundColor={theme.vars.tokens.bigid.negativeSubtle}
      size="small"
      label={t('hasDuplicates')}
      icon={<Dot color={theme.vars.tokens.bigid.negative} />}
    />
  );
};

const HasOpenAccessBadge = () => {
  const theme = useTheme();
  const { t } = useLocalTranslation('sidePanel.metadata');
  return (
    <BigidStyledBadge
      backgroundColor={theme.vars.tokens.bigid.negativeSubtle}
      size="small"
      label={t('openAccess')}
      icon={<Dot color={theme.vars.tokens.bigid.negative} />}
    />
  );
};

export const SidePanelMetadata = ({ selectedItem }: SidePanelMetadataProps) => {
  const {
    id,
    data: { fullPath },
  } = selectedItem;
  const { data } = useGetObjectDetails(String(id));
  const { t } = useLocalTranslation('sidePanel.metadata');

  const { total_pii_count, collaborationStatus, open_access, duplicate_id, object_owners_struct } = data?.data || {};
  const isFollowing = collaborationStatus ? collaborationStatus?.isFollowing : false;

  const breadcrumbs: Breadcrumb[] = fullPath.map(path => ({
    label: path.name,
  }));

  const { mutate } = useFollowObjectMutation(String(id), isFollowing);
  const showPiiBadge = total_pii_count > 0;

  const generalTags = [];
  if (showPiiBadge) {
    generalTags.push(<PiiBadge key="PiiBadge" />);
  }

  if (open_access?.toLowerCase() === 'yes') {
    generalTags.push(<HasOpenAccessBadge key="HasOpenAccessBadge" />);
  }

  if (duplicate_id) {
    generalTags.push(<HasDuplicatesBadge key="HasDuplicatesBadge" />);
  }

  return (
    <Root>
      <Section>
        {generalTags}
        <Section>
          <BigidBody1 whiteSpace="nowrap" textOverflow="ellipsis" flexShrink={1}>
            {t('owner')}
            <OverviewOwners
              threshold={1}
              totalNumberOfItems={object_owners_struct.length}
              value={formatOwnerToDetails(object_owners_struct[0])}
              valueMaxWidth="800px"
              tooltipList={tail(object_owners_struct).map(formatOwnerToDetails)}
            />
          </BigidBody1>
          <BigidBreadcrumbs breadcrumbs={breadcrumbs} />
        </Section>
      </Section>

      <RightSection>
        <BigidButtonIcon
          icon={isFollowing ? BigidHideIcon : BigidViewIcon}
          onClick={() => mutate()}
          tooltipText={t(isFollowing ? 'unfollowAction' : 'followAction')}
        />
      </RightSection>
    </Root>
  );
};
