import React, { FC, useEffect, useState } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { BigidTabsAndContent, BigidTabsAndContentProps, PrimaryButton } from '@bigid-ui/components';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import makeStyles from '@mui/styles/makeStyles';
import { navigateToForbiddenPageIfNecessary } from '../Scans/ScanUtils';
import { BigidAddIcon } from '@bigid-ui/icons';
import { UIView } from '@uirouter/react';
import styled from '@emotion/styled';
import { getFixedT, useLocalTranslation } from './translations';
import { EmailEditor } from '../../components/EmailEditor/EmailEditor';
import { openEmailEditor } from '../../components/EmailEditor/emailEditorService';
import { emailTemplatesEventEmitter, EmailTemplatesEvents } from './emailTemplatesEvents';
import { EmailTemplateTrackingEvents } from './emailTemplateTrackingUtils';
import { isPermitted } from '../../services/userPermissionsService';
import { EMAIL_TEMPLATES_PERMISSIONS } from '@bigid/permissions';

type EmailTemplatesProps = {
  dataAid?: string;
};

export const PAGE_BOTTOM_MARGIN = 10;

const DEFAULT_TAB = 0;
const TAB_HEIGHT = 34;

const useStyles = makeStyles({
  tabsContainer: {
    '& [class*="MuiTab-root"]:last-of-type': {
      marginLeft: 0,
    },
    marginBottom: 0,
  },
  contentContainer: { overflow: 'hidden', display: 'none' },
});

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${PAGE_BOTTOM_MARGIN}px);
  width: 100%;
  overflow: hidden;
`;

const ButtonContainer = styled('div')`
  display: flex;
  gap: 8px;
`;

const View = styled(UIView)({
  height: `calc(100% - ${TAB_HEIGHT}px)`,
});

const getEmailTemplatesHeaderButton = () => {
  const t = getFixedT('Wrapper');
  return (
    <PrimaryButton
      onClick={() => {
        openEmailEditor({
          onClose: () => emailTemplatesEventEmitter.emit(EmailTemplatesEvents.REFRESH_TEMPLATES_VIEW),
        });
      }}
      size="large"
      dataAid="add-template-button"
      startIcon={<BigidAddIcon />}
      text={t('addTemplate') as string}
      bi={{ eventType: EmailTemplateTrackingEvents.EMAIL_TEMPLATES_BROWSE_ADD_TEMPLATE_CLICK }}
    />
  );
};

export const EmailTemplates: FC<EmailTemplatesProps> = ({ dataAid = 'EmailTemplates' }) => {
  const emailTemplatesTabs = [CONFIG.states.EMAIL_TEMPLATES_BROWSE, CONFIG.states.EMAIL_TEMPLATES_BRANDS];
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    emailTemplatesTabs.indexOf($state.$current.name) === -1
      ? DEFAULT_TAB
      : emailTemplatesTabs.indexOf($state.$current.name),
  );
  const classes = useStyles({});
  const { t } = useLocalTranslation();

  useEffect(() => {
    navigateToForbiddenPageIfNecessary();
  }, []);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: t('Wrapper.emailTemplates'),
      ...(isPermitted(EMAIL_TEMPLATES_PERMISSIONS.CREATE.name) && {
        rightSideComponentsContainer: <ButtonContainer>{getEmailTemplatesHeaderButton()}</ButtonContainer>,
      }),
    });
  });

  useEffect(() => {
    const handleEvent = (newTabIndex: number) => {
      setSelectedTabIndex(newTabIndex);
    };

    const unregisterRefresh = emailTemplatesEventEmitter.addEventListener(
      EmailTemplatesEvents.CHANGE_TAB_INDEX,
      handleEvent,
    );

    return function cleanup() {
      unregisterRefresh();
    };
  }, []);

  useEffect(() => {
    const state = emailTemplatesTabs[selectedTabIndex];
    $state.go(state);
  }, []);

  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: { tabsContainer: classes.tabsContainer, contentContainer: classes.contentContainer },
    tabProps: {
      size: 'large',
      tabs: [{ label: t('Wrapper.emailTemplates') }, { label: t('Wrapper.brands') }],
      onChange: tabIndex => {
        const state = emailTemplatesTabs[tabIndex];
        $state.go(state);
        setSelectedTabIndex(tabIndex);
      },
      selectedIndex: selectedTabIndex,
    },
  };

  return (
    <Root data-aid={dataAid}>
      <EmailEditor />
      <BigidTabsAndContent {...tabsAndContentConfig} />
      <View />
    </Root>
  );
};
