import { useState, useEffect, useCallback, SetStateAction } from 'react';
import {
  PageFilterPreferences,
  PageGridPreferences,
  UserPreference,
  userPreferencesService,
} from '../../services/userPreferencesService';
import { BigidGridColumn, BigidGridRow, BigidGridWithToolbarProps, updateFiltersWithParams } from '@bigid-ui/grid';
import { notificationService } from '../../services/notificationService';
import { debounce, omit, pick } from 'lodash';
import { BigidFilter, BigidFilterOptionValue } from '@bigid-ui/components';
import { v4 as uuid } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface UseUserPreferencesState<T = any, U extends BigidGridRow = any> {
  isReady: boolean;
  preferences?: UserPreference<T>;
  gridColumns?: BigidGridColumn<U>[];
  filterToolbarConfig?: FilterToolbarConfig<U>;
  error?: Error;
  key?: string;
  initialToolbarFilterConfig?: FilterToolbarConfig<U>;
}

interface UpdateFunctionParams {
  gridState?: PageGridPreferences;
  filterState?: PageFilterPreferences;
  updateState?: boolean;
}

interface UsePreferencesHookParams<U extends BigidGridRow> {
  stateName: string;
  initialGridColumns?: BigidGridColumn<U>[];
  getInitialFilterToolbarConfig?: () => Promise<FilterToolbarConfig<U>>;
  /* use this property only if you want to override the existing filter preferences, this flag will override also the preferences in the DB. */
  externalFilter?: BigidFilter;
}

type UpdateFunction = (params: UpdateFunctionParams) => void;
type HookReturnValue<T> = UseUserPreferencesState<T> & {
  updatePreferences: UpdateFunction;
  updatePreferencesWithoutDebounce: UpdateFunction;
  setPreferencesState: (value: SetStateAction<UseUserPreferencesState>) => void;
};
type FilterToolbarConfig<T extends BigidGridRow> = BigidGridWithToolbarProps<T>['filterToolbarConfig'];
type UserPreferencesState = PageGridPreferences | PageFilterPreferences;

export function useUserPreferences<T, U extends BigidGridRow>({
  stateName,
  initialGridColumns,
  getInitialFilterToolbarConfig,
  externalFilter,
}: UsePreferencesHookParams<U>): HookReturnValue<T> {
  const [state, setState] = useState<UseUserPreferencesState<T, U>>({
    isReady: false,
    preferences: null,
    error: null,
    gridColumns: initialGridColumns,
    filterToolbarConfig: null,
    initialToolbarFilterConfig: null,
    key: uuid(),
  });

  useEffect(() => {
    async function getPreferences() {
      try {
        const [preferences, initialToolbarFilterConfig] = await Promise.all([
          userPreferencesService.get<T>(stateName),
          getInitialFilterToolbarConfig?.(),
        ]);

        const { grid: gridUserPreferences = {}, filter: filterFromPreferences = {} } = preferences || {};

        if (externalFilter) {
          filterFromPreferences.filter = externalFilter;
        }
        setState({
          isReady: true,
          preferences,
          gridColumns: convertColumnsByUserPreferences(initialGridColumns, gridUserPreferences),
          filterToolbarConfig: updateFiltersWithParams<U>(filterFromPreferences.filter, initialToolbarFilterConfig),
          initialToolbarFilterConfig: initialToolbarFilterConfig,
          key: uuid(),
        });
      } catch (error) {
        notificationService.warning('There was a problem getting user preferences, using default values.');
        console.error(error);
        setState({
          isReady: true,
          error,
        });
      }
    }

    getPreferences();
  }, [stateName, getInitialFilterToolbarConfig, initialGridColumns, externalFilter]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatePreferences: UpdateFunction = useCallback(
    debounce(async ({ filterState = {}, gridState = {}, updateState = false }) => {
      gridState = omit(gridState, ['selectedRowIds', 'allSelected', 'selectedRows']);
      const { grid, filter } = (await userPreferencesService.get(stateName)) || {};

      if (isStateUnchanged(grid, gridState) && isStateUnchanged(filter, filterState)) {
        return;
      }

      const toUpdate = {
        preference: stateName,
        grid: { ...grid, ...gridState },
        filter: { ...filter, ...filterState },
      } as UserPreference<T>;

      updateState &&
        setState(prev => {
          const newState: UseUserPreferencesState<T, U> = {
            ...prev,
            preferences: { ...prev.preferences, ...toUpdate },
            filterToolbarConfig: {
              ...prev.filterToolbarConfig,
              filters: prev.filterToolbarConfig.filters.map(cfgFilter => {
                const valueFilter = toUpdate.filter.filter.find(filter => filter.field === cfgFilter.field);
                const newValue = valueFilter?.value ? getFilterParamsValueForInitialFiltering(valueFilter.value) : [];

                const originalState = prev.initialToolbarFilterConfig?.filters.find(
                  ogFilter => ogFilter.field === cfgFilter.field,
                );
                return {
                  ...cfgFilter,
                  value: newValue,
                  isSelected: newValue?.length > 0,
                  disabled: newValue?.length > 0 ? false : originalState.disabled ?? cfgFilter.disabled,
                  options: cfgFilter.options.map(opt => ({
                    ...opt,
                    isSelected: newValue.includes(opt.value?.toString()),
                  })),
                };
              }),
            },
            key: uuid(),
          };

          return newState;
        });

      userPreferencesService.update(toUpdate);
    }, 1000),
    [stateName],
  );

  const updatePreferencesWithoutDebounce: UpdateFunction = useCallback(
    async ({ filterState = {}, gridState = {} }) => {
      gridState = omit(gridState, ['selectedRowIds', 'allSelected', 'selectedRows']);
      const { grid, filter } = (await userPreferencesService.get(stateName)) || {};

      if (isStateUnchanged(grid, gridState) && isStateUnchanged(filter, filterState)) {
        return;
      }

      const toUpdate = {
        preference: stateName,
        grid: { ...grid, ...gridState },
        filter: { ...filter, ...filterState },
      } as UserPreference<T>;

      setState(prev => {
        const newState: UseUserPreferencesState<T, U> = {
          ...prev,
          preferences: { ...prev.preferences, ...toUpdate },
          filterToolbarConfig: {
            ...prev.filterToolbarConfig,
            filters: prev.filterToolbarConfig.filters.map(cfgFilter => {
              const valueFilter = toUpdate.filter.filter.find(filter => filter.field === cfgFilter.field);
              const newValue = valueFilter?.value ? getFilterParamsValueForInitialFiltering(valueFilter.value) : [];

              const originalState = prev.initialToolbarFilterConfig?.filters.find(
                ogFilter => ogFilter.field === cfgFilter.field,
              );

              return {
                ...cfgFilter,
                value: newValue,
                isSelected: newValue?.length > 0,
                disabled: newValue?.length > 0 ? false : originalState.disabled ?? cfgFilter.disabled,
                options: cfgFilter.options.map(opt => ({
                  ...opt,
                  isSelected: newValue.includes(opt.value?.toString()),
                })),
              };
            }),
          },
          key: uuid(),
        };

        return newState;
      });

      userPreferencesService.update(toUpdate);
    },
    [stateName],
  );

  const setPreferencesState = setState;

  return { ...state, updatePreferences, updatePreferencesWithoutDebounce, setPreferencesState };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertColumnsByUserPreferences = (columns: BigidGridColumn<any>[], gridUserPreferences: PageGridPreferences) => {
  if (!gridUserPreferences) {
    return columns;
  }

  const { columnsOrder = [] } = gridUserPreferences;

  return columns
    .map(column => {
      const { name: columnName, width: defaultWidth, isHiddenByDefault: defaultHidden } = column;
      const width =
        gridUserPreferences.columnsWidth?.find(({ columnName: name }) => name === columnName)?.width || defaultWidth;
      const isHiddenByDefault = Array.isArray(gridUserPreferences.hiddenColumnNames)
        ? !!gridUserPreferences.hiddenColumnNames.find(name => name === columnName)
        : defaultHidden;

      return {
        ...column,
        width,
        isHiddenByDefault,
      };
    })
    .sort(({ name: a }, { name: b }) => columnsOrder.indexOf(a) - columnsOrder.indexOf(b));
};

const isStateUnchanged = (oldState: UserPreferencesState, newState: UserPreferencesState) => {
  oldState = pick(oldState, Object.keys(newState));
  return JSON.stringify(oldState) === JSON.stringify(newState);
};

function getFilterParamsValueForInitialFiltering(value: BigidFilterOptionValue) {
  return Array.isArray(value) ? value : [value];
}
