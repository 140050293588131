import { createContext, Dispatch, SetStateAction } from 'react';
import { AggregatedCategories, CreateDataSourceOnboardingState } from './types';

export const DEFAULT_CREATE_DATA_SOURCE_ONBOARDING_STATE: CreateDataSourceOnboardingState = {
  isInit: true,
  selectedCategory: AggregatedCategories.AllCategories,
  isSearching: false,
};

export interface SelectDataSourceOnboardingTypeContextInterface extends CreateDataSourceOnboardingState {
  setState?: Dispatch<SetStateAction<CreateDataSourceOnboardingState>>;
}

export const SelectDataSourceOnboardingTypeContext = createContext<SelectDataSourceOnboardingTypeContextInterface>(
  DEFAULT_CREATE_DATA_SOURCE_ONBOARDING_STATE,
);
