import React, { FC } from 'react';
import { BigidToggleButton, BigidWidgetContainer } from '@bigid-ui/components';
import { DataRiskCharts } from './charts/DataRiskCharts';

export interface DataRiskWidgetProps {
  title: string;
}

export const DataRiskWidget: FC<DataRiskWidgetProps> = ({ title }) => {
  const onToggleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    console.log('Got new value', value);
  };

  return (
    <BigidWidgetContainer
      content={<DataRiskCharts />}
      titleConfig={{ title }}
      actionsSlot={
        <>
          {/* // TODO: POC Code, to be replaced with real widget actions */}
          <BigidToggleButton
            onChange={onToggleChange}
            initialSelected="findings"
            size="small"
            toggleButtons={[
              { value: 'findings', label: 'Findings' },
              { value: 'objects', label: 'Objects' },
            ]}
          />
        </>
      }
    />
  );
};
