import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { BigidGridQueryComponents, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { RelationGridRow } from './types';
import { deleteRelations, getInitialFilters, getRelations } from './dataCatalogRelationsService';
import { DataCatalogRecord } from '../DataCatalogService';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { $state } from '../../../services/angularServices';
import { BigidDialogPropsWithChildren, DataCatalogRelationsAddDialog } from './DataCatalogRelationsAddDialog';
import { BigidAddIcon, BigidDeleteIcon } from '@bigid-ui/icons';
import { ToolbarActionType } from '@bigid-ui/components';
import { notificationService } from '../../../services/notificationService';
import { columns } from './utils';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { useLocalTranslation } from '../../CatalogDiscovery/toolbar/actions/SaveQuery/translations';

const ContentContainer = styled('div')`
  height: calc(100% - 10px);
  flex-direction: column;
  display: flex;
  overflow: hidden;
`;
const GridContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 4px;
`;

export const DataCatalogRelations: FunctionComponent<DataCatalogRecord> = ({
  fullyQualifiedName,
  source,
}: DataCatalogRecord) => {
  const { t } = useLocalTranslation();
  const getInitialFilterToolbarConfig = useCallback(() => getInitialFilters(), []);
  const initialColumns = useMemo(columns, []);
  const [relations, setRelations] = useState<RelationGridRow[]>([]);
  const [addRelationDialogState, setAddRelationDialogState] = useState<BigidDialogPropsWithChildren>({
    fullyQualifiedName,
    dataSourceName: source,
    isOpen: false,
    onClose: () => undefined,
    title: 'Add Datasets and Vector DB',
    existingRelationsFqns: relations.map(relation => relation.fullyQualifiedName),
  });

  const { isReady, gridColumns, preferences, updatePreferences, filterToolbarConfig } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: initialColumns,
    getInitialFilterToolbarConfig,
  });

  const closeAddRelationDialog = useCallback(() => {
    setAddRelationDialogState(prevState => ({
      ...prevState,
      isOpen: false,
    }));
  }, []);

  const addRelationDialogConfig: BigidDialogPropsWithChildren = useMemo(
    () => ({
      ...addRelationDialogState,
      onClose: closeAddRelationDialog,
    }),
    [addRelationDialogState, closeAddRelationDialog],
  );

  async function fetchRelations(queryComponents: BigidGridQueryComponents) {
    try {
      const relations = await getRelations(queryComponents, fullyQualifiedName);
      setRelations(relations.data);
      return relations;
    } catch (exception) {
      console.error(`An error has occurred: ${exception.message}`);
      notificationService.error(`An error has occurred: ${exception.message}`);
    }
  }

  const gridData: BigidGridWithToolbarProps<RelationGridRow> = {
    columns: gridColumns,
    filterToolbarConfig,
    fetchData: async queryComponents => fetchRelations(queryComponents),
    toolbarActions: [
      {
        label: 'Add',
        icon: BigidAddIcon,
        tooltip: 'Add Relation',
        execute: async () => {
          return new Promise((resolve, reject) => {
            setAddRelationDialogState(prevState => {
              return {
                ...prevState,
                fullyQualifiedName: fullyQualifiedName,
                dataSourceName: source,
                existingRelationsFqns: relations.map((relation: any) => relation.fullyQualifiedName),
                isOpen: true,
                onSubmit: () => {
                  closeAddRelationDialog();
                  resolve({ shouldGridReload: true, shouldClearSelection: true });
                },
                onClose: () => {
                  reject();
                },
              };
            });
          });
        },
        type: ToolbarActionType.ACTION_ICON,
        disable: () => false,
        show: ({ selectedRowIds }) => {
          return selectedRowIds.length == 0;
        },
        placement: 'end',
      },
      {
        label: 'Delete',
        icon: BigidDeleteIcon,
        type: ToolbarActionType.TERTIARY,
        isGlobal: false,
        execute: async params => {
          return new Promise(async resolve => {
            const isDeletionConfirmed = await showConfirmationDialog({
              entityNameSingular: '',
              actionName: 'Delete Relation',
              actionButtonName: t('actions.deleteQuery.confirmationDialog.confirmBtn'),
              customDescription: 'Do you want to delete the selected objects?',
            });

            if (isDeletionConfirmed) {
              try {
                const selectedObjects = params.selectedRows.map(selectedRow => selectedRow.fullyQualifiedName);
                await deleteRelations(fullyQualifiedName, source, selectedObjects);
              } catch ({ message }) {
                console.error(`An error has occurred: ${message}`);
                notificationService.error('An error has occurred');
              } finally {
                resolve({ shouldGridReload: true, shouldClearSelection: true });
              }
            }
          });
        },
        show: ({ selectedRowIds }) => selectedRowIds.length > 0,
      },
    ],
  };

  return (
    <ContentContainer>
      <GridContainer>
        <BigidGridWithToolbar key={fullyQualifiedName} {...gridData}></BigidGridWithToolbar>
        <DataCatalogRelationsAddDialog {...addRelationDialogConfig} />
      </GridContainer>
    </ContentContainer>
  );
};
