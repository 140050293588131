import { BigidTag } from '@bigid-ui/components';
import React, { FC } from 'react';
import { extractEmTagValue, removeHtmlTags, replaceCodeWithColon } from '../../../../../utils';
import { DataComponentProps } from '../../../../../types';

type TagProps = {
  elementToProcess: string;
};

export const TagReason: FC<TagProps> = ({ elementToProcess }) => {
  const { highlight: highlightValue } = extractEmTagValue(elementToProcess);
  const sanitizedHighlight = removeHtmlTags(replaceCodeWithColon(elementToProcess));
  const [tagName, tagValue] = sanitizedHighlight.split(':');

  return (
    <BigidTag
      key={sanitizedHighlight}
      name={tagName}
      value={tagValue}
      hightlight={highlightValue}
      tooltipProps={{ title: '', isDisabled: true }}
    />
  );
};
