import { EventEmitter } from '@bigid-ui/utils';

export enum ExecutiveDashboardEvents {
  SAVE_VIEW = 'saveView',
  HIDE_CURRENT_VIEW = 'hideCurrentView',
  ENABLE_VIEW_SAVING = 'enableViewSaving',
  DISABLE_VIEW_SAVING = 'disableViewSaving',
}

export const executiveDashboardEventEmitter = new EventEmitter<ExecutiveDashboardEvents>();
