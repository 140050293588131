import React, { useMemo } from 'react';
import { BigidBody1, BigidSeverityMarker, BigidWidgetContainer, BigidWidgetList } from '@bigid-ui/components';
import { orderBy } from 'lodash';
import { BigidNoDataIllustration, BigidMoreActionIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../../translations';
import { iconsSchema } from '../../../../../views/Frameworks/utils';
import {
  Content,
  DescriptionWrapper,
  WidgetContainerWrapper,
  WidgetContent,
  SeverityWrapper,
  ElipsedText,
} from '../WidgetsStyles';
import { useTheme } from '@mui/material';
import { MenuItems } from '../WidgetsContainer';

interface TopRiskWidgetProps {
  data: any[];
  dataAid: string;
}

export const TopRiskWidget = ({ data, dataAid }: TopRiskWidgetProps) => {
  const { t } = useLocalTranslation('compliance.widgets');
  const Theme = useTheme();

  const sortedDataByField = orderBy(data, 'failedControlsCount', 'desc').slice(0, 5);

  const WidgetList = useMemo(() => {
    return sortedDataByField.map(element => {
      const expandableList = element.controls.map((control: any) => {
        const Icon = iconsSchema[control.frameworkName];
        return {
          descriptionIcon: <Icon width={20} />,
          description: <ElipsedText>{control.controlName}</ElipsedText>,
          actionButtonIcon: BigidMoreActionIcon,
          actionMenuList: MenuItems,
        };
      });
      return {
        descriptionIcon: (
          <SeverityWrapper>
            <BigidSeverityMarker level={element.policySeverityLevel} />
          </SeverityWrapper>
        ),
        description: element.policyName,
        value: element.failedControlsCount,
        actionButtonIcon: BigidMoreActionIcon,
        actionMenuList: MenuItems,
        slide: <BigidWidgetList items={expandableList} />,
      };
    });
  }, [sortedDataByField]);

  return (
    <WidgetContainerWrapper dataAid={dataAid}>
      <BigidWidgetContainer
        dataAid={dataAid}
        titleConfig={{
          title: t('topRisk.title'),
        }}
        content={
          <Content>
            <WidgetContent>
              <BigidWidgetList items={WidgetList} />
            </WidgetContent>
          </Content>
        }
        contentHeight="190px"
        actionsSlot={<BigidBody1 color={Theme.palette.bigid.gray500}>{t('topRisk.description')}</BigidBody1>}
        emptyStateConfig={{
          isEmpty: data.length === 0,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: (
            <DescriptionWrapper rowDirection="column">
              <BigidBody1>{t('emptyState.defaultTextTitle')}</BigidBody1>
              <BigidBody1>{t('emptyState.defaultTextDescription')}</BigidBody1>
            </DescriptionWrapper>
          ),
        }}
      />
    </WidgetContainerWrapper>
  );
};
