import {
  AbstractQueryNode,
  Operators,
  parseAbstractQueryTreeFromNodes,
  parseGraphQueryNodes,
  QueryExpressionOperator,
  RulesStructNode,
} from '@bigid/query-object-serialization';
import {
  AdvancedToolbarOverrideValue,
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidDropdownOption,
  BigidFieldFilterOperator,
  DateRangeFilterSchema,
  UseCancelablePromise,
} from '@bigid-ui/components';
import { getFixedT } from './translations';
import {
  Aggregation,
  AggregationFilterOperand,
  aggregationIrrelevantNumericFieldIdentifier,
  AggregationItemBase,
  AggregationItemName,
  AggregationPossesionIdentifier,
  AggregationType,
  AttributesGridAggregationItem,
  NonAggregationType,
} from '../catalogDiscoveryTypes';
import {
  GetAggredatedDataRequestObject,
  getAggregatedData,
  GetAggregatedDataPayload,
  GetAggregatedDataResponse,
} from '../catalogDiscoveryService';
import { v4 as uuid } from 'uuid';
import { capitalize, groupBy, partition, pick, uniqBy } from 'lodash';
import { formatNumberCompact } from '../../../utilities/numericDataConverter';
import {
  aggregationFilterConfig,
  booleanFilterToNode,
  fetchAllFilterOptionsLimit,
  mapAggregationToFilterTranslationKey,
} from '../filter/config';
import {
  transformDateFilterToParserNode,
  transformDropdownFilterToParserNode,
} from '../../../utilities/toolbarFilterToBIQL/toolbarFilterToBIQL';

type FetchFilterOptionsPreprocessorFunc = (items: BigidDropdownOption[]) => BigidDropdownOption[];

type FetchFilterOptionsTransformerFunc = (
  aggregation: Aggregation,
  itemsPreprocessor?: FetchFilterOptionsPreprocessorFunc,
) => BigidDropdownOption[];

const mapQuerySerialisationOperator: { [operator in BigidFieldFilterOperator]?: Operators } = {
  in: Operators.IN,
  equal: Operators.EQUAL,
};

export function getAggregationBooleanItemLabel(
  aggregationType: AggregationType,
  aggregationItemName: AggregationItemName,
): string {
  const t = getFixedT('filters');

  switch (aggregationItemName) {
    case AggregationPossesionIdentifier.WITH:
      return t(`${mapAggregationToFilterTranslationKey[aggregationType]}.values.yes`);
    case AggregationPossesionIdentifier.WITHOUT:
      return t(`${mapAggregationToFilterTranslationKey[aggregationType]}.values.no`);
    default:
      return aggregationItemName;
  }
}

export type UnionFilterExtendedForWidgets = BigidAdvancedToolbarFilterUnion & {
  isBoolean?: boolean;
  hasNestedItems?: boolean;
};

export const nestedFilterOptionIdDelimeter = '@#&';

export function getNestedFilterItemId(aggItem: AggregationItemBase) {
  const { aggItemName, aggItemGroup } = aggItem;

  return aggItemGroup ? `${aggItemGroup}${nestedFilterOptionIdDelimeter}${aggItemName}` : aggItemName;
}

export function parseAggregationNestedItemsToFilterOptions(aggregation: Aggregation): BigidDropdownOption[] {
  const { aggData = [] } = aggregation;

  const optionsBase: BigidDropdownOption[] = uniqBy(
    aggData.map(({ aggItemGroup, groupDocCount }) => ({
      id: aggItemGroup,
      value: aggItemGroup,
      displayValue: aggItemGroup,
      annotation:
        !isNaN(groupDocCount) &&
        groupDocCount !== aggregationIrrelevantNumericFieldIdentifier &&
        aggregation.aggName !== AggregationType.ATTRIBUTE_CATEGORY
          ? String(formatNumberCompact(groupDocCount))
          : undefined,
      children: [],
    })),
    ({ id }) => id,
  );

  return aggData.reduce((options, aggItem) => {
    const { aggItemName, aggItemGroup, docCount } = aggItem;

    return options.map(option => {
      if (option.id === aggItemGroup) {
        return {
          ...option,
          children: [
            ...option.children,
            {
              id: getNestedFilterItemId(aggItem),
              parentId: aggItemGroup,
              value: aggItem,
              displayValue: aggItemName,
              annotation:
                !isNaN(docCount) && docCount !== aggregationIrrelevantNumericFieldIdentifier
                  ? String(formatNumberCompact(docCount))
                  : undefined,
            },
          ],
        };
      } else {
        return option;
      }
    });
  }, optionsBase);
}

export function parseAggregationBooleanItemsToFilterOptions(aggregation: Aggregation): BigidDropdownOption[] {
  const { aggData = [], aggName } = aggregation;

  return [AggregationPossesionIdentifier.WITH, AggregationPossesionIdentifier.WITHOUT].reduce((options, option) => {
    const aggItem = aggData.find(({ aggItemName }) => aggItemName === option);

    if (aggItem) {
      const { aggItemName, docCount } = aggItem;

      return [
        ...options,
        {
          id: aggItemName,
          value: aggItem,
          displayValue: getAggregationBooleanItemLabel(aggName, aggItemName),
          annotation:
            !isNaN(docCount) && docCount !== aggregationIrrelevantNumericFieldIdentifier
              ? String(formatNumberCompact(docCount))
              : undefined,
        },
      ];
    } else {
      return options;
    }
  }, []);
}

export function parseAggregationItemsToFilterOptions(
  aggregation: Aggregation,
  filterOptionsPreprocessorFunc?: FetchFilterOptionsPreprocessorFunc,
): BigidDropdownOption[] {
  const { aggData } = aggregation;
  const options = (aggData ?? []).map(aggItem => {
    const { aggItemName, docCount } = aggItem;

    return {
      id: aggItemName,
      value: aggItem,
      displayValue: aggItemName,
      annotation:
        !isNaN(docCount) && docCount !== aggregationIrrelevantNumericFieldIdentifier
          ? String(formatNumberCompact(docCount))
          : undefined,
    };
  });

  return filterOptionsPreprocessorFunc ? filterOptionsPreprocessorFunc(options) : options;
}

export async function fetchFilterOptions(
  payload: GetAggregatedDataPayload,
  cancelable: UseCancelablePromise<GetAggregatedDataResponse>,
  aggItemsTransformerFunc: FetchFilterOptionsTransformerFunc,
  aggItemsPreprocessorFunc?: FetchFilterOptionsPreprocessorFunc,
): Promise<BigidDropdownOption[]> {
  const { aggregations } = await cancelable(getAggregatedData(payload) as Promise<GetAggregatedDataResponse>);

  if (!aggregations?.[0]) {
    return [];
  }

  return aggItemsTransformerFunc(aggregations?.[0], aggItemsPreprocessorFunc);
}

export function parseObjectifiedQueryToString(filter: RulesStructNode): string {
  return filter?.rules.length > 0 ? parseGraphQueryNodes(filter) : '';
}

export function getIsBooleanNegationOperator({ aggItemName }: AggregationItemBase): boolean {
  return aggItemName === AggregationPossesionIdentifier.WITHOUT;
}

export function getObjectifiedQueryOperator(filter: BigidAdvancedToolbarDropdownFilter): Operators {
  const { operator } = filter;

  return mapQuerySerialisationOperator[operator] ?? Operators.IN;
}

export function getObjectifiedQueryLeftOperand(filter: BigidAdvancedToolbarDropdownFilter): string {
  const { id, field } = filter;
  const { leftOperandComposerFunc } = aggregationFilterConfig[id as AggregationType];

  return leftOperandComposerFunc ? leftOperandComposerFunc(filter) : field;
}

export function getObjectifiedQueryRightOperand(options: BigidDropdownOption[]): string | string[] {
  return options.map(({ value }) => {
    const { aggItemName } = value as AggregationItemBase;
    return aggItemName.toString();
  });
}

export function getAggregationBooleanItemQueryValue(): string {
  return 'true'; //NOTE: at this moment query language supports only "true"
}

export function getDropdownFilterQueryNodeWithNestedItems(
  parentFilterId: string,
  filter: BigidAdvancedToolbarDropdownFilter,
): RulesStructNode {
  const { id, options } = filter;

  return {
    id: id.toString(),
    parentId: parentFilterId,
    operator: Operators.OR,
    rules: Object.entries(groupBy(options, ({ parentId }) => parentId)).map(([id, options]) => {
      return {
        id,
        leftOperand: getObjectifiedQueryLeftOperand({ ...filter, options }),
        rightOperand: getObjectifiedQueryRightOperand(options),
        operator: getObjectifiedQueryOperator(filter),
      };
    }),
  };
}

export function getDropdownFilterQueryNodeWithFlatItems(
  parentFilterId: string,
  filter: BigidAdvancedToolbarDropdownFilter,
): RulesStructNode {
  const { id, options } = filter;

  return {
    id: id.toString(),
    parentId: parentFilterId,
    leftOperand: getObjectifiedQueryLeftOperand(filter),
    rightOperand: getObjectifiedQueryRightOperand(options),
    operator: getObjectifiedQueryOperator(filter),
  };
}

export function getDropdownFilterQueryNodeWithBooleanItems(
  parentFilterId: string,
  filter: BigidAdvancedToolbarDropdownFilter,
): RulesStructNode {
  const { id, options } = filter;

  return {
    id: id.toString(),
    operator: Operators.OR,
    parentId: parentFilterId,
    rules: options.map(option => {
      const { value } = option;

      return {
        id: id.toString(),
        parentId: parentFilterId,
        leftOperand: getObjectifiedQueryLeftOperand(filter),
        rightOperand: getAggregationBooleanItemQueryValue(),
        isNegation: getIsBooleanNegationOperator(value),
        operator: getObjectifiedQueryOperator(filter),
      };
    }),
  };
}

export function parseDropdownFilterToObjectifiedQueryNode(
  parentFilterId: string,
  filter: BigidAdvancedToolbarDropdownFilter,
): RulesStructNode {
  const { hasNestedItems, isBoolean } = aggregationFilterConfig[filter.id as AggregationType];

  if (hasNestedItems) {
    return getDropdownFilterQueryNodeWithNestedItems(parentFilterId, filter);
  } else {
    if (isBoolean) {
      return getDropdownFilterQueryNodeWithBooleanItems(parentFilterId, filter);
    } else {
      return getDropdownFilterQueryNodeWithFlatItems(parentFilterId, filter);
    }
  }
}

export function parseRegularFilterOutputToObjectifiedQuery(filter: BigidAdvancedToolbarFilterUnion[]): RulesStructNode {
  const rootNodeId = uuid();

  return filter.reduce(
    (objectifiedQuery, filter) => {
      //NOTE: extend with any further parseable filter preprocessor, especially with DATA_RANGE once it's parseable
      switch (filter.type) {
        case BigidAdvancedToolbarFilterTypes.DROPDOWN:
          return {
            ...objectifiedQuery,
            rules: [...objectifiedQuery.rules, parseDropdownFilterToObjectifiedQueryNode(rootNodeId, filter)],
          };
      }
    },
    {
      id: rootNodeId,
      operator: Operators.AND,
      rules: [],
    },
  );
}

export function parseRegularFilterOutputToQueryString(filter: BigidAdvancedToolbarFilterUnion[]): string {
  const objectifiedQuery = parseRegularFilterOutputToObjectifiedQuery(filter);
  return parseObjectifiedQueryToString(objectifiedQuery);
}

export function parseFilterOutputToQueryString(filter: BigidAdvancedToolbarFilterUnion[]): string {
  let query = '';

  if (filter?.length > 0) {
    const [specialFilters, regularFilters] = partition(filter, ({ id }) =>
      Boolean(aggregationFilterConfig[id as AggregationType].parseFilterToQueryFunc),
    ); //NOTE: once query parser can parse dates consider special vs parseable partition with swith/case DROPDOWN/DATE_RANGE afterwards

    const stringifiedDropdownFilters = parseRegularFilterOutputToQueryString(regularFilters);
    const stringifiedSpecialFilters = specialFilters.map(filter => {
      const { parseFilterToQueryFunc } = aggregationFilterConfig[filter.id as AggregationType];
      return parseFilterToQueryFunc([filter]);
    });

    query = [stringifiedDropdownFilters, ...stringifiedSpecialFilters]
      .filter(queryChunk => typeof queryChunk === 'string' && queryChunk.length > 0)
      .join(' AND ');
  }

  return query;
}

export function clearOutEmptyFilters(filter: BigidAdvancedToolbarFilterUnion[]): BigidAdvancedToolbarFilterUnion[] {
  return filter?.filter(filter => {
    switch (filter.type) {
      case BigidAdvancedToolbarFilterTypes.DROPDOWN: {
        const { options } = filter as BigidAdvancedToolbarDropdownFilter;
        return options.length > 0;
      }
      case BigidAdvancedToolbarFilterTypes.DATE_RANGE: {
        const { options } = filter as BigidAdvancedToolbarDateRangeFilter;
        return options.pickersState.dates.from && options.pickersState.dates.until;
      }
      default:
        return true;
    }
  });
}

type GetFilterOptionsFetchMethodPayload = {
  aggName: AggregationType;
  filterOperand: AggregationFilterOperand;
  filter: BigidAdvancedToolbarFilterUnion[];
  value: string;
  shouldFetchEverything?: boolean;
};
export function getFilterOptionsFetchMethodPayload({
  aggName,
  filter,
  filterOperand,
  value,
  shouldFetchEverything,
}: GetFilterOptionsFetchMethodPayload): GetAggregatedDataPayload {
  const aggregation: Partial<GetAggredatedDataRequestObject> = {
    aggName,
    sorting: [
      {
        field: 'docCount',
        order: 'DESC',
      },
    ],
  };

  if (shouldFetchEverything) {
    aggregation.paging = {
      limit: fetchAllFilterOptionsLimit,
      skip: 0,
    };
  }

  let payloadBase: GetAggregatedDataPayload = {
    aggregations: [aggregation],
  };

  //NOTE: excluding the given filter when user performs an inner search
  const stringifiedQuery = parseFilterOutputToQueryString(value ? filter.filter(({ id }) => id !== aggName) : filter);
  const payloadFilter = [stringifiedQuery, value ? `${filterOperand} = "${value}"` : null]
    .filter(expression => typeof expression === 'string' && expression.length > 0)
    .join(' AND ');

  if (payloadFilter.length > 0) {
    payloadBase = {
      ...payloadBase,
      filter: payloadFilter,
    };
  }

  return payloadBase;
}

export function getFilterByOperand(
  filters: BigidAdvancedToolbarFilterUnion[],
  operand: AggregationFilterOperand,
): BigidAdvancedToolbarFilterUnion {
  return filters?.find(({ field }) => field === operand);
}

export function getAppliedFilterValues(filter: BigidAdvancedToolbarFilterUnion[]): AdvancedToolbarOverrideValue[] {
  return filter.map(({ id, type, options }) => ({ id, type, options }));
}
//TODO: I need a special function for Categories widget with aggItemGroup only
export function getWidgetAppliedFilter(
  filterConfig: BigidAdvancedToolbarFilterUnion,
  items: AggregationItemBase[],
  shouldUndo?: boolean,
): AdvancedToolbarOverrideValue {
  return {
    ...pick(filterConfig, ['id', 'type']),
    options: shouldUndo
      ? []
      : items.map(item => {
          const { aggItemName, docCount = 0 } = item;

          return {
            id: getNestedFilterItemId(item), //NOTE: reconsider using uuid once we have tags on the page
            displayValue: aggItemName,
            value: item,
            annotation: String(docCount),
            isSelected: true,
          };
        }),
  };
}

export function getAttributeNameAppliedFilter(
  filterConfig: BigidAdvancedToolbarFilterUnion,
  items: AttributesGridAggregationItem[],
): AdvancedToolbarOverrideValue {
  return {
    ...pick(filterConfig, ['id', 'type']),
    options: items.reduce((options, item) => {
      const { aggItemName, docCount = 0, categories = [] } = item;
      const categoriesComputed = categories.length === 0 ? ['Other'] : categories;

      return [
        ...options,
        ...categoriesComputed.reduce((options, category) => {
          return [
            ...options,
            {
              id: `${category}${nestedFilterOptionIdDelimeter}${aggItemName}`,
              parentId: category,
              displayValue: aggItemName,
              value: item,
              annotation: String(docCount),
              isSelected: true,
            },
          ];
        }, []),
      ];
    }, []),
  };
}

export function capitaliseFilterOptionDisplayValue(options: BigidDropdownOption[]): BigidDropdownOption[] {
  return options.map(option => {
    return { ...option, displayValue: capitalize(option.displayValue) };
  });
}

export function getIsOverrideFilterEqualToCurrentOne(
  overrideFilter: AdvancedToolbarOverrideValue[],
  currentFilter: BigidAdvancedToolbarFilterUnion[] | AdvancedToolbarOverrideValue[],
): boolean {
  let isEqual = overrideFilter.length === currentFilter.length;

  if (isEqual) {
    for (const overrideFilterEntity of overrideFilter) {
      const counterpartFilter = currentFilter.find(filter => filter.id === overrideFilterEntity.id);

      if (counterpartFilter) {
        switch (overrideFilterEntity.type) {
          case BigidAdvancedToolbarFilterTypes.DROPDOWN: {
            const options = overrideFilterEntity.options as BigidDropdownOption[];
            const counterpartFilterOptions = counterpartFilter.options as BigidDropdownOption[];

            if (options.length === counterpartFilterOptions.length) {
              for (const option of options) {
                isEqual =
                  isEqual &&
                  Boolean((counterpartFilter.options as BigidDropdownOption[]).find(({ id }) => id === option.id));
              }
            } else {
              isEqual = false;
            }
            break;
          }
          case BigidAdvancedToolbarFilterTypes.DATE_RANGE: {
            const { from: fromDate1, until: untilDate1 } = (overrideFilterEntity.options as DateRangeFilterSchema)
              .pickersState.dates;
            const { from: fromDate2, until: untilDate2 } = (counterpartFilter.options as DateRangeFilterSchema)
              .pickersState.dates;

            isEqual = isEqual && fromDate1 === fromDate2 && untilDate1 === untilDate2;
            break;
          }
        }
      } else {
        isEqual = false;
        break;
      }
    }
  }

  return isEqual;
}

const getProcessedTagsFilters = (tagFilters: UnionFilterExtendedForWidgets[], field: AggregationFilterOperand) => {
  const aggItemGroupArray = getUniqueAggItemGrouped(tagFilters);
  const tagFiltersModified: UnionFilterExtendedForWidgets[] = [];

  const optionsDictionaryByAggItemGroup: { [key: string]: BigidDropdownOption[] } = {};

  for (const item of tagFilters) {
    const options = item.options as BigidDropdownOption[];
    for (const option of options) {
      const key = option.value.aggItemGroup;
      if (optionsDictionaryByAggItemGroup.hasOwnProperty(key)) {
        optionsDictionaryByAggItemGroup[key].push({ ...option, id: option.value.aggItemName });
      } else {
        optionsDictionaryByAggItemGroup[key] = [{ ...option, id: option.value.aggItemName }];
      }
    }
  }

  aggItemGroupArray.forEach(aggItemGroup => {
    const options = optionsDictionaryByAggItemGroup[aggItemGroup];
    const filterItem = { ...tagFilters[0], field: `${field}.${aggItemGroup}`, options };
    tagFiltersModified.push(filterItem as UnionFilterExtendedForWidgets);
  });

  return tagFiltersModified;
};

const getUniqueAggItemGrouped = (filters: UnionFilterExtendedForWidgets[]): string[] => {
  const uniqueAggItemGroup: string[] = [];

  filters.forEach(item => {
    const optionsT = item.options as BigidDropdownOption[];

    optionsT.forEach(option => {
      if (!uniqueAggItemGroup.includes(option.value.aggItemGroup)) {
        uniqueAggItemGroup.push(option.value.aggItemGroup);
      }
    });
  });
  return uniqueAggItemGroup;
};

export const getRelevantQueryNodes = (filterToParse: UnionFilterExtendedForWidgets[]) => {
  const dateFilters = filterToParse.filter(
    item => item.type === BigidAdvancedToolbarFilterTypes.DATE_RANGE && !item.isBoolean,
  );

  // without tags filters
  const dropdownFiltersWithoutBooleanFilters = filterToParse.filter(
    item =>
      item.type === BigidAdvancedToolbarFilterTypes.DROPDOWN &&
      !item.isBoolean &&
      item.field !== AggregationFilterOperand.TAGS &&
      item.field !== AggregationFilterOperand.SENSITIVITY_FILTER,
  );

  const nestedFilters = dropdownFiltersWithoutBooleanFilters.filter(item => item.hasNestedItems);

  if (nestedFilters.length > 0) {
    nestedFilters.forEach(filter => {
      const options: BigidDropdownOption[] = filter.options as BigidDropdownOption[];

      filter.options = options.map((option: BigidDropdownOption) => ({
        ...option,
        id: option.value.aggItemName,
      }));
    });
  }
  const keys = Object.keys(aggregationFilterConfig);
  const tagsFiltersNodes: AbstractQueryNode[] = []; // will hold all tag based nodes. Currently, its tags, sensitivity classification and access type.

  keys.forEach(key => {
    const currentConfiguration = aggregationFilterConfig[key as NonAggregationType | AggregationType];

    if (currentConfiguration?.isTagsBasedFilter) {
      const currentFilter = filterToParse?.filter(
        item => item?.field === currentConfiguration.field && item?.id === currentConfiguration.id,
      );

      if (currentFilter.length > 0) {
        const tagsNode = transformDropdownFilterToParserNode(
          getProcessedTagsFilters(currentFilter, currentConfiguration.field as AggregationFilterOperand),
        );

        const tagsNodeModified = parseAbstractQueryTreeFromNodes(tagsNode.flat(), QueryExpressionOperator.OR);
        tagsFiltersNodes.push(tagsNodeModified);
      }
    }
  });

  const dateFiltersNode = transformDateFilterToParserNode(dateFilters);

  const dropdownFiltersWithoutBooleanFiltersNode = transformDropdownFilterToParserNode(
    dropdownFiltersWithoutBooleanFilters,
  );

  const booleanNodes = processBooleanFilters(filterToParse.filter(item => item.isBoolean));

  return [...dropdownFiltersWithoutBooleanFiltersNode, ...dateFiltersNode, ...booleanNodes, ...tagsFiltersNodes];
};

const processBooleanFilters = (booleanFilters: UnionFilterExtendedForWidgets[]): AbstractQueryNode[] => {
  const booleanNodes: AbstractQueryNode[] = [];

  if (booleanFilters.length > 0) {
    booleanFilters.forEach(filter => {
      const node = booleanFilterToNode(filter);
      if (node) {
        booleanNodes.push(node);
      }
    });
  }

  return booleanNodes;
};
