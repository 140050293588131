import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import React from 'react';
import { useLocalTranslation } from '../../../../translations';
import { formatNumber } from '../../../../../../utilities/numericDataConverter';

type ItemsCountProps = {
  count: number;
  translationKey?: string;
};
export const ItemsCount = ({ count, translationKey = 'items' }: ItemsCountProps) => {
  const { t } = useLocalTranslation(`actions.countEntityNames`);
  const itemsInfo = t(translationKey, { itemsCount: formatNumber(count) });

  return <BigidBody1 data-aid={generateDataAid('Actions', ['count'])}>{itemsInfo}</BigidBody1>;
};
