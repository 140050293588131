import React from 'react';
import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { CatalogSearchGridRow, DataSourceGridRow, PolicyGridRow } from '../types';
import { getCatalogEntityIconByType, removeHtmlTags } from '../utils';
import { FooterSection } from './Card/components/Footer/FooterSection';
import { FullPath } from '../components/FullPath/FullPath';
import { FooterDsType } from './Card/components/Footer/FooterDsType';
import { BigidDataSourceOnlyIcon, BigidPolicyIcon } from '@bigid-ui/icons';
import { FooterOwners } from './Card/components/Footer/FooterOwners';
import { FooterModifiedDate } from './Card/components/Footer/FooterModifiedDate';

export function getCatalogGridCellValue(highlight: string): BigidGridColumn<CatalogSearchGridRow>[] {
  return [
    {
      getCellValue: row => {
        const {
          title,
          data: {
            fullPath,
            type,
            datasource: { scannerType },
          },
        } = row;

        const icon = getCatalogEntityIconByType(type);

        const { columns, tags, attributes, category } = row.highlights.reduce(
          (acc, { fieldName, ...rest }) => {
            if (fieldName === 'columns') acc.columns.push({ fieldName, ...rest });
            else if (fieldName === 'tags') acc.tags.push({ fieldName, ...rest });
            else if (fieldName === 'attributes') acc.attributes.push({ fieldName, ...rest });
            else if (fieldName === 'category') acc.category.push({ fieldName, ...rest });
            return acc;
          },
          { columns: [], tags: [], attributes: [], category: [] },
        );

        return {
          card: {
            isCardOutlined: true,
            cardDisplayData: {
              subtitle: {
                value: <FullPath dsType={scannerType} fullPath={fullPath} highlight={highlight} />,
              },
              title: {
                icon,
                value: removeHtmlTags(title),
                highlight,
              },
              footer: {
                value: [
                  <FooterSection
                    sectionName="columns"
                    key="columns"
                    isFirstItem
                    highlightValue={columns}
                    searchValue={highlight}
                  />,
                  <FooterSection
                    key="attributes"
                    sectionName="attributes"
                    highlightValue={attributes}
                    searchValue={highlight}
                  />,
                  <FooterSection key="tags" sectionName="tags" highlightValue={tags} searchValue={highlight} />,
                  <FooterSection
                    key="categories"
                    sectionName="category"
                    highlightValue={category}
                    searchValue={highlight}
                  />,
                ],
              },
            },
          },
        };
      },
      type: BigidGridColumnTypes.PARTITIONED_CARD,
      name: 'objectName',
      title: 'Name',
      width: 'auto',
    },
  ];
}

export function getPolicyGridCellValue(highlight: string): BigidGridColumn<PolicyGridRow>[] {
  return [
    {
      name: 'name',
      title: 'Name',
      type: BigidGridColumnTypes.PARTITIONED_CARD,
      width: 'auto',
      getCellValue: row => {
        const {
          title,
          id,
          data: { _es_date, owner },
        } = row;

        return {
          card: {
            isCardOutlined: true,
            cardDisplayData: {
              title: {
                icon: BigidPolicyIcon,
                value: removeHtmlTags(title),
                highlight,
              },
              id,
              footer: {
                value: [
                  <FooterOwners key="owner" owners={owner} />,
                  <FooterModifiedDate key="modifiedDate" date={_es_date} />,
                ],
              },
            },
          },
        };
      },
    },
  ];
}

export function getDataSourceGridCellValue(highlight: string): BigidGridColumn<DataSourceGridRow>[] {
  return [
    {
      name: 'name',
      title: 'Name',
      type: BigidGridColumnTypes.PARTITIONED_CARD,
      width: 'auto',
      getCellValue: row => {
        const {
          title,
          id,
          data: { owners_v2 = [], type: scannerType, scanDate },
        } = row;

        const stringifiedOwnersSet = new Set(owners_v2.map(owner => owner.id));

        return {
          card: {
            isCardOutlined: true,
            cardDisplayData: {
              title: {
                icon: BigidDataSourceOnlyIcon,
                value: removeHtmlTags(title),
                highlight,
              },
              id,
              footer: {
                value: [
                  <FooterDsType dsType={scannerType} key="dsType" />,
                  <FooterOwners key="owner" owners={[...stringifiedOwnersSet]} />,
                  <FooterModifiedDate key="modifiedDate" date={scanDate} />,
                ],
              },
            },
          },
        };
      },
    },
  ];
}
