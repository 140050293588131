import React, { FC } from 'react';
import { useLocalTranslation } from '../../../../translations';
import { dateTimeService } from '@bigid-ui/i18n';
import { useTheme } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { FooterSectionRoot } from './FooterSection';

type Props = {
  date: string;
};

export const FooterModifiedDate: FC<Props> = ({ date }) => {
  const { t } = useLocalTranslation('modifiedDate');
  const {
    vars: { tokens },
  } = useTheme();

  const valueToDisplay = date
    ? dateTimeService.formatDate(date, { formatString: 'MM/dd/yyyy' })
    : t('noModifiedDateDescription');

  return (
    <FooterSectionRoot>
      <BigidBody1 color={tokens.bigid.foregroundSecondary}>{t('name')}</BigidBody1>
      <BigidBody1 color={tokens.bigid.foregroundTertiary}>{valueToDisplay}</BigidBody1>
    </FooterSectionRoot>
  );
};
