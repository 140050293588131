import React, { ReactNode } from 'react';
import { capitalize } from 'lodash';
import { BigidConfidenceIndicator, BigidConfidenceLevel, BigidContentItem, BigidChipProps } from '@bigid-ui/components';
import { AssociatedColumn, AttributesGridColumn, DataCatalogAttribute } from './DataExplorerAttributesService';
import {
  DataCatalogRecordScannerTypeGroup,
  DetailedObjectType,
} from '../../../../../../DataCatalog/DataCatalogService';
import { GRID_COLUMN_NAMES_MAP, COLUMNS_COLUMN_NAME, CONFIDENCE_LEVEL_COLUMN_NAME } from './constants';
import { BigidGridColumn } from '@bigid-ui/grid';

export const probabilityToPercentage = (probability: number | string): string => {
  if (typeof probability === 'string' && isNaN(Number(probability))) {
    return probability;
  }

  // ! Rounding to the closest integer
  return `${Math.round(Number(probability) * 100)}%`;
};

export const getConfidenceLevelScore = (confidenceLevel: number, rank?: string): string => {
  if (confidenceLevel) {
    return probabilityToPercentage(confidenceLevel);
  }

  if (rank) {
    return probabilityToPercentage(rank);
  }

  return '';
};

export const isAttributeExpired = (attributesData: DataCatalogAttribute[], selectedItem: BigidContentItem) => {
  if (!selectedItem?.attribute_name) {
    return false;
  }
  const selectedAttribute = attributesData.find(attr => attr.attribute_name === selectedItem.attribute_name);
  return !!selectedAttribute?.is_expired;
};

export const getConfidenceLevelColumnTitle = (
  isClassifyFileNamesEnabled: boolean,
  scannerTypeGroup: string,
  detailedObjectType: string,
) => {
  if (isClassifyFileNamesEnabled) {
    if (scannerTypeGroup === DataCatalogRecordScannerTypeGroup.EMAIL) {
      return 'Confidence level';
    }

    if (
      scannerTypeGroup === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED &&
      detailedObjectType !== DetailedObjectType.STRUCTURED_FILE
    ) {
      return 'Field Names';
    }
  }

  return scannerTypeGroup === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED ||
    scannerTypeGroup === DataCatalogRecordScannerTypeGroup.EMAIL
    ? 'Confidence level'
    : 'Column names (confidence level)';
};

const getFieldNameDisplayValue = (fieldName: string): string => GRID_COLUMN_NAMES_MAP[fieldName] || fieldName;

//TODO: refactor this, get rid of the detailedObjectType, make it generic
export const getChipTitleAndLabel = (
  isClassifyFileNamesEnabled: boolean,
  scannerTypeGroup: string,
  detailedObjectType: string,
  columnItem: AssociatedColumn,
): Pick<BigidChipProps, 'title' | 'label'> => {
  const isUnstructured =
    scannerTypeGroup === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED ||
    scannerTypeGroup === DataCatalogRecordScannerTypeGroup.EMAIL;
  const { column_name, calc_confidence_level, rank } = columnItem;

  const confidenceLevelScore = getConfidenceLevelScore(calc_confidence_level, rank);
  const confidenceLevelIndication = capitalize(rank);
  const structuredLabel = confidenceLevelScore ? `${column_name} (${confidenceLevelScore})` : column_name;

  if (isClassifyFileNamesEnabled && detailedObjectType !== DetailedObjectType.STRUCTURED_FILE) {
    switch (scannerTypeGroup) {
      case DataCatalogRecordScannerTypeGroup.EMAIL:
        return {
          label: confidenceLevelScore,
          title: confidenceLevelIndication,
        };

      case DataCatalogRecordScannerTypeGroup.UNSTRUCTURED:
        const name = column_name ? getFieldNameDisplayValue(column_name) : 'Manual';
        return {
          label: confidenceLevelScore ? `${name} (${confidenceLevelScore})` : name,
          title: confidenceLevelIndication ? `${name} (${confidenceLevelIndication})` : name,
        };

      default:
        return {
          label: structuredLabel,
          title: `${column_name} (${confidenceLevelIndication})`,
        };
    }
  }

  return {
    label: isUnstructured ? confidenceLevelScore : structuredLabel,
    title: isUnstructured ? confidenceLevelIndication : `${column_name} (${confidenceLevelIndication})`,
  };
};

//TODO: fix this, get rid of the detailedObjectType !== DetailedObjectType.STRUCTURED_FILE condition
export const getConfidenceLevelIndicator = (
  isClassifyFileNamesEnabled: boolean,
  rank: string,
  detailedObjectType: string,
): ReactNode => {
  let ConfidenceLevelIndicator;

  if (isClassifyFileNamesEnabled && detailedObjectType !== DetailedObjectType.STRUCTURED_FILE && rank) {
    ConfidenceLevelIndicator = <BigidConfidenceIndicator level={rank.toLowerCase() as BigidConfidenceLevel} />;
  }

  return ConfidenceLevelIndicator;
};

export const getDisplayGridColumns = (
  initialColumns: BigidGridColumn<AttributesGridColumn>[],
  isUnstructured: boolean,
  isConfidenceLevelColumnEnabled: boolean,
) => {
  let filteredGridColumn = initialColumns;
  if (isUnstructured || isConfidenceLevelColumnEnabled) {
    filteredGridColumn = filteredGridColumn.filter(({ name }) => name !== COLUMNS_COLUMN_NAME);
  }

  if (!isConfidenceLevelColumnEnabled) {
    filteredGridColumn = filteredGridColumn.filter(({ name }) => name !== CONFIDENCE_LEVEL_COLUMN_NAME);
  }
  return filteredGridColumn;
};
