import { BigidAreaChart } from '@bigid-ui/visualisation';
import { BigidColorsV2, capitalizeFirstLetter } from '@bigid-ui/components';
import React, { FC } from 'react';

export const DataCoverageAreaChart: FC = () => {
  const data = [
    {
      category: '28/04',
      listed: 2000,
      scanned: 100,
    },
    {
      category: '29/04',
      listed: 5000,
      scanned: 1000,
    },
    {
      category: '30/04',
      listed: 8000,
      scanned: 2500,
    },
    {
      category: '01/05',
      listed: 15000,
      scanned: 11200,
    },
    {
      category: '02/05',
      listed: 28000,
      scanned: 21200,
    },
    {
      category: '03/05',
      listed: 49000,
      scanned: 31200,
    },
    {
      category: '04/05',
      listed: 53000,
      scanned: 34700,
    },
    {
      category: '05/05',
      listed: 57000,
      scanned: 38200,
    },
    {
      category: '06/05',
      listed: 64000,
      scanned: 44000,
    },
    {
      category: '07/05',
      listed: 68000,
      scanned: 56000,
    },
    {
      category: '08/05',
      listed: 70960,
      scanned: 61600,
    },
  ];

  return (
    <BigidAreaChart
      legendProps={{ hideLegend: true }}
      colorDataMap={{ listed: BigidColorsV2.blue[600], scanned: BigidColorsV2.cyan[300] }}
      isLineSmoothed={true}
      numberFormat="#.A"
      data={data}
      seriesLabelFormatter={series => capitalizeFirstLetter(series)}
    />
  );
};
