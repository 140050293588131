import React, { FC, memo, useMemo } from 'react';
import {
  BigidPaper,
  objectToQueryString,
  QueryParams,
  BigidDialog,
  SecondaryButton,
  BigidIconSize,
  BigidIconSourceType,
  BigidColors,
  BigidIcon,
  BigidFilter,
} from '@bigid-ui/components';
import {
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridRow,
  BigidGridColumnTypes,
  BigidGridColumn,
  ChipsFormatterProps,
  IconFormatterProps,
  BigidGridQueryComponents,
} from '@bigid-ui/grid';
import makeStyles from '@mui/styles/makeStyles';
import { LocalOfferOutlined } from '@mui/icons-material';
import { getSimilarColumns, SimilarColumn } from '../../../../../../../DataCatalog/DataCatalogColumns';
import { notificationService } from '../../../../../../../../services/notificationService';
import { BigidKeyIcon } from '@bigid-ui/icons';

export interface SimilarColumnsDialogProps {
  clusterId: string;
  columnName: string;
  fullyQualifiedName: string;
  isOpen: boolean;
  onClose?: () => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '500px',
    width: '100%',
  },
});

type SimilarColumnGridColumn = BigidGridRow &
  Omit<SimilarColumn, 'attributes' | 'isPrimary' | 'fullyQualifiedName' | 'tags'> & {
    attributes: ChipsFormatterProps;
    primaryKeyIndicator?: IconFormatterProps;
    tags: ChipsFormatterProps;
  };

interface SimilarColumnFilter {
  include: BigidFilter;
  exclude: BigidFilter;
}

const parseFilterToQuery = ({ include, exclude }: SimilarColumnFilter): string => {
  const excludeQuery = exclude.reduce((query, { value, field }) => {
    return query.length > 0 ? `${query} OR (NOT ${field} IN ("${value}"))` : `(NOT ${field} IN ("${value}"))`;
  }, '');

  const includeQuery = include.reduce((query, { value, field }) => {
    return query.length > 0 ? `${query} OR ${field} = "${value}"` : `${field} = "${value}"`;
  }, '');

  return includeQuery.length > 0 ? `(${excludeQuery}) AND (${includeQuery})` : excludeQuery;
};

const getDridData = (responseData: SimilarColumn[]): SimilarColumnGridColumn[] => {
  return responseData.map((similarColumn, index) => {
    const {
      columnName,
      tableName,
      businessAttribute,
      source,
      dataType,
      attributes = [],
      tags = [],
      isPrimary = false,
    } = similarColumn;

    const columnAttributes: ChipsFormatterProps = {
      chips: {
        value: attributes.map(({ attribute_name }, index) => {
          return {
            id: index,
            label: attribute_name,
            title: attribute_name,
          };
        }),
        isDisabled: true,
      },
    };

    const primaryKeyIndicator: IconFormatterProps = isPrimary
      ? {
          icon: {
            icon: BigidKeyIcon,
            color: BigidColors.gray[300],
            type: BigidIconSourceType.CUSTOM,
            size: BigidIconSize.MEDIUM,
          },
        }
      : undefined;

    const columnTags: ChipsFormatterProps = {
      chips: {
        value: tags.map(({ tagName, tagValue }) => ({
          label: `${tagName} : ${tagValue}`,
          icon: <BigidIcon icon={LocalOfferOutlined} size={BigidIconSize.REGULAR_PLUS} />,
        })),
        isDisabled: true,
      },
    };

    return {
      id: index,
      columnName,
      businessAttribute,
      tableName,
      source,
      primaryKeyIndicator,
      dataType,
      attributes: columnAttributes,
      tags: columnTags,
    };
  });
};

export const SimilarColumnsDialog: FC<SimilarColumnsDialogProps> = memo(
  ({ clusterId, columnName, fullyQualifiedName, isOpen, onClose }) => {
    const classes = useStyles({});

    const handleOnClose = (): void => {
      onClose();
    };

    const columns: BigidGridColumn<SimilarColumnGridColumn>[] = useMemo(
      () => [
        {
          name: 'columnName',
          title: 'Column Name',
          getCellValue: ({ columnName }) => columnName,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'businessAttribute',
          title: 'Business Attribute',
          getCellValue: ({ businessAttribute }) => businessAttribute,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'primaryKeyIndicator',
          title: 'Primary Key',
          getCellValue: ({ primaryKeyIndicator }) => primaryKeyIndicator,
          type: BigidGridColumnTypes.ICON,
          width: 150,
        },
        {
          name: 'tableName',
          title: 'Table Name',
          getCellValue: ({ tableName }) => tableName,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'source',
          title: 'Data Source Name',
          getCellValue: ({ source }) => source,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'dataType',
          title: 'Data Type',
          getCellValue: ({ dataType }) => dataType,
          type: BigidGridColumnTypes.TEXT,
          width: 150,
        },
        {
          name: 'attributes',
          title: 'Attributes',
          width: 400,
          getCellValue: ({ attributes }) => attributes,
          type: BigidGridColumnTypes.CHIPS,
        },
        {
          name: 'tags',
          title: 'Tags',
          width: 400,
          getCellValue: ({ tags }) => tags,
          type: BigidGridColumnTypes.CHIPS,
        },
      ],
      [],
    );

    const gridConfig: BigidGridWithToolbarProps<SimilarColumnGridColumn> = useMemo(
      () => ({
        entityName: 'columns',
        showSortingControls: true,
        showFilteringControls: false,
        filterToolbarConfig: {
          searchConfig: {
            searchFilterKeys: [
              'column_name',
              'business_attribute.friendly_name',
              'fully_qualified_name',
              'table_name',
              'source',
            ],
            initialValue: '',
            operator: 'equal',
          },
        },
        fetchData: async (queryComponents: BigidGridQueryComponents) => {
          try {
            const excludeColumnFilter: BigidFilter = [
              {
                field: 'column_name',
                value: columnName,
                operator: 'notEqual',
              },
              {
                field: 'fully_qualified_name',
                value: fullyQualifiedName,
                operator: 'notEqual',
              },
            ];

            const query = objectToQueryString({
              ...(queryComponents as QueryParams),
              clusterId,
              filter: parseFilterToQuery({ exclude: excludeColumnFilter, include: queryComponents.filter }),
            });
            const { results, totalCount } = await getSimilarColumns(query);
            const data = getDridData(results);

            return {
              totalCount,
              data,
            };
          } catch ({ message }) {
            notificationService.error('An error has occurred');
            console.error(`An error has occurred: ${message}`);
            return {
              totalCount: 0,
              data: [],
            };
          }
        },
        columns,
      }),
      [clusterId, columnName, columns, fullyQualifiedName],
    );

    return (
      <BigidDialog
        title={`Similar columns to '${columnName}'`}
        isOpen={isOpen}
        onClose={handleOnClose}
        borderTop
        buttons={[
          {
            component: SecondaryButton,
            onClick: handleOnClose,
            text: 'Close',
          },
        ]}
        maxWidth="xl"
      >
        <div className={classes.root}>
          <BigidPaper>
            <BigidGridWithToolbar {...gridConfig} />
          </BigidPaper>
        </div>
      </BigidDialog>
    );
  },
);
