import { BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';
import { filtersToQueryParams } from '../activityMonitoringService';

export enum ReducerActions {
  'UPDATE_FILTERS',
}

export interface ActivityMonitoringDetailsReducerState {
  fullyQualifiedName: string;
  filters: BigidAdvancedToolbarFilterUnion[];
}

export interface ActivityMonitoringDetailsReducerAction {
  type: ReducerActions;
  payload: Partial<ActivityMonitoringDetailsReducerState>;
}

export const activityMonitoringDetailsReducer: React.Reducer<
  ActivityMonitoringDetailsReducerState,
  ActivityMonitoringDetailsReducerAction
> = (state, { type, payload }) => {
  switch (type) {
    case ReducerActions.UPDATE_FILTERS: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};
