import React, { FC, ReactElement, ReactHTMLElement, ReactNode } from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { BigidDonutChartProps, BigidDonutChart } from '@bigid-ui/visualisation';

type AlignITemsDirection = 'start' | 'end' | 'center';

interface PieInsightChartProps {
  dataAid?: string;
  dataAidLabels?: string;
  title?: string;
  data: BigidDonutChartProps['data'];
  total?: number;
  withTooltips?: boolean;
  showZeroedTotal?: boolean;
  showZeroedData?: boolean;
  entityName?: string;
  alignItems?: AlignITemsDirection;
  size?: any;
  insightSubTitleContent?: ReactElement;
  legendProps?: BigidDonutChartProps['legendProps'];
  chartSize?: BigidDonutChartProps['chartSize'];
  showSumValue?: BigidDonutChartProps['showSumValue'];
}

const InsightsSubTitle = styled(BigidBody1)`
  margin-bottom: 18px;
  font-weight: bold;
`;

const PiiChartRow = styled('div')<{ alignItems: AlignITemsDirection }>`
  display: flex;
  gap: 16px;
  align-items: ${({ alignItems }) => alignItems};
`;

export const PieInsightChart: FC<PieInsightChartProps> = ({
  title,
  data,
  withTooltips,
  entityName = '',
  dataAid = 'PieInsightChart',
  alignItems = 'start',
  insightSubTitleContent: InsightSubTitleContent,
  legendProps = {},
  chartSize,
  showSumValue = true,
}) => {
  return (
    <div data-aid={dataAid}>
      {(title || InsightSubTitleContent) && <InsightsSubTitle>{InsightSubTitleContent || title}</InsightsSubTitle>}
      <PiiChartRow alignItems={alignItems}>
        <BigidDonutChart
          entityName={entityName}
          chartSize={chartSize}
          data={data}
          emptyStateProps={{}}
          legendProps={legendProps}
          showTooltip={withTooltips}
          showSumValue={showSumValue}
        />
      </PiiChartRow>
    </div>
  );
};
