import React, { FC, useCallback, useState, useEffect, memo } from 'react';
import { BigidSkeletonGenerator } from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { UseCatalogDiscoveryResponse } from '../../useCatalogDiscovery';
import { PieChartWidget, PieChartWidgetSeries } from '../../widgets/PieChartWidget/PieChartWidget';
import { getAggregatedData, GetAggregatedDataPayload, GetAggregatedDataResponse } from '../../catalogDiscoveryService';
import { AggregationFilterOperand, AggregationType } from '../../catalogDiscoveryTypes';
import {
  getFiltersExcludingCurrentType,
  getCurrentTypeAppliedOptions,
  getPieChartWidgetKnownData,
} from '../../utils/widgets';
import { PieChartWidgetWrapper } from '../../utils/PieChartWidgetWrapper';
import { useLocalTranslation } from './translations';
import { useFetchDataCancelable } from '../../config/useFetchDataCancelable';
import { pieChartWidgetSkeletonConfig } from '../../config/skeleton';
import { CatalogDiscoveryWidget } from '../../config/widgets';
import { BigidDonutChartSliceData } from '@bigid-ui/visualisation';
import { getRelevantQueryNodes } from '../../filter/utils';
import {
  parseAbstractQueryTreeIntoString,
  parseExpressionsIntoAbstractQueryTree,
} from '@bigid/query-object-serialization';

export interface ObjectStatusPieChartProps
  extends Pick<UseCatalogDiscoveryResponse, 'query' | 'onWidgetFilterChange' | 'onDataFetchStatusChange' | 'filter'> {
  dataAid?: string;
  dataTourId?: string;
  isPageInitialised: boolean;
}

export const ObjectStatusPieChart: FC<ObjectStatusPieChartProps> = memo(
  ({
    dataAid = 'ObjectStatusPieChart',
    dataTourId = 'ObjectStatusPieChart',
    filter,
    onWidgetFilterChange,
    onDataFetchStatusChange,
    isPageInitialised,
  }) => {
    const { fetchObjectStatusCancelable } = useFetchDataCancelable();
    const { t } = useLocalTranslation();
    const [chartData, setChartData] = useState<PieChartWidgetSeries[]>(
      getPieChartWidgetKnownData({ aggregationData: [], aggregationType: AggregationType.OBJECT_STATUS }),
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const filterToParse = getFiltersExcludingCurrentType(filter, AggregationFilterOperand.OBJECT_STATUS);
    const pickedOptions = getCurrentTypeAppliedOptions(filter, AggregationFilterOperand.OBJECT_STATUS);

    const queryTreeNodes = getRelevantQueryNodes(filterToParse);

    const queryTree = parseExpressionsIntoAbstractQueryTree(queryTreeNodes);
    const query = parseAbstractQueryTreeIntoString(queryTree);

    const fetchAggregationData = useCallback(async () => {
      try {
        const payload: GetAggregatedDataPayload = {
          filter: query,
          aggregations: [
            {
              aggName: AggregationType.OBJECT_STATUS,
              isTotalRequired: true,
            },
          ],
        };

        setIsLoading(true);
        onDataFetchStatusChange(CatalogDiscoveryWidget.OBJECT_STATUS_PIE_CHART, true);

        const { aggregations } = await fetchObjectStatusCancelable(
          getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
        );

        const data = getPieChartWidgetKnownData({
          aggregationData: aggregations?.[0]?.aggData,
          aggregationType: AggregationType.OBJECT_STATUS,
          filters: filter,
          pickedOptions,
        });

        setChartData(data);
      } catch ({ isCanceled, message }) {
        if (!isCanceled) {
          console.error(`An error has occurred: ${message}`);
        }
      } finally {
        setIsLoading(false);
        onDataFetchStatusChange(CatalogDiscoveryWidget.OBJECT_STATUS_PIE_CHART, false);
      }
    }, [fetchObjectStatusCancelable, filter, query, onDataFetchStatusChange]);

    const handleSectorClick = useCallback(
      (data: BigidDonutChartSliceData): void => {
        if (data.category.length > 0) {
          const { aggItem, isAppliedFilter } = chartData.find(({ category }) => category === data.category);
          if (!isAppliedFilter) {
            onWidgetFilterChange(AggregationType.OBJECT_STATUS, [aggItem], isAppliedFilter);
          }
        }
      },
      [chartData, onWidgetFilterChange],
    );

    const handleLegendItemClick = useCallback(
      (data: BigidDonutChartSliceData): void => {
        const { aggItem, isAppliedFilter } = chartData.find(({ category }) => category === data.category);
        if (data.value > 0 && !isAppliedFilter) {
          onWidgetFilterChange(AggregationType.OBJECT_STATUS, [aggItem]);
        }
      },
      [chartData, onWidgetFilterChange],
    );

    useEffect(() => {
      if (isPageInitialised) {
        fetchAggregationData();
      }
    }, [fetchAggregationData, isPageInitialised]);

    return (
      <PieChartWidgetWrapper dataAid={dataAid}>
        {isPageInitialised ? (
          <PieChartWidget
            dataAid={generateDataAid(dataAid, ['widget'])}
            dataTourId={generateGuidedTourId(dataTourId, ['widget'])}
            entityName={t('title')}
            tooltipText={t('tooltip.text')}
            data={chartData}
            onSectorClick={handleSectorClick}
            onLegendItemClick={handleLegendItemClick}
            isBusy={isLoading}
          />
        ) : (
          <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...pieChartWidgetSkeletonConfig} />
        )}
      </PieChartWidgetWrapper>
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query && prevProps.isPageInitialised === newProps.isPageInitialised,
);
