import React, { useMemo } from 'react';
import { styled } from '@mui/material';
import { BigidChevronRightIcon, BigidChevronLeftIcon } from '@bigid-ui/icons';

export const ArrowWrapper = styled('div')<{ withHover?: boolean; arrowSectionWidth: number }>(
  ({ withHover = true, arrowSectionWidth, theme }) => `
	position: relative;
  height: 120px;
  width: ${arrowSectionWidth}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 1000;
	${withHover && `&:hover { background-color: ${theme.palette.bigid.gray150}; cursor: pointer; }`}
`,
);

type ArrowType = 'left' | 'right';

interface ArrowSectionProps {
  handleSection: (value: number) => void;
  currentIndex: number;
  dataItemsLength: number;
  arrowType: ArrowType;
  itemsToShow: number;
  arrowSectionWidth: number;
}

export const ArrowSection = ({
  handleSection,
  arrowType,
  currentIndex,
  dataItemsLength,
  itemsToShow,
  arrowSectionWidth,
}: ArrowSectionProps) => {
  const handleClick = () => {
    switch (true) {
      case arrowType === 'left':
        handleSection(currentIndex + 1);
        break;
      case arrowType === 'right':
        handleSection(currentIndex - 1);
        break;
      default:
        handleSection(currentIndex);
        break;
    }
  };

  const ArrowComponent = useMemo(() => {
    switch (true) {
      case currentIndex === 0 && arrowType === 'left':
        return <ArrowWrapper withHover={false} arrowSectionWidth={arrowSectionWidth} />;
      case dataItemsLength + currentIndex === itemsToShow && arrowType === 'right':
        return <ArrowWrapper withHover={false} arrowSectionWidth={arrowSectionWidth} />;
      case arrowType === 'left':
        return (
          <ArrowWrapper onClick={handleClick} arrowSectionWidth={arrowSectionWidth}>
            <BigidChevronLeftIcon />
          </ArrowWrapper>
        );
      case arrowType === 'right':
        return (
          <ArrowWrapper onClick={handleClick} arrowSectionWidth={arrowSectionWidth}>
            <BigidChevronRightIcon />
          </ArrowWrapper>
        );
      default:
        return <ArrowWrapper arrowSectionWidth={arrowSectionWidth} />;
    }
  }, [currentIndex, arrowType]);

  return <>{ArrowComponent}</>;
};
