import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { ToolbarActionType, ToolbarAction, BigidContentItem, ColumnInfo } from '@bigid-ui/components';
import { BigidGridProps, BigidGrid, BigidGridColumn } from '@bigid-ui/grid';
import {
  AppIntegrationSettings,
  AppIntegrationSettingsTabConfig,
  DataCatalogObjectType,
  DataCatalogRecord,
  DataCatalogRecordScannerTypeGroup,
  DetailedObjectType,
  addFollowedObjects,
  deleteFollowedObjects,
  deleteIgnoreConfidenceLevel,
  getConfidenceLevel,
  getIntegrationSettings,
  postIgnoreConfidenceLevel,
} from '../DataCatalogService';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { notificationService } from '../../../services/notificationService';
import { DataCatalogObjectIssueDialog, DataCatalogObjectIssueDialogProps } from '../DataCatalogObjectIssueDialog';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { getIsClassifyFileNamesEnabled } from '../utils';
import { getTitleServiceConfig } from './utils';
import { useLocalTranslation } from '../translations';
import { styled } from '@mui/material';
import { fetchGridData, fetchGridDataCount, getColumns } from './newDataCatalogService';
import { DataCatalogExtendedRecord } from './types';
import { NewDataCatalogToolbar } from './NewDataCatalogToolbar';
import { useDataCatalogContext } from './newDataCatalogContext';
import { NewDataCatalogSearchbar } from './NewDataCatalogSearchbar';
import { DataCatalogActionRow } from './NewDataCatalogActionRow/NewDataCatalogActionRow';
import { BigidLayoutEmptyState, BigidMasterDetailsContent, BigidMasterDetailsContentProps } from '@bigid-ui/layout';
import {
  BigidHideIcon,
  BigidIgnoreConfidenceLevel,
  BigidNoDataIllustration,
  BigidRestoreConfidenceLevel,
  BigidUserReportIcon,
  BigidViewIcon,
} from '@bigid-ui/icons';
import { showGenericConfirmationDialog } from '../../../services/genericConfirmationDialog';
import { analyticsService } from '../../../services/analyticsService';
import { CatalogEventsEnum } from '../events';
import { AppIntegrationTab, validateAppTabSettings } from '../DataCatalogAppIntegrations/AppIntegrationTab';
import { DataCatalogDetails } from '../DataCatalogDetails';
import { DataCatalogAttributes } from '../DataCatalogAttributes';
import { DataCatalogColumns } from '../DataCatalogColumns';
import { DataCatalogLineage } from '../DataCatalogLineage';
import { PreviewFileContent } from '../PreviewFileObjects/PreviewFileContent';
import { DataCatalogTablePreview } from '../DataCatalogTablePreview';
import { DataCatalogFileDuplicates } from '../DataCatalogFileDuplicates/DataCatalogFileDuplicates';
import { DataCatalogAuditTrail } from '../DataCatalogAuditTrail/DataCatalogAuditTrail';
import { DATA_OVERVIEW_PERMISSIONS, LINEAGE_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { makeStyles } from '@mui/styles';
import { getSelectedItem } from './newDataCatalogUtils';
import { useQueries } from 'react-query';
import { DataCatalogFields } from '../DataCatalogFields';
import { DataCatalogActivityMonitoring } from '../DataCatalogActivityMonitoring/DataCatalogActivityMonitoring';
import { DataCatalogAsyncOpsProcessingWidget } from '../DataCatalogAsyncOps/DataCatalogAsyncOpsProcessingWidget';
import { DataCatalogSimilarTables } from '../DataCatalogSimilarTables/DataCatalogSimilarTables';
import { DataCatalogRelations } from '../DataCatalogRelations';

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
  },
});

const ContentContainer = styled('div')`
  height: calc(100% - 10px);
  flex-direction: column;
  display: flex;
  overflow: hidden;
`;

const NoDataContainer = styled('td')`
  left: 50%;
  height: 600px;
  position: fixed;
`;

const SearchbarContainer = styled('div')`
  position: relative;
  min-height: 62px;
  width: 100%;
  margin-bottom: 16px;
`;

const GridContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 78px);
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 4px;
`;

const ProcessingWidgetWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 10px;
`;

export const previewTableDataSourcesBlacklist: string[] = ['cassandra', 'ldap', 'external-catalog'];
export const previewFileDataSourcesBlacklist: string[] = ['external-catalog'];

const PAGE_SIZE = 100;

export const NewDataCatalog: FC = () => {
  const { t } = useLocalTranslation();
  const [hasSelectedFirstItem, setHasSelectedFirstItem] = useState(false);
  const [hideColumns, setHideColumns] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<BigidContentItem>(null);
  const [shouldDisplayGrid, setShouldDisplayGrid] = useState(false);
  const [rowsSkip, setSkip] = useState(0);
  const {
    state: { selectedFilters, isCustomQuery, filterString, gridDataFetchEnabled },
    shouldSelectFirstItem,
  } = useDataCatalogContext();

  const classes = useStyles({});

  const [objectIssueDialogState, setObjectIssueDialogState] = useState<DataCatalogObjectIssueDialogProps>({
    isOpen: false,
  });

  const {
    isTagsBulkAssignmentEnabled,
    isColumnClusteringEnabled,
    isFollowObjectEnabled,
    isObjectIssuesEnabled,
    isLineageEnabled,
    isActivityMonitoringEnabled,
    isClassifyFileNamesFFEnabled,
    isElasticEnabled,
    isSimilarTablesEnabled,
    isDataOverviewPermitted,
    isTagsCreatePermitted,
  } = useMemo(
    () => ({
      isTagsBulkAssignmentEnabled: Boolean(getApplicationPreference('TAGS_BULK_ASSIGNMENT_ENABLED')),
      isTagsCreatePermitted: isPermitted(TAGS_PERMISSIONS.CREATE.name),
      isColumnClusteringEnabled: getApplicationPreference('SHOW_STRUCTURED_CLUSTERING'),
      isFollowObjectEnabled:
        getApplicationPreference('FOLLOW_OBJECT_ENABLED') && getApplicationPreference('CATALOG_OBJECT_MANAGE_FF'),
      isObjectIssuesEnabled: getApplicationPreference('OBJECT_ISSUES_ENABLED'),
      isLineageEnabled:
        getApplicationPreference('ENABLE_LINEAGE') &&
        isPermitted(LINEAGE_PERMISSIONS.READ.name) &&
        isPermitted(LINEAGE_PERMISSIONS.ACCESS.name),
      isActivityMonitoringEnabled: getApplicationPreference('ENABLE_DATA_CATALOG_ACTIVITY_MONITORING'),
      isClassifyFileNamesFFEnabled: getIsClassifyFileNamesEnabled(),
      isElasticEnabled:
        getApplicationPreference('METADATA_SEARCH_ENABLED') && getApplicationPreference('DATA_CATALOG_ELASTIC_ENABLED'),
      isSimilarTablesEnabled: getApplicationPreference('SIMILAR_TABLES_ENABLED'),
      isDataOverviewPermitted: isPermitted(DATA_OVERVIEW_PERMISSIONS.ACCESS.name),
    }),

    [],
  );

  const closeObjectIssueDialog = useCallback(() => {
    setObjectIssueDialogState({ isOpen: false });
  }, []);

  const [fetchGridDataResult, fetchCountResult] = useQueries([
    {
      enabled: gridDataFetchEnabled,
      queryKey: ['fetchGridData', filterString, rowsSkip],
      queryFn: async () => {
        const result = await fetchGridData({
          filter: filterString || '',
          skip: rowsSkip,
          limit: PAGE_SIZE,
          offset: rowsSkip,
          requireTotalCount: true,
        });

        return {
          rows: result.data as DataCatalogExtendedRecord[],
        };
      },
      placeholderData: {
        rows: [],
      },
    },
    {
      enabled: gridDataFetchEnabled,
      queryKey: ['fetchCount', filterString],
      queryFn: async () => await fetchGridDataCount(filterString),
      keepPreviousData: true,
      placeholderData: 0,
    },
  ]);

  const { isLoading, isFetching, data: gridData } = fetchGridDataResult;
  const rows = useMemo(() => gridData?.rows ?? [], [gridData]);
  const { data: count, isFetching: isFetchingCount } = fetchCountResult;

  useEffect(() => {
    if (isFetching) {
      setShouldDisplayGrid(true);
    }
  }, [isFetching]);

  const refetchAll = useCallback(() => {
    fetchGridDataResult.refetch();
    fetchCountResult.refetch();
  }, []);

  useEffect(() => {
    if (isFetching && selectedItem) {
      setSelectedItem(null);
    }
  }, [isFetching, selectedItem, setSelectedItem]);

  useEffect(() => {
    const titleConfig = getTitleServiceConfig(isElasticEnabled);
    pageHeaderService.setTitle(titleConfig);
  }, [isElasticEnabled]);

  useEffect(() => {
    if (shouldSelectFirstItem && rows.length > 0) {
      setSelectedItem(rows[0] as unknown as BigidContentItem);
      setHasSelectedFirstItem(true);
    }
  }, [rows, setSelectedItem, shouldSelectFirstItem]);

  const isMasterDetailsListMode = !!selectedItem;

  const handlePagingChange = (skip: number) => {
    setSkip(skip);
  };

  const selItem = getSelectedItem({
    selectedItem: {
      ...selectedItem,
      name: selectedItem?.objectName,
      tags: selectedItem?.tags,
    },
    selectedRowIds: selectedItem?.id ? [selectedItem.id] : [],
    rowClickShouldKeepSelection: true,
    selectedItemPropsMapping: {
      id: 'id',
      name: 'objectName',
      objectName: 'objectName',
      fullyQualifiedName: 'fullyQualifiedName',
      scanner_type_group: 'scanner_type_group',
      detailedObjectType: 'detailedObjectType',
      hierarchyType: 'hierarchyType',
      containerName: 'containerName',
      source: 'source',
      fullObjectName: 'fullObjectName',
      objectType: 'objectType',
      owner: 'owner',
      tags: 'generalTags',
      icon: 'icon',
      scannerType: 'type',
      collaborationStatus: 'collaborationStatus',
      isPermittedToPreview: 'isPermittedToPreview',
      isConfidenceLevelIgnored: 'isConfidenceLevelIgnored',
    },
  });

  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    isPersistentListMode: isMasterDetailsListMode,
    actions: [
      {
        type: ToolbarActionType.ACTION_ICON,
        label: 'Ignore Confidence Level',
        icon: BigidIgnoreConfidenceLevel,
        tooltip: 'Showing all attributes',
        show: ({ selectedItem }) => {
          return (
            selectedItem.scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED &&
            !selectedItem.isConfidenceLevelIgnored
          );
        },
        execute: async ({ selectedItem }) => {
          let shouldExecute = false;
          try {
            shouldExecute = await showGenericConfirmationDialog({
              title: 'Show all attributes',
              closeButton: 'Cancel',
              actionButton: 'Proceed',
              text: 'Show attributes of all confidence levels, including Low.',
            });

            if (shouldExecute) {
              analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_OBJECT_IGNORE_CONFIDENCE);
              try {
                await postIgnoreConfidenceLevel(selectedItem.fullyQualifiedName);

                setSelectedItem({
                  ...selectedItem,
                  isConfidenceLevelIgnored: true,
                });

                notificationService.success('Low confidence level attributes are shown.');
              } catch (e) {
                notificationService.error('An error has occurred');
                console.error(e.message);
              }
            }
          } catch (e) {
            notificationService.error('An error has occurred');
            console.error(e);
          } finally {
            return { shouldGridReload: shouldExecute, shouldClearSelection: shouldExecute };
          }
        },
      },
      {
        type: ToolbarActionType.ACTION_ICON,
        label: 'Restore Confidence Level',
        icon: BigidRestoreConfidenceLevel,
        tooltip: 'Revert from "Ignore" confidence',
        show: ({ selectedItem }) => {
          return (
            selectedItem.scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED &&
            selectedItem.isConfidenceLevelIgnored
          );
        },
        execute: async ({ selectedItem }) => {
          let shouldExecute = false;
          try {
            shouldExecute = await showGenericConfirmationDialog({
              title: 'Revert from "Ignore" confidence',
              closeButton: 'Cancel',
              actionButton: 'Restore Confidence Level',
              text: 'This process removes the low confidence level attributes from the object. Do you want to continue?',
            });

            if (shouldExecute) {
              const { source, fullyQualifiedName, scannerType } = selectedItem;
              const trackData = {
                fullyQualifiedName,
                dsType: scannerType,
                dsName: source,
              };
              analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_OBJECT_REVERT_CONFIDENCE, trackData);
              try {
                const confidenceLevelData = await getConfidenceLevel(selectedItem.fullyQualifiedName);
                await deleteIgnoreConfidenceLevel(confidenceLevelData[0]?.id);

                setSelectedItem({
                  ...selectedItem,
                  isConfidenceLevelIgnored: false,
                });

                notificationService.success('Low confidence level attributes are not shown.');
              } catch (e) {
                notificationService.error('An error has occurred');
                console.error(e.message);
              }
            }
          } catch (e) {
            notificationService.error('An error has occurred');
            console.error(e);
          } finally {
            return { shouldGridReload: shouldExecute, shouldClearSelection: shouldExecute };
          }
        },
      },
      ...(isFollowObjectEnabled
        ? ([
            {
              type: ToolbarActionType.ACTION_ICON,
              label: 'Follow Object',
              icon: BigidViewIcon,
              tooltip: 'Follow object',
              show: ({ selectedItem }) => {
                return selectedItem.collaborationStatus?.isFollowing === false;
              },
              execute: async ({ selectedItem }) => {
                try {
                  await addFollowedObjects([selectedItem.fullyQualifiedName]);

                  setSelectedItem({
                    ...selectedItem,
                    collaborationStatus: { ...selectedItem.collaborationStatus, isFollowing: true },
                  });
                } catch (err) {
                  const errMsg = `An error has occurred`;
                  notificationService.error(errMsg);
                  console.error(`${errMsg}: ${err}`);
                } finally {
                  const { fullyQualifiedName, scanner_type_group, name, containerName, type } = selectedItem;
                  const trackData = {
                    objectName: name,
                    fullyQualifiedName,
                    dsType: type,
                    dsName: containerName,
                    scannerType: scanner_type_group,
                    scanner_type_group,
                  };

                  analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_FOLLOW_OBJECT, trackData);
                  return {};
                }
              },
            },
            {
              type: ToolbarActionType.ACTION_ICON,
              label: 'Unfollow Object',
              icon: BigidHideIcon,
              tooltip: 'Unfollow object',
              show: ({ selectedItem }) => !!selectedItem.collaborationStatus?.isFollowing,
              execute: async ({ selectedItem }) => {
                try {
                  await deleteFollowedObjects([selectedItem.fullyQualifiedName]);
                  setSelectedItem({
                    ...selectedItem,
                    collaborationStatus: { ...selectedItem.collaborationStatus, isFollowing: false },
                  });
                } catch (err) {
                  const errMsg = `An error has occurred`;
                  notificationService.error(errMsg);
                  console.error(`${errMsg}: ${err}`);
                } finally {
                  return {};
                }
              },
            },
          ] as ToolbarAction[])
        : []),
      ...(isObjectIssuesEnabled
        ? ([
            {
              type: ToolbarActionType.ACTION_ICON,
              label: 'Report Issue',
              icon: BigidUserReportIcon,
              tooltip: 'Report an issue',
              show: ({ selectedItem }) => !!selectedItem.collaborationStatus?.canOpenIssue,
              execute: ({ selectedItem }) => {
                analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_REPORT_AN_ISSUE);

                return new Promise(resolve => {
                  setObjectIssueDialogState({
                    isOpen: true,
                    fullyQualifiedName: selectedItem.fullyQualifiedName,
                    onSubmit: () => {
                      closeObjectIssueDialog();
                      resolve({});
                    },
                    onClose: () => {
                      closeObjectIssueDialog();
                      resolve({});
                    },
                  });
                });
              },
            },
          ] as ToolbarAction[])
        : []),
    ],
    shouldReloadGridOnClose: true,

    loadAsyncTabsAndContent: async () => {
      const appIntegrationSettings = await getIntegrationSettings();
      const appIntegrationTabs = appIntegrationSettings
        ? appIntegrationSettings
            .filter(validateAppTabSettings)
            .map((setting: AppIntegrationSettings) => {
              return setting.objectDetails.tabs.map(tabSettings => {
                return {
                  tabSettings,
                  appId: setting.appId,
                  appName: setting.appFriendlyName,
                };
              });
            })
            .flat()
            .map(
              ({
                tabSettings,
                appId,
                appName,
              }: {
                tabSettings: AppIntegrationSettingsTabConfig;
                appId: string;
                appName: string;
              }) => ({
                label: tabSettings.title,
                data: {
                  component: AppIntegrationTab,
                  customProps: {
                    tabSettings,
                    appId,
                    appName,
                  },
                },
              }),
            )
        : [];
      return {
        classes: {
          contentContainer: classes.contentContainer,
        },
        tabProps: {
          tabs: [
            {
              label: 'Details',
              id: 'details',
              data: {
                component: DataCatalogDetails,
                customProps: {
                  filter: filterString,
                  setSelectedItem,
                },
              },
            },
            {
              label: 'Attributes',
              id: 'attributes',
              data: { component: DataCatalogAttributes },
            },
            {
              label: 'Columns',
              id: 'columns',
              data: { component: DataCatalogColumns },
              getIsAvailable: function ({ scanner_type_group, detailedObjectType }) {
                return (
                  detailedObjectType === DetailedObjectType.STRUCTURED ||
                  detailedObjectType === DetailedObjectType.STRUCTURED_FILE ||
                  detailedObjectType === DetailedObjectType.PARTITIONED_TABLE ||
                  (!detailedObjectType &&
                    scanner_type_group !== DataCatalogRecordScannerTypeGroup.UNSTRUCTURED &&
                    scanner_type_group !== DataCatalogRecordScannerTypeGroup.EMAIL)
                );
              },
            },
            {
              label: 'Similar tables',
              id: 'similar tables',
              data: { component: DataCatalogSimilarTables },
              getIsAvailable: ({ scanner_type_group, detailedObjectType }) => {
                return (
                  (detailedObjectType === DetailedObjectType.STRUCTURED ||
                    detailedObjectType === DetailedObjectType.PARTITIONED_TABLE ||
                    detailedObjectType === DetailedObjectType.STRUCTURED_FILE ||
                    (!detailedObjectType && scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED)) &&
                  isSimilarTablesEnabled
                );
              },
            },
            {
              label: 'Fields',
              id: 'fields',
              data: { component: DataCatalogFields },
              getIsAvailable: function ({ scanner_type_group, detailedObjectType }) {
                return (
                  (detailedObjectType === DetailedObjectType.UNSTRUCTURED ||
                    (!detailedObjectType && scanner_type_group === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED)) &&
                  isClassifyFileNamesFFEnabled
                );
              },
            },
            {
              label: 'Graph View',
              id: 'lineage',
              data: { component: DataCatalogLineage },
              getIsAvailable: () => isLineageEnabled,
            },
            {
              label: 'Preview',
              id: 'preview',
              data: { component: PreviewFileContent },
              getIsAvailable: ({
                objectType,
                scanner_type_group,
                scannerType,
                isPermittedToPreview,
                detailedObjectType,
              }) => {
                return (
                  (detailedObjectType === DetailedObjectType.UNSTRUCTURED ||
                    (!detailedObjectType && scanner_type_group === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED)) &&
                  isPermittedToPreview &&
                  !previewFileDataSourcesBlacklist.includes(scannerType) &&
                  !getApplicationPreference('DATA_PREVIEW_DISABLED') &&
                  objectType !== DataCatalogObjectType.MODEL
                );
              },
            },
            {
              label: 'Preview',
              id: 'preview',
              data: { component: DataCatalogTablePreview },
              getIsAvailable: ({ scanner_type_group, scannerType, isPermittedToPreview, detailedObjectType }) => {
                return (
                  (detailedObjectType === DetailedObjectType.STRUCTURED ||
                    detailedObjectType === DetailedObjectType.PARTITIONED_TABLE ||
                    detailedObjectType === DetailedObjectType.STRUCTURED_FILE ||
                    (!detailedObjectType && scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED)) &&
                  isPermittedToPreview &&
                  !previewTableDataSourcesBlacklist.includes(scannerType) &&
                  !getApplicationPreference('DATA_PREVIEW_DISABLED')
                );
              },
            },
            {
              label: 'Duplicates',
              id: 'duplicates',
              data: { component: DataCatalogFileDuplicates },
              getIsAvailable: ({ objectType, detailedObjectType }) =>
                (objectType === DataCatalogObjectType.FILE ||
                  detailedObjectType === DetailedObjectType.UNSTRUCTURED ||
                  detailedObjectType === DetailedObjectType.APP) &&
                objectType != DataCatalogObjectType.MODEL,
            },
            {
              label: 'Activity Monitoring',
              id: 'Activity Monitoring',
              data: { component: DataCatalogActivityMonitoring },
              getIsAvailable: () => isActivityMonitoringEnabled,
            },
            ...appIntegrationTabs,
            {
              label: 'Audit Trail',
              id: 'auditTrail',
              data: { component: DataCatalogAuditTrail },
              getIsAvailable: () => getApplicationPreference('CATALOG_AUDIT_TRAIL_UI_ENABLED'),
            },
            {
              label: 'Relations',
              id: 'relations',
              data: { component: DataCatalogRelations },
              getIsAvailable: ({ objectType }) =>
                DataCatalogObjectType.MODEL === objectType && getApplicationPreference('AI_CATALOG_RELATIONS_ENABLED'),
            },
          ],
          selectedIndex: 0,
          indexByTabId: true,
        },
      };
    },
  };

  const handleGridRowClick = useCallback(
    (row: DataCatalogRecord): void => {
      if (row.id === selectedItem?.id) {
        setSelectedItem(null);
      } else {
        setSelectedItem(row as unknown as BigidContentItem);
      }
    },
    [selectedItem, setSelectedItem],
  );

  const columns = getColumns();

  const getHiddenColumnNames = (columns: BigidGridColumn<DataCatalogExtendedRecord>[], isDetailsMode: boolean) => {
    if (!isDetailsMode) {
      return hideColumns;
    }
    return columns.filter(column => !column.isListColumn).map(column => column.name);
  };

  const gridConfig: BigidGridProps<DataCatalogExtendedRecord> = {
    columns,
    pageSize: PAGE_SIZE,
    rows: rows,
    skip: rowsSkip,
    loading: isLoading || isFetching,
    totalRowsCount: isFetchingCount ? 100 : count, // BDT-93615: default value for the case when retching count takes too long
    selectedRowIds: selectedItem?.id ? [selectedItem.id] : [],
    onPagingChanged: handlePagingChange,
    onRowClick: handleGridRowClick,
    hiddenColumnNames: getHiddenColumnNames(columns, isMasterDetailsListMode),
    noDataContent: (
      <NoDataContainer>
        <BigidLayoutEmptyState illustration={BigidNoDataIllustration} description={t('noDataContent.description')} />
      </NoDataContainer>
    ),
    chosenRowId: selectedItem?.id,
    showSelectionColumn: false,
    listMode: isMasterDetailsListMode,
    showSortingControls: false,
    rowClickShouldKeepSelection: true,
    dataAid: 'DataCatalog-grid',
  };

  const handleMasterDetailsContentClose = (): void => {
    setSelectedItem(null);
    refetchAll();
  };

  const columnsList = columns.reduce<ColumnInfo[]>((acc, column) => {
    const columnsToExcludeFromChooser = ['objectName'];
    if (!columnsToExcludeFromChooser.includes(column.name)) {
      return [
        ...acc,
        {
          checked: !hideColumns.includes(column.name),
          name: column.name,
          label: column.title,
          title: column.title,
        },
      ];
    }
    return acc;
  }, []);

  const handleHideColumns = (columns: ColumnInfo[]) => {
    setHideColumns(columns.filter(column => !column.checked).map(column => column.name));
  };

  const handleProcessingWidgetGotCompletedOperations = (): void => {
    refetchAll();
  };

  return (
    <ContentContainer>
      <SearchbarContainer>
        <NewDataCatalogSearchbar />
        {(isTagsBulkAssignmentEnabled || isColumnClusteringEnabled) && (
          <ProcessingWidgetWrapper>
            <DataCatalogAsyncOpsProcessingWidget onUpdate={handleProcessingWidgetGotCompletedOperations} />
          </ProcessingWidgetWrapper>
        )}
      </SearchbarContainer>
      <GridContainer>
        <NewDataCatalogToolbar />
        <DataCatalogActionRow
          filterString={filterString}
          totalCount={count}
          selectedFilters={selectedFilters}
          columns={columnsList}
          setHideColumns={handleHideColumns}
          isCustomQuery={isCustomQuery}
          refetchData={fetchGridDataResult.refetch}
          shouldShowViewInDataOverviewButton={isDataOverviewPermitted}
          shouldShowAssignBulkTagButton={isTagsBulkAssignmentEnabled && isTagsCreatePermitted}
          isFetchingCount={isFetchingCount}
        />
        {shouldDisplayGrid && (
          <BigidGrid {...gridConfig}>
            {isMasterDetailsListMode && (
              <BigidMasterDetailsContent
                {...masterDetailsConfig}
                onClose={handleMasterDetailsContentClose}
                selectedItem={selItem}
              />
            )}
          </BigidGrid>
        )}
      </GridContainer>
      <DataCatalogObjectIssueDialog {...objectIssueDialogState} />
    </ContentContainer>
  );
};
