interface FieldData {
  name: string;
}

export const useRenderFields = () => {
  const commonFields: FieldData[] = [
    { name: 'name' },
    { name: 'description' },
    { name: 'credentialsId' },
    { name: 'isHttpOrHttps' },
    { name: 'certificateId' },
    { name: 'protocolsLabel' },
    { name: 'httpProxyHost' },
    { name: 'httpProxyPort' },
    { name: 'httpsProxyHost' },
    { name: 'httpsProxyPort' },
  ];

  const getFieldsToRender = (): FieldData[] => {
    return commonFields;
  };

  return {
    fieldsToRender: getFieldsToRender(),
  };
};
