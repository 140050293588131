import { CloudProvider } from './types';
import { BigidFormField, BigidFormFieldTypes, BigidSelectOption } from '@bigid-ui/components';
import { MaskedJsonField } from '../AutoDiscoveryWizard/autoDiscoveryComponents/fields/MaskedJsonField';
import { getAvailableScannerGroups } from '../../Labeling/LabelingService';
import { notificationService } from '../../../services/notificationService';
import { flatten, uniq } from 'lodash';
import { DataSourceTemplateConditionOperatorsEnum } from '../../DataSources/DataSourceConfiguration/types';

export enum DiscoveryAuthTypes {
  CREDENTIALS = 'credentials',
  CREDENTIALS_MULTI = 'credentialsMulti',
  IAM_ROLE = 'isIAMRole',
  IAM_ROLE_MULTI = 'isIAMRoleMulti',
  WORKLOAD_IDENTITY = 'workloadIdentity',
}

export const TYPE_TO_AUTH_OPTIONS: Record<CloudProvider, BigidSelectOption[]> = {
  [CloudProvider.AWS]: [
    { label: 'IAM Role (Single Account)', value: DiscoveryAuthTypes.IAM_ROLE },
    { label: 'IAM Role (Multiple Accounts)', value: DiscoveryAuthTypes.IAM_ROLE_MULTI },
    {
      label: 'Credentials (Single Account)',
      value: DiscoveryAuthTypes.CREDENTIALS,
    },
    {
      label: 'Credentials (Multiple Accounts)',
      value: DiscoveryAuthTypes.CREDENTIALS_MULTI,
    },
  ],
  [CloudProvider.AZURE]: [
    {
      label: 'Credentials (Single Account)',
      value: DiscoveryAuthTypes.CREDENTIALS,
    },
    {
      label: 'Credentials (Multiple Accounts)',
      value: DiscoveryAuthTypes.CREDENTIALS_MULTI,
    },
    { label: 'Secretless (Single Account)', value: DiscoveryAuthTypes.IAM_ROLE },
    { label: 'Secretless (Multiple Accounts)', value: DiscoveryAuthTypes.IAM_ROLE_MULTI },
  ],
  [CloudProvider.GCP]: [
    {
      label: 'Credentials',
      value: DiscoveryAuthTypes.CREDENTIALS,
    },
    {
      label: 'Workload Identity',
      value: DiscoveryAuthTypes.WORKLOAD_IDENTITY,
    },
  ],
  [CloudProvider.ONTAP]: [
    {
      label: 'Credentials',
      value: DiscoveryAuthTypes.CREDENTIALS,
    },
  ],
};

export const AUTH_TYPE_FIELD_NAME = 'authType';

const managedIdentityClientIdField = {
  name: 'managed_identity_client_id',
  tooltipText: 'Required if you are using User-Assigned Managed Identity',
};

const scannerGroup = {
  name: 'scannerGroup',
  misc: {
    enabledIf: [
      {
        field: 'scan_group_strategy',
        value: {
          operator: DataSourceTemplateConditionOperatorsEnum.or,
          values: ['Custom', 'Default', ''],
        },
      },
    ],
  },
};

const gcpCredentialsField = {
  name: 'credentials',
  type: BigidFormFieldTypes.TEXTAREA,
  render: MaskedJsonField,
  misc: {
    multiline: true,
    rows: 6,
    isSensitive: true,
  },
};

export const TYPE_TO_VISIBLE_BY_AUTH_FIELDS: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: string[];
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: ['access_key_id', 'secret_access_key'],
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: [
      'access_key_id',
      'secret_access_key',
      'aws_config_aggregator_name',
      'aws_config_aggregator_region',
      'base_role_name',
    ],
    [DiscoveryAuthTypes.IAM_ROLE]: ['aws_role_arn'],
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: [
      'aws_role_arn',
      'aws_config_aggregator_name',
      'aws_config_aggregator_region',
      'base_role_name',
    ],
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: ['client_id', 'client_secret', 'tenant_id'],
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: ['client_id', 'client_secret', 'tenant_id', 'management_group_name'],
    [DiscoveryAuthTypes.IAM_ROLE]: ['managed_identity_client_id'],
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: ['managed_identity_client_id', 'management_group_name'],
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: ['credentials'],
    [DiscoveryAuthTypes.WORKLOAD_IDENTITY]: ['impersonate_service_account', 'impersonate_service_account_scanner'],
  },
  [CloudProvider.ONTAP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: ['api_url', 'username', 'password'],
  },
};

function getClearedFieldsBYAuthType(type: CloudProvider, authType: DiscoveryAuthTypes) {
  return uniq(flatten(Object.values(TYPE_TO_VISIBLE_BY_AUTH_FIELDS[type]))).filter(
    fieldName => !TYPE_TO_VISIBLE_BY_AUTH_FIELDS[type][authType].includes(fieldName),
  );
}

export const TYPE_TO_NULLIFY_AUTH_FIELDS: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: string[];
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: getClearedFieldsBYAuthType(CloudProvider.AWS, DiscoveryAuthTypes.CREDENTIALS),
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: getClearedFieldsBYAuthType(
      CloudProvider.AWS,
      DiscoveryAuthTypes.CREDENTIALS_MULTI,
    ),
    [DiscoveryAuthTypes.IAM_ROLE]: getClearedFieldsBYAuthType(CloudProvider.AWS, DiscoveryAuthTypes.IAM_ROLE),
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: getClearedFieldsBYAuthType(
      CloudProvider.AWS,
      DiscoveryAuthTypes.IAM_ROLE_MULTI,
    ),
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: getClearedFieldsBYAuthType(CloudProvider.AZURE, DiscoveryAuthTypes.CREDENTIALS),
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: getClearedFieldsBYAuthType(
      CloudProvider.AZURE,
      DiscoveryAuthTypes.CREDENTIALS_MULTI,
    ),
    [DiscoveryAuthTypes.IAM_ROLE]: getClearedFieldsBYAuthType(CloudProvider.AZURE, DiscoveryAuthTypes.IAM_ROLE),
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: getClearedFieldsBYAuthType(
      CloudProvider.AZURE,
      DiscoveryAuthTypes.IAM_ROLE_MULTI,
    ),
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: getClearedFieldsBYAuthType(CloudProvider.GCP, DiscoveryAuthTypes.CREDENTIALS),
    [DiscoveryAuthTypes.WORKLOAD_IDENTITY]: getClearedFieldsBYAuthType(
      CloudProvider.GCP,
      DiscoveryAuthTypes.WORKLOAD_IDENTITY,
    ),
  },
  [CloudProvider.ONTAP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: getClearedFieldsBYAuthType(CloudProvider.GCP, DiscoveryAuthTypes.CREDENTIALS),
  },
};

export const TYPE_TO_OVERRIDE_FIELDS: Record<CloudProvider, Record<string, BigidFormField>> = {
  [CloudProvider.AWS]: {
    scannerGroup,
  },
  [CloudProvider.GCP]: {
    credentials: gcpCredentialsField,
  },
  [CloudProvider.AZURE]: {
    managed_identity_client_id: managedIdentityClientIdField,
  },
  [CloudProvider.ONTAP]: {},
};

export const TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: boolean;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: true,
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: false,
    [DiscoveryAuthTypes.IAM_ROLE]: true,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: false,
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: true,
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: true,
    [DiscoveryAuthTypes.IAM_ROLE]: true,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: true,
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: true,
    [DiscoveryAuthTypes.WORKLOAD_IDENTITY]: true,
  },
  [CloudProvider.ONTAP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: true,
  },
};

export const TYPE_TO_DEFAULT_VALUES_BY_AUTH_FIELDS: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: Record<string, any>;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: { is_iam_role: 'false' },
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: { is_iam_role: 'false' },
    [DiscoveryAuthTypes.IAM_ROLE]: { is_iam_role: 'true' },
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: { is_iam_role: 'true' },
  },
  [CloudProvider.AZURE]: {},
  [CloudProvider.GCP]: {},
  [CloudProvider.ONTAP]: {},
};

const DEFAULT_HIDDEN_FIELDS = { check_permission: true, run_scan: true };
export const TYPE_TO_HIDDEN_VALUES: Record<CloudProvider, Record<string, boolean>> = {
  [CloudProvider.AWS]: {
    ...DEFAULT_HIDDEN_FIELDS,
    is_iam_role: true,
  },
  [CloudProvider.AZURE]: DEFAULT_HIDDEN_FIELDS,
  [CloudProvider.GCP]: DEFAULT_HIDDEN_FIELDS,
  [CloudProvider.ONTAP]: DEFAULT_HIDDEN_FIELDS,
};

const getRoleNameBasedOnArn = async ({ base_role_name, aws_role_arn }: Record<string, any>) => {
  if (base_role_name) {
    return { base_role_name };
  }
  const match = aws_role_arn?.match(/role\/(.+)$/);
  return { base_role_name: match?.[1] || '' };
};

const fillCredentialWithStringIfEmptyForSaveDraft = (values: Record<string, any>) => ({
  credentials: values?.credentials || '_',
});

export const GCP_SCANNER_GROUP = 'gcp_default';
const gcpScannerGroupCalc = async (values: Record<string, any>) => {
  let scannerGroup = values?.scannerGroup ?? 'default';
  try {
    const scannerGroups = await getAvailableScannerGroups();
    if (scannerGroups?.includes?.(GCP_SCANNER_GROUP)) {
      scannerGroup = GCP_SCANNER_GROUP;
    }
  } catch (err) {
    notificationService.error('Get scanner groups error');
    console.error(err);
  }

  return { scannerGroup };
};

export const TYPE_TO_VALUES_COMPUTE: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: (args: Record<string, any>) => Promise<Record<string, any>>;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: getRoleNameBasedOnArn,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: getRoleNameBasedOnArn,
  },
  [CloudProvider.AZURE]: {},
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: async values => ({
      ...fillCredentialWithStringIfEmptyForSaveDraft(values),
      ...(await gcpScannerGroupCalc(values)),
    }),
    [DiscoveryAuthTypes.WORKLOAD_IDENTITY]: async values => ({
      ...(await gcpScannerGroupCalc(values)),
    }),
  },
  [CloudProvider.ONTAP]: {},
};

export enum DiscoveryActionType {
  SINGLE = 'configData',
  MULTI = 'configDataMultiply',
}

export const ACTION_DATA_KEY_BY_TYPE: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: DiscoveryActionType;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: DiscoveryActionType.SINGLE,
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: DiscoveryActionType.MULTI,
    [DiscoveryAuthTypes.IAM_ROLE]: DiscoveryActionType.SINGLE,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: DiscoveryActionType.MULTI,
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: DiscoveryActionType.MULTI,
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: DiscoveryActionType.MULTI,
    [DiscoveryAuthTypes.IAM_ROLE]: DiscoveryActionType.MULTI,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: DiscoveryActionType.MULTI,
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: DiscoveryActionType.SINGLE,
    [DiscoveryAuthTypes.WORKLOAD_IDENTITY]: DiscoveryActionType.SINGLE,
  },
  [CloudProvider.ONTAP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: DiscoveryActionType.SINGLE,
  },
};
