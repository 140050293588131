import { getNewUISupportedApps } from '../../../../services/autoDiscoveryService';
import { CloudProvider, cloudProviderLabel, DS_LIST_BY_TYPE } from '../../../AutoDiscovery/constants';
import {
  DataSourceCategoryFetch,
  DataSourceOnboardingItem,
  DataSourceOnboardingTypesProvider,
  OnSelectOnboardingType,
} from '../types';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

let naiveCache: DataSourceOnboardingItem[] | null;
const fetchTypes: DataSourceCategoryFetch = async (_search, invalidateCache, onStart) => {
  if (!invalidateCache && naiveCache) {
    return {
      isFromCache: true,
      items: naiveCache,
    };
  }

  onStart?.();
  const items = await getNewUISupportedApps();

  const itemsFormatted = items.map(({ type }) => {
    const keywords = Object.values(DS_LIST_BY_TYPE[type as CloudProvider])
      ?.map(({ label, value }) => [label, value])
      .flat();
    return {
      type,
      keywords,
      isPermitted: true,
      displayName: cloudProviderLabel[type as CloudProvider],
    };
  });
  naiveCache = itemsFormatted;
  return { items: itemsFormatted, isFromCache: false };
};

export const onSelectType: OnSelectOnboardingType = ({ type, openAutoDiscoveryModal }) => {
  openAutoDiscoveryModal(type);
};

const isPermitted = () => getApplicationPreference('SHOW_AUTO_DISCOVERY_WIZARD');

export const automaticOnboardingProvider: DataSourceOnboardingTypesProvider = {
  fetch: fetchTypes,
  onSelectType,
  isPermitted,
};
