import { httpService } from '../../services/httpService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { queryService } from '../../services/queryService';
import { LegalEntitiesInterface } from './LegalEntitiesTypes';
import { getSearchQuery } from './utils/common';

export const getLegalEntities = async (bigidGridQuery: BigidGridQueryComponents) => {
  const gridConfigQuery = queryService.getGridConfigQuery(bigidGridQuery);
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities?${gridConfigQuery}&checkHasLegalEntities=true`);

  return data;
};

export const getLegalEntity = async (id: string) => {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities/${id}`);
  return data;
};

export const createLegalEntity = async (legalEntity: Omit<LegalEntitiesInterface, 'id' | 'status' | 'branding'>) => {
  const {
    data: { data },
  } = await httpService.post('data-mapping/legal-entities', legalEntity);
  return data;
};

export const updateLegalEntity = async (id: string, payload: Partial<LegalEntitiesInterface>) => {
  const {
    data: { data },
  } = await httpService.patch(`data-mapping/legal-entities/${id}`, payload);
  return data;
};

export async function deleteLegalEntity(id: string) {
  const {
    data: { data },
  } = await httpService.delete(`data-mapping/legal-entities/${id}`);

  return data;
}

export const getLevels = async (searchText?: string, computeUsage?: boolean) => {
  const {
    data: { data },
  } = await httpService.fetch(
    `data-mapping/legal-entities/level?${getSearchQuery(searchText)}${computeUsage ? '&computeUsage=true' : ''}`,
  );
  return data;
};

export const getParents = async (searchText?: string) => {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities?${getSearchQuery(searchText, true)}`);
  return data;
};

export const getParentsByLevel = async (levelId: string, searchText?: string) => {
  const {
    data: { data },
  } = await httpService.fetch(
    `data-mapping/legal-entities/level/${levelId}/parent-entities?${getSearchQuery(searchText)}`,
  );
  return data;
};

export const getHierarchyById = async (entityId: string) => {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities/${entityId}/hierarchy`);
  return data;
};

export async function createAsset(payload: { name: string }): Promise<{ _id: string }> {
  const {
    data: { _id },
  } = await httpService.post('applications?exposeSystemId=true', payload);

  return { _id };
}

export async function getActivityLogs(id: string) {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities/${id}/audit-logs`);

  return data;
}

export const getBrandingLogo = async (brandingId: string) => {
  const result = await httpService.fetch(
    `data-mapping/legal-entities/branding/${brandingId}/logo`,
    { preview: 'true' },
    undefined,
    'arraybuffer',
  );
  return result;
};

export const getBranding = async (brandingId: string) => {
  const {
    data: { data },
  } = await httpService.fetch(`data-mapping/legal-entities/branding/${brandingId}`, {
    excludeOriginalImage: 'true',
  });
  return data;
};

export const createBranding = async (entityId: string, brandingPayload: FormData) => {
  const {
    data: { data },
  } = await httpService.post(`data-mapping/legal-entities/${entityId}/branding`, brandingPayload);
  return data;
};

export const updateBranding = async (entityId: string, brandingId: string, payload: FormData) => {
  const {
    data: { data },
  } = await httpService.patch(`data-mapping/legal-entities/${entityId}/branding/${brandingId}`, payload);
  return data;
};

export const updateBrandingInheritance = async (entityId: string, payload: FormData, cloneImages: boolean) => {
  const {
    data: { data },
  } = await httpService.patch(`data-mapping/legal-entities/${entityId}/branding`, payload, {
    cloneImages: String(cloneImages),
  });
  return data;
};

export async function updateLevels(payload: { levels: { name: string }[] }) {
  const {
    data: { data },
  } = await httpService.put('data-mapping/legal-entities/levels', payload);

  return data;
}
