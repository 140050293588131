import React from 'react';

import { BigidGridRow, ChipsFormatterProps, EntitiesCounterFormatterProps, TagsFormatterProps } from '@bigid-ui/grid';
import {
  ColumnBusinessAttribute,
  ColumnBusinessAttributePopulatedBy,
  DataCatalogObjectColumn,
  DataCatalogObjectColumnsResponse,
  getColumnsByObjectName,
} from '../../../../../../DataCatalog/DataCatalogColumns';
import { capitalize } from 'lodash';
import {
  BigidChipIconType,
  BigidColors,
  BigidConfidenceIndicator,
  BigidConfidenceLevel,
  BigidEditableChipAreaEntity,
  BigidHighlightIndicator,
  BigidIcon,
  BigidIconSize,
  BigidIconSourceType,
} from '@bigid-ui/components';
import { ConfidenceLevelExplanation } from '../../../../../../../components/ConfidenceLevelExplanation/ConfidenceLevelExplanation';
import { getTagFormattedName, getTagIcon } from '../../../../../../TagsManagement/TagsManagementUtils';
import { TagAssignmentTarget } from '../../../../../../TagsManagement/TagsManagementService';
import { EmojiObjectsOutlined } from '@mui/icons-material';
import { BigidLinkIcon } from '@bigid-ui/icons';

export type ColumnsGridRow = Omit<DataCatalogObjectColumn, 'tags'> &
  BigidGridRow & {
    columnTags?: TagsFormatterProps;
    attributes?: ChipsFormatterProps;
    linkedColumnsIndicator?: EntitiesCounterFormatterProps;
    columnBusinessAttribute?: ChipsFormatterProps;
  };

const getBusinessAttributeIcon = (populatedBy: ColumnBusinessAttributePopulatedBy): BigidChipIconType => {
  if (populatedBy === ColumnBusinessAttributePopulatedBy.AUTO) {
    return <BigidHighlightIndicator text="Auto populated Business Attribute" color={BigidColors.orange[600]} />;
  }

  return undefined;
};

const getBusinessAttributeFormatted = (
  businessAttribute: ColumnBusinessAttribute,
  isPermitted: boolean,
): ChipsFormatterProps => {
  const attribute: ChipsFormatterProps = {
    chips: {
      value: [],
      placeholder: 'Add business term',
      showPlaceholderOnlyOnHover: true,
      isDisabled: !isPermitted,
    },
  };

  if (Object.keys(businessAttribute).length > 0) {
    const { friendlyName, totalSuggestionsCount, populatedBy } = businessAttribute;

    if (friendlyName) {
      attribute.chips = {
        ...attribute.chips,
        value: [
          {
            label: friendlyName,
            iconPlacement: 'left',
            icon: getBusinessAttributeIcon(populatedBy),
          },
        ],
      };
    } else if (totalSuggestionsCount) {
      attribute.chips = {
        ...attribute.chips,
        value: [
          {
            label: totalSuggestionsCount.toString(),
            iconPlacement: 'right',
            icon: (
              <BigidIcon
                icon={EmojiObjectsOutlined}
                size={BigidIconSize.REGULAR_PLUS}
                color={BigidColors.orange[600]}
              />
            ),
            variant: 'outlined',
            outline: 'dashed',
          },
        ],
      };
    }
  }

  return attribute;
};

const excludedAttributeTypes = ['ClassificationMd', 'Classification', 'Manual', 'Enrichment Attribute'];

type MapColumnsResponseToGridRowsProps = {
  columns: DataCatalogObjectColumnsResponse;
  fullyQualifiedName: string;
  isEditManualAttributesPermitted: boolean;
  confidenceLevelExplainTooltipEnabled: boolean;
  clusteringEnabled: boolean;
  isColumnTagsAssignmentPermitted: boolean;
  isEditBusinessAttributePermitted: boolean;
};
const mapColumnsResponseToGridRows = ({
  columns,
  fullyQualifiedName,
  isEditManualAttributesPermitted,
  clusteringEnabled,
  confidenceLevelExplainTooltipEnabled,
  isColumnTagsAssignmentPermitted,
  isEditBusinessAttributePermitted,
}: MapColumnsResponseToGridRowsProps): ColumnsGridRow[] => {
  return columns.map((column: DataCatalogObjectColumn, index: number) => {
    const {
      column_name,
      attribute_list = [],
      isPrimary = false,
      isProfiled = false,
      fieldType,
      linkedColumns = 0,
      tags = [],
      businessAttribute = {},
      clusterId,
      order,
      nullable,
    } = column;

    const attributes: ChipsFormatterProps = {
      chips: {
        value: attribute_list.map(attribute => {
          const { attribute_name, attribute_type, rank, attribute_id, calc_confidence_level } = attribute;
          let confidenceLevelScore;

          if (calc_confidence_level) {
            confidenceLevelScore = (calc_confidence_level * 100).toFixed(0) + '%';
          }

          let confidenceLevelIndication;

          if (confidenceLevelScore && rank) {
            confidenceLevelIndication = `${capitalize(rank)} - ${confidenceLevelScore}`;
          } else if (rank) {
            confidenceLevelIndication = capitalize(rank);
          }

          let chipLabel;

          if (confidenceLevelScore) {
            chipLabel = `${attribute_name} (${confidenceLevelScore})`;
          } else {
            chipLabel = attribute_name;
          }

          let chipTitle;

          if (confidenceLevelIndication) {
            chipTitle = `${attribute_name} (${confidenceLevelIndication})`;
          } else {
            chipTitle = attribute_name;
          }

          let chipIcon;

          if (rank) {
            chipIcon = <BigidConfidenceIndicator level={rank.toLowerCase() as BigidConfidenceLevel} />;
          }

          const shouldDisplayTooltip = confidenceLevelExplainTooltipEnabled && clusteringEnabled;

          const entity: BigidEditableChipAreaEntity = {
            id: attribute_id,
            label: chipLabel,
            icon: chipIcon,
            title: chipTitle,
            ...(shouldDisplayTooltip && {
              tooltipProps:
                !excludedAttributeTypes.includes(attribute_type) && calc_confidence_level !== undefined
                  ? {
                      width: '400px',
                      title: (
                        <ConfidenceLevelExplanation
                          item={{
                            fullyQualifiedName,
                            fieldName: column.column_name,
                            attribute_type: attribute_type,
                            attribute_name: attribute_name,
                            confidence_level: calc_confidence_level,
                          }}
                        />
                      ),
                    }
                  : undefined,
            }),
          };

          return entity;
        }),
        isDisabled: !isEditManualAttributesPermitted,
        placeholder: 'Add attributes',
        showPlaceholderOnlyOnHover: true,
      },
    };

    const columnTags: TagsFormatterProps = {
      tags: {
        value: tags.map(({ tagName, tagValue, tagType, properties }) => ({
          name: getTagFormattedName(tagName),
          value: tagValue,
          iconDescription: tagType === TagAssignmentTarget.column ? tagType : undefined,
          icon: getTagIcon(properties, tagType),
        })),
        isDisabled: !isColumnTagsAssignmentPermitted,
        isAutoFit: false,
        placeholder: 'Add tag',
        showPlaceholderOnlyOnHover: true,
      },
    };

    const linkedColumnsIndicator: EntitiesCounterFormatterProps = {
      counter: {
        counterIcon: {
          icon: BigidLinkIcon,
          type: BigidIconSourceType.CUSTOM,
          size: BigidIconSize.MEDIUM,
          label: linkedColumns,
        },
        placeholder: 'Link column',
        showPlaceholderOnlyOnHover: true,
      },
    };

    const columnBusinessAttribute = getBusinessAttributeFormatted(businessAttribute, isEditBusinessAttributePermitted);

    return {
      id: column_name,
      attribute_list,
      column_name,
      attributes,
      columnTags,
      tags,
      fieldType,
      isPrimary,
      isProfiled,
      columnBusinessAttribute,
      clusterId,
      order,
      nullable,
      linkedColumnsIndicator,
    };
  });
};

export const fetchColumnsRowsByObjectName = async (props: Omit<MapColumnsResponseToGridRowsProps, 'columns'>) => {
  try {
    const { data } = await getColumnsByObjectName(`object_name=${props.fullyQualifiedName}`);

    return mapColumnsResponseToGridRows({ columns: data, ...props });
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
  }
};
