import {
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
} from '@bigid-ui/components';
import {
  AbstractQueryConditionNode,
  AbstractQueryNode,
  BigidValueType,
  parseAbstractQueryTreeIntoString,
  parseExpressionsIntoAbstractQueryTree,
  QueryConditionOperation,
  QueryExpressionOperator,
} from '@bigid/query-object-serialization';

export type SearchOptionPayload = {
  searchOptionValue: string;
  field: string;
};

const mapDropdownFilterToConditionNode = ({
  options,
  field,
}: BigidAdvancedToolbarDropdownFilter): AbstractQueryConditionNode => {
  return {
    name: field,
    bigidName: field,
    operation: QueryConditionOperation.IN,
    operator: QueryExpressionOperator.UNDEFINED,
    type: BigidValueType.STRING,
    value: options.map(({ id }) => id),
    isIgnored: false,
    isNegativeOperation: false,
    isTagsNegativeOperation: false,
    arrayFieldName: null,
  };
};

export const mapDateRangeFilterToConditionNode = (filter: BigidAdvancedToolbarDateRangeFilter): AbstractQueryNode[] => {
  const { field, options } = filter;
  return [
    {
      name: field,
      bigidName: field,
      operation: QueryConditionOperation.GTE,
      operator: QueryExpressionOperator.UNDEFINED,
      type: BigidValueType.DATE,
      value:
        options.pickersState.dates.from instanceof Date
          ? options.pickersState.dates.from.toISOString()
          : options.pickersState.dates.from,
      isIgnored: false,
      isNegativeOperation: false,
      isTagsNegativeOperation: false,
      arrayFieldName: null,
    },
    {
      name: field,
      bigidName: field,
      operation: QueryConditionOperation.LTE,
      operator: QueryExpressionOperator.UNDEFINED,
      type: BigidValueType.DATE,
      value:
        options.pickersState.dates.until instanceof Date
          ? options.pickersState.dates.until.toISOString()
          : options.pickersState.dates.until,
      isIgnored: false,
      isNegativeOperation: false,
      isTagsNegativeOperation: false,
      arrayFieldName: null,
    },
  ];
};

export const mapDropdownFiltersToFilterString = (
  filters: BigidAdvancedToolbarFilterUnion[],
  searchOptionPayload?: SearchOptionPayload,
) => {
  const result = filters.reduce<AbstractQueryNode[]>((acc, filter) => {
    switch (filter.type) {
      case BigidAdvancedToolbarFilterTypes.DROPDOWN: {
        const conditionNode = mapDropdownFilterToConditionNode(filter);
        acc.push(conditionNode);
        return acc;
      }
      case BigidAdvancedToolbarFilterTypes.DATE_RANGE: {
        const conditionNode = mapDateRangeFilterToConditionNode(filter);
        acc.push(...conditionNode);
        return acc;
      }
    }
  }, []);

  if (searchOptionPayload?.searchOptionValue) {
    const searchOptionConditionNode: AbstractQueryConditionNode = {
      name: searchOptionPayload.field,
      bigidName: searchOptionPayload.field,
      operator: QueryExpressionOperator.UNDEFINED,
      type: BigidValueType.STRING,
      operation: QueryConditionOperation.EQUAL,
      value: searchOptionPayload.searchOptionValue,
      isIgnored: false,
      isNegativeOperation: false,
      isTagsNegativeOperation: false,
      arrayFieldName: null,
    };
    result.push(searchOptionConditionNode);
  }

  const abstractTree = parseExpressionsIntoAbstractQueryTree(result);
  return parseAbstractQueryTreeIntoString(abstractTree);
};

export const transformDateFilterToParserNode = (filters: BigidAdvancedToolbarFilterUnion[]) => {
  return filters.reduce<AbstractQueryNode[]>((acc, filter) => {
    if (filter.type === BigidAdvancedToolbarFilterTypes.DATE_RANGE) {
      const conditionNode = mapDateRangeFilterToConditionNode(filter);
      acc.push(...conditionNode);
      return acc;
    }
  }, []);
};

export const transformDropdownFilterToParserNode = (filters: BigidAdvancedToolbarFilterUnion[]) => {
  return filters.reduce<AbstractQueryNode[]>((acc, filter) => {
    if (filter.type === BigidAdvancedToolbarFilterTypes.DROPDOWN) {
      const conditionNode = mapDropdownFilterToConditionNode(filter);
      acc.push(conditionNode);
      return acc;
    }
  }, []);
};
