import React from 'react';
import { TopFailedControlsWidget } from './TopFailedControlsWidget/TopFailedControlsWidget';
import { TopRiskWidget } from './TopRiskWidget/TopRiskWidget';
import { DataSourceWidget } from './DataSourceWidget/DataSourceWidget';
import { FailedControlsWidget } from './FailedControlsWidget/FailedControlsWidget';
import { ContentWrapper, WidgetsSection } from './WidgetsStyles';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';
import { ComplianceDashboardWidget } from '../ComplianceDashboard';

export enum MenuActions {
  OPEN_SECURITY_POSTURE = 'Open in Security Posture',
  ADD_FILTERS = 'Add to filters',
}

export const MenuItems = [
  {
    id: 1,
    label: MenuActions.OPEN_SECURITY_POSTURE,
    onClick: () => console.log(MenuActions.OPEN_SECURITY_POSTURE),
  },
  { id: 2, label: MenuActions.ADD_FILTERS, onClick: () => console.log(MenuActions.ADD_FILTERS) },
];

interface WidgetsContainerProps {
  activeFilters: BigidAdvancedToolbarFilterUnion[];
  activeWidgetIds: string[];
  dataAid?: string;
}

export const WidgetsContainer = ({ dataAid = 'complianceDashboard', activeWidgetIds }: WidgetsContainerProps) => {
  const isActiveWidget = (widgetId: ComplianceDashboardWidget) => !!activeWidgetIds.includes(widgetId);
  const widgetSectionAmount = [
    isActiveWidget(ComplianceDashboardWidget.TOP_FAILED),
    isActiveWidget(ComplianceDashboardWidget.TOP_RISK),
  ].filter((value: boolean) => value);

  return (
    <ContentWrapper data-aid={generateDataAid(dataAid, ['container'])}>
      {isActiveWidget(ComplianceDashboardWidget.FAILED_CONTROLS) && (
        <FailedControlsWidget data={[]} dataAid={generateDataAid(dataAid, ['failedControls', 'widget'])} />
      )}
      <WidgetsSection amountOfWidgets={widgetSectionAmount.length} isHidden={widgetSectionAmount.length === 0}>
        {isActiveWidget(ComplianceDashboardWidget.TOP_FAILED) && (
          <TopFailedControlsWidget data={[]} dataAid={generateDataAid(dataAid, ['topFailed', 'widget'])} />
        )}
        {isActiveWidget(ComplianceDashboardWidget.TOP_RISK) && (
          <TopRiskWidget data={[]} dataAid={generateDataAid(dataAid, ['topRisk', 'widget'])} />
        )}
      </WidgetsSection>
      {isActiveWidget(ComplianceDashboardWidget.DATA_SOURCE) && (
        <DataSourceWidget data={[]} dataAid={generateDataAid(dataAid, ['dataSource', 'widget'])} />
      )}
    </ContentWrapper>
  );
};
