import { DateISO8601 } from '../../../types/types';
import { httpService } from '../../../services/httpService';
import {
  attachTags,
  createTag,
  detachTags,
  TagAssignmentTarget,
  TagCompositionPartType,
  TagEntity,
  TagResponseEntity,
  UseTagResponseData,
} from '../../TagsManagement/TagsManagementService';
import { getTagEntityByName } from '../../TagsManagement/TagsManagementUtils';
import { BigidTagBaseProps } from '@bigid-ui/components';
import {
  DataCatalogObjectType,
  DataCatalogRecordScannerTypeGroup,
  DetailedObjectType,
} from '../../DataCatalog/DataCatalogService';
import { ReactText } from 'react';

export interface CollaborationStatus {
  canOpenIssue: boolean;
  isFollowing?: boolean;
}

export type ObjectAttributeDetails = {
  name: string;
  count: number;
  type: string[];
  ranks: string[];
};

export interface DataCatalogObjectDetails {
  type: string;
  modified_date: DateISO8601;
  lastUpdatedBy?: string;
  dataSourceName: string;
  ds_location: string;
  num_identities: number;
  fullyQualifiedName: string;
  owner: string;
  scannerType: string;
  objectName: string;
  fullObjectName: string;
  scanner_type_group: string;
  sizeInBytes: number;
  scanDate: number;
  update_date: DateISO8601;
  ds: any[];
  language: string;
  branchName: string;
  reporter: string;
  reported_date: DateISO8601;
  cluster_id?: string;
  duplicate_id?: string;
  cluster_name?: string;
  document_type?: string[];
  was_scanned?: boolean;
  scanStatus?: ScanStatus;
  tags?: TagEntity[];
  messageLink?: string;
  object_owners_struct?: DataOwnerEntity[];
  collaborationStatus: CollaborationStatus;
  tableTotalRows?: number;
  columnOrFieldOccurrencesCounter?: object[];
  attribute_details?: ObjectAttributeDetails[];
  created_date?: DateISO8601;
  createdBy?: string;
  last_opened?: DateISO8601;
  lastAccessedBy?: string;
  isConfidenceLevelIgnored?: boolean;
  extendedObjectType?: DataCatalogObjectType;
}

export interface DataOwnerEntity {
  email: string;
  fullName?: string;
}
export interface DataCatalogObjectDetailsUseTagResponseData {
  tag: TagEntity;
  response?: UseTagResponseData;
}

export interface GetSystemUsersResponse {
  firstName: string;
  id: string;
  name: string;
  roleIds?: string[];
  _id: string;
}

export interface UpdateOwners {
  data: UpdateOwnersData[];
}

export interface UpdateOwnersData {
  fullyQualifiedName: string;
  owners: DataOwnerEntity[];
}

export enum ScanStatus {
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  METADATACOMPLETED = 'MetadataCompleted',
  INPROGRESS = 'InProgress',
}

export const scanStatusDisplayName: Record<ScanStatus, string> = {
  [ScanStatus.COMPLETED]: 'Fully Scanned',
  [ScanStatus.FAILED]: 'Failed',
  [ScanStatus.METADATACOMPLETED]: 'Metadata Completed',
  [ScanStatus.INPROGRESS]: 'In Progress',
};

export const getDetailsByObjectName = (fullyQualifiedName: string) => {
  const fullyQualifiedNameParsed = encodeURIComponent(fullyQualifiedName);
  return httpService
    .fetch<{ data: DataCatalogObjectDetails }>(`data-catalog/object-details/?object_name=${fullyQualifiedNameParsed}`)
    .then(result => result.data);
};

export const getSystemUsers = () => {
  return httpService.fetch<GetSystemUsersResponse[]>(`system_users`).then(result => result.data);
};

export const updateOwners = (data: UpdateOwners) => {
  const refreshHeaders = {
    'catalog-refresh-option': 'refresh',
  };
  return httpService.put(`data-catalog/manual-fields/object-owner`, data, undefined, refreshHeaders);
};

export const createAndAttachTag = async (
  systemTags: TagEntity[],
  tag: BigidTagBaseProps,
  fullyQualifiedName: string,
  source: string,
): Promise<DataCatalogObjectDetailsUseTagResponseData> => {
  let nameCreated: TagResponseEntity;
  let valueCreated: TagResponseEntity;
  let tagNameIdToAttach: TagResponseEntity['_id'];
  let tagValueIdToAttach: TagResponseEntity['_id'];
  const tagNameDescription = `${tag.name} tag name`;
  const tagValueDescription = `${tag.value} tag value`;

  if (tag.isNew) {
    const { data: tagCreatedResponse } = await createTag({
      name: tag.name,
      type: TagCompositionPartType.tag,
      description: tagNameDescription,
    });
    nameCreated = tagCreatedResponse[0];
    tagNameIdToAttach = nameCreated._id;

    const { data: valueCreatedResponse } = await createTag({
      name: tag.value,
      type: TagCompositionPartType.value,
      description: tagValueDescription,
      parentId: tagNameIdToAttach,
    });
    valueCreated = valueCreatedResponse[0];
    tagValueIdToAttach = valueCreated._id;
  } else {
    tagNameIdToAttach = getTagEntityByName(systemTags, tag.name)?.tagId;
    const { data: valueCreatedResponse } = await createTag({
      name: tag.value,
      type: TagCompositionPartType.value,
      description: tagValueDescription,
      parentId: tagNameIdToAttach,
    });

    valueCreated = valueCreatedResponse[0];
    tagValueIdToAttach = valueCreated._id;
  }

  if (tagNameIdToAttach && tagValueIdToAttach) {
    const { data } = await attachTags([
      {
        type: TagAssignmentTarget.object,
        fullyQualifiedName,
        source,
        tags: [
          {
            tagId: tagNameIdToAttach,
            valueId: tagValueIdToAttach,
          },
        ],
      },
    ]);

    return {
      tag: { tagId: tagNameIdToAttach, tagName: tag.name, valueId: tagValueIdToAttach, tagValue: tag.value },
      response: data,
    };
  } else {
    throw { message: 'Tag name or value does not exist' };
  }
};

export const attachTag = async (
  systemTags: TagEntity[],
  tag: BigidTagBaseProps,
  fullyQualifiedName: string,
  source: string,
): Promise<DataCatalogObjectDetailsUseTagResponseData> => {
  const tagToAttach = getTagEntityByName(systemTags, tag.name, tag.value);

  if (tagToAttach?.tagId && tagToAttach?.valueId) {
    const { tagId, valueId } = tagToAttach;
    const { data } = await attachTags([
      {
        type: TagAssignmentTarget.object,
        fullyQualifiedName,
        source,
        tags: [
          {
            tagId,
            valueId,
          },
        ],
      },
    ]);

    return {
      tag: {
        tagId,
        valueId,
        tagName: tagToAttach.tagName,
        tagValue: tagToAttach.tagValue,
      },
      response: data,
    };
  } else {
    throw { message: 'Tag name or value does not exist' };
  }
};

export const detachTag = async (
  systemTags: TagEntity[],
  tag: BigidTagBaseProps,
  fullyQualifiedName: string,
  source: string,
): Promise<DataCatalogObjectDetailsUseTagResponseData> => {
  const tagToDetach = getTagEntityByName(systemTags, tag.name, tag.value);

  if (tagToDetach?.tagId && tagToDetach?.valueId) {
    const { tagId, valueId } = tagToDetach;
    await detachTags([
      {
        type: TagAssignmentTarget.object,
        fullyQualifiedName,
        source,
        tags: [
          {
            tagId,
            valueId,
          },
        ],
      },
    ]);
    return {
      tag: {
        tagId,
        valueId,
        tagName: tagToDetach.tagName,
        tagValue: tagToDetach.tagValue,
      },
    };
  } else {
    throw { message: 'Tag name or value does not exist' };
  }
};

export enum HierarchyType {
  CONTAINER = 'CONTAINER',
  SUB_CONTAINER = 'SUB_CONTAINER',
  LEAF_DATA_OBJECT = 'LEAF_DATA_OBJECT',
}

export interface DataCatalogRecord {
  id: ReactText;
  source: string;
  type: string;
  location: string;
  objectName: string;
  objectId: string;
  scannerType: string;
  containerName?: string;
  containerId?: string;
  subContainerName?: string;
  subContainerId?: string;
  objectType?: DataCatalogObjectType;
  extendedObjectType?: DataCatalogObjectType;
  detailedObjectType?: DetailedObjectType;
  hierarchyType?: HierarchyType;
  fullyQualifiedName: string;
  fullObjectName: string;
  attribute?: string[];
  application_name?: string[];
  open_access: 'Yes' | 'No' | '';
  scanner_type_group?: DataCatalogRecordScannerTypeGroup;
  total_pii_count?: number;
  owner?: string;
  created_date?: DateISO8601;
  document_type?: string;
  ownersList?: string[];
  last_scanned?: DateISO8601;
  ds_owner?: string[];
  last_opened?: DateISO8601;
  scanDate?: DateISO8601;
  modified_date?: DateISO8601;
  catalogUpdateTime?: string;
  branchName?: string;
  reporter?: string;
  comment?: string;
  reported_date?: DateISO8601;
  object_owners_struct?: DataOwnerEntity[];
  update_date?: DateISO8601;
  duplicate_id?: string;
  cluster_id?: string;
  fileId?: string;
  has_duplicates: 'Yes' | 'No';
  tags?: TagEntity[];
  isPermittedToPreview?: boolean;
  attribute_details?: ObjectAttributeDetails[];
  sizeInBytes?: number;
  was_scanned?: boolean;
  language?: string;
  attribute_original_name?: string[];
  scanStatus: ScanStatus;
}

export interface DataCatalogResponse {
  totalRowsCounter?: number;
  estimatedCount?: number;
  total?: number;
  results: DataCatalogRecord[];
}

export const getDataCatalogRecords = (query?: string) => {
  const catalogHeaders = { 'catalog-entity': 'CONTAINERS', 'use-elastic': 'false' };
  return httpService
    .fetch<DataCatalogResponse>(`data-catalog/?format=json${query || ''}`, undefined, catalogHeaders)
    .then(({ data }) => data);
};

export const transformRecordToObjectDetails = (dataCatalogRecord: DataCatalogRecord): DataCatalogObjectDetails => {
  const dataCatalogObjectDetails: DataCatalogObjectDetails = {
    type: dataCatalogRecord.type,
    modified_date: dataCatalogRecord.modified_date,
    dataSourceName: dataCatalogRecord.source,
    ds_location: dataCatalogRecord.location,
    num_identities: null, // This field does not exist in DataCatalogRecord
    fullyQualifiedName: dataCatalogRecord.fullyQualifiedName,
    owner: dataCatalogRecord.owner,
    scannerType: dataCatalogRecord.scannerType,
    objectName: dataCatalogRecord.objectName,
    fullObjectName: dataCatalogRecord.fullObjectName,
    scanner_type_group: dataCatalogRecord.scanner_type_group,
    sizeInBytes: dataCatalogRecord.sizeInBytes,
    scanDate: new Date(dataCatalogRecord.scanDate).getTime(),
    update_date: dataCatalogRecord.update_date,
    ds: [{ owners: [], name: dataCatalogRecord.source, type: dataCatalogRecord.type }],
    language: dataCatalogRecord.language,
    branchName: dataCatalogRecord.branchName,
    reporter: dataCatalogRecord.reporter,
    reported_date: dataCatalogRecord.reported_date,
    cluster_id: dataCatalogRecord.cluster_id,
    duplicate_id: dataCatalogRecord.duplicate_id,
    document_type: dataCatalogRecord.document_type ? [dataCatalogRecord.document_type] : [],
    was_scanned: dataCatalogRecord.was_scanned,
    tags: dataCatalogRecord.tags,
    object_owners_struct: dataCatalogRecord.object_owners_struct,
    collaborationStatus: null, // This field does not exist in DataCatalogRecord
    tableTotalRows: null, // This field does not exist in DataCatalogRecord
    columnOrFieldOccurrencesCounter: null, // This field does not exist in DataCatalogRecord
    attribute_details: dataCatalogRecord.attribute_details,
    created_date: dataCatalogRecord.created_date,
    createdBy: null, // This field does not exist in DataCatalogRecord
    last_opened: dataCatalogRecord.last_opened,
    lastAccessedBy: null, // This field does not exist in DataCatalogRecord
    isConfidenceLevelIgnored: null, // This field does not exist in DataCatalogRecord
    extendedObjectType: dataCatalogRecord.extendedObjectType,
    scanStatus: dataCatalogRecord.scanStatus,
  };
  return dataCatalogObjectDetails;
};
