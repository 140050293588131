export enum ProxyType {
  DATASOURCE_PROXY = 'datasourceProxy',
}

export enum ProtocolType {
  HTTP = 'http',
  HTTPS = 'https',
}

export interface proxyUrl {
  protocol: ProtocolType;
  host: string;
  port: string | number;
}

export interface ProxyItemResponse {
  _id: string;
  created_at: string;
  name: string;
  proxyType: ProxyType;
  isUsingCertificate: boolean;
  proxyUrls: proxyUrl[];
  hostsToBypassProxy: string[];
  description?: string;
  updated_at?: string;
  certificateId?: string;
  certificateType?: string;
  credentialsId?: string;
}

export type ProxyGridRow = Omit<ProxyItemResponse, 'proxyType' | 'created_at' | 'isUsingCertificate'> & {
  id: string | number;
  isPending?: boolean;
  isHttpOrHttps: boolean;
};

export interface CredentialsPermissions {
  isDeletePermitted: boolean;
  isEditPermitted: boolean;
  isCreatePermitted: boolean;
  isTestConnectionPermitted: boolean;
}

export interface ForwardFormRef {
  fieldsToRender: string[];
  formElement: HTMLDivElement;
}
