import { useCancelablePromise } from '@bigid-ui/components';
import { useRef } from 'react';
import { GetAggregatedDataResponse } from '../catalogDiscoveryService';
import {
  SourcesExtendedAggregationItem,
  SourcesGridAggregationItem,
  AttributesGridAggregationItem,
} from '../catalogDiscoveryTypes';
import { GetObjectsPreviewResponse } from '../content/DataSourcesLayout/dataSourcesLayoutService';

export function useFetchDataCancelable() {
  const fetchFindingsCancelable = useRef(useCancelablePromise<GetAggregatedDataResponse>());
  const fetchDuplicateFilesCancelable = useRef(useCancelablePromise<GetAggregatedDataResponse>());
  const fetchViolatedPoliciesCancelable = useRef(useCancelablePromise<GetAggregatedDataResponse>());
  const fetchObjectStatusCancelable = useRef(useCancelablePromise<GetAggregatedDataResponse>());
  const fetchDataFormatCancelable = useRef(useCancelablePromise<GetAggregatedDataResponse>());
  const fetchSensitivityCancelable = useRef(useCancelablePromise<GetAggregatedDataResponse>());
  const fetchTopCategoriesCancelable = useRef(useCancelablePromise<GetAggregatedDataResponse>());
  const fetchAttributesCancelable = useRef(
    useCancelablePromise<GetAggregatedDataResponse<AttributesGridAggregationItem>>(),
  );
  const fetchDataSourcesCancelable = useRef(
    useCancelablePromise<GetAggregatedDataResponse<SourcesGridAggregationItem>>(),
  );
  const fetchDataSourceExtendedCancelable = useRef(
    useCancelablePromise<GetAggregatedDataResponse<SourcesExtendedAggregationItem>>(),
  );
  const fetchScannedVolumeCancelable = useRef(useCancelablePromise<GetAggregatedDataResponse>());
  const fetchObjectsPreviewCancelable = useRef(useCancelablePromise<GetObjectsPreviewResponse>());
  const fetchDataSourceLocationsCancelable = useRef(useCancelablePromise<GetAggregatedDataResponse>());

  return {
    fetchFindingsCancelable: fetchFindingsCancelable.current,
    fetchDuplicateFilesCancelable: fetchDuplicateFilesCancelable.current,
    fetchViolatedPoliciesCancelable: fetchViolatedPoliciesCancelable.current,
    fetchObjectStatusCancelable: fetchObjectStatusCancelable.current,
    fetchDataFormatCancelable: fetchDataFormatCancelable.current,
    fetchSensitivityCancelable: fetchSensitivityCancelable.current,
    fetchAttributesCancelable: fetchAttributesCancelable.current,
    fetchDataSourcesCancelable: fetchDataSourcesCancelable.current,
    fetchScannedVolumeCancelable: fetchScannedVolumeCancelable.current,
    fetchDataSourceExtendedCancelable: fetchDataSourceExtendedCancelable.current,
    fetchObjectsPreviewCancelable: fetchObjectsPreviewCancelable.current,
    fetchTopCategoriesCancelable: fetchTopCategoriesCancelable.current,
    fetchDataSourceLocationsCancelable: fetchDataSourceLocationsCancelable.current,
  };
}
