import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { BigidGridColumnTypes, BigidGridProps, BigidGridQueryComponents, BigidGridRow } from '@bigid-ui/grid';
import { BigidBody1 } from '@bigid-ui/components';
import { allFrameworks, FrameworkProps } from '../../../../../react/views/Frameworks/FrameworksServices';
import {
  BigidLayoutMasterDetails,
  BigidLayoutMasterDetailsConfig,
  BigidLayoutMasterDetailsGridSearchTypes,
  BigidLayoutsMasterDetailsTypes,
  useLayout,
} from '@bigid-ui/layout';
import { $state } from '../../../../../react/services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { frameworkIconGenerator } from '../../../../../react/views/Frameworks/utils';
import { MappedControlsDialogContent } from './MappedControlsDialogContent';
import { useLocalTranslation } from '../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { calculateAmountOfSelectedControls, MappedControlRowProps, NormalizedMappedControlsProps } from '../../utils';
import { notificationService } from '../../../../../react/services/notificationService';

const MainGrid = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: calc(80vh - 32px);
`;

const MainGridTitle = styled.div`
  display: flex;
  column-gap: 4px;
`;

const BodyText1 = styled(BigidBody1)`
  cursor: pointer;
`;

const LayoutMasterDetails = styled(BigidLayoutMasterDetails)`
  height: calc(100% - 32px);
`;

const RowTitle = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RowTitleIcon = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const RowTitleNumber = styled.div(({ theme }) => ({
  height: '24px',
  minWidth: '24px',
  backgroundColor: theme.palette.bigid.gray150,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 2px',
}));

interface MappedControlsDialogProps {
  dataAid?: string;
  dynamicMappedControls: NormalizedMappedControlsProps[];
  handleDialogVisibility: (value: boolean) => void;
  policyFqdn: string;
  fetchMappedControls: () => void;
  handleResetDialog: (value: boolean, frameworkName?: string) => void;
  handleDynamicControls: (dynamicControls: NormalizedMappedControlsProps[]) => void;
  staticMappedControls: NormalizedMappedControlsProps[];
  isContentLoading: boolean;
  handleContentLoading: (value: boolean) => void;
}

export const MappedControlsDialog = ({
  dynamicMappedControls,
  staticMappedControls,
  policyFqdn,
  isContentLoading,
  fetchMappedControls,
  handleResetDialog,
  handleDialogVisibility,
  handleDynamicControls,
  handleContentLoading,
  dataAid = 'MappedDialog',
}: MappedControlsDialogProps) => {
  const { t } = useLocalTranslation();

  const handleRedirect = () => {
    $state.go(CONFIG.states.FRAMEWORKS);
  };

  const args = {
    searchable: true,
    searchType: BigidLayoutMasterDetailsGridSearchTypes.INTEGRATED,
  };
  const { searchType, ...rest } = args;

  const { gridId, rows, isLoading, totalRowsCount, skip, onPagingChanged, defaultSorting, setSelectedRowById } =
    useLayout<any>(BigidLayoutsMasterDetailsTypes.MASTER_DETAILS, {
      pageSize: 100,
      fetchDataFunction: async (queryComponents: BigidGridQueryComponents) => {
        try {
          const data = await allFrameworks(queryComponents);
          const optimizedData: FrameworkProps[] = data
            .filter((item: FrameworkProps) => item.enabled)
            .map((framework: FrameworkProps) => ({ ...framework, id: framework._id }));
          if (Boolean(optimizedData[0]?.id)) {
            setSelectedRowById([optimizedData[0]?.id]);
          }
          return { data: optimizedData, totalCount: optimizedData.length };
        } catch {
          notificationService.error(t('messages.frameworksGetError'), { duration: 3000 });
          return { data: [], totalCount: 0 };
        }
      },
      initialSorting: [{ field: 'framework_name', order: 'asc' }],
    });

  const RenderContentComponent = ({ selectedItem }: { selectedItem: MappedControlRowProps }) => {
    return (
      <>
        {selectedItem && (
          <MappedControlsDialogContent
            row={selectedItem}
            dynamicMappedControls={dynamicMappedControls}
            policyFqdn={policyFqdn}
            handleDialogVisibility={handleDialogVisibility}
            fetchMappedControls={fetchMappedControls}
            handleResetDialog={handleResetDialog}
            handleDynamicControls={handleDynamicControls}
            staticMappedControls={staticMappedControls}
            isContentLoading={isContentLoading}
            handleContentLoading={handleContentLoading}
            dataAid={dataAid}
          />
        )}
      </>
    );
  };

  const gridConfig: BigidGridProps<MappedControlRowProps> = useMemo(
    () => ({
      gridId,
      rows,
      totalRowsCount,
      skip,
      onPagingChanged,
      defaultSorting,
      loading: isLoading,
      pageSize: 10000,
      searchType,
      columns: [
        {
          name: 'framework_name',
          title: 'Framework',
          width: 'auto',
          getCellValue: row => {
            const Icon = frameworkIconGenerator(row.framework_name);
            const { alreadySelected } = calculateAmountOfSelectedControls(dynamicMappedControls, row.framework_name);

            return (
              <RowTitle>
                <RowTitleIcon>
                  <Icon height={24} widht={24} />
                  <BigidBody1 data-aid={generateDataAid(dataAid, ['framework', row.framework_name])}>
                    {row.framework_name}
                  </BigidBody1>
                </RowTitleIcon>
                {Boolean(alreadySelected) && (
                  <RowTitleNumber>
                    <BigidBody1 data-aid={generateDataAid(dataAid, ['framework', row.framework_name, 'amount'])}>
                      {alreadySelected}
                    </BigidBody1>
                  </RowTitleNumber>
                )}
              </RowTitle>
            );
          },
          type: BigidGridColumnTypes.CUSTOM,
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, dynamicMappedControls],
  );

  const config: BigidLayoutMasterDetailsConfig<BigidGridRow> = useMemo<BigidLayoutMasterDetailsConfig<any>>(
    () => ({
      grid: gridConfig,
      search: {
        fields: ['framework_name'],
        placeholder: t('dialog.searchPlaceholder'),
        type: BigidLayoutMasterDetailsGridSearchTypes.INTEGRATED,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridConfig],
  );

  return (
    <MainGrid data-aid={dataAid}>
      <MainGridTitle>
        <BigidBody1 size="large">{t('dialog.title')}</BigidBody1>
        <BodyText1 data-aid={generateDataAid(dataAid, ['frameworksUrl'])} color="blue" onClick={handleRedirect}>
          {t('dialog.manage')}
        </BodyText1>
      </MainGridTitle>
      <LayoutMasterDetails key="Frameworks" config={config as BigidLayoutMasterDetailsConfig<BigidGridRow>} {...rest}>
        <BigidLayoutMasterDetails.Content render={RenderContentComponent} />
      </LayoutMasterDetails>
    </MainGrid>
  );
};
