import { customAppService } from '../../../services/customAppService';
import { AutoDiscoveryConfigModel } from './AutoDiscoveryGridConfiguration';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { ExecutionPayload, generatedUniquePresetName } from '../../CustomApp/views/CustomAppActions/CustomAppActions';
import { notificationService } from '../../../services/notificationService';
import { BigidColorsV2, EntityEvents, BigidFieldFilter } from '@bigid-ui/components';
import { ExecutionStatus } from '../../CustomApp/components/PresetCard/PresetLatestExecutionStatus';
import { downloadFileByAttachmentId } from '../../CustomApp/views/ActivityLog/ActivityLog';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { getDataSourceGridId } from '../../DataSources/DataSourceConnections/config/grid';

export const discoverAction = async ({ _id, appId, appRunAction }: AutoDiscoveryConfigModel) => {
  await customAppService.runCustomAppPreset(appRunAction._id, _id, appId);
  return {
    shouldGridReload: true,
  };
};

export const editAction = async ({ _id, type }: AutoDiscoveryConfigModel) => {
  const params = {
    type,
    id: _id,
  };
  $state.go(CONFIG.states.AUTO_DISCOVERY_PRESET_CONFIG, params);
  return {
    shouldGridReload: false,
  };
};

export const deleteAction = async ({ appId, _id, appRunAction, name }: AutoDiscoveryConfigModel) => {
  try {
    await customAppService.deleteActionPreset(appId, appRunAction._id, _id);
    notificationService.success(`${name} Config - Deleted`, { shouldCloseOnTransition: false });
    return {
      shouldGridReload: true,
      shouldClearSelection: true,
      entityEventToEmit: EntityEvents.RELOAD,
    };
  } catch (err) {
    notificationService.error(`${name} Config delete error. ${err?.response?.data?.error || ''}`);
    console.warn(err);
    return {
      shouldGridReload: false,
      shouldClearSelection: false,
    };
  }
};

export const stopAction = async ({ appId, _id, appStopAction }: AutoDiscoveryConfigModel) => {
  const { data } = await customAppService.getCustomAppLastExecutions(appId);
  const executionId = data.find(({ preset_id }: ExecutionPayload) => preset_id === _id)._id;
  await customAppService.editActionPreset(appId, appStopAction._id, appStopAction.presets[0]._id, {
    paramsKeyValue: {
      executionId,
    },
  });
  await customAppService.runCustomAppPreset(appStopAction._id, appStopAction.presets[0]._id, appId);

  notificationService.success(`Stop request - the action will be stopped within a few minutes`, {
    shouldCloseOnTransition: false,
  });
  return {
    shouldGridReload: false,
  };
};

export const copyAction = async ({
  appId,
  name,
  description,
  paramsKeyValue,
  overrideGlobalParams,
  globalParamsKeyValue,
  appRunAction,
}: AutoDiscoveryConfigModel) => {
  const existingNames = appRunAction.presets.map(({ name }) => name);
  const nameForCloning = generatedUniquePresetName(`Clone - ${name}`, existingNames);
  try {
    const presetData = {
      name: nameForCloning,
      description,
      paramsKeyValue,
      overrideGlobalParams,
      globalParamsKeyValue: overrideGlobalParams ? globalParamsKeyValue : undefined,
    };
    await customAppService.addActionPreset(appId, appRunAction._id, presetData);

    notificationService.success(`Cloned configuration successfully`, { shouldCloseOnTransition: false });
  } catch (err) {
    console.warn(err);
    const message = 'There is a problem cloning this configuration';
    notificationService.error(message);
  }
  return {
    shouldGridReload: true,
    shouldClearSelection: true,
    entityEventToEmit: EntityEvents.RELOAD,
  };
};

export const downloadReport = async ({ latestRun }: AutoDiscoveryConfigModel) => {
  try {
    await downloadFileByAttachmentId(latestRun.attachments[0].id);
  } catch (err) {
    console.warn(err);
    notificationService.error('There is a problem download report for this configuration');
  }

  return {
    shouldGridReload: false,
    shouldClearSelection: false,
  };
};

export const downloadLogs = async ({ appId }: AutoDiscoveryConfigModel) => {
  try {
    await customAppService.downloadLogs(appId);
  } catch (err) {
    console.warn(err);
    notificationService.error('There is a problem download logs for the app');
  }

  return {
    shouldGridReload: false,
    shouldClearSelection: false,
  };
};

export const getTheRequiredColorsToChip = (status: ExecutionStatus) => {
  switch (status) {
    case ExecutionStatus.COMPLETED:
      return {
        color: BigidColorsV2.green[900],
        bgColor: BigidColorsV2.green[100],
      };
    case ExecutionStatus.ERROR:
      return {
        color: BigidColorsV2.red[700],
        bgColor: BigidColorsV2.red[100],
      };
    case ExecutionStatus.IN_PROGRESS:
      return {
        color: BigidColorsV2.blue[900],
        bgColor: BigidColorsV2.blue[100],
      };
    default:
      return {
        bgColor: BigidColorsV2.gray[150],
        color: BigidColorsV2.gray[600],
      };
  }
};

const DISCOVERY_APP_PRESET_ID_KEY = 'discoveryAppPresetIdForFilter';

const getPresetIdFilter = (id: string): BigidFieldFilter => ({
  field: 'discoveryAppPresetId',
  value: id,
  operator: 'equal',
});
export const goToDataSourcesWithPresetFilter = async (id: string) => {
  sessionStorageService.set(DISCOVERY_APP_PRESET_ID_KEY, id);
  const preferenceKey = getDataSourceGridId();
  const preferences = await userPreferencesService.get(preferenceKey);
  if (preferences) {
    await userPreferencesService.update({
      preference: preferenceKey,
      grid: preferences.grid,
      filter: {},
    });
  }

  $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, {
    tab: 'ds',
  });
};

export const getDiscoveryAppPresetFilter = () => {
  const discoveryAppPresetId = sessionStorageService.get(DISCOVERY_APP_PRESET_ID_KEY) as string;

  if (discoveryAppPresetId) {
    sessionStorageService.remove('discoveryAppPresetIdForFilter');
    return getPresetIdFilter(discoveryAppPresetId);
  }
};
