import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { BigidColorsV2, BigidToggleButton, BigidHeading6 } from '@bigid-ui/components';
import {
  BigidPrivacyDeleteIllustration,
  BigidPrivacyEditIllustration,
  BigidPrivacySettingsIllustration,
} from '@bigid-ui/icons';
import { LegalEntitiesTrackingEvents, trackLegalEntities } from './utils/analytics';

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  background-color: ${BigidColorsV2.gray[150]};
`;

const PreviewHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PreviewContentWrapper = styled.div`
  display: flex;
  padding-top: 32px;
  padding-bottom: 8px;
  margin: 0 auto;
  height: 468px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  height: 400px;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 2px 8px 0px ${BigidColorsV2.black}1a;
  background-color: ${BigidColorsV2.white};
`;

const EmailWrapper = styled(ComponentWrapper)`
  width: 430px;
  height: 400px;
  gap: 16px;
`;

const ReportWrapper = styled(ComponentWrapper)`
  width: 291px;
  height: 428px;
`;

const SiteWrapper = styled(ComponentWrapper)`
  width: 458px;
  height: 293px;
  padding: 16px 34px;
  background-color: ${BigidColorsV2.gray[150]};
`;

const Logo = styled.img`
  object-fit: contain;
`;

const SiteCard = styled(Stack)(({ theme, color }) => ({
  width: '74px',
  height: '57px',
  border: `1px solid ${color || theme.vars.palette.bigid.gray200}`,
  borderRadius: '8px',
  background: theme.vars.palette.bigid.white,
  boxShadow: theme.vars.tokens.bigid.shadow5,
  padding: '5px 6px',
}));

const Placeholder = styled('span')(({ width, white }: { width: string | number; white?: boolean }) => ({
  width,
  height: '5.15px',
  borderRadius: '22px',
  backgroundColor: white ? BigidColorsV2.white : `${BigidColorsV2.gray[700]}1F`,
  margin: 0,
}));

interface PreviewProps {
  logo: File;
  color: string;
}

const TOGGLE_BUTTONS = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Report',
    value: 'report',
  },
  {
    label: 'Site',
    value: 'site',
  },
];

export const LegalEntitiesBrandingPreview = (props: PreviewProps) => {
  const [currentTab, setCurrentTab] = useState(TOGGLE_BUTTONS[0].value);
  const TabComponent = COMPONENT_MAPPER[currentTab];

  return (
    <PreviewWrapper>
      <PreviewHeaderWrapper>
        <BigidHeading6>{'Sample'}</BigidHeading6>
        <BigidToggleButton
          size="small"
          initialSelected={currentTab}
          onChange={(_, value) => {
            setCurrentTab(value);
            trackLegalEntities(ANALYTICS_TRACKING_MAPPER[value]);
          }}
          minWidth={78}
          toggleButtons={TOGGLE_BUTTONS}
        />
      </PreviewHeaderWrapper>
      <PreviewContentWrapper>
        <TabComponent {...props} />
      </PreviewContentWrapper>
    </PreviewWrapper>
  );
};

const Email = ({ logo, color }: PreviewProps) => {
  return (
    <EmailWrapper>
      <Stack direction="row" spacing={0.5}>
        {logo && <Logo width="24px" height="24px" src={URL.createObjectURL(logo)} alt="Logo" />}
        <Stack direction="column" spacing="5px" sx={{ justifyContent: 'center' }}>
          <Placeholder width={71} />
          <Placeholder width={37} />
        </Stack>
      </Stack>
      <Stack
        direction="column"
        sx={{
          bgcolor: BigidColorsV2.gray[150],
          padding: '60px 45px',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', marginBottom: '12px' }}>
          {logo && <Logo width="24px" height="24px" src={URL.createObjectURL(logo)} alt="Logo" />}
          <Placeholder width={71} />
        </Stack>
        <Stack
          sx={{
            width: '100%',
            height: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: color,
            borderRadius: '4.26px 4.26px 0px 0px',
          }}
        >
          <Placeholder white width={98} />
        </Stack>
        <Stack
          spacing="6px"
          sx={{
            width: '100%',
            padding: '25px 0 16px 25px',
            bgcolor: BigidColorsV2.white,
          }}
        >
          <Placeholder width={71} />
          <Placeholder width={200} />
          <Placeholder width={135} />
          <Stack sx={{ alignItems: 'center' }}>
            <Stack
              sx={{
                width: '108px',
                height: '21px',
                bgcolor: color,
                marginTop: '21px',
                borderRadius: '2px',
              }}
            />
          </Stack>
        </Stack>
        <Stack spacing="6px" sx={{ alignItems: 'center', marginTop: '12px' }}>
          <Placeholder width={108} />
          <Placeholder width={49} />
        </Stack>
      </Stack>
    </EmailWrapper>
  );
};

const ReportIcon = ({ color }: { color: string }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
    <circle cx="6.63297" cy="6.51139" r="5.92149" stroke={color} strokeWidth="0.422964" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.42124 3.27295H9.02097C9.30323 3.27295 9.53205 3.50177 9.53205 3.78403V4.29443V4.63515V9.23556C9.53205 9.51782 9.30323 9.74664 9.02097 9.74664H4.42124C4.13898 9.74664 3.91016 9.51782 3.91016 9.23556V4.63515V4.29443V3.78403C3.91016 3.50177 4.13898 3.27295 4.42124 3.27295ZM4.25 4.63515V9.23664C4.25 9.33073 4.32627 9.407 4.42036 9.407H9.02009C9.11418 9.407 9.19045 9.33073 9.19045 9.23664V4.63515H4.25ZM9.19045 4.29443H4.25V3.78511C4.25 3.69102 4.32627 3.61475 4.42036 3.61475H9.02009C9.11418 3.61475 9.19045 3.69102 9.19045 3.78511V4.29443ZM4.76172 5.82808C4.76172 5.73399 4.83799 5.65771 4.93208 5.65771H8.50965C8.60373 5.65771 8.68001 5.73399 8.68001 5.82808C8.68001 5.92216 8.60373 5.99844 8.50965 5.99844H4.93208C4.83799 5.99844 4.76172 5.92216 4.76172 5.82808ZM4.93208 6.84912C4.83799 6.84912 4.76172 6.92539 4.76172 7.01948C4.76172 7.11357 4.83799 7.18984 4.93208 7.18984H8.50965C8.60373 7.18984 8.68001 7.11357 8.68001 7.01948C8.68001 6.92539 8.60373 6.84912 8.50965 6.84912H4.93208ZM4.76172 8.21479C4.76172 8.12071 4.83799 8.04443 4.93208 8.04443H8.50965C8.60373 8.04443 8.68001 8.12071 8.68001 8.21479C8.68001 8.30888 8.60373 8.38515 8.50965 8.38515H4.93208C4.83799 8.38515 4.76172 8.30888 4.76172 8.21479ZM8.85049 3.95308C8.85049 3.85899 8.77421 3.78271 8.68013 3.78271C8.58604 3.78271 8.50977 3.85899 8.50977 3.95308C8.50977 4.04716 8.58604 4.12344 8.68013 4.12344C8.77421 4.12344 8.85049 4.04716 8.85049 3.95308ZM7.99849 3.78271C8.09257 3.78271 8.16885 3.85899 8.16885 3.95308C8.16885 4.04716 8.09257 4.12344 7.99849 4.12344C7.9044 4.12344 7.82812 4.04716 7.82812 3.95308C7.82812 3.85899 7.9044 3.78271 7.99849 3.78271Z"
      fill={color}
    />
  </svg>
);

const Report = ({ logo, color }: PreviewProps) => {
  return (
    <ReportWrapper>
      <Stack direction="row" spacing={0.5}>
        {logo && <Logo width="16px" height="16px" src={URL.createObjectURL(logo)} alt="Logo" />}
        <Stack spacing="5px" sx={{ justifyContent: 'center' }}>
          <Placeholder width={71} />
        </Stack>
      </Stack>
      <Stack sx={{ borderTop: `1px solid ${BigidColorsV2.gray[200]}`, mt: '16px', mb: '16px' }}></Stack>
      <Stack sx={{ justifyContent: 'center', p: '96px 16px 48px' }}>
        <Stack sx={{ fontSize: '22px', fontWeight: 800, color }}>{'Title'}</Stack>
        <Stack sx={{ mt: '14px' }}>
          <Placeholder width={80} />
        </Stack>
        <Stack sx={{ mt: '23px' }}>
          <Placeholder width={63} />
        </Stack>
        <Stack sx={{ mt: '19px' }}>
          <Placeholder width={177} />
        </Stack>
        <Stack sx={{ mt: '5px' }}>
          <Placeholder width={120} />
        </Stack>
      </Stack>
      <Stack sx={{ borderTop: `1px solid ${color}` }}></Stack>
      <Stack direction="row" spacing={2} sx={{ mt: '21px' }}>
        <Stack direction="row" spacing="4px">
          <ReportIcon color={color} />
          <Stack spacing="5px">
            <Placeholder width={39} />
            <Placeholder width={78} />
          </Stack>
        </Stack>
        <Stack direction="row" spacing="4px">
          <ReportIcon color={color} />
          <Stack spacing="5px">
            <Placeholder width={39} />
            <Placeholder width={78} />
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ borderTop: `1px solid ${BigidColorsV2.gray[200]}`, mt: '16px', mb: '16px' }}></Stack>
      <Placeholder width={109} />
    </ReportWrapper>
  );
};

const Site = ({ logo, color }: PreviewProps) => {
  return (
    <SiteWrapper>
      <Stack
        sx={{
          bgcolor: BigidColorsV2.gray[100],
          border: `1px solid ${BigidColorsV2.gray[200]}`,
          borderRadius: '4px',
          overflow: 'hidden',
        }}
      >
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ height: '12px', alignItems: 'center', p: '4px 8px', bgcolor: BigidColorsV2.gray[150] }}
        >
          <Stack sx={{ bgcolor: '#EF6961', borderRadius: '50%', width: '3.5px', height: '3.5px' }}></Stack>
          <Stack sx={{ bgcolor: '#ECBB1A', borderRadius: '50%', width: '3.5px', height: '3.5px' }}></Stack>
          <Stack sx={{ bgcolor: '#34589D', borderRadius: '50%', width: '3.5px', height: '3.5px' }}></Stack>
        </Stack>
        <Stack direction="row" spacing={0.5} sx={{ p: '4px 4px 4px 8px', bgcolor: BigidColorsV2.white }}>
          {logo && <Logo width="16px" height="16px" src={URL.createObjectURL(logo)} alt="Logo" />}
          <Stack spacing="5px" sx={{ justifyContent: 'center' }}>
            <Placeholder width={38} />
          </Stack>
        </Stack>
        <Stack spacing="6px" sx={{ alignItems: 'center', mt: '16px' }}>
          <Placeholder width={188} />
          <Placeholder width={70} />
        </Stack>
        <Stack direction="row" spacing={0.5} sx={{ pl: '22px', pt: '8px' }}>
          <Stack
            sx={{
              bgcolor: BigidColorsV2.white,
              border: `1px solid ${BigidColorsV2.gray[700]}CC`,
              borderRadius: '50%',
              width: '6.42px',
              height: '6.42px',
            }}
          ></Stack>
          <Stack spacing="5px" sx={{ justifyContent: 'center' }}>
            <Placeholder width={71} />
          </Stack>
        </Stack>
        <Stack direction="row" spacing="5.71px" sx={{ ml: '22px', mt: '11px', alignItems: 'center' }}>
          <Stack
            sx={{ width: '0.7px', height: '74px', ml: '2.85px', mr: '5px', bgcolor: BigidColorsV2.gray[300] }}
          ></Stack>
          <SiteCard color={color} sx={{ justifyContent: 'space-between' }}>
            <Placeholder width={24} />
            <Stack
              sx={{
                width: '22px',
                height: '10px',
                borderRadius: '1.24px',
                bgcolor: color,
                alignSelf: 'flex-end',
                justifySelf: 'flex-end',
              }}
            />
          </SiteCard>
          <SiteCard sx={{ justifyContent: 'center', alignItems: 'center', gap: '5.71px' }}>
            <Stack
              sx={{
                width: '26px',
                height: '26px',
                '& > svg': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              <BigidPrivacyEditIllustration />
            </Stack>
            <Placeholder width={26} />
          </SiteCard>
          <SiteCard sx={{ justifyContent: 'center', alignItems: 'center', gap: '5.71px' }}>
            <Stack
              sx={{
                width: '26px',
                height: '26px',
                '& > svg': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              <BigidPrivacyDeleteIllustration />
            </Stack>
            <Placeholder width={26} />
          </SiteCard>
          <SiteCard sx={{ justifyContent: 'center', alignItems: 'center', gap: '5.71px' }}>
            <Stack
              sx={{
                width: '26px',
                height: '26px',
                '& > svg': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              <BigidPrivacySettingsIllustration />
            </Stack>
            <Placeholder width={26} />
          </SiteCard>
        </Stack>
        <Stack direction="row" spacing={0.5} sx={{ pl: '22px', pt: '8px' }}>
          <Stack
            sx={{
              bgcolor: BigidColorsV2.gray[300],
              borderRadius: '50%',
              width: '6.42px',
              height: '6.42px',
            }}
          ></Stack>
          <Stack spacing="5px" sx={{ justifyContent: 'center' }}>
            <Placeholder width={71} />
          </Stack>
        </Stack>
        <Stack direction="row" spacing="5px" sx={{ p: '5px 8px', mt: '40px', alignItems: 'center' }}>
          <Placeholder width={25} />
          <Placeholder width={39} />
          <Placeholder width={23} />
        </Stack>
      </Stack>
    </SiteWrapper>
  );
};

const COMPONENT_MAPPER = {
  [TOGGLE_BUTTONS[0].value]: Email,
  [TOGGLE_BUTTONS[1].value]: Report,
  [TOGGLE_BUTTONS[2].value]: Site,
};

const ANALYTICS_TRACKING_MAPPER = {
  [TOGGLE_BUTTONS[0].value]: LegalEntitiesTrackingEvents.BRANDING_DIALOG_PREVIEW_EMAIL_TAB_CLICK,
  [TOGGLE_BUTTONS[1].value]: LegalEntitiesTrackingEvents.BRANDING_DIALOG_PREVIEW_REPORT_TAB_CLICK,
  [TOGGLE_BUTTONS[2].value]: LegalEntitiesTrackingEvents.BRANDING_DIALOG_PREVIEW_SITE_TAB_CLICK,
};
