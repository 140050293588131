import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidColorsV2, BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourceGridRecord } from './DataSourcesLayout';
import { formatNumberCompact, formatBytes } from '../../../../utilities/numericDataConverter';
import { useLocalTranslation } from './translations';
import { isUndefined } from 'lodash';
import { DataSourceIconByDsType } from '@bigid-ui/icons';

interface DataSourcesHotspotTooltipContentProps {
  dataAid?: string;
  dataSourceName: string;
  row: DataSourceGridRecord;
}

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

const IconFrame = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 1px solid ${BigidColorsV2.gray[200]};
`;

const Properties = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Property = styled('div')`
  display: flex;
  align-items: center;
`;

const PropertyName = styled(BigidBody1)`
  margin-right: 4px;
`;

const PropertyValue = styled(BigidBody1)`
  font-weight: bold;
`;

export const DataSourcesHotspotTooltipContent: FC<DataSourcesHotspotTooltipContentProps> = ({
  dataAid = 'DataSourcesHotspotTooltipContent',
  dataSourceName,
  row,
}) => {
  const { t } = useLocalTranslation('hotspot');
  const { id, sourceType, aggItemName, docCount, findings, size, sourceLocation } = row;
  return (
    <Root data-aid={generateDataAid(dataAid, [id])}>
      {dataSourceName && (
        <IconContainer>
          <IconFrame>
            <DataSourceIconByDsType
              data-aid={generateDataAid(dataAid, ['icon', sourceType])}
              dataSourceType={sourceType}
              width={42}
              height={42}
            />
          </IconFrame>
        </IconContainer>
      )}
      <Properties>
        {aggItemName && (
          <Property>
            <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'aggItemName', 'name'])}>
              {t('fields.name')}
            </PropertyName>
            <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'aggItemName', 'value'])}>
              {aggItemName}
            </PropertyValue>
          </Property>
        )}
        {dataSourceName && (
          <Property>
            <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'sourceType', 'name'])}>
              {t('fields.type')}
            </PropertyName>
            <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'sourceType', 'value'])}>
              {dataSourceName}
            </PropertyValue>
          </Property>
        )}
        {!isUndefined(docCount) && (
          <Property>
            <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'docCount', 'name'])}>
              {t('fields.numOfObjects')}
            </PropertyName>
            <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'docCount', 'value'])}>
              {formatNumberCompact(docCount)}
            </PropertyValue>
          </Property>
        )}
        {!isUndefined(findings) && (
          <Property>
            <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'findings', 'name'])}>
              {t('fields.numOfFindings')}
            </PropertyName>
            <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'findings', 'value'])}>
              {formatNumberCompact(findings)}
            </PropertyValue>
          </Property>
        )}
        {!isUndefined(size) && (
          <Property>
            <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'size', 'name'])}>
              {t('fields.volume')}
            </PropertyName>
            <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'size', 'value'])}>
              {formatBytes(size, 2, true)}
            </PropertyValue>
          </Property>
        )}
        {sourceLocation && (
          <Property>
            <PropertyName data-aid={generateDataAid(dataAid, [id, 'property', 'sourceLocation', 'name'])}>
              {t('fields.location')}
            </PropertyName>
            <PropertyValue data-aid={generateDataAid(dataAid, [id, 'property', 'sourceLocation', 'value'])}>
              {sourceLocation}
            </PropertyValue>
          </Property>
        )}
      </Properties>
    </Root>
  );
};
