import React, { FC } from 'react';
import {
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidMenuItemProps,
} from '@bigid-ui/components';
import { useDashboardToolbar } from '../../../components/ExecutiveDashboard/hooks/useDashboardToolbar';
import { ExecutiveDashboard, DashboardType } from '../../../components/ExecutiveDashboard';
import { useLocalTranslation } from '../translations';
import { WidgetsContainer } from './Widgets/WidgetsContainer';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { allFrameworks } from '../../Frameworks/FrameworksServices';

export const COMPLIANCE_DASHBOARD_ID = 'complianceDashboard';

enum FilterToolbarTitles {
  RISK_TYPE = 'riskType',
  DATA_SOURCE = 'dataSource',
  ENVIRONMENT = 'environment',
  FRAMEWORKS = 'frameworks',
}

export enum ComplianceDashboardWidget {
  FAILED_CONTROLS = 'failedControls',
  TOP_FAILED = 'topFailed',
  TOP_RISK = 'topRisk',
  DATA_SOURCE = 'dataSource',
}

const widgetIds = [
  ComplianceDashboardWidget.FAILED_CONTROLS,
  ComplianceDashboardWidget.TOP_FAILED,
  ComplianceDashboardWidget.TOP_RISK,
  ComplianceDashboardWidget.DATA_SOURCE,
];

export const ComplianceDashboard: FC = () => {
  const { t } = useLocalTranslation('compliance');
  const widgetSelectionItems: BigidMenuItemProps[] = [
    {
      id: ComplianceDashboardWidget.FAILED_CONTROLS,
      label: t(`widgets.${ComplianceDashboardWidget.FAILED_CONTROLS}.title`),
    },
    { id: ComplianceDashboardWidget.TOP_FAILED, label: t(`widgets.${ComplianceDashboardWidget.TOP_FAILED}.title`) },
    { id: ComplianceDashboardWidget.TOP_RISK, label: t(`widgets.${ComplianceDashboardWidget.TOP_RISK}.title`) },
    {
      id: ComplianceDashboardWidget.DATA_SOURCE,
      label: t(`widgets.${ComplianceDashboardWidget.DATA_SOURCE}.title`),
    },
  ];
  const toolbarFilters: BigidAdvancedToolbarFilterUnion[] = [
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: FilterToolbarTitles.RISK_TYPE,
      field: FilterToolbarTitles.RISK_TYPE,
      title: t(`dashboard.toolbarTitles.${FilterToolbarTitles.RISK_TYPE}`),
      parentId: FilterToolbarTitles.RISK_TYPE,
      operator: 'in',
      options: [],
      asyncOptionsFetch: async () => [],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: FilterToolbarTitles.DATA_SOURCE,
      field: FilterToolbarTitles.DATA_SOURCE,
      title: t(`dashboard.toolbarTitles.${FilterToolbarTitles.DATA_SOURCE}`),
      parentId: FilterToolbarTitles.DATA_SOURCE,
      operator: 'in',
      options: [],
      asyncOptionsFetch: async () => [],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: FilterToolbarTitles.FRAMEWORKS,
      field: FilterToolbarTitles.FRAMEWORKS,
      title: t(`dashboard.toolbarTitles.${FilterToolbarTitles.FRAMEWORKS}`),
      parentId: FilterToolbarTitles.FRAMEWORKS,
      operator: 'in',
      options: [],
      asyncOptionsFetch: async () => {
        const queryComponents: BigidGridQueryComponents = {
          requireTotalCount: true,
          skip: 0,
          offset: 0,
          limit: 100,
          sort: [
            {
              field: 'framework_name',
              order: 'asc',
            },
          ],
          grouping: [],
        };
        const data = await allFrameworks(queryComponents);
        const fileteredFrameworks = data.filter((framework: any) => framework.enabled === true);
        const filteredOptions = fileteredFrameworks.map((fileteredFramework: any) => ({
          id: fileteredFramework._id,
          displayValue: fileteredFramework.framework_name,
          value: fileteredFramework.framework_name,
        }));
        return filteredOptions;
      },
    },
  ];

  const { activeFilters, externalAppliedFilters, activeWidgetIds, toolbarActions } = useDashboardToolbar(
    widgetIds,
    toolbarFilters,
    COMPLIANCE_DASHBOARD_ID,
  );

  return (
    <ExecutiveDashboard
      dashboardId={COMPLIANCE_DASHBOARD_ID}
      dashboardType={DashboardType.COMPLIANCE}
      toolbarFilters={toolbarFilters}
      activeFilters={activeFilters}
      toolbarActions={toolbarActions}
      externalAppliedFilters={externalAppliedFilters}
      isSavedFiltersTabsDisplayed={true}
      activeWidgetIds={activeWidgetIds}
      widgetSelectionItems={widgetSelectionItems}
    >
      <WidgetsContainer activeWidgetIds={activeWidgetIds} activeFilters={activeFilters} />
    </ExecutiveDashboard>
  );
};
