import { ReactText } from 'react';
import { BigidGridRow, IconFormatterProps, ChipsFormatterProps } from '@bigid-ui/grid';
import { httpService } from '../../../../../../../services/httpService';
import { SystemAttributeType } from '../../../../../../DataCatalog/DataCatalogService';
import { analyticsService } from '../../../../../../../services/analyticsService';
import { DataExplorerEventsEnum, getBiEventName } from '../../../../../events';
import { getDetailsByObjectName } from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';

export interface AssociatedColumn {
  column_name: string;
  rank: string;
  calc_confidence_level: number;
}

export interface Category {
  _id: string;
  display_name: string;
  unique_name: string;
  color: string;
  glossary_id: string;
}

export interface DataCatalogAttribute {
  fullyQualifiedName: string;
  attribute_name: string;
  attribute_original_name: string;
  column_list: AssociatedColumn[];
  categories: Category[];
  description: string;
  business_flow: string[];
  is_support_investigation: boolean;
  is_expired: boolean;
  investigation_scan_id_list: string[];
  attribute_type: string;
  attribute_original_type: string;
  attribute_id: string;
}

export interface AttributesGridColumn extends Partial<DataCatalogAttribute>, BigidGridRow {
  columns?: string;
  purposes: string;
  confidenceLevel: ChipsFormatterProps;
  cachedValues?: IconFormatterProps;
  numberOfFindings: number;
}

export interface FetchClearAttrValuePayload {
  fullyQualifiedName: string;
  attribute_name: string;
  attribute_type: SystemAttributeType;
}

export const getAttributesByObjectName = (id: ReactText) => {
  const idParsed = encodeURIComponent(id);
  return httpService
    .fetch<{ data: DataCatalogAttribute[] }>(`data-catalog/object-details/attributes?object_name=${idParsed}`)
    .then(({ data }) => data);
};

export const fetchClearAttributeValue = (data: FetchClearAttrValuePayload) => {
  return httpService.post(`data-catalog/fetch-clear-value`, data).then(({ data }) => data);
};

export const deleteCachedValue = (data: FetchClearAttrValuePayload) => {
  return httpService.delete(`data-catalog/fetch-clear-value`, data).then(({ data }) => data);
};

export const getPiiInvestigationData = (piiInvestigationId: string) => {
  return httpService.cancelableFetch(`piiInvestigations/${piiInvestigationId}`);
};

export const fetchAttributesData = async (fullyQualifiedName: string) => {
  const { data: objectDetails } = await getDetailsByObjectName(fullyQualifiedName);
  const { data } = await getAttributesByObjectName(fullyQualifiedName);

  const trackData = {
    fullyQualifiedName,
    dsType: objectDetails.scannerType,
    dsName: objectDetails.dataSourceName,
    attributeName: objectDetails.objectName,
    scannerType: objectDetails.scanner_type_group,
  };

  analyticsService.trackManualEvent(getBiEventName(DataExplorerEventsEnum.ATTRIBUTE_TAB_FETCH_VALUES), trackData);

  return {
    totalCount: data.length,
    data,
    objectDetails,
  };
};
