import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BigidNavigation, NavigationEditActions, SideBarStatus, useSidebarNavigation } from '@bigid-ui/components';
import { $state, $transitions } from '../../services/angularServices';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { userPreferencesService } from '../../services/userPreferencesService';
import {
  APP_MANAGEMENT_USER_PREFERENCE_NAME,
  ApplicationManagementPreferences,
  CategoriesEnum,
  CustomApps,
  NavigationMenuItem,
} from '../../views/ApplicationsManagement/applicationManagementService';
import { userPreferencesEventEmitter, UserPreferencesEvents } from '../../services/eventEmitters/userPreferencesEvents';
import { getFavoriteApps } from '../Sidebar/SidebarUtils';
import { customAppService } from '../../services/customAppService';
import { privacyPortalService } from '../../services/privacyPortalService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { CustomAppEvents, customAppEventsEmitter } from '../../services/customAppEvents';
import { EventEmitterDeregistrator } from '@bigid-ui/utils';
import {
  BigidSidebarItemType,
  FAVORITES_LINK,
  getInitialFooterItems,
  getInitialHeaderItems,
  getInitialNavbarItems,
  getSideNavigationPreferencesByWorkspace,
  reorderFavoritesItemsByUserSettings,
  updateSideNavigationPreferencesByWorkspace,
  combineNavigationItems,
  sortNavItems,
  getMostSimilarSelectedLink,
  getAppsNavLinks,
  getNavItemsDefaultSettingsByWorkspace,
  applyDefaultVisibilityByWorkspace,
  getAllNavLinksFromBigidNavigation,
} from './navigationSidebarUtils';
import { CONFIG } from '../../../config/common';
import { notificationService } from '../../services/notificationService';
import { usePermissionNavigationItems } from './usePermissionNavigationItems';
import { getFavoritePages } from './favoritePagesManagementService';
import { useFavoritesAggregated } from './hooks/useFavoritesAggregated';
import { getPopularApps } from './utils';
import { NavigationGuidedTourLazy } from './NavigationGuidedTour/NavigationGuidedTourLazy';
import { getUniqueCustomApps } from '../../views/ApplicationsManagement/Tabs/InstalledApps';
import { updateWorkspaceSettingsByWorkspaceId, useWorkspaces } from '../../services/workspacesService';
import { WorkspacesGuidedTourLazy } from './WorkSpacesGuidedTour/WorkspacesGuidedTourLazy';
import { BIGID_BI_EVENTS } from '../../config/BigIdBiEvents';
import { analyticsService } from '../../services/analyticsService';
import { useGuidedTourManager } from '../../hooks/useGuidedTourManager';
import { isWorkspacesEnabled } from '../../utilities/featureFlagUtils';

const LINKS_WITHOUT_REDIRECT = [
  'gettingStartedTEMP',
  'settingsTEMP',
  'dataManagementTEMP',
  'reportsTEMP',
  'dataExplorerTEMP',
  'favoritesLinkPlaceholder',
];

const CUSTOM_APP_ACTION_PAGE = 'customAppActionsPage';
export const CUSTOM_APP_PAGE = 'customApp';

export const NavigationSidebar: FC = () => {
  const [customApps, setCustomApps] = useState<CustomApps[]>([]);
  const [privacyPortalNavLinks, setPrivacyPortalNavLinks] = useState<NavigationMenuItem[]>([]);
  const { selectedWorkspaceId } = useWorkspaces();

  const onNavigationItemClicked = useCallback((link: string) => {
    if (isWorkspacesEnabled()) {
      analyticsService.trackManualEvent(BIGID_BI_EVENTS.WORKSPACES_LEFT_NAV_ITEM_CLICK, { link });
    }
    if (link.startsWith(CONFIG.states.CUSTOM_APP) || link.startsWith(CONFIG.states.CUSTOM_APP_DEEP_LINKING)) {
      const parts = link.split('/');
      const appId = parts[1];
      const appRoute = parts.slice(2).join('/');
      $state.go(
        CONFIG.states.CUSTOM_APP_DEEP_LINKING,
        { id: appId, appRoute: appRoute || null },
        { reload: false, notify: false },
      );
      return;
    }
    $state.go(link);
  }, []);

  const initialNavigationItems = useMemo(
    () => getInitialNavbarItems(onNavigationItemClicked),
    [onNavigationItemClicked],
  );

  const initialFooterItems: BigidSidebarItemType[] = useMemo(
    () => getInitialFooterItems(onNavigationItemClicked),
    [onNavigationItemClicked],
  );

  const initialHeaderItems: BigidSidebarItemType[] = useMemo(
    () => getInitialHeaderItems(onNavigationItemClicked, selectedWorkspaceId),
    [onNavigationItemClicked, selectedWorkspaceId],
  );

  const appNavItems = useMemo(
    () => getAppsNavLinks(customApps, privacyPortalNavLinks, onNavigationItemClicked),
    [customApps, privacyPortalNavLinks, onNavigationItemClicked],
  );

  const { navigationItemsPermitted, footerItemsPermitted, headerItemsPermitted } = usePermissionNavigationItems({
    navigationItems: initialNavigationItems,
    footerItems: initialFooterItems,
    headerItems: initialHeaderItems,
  });

  const sortedNavItems = useMemo(() => {
    if (!isWorkspacesEnabled()) return navigationItemsPermitted;

    const combinedNavigationItems = combineNavigationItems(navigationItemsPermitted, appNavItems);
    const updatedNavItems = applyDefaultVisibilityByWorkspace(combinedNavigationItems, selectedWorkspaceId);

    return sortNavItems(updatedNavItems);
  }, [navigationItemsPermitted, appNavItems, selectedWorkspaceId]);

  const initialUserMenuSettings = useMemo(
    () => getNavItemsDefaultSettingsByWorkspace(sortedNavItems, selectedWorkspaceId),
    [sortedNavItems, selectedWorkspaceId],
  );

  const filteredHeaderItems = useMemo(() => {
    if (!isWorkspacesEnabled()) return headerItemsPermitted;
    return headerItemsPermitted.filter(item => item.workspaces?.includes(selectedWorkspaceId));
  }, [headerItemsPermitted, selectedWorkspaceId]);

  const {
    setSelectedItemLink,
    selectedItemLink,
    navigationItems,
    footerItems,
    favorites,
    defaultMenuSettings,
    dispatchMenuEditAction,
    headerItems,
    hoveredItem,
    saveSettingsRequired,
    setNavigationItems,
  } = useSidebarNavigation({
    selectedItemLink: isWorkspacesEnabled() ? '' : CONFIG.states.DASHBOARD,
    navigationItems: sortedNavItems,
    footerItems: footerItemsPermitted,
    headerItems: filteredHeaderItems,
    userMenuSettings: initialUserMenuSettings,
  });

  const { favoritesRef, settingsRef, updateUserFavorites } = useFavoritesAggregated();
  const { currentTour } = useGuidedTourManager();
  const mostSimilarSelectedLink = useMemo(() => {
    const allLinks = getAllNavLinksFromBigidNavigation(navigationItems);
    return getMostSimilarSelectedLink(allLinks, selectedItemLink);
  }, [navigationItems, selectedItemLink]);

  useEffect(() => {
    const deregister = $transitions.onSuccess({}, transition => {
      const toState = transition.to().name;
      if (toState !== CONFIG.states.CUSTOM_APP && toState !== CONFIG.states.CUSTOM_APP_DEEP_LINKING) {
        if (isWorkspacesEnabled() && selectedWorkspaceId) {
          const lastVisitedPage = $state.current.name;
          updateWorkspaceSettingsByWorkspaceId({
            workspaceId: selectedWorkspaceId,
            lastVisitedPageSettings: { name: lastVisitedPage, params: $state.params },
          });
        }
      }
    });

    return () => {
      deregister();
    };
  }, [selectedWorkspaceId]);

  useEffect(() => {
    setNavigationItems(sortedNavItems);
  }, [sortedNavItems]);

  useEffect(() => {
    const fetchDynamicData = async () => {
      const { data: { favorites = [] } = {} } =
        (await userPreferencesService.get<ApplicationManagementPreferences>(APP_MANAGEMENT_USER_PREFERENCE_NAME)) || {};
      const { main, header } = await getSideNavigationPreferencesByWorkspace(sortedNavItems, selectedWorkspaceId);
      const favoriteMenuItems = getFavoriteApps(favorites, customApps, false);
      const { favoriteItems: favoritePages, favoritesOriginal } = await getFavoritePages(sortedNavItems || []);
      const favoritesUserOrder = header.find(({ id }) => id === FAVORITES_LINK)?.menuItems ?? [];
      const allFavoriteItems: Partial<BigidSidebarItemType | any>[] = [...favoriteMenuItems, ...favoritePages];

      favoritesRef.current = {
        apps: [...favorites],
        pages: favoritesOriginal,
      };
      settingsRef.current = {
        main,
        header,
      };
      const favItemsReOrdered = reorderFavoritesItemsByUserSettings(
        allFavoriteItems.map(
          ({ onClick, title, icon, selectedStates, selectedItemStates, link, order, workspaceId, name }) => {
            const onItemClicked =
              onClick || (() => onNavigationItemClicked(selectedStates?.[0] || selectedItemStates?.[0]));
            return {
              title,
              icon,
              onItemClicked,
              isVisible: true,
              link: selectedStates?.[0] || selectedItemStates?.[0] || link,
              selectedItemStates: selectedStates?.[0] || selectedItemStates,
              order,
              name,
              workspaceId,
            };
          },
        ),
        favoritesUserOrder,
      );

      dispatchMenuEditAction({
        type: NavigationEditActions.SET_FAVORITES,
        payload: favItemsReOrdered,
      });

      dispatchMenuEditAction({
        type: NavigationEditActions.SET_USER_SETTINGS,
        payload: main,
      });
    };

    fetchDynamicData();

    const destroyUpdateFavoritesListener = userPreferencesEventEmitter.addEventListener(
      UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES,
      fetchDynamicData,
    );

    const deregister = $transitions.onSuccess({}, transition => {
      const toState = transition.to().name;
      const { id, appRoute } = transition.params();
      if (toState === CUSTOM_APP_ACTION_PAGE) {
        setSelectedItemLink(`${CUSTOM_APP_PAGE}/${id}/actions`);
      } else if (toState === CUSTOM_APP_PAGE) {
        setSelectedItemLink(`${CUSTOM_APP_PAGE}/${id}`);
      } else if (toState === CONFIG.states.CUSTOM_APP_DEEP_LINKING) {
        if (appRoute) {
          setSelectedItemLink(`${CONFIG.states.CUSTOM_APP_DEEP_LINKING}/${id}/${appRoute}`);
        } else {
          setSelectedItemLink(`${CUSTOM_APP_PAGE}/${id}`);
        }
      } else {
        setSelectedItemLink(toState);
      }
    });

    return () => {
      deregister();
      destroyUpdateFavoritesListener();
    };
  }, [
    customApps,
    dispatchMenuEditAction,
    favoritesRef,
    sortedNavItems,
    onNavigationItemClicked,
    setSelectedItemLink,
    settingsRef,
    selectedWorkspaceId,
  ]);

  useEffect(() => {
    dispatchMenuEditAction({
      type: NavigationEditActions.SET_HEADER_ITEMS,
      payload: filteredHeaderItems.map((item: BigidSidebarItemType) =>
        item.link === FAVORITES_LINK
          ? { ...item, quickViewSchema: { ...item.quickViewSchema, menuItems: favorites } }
          : item,
      ),
    });
  }, [dispatchMenuEditAction, favorites, filteredHeaderItems]);
  useEffect(() => {
    const updateCustomApplications = (): void => {
      customAppService.getCustomApps().then(({ data }) => {
        const uniqueApps = getUniqueCustomApps(data);
        const appsWithCategory = uniqueApps.map((item: any) => ({ ...item, category: CategoriesEnum.UTILITIES }));
        setCustomApps(appsWithCategory);
      });
      privacyPortalService.getPrivacyPortalNavLinks().then(data => {
        setPrivacyPortalNavLinks(data);
      });
    };

    let customAppEventListener: EventEmitterDeregistrator = null;

    if (getApplicationPreference('SHOW_CUSTOM_APPS')) {
      updateCustomApplications();
      customAppEventListener = customAppEventsEmitter.addEventListener(
        CustomAppEvents.UPDATE_APP_LIST,
        updateCustomApplications,
      );
    }
    return () => {
      customAppEventListener?.();
    };
  }, []);

  useEffect(() => {
    const popularAppsItems: Partial<BigidSidebarItemType | any>[] = getPopularApps(customApps, true);
    dispatchMenuEditAction({
      type: NavigationEditActions.SET_FOOTER_ITEMS,
      payload: footerItemsPermitted.map((item: BigidSidebarItemType) =>
        item.link === CONFIG.states.APPLICATIONS_MANAGEMENT
          ? {
              ...item,
              quickViewSchema: {
                ...item.quickViewSchema,
                menuItems: popularAppsItems.map(({ onClick, title, icon, selectedStates, selectedItemStates }) => {
                  const onItemClicked = onClick || (() => onNavigationItemClicked(selectedStates?.[0]));
                  return {
                    title,
                    icon,
                    onItemClicked,
                    isVisible: true,
                    link: selectedStates?.[0] || selectedItemStates?.[0] || title,
                    selectedItemStates: selectedStates?.[0] || selectedItemStates?.[0] || [],
                  };
                }),
              },
            }
          : item,
      ),
    });
  }, [customApps, dispatchMenuEditAction, footerItemsPermitted, onNavigationItemClicked]);

  const TourComponent = useMemo(() => <NavigationGuidedTourLazy />, []);
  const WorkspaceTourComponent = useMemo(() => <WorkspacesGuidedTourLazy currentTour={currentTour} />, [currentTour]);

  return (
    <>
      <BigidNavigation
        initialStatus={SideBarStatus.SEMI_OPEN}
        navigationItems={navigationItems}
        footerItems={footerItems}
        selectedItemLink={mostSimilarSelectedLink}
        headerItems={headerItems}
        onSaveSettings={async settingsNew => {
          try {
            const favoritesItem = settingsNew.find(({ id }) => id === FAVORITES_LINK);
            if (favoritesItem) {
              const customAppsIds = customApps?.map(({ _id }) => _id);
              return await updateUserFavorites(settingsNew, customAppsIds);
            } else {
              await updateSideNavigationPreferencesByWorkspace(selectedWorkspaceId, {
                main: settingsNew,
                header: settingsRef.current.header,
              });
              settingsRef.current = {
                ...settingsRef.current,
                main: settingsNew,
              };

              userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);

              return true;
            }
          } catch (e) {
            notificationService.error('Failed to update user settings');
            return false;
          }
        }}
        defaultMenuSettings={defaultMenuSettings}
        dispatchMenuEditAction={dispatchMenuEditAction}
        hoveredItem={hoveredItem}
        saveSettingsRequired={saveSettingsRequired}
        linksWithoutRedirect={LINKS_WITHOUT_REDIRECT}
        dataBypass="bypass-sidebar"
      />
      {TourComponent}
      {WorkspaceTourComponent}
    </>
  );
};

angular.module('app').component('navigationSidebar', convertToAngular(NavigationSidebar));
