import React, { FC, useContext, useState } from 'react';
import {
  BigidBody1,
  BigidColorsV2,
  BigidDialog,
  BigidTooltip,
  convertCronToRecurringScheduleObject,
  convertRecurringScheduleObjectToCron,
  PrimaryButton,
  RecurringSchedule,
  TertiaryButton,
} from '@bigid-ui/components';
import { CreateScanWizardContext } from '../../createScanContext';
import { BigidAddIcon, BigidApplyIcon, BigidScheduleIcon } from '@bigid-ui/icons';
import { getDefaultScheduleRecurrence, SchedulerStep } from '../SchedulerStep/SchedulerStep';
import { styled } from '@mui/material';
import { useLocalTranslation } from '../../../translations';
import { isNil } from 'lodash';

const info = 'You must select at least one data source to create a template.';
const prefix = 'scan-create-wizard-create-template';

const RightSideComponent = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const EnableContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-right: 8px;
`;

const EnableText = styled(BigidBody1)`
  color: ${BigidColorsV2.green[600]};
`;

const CreateTemplateBox = styled('div')<{ isStepWithSchedulingButtons: boolean; isEnabled: boolean }>`
  border-left: ${props =>
    props.isStepWithSchedulingButtons && props.isEnabled ? `1px solid ${BigidColorsV2.gray[200]}` : 'none'};
  padding-left: 8px;
`;

export const CreateTemplateButton: FC<{
  isSelectTemplateStep: boolean;
  isStepWithSchedulingButtons: boolean;
  isNextDisabled: boolean;
}> = ({ isSelectTemplateStep, isStepWithSchedulingButtons, isNextDisabled }) => {
  const { t } = useLocalTranslation('createTemplateButton');
  const [isDialogScheduleOpen, setIsDialogScheduleOpen] = useState(false);
  const { scanWizardState, setScanWizardState, onChangeToCreateTemplate } = useContext(CreateScanWizardContext);
  const onCreateHandle = () => {
    setScanWizardState({ ...scanWizardState, isTemplateCreate: true });
    onChangeToCreateTemplate();
  };

  const initialScheduleState = scanWizardState.schedule
    ? convertCronToRecurringScheduleObject({
        ...scanWizardState.schedule,
        startOn: new Date(scanWizardState.schedule.startOn),
      })
    : getDefaultScheduleRecurrence();
  const [schedule, setSchedule] = useState<RecurringSchedule>(initialScheduleState);
  const [isEnabled, setIsEnabled] = useState(scanWizardState.enabled);

  const onCloseHandler = () => {
    setIsDialogScheduleOpen(false);
    setIsEnabled(scanWizardState.enabled);
    setSchedule(initialScheduleState);
  };

  const handleSave = () => {
    setIsDialogScheduleOpen(false);
    setScanWizardState({
      ...scanWizardState,
      schedule: convertRecurringScheduleObjectToCron(schedule),
      enabled: isEnabled,
    });
  };

  const openScheduleDialog = () => {
    if (isNil(scanWizardState.scanId)) {
      setIsEnabled(true);
    }

    setIsDialogScheduleOpen(true);
  };

  const disabled = !scanWizardState.allEnabledDs && scanWizardState?.dataSourcesIds?.length === 0;
  return (
    <RightSideComponent>
      {isStepWithSchedulingButtons && (
        <>
          <BigidTooltip title={isNextDisabled ? t('selectTemplate') : ''} placement="bottom">
            <span>
              <TertiaryButton
                size="medium"
                startIcon={<BigidScheduleIcon />}
                onClick={openScheduleDialog}
                dataAid="create-scan-wizard-schedule-scan-button"
                text={'Schedule Scan'}
                disabled={isNextDisabled}
              />
            </span>
          </BigidTooltip>
          {scanWizardState.enabled && (
            <EnableContainer>
              <BigidApplyIcon color={BigidColorsV2.green[600]} />
              <EnableText>Enabled</EnableText>
            </EnableContainer>
          )}
        </>
      )}
      {isSelectTemplateStep && (
        <CreateTemplateBox
          isStepWithSchedulingButtons={isStepWithSchedulingButtons}
          isEnabled={scanWizardState.enabled}
        >
          <BigidTooltip title={info} placement="bottom" isDisabled={!disabled} dataAid={`${prefix}-tooltip`}>
            <span>
              <TertiaryButton
                size={'medium'}
                onClick={onCreateHandle}
                dataAid={`${prefix}-button`}
                disabled={disabled}
                text="Create Scan Template"
                startIcon={<BigidAddIcon />}
              />
            </span>
          </BigidTooltip>
        </CreateTemplateBox>
      )}

      <BigidDialog
        isOpen={isDialogScheduleOpen}
        onClose={onCloseHandler}
        title="Schedule scan"
        buttons={[
          {
            component: TertiaryButton,
            onClick: onCloseHandler,
            text: 'Cancel',
            dataAid: 'cancel-scheduler-dialog',
          },
          {
            component: PrimaryButton,
            onClick: handleSave,
            text: 'Save',
            dataAid: 'save-scheduler-dialog',
          },
        ]}
      >
        <SchedulerStep
          isLoadingStep={false}
          enabled={isEnabled}
          schedule={schedule}
          setIsEnabled={setIsEnabled}
          setSchedule={setSchedule}
        />
      </BigidDialog>
    </RightSideComponent>
  );
};
