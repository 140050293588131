import { BigidAreaChart } from '@bigid-ui/visualisation';
import { BigidColorsV2, capitalizeFirstLetter } from '@bigid-ui/components';
import React, { FC } from 'react';

export const DataRiskAreaChart: FC = () => {
  const data = [
    {
      category: '28/04',
      discovered: 20000,
      remediated: 10000,
    },
    {
      category: '29/04',
      discovered: 25000,
      remediated: 16000,
    },
    {
      category: '30/04',
      discovered: 28000,
      remediated: 22000,
    },
    {
      category: '01/05',
      discovered: 35000,
      remediated: 30000,
    },
    {
      category: '02/05',
      discovered: 48000,
      remediated: 37000,
    },
    {
      category: '03/05',
      discovered: 59000,
      remediated: 39000,
    },
    {
      category: '04/05',
      discovered: 63000,
      remediated: 45000,
    },
    {
      category: '05/05',
      discovered: 77000,
      remediated: 48000,
    },
    {
      category: '06/05',
      discovered: 84000,
      remediated: 50100,
    },
    {
      category: '07/05',
      discovered: 88000,
      remediated: 52200,
    },
    {
      category: '08/05',
      discovered: 98000,
      remediated: 52200,
    },
  ];

  return (
    <BigidAreaChart
      legendProps={{ hideLegend: true }}
      colorDataMap={{ discovered: BigidColorsV2.purple[600], remediated: BigidColorsV2.magenta[200] }}
      isLineSmoothed={true}
      numberFormat="#.A"
      data={data}
      seriesLabelFormatter={series => capitalizeFirstLetter(series)}
    />
  );
};
