import { BigidBody1 } from '@bigid-ui/components';
import React from 'react';
import { FooterSectionRoot } from './FooterSection';
import { ExcessiveNumberChip } from './ExcessiveNumbersChip';
import { useLocalTranslation } from '../../../../translations';
import { useTheme } from '@mui/styles';

type FooterOwnersProps = {
  owners: string[];
};

export const FooterOwners = ({ owners = [] }: FooterOwnersProps) => {
  const { t } = useLocalTranslation('owners');
  const { vars } = useTheme();

  const [displayOwner, ...tooltipList] = owners;

  return (
    <FooterSectionRoot>
      <BigidBody1 color={vars.tokens.bigid.foregroundSecondary}>{t('name')}</BigidBody1>
      <BigidBody1
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        color={vars.tokens.bigid.foregroundTertiary}
      >
        {displayOwner || t('noOwnersDescription')}
      </BigidBody1>
      <ExcessiveNumberChip threshold={1} totalNumberOfItems={owners.length} tooltipList={tooltipList} />
    </FooterSectionRoot>
  );
};
