import React, { ElementType, MutableRefObject, useCallback, useRef } from 'react';
import { BigidColorsV2, BigidFieldRenderProps, BigidFormField, BigidFormStateAndHandlers } from '@bigid-ui/components';
import { Box } from '@mui/material';
import {
  useSetTemplateConfig as useSetTemplateConfigV1,
  UseSetTemplateConfigProps,
} from '../../DataSourceConfiguration/hooks/useSetTemplateConfig';
import {
  DataSourceConnectionTemplateField,
  DataSourceTemplateConditionObject,
  DataSourceTemplateFieldConditionalType,
  DsConnectionFieldSectionsEnum,
  DsTypesEnum,
} from '../../DataSourceConfiguration/types';
import { getFieldStyleOverridesForDataSourceType, getNonResizeAwareFields } from '../mappers/styles';
import {
  checkIsMandatoryForTest,
  markChildFieldsOverride,
  mapFieldNameToVisibilityDependencies,
} from '../../DataSourceConfiguration/utils/conditionUtils';
import { isFunction, uniq } from 'lodash';
import { uniqByWithOrder, sortByArray } from '../utils/utils';
import {
  REQUIRED_FIELDS_OVERRIDE_BY_SOURCE,
  HIDDEN_FIELDS_OVERRIDE,
  REQUIRED_FIELDS_OVERRIDE_BY_NAME,
  DISABLED_EDIT_FIELDS_OVERRIDE,
  COMMON_NON_RESIZABLE_FIELDS_OVERRIDE,
} from '../constants/constants';
import { getDataSourceFieldRenderOverrides, getDataSourceTypeSortOverrides } from '../config/connection';
import { DataSourceConnectionResizableField } from '../DataSourceConnectionResizableField';
import { mapHiddenFieldsToDetailsSection } from '../../DataSourceConfiguration/mappers/overrides';
import styled from '@emotion/styled';
import { getFieldPropsFunc } from '../../DataSourceConfiguration/utils/fieldUtils';

type UpdateStateProps = { fields: BigidFormField[] };
type BigidFormStateAndHandlersPartialProps = {
  getFieldProps: MutableRefObject<BigidFormStateAndHandlers['getFieldProps']>;
};

const Field = DataSourceConnectionResizableField;

export const VerticalObjectField = styled(Box)`
  & fieldset > div {
    flex-wrap: wrap;
    row-gap: 8px;
  }
  & fieldset > div > label {
    padding-left: 0px;
  }
  .FormObjectFieldFirstItem {
    margin-left: 0px;
  }
  .FormObjectFieldFirstItem > fieldset {
    margin: 0px;
  }
  .FormVerticalObjectContainerdOverride {
    row-gap: 20px;
    margin: 10px 0px;
  }
  .FormVerticalObjectContainerdOverride * fieldset {
    margin: 0px;
  }

  & .FormCertificateField > div {
    width: 100%;
  }
`;

export const ChildFieldStyle = styled(VerticalObjectField)`
  background-color: ${({ theme }) => theme.vars.palette.bigid.gray150};
  padding: 12px 20px 12px 20px;
  border-radius: 4px;
`;

export const CustomParametersField = VerticalObjectField;

const BooleanField = styled.div`
  flex-direction: row !important;
`;

const MultiStringBoolean = styled.div`
  & fieldset > div {
    margin-left: 10px;
  }

  & fieldset > div > label > .MuiFormControlLabel-label {
    display: none;
  }
`;

const MANDATORY_SECTION = DsConnectionFieldSectionsEnum.connection;
const ADVANCED_SECTION = DsConnectionFieldSectionsEnum.connectionAdvanced;
const EMPTY_FIELD_ARRAY: string[] = [];

// @info Add style updates for template fields here until unified
const styleOverrideComponentMapper: Partial<Record<DsTypesEnum, React.ElementType>> = {
  [DsTypesEnum.verticalObject]: VerticalObjectField,
  [DsTypesEnum.boolean]: BooleanField,
  [DsTypesEnum.multiStringBoolean]: MultiStringBoolean,
};

const getSortOrderByParentChild = (fields: BigidFormField[]): string[] => {
  const visbileByMap = mapFieldNameToVisibilityDependencies(fields);
  return (fields || []).reduce((acc, field) => {
    if (field?.misc?.isChildField === true || field.misc?.hidden) {
      return [...acc];
    } else {
      const visibeByFields = visbileByMap[field.name] ?? [];
      return [...acc, field.name, ...visibeByFields];
    }
  }, []);
};

const mapDataSourceTypeToStyleOverrideComponent = (type: DsTypesEnum, isChildField: boolean): React.ElementType =>
  isChildField ? ChildFieldStyle : styleOverrideComponentMapper[type] ?? Field;

const setFieldSection = (section: string) => (field: BigidFormField) => ({
  ...field,
  misc: { ...field?.misc, section },
});

const uniqBySection = (array: BigidFormField[]) =>
  uniqByWithOrder(
    array,
    ({ name }) => name,
    ({ misc }) => misc.section,
  );

const showRequiredFieldsOnTopSectionOverride =
  (fieldConfig: BigidFormField[], getFieldProps: MutableRefObject<BigidFormStateAndHandlers['getFieldProps']>) =>
  (acc: BigidFormField[], field: BigidFormField): BigidFormField[] => {
    const isMandatory =
      checkIsMandatoryForTest({ misc: field.misc, getFieldProps: getFieldProps.current }) ||
      REQUIRED_FIELDS_OVERRIDE_BY_NAME.includes(field.name) ||
      REQUIRED_FIELDS_OVERRIDE_BY_SOURCE.includes(field?.misc?.source);
    const isSectionChangeSupported = [MANDATORY_SECTION, ADVANCED_SECTION].includes(field.misc?.section);

    const markFieldAsMandatory = setFieldSection(MANDATORY_SECTION);
    const markFieldAsOptional = setFieldSection(ADVANCED_SECTION);

    const connectedFields = isMandatory
      ? (field?.misc?.visibleIf as DataSourceTemplateFieldConditionalType)
          ?.map(condition => condition.field)
          .filter(Boolean) ?? []
      : EMPTY_FIELD_ARRAY;

    const connectedObjectFields = isMandatory
      ? (field?.misc?.visibleIfObject as DataSourceTemplateConditionObject)?.fields
          ?.map(condition => condition.field)
          .filter(Boolean) ?? []
      : EMPTY_FIELD_ARRAY;

    // @info additional fields controlling the visibility of required fields via `visibleIf` and `visibleIfObject` conditions
    const additionalFields = uniq([...connectedFields, ...connectedObjectFields]).map(fieldName =>
      fieldConfig.find(({ name }) => fieldName === name),
    );

    const result = uniqBySection([
      ...acc,
      ...additionalFields.map(markFieldAsMandatory),
      {
        ...(isMandatory ? markFieldAsMandatory(field) : isSectionChangeSupported ? markFieldAsOptional(field) : field),
      },
    ]);

    return result;
  };

const hideNestedTemplateFieldsOverride =
  (getFieldProps: MutableRefObject<BigidFormStateAndHandlers['getFieldProps']>) =>
  (templateField: DataSourceConnectionTemplateField): DataSourceConnectionTemplateField => {
    const isVerticalObjectField = templateField?.type === DsTypesEnum.verticalObject;

    if (!isVerticalObjectField) {
      return templateField;
    }

    const hiddenObjectFields =
      templateField?.objectFields?.filter(({ name, type, ...rest }) => {
        const isMandatory = checkIsMandatoryForTest({
          misc: { name, ...rest },
          getFieldProps: getFieldPropsFunc(
            type,
            getFieldProps?.current(templateField.name)?.value,
            getFieldProps.current,
          ),
        });
        return HIDDEN_FIELDS_OVERRIDE.includes(name) && !isMandatory;
      }) ?? [];
    const hasHiddenObjectFieldsOverrides = !!hiddenObjectFields.length;
    const hiddenObjectFieldsNames = hiddenObjectFields.map(({ name }) => name);

    const objectFieldOverrides: DataSourceConnectionTemplateField[] = hasHiddenObjectFieldsOverrides
      ? [
          ...templateField?.objectFields.filter(({ name }) => !hiddenObjectFieldsNames.includes(name)),
          ...hiddenObjectFields.map(field => ({ ...field, hidden: true })),
        ]
      : templateField?.objectFields;
    const fieldOverride: DataSourceConnectionTemplateField = { ...templateField, objectFields: objectFieldOverrides };

    return fieldOverride;
  };

const hideFieldsOverride =
  (getFieldProps: MutableRefObject<BigidFormStateAndHandlers['getFieldProps']>, isDisabled: boolean) =>
  (field: BigidFormField): BigidFormField => {
    const isMandatory = checkIsMandatoryForTest({ misc: field.misc, getFieldProps: getFieldProps.current });

    const isHidden = isDisabled ? false : HIDDEN_FIELDS_OVERRIDE.includes(field.name) && !isMandatory;
    return { ...field, misc: { ...field.misc, hidden: isHidden ? isHidden : field?.misc?.hidden } };
  };

const disableFieldsOverride =
  (isStandalone: boolean) =>
  (field: BigidFormField): BigidFormField => {
    const isDisabled = DISABLED_EDIT_FIELDS_OVERRIDE.includes(field.name) && isStandalone;

    return {
      ...field,
      disabled: isDisabled,
      render: (props: BigidFieldRenderProps) => field.render({ ...props, disabled: isDisabled }),
    };
  };

const hideFieldSeparatorOverride = (field: BigidFormField): BigidFormField => ({
  ...field,
  misc: { ...field.misc, isSeparatorAfter: false },
});

export const useSetTemplateConfig = (
  { updateState, ...rest }: UseSetTemplateConfigProps,
  state: BigidFormStateAndHandlersPartialProps,
) => {
  const { selectedType: sourceType, isEditable: isEditPage } = rest;
  const getFieldPropsFunctionOverride = useRef<BigidFormStateAndHandlers['getFieldProps']>((fieldName: string) =>
    state?.getFieldProps?.current?.(fieldName),
  );
  const fieldRenderOverrides = getDataSourceFieldRenderOverrides();

  const fieldComponentStyleOverrides = getFieldStyleOverridesForDataSourceType(rest.selectedType);

  const updateStateOverride = useCallback(
    ({ fields, ...props }: UpdateStateProps) => {
      const fieldsOverride = markChildFieldsOverride(fields);
      const fieldSortOrderByType = getDataSourceTypeSortOverrides(rest.selectedType);
      const nonResizeAwareFields = getNonResizeAwareFields();
      const defaultSortOrder = uniq([
        ...(fieldSortOrderByType ?? []),
        ...getSortOrderByParentChild(fieldsOverride),
        ...(fields?.map(({ name }) => name) ?? []),
      ]);

      if (fields && !state?.getFieldProps?.current) {
        getFieldPropsFunctionOverride.current = (fieldName: string) => {
          const field = fields.find(({ name }) => name === fieldName);
          return {
            value: field?.misc?.defaultValue,
            ...field,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as BigidFieldRenderProps<any, any>;
        };
      }

      if (!fields) {
        updateState({ ...props });
        return;
      }

      updateState({
        ...props,
        fields: fieldsOverride
          ?.map(({ render: Component, misc, ...rest }) => {
            const isChildField = misc?.isChildField === true;

            const StyleOverrideComponent = mapDataSourceTypeToStyleOverrideComponent(
              misc?.type as DsTypesEnum,
              isChildField,
            );

            const isNonResizableField = [
              ...COMMON_NON_RESIZABLE_FIELDS_OVERRIDE,
              ...(nonResizeAwareFields?.[sourceType] ?? []),
            ]?.includes(rest.name);
            const fieldComponentOverrideGetter = fieldComponentStyleOverrides?.[rest.name];

            const FieldStyleOverrideComponent = isFunction(fieldComponentOverrideGetter)
              ? fieldComponentOverrideGetter(StyleOverrideComponent)
              : fieldComponentStyleOverrides[rest.name];

            // @info workaround for VerticalObject fields without any content
            const isResizeAwareField = [DsTypesEnum.verticalObject].includes(misc?.type) && !isNonResizableField;

            const StyleComponent = FieldStyleOverrideComponent ?? StyleOverrideComponent;
            const Wrapper = (props: BigidFieldRenderProps) => (
              <Field type={misc?.type} disableSizeCheck={!isResizeAwareField} as={StyleComponent as ElementType}>
                <Component {...props} />
              </Field>
            );

            return { ...rest, misc, render: Wrapper, tooltipText: undefined };
          })
          .filter(({ misc }) => !misc?.nested)
          // @info add field overrides
          .map(hideFieldsOverride(getFieldPropsFunctionOverride, isEditPage))
          .map(mapHiddenFieldsToDetailsSection)
          .map(hideFieldSeparatorOverride),
        fieldConfig: fields,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateState, rest?.selectedType],
  );

  const fieldConfigOverrides = (fieldConfig: DataSourceConnectionTemplateField[]) =>
    fieldConfig.map(hideNestedTemplateFieldsOverride(getFieldPropsFunctionOverride));

  useSetTemplateConfigV1({
    ...rest,
    updateState: updateStateOverride,
    isBulkUpdate: true,
    includeNestedFields: true,
    overrides: { fieldConfig: fieldConfigOverrides, mapping: fieldRenderOverrides },
  });
};
