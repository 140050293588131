import React, { FC, useEffect } from 'react';
import { BigidBody1, BigidHeading3, PrimaryButton, TertiaryButton } from '@bigid-ui/components';
import { BigidReportsIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import styled from '@emotion/styled';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { closeSystemDialog } from '../../../services/systemDialogService';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { GO_TO_SECURITY_DASHBOARD_MODAL_SHOWN } from './goToSecurityDashboardModalService';

const GoToSecurityDashboardContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px 16px 16px;
`;

const ContentItemWrapper = styled('div')`
  display: flex;
`;

const HeaderItemWrapper = styled('div')`
  padding: 16px 0;
`;

const ButtonItemWrapper = styled('div')`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
`;

export const GoToSecurityDashboardModalContent: FC = () => {
  const { t } = useLocalTranslation('security.modals.goToSecurityDashboardModal');

  const onViewDashboardClick = () => {
    closeSystemDialog();
    $state.go(CONFIG.states.DASHBOARD_SECURITY);
  };

  useEffect(() => {
    const setModalShownUserPreference = async () => {
      userPreferencesService.update({
        preference: GO_TO_SECURITY_DASHBOARD_MODAL_SHOWN,
        data: true,
      });
    };

    setModalShownUserPreference();
  }, []);

  return (
    <GoToSecurityDashboardContainer>
      <ContentItemWrapper>
        <BigidReportsIllustration />
      </ContentItemWrapper>
      <HeaderItemWrapper>
        <BigidHeading3>{t('title')}</BigidHeading3>
      </HeaderItemWrapper>
      <ContentItemWrapper>
        <BigidBody1 textAlign="center">{t('body')}</BigidBody1>
      </ContentItemWrapper>
      <ButtonItemWrapper>
        <PrimaryButton
          text={t('buttonLabel')}
          onClick={onViewDashboardClick}
          size="medium"
          dataAid="view-security-dashboard-modal-button"
        />
        <TertiaryButton
          onClick={closeSystemDialog}
          size="medium"
          text={t('close')}
          dataAid="close-security-dashboard-modal-button"
        />
      </ButtonItemWrapper>
    </GoToSecurityDashboardContainer>
  );
};
