import React from 'react';
import { DetailsTab, PermissionsTab } from '.';
import { ActivityMonitoringDetailsTab } from '../../../../ActivityMonitoring/ActivityMonitoringDetailsTab';

export const TabContent = ({ tab, caseDetails }: any) => {
  switch (true) {
    case tab === 0:
      return <DetailsTab caseDetails={caseDetails} />;
    case tab === 1:
      return <PermissionsTab />;
    case tab === 2:
      return <ActivityMonitoringDetailsTab />;
    default:
      return null;
  }
};
