import { noop } from 'lodash';
import { generateDataAid } from '@bigid-ui/utils';
import { closeSystemDialog, openSystemDialog } from '../../../../../../services/systemDialogService';
import {
  CuratedFieldValuePreviewProps,
  CuratedFieldValuePreview,
} from '../../../CuratedFieldValuePreview/CuratedFieldValuePreview';
import { httpService } from '../../../../../../services/httpService';
import { getApplicationPreference } from '../../../../../../services/appPreferencesService';
import { FindingCurateProps } from '../../../../curationService';

export type OpenFieldValuePreviewDialogProps = Omit<CuratedFieldValuePreviewProps, 'dataAid'> & { fieldName: string };

export async function openFieldValuePreviewDialog({
  fieldName,
  valueChunks,
  onFindingCurate: handleFindingCurate,
  isPermittedToExclude,
  fieldId,
}: OpenFieldValuePreviewDialogProps) {
  openSystemDialog({
    borderTop: true,
    borderBottom: true,
    withCloseButton: true,
    title: `Curate findings for '${fieldName}' field`,
    onClose: noop,
    content: CuratedFieldValuePreview,
    contentProps: {
      dataAid: generateDataAid('CuratedFieldValuePreview', ['dialog', fieldName]),
      valueChunks,
      onFindingCurate: (findingCurateProps: FindingCurateProps) => {
        handleFindingCurate(findingCurateProps);
        closeSystemDialog();
      },
      isPermittedToExclude,
      fieldId,
    },
    maxWidth: 'lg',
  });
}

export const clearFromCacheForStructured = (fullyQualifiedName: string) => {
  return httpService
    .delete(`data-catalog/scan-result-fetch-findings/preview`, { fullyQualifiedName })
    .then(({ data }) => data);
};
