import { useEffect, useState, useMemo } from 'react';
import { objectToQueryString, QueryParams, BigidAdvancedToolbarFilterTypes } from '@bigid-ui/components';
import {
  AggregationFilterOperand,
  AggregationType,
  SourcesExtendedAggregationItem,
} from '../../../catalogDiscoveryTypes';
import {
  getAggregatedData,
  GetAggregatedDataPayload,
  GetAggregatedDataResponse,
} from '../../../catalogDiscoveryService';
import { useFetchDataCancelable } from '../../../config/useFetchDataCancelable';
import { notificationService } from '../../../../../services/notificationService';
import { useLocalTranslation } from '../translations';
import { getObjectsPreview, GetObjectsPreviewResponse } from '../dataSourcesLayoutService';
import { getFilterByOperand, parseFilterOutputToQueryString } from '../../../filter/utils';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';
import { CatalogDiscoveryWidget } from '../../../config/widgets';
import { DataSourceSidePanelProps } from './DataSourceSidePanel';
import { useFetch } from '@bigid-ui/grid';
import { getSourceCategoriesExtendedWithArbitraryColors } from '../../../utils/common';

const objectPreviewLimit = 20;

enum DataSourceSidePanelSection {
  DATA_SOURCE_ASSETS = 'DATA_SOURCE_ASSETS',
  TOTAL_OBJECTS_COUNT = 'TOTAL_OBJECTS_COUNT',
  SENSITIVITY_PIE_CHART = 'SENSITIVITY_PIE_CHART',
  ATTRIBUTES_LAYOUT = 'ATTRIBUTES_LAYOUT',
  OBJECT_PREVIEW = 'OBJECT_PREVIEW',
}

export function useDataSourceSidePanelContent({ dataSourceName, filter }: Omit<DataSourceSidePanelProps, 'dataAid'>) {
  const { fetchDataSourceExtendedCancelable, fetchObjectsPreviewCancelable, fetchObjectStatusCancelable } =
    useFetchDataCancelable();
  const { t } = useLocalTranslation('sidePanel');
  const isSensitivityWidgetAvailable = getApplicationPreference('SENSITIVITY_CLASSIFICATION_ENABLED');
  const [dataSource, setDataSource] = useState<SourcesExtendedAggregationItem>();
  const [totalObjects, setTotalObjects] = useState<number>();
  const [sectionsDataLoadingStatus, setSectionsDataLoadingStatus] = useState<
    Record<DataSourceSidePanelSection, boolean>
  >({
    [DataSourceSidePanelSection.DATA_SOURCE_ASSETS]: false,
    [DataSourceSidePanelSection.SENSITIVITY_PIE_CHART]: !isSensitivityWidgetAvailable,
    [DataSourceSidePanelSection.TOTAL_OBJECTS_COUNT]: false,
    [DataSourceSidePanelSection.ATTRIBUTES_LAYOUT]: false,
    [DataSourceSidePanelSection.OBJECT_PREVIEW]: false,
  });
  const [reloadObjectPreview, setReloadObjectPreview] = useState([]);

  const { fullQueryComputed, dataSourceQuery } = useMemo(() => {
    const dataSourceQuery = dataSourceName
      ? `${AggregationFilterOperand.DATA_SOURCE_NAME} = "${dataSourceName}"`
      : undefined;
    const hasPageDataSourceFilter = Boolean(getFilterByOperand(filter, AggregationFilterOperand.DATA_SOURCE_NAME));
    const query =
      filter?.length > 0
        ? parseFilterOutputToQueryString(
            filter.map(filterEntity => {
              if (
                filterEntity.field === AggregationFilterOperand.DATA_SOURCE_NAME &&
                filterEntity.type === BigidAdvancedToolbarFilterTypes.DROPDOWN
              ) {
                return {
                  ...filterEntity,
                  options: filterEntity.options.filter(option => option.id === dataSourceName),
                };
              }

              return filterEntity;
            }),
          )
        : undefined;
    const fullQueryComputed = hasPageDataSourceFilter
      ? query
      : [dataSourceQuery, query].filter(query => query).join(' AND ');

    return {
      fullQueryComputed: fullQueryComputed || null,
      dataSourceQuery: dataSourceQuery || null,
    };
  }, [dataSourceName, filter]);

  const useFetchState = useFetch({
    externalFilter: reloadObjectPreview,
    fetchDataFunction: async queryComponents => {
      try {
        const query = objectToQueryString({
          ...(queryComponents as QueryParams),
          requireTotalCount: false,
          limit: objectPreviewLimit,
          filter: fullQueryComputed,
        });

        const { results } = await fetchObjectsPreviewCancelable(
          getObjectsPreview(query) as Promise<GetObjectsPreviewResponse>,
        );

        return {
          totalCount: results.length,
          data: results,
        };
      } catch ({ message, isCanceled }) {
        if (!isCanceled) {
          console.error(`An error has occurred: ${message}`);
        }

        return {
          totalCount: 0,
          data: [],
        };
      }
    },
  });

  useEffect(() => {
    if (dataSourceName) {
      async function fetchDataSource() {
        try {
          const payload: GetAggregatedDataPayload = {
            filter: dataSourceQuery,
            aggregations: [
              {
                aggName: AggregationType.DATA_SOURCE_EXTENDED,
              },
            ],
          };

          const { aggregations = [] } = await fetchDataSourceExtendedCancelable(
            getAggregatedData(payload) as Promise<GetAggregatedDataResponse<SourcesExtendedAggregationItem>>,
          );

          setDataSource({
            ...aggregations[0]?.aggData?.[0],
            categories: getSourceCategoriesExtendedWithArbitraryColors(aggregations[0]?.aggData?.[0]?.categoryNames),
          });
        } catch ({ message, isCanceled }) {
          if (!isCanceled) {
            console.error(`An error has occurred: ${message}`);
            notificationService.error(t('errors.generic'));
          }

          setDataSource(undefined);
        } finally {
          setSectionsDataLoadingStatus(currentStatus => {
            return {
              ...currentStatus,
              [DataSourceSidePanelSection.DATA_SOURCE_ASSETS]: true,
            };
          });
        }
      }

      async function fetchTotalObjects() {
        try {
          const payload: GetAggregatedDataPayload = {
            filter: fullQueryComputed,
            aggregations: [
              {
                aggName: AggregationType.OBJECT_STATUS,
                isTotalRequired: true,
              },
            ],
          };

          const { aggregations = [] } = await fetchObjectStatusCancelable(
            getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
          );

          setTotalObjects(aggregations[0]?.aggTotal);
        } catch ({ message, isCanceled }) {
          if (!isCanceled) {
            console.error(`An error has occurred: ${message}`);
            notificationService.error(t('errors.generic'));
          }

          setDataSource(undefined);
        } finally {
          setSectionsDataLoadingStatus(currentStatus => {
            return {
              ...currentStatus,
              [DataSourceSidePanelSection.TOTAL_OBJECTS_COUNT]: true,
            };
          });
        }
      }

      fetchDataSource();
      fetchTotalObjects();
    }
  }, [
    fetchDataSourceExtendedCancelable,
    dataSourceName,
    t,
    dataSourceQuery,
    fetchObjectStatusCancelable,
    fullQueryComputed,
  ]);

  const handleWidgetDataLoadingStatusChanged = (widget: CatalogDiscoveryWidget, isBusy: boolean) => {
    if (!isBusy) {
      setSectionsDataLoadingStatus(currentStatus => {
        switch (widget) {
          case CatalogDiscoveryWidget.SENSITIVITY_PIE_CHART:
            return {
              ...currentStatus,
              [DataSourceSidePanelSection.SENSITIVITY_PIE_CHART]: true,
            };
          case CatalogDiscoveryWidget.ATTRIBUTES_LAYOUT:
            return {
              ...currentStatus,
              [DataSourceSidePanelSection.ATTRIBUTES_LAYOUT]: true,
            };
        }
      });
    }
  };

  useEffect(() => {
    if (dataSourceName) {
      setReloadObjectPreview([]);
    }
  }, [dataSourceName]);

  useEffect(() => {
    setSectionsDataLoadingStatus(currentStatus => {
      return {
        ...currentStatus,
        [DataSourceSidePanelSection.OBJECT_PREVIEW]: useFetchState.isInitiallyFetched,
      };
    });
  }, [useFetchState.isInitiallyFetched]);

  return {
    useFetchState,
    dataSource,
    handleWidgetDataLoadingStatusChanged,
    fullQueryComputed,
    isSensitivityWidgetAvailable,
    sectionsDataLoadingStatus,
    totalObjects,
  };
}
