import {
  ActionData,
  BigidBody1,
  BigidFormStateAndHandlers,
  BigidFormValues,
  BigidNotes1,
  EntityEvents,
  entityEventsEmitter,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { deleteDataSourceClassificationFindings, updateDataSourceFields } from '../DataSourcesService';
import { notificationService } from '../../../services/notificationService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { httpService } from '../../../services/httpService';
import { closeSystemDialog, openSystemDialog } from '../../../services/systemDialogService';
import { DataSourceBulkUpdate } from '../DataSourceConfiguration/DataSourceBulkUpdate/DataSourceBulkUpdate';
import { updateScopeIfNeeded } from '../DataSourceConfiguration/hooks/useSubmitDataSource';
import { prepareDataSourceFormValueDataForSend } from '../DataSourceConfiguration/utils/prepareDataSourceFormValueDataForSend';
import { getValuesToSubmit } from '../DataSourceConfiguration/DataSourceBulkUpdate/dsBulkUpdateUtils';
import { $state, dataSourceConnectionsService, scopesService } from '../../../services/angularServices';
import { DELETE_DESCRIPTION_FINDINGS } from './DataSourceConnectionTypes';
import { showDeleteACEntityDialog } from '../../ActionCenter/DeleteEntityDialog/DeleteEntityDialogContent';
import { EntityType } from '../../ActionCenter/ActionWorkflow/actionWorkflowTypes';
import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  sendSSERequestWithEventId,
  SSEDataMessage,
  subscribeToRepeatedSSEEventById,
} from '../../../services/sseService';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../DataSourcesEventTrackerUtils';
import { smallIdLicenseService } from '../../../services/smallIdLicenseService';
import { openImportDataSourceFormModal } from '../../../components/ImportDataSourcesForm/openImportDataSourceFormModal';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { AxiosError } from 'axios';
import { setSelectedDataSourcesToStorage } from '../../Scans/ScanCreateWizard/hooks/useGetPreselectedDataSources';
import { openScanWarningDialog } from './DataSourceScanSelectedButton';
import { openDialogForLargeScanSet } from './LargeAmountOfDataSourcesModal';
import { CONFIG } from '../../../../config/common';

const MAX_DS_IN_SELECT_ALL = 100;
export async function updateDataSources(actionData: ActionData, updatedFields: any) {
  let didUpdate = false;
  const { selectedRowIds, allSelected, filter } = actionData;

  try {
    await updateDataSourceFields({
      ids: selectedRowIds,
      allSelected,
      query: { filter },
      updatedFields,
    });
    didUpdate = true;
    notificationService.success('Data sources updated successfully');
  } catch (e) {
    notificationService.error('Could not update data sources. See logs for more information');
  }

  return {
    shouldGridReload: didUpdate,
    shouldClearSelection: didUpdate,
  };
}

export async function updateArchiveStatusDataSources(actionData: ActionData, updatedFields: any, label: string) {
  let didUpdate = false;
  let shouldChangeArchiveStatus = false;
  const { selectedRowIds, allSelected, filter } = actionData;
  try {
    const isUnarchived = label.toLowerCase() === 'unarchive';

    shouldChangeArchiveStatus = await showConfirmationDialog({
      entitiesCount: selectedRowIds.length,
      entityNameSingular: 'Data Source',
      entityNamePlural: 'Data Sources',
      actionName: label,
      allSelected,
      postfix: isUnarchived ? ' from the archive?' : '? (Previous scan results will not be deleted)',
    });

    if (shouldChangeArchiveStatus) {
      await httpService.put(`ds-connections`, {
        ids: selectedRowIds,
        allSelected,
        query: { filter },
        updatedFields,
      });
      didUpdate = true;
      notificationService.success('Data sources updated successfully');
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    }
  } catch (e) {
    notificationService.error('Could not update data sources. See logs for more information');
  }

  return {
    shouldGridReload: didUpdate,
    shouldClearSelection: didUpdate,
  };
}

export const deleteFindings = async (actionData: ActionData) => {
  let shouldDelete = false;
  const { selectedRowIds } = actionData;
  const [selectedRow] = selectedRowIds;
  try {
    shouldDelete = await showConfirmationDialog({
      allSelected: true,
      entityNameSingular: `${selectedRow} Findings`,
    });

    if (shouldDelete) {
      await dataSourceConnectionsService.deletePiiDataForDataSource(selectedRow);
      notificationService.success('Data sources findings were deleted successfully');
    }
  } catch (error) {
    const defaultMessage = 'Could not delete data source findings. See logs for more information';
    notificationService.error(error?.data?.message ?? defaultMessage);
    console.error(error);
  }

  return {
    shouldGridReload: shouldDelete,
    shouldClearSelection: shouldDelete,
  };
};

export const deleteClassificationFindings = async (actionData: ActionData) => {
  let shouldDelete = false;
  const { selectedRowIds } = actionData;
  const [dataSourceId] = selectedRowIds;
  try {
    shouldDelete = await showConfirmationDialog({
      allSelected: true,
      entityNameSingular: `${dataSourceId} Classification Findings`,
    });

    if (shouldDelete) {
      await deleteDataSourceClassificationFindings(dataSourceId as string);
      notificationService.success(`Started classification findings deletion job for data source: ${dataSourceId}`);
    }
  } catch (error) {
    const defaultMessage = 'Could not delete data source classification findings. See logs for more information';
    notificationService.error(error?.data?.message ?? defaultMessage);
    console.error(error);
  }

  return {
    shouldGridReload: shouldDelete,
    shouldClearSelection: shouldDelete,
  };
};

export const modify = async (
  { selectedRows, selectedRowIds, allSelected, filter, totalRows }: ActionData,
  bulkDataSourceUpdateHandlersRef: React.MutableRefObject<BigidFormStateAndHandlers>,
  bulkDataSourceFieldsToModifyRef: React.MutableRefObject<any>,
) => {
  const dsType = selectedRows[0].type;
  const isSingleDsType = selectedRows.every(({ type }) => dsType === type);
  await openSystemDialog({
    title: 'Modify data sources',
    content: DataSourceBulkUpdate,
    contentProps: {
      isSingleDataSourceType: isSingleDsType,
      selectedType: dsType,
      selectedRowIds: selectedRowIds as string[],
      bulkDataSourceUpdateHandlersRef,
      bulkDataSourceFieldsToModifyRef,
      totalRows,
      allSelected,
    },
    buttons: [
      { component: SecondaryButton, text: 'Cancel', onClick: () => null, isClose: true },
      {
        component: PrimaryButton,
        text: 'save',
        onClick: async () => {
          await bulkDataSourceUpdateHandlersRef.current.validateAndSubmit(
            async (values: BigidFormValues) => {
              const fieldsToSubmit = [...bulkDataSourceFieldsToModifyRef.current].filter(({ name, misc }) => {
                if (bulkDataSourceUpdateHandlersRef.current.disabledFields[name]) {
                  return false;
                }

                return (
                  !misc?.visibleIf?.[0]?.field ||
                  !bulkDataSourceUpdateHandlersRef.current.disabledFields[misc?.visibleIf?.[0]?.field]
                );
              });

              const normalizedFields = prepareDataSourceFormValueDataForSend({
                fields: bulkDataSourceFieldsToModifyRef.current,
                values: bulkDataSourceUpdateHandlersRef.current.getValues(),
                getFieldPropsFunction: bulkDataSourceUpdateHandlersRef.current.getFieldProps,
                isNewPassword: true,
                isBulkUpdate: true,
              });

              const updatedFields = getValuesToSubmit(normalizedFields, fieldsToSubmit);

              if (values.scope) {
                await scopesService.bulkUpdateDataSourcesInScopes({
                  isReplace: updatedFields.isReplaceOwners,
                  dataSourceNames: selectedRowIds,
                  scopeIds: values.scope.map((option: Record<string, string>) => option?.value),
                  isAllSelected: allSelected,
                  dataSourceFilter: filter,
                });
              }

              try {
                const { data } = await updateDataSourceFields({
                  ids: selectedRowIds,
                  allSelected,
                  query: { filter },
                  updatedFields,
                  isMultiFieldUpdate: true,
                });

                notificationService.success(`Updated ${data?.data?.updatedRows || ''} data source(s) successfully!`);
              } catch (e) {
                notificationService.error('Could not update data sources. See logs for more information');
              } finally {
                closeSystemDialog();
              }
            },
            () => {
              notificationService.error('Form validation failed, please check form.');
            },
          );
        },
      },
    ],
    isContentScrollable: true,
    onClose: () => null,
  });
  return { shouldClearSelection: true };
};

export const deleteDataSources = async (actionData: ActionData, dialogContent: string) => {
  const { selectedRowIds, totalRows, filter, selectedRows } = actionData;
  const ignoreAllSelected = selectedRowIds?.length > 0 && selectedRowIds?.length < MAX_DS_IN_SELECT_ALL;
  const allSelected = ignoreAllSelected ? false : actionData.allSelected;
  let shouldDelete = false;
  try {
    const ContentComponent = (
      <div className={dialogContent}>
        <BigidBody1>{`Are you sure you want to delete ${selectedRowIds.length} data source${
          selectedRowIds.length > 1 ? 's' : ''
        }${DELETE_DESCRIPTION_FINDINGS}? `}</BigidBody1>
        <BigidNotes1>{`NOTE: Deleting data sources with large quantity of PII may take a while.`}</BigidNotes1>
      </div>
    );

    shouldDelete = await showDeleteACEntityDialog({
      ids: allSelected ? [] : (selectedRows.map(({ _id }) => _id) as string[]),
      entityType: EntityType.DATA_SOURCE,
      entityNameSingular: 'data source',
      entityNamePlural: 'data sources',
      totalRows,
      ContentComponent,
      allSelected,
    });

    if (shouldDelete) {
      await httpService.delete(`ds-connections`, {
        ids: selectedRowIds,
        allSelected,
        query: { filter },
      });
      notificationService.success('Data sources deleted successfully');
    }
  } catch (e) {
    handleDeleteError(e);
  }

  return {
    shouldGridReload: shouldDelete,
    shouldClearSelection: shouldDelete,
  };
};

function handleDeleteError(error: AxiosError<{ message?: string }>) {
  const isBlockedByScan = error.response.data?.message?.includes("The following data sources can't be deleted: ");
  notificationService.error(
    isBlockedByScan
      ? `Scan not completed and ${error.response.data?.message}`
      : 'Could not delete data sources. See logs for more information',
  );
  console.error(error);
}

export const duplicate = async ({ selectedRowIds }: ActionData) => {
  let didDuplicate = false;
  try {
    await httpService.post('ds-connections/duplicate', {
      ids: selectedRowIds,
    });
    didDuplicate = true;
    notificationService.success('Data source was duplicated successfully');
  } catch (e) {
    console.error(e);
    notificationService.error('Could not duplicate data source. See logs for more information');
  }

  return {
    shouldGridReload: didDuplicate,
    shouldClearSelection: didDuplicate,
  };
};

export const exportDataSources = async ({ allSelected, selectedRowIds, filter }: ActionData) => {
  try {
    const queryParams = {
      ...(filter ? { filter } : {}),
      ...(allSelected ? {} : { ids: selectedRowIds.toString() }),
    };

    await httpService.downloadFilePost('ds-connections/file-download/export', { query: queryParams });
  } catch (e) {
    console.error(e);
    notificationService.error('Could not export. See logs for more information');
  }
  return {
    shouldGridReload: false,
  };
};

export const testConnection = async (
  { selectedRowIds, allSelected, filter }: ActionData,
  onBroadCastEventFormTestConnection: ({ results }: SSEDataMessage<any>) => void,
) => {
  const uniqueBroadcastEvent = `test-ds-${uuid()}`;

  subscribeToRepeatedSSEEventById(uniqueBroadcastEvent, onBroadCastEventFormTestConnection);
  trackEventDataSources(DataSourcesUITrackingEvent.ADMINISTRATION_DATA_SOURCES_BULK_TEST_CONNECTION_CLICK, {
    dataSourceNames: selectedRowIds,
  });
  const {
    data: { message },
  } = await sendSSERequestWithEventId<any>(
    'post',
    'ds-connections/test',
    {
      ids: selectedRowIds,
      allSelected,
      query: { filter },
    },
    {},
    uniqueBroadcastEvent,
  );

  notificationService.success(message);

  return {
    shouldGridReload: false,
  };
};

export const importDataSources = async (
  unregisterImportHandler: React.MutableRefObject<any>,
  onBroadCastEventFormImport: ({ results }: SSEDataMessage<any>) => void,
): Promise<{ shouldGridReload: boolean; shouldClearSelection: boolean }> => {
  if (smallIdLicenseService.goToUpgradeIfLimitedSmallID()) {
    return {
      shouldGridReload: false,
      shouldClearSelection: false,
    };
  }

  return new Promise(resolve => {
    openImportDataSourceFormModal(() => {
      const uniqueBroadcastEventForImport: string = sessionStorageService.get(
        'uniqueBroadcastEventForImportDataSource',
      );

      if (uniqueBroadcastEventForImport) {
        unregisterImportHandler.current = subscribeToRepeatedSSEEventById(
          uniqueBroadcastEventForImport,
          onBroadCastEventFormImport,
        );
      }

      resolve({
        shouldGridReload: true,
        shouldClearSelection: true,
      });
    });
  });
};

export const createNewScan = async (
  actionData: ActionData,
  supportedScanTypesForSelectedDs: string[],
  hasMoreThanThresholdSelected: boolean,
) => {
  setSelectedDataSourcesToStorage(actionData.selectedRows.map(({ name, type, enabled }) => ({ name, type, enabled })));
  if (!supportedScanTypesForSelectedDs?.length) {
    openScanWarningDialog();
  } else if (hasMoreThanThresholdSelected) {
    openDialogForLargeScanSet({
      onContinueClick: () => {
        $state.go(CONFIG.states.CREATE_SCAN, { from: CONFIG.states.DATA_SOURCE_CONNECTIONS });
      },
    });
  } else {
    $state.go(CONFIG.states.CREATE_SCAN, { from: CONFIG.states.DATA_SOURCE_CONNECTIONS });
  }

  return { shouldGridReload: false };
};
