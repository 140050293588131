import React, { useEffect, useState } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import {
  BigidTabsAndContent,
  BigidTabsAndContentProps,
  EntityEvents,
  PrimaryButton,
  entityEventsEmitter,
} from '@bigid-ui/components';
import { $state, $transitions } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { BigidDataSearchIcon, BigidExternalLinkIcon, BigidHelpIcon } from '@bigid-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { UIView } from '@uirouter/react';
import { navigateToForbiddenPageIfNecessary, getScanHeaderButton, isTemplatesEnabled, getScanTabs } from './ScanUtils';
import { ScansUITrackingEvent } from './ScansEventTrackerUtils';
import { CreateScanButtonInterface } from './ScanTypes';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import {
  BigidPageTitleHelper,
  BigidPageTitleHelperButtonType,
  BigidPageTitleHelperProps,
} from '../../components/BigidHeader/BigidPageTitle/BigidPageTitleHelper';
import { generateDataAid } from '@bigid-ui/utils';
import { docsUrls } from '../../../react/config/publicUrls';
import { GuidedTourContext } from './ScanTemplates/GuidedTour/guidedTourContext';
import { getApplicationPreference } from '../../services/appPreferencesService';

const pageBottomMargin = 10;
const tabHeight = 34;

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${pageBottomMargin}px)`,
    width: '100%',
    overflow: 'hidden',
  },
  tabsContainer: {
    '& [class*="MuiTab-root"]:last-of-type': {
      marginLeft: 0,
    },
    marginBottom: 0,
  },
  tabsContainerRight: {
    '& [class*="MuiTab-root"]:last-of-type': {
      marginLeft: 'auto',
    },
    marginBottom: 0,
  },
  contentContainer: { overflow: 'hidden', display: 'none' },
  view: {
    height: `calc(100% - ${tabHeight}px)`,
  },
  buttonContainer: {
    display: 'flex',
    gap: '8px',
  },
});

export const getCreateScanButton = ({
  state,
  action,
  label,
  dataAid,
  isShowIcon,
  dataTourId,
}: CreateScanButtonInterface) => {
  return (
    <PrimaryButton
      onClick={() => {
        $state.go(state);
      }}
      size="large"
      dataAid={dataAid}
      bi={{ eventType: action }}
      startIcon={isShowIcon && <BigidDataSearchIcon />}
      text={label}
      dataTourId={dataTourId}
    />
  );
};

export const Scans: React.FC = () => {
  const scanTabs = getScanTabs();
  const [manualStart, setManualStart] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(scanTabs.indexOf($state.$current.name) || 0);
  const { tabsContainer, content, contentContainer, view, buttonContainer, tabsContainerRight } = useStyles({});
  const [routerStateName, setRouterStateName] = useState('');

  useEffect(() => {
    setRouterStateName($state.current.name);
    navigateToForbiddenPageIfNecessary();
  }, []);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Scans',
      rightSideComponentsContainer: (
        <div className={buttonContainer}>{getScanHeaderButton(scanTabs[selectedTabIndex])}</div>
      ),
    });
    const deregisterListener = $transitions.onBefore(
      {
        to: state => {
          setRouterStateName(state.name);
          return state.name.includes(CONFIG.states.SCANS_NEW);
        },
      },
      transition => {
        const { name } = transition.to();
        if (name === CONFIG.states.SCANS_NEW) {
          return $state.target(CONFIG.states.SCANS_NEW_SCANS);
        }
        setSelectedTabIndex(scanTabs.indexOf(name) || 0);
      },
    );

    const deregisterTransitionListener = $transitions.onEnter(
      {
        to: state => {
          setRouterStateName(state.name);
          return state.name.includes(CONFIG.states.SCANS_NEW);
        },
      },
      transition => {
        const { name } = transition.to();
        pageHeaderService.setTitle({
          pageTitle: 'Scans',
          rightSideComponentsContainer: <div className={buttonContainer}>{getScanHeaderButton(name)}</div>,
        });
      },
    );

    if ($state.current.name === CONFIG.states.SCANS_NEW && getApplicationPreference('SHOW_NEW_SCANS_PAGE')) {
      $state.go(CONFIG.states.SCANS_NEW_SCANS);
    }
    return () => {
      deregisterListener?.();
      deregisterTransitionListener?.();
    };
  }, [scanTabs, buttonContainer, selectedTabIndex]);

  useEffect(() => {
    const handleStateChange = (transition: any) => {
      setSelectedTabIndex(scanTabs.indexOf(transition.to().name));
    };

    const deregister = $transitions.onSuccess({}, handleStateChange);

    return () => {
      deregister();
    };
  }, [$state, $transitions]);

  const shouldDisplayLastTabRight = isPermitted(SCANS_PERMISSIONS.READ_SCAN_PROFILES.name) && !isTemplatesEnabled();

  const pageTitleHelperScanTemplateProps: BigidPageTitleHelperProps = {
    dataAid: generateDataAid('scanTemplate', ['title', 'helper']),
    contentWidth: 365,
    togglerIcon: BigidHelpIcon,
    title: 'Get to know Scan Templates',
    body: 'Scan templates save you work by preserving scan configurations that are often reused for multiple scans. For more information, read the documentation or take the guided tour.',
    buttons: [
      {
        type: BigidPageTitleHelperButtonType.TERTIARY,
        text: 'Open Documentation',
        endIcon: <BigidExternalLinkIcon />,
        onClick: () => {
          window.open(docsUrls.SCAN_TEMPLATES, '_blank');
        },
      },
      {
        type: BigidPageTitleHelperButtonType.PRIMARY,
        text: 'Guided Tour',
        onClick: () => {
          setManualStart(true);
          entityEventsEmitter.emit(EntityEvents.RELOAD);
        },
      },
    ],
  };

  const pageTitleHelperScanSavedScans: BigidPageTitleHelperProps = {
    dataAid: generateDataAid('savedScans', ['title', 'helper']),
    contentWidth: 365,
    togglerIcon: BigidHelpIcon,
    title: 'Manage template based scans',
    body: 'Create new scans or easily convert your Legacy scans to Scan Template based.',
    buttons: [
      {
        type: BigidPageTitleHelperButtonType.TERTIARY,
        text: 'Open Documentation',
        endIcon: <BigidExternalLinkIcon />,
        onClick: () => {
          window.open(docsUrls.SAVED_SCANS, '_blank');
        },
      },
      {
        type: BigidPageTitleHelperButtonType.PRIMARY,
        text: 'Guided Tour',
        onClick: () => {
          setManualStart(true);
          entityEventsEmitter.emit(EntityEvents.RELOAD);
        },
      },
    ],
  };
  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: { tabsContainer: shouldDisplayLastTabRight ? tabsContainerRight : tabsContainer, contentContainer },
    tabProps: {
      size: 'large',
      tabs: [
        { label: 'Scans' },
        ...(isPermitted(SCANS_PERMISSIONS.READ_SCAN_PROFILES.name)
          ? [
              {
                label: 'Saved Scans',
                getIsAvailable: () => isTemplatesEnabled(),
                bi: {
                  eventType: ScansUITrackingEvent.SCAN_TAB_CLICK,
                  eventData: {
                    tabName: scanTabs[2],
                  },
                },
                ...(routerStateName === CONFIG.states.SCANS_PLANNED_SCANS
                  ? {
                      AdditionalLabelItem: {
                        component: BigidPageTitleHelper,
                        props: {
                          ...pageTitleHelperScanSavedScans,
                        },
                      },
                    }
                  : {}),
              },
              {
                label: 'Scan Templates',
                getIsAvailable: () => isTemplatesEnabled(),
                bi: {
                  eventType: ScansUITrackingEvent.SCAN_TAB_CLICK,
                  eventData: {
                    tabName: scanTabs[3],
                  },
                },
                ...(routerStateName === CONFIG.states.SCANS_SCAN_TEMPLATES
                  ? {
                      AdditionalLabelItem: {
                        component: BigidPageTitleHelper,
                        props: {
                          ...pageTitleHelperScanTemplateProps,
                        },
                      },
                    }
                  : {}),
              },
              {
                label: 'Saved Profiles',
                icon: { Component: BigidDataSearchIcon, stroke: false },
                getIsAvailable: () => !isTemplatesEnabled(),
                bi: {
                  eventType: ScansUITrackingEvent.SCAN_TAB_CLICK,
                  eventData: {
                    tabName: scanTabs[2],
                  },
                },
              },
            ]
          : []),
      ],
      onChange: tabIndex => {
        const state = scanTabs[tabIndex];
        $state.go(state);
        setSelectedTabIndex(tabIndex);
      },
      selectedIndex: selectedTabIndex,
    },
  };

  return (
    <div className={content}>
      <GuidedTourContext.Provider value={{ manualStart, setManualStart }}>
        <BigidTabsAndContent {...tabsAndContentConfig} />
        <UIView className={view} />
      </GuidedTourContext.Provider>
    </div>
  );
};
