import React, { useEffect, useState } from 'react';
import { BrandingLogo, BrandingLogoSmall } from './LegalEntitiesCommonStyles';
import { getBrandingLogo } from './LegalEntitiesService';
import { BigidIbanIcon, BigidImageIcon } from '@bigid-ui/icons';

const logoCache: Record<string, string> = {};

export const LegalEntitiesBrandingLogo = ({
  brandingId,
  bigLogo,
  shouldRefetch = false,
  inGrid,
}: {
  brandingId: string;
  bigLogo?: boolean;
  shouldRefetch?: boolean;
  inGrid?: boolean;
}) => {
  const [imageSrc, setImageSrc] = useState('');
  const Logo = bigLogo ? BrandingLogo : BrandingLogoSmall;
  const noBrandingLogoSize = bigLogo ? 60 : 20;

  useEffect(() => {
    if (!brandingId) return;

    if (!shouldRefetch && logoCache[brandingId]) {
      return setImageSrc(logoCache[brandingId]);
    }

    if (shouldRefetch) {
      logoCache[brandingId] = '';
    }

    (async () => {
      try {
        const response = await getBrandingLogo(brandingId);
        const contentType = response.headers['content-type'];
        const buffer = response.data;

        const blob = new Blob([buffer], { type: contentType });
        const imageUrl = URL.createObjectURL(blob);

        logoCache[brandingId] = imageUrl;
        setImageSrc(imageUrl);
      } catch (err) {
        console.error('Error fetching image:', err);
      }
    })();
  }, [brandingId, shouldRefetch]);

  if (!brandingId || (inGrid && !imageSrc)) {
    return <BigidIbanIcon width={noBrandingLogoSize} height={noBrandingLogoSize} />;
  }

  return imageSrc ? <Logo src={imageSrc} /> : <BigidImageIcon />;
};
