import React, { FC } from 'react';
import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { BigidCircularProgressBar } from '@bigid-ui/visualisation';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { styled } from '@mui/material';

const LegendContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 216px;
`;

const data = [
  {
    category: 'Remediated',
    value: 52200,
    isActive: true,
    color: BigidColorsV2.magenta[200],
  },
  {
    category: 'Discovered',
    value: 98000,
    isActive: true,
    color: BigidColorsV2.purple[600],
  },
];

export const DataRiskProgressChart: FC = () => {
  const formatLegends = (dataItem: any) => {
    const percentage = `(${Math.round(dataItem?.percent)}%)`;

    return (
      <LegendContainer>
        <BigidBody1>{dataItem?.category}</BigidBody1>
        <BigidBody1>
          {formatNumberCompact(dataItem?.value, 2)} {percentage}
        </BigidBody1>
      </LegendContainer>
    );
  };
  // TODO: POC Code, to be replaced with new pie chart which is in dev BCO-16977
  return (
    <BigidCircularProgressBar
      legendProps={{
        legendPosition: 'bottom',
        shouldHideSeries: false,
        hideLegend: false,
        legendLabelFormatter: dataItem => formatLegends(dataItem),
        legendConfig: { orientation: 'column' },
      }}
      size={230}
      data={data}
      numberFormat="#.00A"
      label="Scanned"
      value={98000}
    />
  );
};
