import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  BigidDialog,
  BigidTags,
  BigidTagBaseProps,
  BigidTagWizardDictionary,
  getTagsDictionary,
  BigidTagsValidationPayload,
} from '@bigid-ui/components';
import { DataCatalogRecord } from '../../../../../../../DataCatalog/DataCatalogService';
import {
  getTagsAllPairs,
  TagEntity,
  TagMutualExclusion,
} from '../../../../../../../TagsManagement/TagsManagementService';
import {
  getTagEntityById,
  getTagEntityByName,
  getTagFormattedName,
  getTagIcon,
  validateTag,
} from '../../../../../../../TagsManagement/TagsManagementUtils';
import { isPermitted } from '../../../../../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import {
  attachMultipleTags,
  createAndAttachMultipleTags,
  DataCatalogObjectColumnUseTagResponseData,
  detachMultipleTags,
} from '../../../../../../../DataCatalog/DataCatalogColumns';
import { makeStyles } from '@mui/styles';
import { notificationService } from '../../../../../../../../services/notificationService';
import { analyticsService } from '../../../../../../../../services/analyticsService';
import { DataExplorerEventsEnum, getBiEventName } from '../../../../../../events';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
  },
});

export interface TagsDialogProps extends Pick<DataCatalogRecord, 'fullyQualifiedName' | 'source'> {
  columnName: string;
  tags: TagEntity[];
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (updatedTags: BigidTagBaseProps[], postSubmitMessage?: string) => void;
}

const validateTagName = ({ tagName }: BigidTagsValidationPayload): string => {
  const { isTagNameValueValid } = validateTag;

  if (!isTagNameValueValid(tagName)) {
    return 'Invalid tag name';
  }

  return undefined;
};

const validateTagValue = ({ tagName, tagValue, tagDictionary }: BigidTagsValidationPayload): string => {
  const { isTagValuePairUnique, isTagValueNotEqualsToTagName, isTagNameValueValid } = validateTag;

  if (!isTagValuePairUnique(tagValue, tagName, tagDictionary)) {
    return 'Tag & Value pair has to be unique';
  }

  if (!isTagValueNotEqualsToTagName(tagValue, tagName)) {
    return 'Tag & Value cannot be equal';
  }

  if (!isTagNameValueValid(tagValue)) {
    return 'Invalid tag value';
  }

  return undefined;
};

const composeMutuallyExclusiveNotification = (exclusion: TagMutualExclusion[], systemTags: TagEntity[]): string => {
  const messageBase = 'The following tags were excluded due to their mutual exclusion:';

  return exclusion.reduce((messageAggr, { from, to }) => {
    const fromTagEntity = getTagEntityById(systemTags, from.tagId, from.valueId);
    const toTagEntity = getTagEntityById(systemTags, to.tagId, to.valueId);

    if (fromTagEntity && toTagEntity) {
      const fromTag = `${fromTagEntity.tagName}:${fromTagEntity.tagValue}`;
      const toTag = `${toTagEntity.tagName}:${toTagEntity.tagValue}`;
      return messageAggr ? `${messageAggr}, '${fromTag}' to '${toTag}'` : `${messageBase} '${fromTag}' to '${toTag}'`;
    } else {
      return messageAggr;
    }
  }, '');
};

export const TagsDialog: FC<TagsDialogProps> = ({
  tags,
  columnName,
  fullyQualifiedName,
  source,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [columnTagsStated, setColumnTagsStated] = useState<BigidTagBaseProps[]>([]);
  const [systemTags, setSystemTags] = useState<TagEntity[]>([]);
  const [dictionary, setDictionary] = useState<BigidTagWizardDictionary>();
  const [createdColumnTags, setCreatedColumnTags] = useState<BigidTagBaseProps[]>([]);
  const [attachedColumnTags, setAttachedColumnTags] = useState<BigidTagBaseProps[]>([]);
  const [detachedColumnTags, setDetachedColumnTags] = useState<BigidTagBaseProps[]>([]);

  const { isCreateTagsPermitted, isReadTagsPermitted, isColumnTagsAssignmentPermitted } = useMemo(
    () => ({
      isCreateTagsPermitted: isPermitted(TAGS_PERMISSIONS.CREATE.name),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
      isColumnTagsAssignmentPermitted: isPermitted(CATALOG_PERMISSIONS.ASSIGN_TAG.name),
    }),
    [],
  );

  const submitCreatedTags = useCallback(async (): Promise<DataCatalogObjectColumnUseTagResponseData> => {
    try {
      return await createAndAttachMultipleTags({
        systemTags,
        tags: createdColumnTags,
        fullyQualifiedName,
        source,
        columnName,
      });
    } catch ({ message, response }) {
      const notificationMessage =
        response?.data?.message === 'this tag-name already exist'
          ? 'A tag with this name already exists'
          : 'An error has occurred';
      notificationService.error(notificationMessage);
      console.error(`An error has occurred: ${message}`);
      return { tags: [] };
    }
  }, [systemTags, createdColumnTags, fullyQualifiedName, source, columnName]);

  const submitAttachedTags = useCallback(async (): Promise<DataCatalogObjectColumnUseTagResponseData> => {
    try {
      return await attachMultipleTags({
        systemTags,
        tags: attachedColumnTags,
        fullyQualifiedName,
        source,
        columnName,
      });
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
      return { tags: [] };
    }
  }, [systemTags, attachedColumnTags, fullyQualifiedName, source, columnName]);

  const submitDetachedTags = useCallback(async (): Promise<DataCatalogObjectColumnUseTagResponseData> => {
    try {
      return await detachMultipleTags({
        systemTags,
        tags: detachedColumnTags,
        fullyQualifiedName,
        source,
        columnName,
      });
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
      return { tags: [] };
    }
  }, [systemTags, detachedColumnTags, fullyQualifiedName, source, columnName]);

  const submitChanges = useCallback(async () => {
    try {
      setIsLoading(true);

      const { tags: detachedTags } = await submitDetachedTags();
      const { tags: attachedTags, response: submitAttachedTagsResponse } = await submitAttachedTags();
      const { tags: createdTags, response: submitCreatedTagsResponse } = await submitCreatedTags();

      const systemTagsUpdated = [...systemTags, ...createdTags];

      const mutualExclusionAggregated = [
        ...submitCreatedTagsResponse
          .reduce((submitCreatedTagsMutualExclusion, { mutuallyExcluded }) => {
            return [...submitCreatedTagsMutualExclusion, ...mutuallyExcluded];
          }, [])
          .filter(({ from, to }) => from && to),
        ...submitAttachedTagsResponse
          .reduce((submitAttachedTagsMutualExclusion, { mutuallyExcluded }) => {
            return [...submitAttachedTagsMutualExclusion, ...mutuallyExcluded];
          }, [])
          .filter(({ from, to }) => from && to),
      ];

      const attachedTagsFiltered = [...createdTags, ...attachedTags]
        .filter(({ tagId, valueId }) => {
          const isBeingExcluded = !!mutualExclusionAggregated.find(
            ({ from: excludedTag }) => excludedTag.tagId === tagId && excludedTag.valueId === valueId,
          );

          return !isBeingExcluded;
        })
        .map(({ tagName, tagValue }) => ({ name: tagName, value: tagValue }));

      const updatedTags = [
        ...columnTagsStated.filter(({ name, value }) => {
          const isBeingDetached = !!detachedTags.find(
            detachedTag => detachedTag.tagName === name && detachedTag.tagValue === value,
          );
          const isBeingExcluded = !!mutualExclusionAggregated.find(({ from: excludedTag }) => {
            const excludedTagEntity = getTagEntityByName(systemTags, name, value);
            return excludedTagEntity.tagId === excludedTag.tagId && excludedTagEntity.valueId === excludedTag.valueId;
          });

          return !isBeingDetached && !isBeingExcluded;
        }),
        ...attachedTagsFiltered,
      ];

      const postSubmitMessage = composeMutuallyExclusiveNotification(mutualExclusionAggregated, systemTagsUpdated);

      onSubmit(updatedTags, postSubmitMessage);
      resetState();
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    } finally {
      setIsLoading(false);
    }
  }, [columnTagsStated, onSubmit, submitAttachedTags, submitCreatedTags, submitDetachedTags, systemTags]);

  const fetchTags = useCallback(async () => {
    try {
      setIsLoading(true);

      const tags = await getTagsAllPairs();
      const dictionary = getTagsDictionary(tags.map(({ tagName, tagValue }) => ({ name: tagName, value: tagValue })));

      setSystemTags(tags);
      setDictionary(dictionary);
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleTagCreate = useCallback(
    (tag: BigidTagBaseProps): void => {
      const isCreatedTagExist = createdColumnTags.find(({ name, value }) => tag.name === name && tag.value === value);
      !isCreatedTagExist && setCreatedColumnTags(createdColumnTagsPrev => [...createdColumnTagsPrev, tag]);
    },
    [createdColumnTags],
  );

  const handleTagAttach = useCallback(
    (tag: BigidTagBaseProps): void => {
      const isAttachedTagExist = attachedColumnTags.find(({ name, value }) => tag.name === name && tag.value === value);
      !isAttachedTagExist && setAttachedColumnTags(attachedColumnTagsPrev => [...attachedColumnTagsPrev, tag]);

      analyticsService.trackManualEvent(getBiEventName(DataExplorerEventsEnum.ADD_TAG_EVENT));
    },
    [attachedColumnTags],
  );

  const handleTagDetach = useCallback(
    (tag: BigidTagBaseProps): void => {
      const isDetachedTagExists = detachedColumnTags.find(
        ({ name, value }) => tag.name === name && tag.value === value,
      );
      const isDetachedTagExistsAmongColumnTags = columnTagsStated.find(
        ({ name, value }) => tag.name === name && tag.value === value,
      );

      if (!isDetachedTagExists && isDetachedTagExistsAmongColumnTags) {
        setDetachedColumnTags(detachedColumnTagsPrev => [...detachedColumnTagsPrev, tag]);
      }

      const detachedTagIndexAmongCreated = createdColumnTags.findIndex(
        ({ name, value }) => tag.name === name && tag.value === value,
      );

      detachedTagIndexAmongCreated >= 0 &&
        setCreatedColumnTags(createdColumnTagsPrev => [
          ...createdColumnTagsPrev.slice(0, detachedTagIndexAmongCreated),
          ...createdColumnTagsPrev.slice(detachedTagIndexAmongCreated + 1, createdColumnTagsPrev.length),
        ]);

      const detachedTagIndexAmongAttached = attachedColumnTags.findIndex(
        ({ name, value }) => tag.name === name && tag.value === value,
      );

      detachedTagIndexAmongAttached >= 0 &&
        setAttachedColumnTags(attachedColumnTagsPrev => [
          ...attachedColumnTagsPrev.slice(0, detachedTagIndexAmongAttached),
          ...attachedColumnTagsPrev.slice(detachedTagIndexAmongAttached + 1, attachedColumnTagsPrev.length),
        ]);

      analyticsService.trackManualEvent(getBiEventName(DataExplorerEventsEnum.REMOVE_TAG_EVENT));
    },
    [createdColumnTags, attachedColumnTags, detachedColumnTags, columnTagsStated],
  );

  const resetState = () => {
    setSystemTags([]);
    setDictionary(undefined);
    setCreatedColumnTags([]);
    setAttachedColumnTags([]);
    setDetachedColumnTags([]);
  };

  const handleOnDialogSave = () => {
    submitChanges();
  };

  const handleOnDialogClose = () => {
    resetState();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      isReadTagsPermitted && fetchTags();
      setColumnTagsStated(
        (tags || []).map(({ tagName, tagValue, properties }) => {
          return { name: getTagFormattedName(tagName), value: tagValue, icon: getTagIcon(properties) };
        }),
      );
    }
  }, [isOpen, fetchTags, tags, isReadTagsPermitted]);

  return (
    <BigidDialog
      title={'Add Tag'}
      isOpen={isOpen}
      borderTop
      onClose={handleOnDialogClose}
      buttons={[
        {
          component: SecondaryButton,
          onClick: handleOnDialogClose,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: handleOnDialogSave,
          text: 'Save',
        },
      ]}
      isLoading={isLoading}
      maxWidth="sm"
    >
      <div className={classes.wrapper}>
        <BigidTags
          tagWizardMenuPosition="fixed"
          tags={columnTagsStated}
          tagsDictionary={dictionary}
          useMinHeight
          onCreate={handleTagCreate}
          onAdd={handleTagAttach}
          onDelete={handleTagDetach}
          isAbleToCreate={isCreateTagsPermitted}
          isAbleToDelete={isColumnTagsAssignmentPermitted}
          isAbleToEdit={isColumnTagsAssignmentPermitted}
          placeholder="Click the + to add tag"
          validateName={validateTagName}
          validateValue={validateTagValue}
        />
      </div>
    </BigidDialog>
  );
};
