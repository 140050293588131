import { BigidBody1 } from '@bigid-ui/components';
import { styled } from '@mui/material';

export const CaseSidePanelContainer = styled('div', { shouldForwardProp: prop => prop !== 'isSidePanelActive' })<{
  isSidePanelActive?: boolean;
}>(({ isSidePanelActive, theme }) => ({
  display: isSidePanelActive ? 'block' : 'none',

  '& aside > div > div': {
    overflowY: 'auto',
    scrollbarGutter: 'stable', // not supported in safari, need to check for safari replacement
  },
  '& aside > div > header': {
    position: 'relative',
    borderBottom: theme.vars.tokens.bigid.borderDefault,
    background: theme.vars.tokens.bigid.backgroundPrimary,
  },
  '& aside > div > header > div': {
    borderBottom: 'unset',
    height: '100%',
  },
}));

export const SidePanelHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '7px 0 4px 8px',
  alignItems: 'flex-start',
});

export const HeaderBottomInfoWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

export const RiskStatusAndCreatedAtWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

export const JiraTicketAssigneeAndStatusButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  position: 'absolute',
  right: '24px',
});

export const SidePanelContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  padding: '24px',
});

export const ContentTopSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const TopSectionWrapper = styled('div')({
  display: 'flex',
  gap: '32px',
  justifyContent: 'space-between',
});

export const RiskDescriptionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
});

export const LegalEntitiesAndVendorsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginLeft: '32px',
});

export const LegalEntitiesAndVendors = styled('div')({
  width: '216px',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

export const TitleWrapper = styled(BigidBody1)(({ theme }) => ({
  color: theme.vars.tokens.bigid.foregroundTertiary,
  fontWeight: 400,
  lineHeight: '150%',
}));

export const TriggeredInInstanceWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const AccordionHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

export const AccordionContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const InstanceQuestionSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export const NoAnswerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '4px',
});

export const QuestionAnswer = styled(BigidBody1)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
  background: theme.vars.palette.bigid.gray125,
}));

export const AnsweredByTitle = styled(BigidBody1)(({ theme }) => ({
  fontSize: '12px',
  display: 'flex',
  gap: '4px',
  alignSelf: 'end',
  color: theme.vars.tokens.bigid.foregroundTertiary,
}));

export const AnsweredByWrapper = styled('div')({
  display: 'flex',
  gap: '4px',
  alignSelf: 'end',
  alignItems: 'center',
});

export const FlaggedByWrapper = styled('div')({
  display: 'flex',
  gap: '6px',
});

export const FlaggedByUser = styled('div')({
  display: 'flex',
  gap: '6px',
});

export const RiskNote = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  paddingTop: '20px',
});

export const Link = styled(BigidBody1)(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-block',
  alignItems: 'center',
  justifyContent: 'space-between',
  textDecoration: 'undefined',
  color: theme.vars.tokens.bigid.textLink,
  paddingTop: '6px',
  fontWeight: 700,
}));

export const ContentDetailsSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const DetailsItems = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '24px',
});

export const DetailItem = styled('div')({
  display: 'flex',
  flex: '0 0 30%',
  flexDirection: 'column',
  gap: '4px',
});

export const ActivityLogContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const DescriptionContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const Description = styled(BigidBody1, { shouldForwardProp: prop => prop !== 'shouldShowMore' })<{
  shouldShowMore?: boolean;
}>(({ shouldShowMore }) => ({
  textOverflow: shouldShowMore ? 'ellipsis' : 'unset',
  display: shouldShowMore ? '-webkit-box' : 'initial',
  '-webkit-line-clamp': shouldShowMore ? '3' : 'initial',
  overflow: shouldShowMore ? 'hidden' : 'initial',
  '-webkit-box-orient': shouldShowMore ? 'vertical' : 'initial',
}));

export const ShowMoreText = styled(BigidBody1)(({ theme }) => ({
  fontSize: '14px',
  color: theme.vars.tokens.bigid.textLink,
  cursor: 'pointer',
}));

export const MoreActionsButton = styled('div')({
  marginRight: '4px',
});
