import React, { FC, useEffect, useState } from 'react';
import {
  BigidBody1,
  BigidFormProps,
  BigidHeading5,
  TertiaryButton,
  BigidForm,
  BigidLoader,
  BigidDropdownOption,
} from '@bigid-ui/components';
import { BigidEmailTemplateIllustration, BigidWorkspaceIcon, BigidSendIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import {
  generateEmailContentPayload,
  openEmailStructureDialog,
  sendTestEmail,
  TestEmailPayload,
} from '../../emailEditorService';
import { useLocalTranslation } from '../../translations';
import { QuickActionButtonWithPopper } from '../../../QuickActionButtonWithPopper/QuickActionButtonWithPopper';
import { getCurrentUser } from '../../../../utilities/systemUsersUtils';
import {
  getBrandsOptions,
  getDsCollaborationUserPreferences,
} from '../../../../views/DataSources/DataSourceConnectionModal/DataSourceConnectionCollaboration/dataSourceCollaborationService';
import { SendTestEmailForm } from './SendTestEmailForm';
import { notificationService } from '../../../../services/notificationService';
import { EmailEditorTrackingEvents, trackEmailEditorEvent } from '../../emailEditorTrackingUtils';
import { useKey } from '../../../../views/DataSources/DataSourceConfiguration/hooks/useKey';
import { ACCESS_MANAGEMENT_PERMISSIONS, BRANDS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';

export interface EmailContentFormProps {
  isLoading: boolean;
  formProps: BigidFormProps;
  sectionFields: string[][];
  selectedCategory?: string;
  validateBeforeSend?: boolean;
  onValidate?: () => Promise<boolean>;
}

const MainContainer = styled('div')(({ theme }) => ({
  width: '70%',
  height: '100%',
  minHeight: 650,
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.bigid.gray100,
  flexDirection: 'column',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: '7px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: '#949494',
  },
}));

const EmptySelectionContainer = styled('div')({
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '140px',
  margin: '0 auto',
});

const EmailContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
});

const TitleSection = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: '16px',
});

const TitleButtonsContainer = styled('div')({
  display: 'flex',
  gap: '8px',
  flexDirection: 'row',
});

const HeadingAreaContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const EmailContentForm: FC<EmailContentFormProps> = ({
  selectedCategory,
  formProps,
  sectionFields,
  isLoading,
  validateBeforeSend,
  onValidate,
}) => {
  const { t } = useLocalTranslation();
  const [brandOptions, setBrandOptions] = useState<BigidDropdownOption[]>([]);
  const [preferredBrand, setPreferredBrand] = useState<string>();
  const [currentUser, setCurrentUser] = useState<string>('');
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [isEmailSendingInProgress, setIsEmailSendingInProgress] = useState(false);
  const [key, resetKey] = useKey();

  const togglePopper = () => {
    setIsPopperOpen(previousOpen => {
      return !previousOpen;
    });
  };

  const handleShowSendEmailDialog = async () => {
    if (validateBeforeSend) {
      const isValid = await onValidate?.();
      isValid && togglePopper();
      return;
    }

    togglePopper();
  };

  const onSendTestEmail = async (recipientEmail: string, brandName: string) => {
    try {
      setIsEmailSendingInProgress(true);
      const emailContent = generateEmailContentPayload(
        sectionFields,
        formProps.stateAndHandlersRef.current.getValues(),
        formProps.fields,
      );
      const testEmailPayload: TestEmailPayload = {
        emailContent,
        brandName,
        recipientEmail,
      };
      await sendTestEmail(testEmailPayload);
      notificationService.success(t('testMailSection.emailSuccessfullySent'));
      trackEmailEditorEvent(EmailEditorTrackingEvents.EMAIL_EDITOR_SEND_TEST_EMAIL_CLICK, { brandName });
      togglePopper();
    } catch (e) {
      console.error('Sending test email error', e);
      const errorMessage = e?.message ? `, ${t('errorMessage')} ${e.message}` : '.';
      notificationService.error(t('testMailSection.emailError', { errorMessage }));
    } finally {
      setIsEmailSendingInProgress(false);
    }
  };

  useEffect(() => {
    // info: fetching data for the test email action form
    const fetchBrandsData = async () => {
      const newBrandsOptions = await getBrandsOptions();
      if (newBrandsOptions.length > 1) {
        const { data } = (await getDsCollaborationUserPreferences()) || {};
        data?.lastBrandSelected && setPreferredBrand(data.lastBrandSelected);
        setBrandOptions(newBrandsOptions);
      }
    };
    const fetchUserData = async () => {
      const { name } = await getCurrentUser();
      name && setCurrentUser(name);
    };

    if (isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name)) {
      fetchUserData();
    }
    if (isPermitted(BRANDS_PERMISSIONS.READ.name)) {
      fetchBrandsData();
    }
  }, []);

  useEffect(() => {
    resetKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formProps]);

  return (
    <MainContainer>
      <>
        {isLoading ? (
          <BigidLoader position="relative" />
        ) : (
          <>
            {selectedCategory ? (
              <EmailContentContainer>
                <TitleSection>
                  <HeadingAreaContainer>
                    <BigidHeading5>{t('formHeaders.emailTemplate')}</BigidHeading5>
                  </HeadingAreaContainer>
                  <TitleButtonsContainer>
                    <TertiaryButton
                      startIcon={<BigidWorkspaceIcon />}
                      text={t('buttonLables.emailStructre')}
                      onClick={() => openEmailStructureDialog()}
                      size="medium"
                      bi={{ eventType: EmailEditorTrackingEvents.EMAIL_EDITOR_EMAIL_STRUCTURE_PREVIEW_CLICK }}
                    />
                    <QuickActionButtonWithPopper
                      popperWidth={440}
                      buttonIcon={BigidSendIcon}
                      buttonText={t('buttonLables.sendTestEmail')}
                      popperTitle={t('buttonLables.sendTestEmail')}
                      isOpen={isPopperOpen}
                      onToggle={handleShowSendEmailDialog}
                    >
                      <SendTestEmailForm
                        preferredBrand={preferredBrand}
                        brandOptions={brandOptions}
                        currentUser={currentUser}
                        onSendTestEmail={onSendTestEmail}
                        isEmailSendingInProgress={isEmailSendingInProgress}
                      />
                    </QuickActionButtonWithPopper>
                  </TitleButtonsContainer>
                </TitleSection>
                <BigidForm key={key} {...formProps} />
              </EmailContentContainer>
            ) : (
              <EmptySelectionContainer>
                <BigidEmailTemplateIllustration />
                <BigidBody1>{t('messages.emptyCategorySelection')}</BigidBody1>
              </EmptySelectionContainer>
            )}
          </>
        )}
      </>
    </MainContainer>
  );
};
