import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useDataCatalogSearchResultsContext } from '../../contexts/dataCatalogSearchResultsContext';
import { ResultsEntityType, WithDataAid } from '../../types';
import { CatalogToolbar } from './Toolbars/Catalog/CatalogToolbar';
import { EntityFiltersToolbar } from './Toolbars/EnititiesToolbar/EnititiesToolbar';

const Container = styled('div')`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.bigid.gray200};
`;

const entityTypeToToolbarComponentMap: Record<ResultsEntityType, FC<WithDataAid>> = {
  catalog: CatalogToolbar,
  datasource: EntityFiltersToolbar,
  policy: EntityFiltersToolbar,
};

export const CatalogSearchToolbar: FC<WithDataAid> = ({ dataAid = 'CatalogSearchToolbar' }) => {
  const { entityType } = useDataCatalogSearchResultsContext();

  const ToolbarComponent = useMemo(() => entityTypeToToolbarComponentMap[entityType], [entityType]);

  return (
    <Container data-aid={generateDataAid(dataAid, ['toolbar', 'container'])}>
      <ToolbarComponent key={entityType} />
    </Container>
  );
};
