import React, { useState, useRef } from 'react';
import { BigidButtonIcon, BigidMenu } from '@bigid-ui/components';
import { BigidMoreActionIcon } from '@bigid-ui/icons';
import { MenuItems } from '../WidgetsContainer';

export const CarouselItemMenu = ({ handleHoverEffect }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const ref = useRef(null);

  const handleMenuVisibility = (e: any) => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    handleHoverEffect(false);
    setIsMenuOpen(false);
  };

  return (
    <>
      <BigidButtonIcon ref={ref} icon={BigidMoreActionIcon} size="small" onClick={handleMenuVisibility} />
      <BigidMenu open={isMenuOpen} items={MenuItems} anchorEl={ref.current} onMenuClose={handleMenuClose} />
    </>
  );
};
