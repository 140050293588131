import { analyticsService } from '../../../services/analyticsService';

export const trackLegalEntities = (eventType: string, eventData?: Record<string, unknown>) => {
  analyticsService.trackManualEvent(eventType, eventData);
};

export enum LegalEntitiesTrackingEvents {
  //status clicks
  SET_STATUS_ACTIVE_CLICK = 'LEGAL_ENTITIES.SET_STATUS_ACTIVE_CLICK',
  SET_STATUS_INACTIVE_CLICK = 'LEGAL_ENTITIES.SET_STATUS_INACTIVE_CLICK',
  SET_STATUS_DRAFT_CLICK = 'LEGAL_ENTITIES.SET_STATUS_DRAFT_CLICK',
  SET_STATUS_UNDER_REVIEW_CLICK = 'LEGAL_ENTITIES.SET_STATUS_UNDER_REVIEW_CLICK',
  //grid action
  DELETE_LEGAL_ENTITY_INLINE_ACTION = 'LEGAL_ENTITIES.DELETE_LEGAL_ENTITY_INLINE_ACTION',
  EDIT_LEGAL_ENTITY_INLINE_ACTION = 'LEGAL_ENTITIES.EDIT_LEGAL_ENTITY_INLINE_ACTION',
  VIEW_LEGAL_ENTITY_INLINE_ACTION = 'LEGAL_ENTITIES.VIEW_LEGAL_ENTITY_INLINE_ACTION',
  //general actions
  CREATE_LEGAL_ENTITY_ACTION = 'LEGAL_ENTITIES.CREATE_LEGAL_ENTITY_ACTION',
  EDIT_LEGAL_ENTITY_ACTION = 'LEGAL_ENTITIES.EDIT_LEGAL_ENTITY_ACTION',
  CREATE_SUB_ENTITY_ACTION = 'LEGAL_ENTITIES.CREATE_SUB_ENTITY_ACTION',
  GO_TO_SUB_ENTITY_CLICK = 'LEGAL_ENTITIES.GO_TO_SUB_ENTITY_CLICK',
  //modal clicks
  DELETE_LEGAL_ENTITY_DIALOG_DELETE_CLICK = 'LEGAL_ENTITIES.DELETE_LEGAL_ENTITY_DIALOG_DELETE_CLICK',
  DELETE_LEGAL_ENTITY_DIALOG_CANCEL_CLICK = 'LEGAL_ENTITIES.DELETE_LEGAL_ENTITY_DIALOG_CANCEL_CLICK',
  CREATE_LEGAL_ENTITY_DIALOG_SAVE_CLICK = 'LEGAL_ENTITIES.CREATE_LEGAL_ENTITY_DIALOG_SAVE_CLICK',
  CREATE_LEGAL_ENTITY_DIALOG_CANCEL_CLICK = 'LEGAL_ENTITIES.CREATE_LEGAL_ENTITY_DIALOG_CANCEL_CLICK',
  EDIT_LEGAL_ENTITY_DIALOG_SAVE_CLICK = 'LEGAL_ENTITIES.EDIT_LEGAL_ENTITY_DIALOG_SAVE_CLICK',
  EDIT_LEGAL_ENTITY_DIALOG_CANCEL_CLICK = 'LEGAL_ENTITIES.EDIT_LEGAL_ENTITY_DIALOG_CANCEL_CLICK',
  CREATE_SUB_ENTITY_DIALOG_SAVE_CLICK = 'LEGAL_ENTITIES.CREATE_SUB_ENTITY_DIALOG_SAVE_CLICK',
  CREATE_SUB_ENTITY_DIALOG_CANCEL_CLICK = 'LEGAL_ENTITIES.CREATE_SUB_ENTITY_DIALOG_CANCEL_CLICK',
  // Branding events
  CREATE_BRANDING_CLICK = 'LEGAL_ENTITIES.CREATE_BRANDING_CLICK',
  EDIT_BRANDING_CLICK = 'LEGAL_ENTITIES.EDIT_BRANDING_CLICK',
  BRANDING_CHOOSE_LOGO_CLICK = 'LEGAL_ENTITIES.BRANDING_CHOOSE_LOGO_CLICK',
  BRANDING_DELETE_LOGO_CLICK = 'LEGAL_ENTITIES.BRANDING_DELETE_LOGO_CLICK',
  BRANDING_PICK_COLOR_CLICK = 'LEGAL_ENTITIES.BRANDING_PICK_COLOR_CLICK',
  CREATE_BRANDING_DIALOG_SAVE_CLICK = 'LEGAL_ENTITIES.CREATE_BRANDING_DIALOG_SAVE_CLICK',
  CREATE_BRANDING_DIALOG_CANCEL_CLICK = 'LEGAL_ENTITIES.CREATE_BRANDING_DIALOG_CANCEL_CLICK',
  EDIT_BRANDING_DIALOG_SAVE_CLICK = 'LEGAL_ENTITIES.EDIT_BRANDING_DIALOG_SAVE_CLICK',
  EDIT_BRANDING_DIALOG_CANCEL_CLICK = 'LEGAL_ENTITIES.EDIT_BRANDING_DIALOG_CANCEL_CLICK',
  BRANDING_DIALOG_INHERIT_FROM_PARENT_CLICK = 'LEGAL_ENTITIES.BRANDING_DIALOG_INHERIT_FROM_PARENT_CLICK',
  BRANDING_DIALOG_PREVIEW_EMAIL_TAB_CLICK = 'LEGAL_ENTITIES.BRANDING_DIALOG_PREVIEW_EMAIL_TAB_CLICK',
  BRANDING_DIALOG_PREVIEW_REPORT_TAB_CLICK = 'LEGAL_ENTITIES.BRANDING_DIALOG_PREVIEW_REPORT_TAB_CLICK',
  BRANDING_DIALOG_PREVIEW_SITE_TAB_CLICK = 'LEGAL_ENTITIES.BRANDING_DIALOG_PREVIEW_SITE_TAB_CLICK',
  // Hierarchy events
  OPEN_EDIT_HIERARCHY_DIALOG_CLICK = 'LEGAL_ENTITIES.OPEN_EDIT_HIERARCHY_DIALOG_CLICK',
  EDIT_HIERARCHY_SAVE_CLICK = 'LEGAL_ENTITIES.EDIT_HIERARCHY_SAVE_CLICK',
  EDIT_HIERARCHY_CANCEL_CLICK = 'LEGAL_ENTITIES.EDIT_HIERARCHY_CANCEL_CLICK',
}
