import { validateRegExpression } from '../../../components/ClassifierTester/ClassifierTesterUtils';
import { getFixedT } from '../translations';
import { isNil } from 'lodash';

const t = getFixedT('form.validation');

const validateRegex = (regexToTest: string) => {
  try {
    validateRegExpression(regexToTest);
    return false;
  } catch (error) {
    return true;
  }
};

export const validateNumber = (input: string): boolean => {
  const numberRegex = /^-?\d+$/;

  return numberRegex.test(input);
};

export const validatePositiveNumber = (input: string): boolean => {
  const numberRegex = /^\d+$/;

  return numberRegex.test(input);
};

export function isClassifierNameValid(name = '') {
  const pattern = new RegExp(/^\w[\w\-+():'./]+(?: {1,3}[\w\-+():'./]+){0,}$/);
  const isValid = pattern.test(name);

  if (!isValid) {
    return t('invalidName');
  }
}

export const requiredFieldValidator = (fieldValue: string | number) => {
  if (typeof fieldValue === 'string') {
    return !fieldValue?.trim() && t('required');
  }

  return !fieldValue && t('required');
};

export const requiredSupportTermFieldsValidator = (fieldValue: string | number) => {
  if (typeof fieldValue === 'string') {
    return !fieldValue?.trim() && t('required');
  }

  return isNil(fieldValue) && t('required');
};

export const numberFieldValidator = (fieldValue: string) => !validateNumber(fieldValue) && t('invalidNumbers');
export const positiveNumberFieldValidator = (fieldValue: string) =>
  !validatePositiveNumber(fieldValue) && t('invalidNumbers');

export const regexPatternValidator = (regex: string) => validateRegex(regex) && t('invalidRegex');

export const requiredNumberValidator = (number: string) => {
  const isRequiredValid = requiredSupportTermFieldsValidator(number);
  const isNumberValid = numberFieldValidator(number);

  if (isRequiredValid || isNumberValid) {
    return isRequiredValid || isNumberValid;
  }

  return false;
};

export const requiredAndPositiveNumberValidator = (number: string) => {
  const isRequiredValid = requiredFieldValidator(number);
  const isNumberValid = positiveNumberFieldValidator(number);

  if (isRequiredValid || isNumberValid) {
    return isRequiredValid || isNumberValid;
  }

  return false;
};
