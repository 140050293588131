import React, { FC } from 'react';
import { BigidToggleButton, BigidWidgetContainer } from '@bigid-ui/components';
import { DataCoverageCharts } from './charts/DataCoverageCharts';

export interface DataCoverageWidgetProps {
  title: string;
}

export const DataCoverageWidget: FC<DataCoverageWidgetProps> = ({ title }) => {
  const onToggleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    console.log('Got new value', value);
  };

  return (
    <BigidWidgetContainer
      content={<DataCoverageCharts />}
      titleConfig={{ title }}
      actionsSlot={
        <>
          {/* // TODO: POC Code, to be replaced with real widget actions */}
          <BigidToggleButton
            onChange={onToggleChange}
            initialSelected="volume"
            size="small"
            toggleButtons={[
              { value: 'volume', label: 'Volume' },
              { value: 'amount', label: 'Amount' },
            ]}
          />
        </>
      }
    />
  );
};
