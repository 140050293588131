export type LoginOverride = {
  name?: string;
  isPermitted: () => boolean | Promise<boolean>;
  isFinal?: boolean;
  action: () => void;
  disableInPages?: string[];
};

export type ResolvedLoginOverride = {
  name?: string;
  isPermitted: boolean;
  isFinal?: boolean;
  action: () => void;
};

export enum LoginStrategies {
  AUTH0 = 'auth0',
  SAML = 'saml',
  DEFAULT = 'default',
}
