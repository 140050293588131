import React from 'react';
import { Stack } from '@mui/material';
import { DATA_MINIMIZATION_PERMISSIONS } from '@bigid/permissions';
import {
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidCaption,
  BigidColorsV2,
  BigidFieldFilter,
  BigidGroupedAvatar,
  BigidLink,
  ToolbarAction,
  ToolbarActionType,
} from '@bigid-ui/components';
import {
  BigidDataSourceIcon,
  BigidExportIcon,
  BigidQuickDeleteIcon,
  BigidClearIcon,
  BigidWarningFilledIcon,
  DataSourceIconByDsType,
} from '@bigid-ui/icons';
import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';

import { formatNumber } from '../../../utilities/numericDataConverter';
import { $state } from '../../../services/angularServices';
import { isPermitted } from '../../../services/userPermissionsService';
import { CONFIG } from '../../../../config/common';
import { getItOwnersOptions } from '../../DataSources/DataSourcesService';
import { getApplicationPreference } from '../../../services/appPreferencesService';

import { getFixedT } from '../translations';
import { DataSourceDeletionModel, State } from './types';
import {
  executeCancel,
  executeDS,
  executeDelete,
  executeExportDS,
  executeExportObjects,
  fetchDSTypes,
  fetchLocations,
} from './helpers';
import { ProgressCell } from './ProgressCell';

const t = getFixedT('DataSource');

export const stateName = 'dataMinimization.dataMinimizationDataSourcesV2';

export const colors: Record<State, string> = {
  [State.completed]: BigidColorsV2.green[300],
  [State.pending]: BigidColorsV2.gray[400],
  [State.queued]: BigidColorsV2.purple[300],
  [State.in_progress]: BigidColorsV2.blue[300],
  [State.failed]: BigidColorsV2.red[300],
  [State.total]: BigidColorsV2.gray[200],
};

export enum events {
  DATA_DELETION_DELETE_CLICK = 'Data_Deletion_Delete_Click',
  DATA_DELETION_EXPORT_OBJECTS_CLICK = 'Data_Deletion_Export_Objects_Click',
  DATA_DELETION_EXPORT_DS_CLICK = 'Data_Deletion_Export_DS_Click',
  DATA_DELETION_CANCEL_CLICK = 'Data_Deletion_Cancel_Click',
  DATA_DELETION_DATA_SOURCES_TAB_V2_OPEN = 'Data_Deletion_Data_Sources_Tab_V2_Open',
  DATA_DELETION_DATA_SOURCES_TAB_OPEN = 'Data_Deletion_Data_Sources_Tab_Open',
}

export const searchConfig: BigidFieldFilter = {
  id: 'name',
  field: 'name',
  operator: 'textSearch',
  value: '',
};

export const filtersConfig: BigidAdvancedToolbarFilterUnion[] = [
  {
    id: 'type',
    title: t('dataSourceType'),
    field: 'type',
    operator: 'in',
    disabled: true,
    isSearchable: true,
    options: [],
    asyncOptionsFetch: async (_, value) => {
      return fetchDSTypes(value);
    },
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
  },
  {
    id: 'last_execution_date',
    type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
    title: t('executions'),
    field: 'last_execution_date',
    disabled: true,
    options: {
      pickersState: {
        dates: {
          from: null,
          until: null,
        },
      },
    },
  } as BigidAdvancedToolbarDateRangeFilter,
  {
    id: 'states',
    title: t('progress'),
    field: 'states',
    operator: 'in',
    disabled: true,
    isSearchable: false,
    options: [],
    asyncOptionsFetch: async () => {
      return Object.values(State)
        .filter(item => item !== State.total)
        .map(v => ({
          id: v,
          displayValue: t(v),
          value: v,
        }));
    },
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
  },
  {
    id: 'location',
    title: t('location'),
    field: 'location',
    operator: 'in',
    disabled: true,
    isSearchable: true,
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    options: [],
    asyncOptionsFetch: (_, value) => {
      return fetchLocations(value);
    },
  },
  {
    id: 'it_data_owners.id',
    title: t('itDataOwners'),
    field: 'it_data_owners.id',
    operator: 'in',
    disabled: true,
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    options: [],
    asyncOptionsFetch: async (_, value) => {
      const owners = await getItOwnersOptions(value);
      return owners.map(owner => ({
        id: owner.id.toString(),
        displayValue: owner.id.toString(),
        value: owner.value,
        isSelected: false,
      }));
    },
  },
];

export const actions: ToolbarAction[] = [
  {
    label: t('dataSource'),
    type: ToolbarActionType.TERTIARY,
    isInline: true,
    hideActionInToolBar: true,
    show: () => true,
    icon: BigidDataSourceIcon,
    execute: executeDS,
  },
  {
    label: t('executeDelete'),
    type: ToolbarActionType.TERTIARY,
    isInline: true,
    show: ({ selectedRowIds }) => isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXECUTE.name) && selectedRowIds.length > 0,
    icon: BigidQuickDeleteIcon,
    execute: executeDelete,
  },
  {
    label: t('executeCancel'),
    type: ToolbarActionType.TERTIARY,
    isInline: true,
    icon: BigidClearIcon,
    execute: executeCancel,
    show: ({ selectedRowIds }) =>
      isPermitted(DATA_MINIMIZATION_PERMISSIONS.CANCEL_REQUEST.name) &&
      getApplicationPreference('ENABLE_CANCEL_DATA_DELETION_REQUEST') &&
      selectedRowIds.length > 0,
  },
  {
    label: t('exportObjects'),
    tooltip: t('exportObjects'),
    type: ToolbarActionType.ACTION_ICON,
    icon: BigidExportIcon,
    placement: 'start',
    isInline: true,
    disable: ({ selectedRowIds }) => selectedRowIds.length === 0,
    show: arg => isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXPORT.name) && !!arg.selectedRows?.length,
    execute: executeExportObjects,
  },
  {
    label: 'Export',
    isGlobal: false,
    type: ToolbarActionType.MENU,
    menuTitle: (<BigidExportIcon style={{ marginTop: '8px' }} />) as unknown as string,
    placement: 'end',
    show: arg => isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXPORT.name) && !arg.selectedRows?.length,
    menuProps: {
      width: 200,
      items: [
        {
          id: '1',
          label: t('exportObjects'),
          onClick: executeExportObjects,
        },
        {
          id: '2',
          label: t('exportDS'),
          onClick: executeExportDS,
        },
      ],
    },
  },
];

export const gridColumnsConfig: BigidGridColumn<DataSourceDeletionModel>[] = [
  {
    title: t('dataSourceName'),
    name: 'name',
    width: 200,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: true,
    getCellValue: ({ name }) => {
      return (
        <BigidLink href={$state.href(CONFIG.states.DATA_MINIMIZATION_OBJECT, { dsName: name })} text={name}></BigidLink>
      );
    },
  },
  {
    title: t('dataSourceType'),
    name: 'type',
    width: 120,
    type: BigidGridColumnTypes.CUSTOM,
    sortingEnabled: true,
    getCellValue: ({ type }) => {
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <DataSourceIconByDsType dataSourceType={type} width={32} />
          {type}
        </Stack>
      );
    },
  },
  {
    title: t('lastExecutionDate'),
    name: 'last_execution_date',
    width: 200,
    type: BigidGridColumnTypes.DATE,
    sortingEnabled: true,
    getCellValue: ({ last_execution_date }) => last_execution_date,
  },
  {
    title: t('deletionProgress'),
    name: 'states',
    width: 200,
    type: BigidGridColumnTypes.CUSTOM,
    sortingEnabled: false,
    getCellValue: ({ states }) => {
      return <ProgressCell states={states} />;
    },
  },
  {
    title: t('total'),
    name: 'states.total',
    width: 120,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    sortingEnabled: true,
    getCellValue: ({ states }) => states?.total && formatNumber(states.total),
  },
  {
    title: t('pending'),
    name: 'states.pending',
    width: 120,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    sortingEnabled: true,
    getCellValue: ({ states }) => states?.pending && formatNumber(states.pending),
  },
  {
    title: t('queued'),
    name: 'states.queued',
    width: 120,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    sortingEnabled: true,
    getCellValue: ({ states }) => states?.queued && formatNumber(states.queued),
  },
  {
    title: t('in_progress'),
    name: 'states.in_progress',
    width: 120,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    sortingEnabled: true,
    getCellValue: ({ states }) => states?.in_progress && formatNumber(states.in_progress),
  },
  {
    title: t('completed'),
    name: 'states.completed',
    width: 120,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    sortingEnabled: true,
    getCellValue: ({ states }) => states?.completed && formatNumber(states.completed),
  },
  {
    title: t('failed'),
    name: 'states.failed',
    width: 120,
    type: BigidGridColumnTypes.NUMBER,
    isHiddenByDefault: true,
    sortingEnabled: true,
    getCellValue: ({ states }) => states?.failed && formatNumber(states.failed),
  },
  {
    title: t('location'),
    name: 'location',
    width: 200,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: true,
    isHiddenByDefault: true,
    getCellValue: props => props.location,
  },
  {
    title: t('dataSourceStatus'),
    name: 'enabled',
    width: 200,
    type: BigidGridColumnTypes.CUSTOM,
    sortingEnabled: true,
    isHiddenByDefault: true,
    getCellValue: ({ enabled }) =>
      enabled === 'yes' ? (
        <>{t('enabled')}</>
      ) : (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <BigidWarningFilledIcon color="warning" />
          <BigidCaption>{t('disabled').toString()}</BigidCaption>
        </Stack>
      ),
  },
  {
    title: t('tags'),
    name: 'tags',
    width: 200,
    type: BigidGridColumnTypes.TAGS,
    sortingEnabled: false,
    isHiddenByDefault: true,
    getCellValue: props => ({
      tags: {
        isEditMode: false,
        value: props.tags?.map(tag => ({ name: tag.tagName, value: tag.tagValue })),
        isDisabled: true,
      },
    }),
  },
  {
    title: t('itDataOwners'),
    name: 'it_data_owners',
    width: 200,
    type: BigidGridColumnTypes.CUSTOM,
    sortingEnabled: false,
    isHiddenByDefault: true,
    getCellValue: ({ it_data_owners: owners }) => {
      const avatars = owners.reduce((ownersAvatars, { id, email, origin }) => {
        return typeof email === 'string' || typeof id === 'string'
          ? [
              ...ownersAvatars,
              {
                userName: email || id,
                ...(origin === 'legacy' ? { color: BigidColorsV2.gray[300] } : {}),
              },
            ]
          : ownersAvatars;
      }, []);

      return <BigidGroupedAvatar avatars={avatars} size="small" />;
    },
  },
];
