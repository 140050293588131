import {
  AggregationType,
  DataFormatAggregationItem,
  AggregationPossesionIdentifier,
  AggregationItemBase,
  SensitivityClassificationAggregationItem,
} from '../catalogDiscoveryTypes';

/**
 * A map needed to preserve the order of legend items no matter how they come from the server side
 */
export const pieChartItemsOrderMap = new Map<AggregationType, AggregationItemBase[]>([
  [
    AggregationType.OBJECT_STATUS,
    [{ aggItemName: AggregationPossesionIdentifier.WITH }, { aggItemName: AggregationPossesionIdentifier.WITHOUT }],
  ],
  //TODO: rearrange the order once @maorpichadze provides the requirements
  [
    AggregationType.SENSITIVITY,
    [
      { aggItemName: SensitivityClassificationAggregationItem.RESTRICTED },
      { aggItemName: SensitivityClassificationAggregationItem.CONFIDENTIAL },
      { aggItemName: SensitivityClassificationAggregationItem.INTERNAL_USE },
      { aggItemName: SensitivityClassificationAggregationItem.PUBLIC },
    ],
  ],
  [
    AggregationType.DATA_FORMAT,
    [
      { aggItemName: DataFormatAggregationItem.STRUCTURED },
      { aggItemName: DataFormatAggregationItem.UNSTRUCTURED },
      { aggItemName: DataFormatAggregationItem.EMAIL },
      { aggItemName: DataFormatAggregationItem.OTHER },
    ],
  ],
]);

export const summaryWidgetsContainerHeight = 167;
export const pieChartWidgetsContainerHeight = 223;
export const gridWidgetHeight = 488;

export const topCategoriesColors = ['#6CD4E0', '#6C9EFF', '#B489FF', '#FF72CF'];

export enum CatalogDiscoveryWidget {
  TOOLBAR_FILTER = 'TOOLBAR_FILTER',
  TOOLBAR_OBJECTS_LIST_REPORT = 'TOOLBAR_OBJECTS_LIST_REPORT',
  FINDINGS_SUMMARY = 'FINDINGS_SUMMARY',
  DUPLICATE_FILES_SUMMARY = 'DUPLICATE_FILES_SUMMARY',
  VIOLATED_POLICIES_SUMMARY = 'VIOLATED_POLICIES_SUMMARY',
  SCANNED_VOLUME_SUMMARY = 'SCANNED_VOLUME_SUMMARY',
  OBJECT_STATUS_PIE_CHART = 'OBJECT_STATUS_PIE_CHART',
  DATA_FORMAT_PIE_CHART = 'DATA_FORMAT_PIE_CHART',
  SENSITIVITY_PIE_CHART = 'SENSITIVITY_PIE_CHART',
  TOP_CATEGORIES_PIE_CHART = 'TOP_CATEGORIES_PIE_CHART',
  DATA_SOURCES_LAYOUT = 'DATA_SOURCES_LAYOUT',
  ATTRIBUTES_LAYOUT = 'ATTRIBUTES_LAYOUT',
}
