import {
  BigidFieldRenderProps,
  BigidFormFieldSideLabelWrapper,
  compareObjectsExceptFunctions,
} from '@bigid-ui/components';
import {
  BigidRichTextEditor,
  markdownToHtml,
  htmlToMarkdown,
  BigidRichTextButtonsType,
} from '@bigid-ui/rich-text-editor';
import { FormControl } from '@mui/material';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { escape } from 'lodash';

interface FormRichTextFieldValue {
  markdown: string;
}
interface FormRichTextFieldProps extends BigidFieldRenderProps {
  value: FormRichTextFieldValue;
}
export const FormRichTextField: FC<FormRichTextFieldProps> = memo(
  ({ label, name, value: { markdown = '' }, setValue, onBlur, misc, isRequired, labelWidth, tooltipText }) => {
    const onChangeHandler = useCallback(
      (html: string) => {
        const value = { markdown: htmlToMarkdown(html) };
        setValue(value);
      },
      [setValue],
    );
    const initialHtml = useMemo(() => markdownToHtml(escape(markdown)), [markdown]);
    const onBlurHandler = useCallback(onBlur, [onBlur]);
    const buttons = useMemo<BigidRichTextButtonsType[]>(
      () => ['bold', 'italic', 'underline', '|', 'link', 'unlink'],
      [],
    );

    return (
      <BigidFormFieldSideLabelWrapper
        id={`bigid-form-field-rich-text-${name}`}
        name={name}
        label={String(label)}
        isRequired={isRequired}
        isSeparatorAfter={misc?.isSeparatorAfter}
        labelWidth={labelWidth}
        tooltipText={tooltipText}
      >
        <FormControl fullWidth={misc?.fullWidth}>
          <BigidRichTextEditor
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            buttons={buttons}
            value={initialHtml}
          />
        </FormControl>
      </BigidFormFieldSideLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);

FormRichTextField.displayName = 'FormRichTextField';
