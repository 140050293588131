import React, { FC, useEffect, useState } from 'react';
import {
  BigidBody1,
  BigidDialog,
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
  BigidLoader,
  BigidSeverityBadge,
  BigidTextField,
  BigidTooltip,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import {
  IdWithName,
  PrivacyRiskCaseResponse,
  createAsset,
  getApplications,
  getRiskMatrix,
} from './RiskRegisterService';
import { RiskMatrixMetadata } from '../Risks/RiskMatrixDefaults';
import { notificationService } from '../../services/notificationService';
import { BigidInfoSmallIcon } from '@bigid-ui/icons';
import { RiskLevelInfo } from '../Risks/components/RiskLevelInfo';
import { useLocalTranslation } from './translations';
import { ApplicationEntity } from '../ApplicationSetup/types';
import { noop } from 'lodash';

const DialogContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const AssetSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const RisksSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const RisksContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px',
  borderRadius: '4px',
  background: theme?.vars?.palette?.bigid?.gray125,
}));

const RiskSettings = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '16px',
});

const RiskLevelTitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

const InputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',

  '& > div': {
    width: '177px',
  },
});

const EqualSign = styled(BigidBody1)(({ theme }) => ({
  color: theme?.vars?.tokens?.bigid?.foregroundPrimary,
  fontWeight: 400,
  lineHeight: '24px',
  paddingBottom: '9px',
}));

const MandatoryFieldWrapper = styled('div')({
  display: 'flex',
  gap: '4px',
});

const Label = styled(BigidBody1)(({ theme }) => ({
  color: theme?.vars?.tokens?.bigid?.foregroundPrimary,
  fontWeight: 400,
  lineHeight: '24px',
}));

const MandatoryAsterisk = styled(BigidBody1)(({ theme }) => ({
  color: theme?.vars?.palette?.bigid?.red600,
  fontWeight: 400,
  lineHeight: '24px',
}));

export interface EditRiskCasePayloadData {
  asset: IdWithName;
  newProbability: number;
  newImpact: number;
  matrixSize: number;
  reason?: string;
}

export interface EditRiskCaseDialogProps {
  isOpen: boolean;
  onClose: () => void;
  editRiskCaseMetadata: Pick<PrivacyRiskCaseResponse, 'riskLevel' | 'probability' | 'impact' | 'asset'>;
  onSaveButtonClick: (payload: EditRiskCasePayloadData) => void;
}

export const EditRiskCaseDialog: FC<EditRiskCaseDialogProps> = ({
  isOpen,
  onClose,
  editRiskCaseMetadata,
  onSaveButtonClick,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [riskMatrixMetadata, setRiskMatrixMetadata] = useState<RiskMatrixMetadata | undefined>();
  const [shouldDisplayRiskLevelExplanationPopup, setShouldDisplayRiskLevelExplanationPopup] = useState(false);
  const [newProbabilityValue, setNewProbabilityValue] = useState<number>(editRiskCaseMetadata?.probability?.value);
  const [newImpactValue, setNewImpactValue] = useState<number>(editRiskCaseMetadata?.impact?.value);
  const [assets, setAssets] = useState<ApplicationEntity[]>([]);
  const [selectedAssetValue, setSelectedAssetValue] = useState<BigidDropdownOption | undefined>(
    editRiskCaseMetadata?.asset?.id
      ? {
          id: editRiskCaseMetadata?.asset?.id,
          value: editRiskCaseMetadata?.asset?.id,
          displayValue: editRiskCaseMetadata?.asset?.name,
        }
      : undefined,
  );
  const [reasonChangedValue, setReasonChangedValue] = useState('');
  const { t } = useLocalTranslation('EditRiskCaseDialog');

  const fetchRiskMatrixDataAndAssets = async () => {
    try {
      const matrix = await getRiskMatrix();
      setRiskMatrixMetadata(matrix);
      const applications = await getApplications();
      setAssets(applications);
      setIsLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      notificationService.error(t('Errors.fetchDataError'));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRiskMatrixDataAndAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapDropdownOptions = (key: keyof RiskMatrixMetadata): BigidDropdownOption[] => {
    return Object.entries(riskMatrixMetadata?.[key]).map(([key, value]) => {
      return {
        id: key,
        value: key,
        displayValue: value,
      };
    });
  };

  const createAssetManually = async (newAssetName: string): Promise<BigidDropdownOption> => {
    try {
      const data = await createAsset(newAssetName);
      return { id: data._id, value: data._id, displayValue: newAssetName };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      notificationService.error(t('Errors.creatingAssetError'));
      return { id: '', value: '', displayValue: '' };
    }
  };

  const mapProbabilityDropdownValue = (): BigidDropdownValue => {
    const probabilityObject = Object.entries(riskMatrixMetadata?.probabilityLabels).find(
      ([key]) => newProbabilityValue.toString() === key,
    );
    return [
      {
        id: probabilityObject ? probabilityObject[0] : editRiskCaseMetadata?.probability?.value.toString(),
        value: probabilityObject ? parseInt(probabilityObject[0]) : editRiskCaseMetadata?.probability?.value,
        displayValue: probabilityObject ? probabilityObject[1] : editRiskCaseMetadata?.probability?.name,
      },
    ];
  };

  const mapImpactDropdownValue = (): BigidDropdownValue => {
    const impactObject = Object.entries(riskMatrixMetadata?.impactLabels).find(
      ([key]) => newImpactValue.toString() === key,
    );
    return [
      {
        id: impactObject ? impactObject[0] : editRiskCaseMetadata?.impact?.value.toString(),
        value: impactObject ? parseInt(impactObject[0]) : editRiskCaseMetadata?.impact?.value,
        displayValue: impactObject ? impactObject[1] : editRiskCaseMetadata?.impact?.name,
      },
    ];
  };

  return (
    <BigidDialog
      title={t('Labels.editCase')}
      isOpen={isOpen}
      onClose={onClose}
      buttons={[
        {
          onClick: onClose,
          component: TertiaryButton,
          text: t('Labels.cancel'),
          size: 'medium',
          alignment: 'right',
        },
        {
          onClick: () => {
            onSaveButtonClick({
              asset: { id: selectedAssetValue?.id ?? '', name: selectedAssetValue?.displayValue ?? '' },
              newProbability: newProbabilityValue,
              newImpact: newImpactValue,
              matrixSize: riskMatrixMetadata.matrixSize,
              reason: reasonChangedValue,
            });
          },
          component: PrimaryButton,
          text: t('Labels.save'),
          size: 'medium',
          alignment: 'right',
          disabled: !selectedAssetValue?.id,
        },
      ]}
    >
      {isLoading && <BigidLoader />}
      {!isLoading && (
        <DialogContent>
          <AssetSection>
            <MandatoryFieldWrapper>
              <Label>{t('Labels.asset')}</Label>
              <MandatoryAsterisk>{'*'}</MandatoryAsterisk>
            </MandatoryFieldWrapper>
            <BigidDropdown
              size="large"
              isCreatable
              isSearchable
              onCreate={(value: string) => {
                return createAssetManually(value);
              }}
              placeholder={t('Labels.selectAssetPlaceholder')}
              options={assets.map(asset => {
                return { id: asset._id, value: asset._id, displayValue: asset.name };
              })}
              value={selectedAssetValue ? [selectedAssetValue] : []}
              onSelect={(options: BigidDropdownOption[]) => {
                setSelectedAssetValue({
                  id: options[0]?.id,
                  value: options[0]?.value,
                  displayValue: options[0]?.displayValue,
                });
              }}
            />
          </AssetSection>
          <RisksSection>
            <RiskLevelTitleWrapper>
              <Label>{t('Labels.riskLevel')}</Label>
              <BigidTooltip title={t('Labels.riskMatrixTooltip')}>
                <span>
                  <BigidInfoSmallIcon
                    onClick={() => setShouldDisplayRiskLevelExplanationPopup(true)}
                    style={{ marginTop: '6px' }}
                  />
                </span>
              </BigidTooltip>
            </RiskLevelTitleWrapper>
            <RisksContainer>
              <RiskSettings>
                <InputWrapper>
                  <Label>{`${t('Labels.current')} ${riskMatrixMetadata?.probabilityLabel}`}</Label>
                  <BigidTextField
                    size="large"
                    onChange={() => noop}
                    disabled
                    value={editRiskCaseMetadata.probability.name}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{`${t('Labels.current')} ${riskMatrixMetadata?.impactLabel}`}</Label>
                  <BigidTextField
                    size="large"
                    onChange={() => noop}
                    disabled
                    value={editRiskCaseMetadata.impact.name}
                  />
                </InputWrapper>
                <EqualSign>{'='}</EqualSign>
                <BigidSeverityBadge
                  customLabel={editRiskCaseMetadata.riskLevel.name}
                  customColor={editRiskCaseMetadata.riskLevel.color}
                  level={'custom'}
                  hasBackground={true}
                  size="medium"
                />
              </RiskSettings>
              <RiskSettings>
                <InputWrapper>
                  <MandatoryFieldWrapper>
                    <Label>{`${t('Labels.new')} ${riskMatrixMetadata?.probabilityLabel}`}</Label>
                  </MandatoryFieldWrapper>
                  <BigidDropdown
                    size="large"
                    options={mapDropdownOptions('probabilityLabels')}
                    onSelect={(options: BigidDropdownOption[]) => {
                      setNewProbabilityValue(parseInt(options[0]?.value));
                    }}
                    value={mapProbabilityDropdownValue()}
                  />
                </InputWrapper>
                <InputWrapper>
                  <MandatoryFieldWrapper>
                    <Label>{`${t('Labels.new')} ${riskMatrixMetadata?.impactLabel}`}</Label>
                  </MandatoryFieldWrapper>
                  <BigidDropdown
                    size="large"
                    options={mapDropdownOptions('impactLabels')}
                    onSelect={(options: BigidDropdownOption[]) => {
                      setNewImpactValue(parseInt(options[0]?.value));
                    }}
                    value={mapImpactDropdownValue()}
                  />
                </InputWrapper>
                <EqualSign>{'='}</EqualSign>
                {newProbabilityValue * newImpactValue > 0 && (
                  <BigidSeverityBadge
                    customLabel={riskMatrixMetadata?.cellData[newProbabilityValue * newImpactValue].riskLevelLabel}
                    customColor={riskMatrixMetadata?.cellData[newProbabilityValue * newImpactValue].color}
                    level={'custom'}
                    hasBackground={true}
                    size="medium"
                  />
                )}
              </RiskSettings>
            </RisksContainer>
          </RisksSection>
          <BigidTextField
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setReasonChangedValue(event.target.value);
            }}
            multiline
            rows={4}
            placeholder={t('Labels.reasonFieldPlaceHolder')}
          />
          {shouldDisplayRiskLevelExplanationPopup && riskMatrixMetadata && (
            <RiskLevelInfo
              isOpen={shouldDisplayRiskLevelExplanationPopup}
              riskMatrix={riskMatrixMetadata}
              onClose={() => setShouldDisplayRiskLevelExplanationPopup(false)}
              fixedHeight={615}
            />
          )}
        </DialogContent>
      )}
    </BigidDialog>
  );
};
