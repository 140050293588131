import { httpService } from '../../services/httpService';
import { SensitivityClassificationData, SensitivityClassificationDataRow } from './SensitivityClassification';

interface SensitivityClassificationsResponse {
  data: {
    scConfigs: SensitivityClassificationDataRow[];
    totalCount: number;
  };
}

interface SensitivityClassificationResponse {
  data: SensitivityClassificationData;
}

export const getSensitivityClassifications = async (query: string) => {
  return httpService.fetch<SensitivityClassificationsResponse>(`aci/sc/configs?${query}`).then(({ data }) => data.data);
};

export const getSensitivityClassificationsWithNoPagination = async () => {
  return httpService
    .fetch<SensitivityClassificationsResponse>(`aci/sc/configs?skip=0&limit=100`)
    .then(({ data }) => data.data);
};

export const getSensitivityClassification = async (id: string) => {
  return httpService.fetch<SensitivityClassificationResponse>(`aci/sc/configs/${id}`).then(({ data }) => data.data);
};

export const createSensitivityClassification = async (sc: SensitivityClassificationData) => {
  const classificationsDto = sc.classifications.map(classificationLevel => ({
    name: classificationLevel.name.trim(),
    priority: classificationLevel.priority,
    queryObj: classificationLevel.queryObject,
    query: classificationLevel.queryString,
  }));
  return httpService
    .post(`aci/sc/configs`, { ...sc, name: sc.name.trim(), classifications: classificationsDto })
    .then(({ data }) => data.data);
};

export const updateSensitivityClassification = async (
  sc: SensitivityClassificationData,
  id: string,
): Promise<SensitivityClassificationData> => {
  const { createdAt, modifiedAt, ...scDto } = sc;
  const classificationsDto = sc.classifications.map(classificationLevel => ({
    name: classificationLevel.name.trim(),
    priority: classificationLevel.priority,
    queryObj: classificationLevel.queryObject ? classificationLevel.queryObject : classificationLevel.queryObj,
    query: classificationLevel.queryString ? classificationLevel.queryString : classificationLevel.query,
    levelId: classificationLevel.levelId,
  }));
  return httpService
    .put(`aci/sc/configs`, { ...scDto, name: scDto.name.trim(), id, classifications: classificationsDto })
    .then(({ data }) => data.data);
};

export const deleteSensitivityClassification = async (id: string) => {
  return httpService.delete(`aci/sc/configs/${id}`);
};

export const applySensitivityClassification = async (id: string) => {
  return httpService.post(`aci/sc/tagging/${id}`);
};
