import React, { FC, useCallback, useContext, useMemo } from 'react';
import { WithDataAid } from '../DataCatalog/NewDataCatalog/types';
import { styled } from '@mui/material';
import {
  BigidAdvancedToolbarFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { ApiParam } from './api';
import { searchField } from './activityMonitoringService';
import { ActivityMonitoringDetailsContext } from './hooks/ActivityMonitoringDetailsContext';
import { ReducerActions } from './hooks/ActivityMonitoringDetailsReducer';
import { useLocalTranslation } from './translations';

const ToolbarContainer = styled('div')`
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const ActivityMonitoringDetailsToolbar: FC<WithDataAid> = ({ dataAid = 'ActivityMonitoringDetails' }) => {
  const { t } = useLocalTranslation('ActivityMonitoringGrid');
  const { dispatch } = useContext(ActivityMonitoringDetailsContext);

  const handleFilterChange = useCallback(
    async (filters: BigidAdvancedToolbarFilterUnion[]) => {
      dispatch({
        type: ReducerActions.UPDATE_FILTERS,
        payload: {
          filters: filters,
        },
      });
    },
    [dispatch],
  );

  const componentToRender = useMemo(() => {
    return (
      <BigidAdvancedToolbarFilter
        shouldAsyncFiltersReload
        dataAid={generateDataAid(dataAid, ['toolbar'])}
        filters={[
          {
            id: ApiParam.ACCOUNT_NAME,
            type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
            title: t('activityMonitoringGridColumns.accountName'),
            field: '',
            operator: 'equal',
            options: [],
            asyncOptionsFetch(_filters: BigidAdvancedToolbarFilterUnion[], searchText: string) {
              return new Promise(resolve =>
                searchField(ApiParam.ACCOUNT_NAME, searchText).then(data =>
                  resolve(
                    data.map(option => ({
                      id: option,
                      value: option,
                      displayValue: option,
                    })),
                  ),
                ),
              );
            },
          },
          {
            id: ApiParam.ACCOUNT_IDENTIFIER,
            type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
            title: t('activityMonitoringGridColumns.accountIdentifier'),
            field: '',
            operator: 'equal',
            options: [],
            asyncOptionsFetch(_filters: BigidAdvancedToolbarFilterUnion[], searchText: string) {
              return new Promise(resolve =>
                searchField(ApiParam.ACCOUNT_IDENTIFIER, searchText).then(data =>
                  resolve(
                    data.map(option => ({
                      id: option,
                      value: option,
                      displayValue: option,
                    })),
                  ),
                ),
              );
            },
          },
          {
            id: ApiParam.LAST_ACTIVITY,
            type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
            title: t('activityMonitoringGridColumns.lastActivity'),
            field: '',
            operator: 'equal',
            options: {
              currentRangeOptionSelected: 'none',
              pickersState: {
                currentMode: 'from',
                dates: {
                  from: null,
                  until: null,
                },
              },
            },
          },
          {
            id: ApiParam.OPERATION,
            type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
            title: t('activityMonitoringGridColumns.operation'),
            field: '',
            operator: 'in',
            options: ['Read', 'Write', 'Copy'].map(option => ({
              id: option,
              value: option,
              displayValue: option,
            })),
          },
        ]}
        onFiltersChange={handleFilterChange}
      />
    );
  }, [dataAid, handleFilterChange, t]);

  return <ToolbarContainer>{componentToRender}</ToolbarContainer>;
};
