import { BigidBody1, BigidDialog, TertiaryButton } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React from 'react';
import { RiskMatrixMetadata } from '../RiskMatrixDefaults';
import { RiskMatrix } from './RiskMatrix';

const ImageContainer = styled('div')({
  display: 'flex',
});

export interface RiskLevelInfoProps {
  isOpen: boolean;
  riskMatrix: RiskMatrixMetadata;
  onClose: () => void;
  fixedHeight?: boolean | number;
}

export const RiskLevelInfo: React.FC<RiskLevelInfoProps> = ({ isOpen, riskMatrix, onClose, fixedHeight }) => {
  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onClose}
      title={riskMatrix.levelLabel}
      borderTop
      maxWidth={'md'}
      borderBottom
      fixedHeight={fixedHeight}
      buttons={[
        {
          component: TertiaryButton,
          text: 'Close',
          size: 'medium',
          alignment: 'right',
          onClick: onClose,
        },
      ]}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <BigidBody1>
          {`The ${riskMatrix.levelLabel} measures the overall severity of the risk’s occurrence, determined by the provided ${riskMatrix.probabilityLabel} and ${riskMatrix.impactLabel}.`}
        </BigidBody1>
        <ImageContainer>
          <RiskMatrix {...riskMatrix} />
        </ImageContainer>
      </div>
    </BigidDialog>
  );
};
