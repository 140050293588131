import { Reducer, useReducer, useCallback, useRef, useEffect } from 'react';
import {
  BigidAdvancedToolbarFilterUnion,
  AdvancedToolbarOverrideValue,
  AdvancedToolbarOverrideValueStrategy,
} from '@bigid-ui/components';
import { SavedFilter } from './toolbar/actions/SaveQuery/saveQueryService';
import {
  AggregationType,
  AggregationItemBase,
  AttributesGridAggregationItem,
  CatalogDiscoveryGuidedTourStatus,
} from './catalogDiscoveryTypes';
import { setUserPreferences, getUserPreferences } from './catalogDiscoveryService';
import {
  getAppliedFilterValues,
  getAttributeNameAppliedFilter,
  getWidgetAppliedFilter,
  parseFilterOutputToQueryString,
  getIsOverrideFilterEqualToCurrentOne,
} from './filter/utils';
import { omit } from 'lodash';
import { useFilterConfig } from './filter/useFilterConfig';
import { dataFetchingTimeoutThreshold } from './config/common';
import { analyticsService } from '../../services/analyticsService';
import { BIGID_BI_EVENTS } from '../../config/BigIdBiEvents';
import { getToolbarValuesFromCatalogFilters } from './catalogToDiscoveryUtils/catalogToDiscoveryUtils';
import { CatalogDiscoveryWidget } from './config/widgets';
import { sendPageInitialisationTimeBiAnalytics, sendWidgetFilteredDataLoadingTimeBiAnalytics } from './utils/analytics';
import { showPageInitTimeoutNotification, showFilterRestorationFailureNotification } from './utils/notifications';
import { GetViewInDataOverviewFiltersResult } from '../DataCatalog/NewDataCatalog/types';

enum UseCatalogDiscoveryAction {
  PAGE_READY_TO_INIT = 'PAGE_READY_TO_INIT',
  PAGE_USER_PREFERENCES_RESTORED = 'PAGE_USER_PREFERENCES_RESTORED',
  PAGE_INIT_FULFILLED = 'PAGE_INIT_FULFILLED',
  FETCH_CONFIG_FULFILLED = 'FETCH_CONFIG_FULFILLED',
  FETCH_CONFIG_REJECTED = 'FETCH_CONFIG_REJECTED',
  FETCH_DATA_STATUS_UPDATE = 'FETCH_DATA_STATUS_UPDATE',
  FETCH_DATA_STATUS_DELAYED = 'FETCH_DATA_STATUS_DELAYED',
  FETCH_DATA_STATUS_FULFILLED = 'FETCH_DATA_STATUS_FULFILLED',
  SELECT_SAVED_QUERY = 'SELECT_SAVED_QUERY',
  CREATE_SAVED_QUERY = 'CREATE_SAVED_QUERY',
  UPDATE_SAVED_QUERY = 'UPDATE_SAVED_QUERY',
  DELETE_SAVED_QUERY = 'DELETE_SAVED_QUERY',
  UPDATE_FILTER = 'UPDATE_FILTER',
  SET_FILTER_DELAYED_STATUS = 'SET_FILTER_DELAYED_STATUS',
  SET_TIMEOUT_NOTIFICATION_STATUS = 'SET_TIMEOUT_NOTIFICATION_STATUS',
  UPDATE_APPLIED_FILTER = 'UPDATE_APPLIED_FILTER',
  UPDATE_GUIDED_TOUR_STATUS = 'UPDATE_GUIDED_TOUR_STATUS',
}

export type UseCatalogDiscoveryDataFetchStatus = {
  isBusy: boolean;
  dataFetchStatusChangeTimestamp?: number;
};

export type UseCatalogDiscoveryWidgetDataFetchStatus = {
  [key in CatalogDiscoveryWidget]?: UseCatalogDiscoveryDataFetchStatus;
} & {
  USER_PREFERENCES?: UseCatalogDiscoveryDataFetchStatus;
};

export type UseCatalogDiscoveryAppliedFilter = {
  strategy: AdvancedToolbarOverrideValueStrategy;
  values: AdvancedToolbarOverrideValue[];
};

export interface UseCatalogDiscoveryState {
  query: string;
  filter: BigidAdvancedToolbarFilterUnion[];
  isOnboardingAcknowledged: boolean;
  appliedFilter: UseCatalogDiscoveryAppliedFilter;
  savedQueries: SavedFilter[]; //NOTE: replace with SavedQuery[] once the parser is ready
  currentSavedQuery: SavedFilter; //NOTE: replace with SavedQuery once the parser is ready
  isFilterChanged: boolean;
  isToolbarFilterDelayed: boolean;
  isDataFetching: boolean;
  isDataFetchingDelayed: boolean;
  isTimeoutNotificationShown: boolean;
  isPageReadyToInitialise: boolean;
  pageInitialisationStatus: UseCatalogDiscoveryDataFetchStatus;
  guidedTourStatus: CatalogDiscoveryGuidedTourStatus;
  widgetsDataFetchingStatus: UseCatalogDiscoveryWidgetDataFetchStatus;
}

export type UseCurationStatePayload = {
  action: UseCatalogDiscoveryAction;
  data?: Partial<UseCatalogDiscoveryState>;
};

export interface UseCatalogDiscoveryResponse extends Partial<UseCatalogDiscoveryState> {
  onEmptyStateComputed: (isEmpty: boolean) => void;
  onGuidedTourStart: () => void;
  onGuidedTourFinished: (originStatus: CatalogDiscoveryGuidedTourStatus) => void;
  onDataFetchStatusChange: (widget: CatalogDiscoveryWidget, isBusy: boolean) => void;
  onWidgetFilterChange: (filterId?: string | number, aggItem?: AggregationItemBase[], shouldUndo?: boolean) => void;
  onAttributesWidgetFilterChange: (aggItem?: AttributesGridAggregationItem[]) => void;
  onToolbarFilterChange: (filter: BigidAdvancedToolbarFilterUnion[], isBusy: boolean) => void;
  onToolbarFilterReloaded: () => void;
  onToolbarFilterInitialise: (isBusy: boolean, isFailedToInitialise: boolean) => void;
  onToolbarFilterTimeoutReached: () => void;
  onSavedQuerySelect: (savedQuery: SavedFilter) => void;
  onSavedQueryCreate: (savedQuery: SavedFilter) => void;
  onSavedQueryUpdate: (savedQuery: SavedFilter) => void;
  onSavedQueryDelete: (savedQuery: SavedFilter) => void;
}

const getCatalogDiscoveryInitialState = (): UseCatalogDiscoveryState => {
  return {
    query: '',
    filter: [],
    appliedFilter: null,
    savedQueries: [],
    isOnboardingAcknowledged: true,
    currentSavedQuery: null,
    isFilterChanged: false,
    isToolbarFilterDelayed: false,
    isDataFetching: false,
    isDataFetchingDelayed: false,
    isTimeoutNotificationShown: false,
    pageInitialisationStatus: {
      isBusy: true,
      dataFetchStatusChangeTimestamp: new Date().getTime(),
    },
    isPageReadyToInitialise: false,
    guidedTourStatus: CatalogDiscoveryGuidedTourStatus.NOT_STARTED,
    widgetsDataFetchingStatus: {
      [CatalogDiscoveryWidget.TOOLBAR_FILTER]: {
        isBusy: true,
        dataFetchStatusChangeTimestamp: new Date().getTime(),
      },
      [CatalogDiscoveryWidget.FINDINGS_SUMMARY]: {
        isBusy: false,
      },
      [CatalogDiscoveryWidget.DUPLICATE_FILES_SUMMARY]: {
        isBusy: false,
      },
      [CatalogDiscoveryWidget.SCANNED_VOLUME_SUMMARY]: {
        isBusy: false,
      },
      [CatalogDiscoveryWidget.VIOLATED_POLICIES_SUMMARY]: {
        isBusy: false,
      },
      [CatalogDiscoveryWidget.OBJECT_STATUS_PIE_CHART]: {
        isBusy: false,
      },
      [CatalogDiscoveryWidget.DATA_FORMAT_PIE_CHART]: {
        isBusy: false,
      },
      [CatalogDiscoveryWidget.SENSITIVITY_PIE_CHART]: {
        isBusy: false,
      },
      [CatalogDiscoveryWidget.TOP_CATEGORIES_PIE_CHART]: {
        isBusy: false,
      },
      USER_PREFERENCES: {
        isBusy: true,
        dataFetchStatusChangeTimestamp: new Date().getTime(),
      },
    },
  };
};

const reducer: Reducer<UseCatalogDiscoveryState, UseCurationStatePayload> = (state, { action, data }) => {
  switch (action) {
    case UseCatalogDiscoveryAction.UPDATE_GUIDED_TOUR_STATUS: {
      const { guidedTourStatus } = data;

      return {
        ...state,
        guidedTourStatus,
      };
    }
    case UseCatalogDiscoveryAction.PAGE_READY_TO_INIT: {
      return {
        ...state,
        isPageReadyToInitialise: true,
      };
    }
    case UseCatalogDiscoveryAction.PAGE_INIT_FULFILLED: {
      const { guidedTourStatus } = data;
      const dataFetchStatusChangeTimestamp = new Date().getTime();

      sendPageInitialisationTimeBiAnalytics(
        dataFetchStatusChangeTimestamp - state.pageInitialisationStatus.dataFetchStatusChangeTimestamp,
      );

      return {
        ...state,
        pageInitialisationStatus: {
          isBusy: false,
          dataFetchStatusChangeTimestamp,
        },
        guidedTourStatus,
      };
    }
    case UseCatalogDiscoveryAction.FETCH_CONFIG_FULFILLED: {
      const { savedQueries } = data;

      return {
        ...state,
        savedQueries,
      };
    }
    case UseCatalogDiscoveryAction.FETCH_CONFIG_REJECTED: {
      return {
        ...state,
        savedQueries: [],
      };
    }
    case UseCatalogDiscoveryAction.FETCH_DATA_STATUS_UPDATE: {
      const { widgetsDataFetchingStatus } = data;
      const widgetsDataFetchingStatusUpdated = {
        ...state.widgetsDataFetchingStatus,
        ...widgetsDataFetchingStatus,
      };
      const isDataFetching = Object.values(widgetsDataFetchingStatusUpdated).some(({ isBusy }) => isBusy);
      const isDataFetchingDelayed = state.isDataFetchingDelayed && isDataFetching;
      const isTimeoutNotificationShown = state.isTimeoutNotificationShown && isDataFetching;
      const isToolbarFilterDelayed =
        state.isToolbarFilterDelayed && state.widgetsDataFetchingStatus[CatalogDiscoveryWidget.TOOLBAR_FILTER].isBusy;

      sendWidgetFilteredDataLoadingTimeBiAnalytics(
        omit(state.widgetsDataFetchingStatus, ['USER_PREFERENCES']),
        omit(widgetsDataFetchingStatusUpdated, ['USER_PREFERENCES']),
      );

      return {
        ...state,
        widgetsDataFetchingStatus: widgetsDataFetchingStatusUpdated,
        isDataFetching,
        isDataFetchingDelayed,
        isTimeoutNotificationShown,
        isToolbarFilterDelayed,
      };
    }
    case UseCatalogDiscoveryAction.FETCH_DATA_STATUS_DELAYED: {
      return {
        ...state,
        isDataFetchingDelayed: true,
      };
    }
    case UseCatalogDiscoveryAction.FETCH_DATA_STATUS_FULFILLED: {
      return {
        ...state,
        isDataFetching: false,
        isDataFetchingDelayed: false,
      };
    }
    case UseCatalogDiscoveryAction.SELECT_SAVED_QUERY: {
      const { currentSavedQuery } = data;

      const updatedState = {
        ...state,
        currentSavedQuery,
        isFilterChanged: false,
      };

      updatedState.appliedFilter = {
        strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITHOUT_RELOAD_OVERRIDE,
        values: currentSavedQuery ? currentSavedQuery.filter : [],
      };

      return updatedState;
    }
    case UseCatalogDiscoveryAction.CREATE_SAVED_QUERY: {
      const { currentSavedQuery } = data;

      return {
        ...state,
        currentSavedQuery,
        isFilterChanged: false,
        savedQueries: [...state.savedQueries, currentSavedQuery],
      };
    }
    case UseCatalogDiscoveryAction.UPDATE_SAVED_QUERY: {
      const { currentSavedQuery } = data;

      return {
        ...state,
        isFilterChanged: false,
        currentSavedQuery,
        savedQueries: state.savedQueries.map(savedQuery => {
          if (currentSavedQuery.id === savedQuery.id) {
            return { ...savedQuery, ...currentSavedQuery };
          }

          return savedQuery;
        }),
      };
    }
    case UseCatalogDiscoveryAction.DELETE_SAVED_QUERY: {
      const { currentSavedQuery } = data;

      return {
        ...state,
        currentSavedQuery: null,
        isFilterChanged: false,
        savedQueries: state.savedQueries.filter(({ id }) => id !== currentSavedQuery.id),
      };
    }
    case UseCatalogDiscoveryAction.UPDATE_FILTER: {
      const { query, filter } = data;
      const isFilterChanged = !getIsOverrideFilterEqualToCurrentOne(
        filter,
        state.currentSavedQuery?.filter ?? state.filter,
      );

      return {
        ...state,
        query,
        filter,
        isFilterChanged,
      };
    }
    case UseCatalogDiscoveryAction.SET_FILTER_DELAYED_STATUS: {
      return {
        ...state,
        isToolbarFilterDelayed: true,
      };
    }
    case UseCatalogDiscoveryAction.SET_TIMEOUT_NOTIFICATION_STATUS: {
      const { isTimeoutNotificationShown } = data;

      return {
        ...state,
        isTimeoutNotificationShown,
      };
    }
    case UseCatalogDiscoveryAction.UPDATE_APPLIED_FILTER: {
      const { appliedFilter } = data;

      return {
        ...state,
        appliedFilter,
        isFilterChanged: false,
      };
    }
    case UseCatalogDiscoveryAction.PAGE_USER_PREFERENCES_RESTORED: {
      const { appliedFilter, isOnboardingAcknowledged, savedQueries } = data;

      return {
        ...state,
        appliedFilter,
        isOnboardingAcknowledged,
        savedQueries,
      };
    }
    default:
      return state;
  }
};

export function useCatalogDiscovery(
  selectedCatalogFilters: GetViewInDataOverviewFiltersResult,
  transitionFromNewCatalog: boolean,
): UseCatalogDiscoveryResponse {
  const dataFetchingTimeoutId = useRef<ReturnType<typeof setTimeout>>(null);
  const { getFilterConfigById } = useFilterConfig();
  const [
    {
      filter,
      appliedFilter,
      isFilterChanged,
      query,
      isDataFetching,
      isToolbarFilterDelayed,
      isDataFetchingDelayed,
      isTimeoutNotificationShown,
      isPageReadyToInitialise,
      pageInitialisationStatus,
      guidedTourStatus,
      widgetsDataFetchingStatus,
      isOnboardingAcknowledged,
      savedQueries,
      currentSavedQuery,
    },
    dispatch,
  ] = useReducer(reducer, {}, getCatalogDiscoveryInitialState);

  const onWidgetFilterChange = useCallback(
    (filterId: string | number, items: AggregationItemBase[], shouldUndo?: boolean) => {
      const filterConfig = getFilterConfigById(filterId);

      analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATAINSIGHTS_WIDGETS_WIDGETCLICKS_CLICK, {
        widgetName: filterId,
      });

      if (filterConfig) {
        const appliedFilter = getWidgetAppliedFilter(filterConfig, items, shouldUndo);

        dispatch({
          action: UseCatalogDiscoveryAction.UPDATE_APPLIED_FILTER,
          data: {
            appliedFilter: {
              strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITH_RELOAD_MERGE,
              values: [appliedFilter],
            },
          },
        });
      }
    },
    [getFilterConfigById],
  );

  const onAttributesWidgetFilterChange = useCallback(
    (items: AttributesGridAggregationItem[]) => {
      const filterConfig = getFilterConfigById(AggregationType.ATTRIBUTE_CATEGORY);

      analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATAINSIGHTS_WIDGETS_WIDGETCLICKS_CLICK, {
        widgetName: AggregationType.ATTRIBUTE_NAME,
      });

      if (filterConfig) {
        const appliedFilter = getAttributeNameAppliedFilter(filterConfig, items);

        dispatch({
          action: UseCatalogDiscoveryAction.UPDATE_APPLIED_FILTER,
          data: {
            appliedFilter: {
              strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITH_RELOAD_MERGE,
              values: [appliedFilter],
            },
          },
        });
      }
    },
    [getFilterConfigById],
  );

  const onToolbarFilterChange = useCallback((toolbarFilter: BigidAdvancedToolbarFilterUnion[], isBusy: boolean) => {
    const query = parseFilterOutputToQueryString(toolbarFilter);

    analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATAINSIGHTS_FILTERSPAGE_FILTERING_CLICK, {
      filterNames: toolbarFilter.map(filter => filter.id),
    });

    setUserPreferences({
      filter: getAppliedFilterValues(toolbarFilter),
    });

    dispatch({
      action: UseCatalogDiscoveryAction.UPDATE_FILTER,
      data: {
        filter: toolbarFilter,
        query,
      },
    });

    dispatch({
      action: UseCatalogDiscoveryAction.FETCH_DATA_STATUS_UPDATE,
      data: {
        widgetsDataFetchingStatus: {
          [CatalogDiscoveryWidget.TOOLBAR_FILTER]: {
            isBusy,
            dataFetchStatusChangeTimestamp: new Date().getTime(),
          },
        },
      },
    });
  }, []);

  const onToolbarFilterReloaded = useCallback(() => {
    dispatch({
      action: UseCatalogDiscoveryAction.FETCH_DATA_STATUS_UPDATE,
      data: {
        widgetsDataFetchingStatus: {
          [CatalogDiscoveryWidget.TOOLBAR_FILTER]: {
            isBusy: false,
            dataFetchStatusChangeTimestamp: new Date().getTime(),
          },
        },
      },
    });
  }, []);

  const onToolbarFilterTimeoutReached = useCallback(() => {
    dispatch({
      action: UseCatalogDiscoveryAction.SET_FILTER_DELAYED_STATUS,
    });
  }, []);

  const onToolbarFilterInitialise = useCallback((isBusy: boolean, isFailedToInitialise: boolean) => {
    if (isFailedToInitialise) {
      dispatch({
        action: UseCatalogDiscoveryAction.UPDATE_APPLIED_FILTER,
        data: {
          appliedFilter: {
            strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITH_RELOAD_MERGE,
            values: [],
          },
        },
      });

      showFilterRestorationFailureNotification();
    } else {
      dispatch({
        action: UseCatalogDiscoveryAction.FETCH_DATA_STATUS_UPDATE,
        data: {
          widgetsDataFetchingStatus: {
            [CatalogDiscoveryWidget.TOOLBAR_FILTER]: {
              isBusy,
              dataFetchStatusChangeTimestamp: new Date().getTime(),
            },
          },
        },
      });
    }
  }, []);

  const onSavedQuerySelect = (savedQuery: SavedFilter) => {
    dispatch({
      action: UseCatalogDiscoveryAction.SELECT_SAVED_QUERY,
      data: {
        currentSavedQuery: savedQuery,
      },
    });
  };

  const onSavedQueryCreate = (savedQuery: SavedFilter) => {
    dispatch({
      action: UseCatalogDiscoveryAction.CREATE_SAVED_QUERY,
      data: {
        currentSavedQuery: savedQuery,
      },
    });
  };

  const onSavedQueryUpdate = (savedQuery: SavedFilter) => {
    dispatch({
      action: UseCatalogDiscoveryAction.UPDATE_SAVED_QUERY,
      data: {
        currentSavedQuery: savedQuery,
      },
    });
  };

  const onSavedQueryDelete = (savedQuery: SavedFilter) => {
    dispatch({
      action: UseCatalogDiscoveryAction.DELETE_SAVED_QUERY,
      data: {
        currentSavedQuery: savedQuery,
      },
    });
  };

  const onDataFetchStatusChange = useCallback((widget: CatalogDiscoveryWidget, isBusy: boolean) => {
    dispatch({
      action: UseCatalogDiscoveryAction.FETCH_DATA_STATUS_UPDATE,
      data: {
        widgetsDataFetchingStatus: {
          [widget]: {
            isBusy,
            dataFetchStatusChangeTimestamp: new Date().getTime(),
          },
        },
      },
    });
  }, []);

  const onEmptyStateComputed = useCallback((isEmpty: boolean) => {
    if (!isEmpty) {
      dispatch({
        action: UseCatalogDiscoveryAction.PAGE_READY_TO_INIT,
      });
    }
  }, []);

  const onGuidedTourStart = useCallback(() => {
    dispatch({
      action: UseCatalogDiscoveryAction.UPDATE_GUIDED_TOUR_STATUS,
      data: {
        guidedTourStatus: CatalogDiscoveryGuidedTourStatus.STARTED_MANUALLY,
      },
    });
  }, []);

  const onGuidedTourFinished = useCallback((originStatus: CatalogDiscoveryGuidedTourStatus) => {
    if (originStatus === CatalogDiscoveryGuidedTourStatus.STARTED_AUTOMATICALLY) {
      setUserPreferences({ isOnboardingAcknowledged: true });
    }

    dispatch({
      action: UseCatalogDiscoveryAction.UPDATE_GUIDED_TOUR_STATUS,
      data: {
        guidedTourStatus: CatalogDiscoveryGuidedTourStatus.NOT_STARTED,
      },
    });
  }, []);

  useEffect(() => {
    async function initPreferences() {
      const { filter, isOnboardingAcknowledged, savedFilters = [] } = await getUserPreferences();
      let appliedFilters: AdvancedToolbarOverrideValue[] = [];

      dispatch({
        action: UseCatalogDiscoveryAction.FETCH_DATA_STATUS_UPDATE,
        data: {
          widgetsDataFetchingStatus: {
            USER_PREFERENCES: {
              isBusy: false,
              dataFetchStatusChangeTimestamp: new Date().getTime(),
            },
          },
        },
      });

      if (selectedCatalogFilters?.dateRangeFilters?.length > 0 || selectedCatalogFilters?.dropdownFilters?.length > 0) {
        //NOTE: transitionFromNewCatalog is needed temporarily till the old catalog page is deprecated
        appliedFilters = getToolbarValuesFromCatalogFilters(selectedCatalogFilters, transitionFromNewCatalog);
      } else if (Array.isArray(filter) && filter.length > 0) {
        appliedFilters = filter;
      }

      dispatch({
        action: UseCatalogDiscoveryAction.PAGE_USER_PREFERENCES_RESTORED,
        data: {
          appliedFilter: {
            strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITHOUT_RELOAD_OVERRIDE,
            values: appliedFilters,
          },
          isOnboardingAcknowledged,
          savedQueries: savedFilters,
        },
      });
    }

    initPreferences(); //NOTE: toolbar preferences only at the moment
  }, [selectedCatalogFilters, transitionFromNewCatalog]);

  useEffect(() => {
    if (
      Object.values(widgetsDataFetchingStatus).every(({ isBusy }) => !isBusy) &&
      appliedFilter &&
      pageInitialisationStatus.isBusy
    ) {
      dispatch({
        action: UseCatalogDiscoveryAction.PAGE_INIT_FULFILLED,
        data: {
          guidedTourStatus: !isOnboardingAcknowledged
            ? CatalogDiscoveryGuidedTourStatus.STARTED_AUTOMATICALLY
            : CatalogDiscoveryGuidedTourStatus.NOT_STARTED,
        },
      });
    }
  }, [appliedFilter, widgetsDataFetchingStatus, isOnboardingAcknowledged, pageInitialisationStatus.isBusy]);

  useEffect(() => {
    if (isDataFetching) {
      dataFetchingTimeoutId.current = setTimeout(() => {
        dispatch({
          action: UseCatalogDiscoveryAction.FETCH_DATA_STATUS_DELAYED,
        });
      }, dataFetchingTimeoutThreshold);
    } else {
      dispatch({
        action: UseCatalogDiscoveryAction.FETCH_DATA_STATUS_FULFILLED,
      });
      clearTimeout(dataFetchingTimeoutId.current);
      dataFetchingTimeoutId.current = null;
    }

    () => {
      clearTimeout(dataFetchingTimeoutId.current);
      dataFetchingTimeoutId.current = null;
    };
  }, [isDataFetching]);

  useEffect(() => {
    if (isPageReadyToInitialise && (isDataFetchingDelayed || isToolbarFilterDelayed) && !isTimeoutNotificationShown) {
      showPageInitTimeoutNotification();
      dispatch({
        action: UseCatalogDiscoveryAction.SET_TIMEOUT_NOTIFICATION_STATUS,
        data: {
          isTimeoutNotificationShown: true,
        },
      });
    }
  }, [isDataFetchingDelayed, isPageReadyToInitialise, isTimeoutNotificationShown, isToolbarFilterDelayed]);

  return {
    query,
    filter,
    appliedFilter,
    isFilterChanged,
    savedQueries,
    currentSavedQuery,
    onToolbarFilterChange,
    onToolbarFilterInitialise,
    onToolbarFilterReloaded,
    onToolbarFilterTimeoutReached,
    onWidgetFilterChange,
    onAttributesWidgetFilterChange,
    onDataFetchStatusChange,
    onEmptyStateComputed,
    onGuidedTourStart,
    onGuidedTourFinished,
    pageInitialisationStatus,
    isPageReadyToInitialise,
    guidedTourStatus,
    widgetsDataFetchingStatus,
    onSavedQueryCreate,
    onSavedQueryUpdate,
    onSavedQueryDelete,
    onSavedQuerySelect,
  };
}
