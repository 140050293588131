import React, { useCallback, useMemo, useRef, useState } from 'react';
import { CONFIG } from '../../../config/common';
import { BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { EmptyStateWrapper } from './LegalEntitiesCommonStyles';
import {
  BigidDefaultIcon,
  BigidDeleteIcon,
  BigidEditIcon,
  BigidFiltersIllustration,
  BigidNoDataIllustration,
  IconMatcher,
} from '@bigid-ui/icons';
import {
  BigidStatusBadgeSize,
  PrimaryButton,
  ToolbarActionType,
  BigidAvatar,
  BigidStatusBadge,
  BigidFilterOptionType,
  usePrevious,
} from '@bigid-ui/components';
import {
  LegalEntitiesInterface,
  LegalEntitiesStatus,
  LegalEntityLevel,
  LegalEntityStateNames,
  LegalEntitiesDeleteDetails,
} from './LegalEntitiesTypes';
import { useUserPreferences } from '../../components/hooks/useUserPrefrences';
import { $state, $stateParams } from '../../services/angularServices';
import { getLegalEntities, getLevels, getParents } from './LegalEntitiesService';
import { notificationService } from '../../services/notificationService';
import { CountryData } from '../Fmsd/fmsdServices';
import { useFetchEntity } from '../DSAR/SarProfileSettings/GridHelpers/useFetchEntity';
import { getCountries } from '../DSAR/dsarService';
import { applicationSetupService } from '../../../administration/applicationSetup/applicationSetup.service';
import { ApplicationEntity } from '../ApplicationSetup/types';
import { useCountryCodes } from './utils/useCountryCodes';
import { statusBadgeMapper } from './utils/common';
import { isPermitted } from '../../services/userPermissionsService';
import { LEGAL_ENTITIES_PERMISSIONS } from '@bigid/permissions';
import { LegalEntitiesTrackingEvents, trackLegalEntities } from './utils/analytics';
import { DynamicChipsArea } from '../Vendors/DynamicChipsArea';
import { LegalEntitiesBrandingLogo } from './LegalEntitiesBrandingLogo';

type Grid = BigidGridWithToolbarProps<LegalEntitiesInterface>;

const columns: BigidGridColumn<LegalEntitiesInterface>[] = [
  {
    width: 160,
    title: 'Name',
    name: 'name',
    type: BigidGridColumnTypes.TEXTIFIED_ICON,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: ({ branding, name }) => {
      return {
        text: { displayValue: name },
        icon: {
          icon: () => <LegalEntitiesBrandingLogo brandingId={branding?.brandingId} inGrid />,
          tooltip: name,
          placement: 'left',
        },
      };
    },
  },
  {
    width: 160,
    title: 'Description',
    name: 'description',
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: row => row?.description,
  },
  {
    width: 160,
    title: 'Level',
    name: 'level.id',
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: row => row.level.name,
  },
  {
    width: 160,
    title: 'Owner Name',
    name: 'mainContactName',
    type: BigidGridColumnTypes.TEXTIFIED_ICON,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: row => ({
      text: { displayValue: row?.mainContactName },
      icon: {
        icon: () => row?.mainContactName && <BigidAvatar userName={row?.mainContactName} size="xsmall" />,
        tooltip: row?.mainContactName,
        placement: 'left',
      },
    }),
  },
  {
    width: 160,
    title: 'Owner Email',
    name: 'mainContactEmail',
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: row => row?.mainContactEmail,
  },
  {
    width: 160,
    title: 'Status',
    name: 'status',
    type: BigidGridColumnTypes.CUSTOM,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: row => (
      <BigidStatusBadge
        label={row?.status === 'UnderReview' ? 'Under Review' : row?.status}
        size={BigidStatusBadgeSize.SMALL}
        type={statusBadgeMapper[row?.status]}
      />
    ),
  },
  {
    width: 180,
    title: 'Assets',
    name: 'assetIds',
    type: BigidGridColumnTypes.CHIPS,
    getCellValue: ({ assets }) => {
      return {
        chips: {
          value: assets?.map(asset => ({
            label: asset.name,
            id: asset.id,
          })),
          isDisabled: true,
        },
      };
    },
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 200,
    title: 'Locations',
    name: 'operatingLocations',
    type: BigidGridColumnTypes.CUSTOM,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: row => {
      const hasLocationData = !!row?.operatingLocations?.length;
      return (
        hasLocationData && (
          <DynamicChipsArea
            values={row?.locations?.map(ele => ({
              label: ele.name,
              icon: (
                <IconMatcher
                  iconLabel={ele.code}
                  size="small"
                  viewBox="0 0 28 24"
                  defaultIcon={BigidDefaultIcon}
                  matchFunction="equals"
                  key={ele.name}
                />
              ),
            }))}
            withChips
          />
        )
      );
    },
  },
  {
    width: 280,
    title: 'Sub-Entities',
    name: 'subEntities',
    type: BigidGridColumnTypes.CHIPS,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: row => ({
      chips: {
        value: row?.subEntities?.map(ele => ({
          label: ele.name,
          id: ele.id,
        })),
        isDisabled: true,
      },
    }),
  },
  {
    width: 160,
    title: 'Parent',
    name: 'parentEntity',
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: row => row?.parentEntity?.name,
  },
  {
    width: 160,
    title: 'Created At',
    name: 'createdAt',
    type: BigidGridColumnTypes.DATE,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: ({ createdAt }) => createdAt,
  },
];
const getFilterConfig = async (
  locations: CountryData[] = [],
  parent: BigidFilterOptionType[] = [],
  level: BigidFilterOptionType[] = [],
  assets: BigidFilterOptionType[] = [],
  parentFilterId?: string,
): Promise<Grid['filterToolbarConfig']> => {
  return {
    filters: [
      {
        title: 'Status',
        field: 'status',
        operator: 'in',
        listWidth: 400,
        value: [],
        options: [
          { value: LegalEntitiesStatus.ACTIVE, label: LegalEntitiesStatus.ACTIVE, isSelected: false },
          { value: LegalEntitiesStatus.INACTIVE, label: LegalEntitiesStatus.INACTIVE, isSelected: false },
          { value: LegalEntitiesStatus.DRAFT, label: LegalEntitiesStatus.DRAFT, isSelected: false },
          { value: LegalEntitiesStatus.UNDER_REVIEW, label: 'Under Review', isSelected: false },
        ],
      },
      {
        title: 'Level',
        field: 'level.id',
        operator: 'in',
        listWidth: 400,
        value: [],
        options: level,
        isSearchAsync: true,
        loadSearchOptions: async (searchText?: string) => {
          const levelOptions = await fetchLevels(searchText);
          return levelOptions;
        },
      },
      {
        title: 'Parent',
        field: 'parentId',
        operator: 'in',
        listWidth: 400,
        value: parentFilterId ? [parentFilterId] : [],
        options: parent,
        isSearchAsync: true,
        loadSearchOptions: async (searchText?: string) => {
          const parentOptions = await fetchParents(searchText);
          return parentOptions;
        },
      },
      {
        title: 'Locations',
        field: 'operatingLocations',
        operator: 'in',
        disabled: true,
        listWidth: 400,
        displayLimit: 300,
        value: [],
        options: locations.map(location => ({
          value: location.name,
          label: location.displayName,
          isSelected: false,
        })),
      },
      {
        title: 'Assets',
        field: 'assetIds',
        operator: 'in',
        listWidth: 400,
        value: [],
        disabled: true,
        options: assets,
      },
    ],
    searchConfig: {
      searchFilterKeys: ['name'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
};
const fetchLocations = async () => {
  try {
    const countries = await getCountries();
    return countries;
  } catch (err) {
    notificationService.error(`Failed to fetch Locations`);
    console.error(`Failed to fetch Locations: ${JSON.stringify(err?.response)}`);
    return [];
  }
};
const fetchParents = async (searchText?: string, selectedPreFilter?: string) => {
  try {
    const { legalEntities } = await getParents(searchText);
    const parentOptions = legalEntities
      ?.filter((entity: LegalEntitiesInterface) => entity?.subEntities)
      ?.map((val: LegalEntitiesInterface) => ({
        label: `${val.name} (${val.level.name})`,
        value: val.id,
        isSelected: val.id === selectedPreFilter,
        icon: () => <LegalEntitiesBrandingLogo brandingId={val?.branding?.brandingId} inGrid />,
      })) as BigidFilterOptionType[];

    return parentOptions;
  } catch (err) {
    notificationService.error(`Failed to fetch Parent`);
    console.error(`Failed to fetch Parent: ${JSON.stringify(err?.response)}`);
    return [];
  }
};
const fetchLevels: (searchText?: string) => Promise<BigidFilterOptionType[]> = async (searchText?: string) => {
  try {
    const { legalEntitiesLevel } = await getLevels(searchText);
    const options = legalEntitiesLevel.map((level: LegalEntityLevel) => {
      return {
        label: level.name,
        value: level.id,
        isSelected: false,
      };
    });
    return options;
  } catch (err) {
    notificationService.error(`Failed to fetch Level`);
    console.error(`Failed to fetch Level: ${JSON.stringify(err?.response)}`);
    return [];
  }
};
const fetchAssets = async () => {
  try {
    const { applications } = await applicationSetupService.getApplicationItems();
    const options = applications.map((application: ApplicationEntity) => {
      return {
        label: application.name,
        value: application._id,
        id: application._id,
        isSelected: false,
      };
    });
    return options;
  } catch (err) {
    notificationService.error(`Failed to fetch Assets`);
    console.error(`Failed to fetch Assets: ${JSON.stringify(err?.response)}`);
    return [];
  }
};

export const LegalEntitiesGrid = ({
  openLegalEntitiesEditDialog,
  openDeleteEntityDialog,
  forceUpdateKeys: { forceUpdateAssetsKey, forceUpdateParentsKey, forceUpdateLevelsKey },
}: {
  openLegalEntitiesEditDialog: (isNewSubEntity: boolean, legalEntityDetails?: LegalEntitiesInterface) => void;
  openDeleteEntityDialog: (legalEntityDetails: LegalEntitiesDeleteDetails) => void;
  forceUpdateKeys: {
    forceUpdateParentsKey: number;
    forceUpdateAssetsKey: number;
    forceUpdateLevelsKey: number;
  };
}) => {
  const gridFilter = useRef(null);
  const prevParentsKey = usePrevious(forceUpdateParentsKey) ?? 0;
  const shouldUpdateParents = forceUpdateParentsKey !== prevParentsKey;
  const prevAssetsKey = usePrevious(forceUpdateAssetsKey) ?? 0;
  const shouldUpdateAssetsType = forceUpdateAssetsKey !== prevAssetsKey;
  const prevLevelsKey = usePrevious(forceUpdateLevelsKey) ?? 0;
  const shouldUpdateLevelsType = forceUpdateLevelsKey !== prevLevelsKey;
  const [showEmptyState, setShowEmptyState] = useState(false);
  const locations = useFetchEntity(fetchLocations);
  const parentFilterId = useMemo(() => $stateParams.parentFilterId, [$stateParams]);
  const parent = useFetchEntity(
    useCallback(() => fetchParents('', parentFilterId), [parentFilterId]),
    {
      skipFetching: !locations,
      forceUpdate: shouldUpdateParents,
    },
  );
  const level = useFetchEntity(fetchLevels, { skipFetching: !locations, forceUpdate: shouldUpdateLevelsType });
  const assets = useFetchEntity(fetchAssets, { skipFetching: !locations, forceUpdate: shouldUpdateAssetsType });
  const getInitialFilterToolbarConfig = useCallback(
    () => getFilterConfig(locations, parent, level, assets, parentFilterId),
    [locations, parent, level, assets, parentFilterId],
  );
  const { countryNameToCodeMapper } = useCountryCodes();

  const {
    isReady,
    key: gridKey,
    preferences,
    gridColumns,
    filterToolbarConfig,
    updatePreferences,
  } = useUserPreferences({
    stateName: LegalEntityStateNames.ENTITIES_LISTING,
    initialGridColumns: columns,
    getInitialFilterToolbarConfig,
  });

  const gridWithToolbarConfig: BigidGridWithToolbarProps<LegalEntitiesInterface> = useMemo(() => {
    const gridWithToolbarConfig: BigidGridWithToolbarProps<LegalEntitiesInterface> = {
      showFilteringControls: true,
      columns: gridColumns,
      entityName: 'Legal Entities',
      showSelectionCheckboxes: true,
      showSelectAll: true,
      rowClickShouldKeepSelection: true,
      defaultSorting: (preferences?.grid?.sort?.length && preferences.grid.sort) || [{ field: 'name', order: 'asc' }],
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      toolbarActions: [
        {
          label: 'Edit',
          icon: BigidEditIcon,
          execute: async ({ selectedRows: [LegalEntityEditDetails] }) => {
            trackLegalEntities(LegalEntitiesTrackingEvents.EDIT_LEGAL_ENTITY_INLINE_ACTION);
            openLegalEntitiesEditDialog(false, LegalEntityEditDetails);
            return { shouldClearSelection: true, shouldGridReload: true };
          },
          show: () => isPermitted(LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_EDIT.name),
          isInline: true,
          hideActionInToolBar: true,
          type: ToolbarActionType.TERTIARY,
        },
        {
          label: 'Delete',
          icon: BigidDeleteIcon,
          execute: async ({ selectedRows: [LegalEntityDeleteDetails] }) => {
            trackLegalEntities(LegalEntitiesTrackingEvents.DELETE_LEGAL_ENTITY_INLINE_ACTION);
            openDeleteEntityDialog({ id: LegalEntityDeleteDetails.id, name: LegalEntityDeleteDetails.name });
            return { shouldClearSelection: true, shouldGridReload: true };
          },
          show: ({ selectedRows: [LegalEntityDeleteDetails] }) => {
            return (
              isPermitted(LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_DELETE.name) &&
              !(
                LegalEntityDeleteDetails?.status === LegalEntitiesStatus.ACTIVE ||
                LegalEntityDeleteDetails?.subEntities?.length
              )
            );
          },
          isInline: true,
          hideActionInToolBar: true,
          type: ToolbarActionType.TERTIARY,
        },
      ],
      filterToolbarConfig: filterToolbarConfig,

      onRowClick: entity => {
        trackLegalEntities(LegalEntitiesTrackingEvents.VIEW_LEGAL_ENTITY_INLINE_ACTION);
        $state.go(
          CONFIG.states.LEGAL_ENTITIES_OVERVIEW,
          { entityId: entity.id, tab: 'overview' },
          { reload: false, notify: false, inherit: true },
        );
      },
      fetchData: async gridQueryParams => {
        try {
          gridFilter.current = gridQueryParams.filter;
          const { legalEntities, totalCount, hasLegalEntities } = await getLegalEntities(gridQueryParams);
          if (totalCount === 0) {
            setShowEmptyState(hasLegalEntities);
          }
          return {
            data: legalEntities.map((entity: LegalEntitiesInterface) => {
              return {
                ...entity,
                locations: entity?.operatingLocations?.map(ele => ({
                  name: ele,
                  code: countryNameToCodeMapper[ele],
                })),
              };
            }),
            totalCount: totalCount,
          };
        } catch (err) {
          notificationService.error(`Failed to fetch Legal Entities.`);
          console.error(`Failed to fetch Legal Entities': ${JSON.stringify(err)}`);
          return { data: [], totalCount: 0 };
        }
      },
      noDataContent: (
        <EmptyStateWrapper>
          {!showEmptyState ? (
            <>
              <BigidNoDataIllustration />
              <p>{'Get Started with Legal Entities'}</p>
              <PrimaryButton
                size="medium"
                onClick={() => {
                  trackLegalEntities(LegalEntitiesTrackingEvents.CREATE_LEGAL_ENTITY_ACTION);
                  openLegalEntitiesEditDialog(false);
                }}
              >
                {'Create Legal Entity'}
              </PrimaryButton>
            </>
          ) : (
            <>
              <BigidFiltersIllustration />
              <p>{'There are no results for this filter'}</p>
            </>
          )}
        </EmptyStateWrapper>
      ),
    };

    return gridWithToolbarConfig;
  }, [
    countryNameToCodeMapper,
    gridColumns,
    preferences?.grid?.sort,
    filterToolbarConfig,
    showEmptyState,
    updatePreferences,
    openLegalEntitiesEditDialog,
    openDeleteEntityDialog,
  ]);

  return <>{isReady && <BigidGridWithToolbar {...gridWithToolbarConfig} key={gridKey} />}</>;
};
