import React from 'react';
import { CarouselDataProps } from '../../../../../components/Carousel/Carousel';
import { OuterItemContainer } from '../WidgetsStyles';
import { CarouselItem } from './CarouselItem';

interface CarouselItemsListProps {
  data: CarouselDataProps[];
  itemsToShow?: number;
}

export const CarouselItemsList = ({ data, itemsToShow = 5 }: CarouselItemsListProps) => {
  return (
    <>
      {data?.map((carouselItem: CarouselDataProps) => {
        return (
          <OuterItemContainer itemsToShow={itemsToShow} key={carouselItem?.frameworkName}>
            <CarouselItem
              frameworkName={carouselItem.frameworkName}
              totalControls={carouselItem.totalControls}
              failedControlsCount={carouselItem.failedControlsCount}
              controls={carouselItem.controls}
            />
          </OuterItemContainer>
        );
      })}
    </>
  );
};
