export enum Api {
  OBJECT_ACTIVITY_SUMMARY = 'object-activity-summary',
  SEARCH_FIELD = 'search-field',
  OBJECT_ACTIVITY_DETAILS = 'object-activity-details',
}

export enum ApiParam {
  ACCOUNT_NAME = 'accountName',
  ACCOUNT_IDENTIFIER = 'accountIdentifier',
  LAST_ACTIVITY = 'lastActivity',
  OPERATION = 'operation',
  OBJECTS = 'objects',
  SEARCH_STRING = 'searchString',
  // BAM specific
  URL = 'url',
  TOKEN = 'token',
  DATA_SOURCE = 'dataSource',
}
