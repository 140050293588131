import { FrameworkProps } from '../../../react/views/Frameworks/FrameworksServices';
import { comparator } from '../../../react/views/Frameworks/utils';
import { concat } from 'lodash';

export enum ContentView {
  LIST = 'list',
  GRID = 'grid',
}

export interface ContentWrapperProps {
  isVisible?: boolean;
}

export interface MappedControlsProps {
  category: string | number;
  description: string;
  framework_name: string;
  id: string;
  name: string;
}

export interface MappedControlRowProps {
  _id: string;
  id: string;
  framework_name: string;
  enabled: boolean;
  number_of_controls: number;
}

export interface NormalizedMappedControlsProps {
  widgetFrameworkName: string;
  controls: MappedControlsProps[];
}

export interface MappedControlsBodyProps {
  controlsToEnable: string[];
  controlsToDisable: string[];
}

export interface MainRowProps {
  _id?: string;
  id: string;
  name: string;
  description: string;
}

export function getOptimizedControls(data: MappedControlsProps[]): NormalizedMappedControlsProps[] {
  const frameworkMap = new Map<string, MappedControlsProps[]>();
  data.forEach(control => {
    if (!frameworkMap.has(control.framework_name)) {
      frameworkMap.set(control.framework_name, []);
    }
    frameworkMap.get(control.framework_name).push(control);
  });
  const normalizedMappedControls = Array.from(frameworkMap.entries()).map(([framework_name, controls]) => ({
    widgetFrameworkName: framework_name,
    controls: controls.sort(comparator('name')),
  }));

  return normalizedMappedControls;
}

export function flatMappedControls(data: NormalizedMappedControlsProps[]): string[] {
  let result: string[] = [];
  data.map((element: NormalizedMappedControlsProps) => {
    const listOfId = element.controls.map((control: MappedControlsProps) => control.id);
    result = concat(result, listOfId);
  });
  return result;
}

export function getNormilizedMappedSelection(
  mappedControls: NormalizedMappedControlsProps[],
  staticMappedControls: NormalizedMappedControlsProps[],
): MappedControlsBodyProps {
  const listDynamic = flatMappedControls(mappedControls);
  const listStatic = flatMappedControls(staticMappedControls);

  const controlsToDisable = listStatic.filter((staticControls: string) => !listDynamic.includes(staticControls));
  const controlsToEnable = listDynamic.filter((dynamiControl: string) => !listStatic.includes(dynamiControl));

  return {
    controlsToEnable,
    controlsToDisable,
  };
}

export const calculateAmountOfSelectedControls = (
  data: NormalizedMappedControlsProps[],
  frameworkName: string,
): { alreadySelected: number } => {
  const currentSelectedFramework = data.find(
    (element: NormalizedMappedControlsProps) => element.widgetFrameworkName === frameworkName,
  );
  return {
    alreadySelected: currentSelectedFramework ? currentSelectedFramework.controls.length : 0,
  };
};

export function getPreselectedControlsLength(
  dynamicMappedControls: NormalizedMappedControlsProps[],
  control?: FrameworkProps,
): number {
  const mappedControl: NormalizedMappedControlsProps = dynamicMappedControls?.find(
    (dynamicMappedControl: NormalizedMappedControlsProps) =>
      dynamicMappedControl.widgetFrameworkName === control.framework_name,
  );
  return (
    mappedControl?.controls.filter((directControl: MappedControlsProps) => directControl.category === control.category)
      ?.length || 0
  );
}

export function getPreselectedControlsIds(
  dynamicMappedControls: NormalizedMappedControlsProps[],
  row: MappedControlRowProps,
  selectedCategory: string,
): string[] {
  const preSelectedIdsList = dynamicMappedControls
    .find((mapped: NormalizedMappedControlsProps) => mapped?.widgetFrameworkName === row?.framework_name)
    ?.controls?.filter((control: MappedControlsProps) => control.category === selectedCategory)
    .map((control: MappedControlsProps) => control.id);
  return preSelectedIdsList;
}

export function updateSelectedControls(
  dynamicMappedControls: NormalizedMappedControlsProps[],
  selectedRows: any[],
  selectedCategory: string,
  frameworkName: string,
) {
  const alreadyExisted = dynamicMappedControls.map((mapped: NormalizedMappedControlsProps) => {
    if (mapped.widgetFrameworkName === frameworkName) {
      const controlsOutOfCategory = mapped.controls.filter(
        (control: MappedControlsProps) => control.category !== selectedCategory,
      );
      return {
        widgetFrameworkName: frameworkName,
        controls: [...selectedRows, ...controlsOutOfCategory],
      };
    } else {
      return mapped;
    }
  });
  const isNewFramework = alreadyExisted.some(
    (mapped: NormalizedMappedControlsProps) => mapped.widgetFrameworkName === frameworkName,
  );
  if (!isNewFramework) {
    alreadyExisted.push({
      widgetFrameworkName: frameworkName,
      controls: [...selectedRows],
    });
  }
  const result = [...alreadyExisted];
  return result;
}
