import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useQueries } from 'react-query';
import { fetchSearchResults, getSearchResultCount } from '../explorerSearchResultsService';
import { pageSize, searchTextMinLength } from '../constants';
import {
  ResultsEntityType,
  CatalogSearchResultsPayload,
  CatalogSearchGridRow,
  SearchResultsGridRowUnion,
} from '../types';
import { useSelectionReset } from './hooks/useSelectionReset';
import { useSelectAllProcessor } from './hooks/useSelectAllProcessor';
import { entityTypeToApiType, stringToSortByMap } from '../utils';
import { useUpdatedStateParams } from '../../../hooks/useUpdatedStateParams';

export const catalogSortOptions = ['name', 'scanDate', 'modified_date'] as const;

export type SearchResultsSortBy = typeof catalogSortOptions[number];

export const alienEntitySortOptions = ['name'] as const;

export const getDefaultSortBy = (entityType: ResultsEntityType): SearchResultsSortBy => {
  switch (entityType) {
    case 'catalog':
      return 'scanDate';
    default:
      return 'name';
  }
};

export type GridConfig = {
  page: number;
  isSelectAll: boolean;
  totalCount: number;
  selectedRowsIds: string[];
  unselectedRowsIds: string[];
  rows?: SearchResultsGridRowUnion[];
};

export type ContextState = {
  gridConfig: GridConfig;
  savedFiltersMap: Record<ResultsEntityType, string>;
  overrideValuesMap: Record<ResultsEntityType, string>;
  selectedItem: CatalogSearchGridRow;
  onPanelCloseCallback: (value: void | PromiseLike<void>) => void;
};

type DataCatalogSearchResultsContextValue = {
  state: ContextState;
  dispatchCatalogSearchAction: Dispatch<ContextActions>;
  isGridDataLoading: boolean;
  query: string;
  entityType?: ResultsEntityType;
  sortBy: SearchResultsSortBy;
};

const getInitialGridConfig = (): GridConfig => {
  const initGridConfig: GridConfig = {
    page: 1,
    rows: null,
    isSelectAll: false,
    totalCount: 0,
    selectedRowsIds: [],
    unselectedRowsIds: [],
  };

  return initGridConfig;
};

const getReducerInitState = (initialFilters: string, entityType: ResultsEntityType): ContextState => {
  const reducerInitState: ContextState = {
    gridConfig: getInitialGridConfig(),
    savedFiltersMap: {
      catalog: '',
      datasource: '',
      policy: '',
    },
    overrideValuesMap: {
      catalog: '',
      datasource: '',
      policy: '',
    },
    selectedItem: null,
    onPanelCloseCallback: null,
  };

  // Directly update savedFiltersMap and overrideValuesMap using entityType as the key
  reducerInitState.overrideValuesMap[entityType] = initialFilters;

  return reducerInitState;
};

const DataCatalogSearchResultsContext = createContext<DataCatalogSearchResultsContextValue>({
  state: getReducerInitState('', 'catalog'),
  isGridDataLoading: false,
  dispatchCatalogSearchAction: () => {
    return;
  },
  query: '',
  sortBy: 'scanDate',
});

type SetContextState = {
  type: 'SET_CONTEXT_STATE';
  payload: Partial<ContextState>;
};

type SetQueryAction = {
  type: 'SET_QUERY';
  payload: string;
};

type ToggleSelectAllAction = {
  type: 'TOGGLE_SELECT_ALL';
};

type ResetGridAction = {
  type: 'RESET_GRID_DATA';
};

type UpdateGridConfigAction = {
  type: 'UPDATE_GRID_CONFIG';
  payload: Partial<GridConfig>;
};

type ProcessRowsSelectionAction = {
  type: 'PROCESS_ROWS_SELECTION';
  payload: {
    selectedRowIds: string[];
    lastUnselectedRowId: string[];
  };
};

type UpdateSavedFiltersMapAction = {
  type: 'UPDATE_SAVED_FILTERS_MAP';
  payload: {
    entityType: ResultsEntityType;
    filter?: string;
    overrideValues?: string;
  };
};

type SetSelectedRow = {
  type: 'SET_SELECTED_ITEM';
  payload: Pick<ContextState, 'selectedItem' | 'onPanelCloseCallback'>;
};

export type ContextActions =
  | SetContextState
  | SetQueryAction
  | ToggleSelectAllAction
  | ResetGridAction
  | UpdateGridConfigAction
  | ProcessRowsSelectionAction
  | UpdateSavedFiltersMapAction
  | SetSelectedRow;

type DataCatalogSearchResultsContextProviderProps = PropsWithChildren;

const reducer: Reducer<ContextState, ContextActions> = (state, action) => {
  switch (action.type) {
    case 'SET_QUERY':
      return {
        ...state,
        searchQuery: action.payload,
      };

    case 'SET_CONTEXT_STATE':
      return {
        ...state,
        ...action.payload,
      };

    case 'TOGGLE_SELECT_ALL':
      return {
        ...state,
        gridConfig: {
          ...state.gridConfig,
          isSelectAll: !state.gridConfig.isSelectAll,
        },
      };

    case 'RESET_GRID_DATA':
      return {
        ...state,
        gridConfig: getInitialGridConfig(),
      };

    case 'UPDATE_GRID_CONFIG':
      return {
        ...state,
        gridConfig: {
          ...state.gridConfig,
          ...action.payload,
        },
      };

    case 'PROCESS_ROWS_SELECTION': {
      const { selectedRowIds, lastUnselectedRowId } = action.payload;
      const { isSelectAll } = state.gridConfig;

      if (isSelectAll) {
        // When "Select All" is checked, dispatch an action to update unselectedRowIds
        // If lastUnselectedRowId is not empty, update the unselectedRowIds array
        if (lastUnselectedRowId.length > 0) {
          const unselectedRowId = lastUnselectedRowId[0];

          return {
            ...state,
            gridConfig: {
              ...state.gridConfig,
              unselectedRowsIds: state.gridConfig.unselectedRowsIds.includes(unselectedRowId)
                ? state.gridConfig.unselectedRowsIds.filter(id => id !== unselectedRowId)
                : [...state.gridConfig.unselectedRowsIds, unselectedRowId],
            },
          };
        } else {
          // If lastUnselectedRowId is empty, update the unselectedRowIds array with all the rows
          const unselectedRowsIds = state.gridConfig.unselectedRowsIds.filter(id => !selectedRowIds.includes(id));

          return {
            ...state,
            gridConfig: {
              ...state.gridConfig,
              unselectedRowsIds,
            },
          };
        }
      } else {
        // When "Select All" is not checked, dispatch an action to update selectedRowIds
        return {
          ...state,
          gridConfig: {
            ...state.gridConfig,
            selectedRowsIds: selectedRowIds,
          },
        };
      }
    }

    case 'UPDATE_SAVED_FILTERS_MAP': {
      const { entityType, filter, overrideValues } = action.payload;

      return {
        ...state,
        savedFiltersMap: {
          ...state.savedFiltersMap,
          [entityType]: filter || '',
        },
        overrideValuesMap: {
          ...state.overrideValuesMap,
          [entityType]: overrideValues || '',
        },
      };
    }

    case 'SET_SELECTED_ITEM': {
      const { selectedItem, onPanelCloseCallback } = action.payload;

      return {
        ...state,
        selectedItem: state.selectedItem?.id === selectedItem?.id ? null : selectedItem,
        onPanelCloseCallback,
      };
    }

    default:
      return state;
  }
};

export const DataCatalogSearchResultsContextProvider: FC<DataCatalogSearchResultsContextProviderProps> = ({
  children,
}) => {
  const { query, filters: initialFilters, activeTab, sort } = useUpdatedStateParams();
  const entityType = entityTypeToApiType[activeTab] || 'catalog';
  const [state, dispatchCatalogSearchAction] = useReducer(reducer, getReducerInitState(initialFilters, entityType));
  const { gridConfig, savedFiltersMap } = state;
  const { page, isSelectAll } = gridConfig;
  const sortBy = stringToSortByMap[sort] || getDefaultSortBy(entityType);

  const decodedQuery = decodeURIComponent(query || '');
  const filter = savedFiltersMap[entityType];

  const [searchResultsQuery, countResultQuery] = useQueries([
    {
      queryKey: ['catalogSearchResults', page, decodedQuery, sortBy, filter, entityType],
      queryFn: () => {
        const payload: CatalogSearchResultsPayload = {
          isHighlight: true,
          paging: {
            skip: pageSize * (page - 1),
            limit: pageSize,
          },
          filter,
          sort: [
            {
              field: sortBy,
              order: 'ASC',
            },
          ],
        };

        if (decodedQuery.trim().length >= searchTextMinLength) {
          payload.searchText = decodedQuery;
        }

        return fetchSearchResults({ entityType, payload });
      },
    },
    {
      queryKey: ['catalogSearchResultsCount', decodedQuery, filter, entityType],
      queryFn: () => {
        return getSearchResultCount({ filter, searchText: decodedQuery }, entityType);
      },
      keepPreviousData: true,
    },
  ]);

  useEffect(() => {
    dispatchCatalogSearchAction({
      type: 'UPDATE_GRID_CONFIG',
      payload: {
        totalCount: countResultQuery.data,
      },
    });
  }, [countResultQuery.data]);

  useEffect(() => {
    dispatchCatalogSearchAction({
      type: 'UPDATE_GRID_CONFIG',
      payload: {
        rows: searchResultsQuery.data,
      },
    });
  }, [searchResultsQuery.data]);

  useSelectAllProcessor({ ...gridConfig, dispatchCatalogSearchAction });
  useSelectionReset(isSelectAll, dispatchCatalogSearchAction);

  return (
    <DataCatalogSearchResultsContext.Provider
      value={{
        state,
        dispatchCatalogSearchAction,
        isGridDataLoading: searchResultsQuery.isLoading,
        query: decodedQuery,
        entityType,
        sortBy,
      }}
    >
      {children}
    </DataCatalogSearchResultsContext.Provider>
  );
};

export const useDataCatalogSearchResultsContext = () => {
  const context = useContext(DataCatalogSearchResultsContext);
  if (context === undefined) {
    throw new Error('useDataCatalogSearchResultsContext must be used within a DataCatalogSearchResultsContextProvider');
  }
  return context;
};
