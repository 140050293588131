import {
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterUnion,
} from '@bigid-ui/components';
import { omit } from 'lodash';
import { getApplicationPreference } from '../../../../../../services/appPreferencesService';
import {
  aggregationFilterConfig,
  fetchFilterOptions,
  getFilterOptionsFetchMethodPayload,
  mapAggregationToFilterTranslationKey,
  parseAggregationBooleanItemsToFilterOptions,
  parseAggregationItemsToFilterOptions,
  parseAggregationNestedItemsToFilterOptions,
} from '../../../../queryHelpers';
import { useLocalTranslation } from '../../../../translations';
import { AggregationFilterOperand, AggregationType, NonAggregationType } from '../../../../types';

export function getAdvancedToolbarFilterConsumableConfig<
  T extends BigidAdvancedToolbarDateRangeFilter | BigidAdvancedToolbarDropdownFilter,
>(filterId: AggregationType | NonAggregationType): Omit<T, 'asyncOptionsFetch' | 'title' | 'value'> {
  return omit(aggregationFilterConfig[filterId], [
    'isBoolean',
    'hasNestedItems',
    'leftOperandComposerFunc',
    'parseFilterToQueryFunc',
    'getUserSearchQueryPreprocessed',
    'getFilterOptionsPreprocessed',
  ]) as T;
}

const filtersAvailabilityMap: { [key in AggregationType]?: boolean } = {
  [AggregationType.RISK]: getApplicationPreference('ENABLE_CATALOG_OBJECT_RISK'),
  [AggregationType.ACCESS_TYPE_FILTER]: getApplicationPreference('ACI_ENABLED'),
};

export const useCatalogFilters = (): BigidAdvancedToolbarFilterUnion[] => {
  const { t } = useLocalTranslation('filters');

  return [
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.DATA_SOURCE_NAME),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.DATA_SOURCE_NAME]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.DATA_SOURCE_NAME,
            filterOperand: AggregationFilterOperand.DATA_SOURCE_NAME,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(
            payload,

            parseAggregationItemsToFilterOptions,
          );
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.DATA_SOURCE_TYPE),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.DATA_SOURCE_TYPE]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.DATA_SOURCE_TYPE,
            filterOperand: AggregationFilterOperand.DATA_SOURCE_TYPE,
            filter,
            value,
            searchText,
          });

          const result = await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions);

          return result;
        } catch (e) {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
        AggregationType.DATA_SOURCE_LOCATION,
      ),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.DATA_SOURCE_LOCATION]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.DATA_SOURCE_LOCATION,
            filterOperand: AggregationFilterOperand.DATA_SOURCE_LOCATION,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.OBJECT_OWNER),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.OBJECT_OWNER]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.OBJECT_OWNER,
            filterOperand: AggregationFilterOperand.OBJECT_OWNER,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.OBJECT_STATUS),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.OBJECT_STATUS]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.OBJECT_STATUS,
            filterOperand: AggregationFilterOperand.OBJECT_STATUS,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationBooleanItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.CLOUD_TYPE),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.CLOUD_TYPE]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.CLOUD_TYPE,
            filterOperand: AggregationFilterOperand.CLOUD_TYPE,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationNestedItemsToFilterOptions);
        } catch (e) {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.FILE_TYPE),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.FILE_TYPE]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.FILE_TYPE,
            filterOperand: AggregationFilterOperand.FILE_TYPE,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.DATA_FORMAT),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.DATA_FORMAT]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.DATA_FORMAT,
            filterOperand: AggregationFilterOperand.DATA_FORMAT,
            filter,
            value,
            searchText,
          });
          const filterOptionsPreprocessorFunc =
            aggregationFilterConfig[AggregationType.DATA_FORMAT]?.getFilterOptionsPreprocessed;

          return await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions, filterOptionsPreprocessorFunc);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.APPLICATION),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.APPLICATION]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.APPLICATION,
            filterOperand: AggregationFilterOperand.APPLICATION,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.TAGS),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.TAGS]}.name`),
      id: AggregationType.TAGS,
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.TAGS,
            filterOperand: AggregationFilterOperand.TAGS,
            filter,
            value,
            shouldFetchEverything: true,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationNestedItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
        AggregationType.SENSITIVITY_FILTER,
      ),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.SENSITIVITY_FILTER]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.SENSITIVITY_FILTER,
            filterOperand: AggregationFilterOperand.SENSITIVITY_FILTER,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationNestedItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
        AggregationType.ATTRIBUTE_CATEGORY,
      ),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.ATTRIBUTE_CATEGORY]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const valueComputed =
            aggregationFilterConfig[AggregationType.ATTRIBUTE_CATEGORY]?.getUserSearchQueryPreprocessed(value) ?? value;
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.ATTRIBUTE_CATEGORY,
            filterOperand: AggregationFilterOperand.ATTRIBUTE_CATEGORY,
            filter,
            value: valueComputed,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationNestedItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.HAS_DUPLICATES),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.HAS_DUPLICATES]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.HAS_DUPLICATES,
            filterOperand: AggregationFilterOperand.HAS_DUPLICATES,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationBooleanItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
        AggregationType.OBJECT_SCAN_STATUS,
      ),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.OBJECT_SCAN_STATUS]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.OBJECT_SCAN_STATUS,
            filterOperand: AggregationFilterOperand.OBJECT_SCAN_STATUS,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.VIOLATED_POLICY),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.VIOLATED_POLICY]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.VIOLATED_POLICY,
            filterOperand: AggregationFilterOperand.POLICY,
            filter,
            value,
            shouldFetchEverything: true,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDateRangeFilter>(NonAggregationType.SCAN_DATE),
      title: t(`${mapAggregationToFilterTranslationKey[NonAggregationType.SCAN_DATE]}.name`),
      value: [],
    },
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDateRangeFilter>(
        NonAggregationType.LAST_ACCESS_DATE,
      ),
      title: t(`${mapAggregationToFilterTranslationKey[NonAggregationType.LAST_ACCESS_DATE]}.name`),
      value: [],
    },
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDateRangeFilter>(
        NonAggregationType.MODIFIED_DATE,
      ),
      title: t(`${mapAggregationToFilterTranslationKey[NonAggregationType.MODIFIED_DATE]}.name`),
      value: [],
    },
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.CONTAINER_NAME),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.CONTAINER_NAME]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.CONTAINER_NAME,
            filterOperand: AggregationFilterOperand.CONTAINER_NAME,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.RISK),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.RISK]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.RISK,
            filterOperand: AggregationFilterOperand.RISK,
            filter,
            value,
            searchText,
          });
          const filterOptionsPreprocessorFunc =
            aggregationFilterConfig[AggregationType.RISK]?.getFilterOptionsPreprocessed;

          return await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions, filterOptionsPreprocessorFunc);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
    {
      ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
        AggregationType.ACCESS_TYPE_FILTER,
      ),
      title: t(`${mapAggregationToFilterTranslationKey[AggregationType.ACCESS_TYPE_FILTER]}.name`),
      value: [],
      asyncOptionsFetch: async (filter, value, searchText) => {
        try {
          const payload = getFilterOptionsFetchMethodPayload({
            aggName: AggregationType.ACCESS_TYPE_FILTER,
            filterOperand: AggregationFilterOperand.ACCESS_TYPE_FILTER,
            filter,
            value,
            searchText,
          });

          return await fetchFilterOptions(payload, parseAggregationItemsToFilterOptions);
        } catch {
          return [];
        }
      },
    } as BigidAdvancedToolbarDropdownFilter,
  ].filter(({ id }) => {
    //TODO: add isHidden parameter to the filter's config object on the BigidAdvancedToolbarFiltes level
    return (
      !filtersAvailabilityMap.hasOwnProperty(id) ||
      (filtersAvailabilityMap.hasOwnProperty(id) && filtersAvailabilityMap[id as AggregationType])
    );
  });
};
