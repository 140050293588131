import React, { useEffect, ReactNode, ReactElement, useRef, useCallback, useState } from 'react';
import { BigidColorsV2 } from '@bigid-ui/colors';
import { LegalEntitiesInterface } from './LegalEntitiesTypes';
import { useLegalEntityContext } from './LegalEntitiesContext';
import { LegalEntityEventEmitter, LegalEntityEvents } from './utils/EntityEvents';
import {
  EntityMainWrapper,
  EntityDetailsWrapper,
  EntityDetailsSectionWrapper,
  EntityDetailsSectionWrapperFullWidth,
  EntityDetailsSectionDescriptionWrapper,
  EntityDetailsAsideWrapper,
  AsideSectionHeaderWrapper,
  AsideSectionLabelWrapper,
  AsideSectionContentWrapper,
  LabelBadgeGroupWrapper,
  LabelBadgeGroupWrapperNoBorder,
  FitContentWrapper,
  FlexWithGap,
  TruncatedText,
  TruncatedTextAside,
  LegalEntityOverviewWrapper,
  ContentCardWrapper,
  ContentCardTextWrapper,
} from './LegalEntitiesCommonStyles';
import { FormLabel } from '@mui/material';
import { DynamicChipsArea } from '../Vendors/DynamicChipsArea';
import {
  BigidAddIcon,
  BigidBrandIllustration,
  BigidChevronDownIcon,
  BigidChevronUpIcon,
  BigidDefaultIcon,
  BigidEditIcon,
  IconMatcher,
} from '@bigid-ui/icons';
import { useCountryCodes } from './utils/useCountryCodes';
import {
  BigidAvatar,
  BigidHeading6,
  BigidTooltip,
  TertiaryButton,
  BigidStatusBadge,
  BigidBody1,
  BigidStatusBadgeSize,
  BigidChip,
  BigidLoader,
  BigidButtonIcon,
} from '@bigid-ui/components';
import { statusBadgeMapper } from './utils/common';
import { isPermitted } from '../../services/userPermissionsService';
import { LEGAL_ENTITIES_PERMISSIONS } from '@bigid/permissions';
import { trackLegalEntities, LegalEntitiesTrackingEvents } from './utils/analytics';
import { $state } from '../../../react/services/angularServices';
import { CONFIG } from '../../../config/common';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { getLegalEntities, getLevels } from './LegalEntitiesService';
import { notificationService } from '../../services/notificationService';
import { LegalEntitiesBrandingLogo } from './LegalEntitiesBrandingLogo';
import { BrandingWidget, InfoTooltip } from './LegalEntitiesCommonComponents';
import { useLegalEntitiesEditBrandingDialog, LegalEntitiesEditBrandingDialog } from './LegalEntitiesEditBrandingDialog';

const EntityDetailsMain = () => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [isFullDescription, setIsFullDescription] = useState<boolean>(false);
  const descriptionWrapperRef = useRef<HTMLSpanElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const { legalEntity = {} as LegalEntitiesInterface } = useLegalEntityContext();
  const { countryNameToCodeMapper } = useCountryCodes();

  const checkTruncation = useCallback(() => {
    const height = descriptionWrapperRef?.current?.offsetHeight;
    const scrollHeight = descriptionWrapperRef?.current?.scrollHeight;
    if (scrollHeight > height) {
      setShowMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalEntity]);

  const showMoreToggleHandler = useCallback(() => {
    if (showMore && isFullDescription) {
      descriptionWrapperRef.current.style.cssText = '-webkit-line-clamp: 2';
      setIsFullDescription(false);
    } else {
      descriptionWrapperRef.current.style.cssText = '-webkit-line-clamp: unset';
      setIsFullDescription(true);
    }
  }, [showMore, isFullDescription]);

  useEffect(() => {
    checkTruncation();
  }, [checkTruncation]);

  return (
    <EntityMainWrapper>
      <EntityDetailsWrapper>
        <EntityLabelWithValue
          label="Level"
          items={legalEntity?.level ? [{ label: legalEntity?.level?.name }] : undefined}
        />
        <EntityLabelWithValue
          label="Parent Entity"
          items={
            legalEntity?.name
              ? [
                  {
                    label: legalEntity?.parentEntity?.name,
                    icon: <LegalEntitiesBrandingLogo brandingId={legalEntity?.parentEntity?.branding?.brandingId} />,
                  },
                ]
              : undefined
          }
          singleChip
        />
        <EntityLabelWithValue
          label="Operating Locations"
          items={legalEntity?.operatingLocations?.map(location => ({
            label: location,
            icon: (
              <IconMatcher
                iconLabel={countryNameToCodeMapper[location]}
                size="small"
                viewBox="0 0 28 24"
                defaultIcon={BigidDefaultIcon}
                matchFunction="equals"
                key={location}
              />
            ),
          }))}
          dynamicChips
        />
        <EntityLabelWithValue
          label="Assets"
          items={legalEntity?.assets?.map(asset => {
            return {
              label: asset.name,
            };
          })}
          dynamicChips
        />
        <EntityLabelWithValue
          label="Owner name"
          items={
            legalEntity?.mainContactName
              ? [
                  {
                    label: legalEntity?.mainContactName,
                    icon: <BigidAvatar userName={legalEntity?.mainContactName} size="xsmall" />,
                  },
                ]
              : undefined
          }
        />
        <EntityLabelWithValue
          label="Owner email"
          items={legalEntity?.mainContactEmail ? [{ label: legalEntity?.mainContactEmail }] : undefined}
        />
        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
          <EntityDetailsSectionWrapperFullWidth>
            <SubSectionLabel text="Description" />
            <BigidTooltip title={legalEntity?.description} placement="top">
              <EntityDetailsSectionDescriptionWrapper ref={descriptionWrapperRef}>
                {legalEntity?.description}
              </EntityDetailsSectionDescriptionWrapper>
            </BigidTooltip>
          </EntityDetailsSectionWrapperFullWidth>
          {showMore && (
            <div ref={buttonRef}>
              <TertiaryButton
                onClick={() => {
                  showMoreToggleHandler();
                }}
                size="small"
                endIcon={isFullDescription ? <BigidChevronUpIcon /> : <BigidChevronDownIcon />}
                text={isFullDescription ? 'Show less' : 'Show more'}
                margin="0 0 4px 0"
              />
            </div>
          )}
        </div>
      </EntityDetailsWrapper>
    </EntityMainWrapper>
  );
};

const EntityDetailsAside = ({ isLastLevel }: { isLastLevel: boolean }) => {
  const [slicedData, setSlicedData] = useState<LegalEntitiesInterface[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { legalEntity, update } = useLegalEntityContext();
  const { openBrandingDialog, brandingDialogProps } = useLegalEntitiesEditBrandingDialog();

  useEffect(() => {
    const fetchSubEntities = async () => {
      const { subEntities, totalCount } = await getSubEntities(legalEntity?.id);
      const slicedSubEntities = subEntities.slice(0, 5);
      setSlicedData(slicedSubEntities);
      setTotalCount(totalCount);
    };
    if (legalEntity?.id) {
      fetchSubEntities();
    }
  }, [legalEntity]);

  const handleShowMore = useCallback(() => {
    $state.go(
      CONFIG.states.LEGAL_ENTITIES,
      { parentFilterId: legalEntity?.id },
      { reload: true, notify: false, inherit: false },
    );
  }, [legalEntity]);

  const openEditDialog = useCallback(async () => {
    trackLegalEntities(LegalEntitiesTrackingEvents.CREATE_SUB_ENTITY_ACTION);
    LegalEntityEventEmitter.emit(LegalEntityEvents.OPEN_EDIT_LEGAL_ENTITY_DIALOG);
  }, []);
  const parentName = legalEntity?.parentEntity?.name;

  return (
    <EntityDetailsAsideWrapper>
      <AsideSectionHeaderWrapper>
        <AsideSectionLabelWrapper>
          <BigidHeading6>{'Branding'}</BigidHeading6>
          <InfoTooltip title="Uploading a logo and changing the color scheme will update branding for all reports, emails, and materials linked to this legal entity." />
        </AsideSectionLabelWrapper>
        {isPermitted(LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_EDIT.name) &&
          (legalEntity.branding?.brandingId || legalEntity.branding?.inherited) && (
            <BigidButtonIcon
              icon={BigidEditIcon}
              onClick={async () => {
                trackLegalEntities(LegalEntitiesTrackingEvents.EDIT_BRANDING_CLICK);
                await openBrandingDialog(
                  legalEntity?.id,
                  legalEntity?.name,
                  parentName,
                  legalEntity.parentEntity?.branding?.brandingId,
                  legalEntity.branding,
                );
                update();
              }}
              size="small"
            />
          )}
        <LegalEntitiesEditBrandingDialog {...brandingDialogProps} />
      </AsideSectionHeaderWrapper>
      {legalEntity.branding?.brandingId || legalEntity.branding?.inherited ? (
        <BrandingWidget brandingId={legalEntity.branding?.brandingId} shouldRefetch />
      ) : (
        <ContentCard
          icon={BigidBrandIllustration}
          text="Add Branding"
          onClick={async () => {
            trackLegalEntities(LegalEntitiesTrackingEvents.CREATE_BRANDING_CLICK);
            await openBrandingDialog(
              legalEntity?.id,
              legalEntity?.name,
              parentName,
              legalEntity.parentEntity?.branding?.brandingId,
              legalEntity.branding,
            );
            update();
          }}
        />
      )}
      {!isLastLevel && (
        <AsideSectionHeaderWrapper paddingTop>
          <AsideSectionLabelWrapper>
            <BigidHeading6>{'Sub Entities'}</BigidHeading6>
            <BigidHeading6 paddingLeft={0.5} color={BigidColorsV2.gray[500]}>{`(${totalCount})`}</BigidHeading6>
          </AsideSectionLabelWrapper>
          {isPermitted(LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_CREATE.name) && !!totalCount && (
            <BigidButtonIcon
              icon={BigidAddIcon}
              onClick={() => {
                openEditDialog();
              }}
              size="small"
            />
          )}
        </AsideSectionHeaderWrapper>
      )}
      {!totalCount && !isLastLevel && <ContentCard text="Add Sub-entities" onClick={openEditDialog} />}
      {!!totalCount && !isLastLevel && (
        <AsideSectionContentWrapper>
          {slicedData?.map((value, i) => (
            <LabelBadgeGroupWrapper
              key={`${value.status}-${i}`}
              onClick={() => {
                trackLegalEntities(LegalEntitiesTrackingEvents.GO_TO_SUB_ENTITY_CLICK);
                $state.go(
                  CONFIG.states.LEGAL_ENTITIES_OVERVIEW,
                  { entityId: value.id, tab: 'overview' },
                  { reload: false, notify: false, inherit: true },
                );
                update();
              }}
            >
              <BigidTooltip title={value?.name} placement="right" enterNextDelay={2000} enterDelay={2000}>
                <TruncatedTextAside>
                  <LegalEntitiesBrandingLogo brandingId={value.branding?.brandingId} shouldRefetch />
                  <BigidBody1>{value?.name}</BigidBody1>
                </TruncatedTextAside>
              </BigidTooltip>
              <BigidStatusBadge
                type={statusBadgeMapper[value.status]}
                size={BigidStatusBadgeSize.SMALL}
                label={value?.status === 'UnderReview' ? 'Under Review' : value?.status}
              />
            </LabelBadgeGroupWrapper>
          ))}
          {totalCount > slicedData?.length && (
            <LabelBadgeGroupWrapperNoBorder>
              <BigidBody1
                color={BigidColorsV2.blue[700]}
                size="small"
                style={{ cursor: 'pointer' }}
                onClick={() => handleShowMore()}
              >
                {`View All (${totalCount})`}
              </BigidBody1>
            </LabelBadgeGroupWrapperNoBorder>
          )}
        </AsideSectionContentWrapper>
      )}
    </EntityDetailsAsideWrapper>
  );
};

const ContentCard = ({
  icon: Icon,
  text,
  onClick,
}: {
  icon?: typeof BigidBrandIllustration;
  text: string;
  onClick: () => void;
}) => {
  return (
    <ContentCardWrapper onClick={onClick}>
      {Icon && <Icon size="small" />}
      <ContentCardTextWrapper>
        <BigidAddIcon size="small" />
        <BigidBody1 size="small">{text}</BigidBody1>
      </ContentCardTextWrapper>
    </ContentCardWrapper>
  );
};

export const LegalEntityOverview = () => {
  const [isLastLevel, setIsLastLevel] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { legalEntity = {} as LegalEntitiesInterface } = useLegalEntityContext();

  useEffect(() => {
    if (legalEntity?.id) {
      setIsLoading(true);
      getLastLevelId().then(value => {
        setIsLastLevel(legalEntity?.level?.id === value);
        setIsLoading(false);
      });
    }
  }, [legalEntity]);

  return isLoading ? (
    <BigidLoader />
  ) : (
    <LegalEntityOverviewWrapper>
      <EntityDetailsMain />
      <EntityDetailsAside isLastLevel={isLastLevel} />
    </LegalEntityOverviewWrapper>
  );
};
const SubSectionLabel = ({ text }: { text: ReactNode }) => {
  return <FormLabel sx={{ color: BigidColorsV2.gray[500], marginBottom: 0 }}>{text}</FormLabel>;
};

const SingleChip = ({ values = [] }: { values: { label: string; icon?: ReactElement }[] }) => {
  return (
    <>
      {values.map((item, i) => {
        return item?.label ? (
          <FitContentWrapper key={`${item.label}-${i}`}>
            <BigidChip
              width="fill"
              variant="outlined"
              bgColor={BigidColorsV2.gray[50]}
              outlineColor={BigidColorsV2.gray[200]}
              size="small"
              label={item?.label}
              icon={item.icon}
            />
          </FitContentWrapper>
        ) : (
          <div key={`${item.label}-${i}`}>{'-'}</div>
        );
      })}
    </>
  );
};

const SimpleValue = ({ values = [] }: { values: { label: string; icon?: ReactElement }[] }) => {
  return (
    <>
      {values.length ? (
        values.map((item, i) => {
          return (
            item?.label && (
              <BigidTooltip key={`${item.label}-${i}`} title={item?.label}>
                <FlexWithGap>
                  {item?.icon}
                  <TruncatedText>{item?.label}</TruncatedText>
                </FlexWithGap>
              </BigidTooltip>
            )
          );
        })
      ) : (
        <div>{'-'}</div>
      )}
    </>
  );
};

const EntityLabelWithValue = ({
  items,
  singleChip,
  dynamicChips,
  label,
}: {
  items: { label: string; icon?: ReactElement }[];
  label: string;
  singleChip?: boolean;
  dynamicChips?: boolean;
}) => {
  return (
    <EntityDetailsSectionWrapper>
      <SubSectionLabel text={label} />
      {dynamicChips && <DynamicChipsArea values={items} withChips={dynamicChips} />}
      {singleChip && <SingleChip values={items} />}
      {!singleChip && !dynamicChips && <SimpleValue values={items} />}
    </EntityDetailsSectionWrapper>
  );
};

const getSubEntities = async (parentId: string) => {
  try {
    const query = {
      filter: [
        {
          field: 'parentId',
          operator: 'in',
          value: [parentId],
        },
      ],
      requireTotalCount: true,
    } as BigidGridQueryComponents;

    const { legalEntities, totalCount } = await getLegalEntities(query);
    return { subEntities: legalEntities, totalCount: totalCount };
  } catch (error) {
    notificationService.error(`Failed to fetch sub entities`);
    console.error(`Failed to fetch sub entities: ${JSON.stringify(error?.response)}`);
    return { subEntities: [], totalCount: 0 };
  }
};

const getLastLevelId = async () => {
  try {
    const { legalEntitiesLevel } = await getLevels();
    const lastLevel = legalEntitiesLevel[legalEntitiesLevel.length - 1];
    return lastLevel?.id as string;
  } catch (error) {
    notificationService.error(`Failed to fetch levels`);
    console.error(`Failed to fetch levels: ${JSON.stringify(error?.response)}`);
    return '';
  }
};
