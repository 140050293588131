import { useState, useCallback, useEffect } from 'react';
import { AsyncOperation } from '../../../../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../../../../services/sseService';
import { useLocalTranslation } from '../../../../translations';
import {
  DataExplorerAsyncOperationRoutingKey,
  DataExplorerAsyncOperationStatus,
  tagBulkAssignmentNameMapping,
  TagBulkAssignmentOperationsSorted,
  TagBulkAssignmentSseResults,
  UseTagBulkAssignmentResponse,
} from '../../../../types/DataExplorerAsyncOpsTypes';
import { TFunction } from 'react-i18next';

type OperationsProcessorParams = Pick<UseTagBulkParams, 'onIndexingOperationCompleted' | 'onOperationCompleted'> & {
  results: TagBulkAssignmentSseResults[];
  t: TFunction<'bigid/catalogSearchResults', string>;
};
// Utility function to process and update operations
const processOperations = ({
  onOperationCompleted,
  results,
  t,
  onIndexingOperationCompleted,
}: OperationsProcessorParams) => {
  const operationsSortedSkeleton = Object.keys(DataExplorerAsyncOperationStatus).reduce(
    (operationsSortedBase, status) => ({ ...operationsSortedBase, [status]: [] }),
    {},
  ) as TagBulkAssignmentOperationsSorted;

  const operationsSorted = results.reduce((operationsSortedAggr, operation) => {
    const { status } = operation;
    return { ...operationsSortedAggr, [status]: [...(operationsSortedAggr[status] || []), operation] };
  }, operationsSortedSkeleton);

  const operationsUpdated = [
    ...operationsSorted.ERROR,
    ...operationsSorted.COMPLETED,
    ...operationsSorted.RUNNING,
    ...operationsSorted.NOT_STARTED,
  ].reduce<AsyncOperation[]>((operationsAggr, serverOperation) => {
    const { name, payload, status, totalObjects, percentage, taskStatus, indexingStatus } = serverOperation;

    if (payload?.tags.length > 0) {
      const hasIndexingPostTask = taskStatus && indexingStatus;
      const { tagName, tagValue } = payload.tags[0];
      const taskSubject = `"${tagName}:${tagValue}"`;
      const taskDescription = totalObjects > 0 ? t('description.total', { totalObjects }) : t('description.counting');
      const taskOperation: AsyncOperation<DataExplorerAsyncOperationStatus> = {
        status: hasIndexingPostTask ? taskStatus : status,
        percentage:
          status === DataExplorerAsyncOperationStatus.RUNNING && typeof percentage === 'number'
            ? Math.round(percentage)
            : undefined,
        name: tagBulkAssignmentNameMapping[name],
        description: taskDescription,
        entityName: taskSubject,
      };

      operationsAggr.push(taskOperation);

      if (hasIndexingPostTask && totalObjects > 0) {
        const indexingOperation: AsyncOperation = {
          status: indexingStatus,
          name: t('indexing'),
          description: t('description.indexing', { totalObjects }),
          entityName: taskSubject,
        };

        operationsAggr.push(indexingOperation);
      }

      return operationsAggr;
    } else {
      return operationsAggr;
    }
  }, []);

  const hasCompletedOperations = operationsUpdated.some(
    ({ status }) => status === DataExplorerAsyncOperationStatus.COMPLETED,
  );

  const hasIndexingTaskCompleted = results.some(
    ({ indexingStatus }) => indexingStatus === DataExplorerAsyncOperationStatus.COMPLETED,
  );

  if (hasCompletedOperations) {
    onOperationCompleted();
  }

  if (hasIndexingTaskCompleted) {
    onIndexingOperationCompleted?.();
  }

  return operationsUpdated;
};

type UseTagBulkParams = {
  operationType: string;
  operationKey: DataExplorerAsyncOperationRoutingKey;
  tPrefix: string;
  onOperationRun: (key: DataExplorerAsyncOperationRoutingKey, isInProcess: boolean) => void;
  onOperationCompleted: () => void;
  onIndexingOperationCompleted?: () => void;
};

// Reusable hook for both assignment and unassignment
const useTagBulkOperation = ({
  operationKey,
  tPrefix,
  onOperationRun,
  onOperationCompleted,
  onIndexingOperationCompleted,
}: UseTagBulkParams): UseTagBulkAssignmentResponse => {
  const [operations, setOperations] = useState<AsyncOperation[]>([]);
  const [isOperationInProcess, setIsOperationInProcess] = useState<boolean>(false);
  const { t } = useLocalTranslation(`operations.tags.${tPrefix}`);

  useEffect(() => {
    onOperationRun(operationKey, isOperationInProcess);
  }, [isOperationInProcess, onOperationRun, operationKey]);

  const handleBroadcastEventReceived = useCallback(
    ({ results = [] }: SSEDataMessage<TagBulkAssignmentSseResults>) => {
      const operationsUpdated = processOperations({ results, t, onOperationCompleted, onIndexingOperationCompleted });
      setOperations(operationsUpdated);
      setIsOperationInProcess(!!results.length);
    },
    [onOperationCompleted, onIndexingOperationCompleted, t],
  );

  useEffect(() => {
    const unsubscribe = subscribeToRepeatedSSEEventById(operationKey, handleBroadcastEventReceived);

    return () => {
      unsubscribe();
    };
  }, [handleBroadcastEventReceived, operationKey]);

  return { operations };
};

type UseTagBulkProps = Pick<
  UseTagBulkParams,
  'onIndexingOperationCompleted' | 'onOperationCompleted' | 'onOperationRun'
>;

// Assignment Hook
export const useTagBulkAssignment = (params: UseTagBulkProps): UseTagBulkAssignmentResponse =>
  useTagBulkOperation({
    operationType: 'assignment',
    operationKey: DataExplorerAsyncOperationRoutingKey.TAG_BULK_ASSIGNMENT,
    tPrefix: 'assign',
    ...params,
  });

// Unassignment Hook
export const useTagBulkUnassignment = (params: UseTagBulkProps): UseTagBulkAssignmentResponse =>
  useTagBulkOperation({
    operationType: 'unassignment',
    operationKey: DataExplorerAsyncOperationRoutingKey.TAG_BULK_UNASSIGNMENT,
    tPrefix: 'unassign',
    ...params,
  });
