import React, { FunctionComponent, useEffect, useState, Fragment, useCallback, useRef, ReactNode } from 'react';
import { BigidNoDataIllustration } from '@bigid-ui/icons';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidColors,
  BigidContentItem,
  SecondaryButton,
  BigidLoader,
  useInterval,
  SystemAttributeType,
} from '@bigid-ui/components';
import {
  FetchClearAttrValuePayload,
  fetchClearAttributeValue,
  getPiiInvestigationData,
  deleteCachedValue,
} from './DataExplorerAttributesService';
import classNames from 'classnames';
import { Canceler } from 'axios';
import { useLocalTranslation } from '../../../../../translations';
import { notificationService } from '../../../../../../../services/notificationService';
import { analyticsService } from '../../../../../../../services/analyticsService';
import { DataExplorerEventsEnum, getBiEventName } from '../../../../../events';

const LOADER_RADIUS = 33;

interface AttributeClearValueStyle {
  isLoading: boolean;
}

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    padding: '12px',
    height: '100%',
    overflowY: 'auto',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  headText: {
    fontWeight: 400,
    fontSize: '0.8125rem',
  },
  caption: {
    color: BigidColors.gray[600],
  },
  deleteCache: {
    color: BigidColors.failureRed,
    cursor: 'pointer',
  },
  body: {
    backgroundColor: BigidColors.gray[50],
    border: `1px solid ${BigidColors.gray[50]}`,
    borderRadius: 4,
    padding: '12px',
    overflowY: 'auto',
  },
  fetch: ({ isLoading }: AttributeClearValueStyle) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: isLoading ? 12 : 7,
    height: isLoading ? LOADER_RADIUS : 'auto',
  }),
  text: {
    textAlign: 'center',
  },
  regularText: {
    fontSize: '0.75rem',
    color: BigidColors.gray[600],
  },
  enlargedText: {
    fontSize: '0.875rem',
    color: BigidColors.gray[700],
    marginBottom: 9,
  },
  failureMessage: {
    fontSize: '0.75rem',
    color: BigidColors.failureRed,
  },
  value: {
    fontSize: '0.9375rem',
    fontWeight: 400,
    whiteSpace: 'pre-line',
    color: BigidColors.gray[600],
    '& mark': {
      lineHeight: '1.875rem',
      border: `1px solid ${BigidColors.green[700]}`,
      backgroundColor: BigidColors.green[50],
      borderRadius: 4,
      '-webkit-box-decoration-break': 'clone',
      'box-decoration-break': 'clone',
    },
  },
});

export interface AttributeClearValueProps {
  dataAid: string;
  fullyQualifiedName: string;
  selectedItem: BigidContentItem;
  isExpired: boolean;
  onFetched: () => void;
}

type AttributeClearValueHighlight = { length: number; offset: number };

const STATE_FAILED = 'failed';
const STATE_COMPLETED = 'completed';

const FETCH_CLEAR_VALUE_INTERVAL = 5000;
const FETCH_CLEAR_VALUE_DELAY = 1000;

const getDisplayValue = (value: string, highlight: AttributeClearValueHighlight, dataAid: string): ReactNode => {
  if (highlight) {
    const { offset, length } = highlight;
    const offsetNormalized = offset - 1;
    const valueHead = value.slice(0, offsetNormalized);
    const highlightedValue = value.slice(offsetNormalized, offsetNormalized + length);
    const valueTail = value.slice(offsetNormalized + length, value.length - 1);

    return (
      <>
        {valueHead}
        <mark data-aid={`${dataAid}-highlighted`}>{highlightedValue}</mark>
        {valueTail}
      </>
    );
  }

  return value;
};

export const AttributeClearValue: FunctionComponent<AttributeClearValueProps> = ({
  dataAid,
  fullyQualifiedName,
  selectedItem,
  isExpired,
  onFetched,
}: AttributeClearValueProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isExpiredWithoutCache, setIsExpiredWithoutCache] = useState<boolean>(false);
  const [value, setValue] = useState<string>(null);
  const [highlight, setHighlight] = useState<AttributeClearValueHighlight>(null);
  const [existingInvestigationScanId, setExistingInvestigationScanId] = useState<string>();
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [isIntervalFetchActive, setIsIntervalFetchActive] = useState<boolean>(false);
  const [isInvestigationSupported, setIsInvestigationSupported] = useState<boolean>(false);

  const investigationScanIdRef = useRef<string>();
  const fetchValueCancelerRef = useRef<Canceler>();

  const classes = useStyles({ isLoading });
  const { t } = useLocalTranslation('DataExplorerAttributes');

  const fetchClearValue = useCallback(
    async (selectedItem: BigidContentItem, fullyQualifiedName: string, scanId?: string) => {
      try {
        let investigationId: string;

        if (!scanId) {
          const { attribute_original_name, attribute_type } = selectedItem;
          const fetchClearValuePayload: FetchClearAttrValuePayload = {
            attribute_name: attribute_original_name,
            attribute_type: attribute_type as SystemAttributeType,
            fullyQualifiedName,
          };

          investigationId = (await fetchClearAttributeValue(fetchClearValuePayload)).data.pop().piiInvestigationId;
          investigationScanIdRef.current = investigationId;
        } else {
          investigationId = scanId;
        }

        const { promise, cancel } = getPiiInvestigationData(investigationId);

        fetchValueCancelerRef.current = cancel;

        const {
          data: { state, ds_value, highlight },
        } = await promise;

        switch (state.toLowerCase()) {
          case STATE_FAILED:
            setIsFailed(true);
            investigationScanIdRef.current = null;
            fetchValueCancelerRef.current = null;
            setIsIntervalFetchActive(false);
            setExistingInvestigationScanId(null);
            setIsLoading(false);
            break;
          case STATE_COMPLETED:
            setValue(ds_value);
            setIsExpiredWithoutCache(!ds_value && isExpired);
            setIsLoading(false);
            setIsFailed(false);
            setHighlight(highlight);
            setIsIntervalFetchActive(false);
            setExistingInvestigationScanId(null);
            investigationScanIdRef.current = null;
            fetchValueCancelerRef.current = null;
            break;
          default:
            setIsIntervalFetchActive(true);
        }

        return { shouldNotifyGrid: !Boolean(scanId) };
      } catch (error) {
        if (error.message !== 'cancelled') {
          if (isExpired) {
            setIsExpiredWithoutCache(true);
          } else {
            console.error(error);
            notificationService.error('An error has occured');
            setExistingInvestigationScanId(null);
            investigationScanIdRef.current = null;
          }
        }

        setIsLoading(false);
        setIsIntervalFetchActive(false);
        fetchValueCancelerRef.current = null;
      }
    },
    [isExpired],
  );

  const handleFetchValueClick = (): void => {
    setIsLoading(true);
    fetchClearValue(selectedItem, fullyQualifiedName).then(({ shouldNotifyGrid }) => {
      shouldNotifyGrid && onFetched();
    });
  };

  const handleDeleteCache = (): void => {
    const { attribute_original_name, attribute_type } = selectedItem;
    const deleteCachedValuePayload: FetchClearAttrValuePayload = {
      attribute_name: attribute_original_name,
      attribute_type: attribute_type as SystemAttributeType,
      fullyQualifiedName,
    };

    setIsDeleting(true);
    setIsLoading(true);
    setTimeout(() => {
      deleteCachedValue(deleteCachedValuePayload)
        .then(() => {
          onFetched();
          setValue(null);
          setExistingInvestigationScanId(null);
          investigationScanIdRef.current = null;
        })
        .catch(error => {
          console.error(error);
          notificationService.error('An error has occured');
        })
        .finally(() => {
          setIsDeleting(false);
          setIsLoading(false);
        });
    }, FETCH_CLEAR_VALUE_DELAY);
  };

  useInterval(
    () => {
      fetchClearValue(selectedItem, fullyQualifiedName, investigationScanIdRef.current).then(({ shouldNotifyGrid }) => {
        shouldNotifyGrid && onFetched();
      });
    },
    isIntervalFetchActive ? FETCH_CLEAR_VALUE_INTERVAL : null,
  );

  useEffect(() => {
    const { is_support_investigation, investigation_scan_id_list } = selectedItem;
    setIsInvestigationSupported(is_support_investigation);

    setIsIntervalFetchActive(false);

    //NOTE: current use case, later there will be more than 1 item
    const [existedInvestigationId] = investigation_scan_id_list;

    if (existedInvestigationId) {
      setIsLoading(true);
      setExistingInvestigationScanId(existedInvestigationId);
      setTimeout(() => {
        fetchClearValue(selectedItem, fullyQualifiedName, existedInvestigationId);
      }, FETCH_CLEAR_VALUE_DELAY);
    } else {
      setIsLoading(false);
      setExistingInvestigationScanId(null);
    }

    return () => {
      setValue(null);
      fetchValueCancelerRef.current?.('cancelled');
      fetchValueCancelerRef.current = null;
    };
  }, [selectedItem, fullyQualifiedName, fetchClearValue]);

  useEffect(() => {
    if (isExpiredWithoutCache) {
      analyticsService.trackManualEvent(getBiEventName(DataExplorerEventsEnum.ATTRIBUTE_PREVIEW_EXPIRED_MSG));
    }
  }, [isExpiredWithoutCache]);

  return (
    <div className={classes.wrapper}>
      {isExpiredWithoutCache ? (
        <BigidLayoutEmptyState illustration={BigidNoDataIllustration} description={t('noPreviewMessage')} />
      ) : (
        <div className={classes.content}>
          {value && !isDeleting && (
            <div className={classes.head}>
              <div className={classNames(classes.headText, classes.caption)}>Cached value</div>
              <div className={classNames(classes.headText, classes.deleteCache)} onClick={handleDeleteCache}>
                Delete cache
              </div>
            </div>
          )}
          <div className={classes.body}>
            {value && !isDeleting ? (
              <div className={classes.value} data-aid={`${dataAid}-value`}>
                {getDisplayValue(value, highlight, `${dataAid}-value`)}
              </div>
            ) : (
              <Fragment>
                {isInvestigationSupported ? (
                  <Fragment>
                    <div className={classes.fetch}>
                      {isLoading ? (
                        <BigidLoader size={LOADER_RADIUS} />
                      ) : (
                        <Fragment>
                          {!existingInvestigationScanId && (
                            <SecondaryButton
                              size="medium"
                              onClick={handleFetchValueClick}
                              dataAid={`${dataAid}-get-attribute-value-btn`}
                              text={isFailed ? 'Try again' : 'Get attribute value'}
                            />
                          )}
                        </Fragment>
                      )}
                    </div>
                    {isLoading ? (
                      <div className={classNames(classes.text, classes.enlargedText)}>
                        {isDeleting ? 'Deleting cache' : 'Fetching value'}...
                      </div>
                    ) : (
                      <Fragment>
                        {!existingInvestigationScanId && (
                          <div
                            className={classNames(
                              classes.text,
                              isFailed ? classes.failureMessage : classes.regularText,
                            )}
                          >
                            {isFailed
                              ? 'Unable to fetch value. Please, try again later.'
                              : 'Fetches the attribute’s value from the original object'}
                          </div>
                        )}
                      </Fragment>
                    )}
                    {isLoading && !isDeleting && (
                      <div className={classNames(classes.text, classes.regularText)}>(Check back in a few minutes)</div>
                    )}
                  </Fragment>
                ) : (
                  <div className={classNames(classes.text, classes.regularText)}>
                    Fetching values is unavailable for this attribute
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
