import React, { FC, useState, useEffect, useCallback, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import {
  BigidContentItem,
  useInterval,
  EntityEvents,
  entityEventsEmitter,
  BigidButtonIcon,
} from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import {
  CuratedField,
  CuratedFieldStatus,
  FieldValueChunk,
  getFindings,
  excludeFinding,
  unexcludeFinding,
  CurateFindingPayload,
  DeleteCurateFindingPayload,
  FindingCurateProps,
  PreviewJobRequest,
  triggerGlobalPreview,
} from '../../../../curationService';
import { notificationService } from '../../../../../../services/notificationService';
import { CuratedFieldValuePreview } from '../../../CuratedFieldValuePreview/CuratedFieldValuePreview';
import { CuratedFieldValuePreviewNoResults } from '../../../CuratedFieldValuePreview/CuratedFieldValuePreviewNoResults';
import {
  CuratedFieldValuePreviewError,
  CuratedFieldPreviewErrorType,
} from '../../../CuratedFieldValuePreview/CuratedFieldValuePreviewError';
import { useLocalTranslation } from '../../../../translations';
import { isPermitted } from '../../../../../../services/userPermissionsService';
import { CLASSIFIERS_PERMISSIONS, GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import { CuratedFieldValuePreviewLoading } from '../../../CuratedFieldValuePreview/CuratedFieldValuePreviewLoading';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { CurationGuidedTourStageId } from '../../../../useCurationState';
import { CurationTabsLayoutStateContext } from '../../../../CurationStateContext';
import { clearFromCacheForUnstructured } from './attributePreviewService';

interface AttributePreviewProps extends BigidContentItem, Omit<CuratedField, 'tags'> {
  dataAid?: string;
  contentContainerClassName?: string;
}

const EXPIRED_ATTR_PREVIEW_ERR_CODE = 422;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '8px',
    position: 'relative',
  },
  helpIcon: {
    position: 'absolute',
    display: 'flex',
    alignSelf: 'flex-end',
  },
});

const currentGuidedTour = CurationGuidedTourStageId.PREVIEW;

export const AttributePreview: FC<AttributePreviewProps> = ({
  dataAid = 'AttributePreviewUnstructured',
  id,
  fieldName,
  fullyQualifiedName,
  attributeName,
  attributeType,
  curatedFieldsGridId,
  source,
  contentContainerClassName,
}) => {
  const classes = useStyles();
  const { t } = useLocalTranslation('CuratedFields.unstructured.AttributePreview');
  const { onProceedToFieldsReviewTab, onCurationGuidedTourStart } = useContext(CurationTabsLayoutStateContext);

  const [valueChunks, setValueChunks] = useState<FieldValueChunk[]>([]);
  const [isPreviewDataFetched, setIsPreviewDataFetched] = useState<boolean>(false);
  const [previewDataFetchError, setPreviewDataFetchError] = useState<string>();
  const [isIntervalFetchActive, setIsIntervalFetchActive] = useState<boolean>(false);
  const [previewErrorType, setPreviewErrorType] = useState<CuratedFieldPreviewErrorType>(
    CuratedFieldPreviewErrorType.ERROR,
  );
  const [isGridReload, setIsGridReload] = useState([]);
  const isPermittedToExclude =
    isPermitted(CLASSIFIERS_PERMISSIONS.EDIT.name) &&
    isPermitted(CLASSIFIERS_PERMISSIONS.DELETE.name) &&
    isPermitted(GENERAL_SETTINGS_PERMISSIONS.EDIT_IGNORED_LISTS.name) &&
    isPermitted(GENERAL_SETTINGS_PERMISSIONS.CREATE_IGNORED_LISTS.name);

  useEffect(() => {
    const openGuidedTour = valueChunks?.length > 0;
    onProceedToFieldsReviewTab(currentGuidedTour, openGuidedTour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueChunks?.length > 0]);

  useInterval(
    () => {
      isIntervalFetchActive && setIsGridReload([]);
    },
    isIntervalFetchActive && valueChunks?.length === 0 ? 10000 : null,
  );

  const handleFindingCurate = async ({ finding, status, fieldId, matchType }: FindingCurateProps) => {
    const { findingValue, excludePatternId } = finding;
    try {
      const excludePayload: CurateFindingPayload = {
        curationFieldId: fieldId,
        value: findingValue,
        matchType: matchType,
      };

      const unexcludePayload: DeleteCurateFindingPayload = {
        curationFieldId: fieldId,
        patternId: excludePatternId,
      };

      switch (status) {
        case CuratedFieldStatus.REJECTED:
          setIsPreviewDataFetched(false);
          await excludeFinding(excludePayload);
          return fetchFindings();
        case CuratedFieldStatus.UNCURATED:
          setIsPreviewDataFetched(false);
          await unexcludeFinding(unexcludePayload);
          return fetchFindings();
      }
    } catch ({ message }) {
      notificationService.error(t('errors.curationError'));
      console.error(`${t('errors.generic')}: ${message}`);
    }
  };

  const handlePreviewError = useCallback(
    (attributeName: string, error_code?: number) => {
      if (error_code === EXPIRED_ATTR_PREVIEW_ERR_CODE) {
        setPreviewDataFetchError(t('errors.expiredPreview'));
        setPreviewErrorType(CuratedFieldPreviewErrorType.EXPIRED_ATTRIBUTE);
      } else {
        setPreviewDataFetchError(t('errors.fetchingPreview'));
      }
      setValueChunks([]);
    },
    [t],
  );

  useEffect(() => {
    if (valueChunks?.length > 0) {
      entityEventsEmitter.emit(EntityEvents.RELOAD, curatedFieldsGridId);
    }
  }, [curatedFieldsGridId, valueChunks]);

  const fetchFindings = async () => {
    return getFindings(id.toString())
      .then(({ data }) => {
        const { findings } = data;
        if (Array.isArray(findings) && findings.length > 0) {
          setValueChunks(findings?.[0]?.fields?.[0]?.fieldValueChunks ?? []);
          setPreviewDataFetchError(null);
        } else {
          handlePreviewError(attributeName);
        }
      })
      .catch(({ message, response }) => {
        handlePreviewError(attributeName, response?.status);
        console.error(`${t('errors.generic')}: ${message}`);
        if (response?.status !== EXPIRED_ATTR_PREVIEW_ERR_CODE) {
          notificationService.error(t('errors.fetchingPreview'));
        }
      })
      .finally(() => {
        setIsPreviewDataFetched(true);
      });
  };

  useEffect(() => {
    setIsPreviewDataFetched(false);
    fetchFindings();
  }, [attributeName, attributeType, fullyQualifiedName, fieldName, t, handlePreviewError, isGridReload, id]);

  const onPreviewClick = async () => {
    try {
      setIsPreviewDataFetched(false);
      setIsIntervalFetchActive(true);
      const previewJobBody: PreviewJobRequest = {
        attributeName,
        source: source,
      };
      await triggerGlobalPreview(previewJobBody);
      notificationService.success(t('successNotification'));
    } catch ({ message }) {
      notificationService.error(t('errors.generic'));
      console.error(`An error has occurred: ${message}`);
    }
  };

  const handleHelpIconClick = () => {
    onCurationGuidedTourStart(currentGuidedTour);
  };

  const handleClearFromCacheClick = async () => {
    try {
      await clearFromCacheForUnstructured(fullyQualifiedName);
      setPreviewDataFetchError(t('errors.fetchingPreview'));
      setValueChunks([]);
    } catch ({ message }) {
      notificationService.error(t('errors.clearFromCache'));
      console.error(`An error has occurred: ${message}`);
    }
  };

  return (
    <div className={contentContainerClassName}>
      <div
        data-aid={generateDataAid(dataAid, [fullyQualifiedName, fieldName])}
        className={classes.root}
        data-tour-id={generateGuidedTourId(currentGuidedTour, ['start'])}
      >
        {isPreviewDataFetched ? (
          <>
            {previewDataFetchError ? (
              <CuratedFieldValuePreviewError
                dataAid={generateDataAid(dataAid, [fullyQualifiedName, fieldName, previewErrorType])}
                onPreviewClick={onPreviewClick}
                errorType={previewErrorType}
                errorText={previewDataFetchError}
              />
            ) : (
              <>
                {valueChunks?.length > 0 ? (
                  <CuratedFieldValuePreview
                    dataAid={generateDataAid(dataAid, [fullyQualifiedName, fieldName, 'results'])}
                    valueChunks={valueChunks}
                    onFindingCurate={handleFindingCurate}
                    onClearFromCacheClick={handleClearFromCacheClick}
                    isPermittedToExclude={isPermittedToExclude}
                    fieldId={id}
                  />
                ) : (
                  <CuratedFieldValuePreviewNoResults
                    dataAid={generateDataAid(dataAid, [fullyQualifiedName, fieldName, 'no-results'])}
                  />
                )}
                <div className={classes.helpIcon}>
                  <BigidButtonIcon icon={BigidHelpIcon} onClick={handleHelpIconClick} />
                </div>
              </>
            )}
          </>
        ) : (
          <CuratedFieldValuePreviewLoading />
        )}
      </div>
    </div>
  );
};
