import { SelectOnboardingTypeCardProps } from '../../types';
import React, { FC, useMemo } from 'react';
import { BigidBody1, BigidButtonIcon, BigidImageCard } from '@bigid-ui/components';
import { BigidInfoIcon, DataSourceIconByDsType } from '@bigid-ui/icons';
import { CloudProvider, TYPE_TO_FULL_DOCS_LINK, TYPE_TO_ICON } from '../../../../AutoDiscovery/constants';
import { generateDataAid } from '@bigid-ui/utils';
import { DS_TYPES_FOR_SHOW } from '../../../../AutoDiscovery/SelectDiscoveryTypeModal/SelectDiscoveryTypeModal';
import { styled } from '@mui/material';
import { useLocalTranslation } from '../../translations';
import { HexagonStyled } from '../HexagonStyled';

export const DsIconsRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
  flex-direction: row-reverse;
  & > * {
    margin-left: -10px;
  }
`;

export const DescriptionWrapper = styled('div')`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const AUTO_DISCOVERY_TYPE_SELECT_CARD_SIZE = {
  height: '140px',
  width: '375px',
};

export const AutoDiscoveryTypeSelectCard: FC<SelectOnboardingTypeCardProps> = ({
  displayName,
  name,
  type,
  onSelectType,
}) => {
  const { t } = useLocalTranslation('cards');
  const Icon = TYPE_TO_ICON[type as CloudProvider];
  const dsTypes = useMemo(() => {
    const items = [...DS_TYPES_FOR_SHOW[type as CloudProvider]];
    return items.reverse();
  }, [type]);

  return (
    <BigidImageCard
      dataAid={`AutoDiscoveryTypeSelectCard-${type}`}
      size={AUTO_DISCOVERY_TYPE_SELECT_CARD_SIZE}
      rightSlot={
        <BigidButtonIcon
          icon={BigidInfoIcon}
          onClick={() => window.open(TYPE_TO_FULL_DOCS_LINK[type as CloudProvider], '_blank')}
          tooltipText="Documentation"
        />
      }
      image={<Icon width={32} height={32} size={'large'} />}
      title={displayName || name}
      onCardClick={onSelectType}
      customDescription={
        <DescriptionWrapper>
          <BigidBody1 sx={{ marginRight: '12px' }}>{t('discoveryDescription')}</BigidBody1>
          <DsIconsRow>
            {dsTypes.map(dsType => (
              <HexagonStyled key={dsType} size={'24px'}>
                <DataSourceIconByDsType
                  data-aid={generateDataAid('ds-type-icon', [dsType])}
                  dataSourceType={dsType}
                  width={16}
                />
              </HexagonStyled>
            ))}
          </DsIconsRow>
        </DescriptionWrapper>
      }
    />
  );
};
