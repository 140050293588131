import React, { FC, useEffect, useState } from 'react';
import {
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridColumnTypes,
  BigidGridColumn,
  BigidGridQueryComponents,
  BigidGridRow,
} from '@bigid-ui/grid';
import { getAttributesDetailsByParentId, getDataSourcesDetailsByParentId } from '../ScanInsightService';
import { BigidPaper, BigidTooltip } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { queryService } from '../../../../services/queryService';
import { AttributeDetails } from '../ScanInsightTypes';
import { EmptyTemplate } from '../../../../components/EmptyTemplate/EmptyTemplate';
import { ViewDetailsDocumentation } from '../ViewDetailsDocumentation/ViewDetailsDocumentation';
import { ParentScanType } from '../../ScanTypes';
import { BigidHelpLargeIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';

interface AttributesViewDetailsProps {
  scanId: string;
  type?: string;
  title: string;
  scannedDataSources: number;
  scanType?: ParentScanType;
}

enum ClassifierStatus {
  Applied = 'Applied',
  Failed = 'Failed',
  Skipped = 'Skipped',
}

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '70vh',
    maxHeight: '70vh',
    overflow: 'hidden',
  },
});

const StatusBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const columnsDefault: BigidGridColumn<AttributeDetails & BigidGridRow>[] = [
  {
    name: 'name',
    title: 'Attribute Name',
    width: 450,
    getCellValue: ({ _id: name }) => name,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'new',
    title: 'New',
    sortingEnabled: true,
    getCellValue: ({ new: newCount }) => newCount || 0,
    type: BigidGridColumnTypes.NUMBER,
  },
  {
    name: 'removed',
    title: 'Removed',
    sortingEnabled: true,
    getCellValue: ({ removed }) => removed || 0,
    type: BigidGridColumnTypes.NUMBER,
  },
];

const columnsHyperscan: BigidGridColumn<AttributeDetails & BigidGridRow>[] = [
  {
    name: 'name',
    title: 'Attribute Name',
    width: 450,
    getCellValue: ({ _id: name }) => name,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'status',
    title: 'Status',
    sortingEnabled: false,
    disableTooltip: true,
    getCellValue: ({ status }) => {
      let title;
      switch (status) {
        case ClassifierStatus.Applied:
          title =
            'AI predicted high accuracy for this classifier, so it was applied to the dataset to discover attributes';
          break;
        case ClassifierStatus.Failed:
          title = "AI predicted low accuracy for this classifier, so it wasn't applied";
          break;
        case ClassifierStatus.Skipped:
          title = "Not enough sampling findings to evaluate this classifier's accuracy";
          break;
      }
      return (
        <StatusBox>
          <span>{status}</span>
          <BigidTooltip title={title}>
            <span>
              <BigidHelpLargeIcon />
            </span>
          </BigidTooltip>
        </StatusBox>
      );
    },
    type: BigidGridColumnTypes.TEXT,
  },
];

export const AttributesViewDetails: FC<AttributesViewDetailsProps> = ({
  scanId,
  type,
  title,
  scannedDataSources,
  scanType,
}) => {
  const [isOldFlow, setIsOldFlow] = useState(true);
  const [isFinishLoading, setIsFinishLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    handleOldFlow();
  }, []);

  const handleOldFlow = async () => {
    if (scannedDataSources === 0) {
      return;
    }
    const response = await getDataSourcesDetailsByParentId(scanId, 'limit=1');
    setIsFinishLoading(true);
    if (response?.dataSourcesInsights) {
      setIsOldFlow(false);
    }
  };

  const gridConfig: BigidGridWithToolbarProps<any> = {
    pageSize: 10,
    entityName: title,
    defaultSorting: [{ field: 'new', order: 'desc' }],
    showSortingControls: true,
    columns: scanType === ParentScanType.HYPERSCAN ? columnsHyperscan : columnsDefault,
    filterToolbarConfig: {
      filters:
        scanType !== ParentScanType.HYPERSCAN
          ? [
              {
                title: 'Status',
                field: 'status',
                operator: 'greaterThan',
                disabled: true,
                single: true,
                value: [],
                options: [
                  {
                    label: 'New',
                    value: 'new',
                    isSelected: false,
                  },
                  {
                    label: 'Removed',
                    value: 'removed',
                    isSelected: false,
                  },
                ],
              },
            ]
          : [],
      searchConfig: {
        searchFilterKeys: ['name'],
        initialValue: '',
        placeholder: `${type} name`,
        operator: 'textSearch',
        autoSubmit: true,
      },
    },
    fetchData: async (queryComponents: BigidGridQueryComponents) => {
      if (scannedDataSources === 0) {
        return { data: [], totalCount: 0 };
      }
      const { filter } = queryComponents;
      const requiredField = filter.find(row => row.field === 'status');
      if (requiredField && (requiredField.value === 'new' || requiredField.value === 'removed')) {
        requiredField.field = requiredField.value as string;
        requiredField.value = 0;
      }
      const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
      const response = await getAttributesDetailsByParentId({ parentId: scanId, type, gridConfigQuery });
      return { data: response.dataSourcesInsights, totalCount: response.totalCount };
    },
  };

  const isShowBigidGrid = () => {
    return scannedDataSources === 0 || (isFinishLoading && isOldFlow === false);
  };

  return (
    <>
      {isFinishLoading && isOldFlow === true && <EmptyTemplate content={<ViewDetailsDocumentation />} />}
      {isShowBigidGrid() && (
        <div className={classes.gridWrapper}>
          <BigidPaper>
            <BigidGridWithToolbar {...gridConfig} />
          </BigidPaper>
        </div>
      )}
    </>
  );
};
