import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { Candidate, RelationGridRow } from './types';
import { getIconByObjectType, getObjectTypeName } from '../utils';
import { BigidDatasetIcon, BigidVectorDbIcon, DataSourceIconByDsType } from '@bigid-ui/icons';
import React from 'react';
import { TagAssignmentTarget, TagEntity } from '../../TagsManagement/TagsManagementService';
import { getTagFormattedName, getTagIcon } from '../../TagsManagement/TagsManagementUtils';
import { BigidColors, BigidDropdownOption } from '@bigid-ui/components';

export const columns: () => BigidGridColumn<RelationGridRow>[] = () => [
  {
    title: 'Object Name',
    name: 'objectName',
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ objectName }) => objectName,
  },
  {
    title: 'Type',
    name: 'objectType',
    type: BigidGridColumnTypes.ICON,
    getCellValue: ({ extendedObjectType }) => ({
      icon: {
        icon: getIconByObjectType(extendedObjectType),
        label: getObjectTypeName(extendedObjectType),
      },
    }),
  },
  {
    title: 'Data Source',
    name: 'type',
    type: BigidGridColumnTypes.ICON,
    getCellValue: ({ source, scannerType }) => ({
      icon: {
        icon: () => <DataSourceIconByDsType dataSourceType={scannerType} width={24} height={24} />,
        label: source,
      },
    }),
  },
  {
    title: 'Attributes',
    name: 'attributes',
    type: BigidGridColumnTypes.CHIPS,
    width: 400,
    getCellValue: ({ attributes }) => {
      return {
        chips: {
          value: attributes.map(attr => ({ label: attr })),
          isDisabled: true,
          isAutoFit: false,
        },
      };
    },
  },
  {
    title: 'Tags',
    name: 'manualTags',
    type: BigidGridColumnTypes.TAGS,
    width: 400,
    getCellValue: ({ manualTags }) => ({
      tags: {
        ...manualTags.tags,
        value: manualTags.tags.value.map(tagDetails => ({
          ...tagDetails,
          name: tagDetails.name,
        })),
      },
    }),
  },
];

export function getManualTagsByTags(tags: TagEntity[]) {
  return {
    tags: {
      value: tags
        .reduce((tagsFiltered, tag) => {
          if (tag.tagType === TagAssignmentTarget.object) {
            return [tag, ...tagsFiltered];
          } else {
            return tagsFiltered.find(t => t.tagName === tag.tagName && t.tagValue === tag.tagValue)
              ? tagsFiltered
              : [...tagsFiltered, tag];
          }
        }, [])
        .filter(({ properties }) => !properties?.hidden)
        .map(({ tagName, tagValue, tagType, properties }) => ({
          name: getTagFormattedName(tagName),
          value: tagValue,
          iconDescription: tagType === TagAssignmentTarget.column ? tagType : undefined,
          icon: getTagIcon(properties, tagType),
        })),
      isDisabled: true,
      isAutoFit: false,
      entityMaxWidth: 150,
      tagBgColor: BigidColors.purple[50],
    },
  };
}

export function mapCandidatesToOptions(candidates: Candidate[]) {
  const optionsMap = new Map<string, BigidDropdownOption>();

  candidates.forEach((candidate, index) => {
    if (!optionsMap.has(candidate.extendedObjectType)) {
      optionsMap.set(candidate.extendedObjectType, {
        displayValue: candidate.extendedObjectType === 'vector' ? 'Vector DB' : 'Datasets',
        icon: candidate.extendedObjectType === 'vector' ? BigidVectorDbIcon : BigidDatasetIcon,
        id: index.toString(),
        value: candidate.extendedObjectType,
        children: [],
      });
    }

    const parent = optionsMap.get(candidate.extendedObjectType)!;
    parent.children?.push({
      displayValue: candidate.objectName,
      id: `${parent.id}-${parent.children?.length}`,
      parentId: parent.id,
      value: candidate.fullyQualifiedName,
    });
  });

  return Array.from(optionsMap.values());
}
