import { BigidButtonIcon, BigidMenu } from '@bigid-ui/components';
import { BigidMoreActionIcon } from '@bigid-ui/icons';
import React, { useRef, useState } from 'react';

export const LegalEntitiesTitleMenu = ({ onClick }: { onClick: () => void }) => {
  const [open, setOpen] = useState(false);
  const iconRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <BigidButtonIcon onClick={() => setOpen(open => !open)} icon={BigidMoreActionIcon} ref={iconRef} />
      <BigidMenu
        open={open}
        onMenuClose={() => setOpen(false)}
        anchorEl={iconRef.current as Element}
        paperStyle={{ marginTop: '8px' }}
        items={[
          {
            id: 'edit-hierarchy-levels',
            label: 'Edit Hierarchy Levels',
            onClick,
          },
        ]}
      />
    </>
  );
};
