import React, { FC, useCallback, useState, useEffect, memo } from 'react';
import { BigidSkeletonGenerator } from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { UseCatalogDiscoveryResponse } from '../../useCatalogDiscovery';
import { PieChartWidget, PieChartWidgetSeries, PieChartWidgetProps } from '../../widgets/PieChartWidget/PieChartWidget';
import { getAggregatedData, GetAggregatedDataPayload, GetAggregatedDataResponse } from '../../catalogDiscoveryService';
import {
  AggregationFilterOperand,
  AggregationType,
  SensitivityClassificationAggregationItem,
} from '../../catalogDiscoveryTypes';
import {
  getFiltersExcludingCurrentType,
  getCurrentTypeAppliedOptions,
  getSensitivityPieChartData,
} from '../../utils/widgets';
import { PieChartWidgetWrapper } from '../../utils/PieChartWidgetWrapper';
import { useLocalTranslation } from './translations';
import { useFetchDataCancelable } from '../../config/useFetchDataCancelable';
import { pieChartWidgetSkeletonConfig } from '../../config/skeleton';
import { SensitivityPieChartTooltip } from './SensitivityPieChartTooltip';
import { CatalogDiscoveryWidget } from '../../config/widgets';
import { BigidDonutChartSliceData } from '@bigid-ui/visualisation';
import {
  parseAbstractQueryTreeIntoString,
  parseExpressionsIntoAbstractQueryTree,
} from '@bigid/query-object-serialization';
import { getRelevantQueryNodes } from '../../filter/utils';

export interface SensitivityPieChartProps
  extends Pick<UseCatalogDiscoveryResponse, 'query' | 'filter'>,
    Pick<PieChartWidgetProps, 'size' | 'width'> {
  dataAid?: string;
  dataTourId?: string;
  isPageInitialised: boolean;
  onWidgetFilterChange?: UseCatalogDiscoveryResponse['onWidgetFilterChange'];
  onDataFetchStatusChange?: UseCatalogDiscoveryResponse['onDataFetchStatusChange'];
  isSidePanel?: boolean;
}

const optionIdPrefix = 'Sensitivity@#&';

export const SensitivityPieChart: FC<SensitivityPieChartProps> = memo(
  ({
    dataAid = 'SensitivityPieChart',
    dataTourId = 'SensitivityPieChart',
    filter,
    onWidgetFilterChange,
    onDataFetchStatusChange,
    isPageInitialised,
    size = 'xl',
    width = '100%',
    query,
    isSidePanel = false,
  }) => {
    const { fetchSensitivityCancelable } = useFetchDataCancelable();
    const { t } = useLocalTranslation();
    const [chartData, setChartData] = useState<PieChartWidgetSeries[]>(
      getSensitivityPieChartData({ aggregationData: [], aggregationType: AggregationType.SENSITIVITY }),
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    let pickedOptions: string[] = [];

    if (!isSidePanel) {
      const filterToParse = getFiltersExcludingCurrentType(filter, AggregationFilterOperand.SENSITIVITY_FILTER);
      pickedOptions = getCurrentTypeAppliedOptions(filter, AggregationFilterOperand.SENSITIVITY_FILTER);
      // We need the values without the filter prefix that is being generated earlier
      for (let i = 0; i < pickedOptions.length; i++) {
        if (pickedOptions[i].includes(optionIdPrefix)) {
          pickedOptions[i] = pickedOptions[i].replace(optionIdPrefix, '');
        }
      }
      const queryTreeNodes = getRelevantQueryNodes(filterToParse);

      const queryTree = parseExpressionsIntoAbstractQueryTree(queryTreeNodes);
      query = parseAbstractQueryTreeIntoString(queryTree);
    }

    const fetchAggregationData = useCallback(async () => {
      try {
        const payload: GetAggregatedDataPayload = {
          filter: query,
          aggregations: [
            {
              aggName: AggregationType.SENSITIVITY,
              isTotalRequired: true,
            },
          ],
        };

        setIsLoading(true);
        onDataFetchStatusChange?.(CatalogDiscoveryWidget.SENSITIVITY_PIE_CHART, true);

        const { aggregations } = await fetchSensitivityCancelable(
          getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
        );

        const aggItemNames = aggregations[0]?.aggData.map(item => item.aggItemName);
        const isDefaultOptions = computeIsDefaultOptions(aggItemNames);

        const data = getSensitivityPieChartData({
          aggregationData: aggregations[0]?.aggData,
          aggregationType: AggregationType.SENSITIVITY,
          filters: filter,
          associatedFilterType: AggregationType.SENSITIVITY_FILTER,
          isDefaultOptions,
          pickedOptions,
        });

        setChartData(data);
      } catch ({ isCanceled, message }) {
        if (!isCanceled) {
          console.error(`An error has occurred: ${message}`);
        }
      } finally {
        setIsLoading(false);
        onDataFetchStatusChange?.(CatalogDiscoveryWidget.SENSITIVITY_PIE_CHART, false);
      }
    }, [fetchSensitivityCancelable, filter, onDataFetchStatusChange]);

    const handleSectorClick = useCallback(
      (data: BigidDonutChartSliceData): void => {
        if (data.category.length > 0) {
          const { aggItem, isAppliedFilter } = chartData.find(({ category }) => category === data.category);
          if (!isAppliedFilter) {
            onWidgetFilterChange(AggregationType.SENSITIVITY_FILTER, [aggItem], isAppliedFilter);
          }
        }
      },
      [chartData, onWidgetFilterChange],
    );

    const handleLegendItemClick = useCallback(
      (data: BigidDonutChartSliceData): void => {
        const { aggItem, isAppliedFilter } = chartData.find(({ category }) => category === data.category);
        if (data.value > 0 && !isAppliedFilter) {
          onWidgetFilterChange(AggregationType.SENSITIVITY_FILTER, [aggItem]);
        }
      },
      [chartData, onWidgetFilterChange],
    );

    useEffect(() => {
      if (isPageInitialised) {
        fetchAggregationData();
      }
    }, [fetchAggregationData, isPageInitialised]);

    return (
      <PieChartWidgetWrapper dataAid={dataAid}>
        {isPageInitialised ? (
          <PieChartWidget
            dataAid={generateDataAid(dataAid, ['widget'])}
            dataTourId={generateGuidedTourId(dataTourId, ['widget'])}
            entityName={t('title')}
            tooltipText={<SensitivityPieChartTooltip />}
            data={chartData}
            onSectorClick={onWidgetFilterChange ? handleSectorClick : undefined}
            onLegendItemClick={onWidgetFilterChange ? handleLegendItemClick : undefined}
            isBusy={isLoading}
            size={size}
            width={width}
          />
        ) : (
          <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...pieChartWidgetSkeletonConfig} />
        )}
      </PieChartWidgetWrapper>
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query && prevProps.isPageInitialised === newProps.isPageInitialised,
);

function computeIsDefaultOptions(strings: string[]): boolean {
  const sensitivityDefaultValues = Object.values(SensitivityClassificationAggregationItem) as string[];

  for (const str of strings) {
    if (!sensitivityDefaultValues.includes(str)) {
      return false;
    }
  }
  return true;
}
