import { notificationService } from '../../../services/notificationService';
import { GettingStartedStep } from '../types';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { isPagePermitted } from '../../Login/loginUtils';
import { CONFIG } from '../../../../config/common';
import { isPermitted } from '../../../services/userPermissionsService';
import { userPreferencesService } from '../../../services/userPreferencesService';

export interface GettingStartedInfo {
  numStepsCompleted?: number;
  steps?: GettingStartedStep[];
}

export const initGettingStartedItems = async (gettingStartedSteps: GettingStartedStep[]) => {
  const steps = gettingStartedSteps.map(step => ({
    ...step,
    checkIsComplete: step.checkIsComplete,
    checkIsActive: step.checkIsActive,
    checkInProgress: step.checkInProgress,
  }));
  for (const [index, step] of steps.entries()) {
    step.isComplete = await step.checkIsComplete();
    if (!step.isComplete) {
      step.isActive = step.checkIsActive();
      return { steps, numStepsCompleted: index };
    }
  }
  return { steps, numStepsCompleted: steps.length };
};

export const initState = async (gettingStartedSteps: GettingStartedStep[]): Promise<GettingStartedInfo> => {
  try {
    const { steps, numStepsCompleted } = await initGettingStartedItems(gettingStartedSteps);
    return {
      numStepsCompleted,
      steps,
    };
  } catch (err) {
    notificationService.error('Failed to fetch Getting Started data');
    console.error(err);
  }
};

export const areAllStepsCompleted = (stepsFinished: number, totalSteps: number) => {
  return stepsFinished === totalSteps;
};

export const isSecurityGettingStartedPermitted = () => {
  return isPermitted('admin') && isPagePermitted(CONFIG.states.GETTING_STARTED_SECURITY);
};

export const getSharedUserPreferenceValue = async (userPreference: string): Promise<boolean> => {
  try {
    const data = await userPreferencesService.get(userPreference);
    return data?.data?.value;
  } catch (err) {
    console.log(err);
    return false;
  }
};
