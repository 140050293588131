import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { BigidBody1, BigidDialog, BigidTextField, PrimaryButton, TertiaryButton } from '@bigid-ui/components';
import { CaseStatusLabel } from './RiskRegisterService';
import { useLocalTranslation } from './translations';

const DialogContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export interface ChangeStatusDialogProps {
  isOpen: boolean;
  onDialogClose: () => void;
  onSubmitButtonClickHandler: (reason?: string) => void;
  caseNewStatus: CaseStatusLabel;
}

export const ChangeStatusDialog: FC<ChangeStatusDialogProps> = ({
  isOpen,
  onDialogClose,
  onSubmitButtonClickHandler,
  caseNewStatus,
}) => {
  const [reasonValue, setReasonValue] = useState<string | undefined>();
  const [showReasonValueError, setShowReasonValueError] = useState(false);
  const [dialogContent, setDialogContent] = useState<
    | {
        title: string;
        body: string;
        submitButtonText: string;
        placeHolder: string;
        errorMessage: string;
      }
    | undefined
  >();
  const { t } = useLocalTranslation('ChangeStatusDialog');

  const validateReasonValue = () => {
    if (!reasonValue) {
      setShowReasonValueError(true);
    } else {
      onSubmitButtonClickHandler(reasonValue);
    }
  };

  const getDialogPropertiesContent = () => {
    switch (caseNewStatus) {
      case CaseStatusLabel.ACKNOWLEDGED:
        setDialogContent({
          title: t('AcknowledgedCase.title'),
          body: t('AcknowledgedCase.body'),
          placeHolder: t('AcknowledgedCase.placeHolder'),
          submitButtonText: t('AcknowledgedCase.submitButtonText'),
          errorMessage: t('AcknowledgedCase.errorMessage'),
        });
        break;
      case CaseStatusLabel.SILENCED:
        setDialogContent({
          title: t('SilencedCase.title'),
          body: t('SilencedCase.body'),
          placeHolder: t('SilencedCase.placeHolder'),
          submitButtonText: t('SilencedCase.submitButtonText'),
          errorMessage: t('SilencedCase.errorMessage'),
        });
        break;
      case CaseStatusLabel.REMEDIATED:
        setDialogContent({
          title: t('RemediatedCase.title'),
          body: t('RemediatedCase.body'),
          placeHolder: t('RemediatedCase.placeHolder'),
          submitButtonText: t('RemediatedCase.submitButtonText'),
          errorMessage: t('RemediatedCase.errorMessage'),
        });
        break;
    }
  };

  useEffect(() => {
    getDialogPropertiesContent();
  }, []);

  return (
    <BigidDialog
      isOpen={isOpen}
      title={dialogContent?.title}
      onClose={onDialogClose}
      buttons={[
        {
          onClick: onDialogClose,
          component: TertiaryButton,
          text: t('Labels.cancel'),
          size: 'medium',
          alignment: 'right',
        },
        {
          onClick: validateReasonValue,
          component: PrimaryButton,
          text: dialogContent?.submitButtonText,
          size: 'medium',
          alignment: 'right',
        },
      ]}
    >
      <DialogContent>
        <BigidBody1 size="medium">{dialogContent?.body}</BigidBody1>
        <BigidTextField
          autoFocus
          required
          placeholder={dialogContent?.placeHolder}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setReasonValue(event.target.value);
            if (showReasonValueError && event.target.value) {
              setShowReasonValueError(false);
            }
          }}
          multiline
          rows={4}
          label={t('Labels.textFieldReason')}
          errorMessage={showReasonValueError && dialogContent.errorMessage}
        />
      </DialogContent>
    </BigidDialog>
  );
};
