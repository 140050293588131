import { $rootScope } from 'ngimport';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { httpService } from '../../react/services/httpService';
import { $state, localStorageService } from '../../react/services/angularServices';
import { SystemEvents, systemEventsEmitter } from '../../react/services/systemEvents';
import { License, licenseService } from '../../react/services/licenseService';
import { AppLicense, appsLicenseService } from '../../react/services/appsLicenseService';
import { ONBOARDING_MODAL_STATE } from '../../react/components/StartOnboardingModal/onboardingModalService';
import { initPermissions } from '../../react/services/userPermissionsService';
import { userPreferencesService } from '../../react/services/userPreferencesService';
import { CONFIG } from '../../config/common';
import { getApplicationPreference, initApplicationPreferences } from '../../react/services/appPreferencesService';
import { CredentialsType } from '../../react/views/Login/Login';
import { notificationService } from '../../react/services/notificationService';
import { syncWorkspacesWithUserPreferences } from '../../react/services/workspacesService';
import { initScannerTypesSupportedFeatures } from '../../react/services/scannerTypesSupportedFeaturesService';
import { getSupportedDataSources } from '../../react/utilities/dataSourcesUtils';
import { privacyPortalService } from '../../react/services/privacyPortalService';

export interface UserInfo {
  auth_token: string;
  username: string;
  permissions: string[];
  origin: string;
  firstName: string;
  email?: string;
  isPasswordChangeNeeded: boolean;
  auth0Token?: string;
  shouldNotifyMaxDisUserLimitReached?: boolean;
  lastSuccessfulLoginAt: Date;
}

interface LicenseResponse {
  license: License;
  supportedApps: AppLicense[];
}

export interface SAMLConfig {
  idp: {
    isEnabled: boolean;
    companyName: string;
    showOnlyIdpLogin: boolean;
  };
  okta: {
    isEnabled: boolean;
  };
  isCloudIdpEnabled: boolean;
  cloudIdpUrlSuffix: string;
}

export interface RemoteUserConfig {
  isEnabled: boolean;
  userIdentifierFieldName: string;
}

class LoginService {
  private static instance: LoginService;
  private samlConf: SAMLConfig;

  public static getInstance(): LoginService {
    if (!LoginService.instance) {
      LoginService.instance = new LoginService();
    }

    return LoginService.instance;
  }

  public async authenticateUser(userCredentials?: CredentialsType) {
    try {
      const { data } = await httpService.post('sessions', userCredentials);
      return data;
    } catch (err) {
      console.error('Error while authenticating user', err);
      throw err;
    }
  }

  public async getSamlStateAPI(): Promise<SAMLConfig> {
    try {
      const response = await httpService.fetch<SAMLConfig>('saml/config');
      return response.data;
    } catch (err) {
      console.error('Error while fetching saml configuration', err);
      return undefined;
    }
  }

  public async getRemoteUserConf() {
    try {
      const {
        data: { data },
      } = await httpService.fetch<{ data: RemoteUserConfig[] }>('remote-user/config');
      return data[0];
    } catch (err) {
      console.error('Error while fetching Remote User configuration', err);
      return undefined;
    }
  }

  public async getSamlConf(): Promise<SAMLConfig> {
    if (this.samlConf === undefined) {
      this.samlConf = await this.getSamlStateAPI();
    }
    return this.samlConf;
  }

  public clear(): void {
    this.samlConf = undefined;
  }

  public handleLogout() {
    localStorageService.remove('filterValue');
    localStorageService.remove('userNameListChecked');
    localStorageService.remove('filterValueForQueryString');
    sessionStorageService.removeAll();
    this.clear();

    systemEventsEmitter.emit(SystemEvents.LOGOUT);
  }

  public async fetchUrlAndPerformLogout(logoutRoute: string) {
    const {
      data: {
        data: { logoutUrl },
      },
    } = await httpService.post(logoutRoute, {});
    window.location.href = logoutUrl;
    return this.handleLogout();
  }

  public async logout(abortDisabled = false) {
    const isSamlIdpEnabled = this.samlConf?.idp?.isEnabled;
    const isUserSaml = sessionStorageService.get('userOrigin') === 'Saml';
    const shouldLogoutSAML = getApplicationPreference('SAML_LOGOUT_ENABLED') && isUserSaml && isSamlIdpEnabled;
    const isUserAuth0 = sessionStorageService.get('userOrigin') === 'Auth0';
    try {
      await privacyPortalService.logoutPrivacyPortal();
      if (isUserAuth0) {
        return await this.fetchUrlAndPerformLogout('oidc/logout');
      } else {
        if (!shouldLogoutSAML) {
          return $state
            .go(CONFIG.states.LOGIN, { logout: true, abortDisabled })
            .transition.onFinish({}, this.handleLogout.bind(this));
        }
        return await this.fetchUrlAndPerformLogout('saml/logout');
      }
    } catch (error) {
      notificationService.error(error);
    }
  }

  public async performUserLoginFlow(userInfo: UserInfo) {
    this.setSessionForUser(userInfo);
    userInfo.shouldNotifyMaxDisUserLimitReached &&
      notificationService.warning(
        'Data Insights Studio Users reached the max allowed limit, the current logged user will not be signed as DIS user',
        { shouldCloseOnTransition: false },
      );
    const {
      data: { license, supportedApps },
    } = await httpService.fetch<LicenseResponse>('license');

    licenseService.setLicense(license);
    appsLicenseService.setAppsLicense(supportedApps);

    await initPermissions();
    await userPreferencesService.getAll();
    await initApplicationPreferences();
    await syncWorkspacesWithUserPreferences();
    await initScannerTypesSupportedFeatures();
    await getSupportedDataSources();

    $rootScope.$emit('madeSuccessfulLogin');
    systemEventsEmitter.emit(SystemEvents.LOGIN);
  }

  public setSessionForUser(userInfo: UserInfo) {
    const bigidRoleAndPermissionNames = new Set([
      'admin',
      'api',
      'non-admin',
      'it-non-admin',
      'it-non-admin',
      'business-admin',
      'collaborator',
      'ds-collaborator',
      'scoped-admin',
      'ops',
      'corp-id',
      'IAM-admin',
      'scanner',
      'data-insights-studio',
    ]);

    const {
      auth0Token,
      auth_token,
      username,
      email,
      permissions,
      isPasswordChangeNeeded = false,
      origin,
      firstName,
      lastSuccessfulLoginAt,
    } = userInfo;

    const userRoles = permissions.filter(per => bigidRoleAndPermissionNames.has(per));
    sessionStorageService.set('bigIdTokenID', auth_token);
    sessionStorageService.set('auth0Token', auth0Token);
    sessionStorageService.set('userName', username);
    sessionStorageService.set('userRoles', userRoles);
    sessionStorageService.set('userEmail', email || username);
    sessionStorageService.set('isPasswordChangeNeeded', isPasswordChangeNeeded);
    sessionStorageService.set('displayName', firstName || username);
    sessionStorageService.set('lastSuccessfulLoginAt', lastSuccessfulLoginAt);

    if (origin) {
      sessionStorageService.set('userOrigin', origin);
    } else {
      sessionStorageService.remove('userOrigin');
    }

    localStorageService.set(
      'onboardingModalShowed',
      localStorageService.get('onboardingModalShowed') === ONBOARDING_MODAL_STATE.DO_NOT_SHOW_AGAIN
        ? ONBOARDING_MODAL_STATE.DO_NOT_SHOW_AGAIN
        : ONBOARDING_MODAL_STATE.NOT_SHOWED,
    );
  }

  public isLoggedIn() {
    return !!sessionStorageService.get('bigIdTokenID');
  }

  public getUser(): Partial<UserInfo> {
    return {
      auth_token: sessionStorageService.get('bigIdTokenID', true),
      auth0Token: sessionStorageService.get('auth0Token', true),
      username: sessionStorageService.get('userName', true),
      firstName: sessionStorageService.get('displayName', true),
      permissions: sessionStorageService.get('userRoles', true),
      origin: sessionStorageService.get('userOrigin', true),
      email: sessionStorageService.get('userEmail', true),
      isPasswordChangeNeeded: sessionStorageService.get('isPasswordChangeNeeded', true),
    };
  }
}

export const loginService: LoginService = LoginService.getInstance();
