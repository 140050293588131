import { DataCatalogRecord } from '../DataCatalog/DataCatalogService';
import { DateISO8601 } from '../../types/types';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { HeadersObject, httpService, QueryParamsAndDataObject } from '../../services/httpService';
import { ReactText } from 'react';
import axios, { AxiosResponse, ResponseType } from 'axios';
import { appSettings } from '../../../common/services/app.common.services';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { TPAItemResponse } from '../Credentials/types';
import { Api, ApiParam } from './api';
import { BigidAdvancedToolbarFilterUnion, BigidDropdownOption, DateRangeFilterSchema } from '@bigid-ui/components';
import { dateUtils } from '../../services/angularServices';

const APP_NAME = 'BAM';

export interface ObjectActivitySummary {
  fullyQualifiedName: string;
  lastActivity: DateISO8601;
  topActiveAccounts: string[];
}

export interface DataCatalogRecordWithActivitySummary extends DataCatalogRecord {
  lastActivity: DateISO8601;
  topActiveAccounts: string[];
}

export interface ObjectActivityDetails {
  id: ReactText;
  accountName: string;
  accountIdentifier: string;
  lastActivity: DateISO8601;
  operation: string[];
}

type ActivityMonitoringSummaryResponse = ObjectActivitySummary[];

interface ActivityMonitoringDetailsResponse {
  details: ObjectActivityDetails[];
  totalCount: number;
}

export const isActivityMonitoringEnabled = (): boolean => {
  return getApplicationPreference('ENABLE_DSPM_ACTIVITY_MONITORING');
};

const getBamQueryParams = async (): Promise<string> => {
  try {
    const tpa = (await httpService.fetch<TPAItemResponse[]>(`tpa/${APP_NAME}`)).data;
    const dataSource = tpa[0].global_params.find(param => param.param_name === 'Snowflake Data Source')?.value || '';

    return `${ApiParam.URL}=https:${appSettings.serverPath}/api/v1/&${ApiParam.TOKEN}=${sessionStorageService.get(
      'bigIdTokenID',
    )}&${ApiParam.DATA_SOURCE}=${dataSource}`;
  } catch (e) {
    console.error(`An error has occurred: ${e.message}`);
    return '';
  }
};

const getProxyURL = async (appNameOrId: string, apiPath: string): Promise<string> => {
  return `${appSettings.serverPath}/proxy/tpa/ui/${appNameOrId}/api/api/${apiPath}&${await getBamQueryParams()}`;
};

const fetchFromTpaProxy = async <T>(
  apiPath: string,
  params?: QueryParamsAndDataObject,
  headers?: HeadersObject,
  responseType?: ResponseType,
): Promise<AxiosResponse<T>> => {
  const serverPath = await getProxyURL(APP_NAME, apiPath);
  return axios.get(serverPath, {
    params,
    headers,
    responseType,
  });
};

export const getActivityMonitoringSummary = async (
  dataCatalogObjects: DataCatalogRecord[],
): Promise<DataCatalogRecordWithActivitySummary[]> => {
  try {
    const data = isActivityMonitoringEnabled()
      ? ((
          await fetchFromTpaProxy<ActivityMonitoringSummaryResponse>(
            `${Api.OBJECT_ACTIVITY_SUMMARY}?${ApiParam.OBJECTS}=${dataCatalogObjects.map(
              dataCatalogObject => dataCatalogObject.fullyQualifiedName,
            )}`,
          )
        ).data as ActivityMonitoringSummaryResponse)
      : [];
    return dataCatalogObjects.map(dataCatalogObject => {
      const objectActivityMonitoring =
        data &&
        data.find(
          activityMonitoringObject =>
            activityMonitoringObject.fullyQualifiedName === dataCatalogObject.fullyQualifiedName,
        );
      return {
        ...dataCatalogObject,
        lastActivity: objectActivityMonitoring
          ? dateUtils.formatDate(new Date(objectActivityMonitoring.lastActivity))
          : '',
        topActiveAccounts: objectActivityMonitoring ? objectActivityMonitoring.topActiveAccounts : [],
      };
    });
  } catch (e) {
    console.error(e);
    return dataCatalogObjects.map(dataCatalogObject => ({
      ...dataCatalogObject,
      lastActivity: '',
      topActiveAccounts: [],
    }));
  }
};

export const searchField = async (field: ApiParam, searchString?: string): Promise<string[]> => {
  try {
    if (isActivityMonitoringEnabled()) {
      return (
        await fetchFromTpaProxy<string[]>(
          `${Api.SEARCH_FIELD}?field=${field}${searchString ? `&${ApiParam.SEARCH_STRING}=${searchString}` : ''}`,
        )
      ).data;
    } else {
      return [];
    }
  } catch (e) {
    console.error(`An error has occurred: ${e.message}`);
    return [];
  }
};

const dropdownFilterToQueryParam = (id: ApiParam, filters: BigidAdvancedToolbarFilterUnion[]): string => {
  const filter = filters.find(filter => filter.id === id);
  if (filter) {
    const value = (filter.options as BigidDropdownOption[])
      .filter(option => option.isSelected)
      .map(option => option.value)
      .join(',');
    return `${id}=${value}`;
  } else {
    return '';
  }
};

const dateRangeFilterToQueryParam = (id: ApiParam, filters: BigidAdvancedToolbarFilterUnion[]): string => {
  const filter = filters.find(filter => filter.id === id);
  if (filter) {
    const value = (filter.options as DateRangeFilterSchema).pickersState.dates;
    return `${id}=${value.from},${value.until}`;
  } else {
    return '';
  }
};

export const filtersToQueryParams = (filters: BigidAdvancedToolbarFilterUnion[]): string => {
  const accountName = dropdownFilterToQueryParam(ApiParam.ACCOUNT_NAME, filters);
  const accountIdentifier = dropdownFilterToQueryParam(ApiParam.ACCOUNT_IDENTIFIER, filters);
  const lastActivity = dateRangeFilterToQueryParam(ApiParam.LAST_ACTIVITY, filters);
  const operation = dropdownFilterToQueryParam(ApiParam.OPERATION, filters);
  return [accountName, accountIdentifier, lastActivity, operation].filter(value => !!value).join('&');
};

export const getActivityMonitoringDetails = async (
  fullyQualifiedName: string,
  filters: BigidAdvancedToolbarFilterUnion[],
  skip = 0,
  limit = 5,
): Promise<ActivityMonitoringDetailsResponse> => {
  try {
    if (isActivityMonitoringEnabled()) {
      const queryParams = filtersToQueryParams(filters);
      const response = (
        await fetchFromTpaProxy<ActivityMonitoringDetailsResponse>(
          `${Api.OBJECT_ACTIVITY_DETAILS}?${ApiParam.OBJECTS}=${fullyQualifiedName}${
            queryParams ? `&${queryParams}` : ''
          }&limit=${limit}&skip=${skip}`,
        )
      ).data;
      return {
        details: response.details.map(detail => ({
          ...detail,
          lastActivity: dateUtils.formatDate(new Date(detail.lastActivity)),
          id: detail.accountName,
        })),
        totalCount: response.totalCount,
      };
    } else {
      return { details: [], totalCount: 0 };
    }
  } catch (e) {
    console.error(e);
    return { details: [], totalCount: 0 };
  }
};
