import React, { FC, useEffect } from 'react';
import { styled } from '@mui/material';
import { BigidHelpIcon, BigidExternalLinkIcon } from '@bigid-ui/icons';
import { BigidBody1, BigidPaper } from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { DATA_OVERVIEW_PERMISSIONS, INVENTORY_PERMISSIONS } from '@bigid/permissions';
import { useCatalogDiscovery } from './useCatalogDiscovery';
import { CatalogDiscoveryToolbar } from './toolbar/CatalogDiscoveryToolbar';
import { DataSourcesLayout } from './content/DataSourcesLayout/DataSourcesLayout';
import { AttributesLayout } from './content/AttributesLayout/AttributesLayout';
import { FindingsSummary } from './content/FindingsSummary/FindingsSummary';
import { DuplicateFilesSummary } from './content/DuplicateFilesSummary/DuplicateFilesSummary';
import { ObjectStatusPieChart } from './content/ObjectStatusPieChart/ObjectStatusPieChart';
import { DataFormatPieChart } from './content/DataFormatPieChart/DataFormatPieChart';
import { SensitivityPieChart } from './content/SensitivityPieChart/SensitivityPieChart';
import { TopCategoriesBarChart } from './content/TopCategoriesBarChart/TopCategoriesBarChart';
import { ViolatedPoliciesSummary } from './content/ViolatedPoliciesSummary/ViolatedPoliciesSummary';
import { useLocalTranslation } from './translations';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { MetadataSearchCatalogIndexingStatus } from '../../components/MetadataSearchCatalogIndexingStatus/MetadataSearchCatalogIndexingStatus';
import { CatalogDiscoveryEmptyState } from './CatalogDiscoveryEmptyState/CatalogDiscoveryEmptyState';
import {
  BigidPageTitleHelper,
  BigidPageTitleHelperButtonType,
  BigidPageTitleHelperProps,
} from '../../components/BigidHeader/BigidPageTitle/BigidPageTitleHelper';
import { docsUrls } from '../../config/publicUrls';
import { pieChartWidgetsContainerHeight, summaryWidgetsContainerHeight } from './config/widgets';
import { GetViewInDataOverviewFiltersResult } from '../DataCatalog/NewDataCatalog/types';
import { isPermitted } from '../../services/userPermissionsService';
import { CatalogDiscoveryNoPermission } from './CatalogDiscoveryNoPermission/CatalogDiscoveryNoPermission';
import { CatalogDiscoveryGuidedTourLazy } from './guidedTour/CatalogDiscoveryGuidedTourLazy';

const Root = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 10px;
  position: relative;
`;

const Toolbar = styled('div')`
  max-width: 1400px;
  flex: 1 1 1400px;
  width: 100%;
  margin-bottom: 16px;
`;

const ContentScrollable = styled('div')`
  display: flex;
  overflow-y: scroll;
  width: 100%;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Centered = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 24px;
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  flex: 1 1 1400px;
  gap: 16px;
  width: 100%;
`;

const SummaryWidgetsContainer = styled('div')<{ columns: number }>`
  display: flex;
  width: 100%;
  gap: 16px;
  height: ${summaryWidgetsContainerHeight}px;
  min-height: ${summaryWidgetsContainerHeight}px;
  > div {
    width: ${({ columns }) => `${100 / columns}%`};
  }
`;

const SummaryWidget = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
`;

const PieWidgetsContainer = styled('div')`
  display: flex;
  height: ${pieChartWidgetsContainerHeight}px;
  min-height: ${pieChartWidgetsContainerHeight}px;
`;

const PieWidgetsContainerInner = styled('div')<{ widgetsCount?: number }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  ${({ widgetsCount }) => (widgetsCount ? 'justify-content: center;' : 'justify-content: space-evenly;')}
  > div {
    width: ${({ widgetsCount }) => (widgetsCount ? `${100 / widgetsCount}%` : 'auto')};
  }
`;

const PieChartContainer = styled('div')`
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LayoutWidgetContainer = styled('div')<{ noBorder: boolean }>`
  display: flex;
  > div {
    ${({ noBorder }) => noBorder && 'border: none;'}
  }
`;

export interface CatalogDiscoveryProps {
  $stateParams?: {
    selectedCatalogFilters: GetViewInDataOverviewFiltersResult;
    transitionFromNewCatalog: boolean;
  };
}

export const CatalogDiscovery: FC<CatalogDiscoveryProps> = ({
  $stateParams: { selectedCatalogFilters, transitionFromNewCatalog },
}) => {
  const { t } = useLocalTranslation();
  const {
    query,
    filter,
    appliedFilter,
    onToolbarFilterChange,
    onToolbarFilterInitialise,
    onToolbarFilterReloaded,
    onToolbarFilterTimeoutReached,
    onWidgetFilterChange,
    onAttributesWidgetFilterChange,
    onDataFetchStatusChange,
    onEmptyStateComputed,
    onGuidedTourStart,
    onGuidedTourFinished,
    pageInitialisationStatus,
    isPageReadyToInitialise,
    guidedTourStatus,
    widgetsDataFetchingStatus,
    currentSavedQuery,
    savedQueries,
    onSavedQueryCreate,
    onSavedQueryUpdate,
    onSavedQueryDelete,
    onSavedQuerySelect,
    isFilterChanged,
  } = useCatalogDiscovery(selectedCatalogFilters, transitionFromNewCatalog);
  const isCatalogDiscoveryEnabled = getApplicationPreference('DATA_OVERVIEW_ENABLED');
  const isSensitivityWidgetAvailable = getApplicationPreference('SENSITIVITY_CLASSIFICATION_ENABLED');
  const isDataOverviewPermitter = isPermitted(DATA_OVERVIEW_PERMISSIONS.ACCESS.name);
  const isInventoryPermitted = isPermitted(INVENTORY_PERMISSIONS.ACCESS.name);

  const isToolbarPreferencesReady = !widgetsDataFetchingStatus.USER_PREFERENCES.isBusy && Boolean(appliedFilter);
  const isPageInitialised = !pageInitialisationStatus.isBusy;

  /**
   *  ! NOTE: redirect to the forbidden page if DATA_OVERVIEW_ENABLED FF is disabled.
   *  ! Still think that Forbidden page is not the best option since it's misleading.
   *  */
  useEffect(() => {
    if (!isCatalogDiscoveryEnabled) {
      $state.go(CONFIG.states.FORBIDDEN);
    }
  }, [isCatalogDiscoveryEnabled]);

  useEffect(() => {
    const pageTitleHelperProps: BigidPageTitleHelperProps = {
      dataAid: generateDataAid('CatalogDiscovery', ['title', 'helper']),
      contentWidth: 365,
      togglerIcon: BigidHelpIcon,
      title: t('pageTitleHelper.title'),
      body: <BigidBody1>{t('pageTitleHelper.body')}</BigidBody1>,
      buttons: [
        {
          type: BigidPageTitleHelperButtonType.TERTIARY,
          text: t('pageTitleHelper.buttons.tertiary'),
          endIcon: <BigidExternalLinkIcon />,
          onClick: () => {
            window.open(docsUrls.DISCOVERY_INSIGHTS, '_blank');
          },
        },
        {
          type: BigidPageTitleHelperButtonType.PRIMARY,
          text: t('pageTitleHelper.buttons.primary'),
          onClick: () => {
            onGuidedTourStart();
          },
        },
      ],
    };

    pageHeaderService.setTitle({
      pageTitle: t('pageTitle'),
      rightSideComponentsContainer: isPageInitialised && (
        <MetadataSearchCatalogIndexingStatus
          dataAid="CatalogDiscoveryIndexingStatus"
          dataTourId="CatalogDiscoveryIndexingStatus"
        />
      ),
      titleHelperComponent: isPageInitialised && <BigidPageTitleHelper {...pageTitleHelperProps} />,
    });
  }, [t, onGuidedTourStart, isPageInitialised]);

  if (!isDataOverviewPermitter) {
    return <CatalogDiscoveryNoPermission />;
  }

  return (
    <Root data-tour-id={generateGuidedTourId('CatalogDiscovery', ['start'])}>
      {isPageReadyToInitialise ? (
        <>
          <Centered>
            <Toolbar>
              <CatalogDiscoveryToolbar
                query={query}
                filter={filter}
                isFilterChanged={isFilterChanged}
                appliedFilter={appliedFilter}
                savedQueries={savedQueries}
                currentSavedQuery={currentSavedQuery}
                isPageInitialised={isPageInitialised}
                isToolbarPreferencesReady={isToolbarPreferencesReady}
                onToolbarFilterChange={onToolbarFilterChange}
                onToolbarFilterInitialise={onToolbarFilterInitialise}
                onToolbarFilterReloaded={onToolbarFilterReloaded}
                onToolbarFilterTimeoutReached={onToolbarFilterTimeoutReached}
                onDataFetchStatusChange={onDataFetchStatusChange}
                onSavedQueryCreate={onSavedQueryCreate}
                onSavedQueryUpdate={onSavedQueryUpdate}
                onSavedQueryDelete={onSavedQueryDelete}
                onSavedQuerySelect={onSavedQuerySelect}
                isInventoryPermitted={isInventoryPermitted}
              />
            </Toolbar>
          </Centered>
          <ContentScrollable>
            <Centered>
              <Content>
                <SummaryWidgetsContainer
                  data-tour-id={generateGuidedTourId('CatalogDiscovery', ['high-level-totals'])}
                  columns={4}
                >
                  <SummaryWidget>
                    <BigidPaper>
                      <FindingsSummary
                        query={query}
                        onDataFetchStatusChange={onDataFetchStatusChange}
                        isPageInitialised={isPageInitialised}
                      />
                    </BigidPaper>
                  </SummaryWidget>
                  <SummaryWidget>
                    <BigidPaper>
                      <DuplicateFilesSummary
                        query={query}
                        onDataFetchStatusChange={onDataFetchStatusChange}
                        onWidgetFilterChange={onWidgetFilterChange}
                        isPageInitialised={isPageInitialised}
                      />
                    </BigidPaper>
                  </SummaryWidget>
                  <SummaryWidget>
                    <BigidPaper>
                      <TopCategoriesBarChart
                        query={query}
                        filter={filter}
                        onDataFetchStatusChange={onDataFetchStatusChange}
                        onWidgetFilterChange={onWidgetFilterChange}
                        isPageInitialised={isPageInitialised}
                      />
                    </BigidPaper>
                  </SummaryWidget>
                  <SummaryWidget>
                    <BigidPaper>
                      <ViolatedPoliciesSummary
                        query={query}
                        onDataFetchStatusChange={onDataFetchStatusChange}
                        isPageInitialised={isPageInitialised}
                      />
                    </BigidPaper>
                  </SummaryWidget>
                </SummaryWidgetsContainer>
                <PieWidgetsContainer data-tour-id={generateGuidedTourId('CatalogDiscovery', ['insights'])}>
                  <BigidPaper>
                    <PieWidgetsContainerInner widgetsCount={isSensitivityWidgetAvailable ? 4 : 3}>
                      <PieChartContainer>
                        <ObjectStatusPieChart
                          query={query}
                          filter={filter}
                          onWidgetFilterChange={onWidgetFilterChange}
                          onDataFetchStatusChange={onDataFetchStatusChange}
                          isPageInitialised={isPageInitialised}
                        />
                      </PieChartContainer>
                      <PieChartContainer>
                        <DataFormatPieChart
                          query={query}
                          filter={filter}
                          onWidgetFilterChange={onWidgetFilterChange}
                          onDataFetchStatusChange={onDataFetchStatusChange}
                          isPageInitialised={isPageInitialised}
                        />
                      </PieChartContainer>
                      {isSensitivityWidgetAvailable && (
                        <PieChartContainer>
                          <SensitivityPieChart
                            query={query}
                            filter={filter}
                            onWidgetFilterChange={onWidgetFilterChange}
                            onDataFetchStatusChange={onDataFetchStatusChange}
                            isPageInitialised={isPageInitialised}
                          />
                        </PieChartContainer>
                      )}
                      {/* NOTE: temporarily disabled, waiting for a feedback */}
                      {/* {!isPageInitialised && (
                        <InitiatingLoader>
                          <CatalogDiscoveryInitiatingLoader />
                        </InitiatingLoader>
                      )} */}
                    </PieWidgetsContainerInner>
                  </BigidPaper>
                </PieWidgetsContainer>
                <LayoutWidgetContainer noBorder={isPageInitialised}>
                  <BigidPaper>
                    <DataSourcesLayout
                      query={query}
                      filter={filter}
                      onWidgetFilterChange={onWidgetFilterChange}
                      onDataFetchStatusChange={onDataFetchStatusChange}
                      isPageInitialised={isPageInitialised}
                    />
                  </BigidPaper>
                </LayoutWidgetContainer>
                <LayoutWidgetContainer noBorder={isPageInitialised}>
                  <BigidPaper>
                    <AttributesLayout
                      query={query}
                      onAttributesWidgetFilterChange={onAttributesWidgetFilterChange}
                      onDataFetchStatusChange={onDataFetchStatusChange}
                      isPageInitialised={isPageInitialised}
                    />
                  </BigidPaper>
                </LayoutWidgetContainer>
              </Content>
            </Centered>
          </ContentScrollable>
          <CatalogDiscoveryGuidedTourLazy status={guidedTourStatus} onGuidedTourFinished={onGuidedTourFinished} />
        </>
      ) : (
        <CatalogDiscoveryEmptyState onEmptyStateComputed={onEmptyStateComputed} />
      )}
    </Root>
  );
};
