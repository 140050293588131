import { heartBeatService } from './react/services/heartBeat';
import angular from 'angular';
import { LICENSE_TAB, licenseService } from './react/services/licenseService';
import './react/components/LicenseExpirationNoticeBar/LicenseExpirationNoticeBar';
import './react/components/ApplicationBanner/ApplicationBanner';
import './react/components/ChangeSecretKeyNoticeBar/ChangeSecretKeyNoticeBar';
import { SystemEvents, systemEventsEmitter } from './react/services/systemEvents';
import { createSSEConnection } from './react/services/sseService';
import './react/components/Sidebar/Sidebar';
import './react/components/NavigationSideBar/NavigationSidebar';
import './react/components/BigidHeader/BigidHeader';
import './react/components/Bigchat/BigChat';
import * as bannerNotificationUtil from './react/utilities/bannerNotificationUtil';
import appTemplate from './app.component.html';
import { setRecentlyUsedFromState } from './react/views/ApplicationsManagement/applicationManagementService';
import { CONFIG, FULL_WIDTH_PAGES } from './config/common';
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';

import { initPermissions, isPermitted } from './react/services/userPermissionsService';
import { initScannerTypesSupportedFeatures } from './react/services/scannerTypesSupportedFeaturesService';
import { getSupportedDataSources } from './react/utilities/dataSourcesUtils';
import { smallIdLicenseService } from './react/services/smallIdLicenseService';
import { startMeteringInterval } from './react/services/meteringService';

const app = angular.module('app');
import { states } from './states';
import { withTheme } from './react/theme/BigidTheme';
import { loginService } from './authentication/login/login.service';
import './react/components/BigidHeader/CustomerFeedback/CustomerFeedbackDialog';
import { $state, localStorageService } from './react/services/angularServices';
import { isPasswordChangeNeeded } from './react/services/passwordService';
import { pageHeaderService } from './common/services/pageHeaderService';
import { headerEventEmitter, HeaderEvents } from './react/services/eventEmitters/headerEvents';
import { dateTimeService } from '@bigid-ui/i18n';
import { sessionStorageService } from './common/services/sessionStorageService';
import { $rootScope, $timeout } from 'ngimport';
import { Login } from './react/views/Login/Login';
import { datadogInitListeners } from './react/services/datadogService';
import { analyticsService } from './react/services/analyticsService';
import { checkNotificationPermission } from './react/services/nativeNotificationService';
import './react/views/ConnectionLost/ConnectionLost';
import {
  getShouldShowBigChatInHeader,
  setShouldShowBigChatInHeader,
} from './react/services/bigchat/bigchatStorageService';
import { isBigChatEnabled } from './react/utilities/featureFlagUtils';
import { getApplicationPreference } from './react/services/appPreferencesService';
import { updateWorkspaceOnTransition } from './react/services/workspacesService';
import { isPagePermitted } from './react/views/Login/loginUtils';

const DEFAULT_DOCUMENT_TITLE = 'BigID';
export const ROOT_ANGULAR_APP_NAME = 'app';
export const ENABLE_NEW_UX_NAVIGATION = 'ENABLE_NEW_UX_NAVIGATION';

const onErrorHandler = errorEvent => {
  errorEvent.preventDefault();
  console.error(errorEvent);
  if (errorEvent?.message === 'ResizeObserver loop limit exceeded') {
    return false;
  }
  systemEventsEmitter.emit(SystemEvents.FEEDBACK_DIALOG, { errorEvent });
};

app.run(function () {
  // workaround for angular-ui-grid. We need to upgrade our angular version to the latest version, due to security reasons
  // however, angular-ui-grid needs to be update as well, but the upgrade creates several regression that are hard to solve
  // since old angular-ui-grid uses a deprecated method angular.uppercase/lowercase, we patch angular and add that functionality back in
  window.angular.uppercase = value => {
    return value.toUpperCase();
  };

  window.angular.lowercase = value => {
    return value.toLowerCase();
  };

  function onMouseDownHandler(event) {
    const { target } = event;

    if (target instanceof HTMLElement) {
      const closeOne = target?.closest('.focusable');
      if (closeOne) {
        closeOne.classList.add('focusable-click');
      }
    }
  }

  function onBlurHandler(event) {
    const { target } = event;

    if (target instanceof HTMLElement) {
      if (target?.classList) {
        target.classList.remove('focusable-click');
      }
    }
  }

  window.addEventListener('mousedown', onMouseDownHandler, { capture: true });
  window.addEventListener('blur', onBlurHandler, { capture: true });
});

app.component('app', {
  template: appTemplate,
  controller: function () {
    this.authSessionKey = bannerNotificationUtil.SHOW_AUTH_SECRET_KEY_BANNER;
    this.authEventName = SystemEvents.USER_CLOSED_AUTH_SECRET_KEY_BAR;

    const setConnectionLostViewStatus = status =>
      ($rootScope.showConnectionLostView = Boolean(sessionStorageService.get('bigIdTokenID')) && status);

    const setConnectionLostViewStatusHandler = status => () =>
      $rootScope.$apply(() => setConnectionLostViewStatus(status));

    setConnectionLostViewStatus(!window.navigator.onLine);

    window.addEventListener('online', setConnectionLostViewStatusHandler(false));
    window.addEventListener('offline', setConnectionLostViewStatusHandler(true));

    this.hasBanner = () =>
      this.showLicenseViolationBanner ||
      this.showApplicationOfflineBar ||
      this.showSecretKeyChangeBar ||
      this.showAuthSecretKeyChangeBar ||
      this.showApplicationLicenseExpiredBar;

    const shouldShowLicenseViolationBanner = () => {
      this.showLicenseViolationBanner = bannerNotificationUtil.shouldShowLicenseViolationBanner();
    };

    this.isFullWidthPage = () => $rootScope.isFullWidthPage || FULL_WIDTH_PAGES.includes($state.current.name);

    const shouldShowFullWidthPage = state => {
      $timeout(() => {
        $rootScope.isFullWidthPage = state;
      }, 0);
    };

    const getShouldShowNewNavigationBar = () => {
      this.shouldShowNewNavigationBar = getApplicationPreference(ENABLE_NEW_UX_NAVIGATION);
    };

    const getBigChatIsEnabled = () => {
      this.bigChatIsEnabled = isBigChatEnabled();
    };

    const getShowBigChatInHeader = () => {
      const result = getShouldShowBigChatInHeader();
      this.showBigChatInHeader = result;
      headerEventEmitter.emit(HeaderEvents.SHOW_BIGCHAT_IN_HEADER, { data: { showBigChatInHeader: result } });
    };

    this.setShowBigChatInHeader = value => {
      this.showBigChatInHeader = value;
      headerEventEmitter.emit(HeaderEvents.SHOW_BIGCHAT_IN_HEADER, { data: { showBigChatInHeader: value } });
      setShouldShowBigChatInHeader(value);
      $rootScope.$applyAsync();
    };

    const setSessionForSecretKeyBanner = () => {
      sessionStorageService.set(bannerNotificationUtil.SHOW_SECRET_KEY_BANNER, true);
      sessionStorageService.set(bannerNotificationUtil.SHOW_AUTH_SECRET_KEY_BANNER, true);
      shouldShowSecretKeyChangeBar();
      shouldShowAuthSecretKeyChangeBar();
    };

    const shouldShowSecretKeyChangeBar = () => {
      this.showSecretKeyChangeBar = bannerNotificationUtil.shouldShowSecretKeyChangeBanner();
      $rootScope.$applyAsync();
    };

    const shouldShowAuthSecretKeyChangeBar = () => {
      this.showAuthSecretKeyChangeBar = bannerNotificationUtil.shouldShowSecretKeyAuthBanner();
      $rootScope.$applyAsync();
    };

    const hideBanners = () => {
      this.showExpirationBar = false;
      this.showSecretKeyChangeBar = false;
      this.showAuthSecretKeyChangeBar = false;
    };

    const checkBannersVisible = () => {
      shouldShowLicenseViolationBanner();
      shouldShowSecretKeyChangeBar();
      shouldShowAuthSecretKeyChangeBar();
    };

    const shouldShowApplicationBanner = ({ isOffline, isExpired }) => {
      this.showApplicationOfflineBar = isOffline;
      this.showApplicationLicenseExpiredBar = isExpired;

      if (isOffline || isExpired) {
        hideBanners();
      } else {
        checkBannersVisible();
      }
    };

    this.$onInit = () => {
      this.loginSuccessListener = systemEventsEmitter.addEventListener(
        SystemEvents.LOGIN,
        shouldShowLicenseViolationBanner,
      );

      this.shouldShowNewNavigationBarListener = systemEventsEmitter.addEventListener(
        SystemEvents.LOGIN,
        getShouldShowNewNavigationBar,
      );

      this.bigChatIsEnabledListener = systemEventsEmitter.addEventListener(SystemEvents.LOGIN, getBigChatIsEnabled);

      this.showBigChatInHeaderListener = systemEventsEmitter.addEventListener(
        SystemEvents.LOGIN,
        getShowBigChatInHeader,
      );

      this.loginSuccessListener2 = systemEventsEmitter.addEventListener(SystemEvents.LOGIN, startMeteringInterval);
      this.loginSuccessListener3 = systemEventsEmitter.addEventListener(
        SystemEvents.LOGIN,
        setSessionForSecretKeyBanner,
      );
      this.loginSuccessListener4 = systemEventsEmitter.addEventListener(
        SystemEvents.LOGIN,
        checkNotificationPermission,
      );
      this.updateLicenseListener = systemEventsEmitter.addEventListener(
        SystemEvents.UPDATE_LICENSE,
        shouldShowLicenseViolationBanner,
      );

      this.onUserClosedExpirationBarListener = systemEventsEmitter.addEventListener(
        SystemEvents.USER_CLOSED_EXPIRATION_BAR,
        shouldShowLicenseViolationBanner,
      );

      this.onUserClosedSecretKeyBarListener = systemEventsEmitter.addEventListener(
        SystemEvents.USER_CLOSED_SECRET_KEY_BAR,
        shouldShowSecretKeyChangeBar,
      );

      this.onUserClosedAuthSecretKeyBarListener = systemEventsEmitter.addEventListener(
        SystemEvents.USER_CLOSED_AUTH_SECRET_KEY_BAR,
        shouldShowAuthSecretKeyChangeBar,
      );

      this.applicationOfflineBarListener = systemEventsEmitter.addEventListener(
        SystemEvents.SHOULD_SHOW_APPLICATION_BANNER,
        shouldShowApplicationBanner,
      );

      this.onToggleLayoutFullWidhtState = systemEventsEmitter.addEventListener(
        SystemEvents.UPDATE_LAYOUT_FULL_WIDTH_STATE,
        state => {
          shouldShowFullWidthPage(state);
        },
      );

      this.applicationSessionStorageAppTokenListener = systemEventsEmitter.addEventListener(
        SystemEvents.SET_APP_TOKEN,
        state => {
          if (state.appName) {
            sessionStorageService.set(`${state.appName}Token`, state.token);
          }
        },
      );

      if (getApplicationPreference('SHOW_ERROR_REPORTER')) {
        window.addEventListener('error', onErrorHandler);
      }
      getShouldShowNewNavigationBar();
      getBigChatIsEnabled();
      getShowBigChatInHeader();
      startMeteringInterval();
      shouldShowFullWidthPage(false);
    };

    this.$onChanges = shouldShowLicenseViolationBanner;
    this.$onDestroy = () => {
      this.loginSuccessListener();
      this.loginSuccessListener2();
      this.loginSuccessListener3();
      this.loginSuccessListener4();
      this.updateLicenseListener();
      this.applicationOfflineBarListener();
      this.onUserClosedExpirationBarListener();
      this.onUserClosedSecretKeyBarListener();
      this.onUserClosedAuthSecretKeyBarListener();
      this.shouldShowNewNavigationBarListener();
      this.bigChatIsEnabledListener();
      this.showBigChatInHeaderListener();
      this.onToggleLayoutFullWidhtState();
      this.applicationSessionStorageAppTokenListener();
      window.removeEventListener('error', onErrorHandler);
    };
  },
});

app.value('LOGIN_STATE_NAME', CONFIG.states.LOGIN);

app.config(function (
  $compileProvider,
  $qProvider,
  $stateProvider,
  $urlRouterProvider,
  $locationProvider,
  $appConfig,
  $translateProvider,
) {
  'ngInject';

  $compileProvider.debugInfoEnabled(process.env.NODE_ENV !== 'production');

  // translation config
  $translateProvider.useLoader('translationsLoader');
  $translateProvider.use($appConfig.defaultLocale);
  $translateProvider.useSanitizeValueStrategy(null);

  // Possibly unhandled rejection: canceled HOT FIX
  $qProvider.errorOnUnhandledRejections(false);
  $locationProvider.hashPrefix('');

  // default state
  $urlRouterProvider.otherwise(($injector, $location) => {
    const path = $location.path();
    const isRootPath = path === '' || path === '/';
    localStorageService.set('isRootPath', isRootPath);
    $injector.invoke([
      '$state',
      $state => $state.go(isRootPath ? CONFIG.states.DASHBOARD : CONFIG.states.NOT_FOUND, { defaultState: true }),
    ]);
  });

  const loginStateUrlParams = ['error', 'auth', 'logout', 'userInfoRespond', 'abortDisabled'];

  /**
   * @param paramsArr {Array<string>} list of params to be resolved from the $transitions$ object
   *
   * @return {Object} - object to be used in state resolve option
   */
  const paramsToResolve = paramsArr => {
    return paramsArr.reduce(
      (all, paramName) => ({
        ...all,
        [paramName]: $transition$ => {
          'ngInject';
          return $transition$.params()[paramName];
        },
      }),
      {},
    );
  };

  $stateProvider.state(CONFIG.states.LOGIN, {
    component: withTheme(Login),
    url: '/login?' + loginStateUrlParams.join('&'),
    resolve: {
      samlConf: () => loginService.getSamlConf(),
      remoteUserConf: () => loginService.getRemoteUserConf(),
      ...paramsToResolve(loginStateUrlParams),
    },
    data: {},
  });

  states.forEach(({ name, definition: { component, ...rest } }) => {
    const definition = { ...rest, ...(component && { component: withTheme(component) }) };

    $stateProvider.state(name, definition);
  });
});

function authResolve($timeout, $state, $q, $rootScope, localStorageService, $appConfig) {
  'ngInject';

  $rootScope.isAuthorized = Boolean(sessionStorageService.get('bigIdTokenID'));

  if (!$rootScope.isAuthorized) {
    $timeout(() => {
      $state.go(CONFIG.states.LOGIN);
    });
    return false;
  } else {
    const states = $state.get();
    const { name: requestedPageRouteName, queryParams: requestedPageRouteParmas } =
      localStorageService.get('requestedPage');

    const {
      data: { featureFlag },
    } = states.find(state => state.name === requestedPageRouteName);

    // RBAC verification
    const hasExpiredLicense = licenseService.shouldBlockUserWithLicenseExpired();
    const canViewLicense = isPermitted(GENERAL_SETTINGS_PERMISSIONS.READ_LICENSE.name);

    if (hasExpiredLicense) {
      if (!canViewLicense) {
        return $state.go(CONFIG.states.FORBIDDEN);
      }
      if (
        requestedPageRouteName === CONFIG.states.GENERAL_SETTINGS &&
        requestedPageRouteParmas?.currentTab === LICENSE_TAB
      ) {
        return $timeout(() => {
          return;
        });
      }
      licenseService.goToLicensePage();
      return $q.reject();
    }

    const isPageEnabled = featureFlag ? getApplicationPreference(featureFlag) : true;
    if (!isPageEnabled) {
      $timeout(() => $state.go(CONFIG.states.DASHBOARD));
      return $q.reject();
    }

    if (!isPagePermitted(requestedPageRouteName)) {
      $timeout(() => $state.go(CONFIG.states.FORBIDDEN));
      return $q.reject();
    }

    if (!smallIdLicenseService.isAccessGranted(requestedPageRouteName)) {
      $timeout(licenseService.openLicenseUpgradeFlow);
      return $q.reject();
    }

    localStorageService.remove('requestedPage');
    localStorageService.remove('isRootPath');

    if (!$rootScope.bigidHeaderComponentExist) {
      // slightly delay the transition of ui-view because we need to have bigid-header component exist before
      return $timeout(() => {
        return;
      });
    }
  }
}

function isLoginState(state) {
  return state && state.name === CONFIG.states.LOGIN;
}

app.run(function ($rootScope, $anchorScroll, localStorageService, $document, $transitions, $uibModalStack) {
  'ngInject';

  // don't convert below hook to `onBefore`
  // it has a bug with async calls which cause flickering issues in angular components
  $transitions.onStart({}, () => {
    return initPermissions();
  });

  $transitions.onStart({}, () => {
    return initScannerTypesSupportedFeatures();
  });

  $transitions.onStart({}, () => {
    return getSupportedDataSources();
  });

  $transitions.onStart({}, transition => {
    return updateWorkspaceOnTransition(transition.to().name);
  });

  $transitions.onStart({}, transition => {
    const toState = transition.to();

    $rootScope.isAuthorized = Boolean(sessionStorageService.get('bigIdTokenID'));
    $rootScope.loaded = $rootScope.isAuthorized && !isLoginState(toState);
    $rootScope.isPasswordChangeNeeded = isPasswordChangeNeeded();

    if (!isLoginState(toState)) {
      if (isPasswordChangeNeeded()) {
        return $state.go(CONFIG.states.UPDATE_PASSWORD);
      }

      localStorageService.set('requestedPage', {
        name: toState.name,
        queryParams: transition.params(),
      });

      $rootScope.isRootPath = false;

      return transition.injector().get('$injector').invoke(authResolve);
    }
  });

  $rootScope.$on('$locationChangeSuccess', function () {
    $anchorScroll();
  });

  $transitions.onSuccess({}, transition => {
    const toState = transition.to();
    const fromState = transition.from();
    const fromParams = transition.params('from');
    const toParams = transition.params();

    $rootScope.showBackgroundImage = isLoginState(toState);
    $rootScope.loaded = !isLoginState(toState);
    $rootScope.isPasswordChangeNeeded = isPasswordChangeNeeded();
    $rootScope.uiViewQueryFilter = toState.name === 'exploration' || toState.name === 'dashboard';
    $rootScope.isFullWidthPage = false;

    if (fromState.url !== '^' && !isLoginState(toState) && !isLoginState(fromState)) {
      const history = sessionStorageService.get('history') || [];
      let historyIndex = sessionStorageService.get('historyIndex') ?? -1;

      history.push({
        to: {
          state: toState.name,
          params: toParams,
        },
        from: {
          state: fromState.name,
          params: fromParams,
        },
      });
      sessionStorageService.set('history', history);

      historyIndex = history.length - 1;
      sessionStorageService.set('historyIndex', historyIndex);
    }

    if (isLoginState(toState)) {
      document.title = DEFAULT_DOCUMENT_TITLE;
    }

    const { name } = toState;
    setRecentlyUsedFromState(name, toParams?.id);
  });

  // FIXME: !!!!
  $rootScope.$on('changePage', (event, pageName, showSearchHeader = false) => {
    $rootScope.isSearchHeaderVisible = showSearchHeader;
  });

  $rootScope.$on('$scrollTop', () => {
    $document[0].getElementById('uiView').scrollTop = 0;
  });

  $rootScope.pageHeaderService = pageHeaderService;

  headerEventEmitter.addEventListener(HeaderEvents.HEADER_HIDE, () => {
    $rootScope.$applyAsync();
  });

  heartBeatService.activate();
  createSSEConnection();
  analyticsService.init();
  datadogInitListeners();

  dateTimeService.setTimeZone(getApplicationPreference('TIME_ZONE'));
  systemEventsEmitter.addEventListener(SystemEvents.LOGIN, () => {
    dateTimeService.setTimeZone(getApplicationPreference('TIME_ZONE'));
  });

  dateTimeService.setLocale(getApplicationPreference('UI_DATE_TIME_LOCALE'));
  systemEventsEmitter.addEventListener(SystemEvents.APPLICATION_PREFERENCES_UPDATED, () => {
    dateTimeService.setLocale(getApplicationPreference('UI_DATE_TIME_LOCALE'));
  });

  systemEventsEmitter.addEventListener(SystemEvents.LOGOUT, () => {
    // close all active modals
    $uibModalStack.dismissAll();
  });
});
