import './addTagModal.component.scss';
import template from './addTagModal.component.html';
import angular from 'angular';
import { escape, unescape } from 'lodash';
angular.module('app').component('addTagModal', {
  template,
  controller: function (savedQueriesService) {
    'ngInject';
    this.tags = [];
    this.tagNameValues = [];
    this.uniqueTagsNames = [];
    this.selectedTagsByName = [];

    this.onAddTag = () => {
      this.selectedTagsByName.push([]);
    };

    this.onDeleteTag = index => {
      if (index >= 0) {
        if (this.selectedTagsByName.length === 1) {
          this.selectedTagsByName[0] = {};
        } else {
          this.selectedTagsByName.splice(index, 1);
        }
      }
    };

    this.onTagSelected = (selectedTag, index) => {
      if (selectedTag && index >= 0) {
        this.tagNameValues = this.tags.filter(tag => tag.tag_name == selectedTag.tag_name);
        selectedTag.tag = {};
      }
    };

    this.loadValuesForTagName = index => {
      if (index >= 0) {
        if (this.selectedTagsByName[index].tag_name && this.selectedTagsByName[index].tag_name !== '') {
          this.tagNameValues = this.tags
            .filter(tag => tag.tag_name === unescape(this.selectedTagsByName[index].tag_name))
            .map(tag => {
              return {
                ...tag,
                tag_value: escape(tag.tag_value),
              };
            });
        } else {
          this.tagNameValues = [];
        }
      }
    };

    this.getSelectedTagsByName = () => {
      if (this.selectedTags && this.selectedTags.length > 0) {
        this.selectedTagsByName = this.selectedTags.map(tag => ({ tag_name: tag.tag_name, tag: tag }));
      } else {
        this.selectedTagsByName = [{}];
      }
    };

    this.closeModal = () => {
      this.selectedTags = this.selectedTagsByName.map(selected => selected.tag);
      this.$close({ selectedTags: this.selectedTags });
    };

    this.$onInit = () => {
      this.selectedTags = this.selectedTags && this.selectedTags.length > 0 ? [...this.selectedTags] : [];
      this.getSelectedTagsByName();
      savedQueriesService.getSavedQueries().then(result => {
        if (result) {
          this.tags = result.map(tag => {
            return {
              ...tag,
              tag_name: escape(tag.tag_name),
            };
          });
          this.uniqueTagsNames = [...new Set(this.tags.map(tag => tag.tag_name))].map(name => ({
            tag_name: escape(name),
          }));
        }
      });
    };
  },
  bindings: {
    selectedTags: '<',
    isTagsIdenticalForAll: '<',
    $close: '&',
    $dismiss: '&',
  },
});
