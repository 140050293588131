import { BigidTextField, TextFieldIconPositionEnum } from '@bigid-ui/components';
import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { BigidSearchIcon } from '@bigid-ui/icons';
import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { QuickSearchResults } from './components/QuickSearchResults';
import { RecentlyViewedItemsPopper } from './components/RecentlyViewedResults';
import { useInputFocus } from './hooks/useInputFocus';
import { QuickSearchPopper } from './components/QuickSearchPopper';
import { useQuery } from 'react-query';
import { getRecentlyViewedCatalogItems } from '../../DataCatalog/recentlyViewedCatalogItemService';
import { fetchQuickSearchResults } from './dataExplorerQuickSearchService';

const SHOW_SEARCH_RESULTS_TEXT_LENGTH_THRESHOLD = 2;

const DataExplorerQuickSearchContainer = styled('div')<{ width: number }>`
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
`;

type DataExplorerQuickSearchProps = {
  quickSearchDisabled?: boolean;
  placeholder?: string;
  initialSearchValue?: string;
  dataAid?: string;
  isDisabled?: boolean;
  isSmall?: boolean;
  width?: number;
  onSubmit?: (searchText: string) => void;
};

export const DataExplorerQuickSearch: FC<DataExplorerQuickSearchProps> = ({
  quickSearchDisabled,
  placeholder,
  initialSearchValue = '',
  dataAid = 'DataExplorerQuickSearch',
  isDisabled,
  isSmall,
  width,
  onSubmit,
}) => {
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const anchorEl = useRef<HTMLDivElement>(null);

  const showSearchResults = searchValue?.length > SHOW_SEARCH_RESULTS_TEXT_LENGTH_THRESHOLD;

  const { handleChildClick, onClickOutsideHandleClose, open, setOpen, isFocused } = useInputFocus(anchorEl);

  const { data: recentlyViewedData, refetch: refetchRecentlyViewed } = useQuery(
    ['recentlyViewed'],
    getRecentlyViewedCatalogItems,
    {
      enabled: isFocused,
      keepPreviousData: true,
      placeholderData: {
        preference: '',
        data: {
          recentlyViewedItems: [],
        },
      },
    },
  );

  const {
    data: { data: quickSearchData },
    isLoading: isQuickSearchLoading,
    isFetching: isQuickSearchFetching,
  } = useQuery(['quickSearch', searchValue], () => fetchQuickSearchResults(searchValue), {
    enabled: searchValue?.length > SHOW_SEARCH_RESULTS_TEXT_LENGTH_THRESHOLD,
    placeholderData: {
      data: {
        catalog: { count: 0, results: [] },
        datasource: { count: 0, results: [] },
        policy: { count: 0, results: [] },
      },
    },
    keepPreviousData: true,
  });

  const recentlyViewedItems = useMemo(() => recentlyViewedData?.data?.recentlyViewedItems || [], [recentlyViewedData]);

  useEffect(() => {
    if ((recentlyViewedItems.length > 0 || showSearchResults) && isFocused) {
      setOpen(true);
      return;
    }
    setOpen(false);
  }, [recentlyViewedItems, setOpen, showSearchResults, isFocused]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(event.target.value);
    if (event.target.value.length <= SHOW_SEARCH_RESULTS_TEXT_LENGTH_THRESHOLD && recentlyViewedItems.length === 0) {
      setOpen(false);
      refetchRecentlyViewed();
    }
  };

  const handleSearchSubmit = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onSubmit?.(searchValue?.trim());
      $state.go(CONFIG.states.CATALOG_SEARCH_RESULTS, { query: searchValue?.trim() });
    }
  };

  const resultsToShow = showSearchResults ? (
    <QuickSearchResults
      searchText={searchValue}
      handleChildClick={handleChildClick}
      setOpen={setOpen}
      data={quickSearchData}
      isLoading={isQuickSearchLoading || isQuickSearchFetching}
    />
  ) : (
    <RecentlyViewedItemsPopper handleChildClick={handleChildClick} recentlyViewedItems={recentlyViewedItems} />
  );

  const shouldOpen = open && isFocused && !quickSearchDisabled;

  return (
    <DataExplorerQuickSearchContainer ref={anchorEl} width={width}>
      <BigidTextField
        size={isSmall ? 'medium' : 'large'}
        onChange={handleSearchChange}
        onKeyPress={handleSearchSubmit}
        placeholder={placeholder}
        value={searchValue}
        disabled={isDisabled}
        dataAid={generateDataAid(dataAid, ['search-bar'])}
        icon={{
          Icon: BigidSearchIcon,
          position: TextFieldIconPositionEnum.right,
        }}
      />
      <QuickSearchPopper anchorEl={anchorEl} open={shouldOpen} handleClose={onClickOutsideHandleClose}>
        {resultsToShow}
      </QuickSearchPopper>
    </DataExplorerQuickSearchContainer>
  );
};
