import { styled } from '@mui/material';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { BigidToolbar, ColumnInfo, ToolbarAction, ToolbarActionType } from '@bigid-ui/components';
import { BigidAddTagIcon, BigidDashboardIcon, BigidExportIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../translations';
import { getDataCatalogRecordsAsCSV } from '../../DataCatalogService';
import { analyticsService } from '../../../../services/analyticsService';
import { notificationService } from '../../../../services/notificationService';
import { CatalogEventsEnum } from '../../events';
import {
  DataCatalogBulkTagAssignmentDialog,
  DataCatalogBulkTagAssignmentDialogProps,
} from '../../DataCatalogBulkTagAssignmentDialog';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { docsUrls } from '../../../../config/publicUrls';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { DataCatalogContext } from '../newDataCatalogContext';
import { getViewInDataOverviewFilters } from '../newDataCatalogUtils';
import { generateDataAid } from '@bigid-ui/utils';
import { WithDataAid } from '../types';

const ActionsContainer = styled('div')``;

type DataCatalogActionRowProps = Pick<DataCatalogContext, 'selectedFilters' | 'isCustomQuery'> & {
  totalCount: number;
  columns: ColumnInfo[];
  setHideColumns: (columns: ColumnInfo[]) => void;
  filterString: string;
  refetchData: () => void;
  shouldShowAssignBulkTagButton: boolean;
  shouldShowViewInDataOverviewButton: boolean;
  isFetchingCount: boolean;
};

export const DataCatalogActionRow: FC<WithDataAid<DataCatalogActionRowProps>> = ({
  filterString,
  totalCount = 0,
  selectedFilters,
  columns,
  setHideColumns,
  isCustomQuery,
  dataAid = 'DataCatalogActionRow',
  refetchData,
  shouldShowAssignBulkTagButton,
  shouldShowViewInDataOverviewButton,
  isFetchingCount,
}) => {
  const { t } = useLocalTranslation('actions');
  const [bulkTagAssignmentDialogState, setBulkTagAssignmentDialogState] =
    useState<DataCatalogBulkTagAssignmentDialogProps>({
      filter: '',
      isOpen: false,
    });

  const handleExportButtonClick = useCallback(async () => {
    try {
      await getDataCatalogRecordsAsCSV(filterString);
    } catch (err) {
      const errMsg = `An error has occurred`;
      notificationService.error(errMsg);
      console.error(`${errMsg}: ${err}`);
    } finally {
      const trackData = {
        Search_Query_Input: filterString,
        totalHits: totalCount,
        isCustomQuery,
      };

      analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_EXPORT, trackData);
    }
  }, [filterString, isCustomQuery, totalCount]);

  const closeBulkTagAssignmentDialog = useCallback(() => {
    setBulkTagAssignmentDialogState(prevState => ({
      ...prevState,
      filter: undefined,
      isOpen: false,
    }));
  }, []);

  const handleAddTagButtonClick = useCallback(() => {
    setBulkTagAssignmentDialogState(prevState => ({
      ...prevState,
      filter: filterString,
      isOpen: true,
      onSubmit: () => {
        closeBulkTagAssignmentDialog();
        const trackingData = {
          objectsCount: totalCount,
          filterString,
        };
        refetchData();
        analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_ASSIGN_BULK_TAG, trackingData);
      },
    }));
  }, [closeBulkTagAssignmentDialog, filterString, totalCount, refetchData]);

  const handleViewInDOButtonClick = useCallback(() => {
    const isDataOverviewEnabled = getApplicationPreference('DATA_OVERVIEW_ENABLED');

    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_GOTODISCOVERYINSIGHTS_CLICK);

    if (!isDataOverviewEnabled) {
      window.open(docsUrls.DISCOVERY_INSIGHTS, '_blank');
      return;
    }

    const filtersToSendToDiscovery = getViewInDataOverviewFilters(selectedFilters);

    $state.go(CONFIG.states.CATALOG_DISCOVERY, {
      selectedCatalogFilters: filtersToSendToDiscovery,
      transitionFromNewCatalog: true,
    });
  }, [selectedFilters]);

  const bulkTagAssignmentDialogConfig: DataCatalogBulkTagAssignmentDialogProps = useMemo(
    () => ({
      ...bulkTagAssignmentDialogState,
      onClose: closeBulkTagAssignmentDialog,
    }),
    [bulkTagAssignmentDialogState, closeBulkTagAssignmentDialog],
  );

  const isViewInDataOverviewButtonDisabled = totalCount === 0 || (isCustomQuery && !!filterString.length);

  const toolbarActions: ToolbarAction[] = useMemo(() => {
    const basicActions: ToolbarAction[] = [];

    if (shouldShowAssignBulkTagButton) {
      basicActions.push({
        label: t('assignTag.label'),
        icon: BigidAddTagIcon,
        execute: async () => {
          handleAddTagButtonClick();
          return {};
        },
        tooltip: Boolean(filterString) ? t('assignTag.tooltipEnabled') : t('assignTag.tooltipDisabled'),
        disable: () => {
          return totalCount === 0 || !Boolean(filterString);
        },
        type: ToolbarActionType.TERTIARY,
        placement: 'start',
        show: () => true,
      });
    }

    if (shouldShowViewInDataOverviewButton) {
      basicActions.push({
        label: t('viewInDO.label'),
        icon: BigidDashboardIcon,
        execute: async () => {
          handleViewInDOButtonClick();
          return {};
        },
        placement: 'start',
        type: ToolbarActionType.TERTIARY,
        show: () => true,
        disable: () => isViewInDataOverviewButtonDisabled,
      });
    }

    basicActions.push({
      label: t('export.label'),
      icon: BigidExportIcon,
      type: ToolbarActionType.ACTION_ICON,
      execute: async () => {
        handleExportButtonClick();
        return {};
      },
      show: () => true,
      disable: () => totalCount === 0,
      placement: 'end',
      tooltip: t('export.label'),
    });

    return basicActions;
  }, [
    t,
    filterString,
    totalCount,
    handleAddTagButtonClick,
    handleViewInDOButtonClick,
    handleExportButtonClick,
    isViewInDataOverviewButtonDisabled,
    shouldShowAssignBulkTagButton,
    shouldShowViewInDataOverviewButton,
  ]);

  return (
    <ActionsContainer data-aid={generateDataAid(dataAid, ['action-row'])}>
      <BigidToolbar
        toolbarActions={toolbarActions}
        entityName="Items"
        totalRows={totalCount}
        isFetchingCount={isFetchingCount}
        columns={columns}
        onColumnChange={setHideColumns}
        shouldShowColumnChooser
        shareIconConfig={{
          getIsDisabled: () => false,
          getShareUrl: () => window.location.href,
          getIsShown: () => true,
        }}
      />
      <DataCatalogBulkTagAssignmentDialog {...bulkTagAssignmentDialogConfig} />
    </ActionsContainer>
  );
};
