import React from 'react';
import styled from '@emotion/styled';
import { PrimaryButton, TertiaryButton } from '@bigid-ui/components';

const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 72px;
  padding-right: 1rem;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  position: absolute;
  bottom: 0;
  border-top: 1px solid rgb(230, 230, 230);
`;

interface MappedControlsDialogFooterProps {
  handleDialogVisibility: (value: boolean) => void;
  handlePutMappedControls: () => void;
}

export const MappedControlsDialogFooter = ({
  handleDialogVisibility,
  handlePutMappedControls,
}: MappedControlsDialogFooterProps) => {
  return (
    <Footer>
      <TertiaryButton onClick={() => handleDialogVisibility(false)} size="medium" text="Cancel" />
      <PrimaryButton onClick={() => handlePutMappedControls()} size="medium" text="Save" />
    </Footer>
  );
};
