import React from 'react';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps, IconFormatterProps } from '@bigid-ui/grid';
import { DataSourceModel } from '../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { actionTypes, DsarDeletionObjectModel } from './DataMinimizationObjects';
import { getSupportedDataSources } from '../../../utilities/dataSourcesUtils';
import RDBIcon from '../../../assets/icons/BigidRDB.svg';
import { BigidColors, BigidIconSize } from '@bigid-ui/components';
import FileIcon from '../../../assets/icons/BigidFile.svg';
import {
  BigidAppLogoDataRightsFulfillmentIcon,
  BigidAppLogoRemediationIcon,
  BigidAppLogoDataRetentionIcon,
} from '@bigid-ui/icons';

export const dataMinimizationObjectsDefaultColumns: BigidGridColumn<DsarDeletionObjectModel>[] = [
  {
    title: 'Object Name',
    name: 'objectName',
    type: BigidGridColumnTypes.TEXT,
    width: 150,
    getCellValue: ({ objectName }) => objectName,
    isListColumn: true,
  },
  {
    title: 'Type',
    name: 'objectType',
    type: BigidGridColumnTypes.ICON,
    width: 100,
    getCellValue: item => objectTypeIndicatorMapping.get(item?.objectType?.toLowerCase()),
  },
  {
    title: 'Request Type',
    name: 'requestType',
    type: BigidGridColumnTypes.ICON,
    width: 100,
    getCellValue: item =>
      objectTypeIndicatorMapping.get(item?.requestType ? item?.requestType?.toLowerCase() : REQUEST_TYPE.DSAR),
  },
  {
    title: 'Data Source Name',
    name: 'dataSourceName',
    width: 170,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ dataSourceName }) => dataSourceName,
  },
  {
    title: 'Schema Name',
    name: 'schemaName',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ schemaName }) => schemaName,
  },
  {
    title: 'Scanner type',
    name: 'scannerType',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ scannerType }) => scannerType,
  },
  {
    title: 'Created Date',
    name: 'createdAt',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ createdAt }) => createdAt,
  },
  {
    title: 'State',
    name: 'state',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: true,
    getCellValue: ({ state }) => state || 'Pending',
  },
  {
    title: 'Marked as',
    name: 'markedAs',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ markedAs }) => markedAs,
  },
  {
    title: 'Removable Rows/Files',
    name: 'estimatedRows',
    width: 140,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ estimatedRows }) => estimatedRows || '0',
  },
  {
    title: 'Actual Deleted Rows/Files',
    name: 'deletedRows',
    width: 140,
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ deletedRows }) => deletedRows || '0',
  },
];

export async function getDataMinimizationObjectsFilterConfig(): Promise<
  BigidGridWithToolbarProps<DataSourceModel>['filterToolbarConfig']
> {
  const dataSources = await getSupportedDataSources();
  return {
    filters: [
      {
        title: 'Scanner Type',
        field: 'scannerType',
        operator: 'in',
        disabled: true,
        options: dataSources.map(({ name, displayName }) => ({
          label: displayName || name,
          value: name.toLowerCase(),
          isSelected: false,
        })),
        value: [],
      },
      {
        title: 'State',
        field: 'state',
        operator: 'in',
        disabled: true,
        value: [],
        options: [
          {
            label: 'Pending',
            value: SCANS.PENDING,
            isSelected: false,
          },
          {
            label: 'Queued',
            value: SCANS.QUEUED,
            isSelected: false,
          },
          {
            label: 'In Progress',
            value: SCANS.IN_PROGRESS,
            isSelected: false,
          },
          {
            label: 'Completed',
            value: SCANS.COMPLETED,
            isSelected: false,
          },

          {
            label: 'Failed',
            value: SCANS.FAILED,
            isSelected: false,
          },
        ],
      },
      {
        title: 'Marked As',
        field: 'markedAs',
        operator: 'in',
        disabled: true,
        value: [],
        options: [
          {
            label: 'Pending',
            value: '',
            isSelected: false,
          },
          {
            label: 'Delete Automatically',
            value: actionTypes.DELETE_AUTO,
            isSelected: false,
          },
          {
            label: 'Delete Manually',
            value: actionTypes.DELETE_MANUAL,
            isSelected: false,
          },
          {
            label: 'Retained',
            value: actionTypes.RETAINED,
            isSelected: false,
          },
        ],
      },
      {
        title: 'Type',
        field: 'objectType',
        operator: 'in',
        disabled: true,
        single: true,
        value: [],
        options: [
          {
            label: 'RDB',
            value: OBJECT_TYPE.RDB,
            isSelected: false,
          },
          {
            label: 'File',
            value: OBJECT_TYPE.FILE,
            isSelected: false,
          },
        ],
      },
    ],
    searchConfig: {
      searchFilterKeys: ['objectName'],
      placeholder: 'Object Name',
      initialValue: '',
      operator: 'contains',
    },
  };
}

export enum SCANS {
  PENDING = 'Pending',
  QUEUED = 'Queued',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  EXPIRED = 'Expired',
  STARTED = 'Started',
}

export enum OBJECT_TYPE {
  RDB = 'rdb',
  FILE = 'file',
  PARTITIONED_TABLE = 'partitioned_table',
}

export enum REQUEST_TYPE {
  DSAR = 'dsar',
  REMEDIATION = 'remediation',
  RETENTION = 'retention',
}

const objectTypeIndicatorMapping = new Map<string, IconFormatterProps>([
  [
    OBJECT_TYPE.RDB,
    {
      icon: {
        icon: RDBIcon,
        color: BigidColors.purple[300],
        label: 'RDB',
        size: BigidIconSize.REGULAR,
      },
    },
  ],
  [
    OBJECT_TYPE.FILE,
    {
      icon: {
        icon: FileIcon,
        color: BigidColors.purple[300],
        label: 'File',
        size: BigidIconSize.REGULAR,
      },
    },
  ],
  [
    OBJECT_TYPE.PARTITIONED_TABLE,
    {
      icon: {
        icon: FileIcon,
        color: BigidColors.purple[300],
        label: 'Partitioned table',
        size: BigidIconSize.REGULAR,
      },
    },
  ],
  [
    REQUEST_TYPE.REMEDIATION,
    {
      icon: {
        icon: () => <BigidAppLogoRemediationIcon size={20} />,
        color: BigidColors.purple[300],
        label: 'Remediation',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
  [
    REQUEST_TYPE.DSAR,
    {
      icon: {
        icon: () => <BigidAppLogoDataRightsFulfillmentIcon size={20} />,
        color: BigidColors.purple[300],
        label: 'DSAR',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
  [
    REQUEST_TYPE.RETENTION,
    {
      icon: {
        icon: () => <BigidAppLogoDataRetentionIcon size={20} />,
        color: BigidColors.purple[300],
        label: 'Retention',
        size: BigidIconSize.MEDIUM,
      },
    },
  ],
]);
