import React, { FC, useEffect, useMemo, useState, useCallback, ReactText } from 'react';
import { httpService } from '../../services/httpService';
import { DateISO8601 } from '../../types/types';
import {
  BigidProgressBar,
  BigidProgressBarStatus,
  BigidProgressBarProps,
  useInterval,
  BigidFilter,
  BigidHeading5,
  objectToQueryString,
  QueryParams,
} from '@bigid-ui/components';
import {
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridRow,
  BigidGridColumnTypes,
  BigidGridColumn,
  BigidGridQueryComponents,
} from '@bigid-ui/grid';
import makeStyles from '@mui/styles/makeStyles';
import { module } from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { notificationService } from '../../services/notificationService';

type ColumnClusteringProgressStatus = 'Started' | 'InProgress' | 'Completed' | 'Aborted' | 'Failed';
const failedIndicationValue = 'Failed';
let hasFailedDataSources = false;

interface ColumnClusteringProgressRecord {
  scan_id: string;
  source: string;
  started_at: DateISO8601;
  updated_at: DateISO8601;
  state: ColumnClusteringProgressStatus;
  collections_progress: number;
  err_msg: string;
}

interface ColumnClusteringProgressResponse {
  results: ColumnClusteringProgressRecord[];
  hasFailedDataSources: boolean;
}

interface ServiceConfigurationParameter {
  service: string;
  name: string;
  value: ReactText;
  isSensitive: boolean;
  type: string;
  isEditable: boolean;
  isRestartRequired: boolean;
}

interface ColumnClusteringProgressGridColumn extends BigidGridRow, ColumnClusteringProgressRecord {
  collectionsProgressFormatted: BigidProgressBarProps;
}

const getColumnClusteringProgressLogsRecords = (query: string) => {
  return httpService
    .fetch<ColumnClusteringProgressResponse>(`clusters/structured/progress?${query}`)
    .then(({ data }) => data);
};

const calculateFailedDataSources = async () => {
  try {
    const response = await httpService.post(`clusters/structured/calculate-failed`, {});
    return response.data;
  } catch (error) {
    notificationService.error('An error has occurred while recalculating failed data sources.');
    console.error(`Error occurred: ${error.message}`);
  }
};

const getClusteringMaxScanHistoryDays = () => {
  return httpService.fetch<ServiceConfigurationParameter[]>(`services-configuration`).then(({ data }) => {
    return data.find(param => param.name === 'STRUCT_CLUSTERING_MAX_SCAN_HISTORY_DAYS')?.value;
  });
};

const getProgressBarStatus = (status: ColumnClusteringProgressStatus): BigidProgressBarStatus => {
  switch (status) {
    case 'Started':
      return BigidProgressBarStatus.PREPARING;
    case 'Completed':
      return BigidProgressBarStatus.COMPLETED;
    case 'InProgress':
      return BigidProgressBarStatus.ACTING;
    case 'Aborted':
      return BigidProgressBarStatus.STOPPED;
    case 'Failed':
      return BigidProgressBarStatus.FAILED;
  }
};

const getStatusValue = (state: string, message = ''): string => {
  if (state === failedIndicationValue && message) {
    return `${state} - ${message}`;
  } else return state;
};

const getDridData = (responseData: ColumnClusteringProgressRecord[]): ColumnClusteringProgressGridColumn[] => {
  return responseData.map((record, index) => {
    const { collections_progress, state } = record;
    return {
      id: index,
      collectionsProgressFormatted: isNaN(collections_progress)
        ? undefined
        : {
            percentage: collections_progress,
            status: getProgressBarStatus(state),
          },
      ...record,
    };
  });
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    padding: '10px 0px 15px 10px',
  },
});

const FORCE_RELOAD_INTERVAL = 60000;
const DEFAULT_CLUSTERING_MAX_SCAN_HISTORY_DAYS = 30;

export const ColumnClusteringProgressGrid: FC = () => {
  const classes = useStyles();
  const [externalFilter, setExternalFilter] = useState<BigidFilter>([]);
  const [titleParameter, setTitleParameter] = useState<number>(DEFAULT_CLUSTERING_MAX_SCAN_HISTORY_DAYS);

  const getTitleParameter = useCallback(async () => {
    try {
      const param = await getClusteringMaxScanHistoryDays();
      param && setTitleParameter(Number(param));
    } catch ({ message }) {
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    }
  }, []);

  const columns: BigidGridColumn<ColumnClusteringProgressGridColumn>[] = useMemo(
    () => [
      {
        name: 'scan_id',
        title: 'Scan ID',
        width: 375,
        getCellValue: ({ scan_id }) => scan_id,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'state',
        title: 'Status',
        getCellValue: ({ state, err_msg }) => getStatusValue(state, err_msg),
        type: BigidGridColumnTypes.TEXT,
        width: 200,
      },
      {
        name: 'source',
        title: 'Data Source Name',
        getCellValue: ({ source }) => source,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'started_at',
        title: 'Started At',
        getCellValue: ({ started_at }) => started_at,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'updated_at',
        title: 'Updated At',
        getCellValue: ({ updated_at }) => updated_at,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'collections_progress',
        title: 'Progress',
        width: 375,
        getCellValue: ({ collectionsProgressFormatted }) =>
          collectionsProgressFormatted ? (
            <div>
              <BigidProgressBar {...collectionsProgressFormatted} />
            </div>
          ) : undefined,
        type: BigidGridColumnTypes.CUSTOM,
      },
    ],
    [],
  );

  const gridConfig: BigidGridWithToolbarProps<ColumnClusteringProgressGridColumn> = {
    externalFilter,
    entityName: 'records',
    showSortingControls: false,
    hideColumnChooser: true,
    fetchData: async (queryComponents: BigidGridQueryComponents) => {
      try {
        const query = objectToQueryString({
          ...(queryComponents as QueryParams),
        });
        const response = await getColumnClusteringProgressLogsRecords(query);
        const data = getDridData(response.results);
        hasFailedDataSources = response.hasFailedDataSources;
        return {
          totalCount: data.length,
          data,
          hasFailedDataSources,
        };
      } catch ({ message }) {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
        return {
          totalCount: 0,
          data: [],
        };
      }
    },
    columns,
    toolbarActions: [
      {
        label: 'Recalculate Failed Data Sources',
        show: () => hasFailedDataSources,
        isGlobal: true,
        execute: async () => {
          const result = await calculateFailedDataSources();
          if (result && result.message) {
            notificationService.success(result.message);
          }
          return {};
        },
      },
    ],
  };

  useInterval(() => {
    setExternalFilter([]);
  }, FORCE_RELOAD_INTERVAL);

  useEffect(() => {
    getTitleParameter();
  }, [getTitleParameter]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <BigidHeading5>
          The list of activities is limited to the activities of the {titleParameter} last days
        </BigidHeading5>
      </div>
      <BigidGridWithToolbar {...gridConfig} />
    </div>
  );
};

module('app').component('columnClusteringProgressGrid', convertToAngular(ColumnClusteringProgressGrid));
