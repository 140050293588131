import { BigidLoader, BigidTabs, BigidTabsItem, BigidSidePanel } from '@bigid-ui/components';
import { BigidGrid } from '@bigid-ui/grid';
import React, { FC } from 'react';
import { ResultsEntityType, WithDataAid } from '../types';
import { styled } from '@mui/material';
import { CatalogSearchToolbar } from './GridComponents/CatalogSearchToolbar';
import { Actions } from './GridComponents/Toolbars/Actions/Actions';
import { generateDataAid } from '@bigid-ui/utils';
import { useDataCatalogSearchResultsContext } from '../contexts/dataCatalogSearchResultsContext';
import { useLocalTranslation } from '../translations';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import {
  DataExplorerSearchTabSwitchBiPayload,
  sendDataExplorerResultsPageTabSwitchBiAnalytics,
} from '../../DataExplorer/services/analytics';
import { getSidePanelComponentProps } from './GridComponents/sidePanels/utils';
import { AlienEntityActions } from './GridComponents/Toolbars/EnititiesToolbar/AlienEntityActions';
import { useGridConfig } from './GridComponents/hooks/useGridConfig';

const Root = styled('div')`
  min-height: 650px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 24px 16px 24px;
`;

const GridContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

const LoaderContainer = styled('div')`
  display: flex;
  flex-grow: 1;
`;

const TabsWrapper = styled('section')`
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
`;

const resultsEntityTypeToIndexMap: Record<ResultsEntityType, number> = {
  catalog: 0,
  datasource: 1,
  policy: 2,
};

export const SearchGrid: FC<WithDataAid> = ({ dataAid = 'SearchGrid' }) => {
  const { state, dispatchCatalogSearchAction, isGridDataLoading, entityType, query } =
    useDataCatalogSearchResultsContext();
  const { gridConfig: contextGridConfig } = state;
  const { rows } = contextGridConfig;
  const { t } = useLocalTranslation('tabs');

  const handleTabChange = (index: number, { data }: BigidTabsItem<TabData>) => {
    dispatchCatalogSearchAction({
      type: 'RESET_GRID_DATA',
    });

    $state.go(CONFIG.states.CATALOG_SEARCH_RESULTS, {
      activeTab: data.entityType,
      sort: null,
      filters: null,
    });

    const biPayload: DataExplorerSearchTabSwitchBiPayload = {
      tab: data.entityType,
    };

    sendDataExplorerResultsPageTabSwitchBiAnalytics(biPayload);
  };

  const gridConfig = useGridConfig();

  type TabData = {
    entityType: ResultsEntityType;
  };
  const tabs: BigidTabsItem<TabData>[] = [
    {
      label: t('catalog'),
      data: {
        entityType: 'catalog',
      },
    },
    {
      label: t('dataSources'),
      data: {
        entityType: 'datasource',
      },
    },
    {
      label: t('policies'),
      data: {
        entityType: 'policy',
      },
    },
  ];

  const gridControls = entityType === 'catalog' ? <Actions /> : <AlienEntityActions />;

  const showLoader = isGridDataLoading || !rows;

  const handleSidePanelClose = () => {
    if (state.onPanelCloseCallback) {
      state.onPanelCloseCallback();
    }
    dispatchCatalogSearchAction({
      type: 'SET_SELECTED_ITEM',
      payload: {
        selectedItem: null,
        onPanelCloseCallback: null,
      },
    });
  };

  const sidePanelProps = getSidePanelComponentProps({
    entityType,
    selectedItem: state.selectedItem,
    highlightValue: query,
  });

  return (
    <Root data-aid={generateDataAid(dataAid, ['page'])}>
      <TabsWrapper>
        <BigidTabs
          tabs={tabs}
          selectedIndex={resultsEntityTypeToIndexMap[entityType] || 0}
          onChange={handleTabChange}
        />
      </TabsWrapper>
      <GridContainer>
        <CatalogSearchToolbar dataAid={generateDataAid(dataAid, ['toolbar'])} />
        {gridControls}
        {showLoader ? (
          <LoaderContainer>
            <BigidLoader blocking={false} position="relative" />
          </LoaderContainer>
        ) : (
          <BigidGrid key={entityType} {...gridConfig} />
        )}
      </GridContainer>
      {sidePanelProps && (
        <BigidSidePanel
          {...sidePanelProps}
          open={!!state.selectedItem}
          onClose={handleSidePanelClose}
          maxWidth="large"
          isShowBackdrop
        />
      )}
    </Root>
  );
};
