import { get, isEmpty, isNumber, isString, uniq } from 'lodash';
import { httpService } from '../../services/httpService';
import { FetchItems, ParamTypes } from './utils/CustomAppTypes';
import { notificationService } from '../../services/notificationService';
import { CustomAppParam } from './views/EditCustomApp/EditCustomApp';
import { CredentialType } from '../Credentials/credentialsFormUtils';
import { credentialsService } from '../../../administration/credentials/credentials.service';
import { CredentialItemResponse, CredentialTypes } from '../Credentials/types';

const apisResponsesCache: Record<string, any> = {};

const fetchAndValidateUniqItemsFromPath = ({ pathToArray, pathToValue, api }: FetchItems): string[] => {
  const itemsFromPath: string[] = (get(apisResponsesCache[api], pathToArray) || [])
    .map((obj: any) => (pathToValue ? get(obj, pathToValue) : obj))
    .filter((val: any) => (isString(val) && !isEmpty(val)) || isNumber(val))
    .map((val: any) => val.toString());

  return uniq(itemsFromPath);
};

const fetchItemsFromApi = async (fetchItems: FetchItems): Promise<string[]> => {
  const { api } = fetchItems;
  try {
    if (isEmpty(apisResponsesCache[api])) {
      const { data } = await httpService.fetch(api.replace('api/v1/', ''));
      apisResponsesCache[api] = data;
    }
    return fetchAndValidateUniqItemsFromPath(fetchItems);
  } catch (err) {
    notificationService.error(`Problem fetching items from: ${api},
      Please change values in manifest
    `);
    return [];
  }
};

interface CredentialItem {
  credential_id: string;
  scanner_group?: string;
}

const fetchRelevantCredentialsItems = async (
  subtype: CredentialType,
  isRemoteApp: boolean,
  scannerGroup: string,
): Promise<string[]> => {
  const { data: credentialsData } = await credentialsService.getCredentials({
    $or: [
      { subType: subtype },
      { subType: { $exists: false }, type: { $in: [CredentialTypes.CyberArk, CredentialTypes.RemoteCyberArk] } },
    ],
    ...(!isRemoteApp && { isScannerOnly: { $not: { $eq: true } } }),
  });

  const typedCredentialsData: CredentialItem[] = credentialsData as CredentialItem[];

  return typedCredentialsData.reduce<string[]>((accumulator: string[], item: CredentialItem) => {
    if (!scannerGroup || !item.scanner_group || scannerGroup === item.scanner_group) {
      accumulator.push(item.credential_id);
    }
    return accumulator;
  }, [] as string[]);
};

export const populateParamsIfFetchItemsExists = async (
  params: CustomAppParam[],
  isRemoteApp: boolean,
  scannerGroup: string,
): Promise<CustomAppParam[]> => {
  for (const param of params) {
    if (param.fetchItems) {
      param.inputItems = await fetchItemsFromApi(param.fetchItems);
    }
    if (param.type === ParamTypes.CREDENTIAL) {
      param.inputItems = await fetchRelevantCredentialsItems(param.subType, isRemoteApp, scannerGroup);
    }
  }
  return params;
};

export const resetFetchItemsCache = (): void =>
  Object.keys(apisResponsesCache).forEach(api => {
    delete apisResponsesCache[api];
  });
