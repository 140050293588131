import { SetScanWizardState } from '../createScanContext';
import { queryService } from '../../../../services/queryService';
import { getScanProfiles } from '../../ScanProfiles/scanProfileService';
import { notificationService } from '../../../../services/notificationService';
import { useEffect } from 'react';
import { SchedulerSettingsWithCronExpression } from '@bigid-ui/components';
import { getCurrentUser } from '../../../../utilities/systemUsersUtils';
import { isBoolean, isEmpty } from 'lodash';

export const useGetScanToEdit = (setScanWizardState: SetScanWizardState, scanId: string) => {
  useEffect(() => {
    const getScanToEditAndUpdateState = async () => {
      try {
        const gridConfigQuery = queryService.getGridConfigQuery({
          skip: 0,
          limit: 1,
          filter: [{ field: '_id', value: scanId, operator: 'equal' }],
        });
        const { scanProfiles } = await getScanProfiles(gridConfigQuery);
        const scheduledScanToEdit = scanProfiles[0];

        if (!scheduledScanToEdit) {
          notificationService.error('Failed to fetch scan');
          return;
        }
        setScanWizardState(state => ({
          ...state,
          templateIds: [scheduledScanToEdit.scanTemplateId],
          dataSourcesIds: scheduledScanToEdit.dataSourceList,
          name: scheduledScanToEdit.name,
          owners: scheduledScanToEdit.owners,
          description: scheduledScanToEdit.description,
          schedule: scheduledScanToEdit.schedule as SchedulerSettingsWithCronExpression,
          allEnabledDs: scheduledScanToEdit.allEnabledDs,
          scanId,
          enabled: !isEmpty(scheduledScanToEdit.schedule),
          ...(isBoolean(scheduledScanToEdit.active) ? { active: scheduledScanToEdit.active } : {}),
        }));
      } catch (e) {
        notificationService.error('Could not fetch scan. See logs for more information');
        console.error(e);
      } finally {
        setScanWizardState(state => ({ ...state, isLoading: false }));
      }
    };

    const getNewWizardState = async () => {
      const { name } = await getCurrentUser();
      setScanWizardState(state => ({
        ...state,
        owners: [name],
        isLoading: false,
      }));
    };

    if (scanId) {
      getScanToEditAndUpdateState();
    } else {
      getNewWizardState();
    }
  }, [setScanWizardState, scanId]);
};
