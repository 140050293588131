import React from 'react';
import { EntitySection } from './EntitySection/EntitySection';
import { noop, take } from 'lodash';
import { RecentlyViewedListItem } from './EntitySection/ListItems/RecentlyViewedListItem';
import { generateDataAid } from '@bigid-ui/utils';
import { RecentlyViewedCatalogItem } from '../../../DataCatalog/DataCatalogService';

type RecentlyViewedItemsPopperProps = {
  handleChildClick: () => void;
  recentlyViewedItems: RecentlyViewedCatalogItem[];
};
export const RecentlyViewedItemsPopper = ({
  recentlyViewedItems = [],
  handleChildClick,
}: RecentlyViewedItemsPopperProps) => {
  if (!recentlyViewedItems.length) return null;

  const list = take(recentlyViewedItems, 3).map(
    ({ dataSourceName, dataSourceType, fullyQualifiedName, objectName }, index) => {
      return (
        <RecentlyViewedListItem
          key={fullyQualifiedName}
          dsName={dataSourceName}
          dsType={dataSourceType}
          fullyQualifiedName={fullyQualifiedName}
          objectName={objectName}
          dataAid={generateDataAid('RecentlyViewedListItem', [index])}
          onListItemClick={handleChildClick}
        />
      );
    },
  );

  return <EntitySection listItems={list} handleShowAllClick={noop} sectionName="recentlyViewed" />;
};
