import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  BigidFieldsetRenderProps,
  BigidForm,
  BigidFormField,
  BigidFormFieldDropDown,
  BigidFormFieldText,
  BigidFormProps,
  BigidFormStateAndHandlers,
  BigidFormValues,
  BigidLoader,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import { httpService } from '../../services/httpService';
import { OpenServiceTicketMetadata } from '../ActionCenter/ActionWorkflow/actionWorkflowTypes';
import { PrivacyRiskCaseResponse, ServiceTicketingType, SourceType, STORED_SERVICE_NAME } from './RiskRegisterService';
import {
  generateJiraConfigurationFormFields,
  generateTicketConfigurationFormFields,
} from '../ActionableInsights/ActionableInsightsGridViews/CaseActionsModal/caseActionsService';
import { Configuration } from '../ActionCenter/ConfigurationManagement/configurationManagementTypes';
import { TicketServiceSelector } from './TicketServiceSelector';
import { getFixedT, useLocalTranslation } from './translations';
import { notificationService } from '../../services/notificationService';

const MainContainer = styled('div')({
  paddingBottom: 16,
  width: '100%',
  minHeight: '480px',
  '& legend:empty': {
    display: 'none',
  },
});

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const ContainerItem = styled('div')({
  width: '49%',
});

export enum JiraTicketFields {
  DUE_DATE = 'duedate',
  PRIORITY = 'priority',
}

export interface CreateRiskTicketFormProps {
  configurations: Configuration[];
  currentConfiguration: Configuration;
  riskCaseData: PrivacyRiskCaseResponse;
  formControls: React.MutableRefObject<BigidFormStateAndHandlers>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateRiskTicketForm: FC<CreateRiskTicketFormProps> = ({
  configurations,
  currentConfiguration,
  riskCaseData,
  formControls,
  isLoading,
  setIsLoading,
}) => {
  const [ticketMetadata, setTicketMetadata] = useState<OpenServiceTicketMetadata>();
  const issueIdRef = useRef('');
  const [formFields, setFormFields] = useState<BigidFormField[]>([]);
  const [selectedConfiguration, setSelectedConfiguration] = useState<Configuration>(currentConfiguration);

  const { t } = useLocalTranslation('CreateRiskTicket');
  const notificationMessages = getFixedT('Notifications');

  const SERVICE_NOW_TOOLTIP_TEXT = t('Labels.serviceNowTooltipText');

  const fetchConfigurationDataById = useCallback(async () => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await httpService.fetch<{ data: OpenServiceTicketMetadata }>(
        `action-center/configurations/ticket/metadata/${selectedConfiguration.id}`,
      );
      setTicketMetadata(data);
      if (selectedConfiguration.type === ServiceTicketingType.SERVICE_NOW) {
        setFormFields(generateTicketConfigurationFormFields(data));
      } else {
        setFormFields(generateJiraConfigurationFormFields(data, selectedConfiguration.id as string));
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      localStorage.removeItem(STORED_SERVICE_NAME);
      notificationService.error(notificationMessages('errorTicketNoFields'));
    }
  }, [selectedConfiguration]);

  useEffect(() => {
    fetchConfigurationDataById();
  }, [selectedConfiguration]);

  const generateTicketDescription = (): string => {
    const ticketDesc = [];
    riskCaseData?.privacyRisk?.name && ticketDesc.push(`${t('Labels.riskName')}: ${riskCaseData?.privacyRisk?.name}\n`);
    riskCaseData?.asset && ticketDesc.push(`${t('Labels.asset')}: ${riskCaseData?.asset?.name}\n`);
    riskCaseData?.privacyRisk?.description &&
      ticketDesc.push(`${t('Labels.riskDescription')}: ${riskCaseData?.privacyRisk?.description}\n`);
    riskCaseData?.source?.name &&
      ticketDesc.push(
        `${t('Labels.flaggedIn')} ${
          riskCaseData?.sourceType === SourceType.PIA ? t('Labels.assessment') : t('Labels.businessProcess')
        }: ${riskCaseData?.source?.name}\n`,
      );
    ticketDesc.push(`${t('Labels.case')}: ${window.location.href}${riskCaseData?.caseId}`);
    return ticketDesc.join('');
  };

  const jiraDefaultFormValues = {
    dueDate: '',
    description: generateTicketDescription(),
    summary: riskCaseData?.caseLabel,
  };

  const serviceNowDefaultFormValues = {
    description: generateTicketDescription(),
    short_description: riskCaseData?.caseLabel,
    state: [{ value: '0', displayValue: 'New', id: '0' }],
    priority: [{ value: '2', displayValue: '2 - High', id: '2' }],
  };

  const updateSelectedJiraProject = (projectName: string) => {
    if (projectName && projectName !== selectedConfiguration.name) {
      setIsLoading(true);
      const serviceSelected = configurations.find(config => config?.name === projectName);
      setSelectedConfiguration(serviceSelected);
      localStorage.setItem(STORED_SERVICE_NAME, JSON.stringify(serviceSelected));
    }
  };

  const onFormChange = (values: BigidFormValues) => {
    if (values?.issueType?.length) {
      const { id: selectedIssueId } = values.issueType[0];
      if (issueIdRef.current !== selectedIssueId) {
        const issueTypeFields = ticketMetadata.fieldsForIssueTypeMap[selectedIssueId];
        setFormFields(
          generateJiraConfigurationFormFields(ticketMetadata, selectedConfiguration.id as string, {
            dueDate: issueTypeFields.some(config => config.name === JiraTicketFields.DUE_DATE),
            priority: issueTypeFields.some(config => config.name === JiraTicketFields.PRIORITY),
          }),
        );
        issueIdRef.current = selectedIssueId;
      }
    }
  };

  const formProps: BigidFormProps = {
    fields: formFields,
    isFieldsBySelection: false,
    controlButtons: false,
    stateAndHandlersRef: formControls,
    onChange: onFormChange,
    initialValues:
      selectedConfiguration.type === ServiceTicketingType.SERVICE_NOW
        ? serviceNowDefaultFormValues
        : jiraDefaultFormValues,
    ...(selectedConfiguration.type === ServiceTicketingType.SERVICE_NOW && {
      fieldsets: [
        {
          name: 'Selection',
          fields: ['opened_by', 'short_description', 'issue_type', 'state', 'assigned_to', 'priority'],
          label: '',
          renderFieldset: ({ getFieldProps }: BigidFieldsetRenderProps) => {
            const openedByFieldProps = getFieldProps('opened_by');
            const shortDescFieldProps = getFieldProps('short_description');
            const issueFieldProps = getFieldProps('issue_type');
            const stateFieldProps = getFieldProps('state');
            const assignedFieldProps = getFieldProps('assigned_to');
            const priorityFieldProps = getFieldProps('priority');

            return (
              <div>
                <BigidFormFieldDropDown
                  value={openedByFieldProps.value}
                  setValue={openedByFieldProps.setValue}
                  dropDownOptions={openedByFieldProps.dropDownOptions}
                  isRequired={openedByFieldProps.isRequired}
                  fieldProps={{
                    isSearchable: true,
                  }}
                  errorIsShown={openedByFieldProps.errorIsShown}
                  error={openedByFieldProps.error}
                  placeholder={t('Labels.selectUserPlaceholder')}
                  label={t('Labels.openedBy')}
                />
                <BigidFormFieldText
                  value={shortDescFieldProps.value}
                  setValue={shortDescFieldProps.setValue}
                  label={t('Labels.nameField')}
                  isRequired={shortDescFieldProps.isRequired}
                  errorIsShown={shortDescFieldProps.errorIsShown}
                  error={shortDescFieldProps.error}
                  placeholder={t('Labels.nameFieldPlaceholder')}
                />
                <Container>
                  <ContainerItem>
                    <BigidFormFieldDropDown
                      value={issueFieldProps.value}
                      setValue={issueFieldProps.setValue}
                      dropDownOptions={issueFieldProps.dropDownOptions}
                      isRequired={issueFieldProps.isRequired}
                      fieldProps={{
                        isSearchable: true,
                      }}
                      errorIsShown={issueFieldProps.errorIsShown}
                      error={issueFieldProps.error}
                      placeholder={t('Labels.issueTypeFieldPlaceholder')}
                      label={t('Labels.issueTypeField')}
                    />
                  </ContainerItem>
                  <ContainerItem>
                    <BigidFormFieldDropDown
                      value={stateFieldProps.value}
                      setValue={stateFieldProps.setValue}
                      dropDownOptions={stateFieldProps.dropDownOptions}
                      isRequired={stateFieldProps.isRequired}
                      fieldProps={{
                        isSearchable: true,
                      }}
                      errorIsShown={stateFieldProps.errorIsShown}
                      error={stateFieldProps.error}
                      placeholder={t('Labels.stateFieldPlaceholder')}
                      isValidated={false}
                      label={t('Labels.stateField')}
                    />
                  </ContainerItem>
                </Container>
                <Container>
                  <ContainerItem>
                    <BigidFormFieldDropDown
                      value={assignedFieldProps.value}
                      setValue={assignedFieldProps.setValue}
                      dropDownOptions={assignedFieldProps.dropDownOptions}
                      fieldProps={{
                        isSearchable: true,
                      }}
                      isRequired={assignedFieldProps.isRequired}
                      errorIsShown={assignedFieldProps.errorIsShown}
                      error={assignedFieldProps.error}
                      placeholder={t('Labels.triageOwnerFieldPlaceholder')}
                      label={t('Labels.triageOwnerField')}
                    />
                  </ContainerItem>
                  <ContainerItem>
                    <BigidFormFieldDropDown
                      value={priorityFieldProps.value}
                      setValue={priorityFieldProps.setValue}
                      dropDownOptions={priorityFieldProps.dropDownOptions}
                      fieldProps={{
                        isSearchable: true,
                      }}
                      isRequired={priorityFieldProps.isRequired}
                      errorIsShown={priorityFieldProps.errorIsShown}
                      error={priorityFieldProps.error}
                      placeholder={t('Labels.priorityFieldPlaceholder')}
                      label={t('Labels.priorityField')}
                    />
                  </ContainerItem>
                </Container>
              </div>
            );
          },
        },
        {
          name: 'Section',
          fields: ['description'],
          label: '',
        },
      ],
    }),
  };

  return (
    <MainContainer>
      {isLoading && <BigidLoader />}
      {!isLoading && (
        <>
          <TicketServiceSelector
            configurations={configurations}
            selectedConfiguration={selectedConfiguration}
            updateSelectedJiraProject={updateSelectedJiraProject}
            tooltipText={selectedConfiguration.type === ServiceTicketingType.SERVICE_NOW && SERVICE_NOW_TOOLTIP_TEXT}
          />
          <BigidForm {...formProps} />
        </>
      )}
    </MainContainer>
  );
};
