import React, { useRef, useState } from 'react';
import { BigidBody1, BigidEntity, BigidStateProgressBar } from '@bigid-ui/components';
import { useTheme } from '@mui/styles';
import { WidgetContainer, WidgetTitleContainer, WidgetFooterContainer, ProgressBarWrapper } from '../WidgetsStyles';
import { CarouselDataProps } from '../../../../../components/Carousel/Carousel';
import { iconsSchema } from '../../../../../views/Frameworks/utils';
import { CarouselItemMenu } from './CarouselItemMenu';

export const CarouselItem = ({ frameworkName, failedControlsCount, totalControls }: CarouselDataProps) => {
  const [isOnHover, setIsOnHover] = useState<boolean>(false);
  const Theme = useTheme();
  const ref = useRef(null);

  const handleHoverEffect = (value: boolean) => {
    if (value) {
      ref.current.style.backgroundColor = 'rgba(34, 34, 34, 0.06)';
      setIsOnHover(true);
    } else {
      ref.current.style.backgroundColor = 'transparent';
      setIsOnHover(false);
    }
  };

  return (
    <WidgetContainer
      ref={ref}
      onMouseEnter={() => handleHoverEffect(true)}
      onMouseLeave={() => handleHoverEffect(false)}
    >
      <WidgetTitleContainer>
        <BigidEntity icon={iconsSchema[frameworkName]} name={frameworkName} isNameBold={false} />
        {isOnHover && <CarouselItemMenu handleHoverEffect={handleHoverEffect} />}
      </WidgetTitleContainer>
      <WidgetFooterContainer>
        <ProgressBarWrapper>
          <BigidStateProgressBar
            percentage={(failedControlsCount / totalControls) * 100}
            size="small"
            width="100%"
            barColor={Theme.palette.bigid.red300}
            barBackgroundColor={Theme.palette.bigid.gray200}
          />
        </ProgressBarWrapper>
        <BigidBody1
          color={Theme.palette.bigid.gray500}
        >{`${failedControlsCount} of ${totalControls} controls`}</BigidBody1>
      </WidgetFooterContainer>
    </WidgetContainer>
  );
};
