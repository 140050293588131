import React, { FC, PropsWithChildren } from 'react';
import { styled } from '@mui/material';

export interface PieChartWidgetWrapperProps extends PropsWithChildren {
  dataAid?: string;
}

const Root = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 16px 24px 24px;
`;

export const PieChartWidgetWrapper: FC<PieChartWidgetWrapperProps> = ({
  dataAid = 'PieChartWidgetWrapper',
  children,
}) => {
  return <Root data-aid={dataAid}>{children}</Root>;
};
