import React, { FC, useMemo } from 'react';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridQueryComponents, BigidGridWithToolbar } from '@bigid-ui/grid';
import { BigidBody1, BigidEntity, BigidSeverityBadge } from '@bigid-ui/components';
import { styled } from '@mui/material';
import {
  ActivityLogActionTypes,
  ActivityLogData,
  caseStatusCodeToStatusMap,
  getCaseActivityLogData,
  getRiskStatusBadge,
} from './RiskRegisterService';
import { dateUtils } from '../../services/angularServices';
import { getFixedT, useLocalTranslation } from './translations';
import { capitalizeFirstLetter } from '../../utilities/textualConverter';
import { notificationService } from '../../services/notificationService';

const ActivityLogContainer = styled('div')(({ theme }) => ({
  borderBottom: theme.vars.tokens.bigid.borderDefault,
  borderLeft: theme.vars.tokens.bigid.borderDefault,
  borderRight: theme.vars.tokens.bigid.borderDefault,
}));

const Link = styled(BigidBody1)(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-block',
  alignItems: 'center',
  justifyContent: 'space-between',
  textDecoration: 'undefined',
  color: theme.vars.tokens.bigid.textLink,
  paddingTop: '6px',
}));

export interface RiskSidePanelActivityLogProps {
  caseId: string;
}

export const RiskSidePanelActivityLog: FC<RiskSidePanelActivityLogProps> = ({ caseId }) => {
  const { t } = useLocalTranslation('ActivityLog');
  const notificationMessages = getFixedT('Notifications');

  const mapActionToText = (action: string) => {
    switch (action) {
      case ActivityLogActionTypes.STATUS_CHANGED:
        return t('Actions.statusChanged');
      case ActivityLogActionTypes.TICKET_CREATED:
        return t('Actions.ticketCreated');
      case ActivityLogActionTypes.SEVERITY_CHANGED:
        return t('Actions.severityChanged');
      case ActivityLogActionTypes.ASSIGNEE_UPDATED:
        return t('Actions.assigneeUpdated');
      case ActivityLogActionTypes.ASSIGNEE_ATTACHED:
        return t('Actions.assigneeAttached');
      case ActivityLogActionTypes.NOTE_UPDATED:
        return t('Actions.noteUpdated');
      case ActivityLogActionTypes.NOTE_ATTACHED:
        return t('Actions.noteAttached');
      case ActivityLogActionTypes.SEVERITY_ATTACHED:
        return t('Actions.severityAttached');
      case ActivityLogActionTypes.CASE_CREATED:
        return t('Actions.caseCreated');
      case ActivityLogActionTypes.CASE_DELETED:
        return t('Actions.caseDeleted');
      case ActivityLogActionTypes.ASSET_ATTACHED:
        return t('Actions.assetAttached');
      case ActivityLogActionTypes.ASSET_CHANGED:
        return t('Actions.assetChanged');
    }
  };

  const getCustomCellValue = (action: string, value: any) => {
    switch (action) {
      case ActivityLogActionTypes.STATUS_CHANGED: {
        const status = caseStatusCodeToStatusMap.get(value);
        return capitalizeFirstLetter(status);
      }
      case ActivityLogActionTypes.TICKET_CREATED:
        return (
          <BigidBody1>
            <Link data-aid="vendor-page" onClick={() => window.open(value?.url, '_blank')}>
              {`${value?.ticketNumber}`}
            </Link>
          </BigidBody1>
        );
      case ActivityLogActionTypes.SEVERITY_CHANGED:
      case ActivityLogActionTypes.SEVERITY_ATTACHED:
        return value?.riskLevel?.name ? (
          <BigidSeverityBadge
            customLabel={value?.riskLevel?.name}
            customColor={value.riskLevel?.color}
            level={'custom'}
            hasBackground={false}
            size="small"
          />
        ) : undefined;
      case ActivityLogActionTypes.ASSIGNEE_UPDATED:
      case ActivityLogActionTypes.ASSIGNEE_ATTACHED:
        return value?.name;
      case ActivityLogActionTypes.CASE_CREATED:
      case ActivityLogActionTypes.NOTE_UPDATED:
      case ActivityLogActionTypes.NOTE_ATTACHED:
        return value;
      case ActivityLogActionTypes.ASSET_ATTACHED:
      case ActivityLogActionTypes.ASSET_CHANGED:
        return value?.name;
    }
  };

  const columns = useMemo<BigidGridColumn<any>[]>(
    () => [
      {
        name: 'date',
        title: t('Columns.date'),
        getCellValue: row => dateUtils.formatDate(row?.createdAt, { formatString: 'MMM do, yyyy, HH:mm' }),
        type: BigidGridColumnTypes.TEXT,
        width: 200,
        sortingEnabled: false,
      },
      {
        name: 'action',
        title: t('Columns.action'),
        getCellValue: row => mapActionToText(row.action),
        type: BigidGridColumnTypes.TEXT,
        width: 150,
        sortingEnabled: false,
      },
      {
        name: 'value',
        title: t('Columns.value'),
        getCellValue: row => getCustomCellValue(row.action, row.value),
        type: BigidGridColumnTypes.CUSTOM,
        width: 200,
        sortingEnabled: false,
      },
      {
        name: 'status',
        title: t('Columns.status'),
        getCellValue: row =>
          row.action !== ActivityLogActionTypes.STATUS_CHANGED
            ? getRiskStatusBadge({ id: row.status as string, name: '' })
            : '',
        type: BigidGridColumnTypes.CUSTOM,
        width: 150,
        sortingEnabled: false,
      },
      {
        name: 'user',
        title: t('Columns.user'),
        getCellValue: row => (
          <BigidEntity
            size={'xsmall'}
            alignment={'center'}
            orientation={'horizontal'}
            name={row?.updatedBy?.name}
            isNameBold={false}
          />
        ),
        type: BigidGridColumnTypes.CUSTOM,
        width: 200,
        sortingEnabled: false,
      },
      {
        name: 'reason',
        title: t('Columns.reason'),
        getCellValue: row => row.reason,
        type: BigidGridColumnTypes.TEXT,
        width: 140,
        sortingEnabled: false,
      },
    ],
    [],
  );

  const fetchData = async (queryComponents: BigidGridQueryComponents) => {
    try {
      const response: ActivityLogData = await getCaseActivityLogData(caseId, queryComponents);
      return {
        data: response.activityLogs,
        totalCount: response.totalCount,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err);
      notificationService.error(notificationMessages('activityLogFetchFailed'));
      return {
        data: [],
        totalCount: 0,
      };
    }
  };

  const gridConfig = {
    columns,
    pagingMode: true,
    pageSize: 5,
    hideColumnChooser: true,
    displayActionToolbar: false,
    fetchData: (queryComponents: BigidGridQueryComponents) => fetchData(queryComponents),
  };

  return (
    <ActivityLogContainer>
      <BigidGridWithToolbar {...gridConfig} />
    </ActivityLogContainer>
  );
};
