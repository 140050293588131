import { BigidFilterToolbarProps } from '@bigid-ui/components';
import { isOnboardingAssistantEnabled as checkOnboardingAssistantEnabled } from '../../../../utilities/featureFlagUtils';
import { onboardingLayoutPreferenceKey } from '../OnboardingAssistant/constants/gridConstants';
import { $state } from '../../../../services/angularServices';

export type GridOverride = {
  enabled: boolean;
  execute: (config: BigidFilterToolbarProps) => BigidFilterToolbarProps;
};

export const getGridWithToolbarFilterOverrides = (
  overrides: GridOverride[],
  config: BigidFilterToolbarProps,
): BigidFilterToolbarProps => {
  return overrides.reduce((acc, { execute, enabled }) => {
    return enabled ? execute(acc as BigidFilterToolbarProps) : { ...acc };
  }, config);
};

export const getDataSourceGridId = () => {
  const isOnboardingAssistantFlagEnabled = checkOnboardingAssistantEnabled();
  return isOnboardingAssistantFlagEnabled ? onboardingLayoutPreferenceKey : $state.$current.name;
};
