import { loginService } from '../../authentication/login/login.service';
import { DateISO8601 } from '../types/types';
import { httpService } from './httpService';
import { SystemEvents, systemEventsEmitter } from './systemEvents';

export type ScannerType = string;

export type ScannerTypeSupportedFeaturesMapped = Pick<
  ScannerTypeSupportedFeatures,
  'supported_features' | 'unsupported_features'
>;

type ScannerTypeSupportedFeatures = {
  scanner_type: ScannerType;
  supported_features: ScannerTypeFeature[];
  unsupported_features: ScannerTypeFeature[];
  updated_at: DateISO8601;
};

type FetchScannerTypesSupportedFeaturesResponse = {
  data: {
    items: ScannerTypeSupportedFeatures[];
  };
};

export enum ScannerTypeFeature {
  HAS_STRUCTURE = 'HasStructure',
  HAS_FIELD_AFFINITY = 'HasFieldAffinity',
  HAS_SPLIT_PARTS = 'HasSplitParts',
  HAS_DSAR = 'HasDsar',
  HAS_DSAR_CUSTOM_ATTRIBUTE_MAPPING = 'HasDsarCustomAttributeMapping',
  HAS_DSAR_XML_FIELD_PARSER = 'HasDsarXmlFieldParser',
  HAS_PERIODIC_CORRELATIONS = 'HasPeriodicCorrelations',
  HAS_RANGED_PII_SUMMARY = 'HasRangedPiiSummary',
  HAS_DSAR_CASE_INSENSITIVE = 'HasDsarCaseInSensitive',
  HAS_BATCH_DSAR = 'HasBatchDsar',
  HAS_INFINITE_SCAN = 'HasInfiniteScan',
  HAS_NATIVE_SEARCH = 'HasNativeSearch',
  HAS_DSAR_REFERENTIAL_INTEGRITY = 'HasDsarReferentialIntegrity',
  HAS_DSAR_BY_ARRAY_ITEMS = 'HasDsarByArrayItems',
  HAS_DSAR_EXACT_MATCH = 'HasDsarExactMatch',
  HAS_MAIL_STRUCTURE = 'HasMailStructure',
  HAS_HYBRID_STRUCTURE = 'HasHybridStructure',
  HAS_DELETION = 'HasDeletion',
  HAS_PREDEFINED_DSAR_ATTRIBUTES_SEARCH = 'HasPredefinedDsarAttributesSearch',
  HAS_SORTABLE_ITERATOR = 'HasSortableIterator',
  HAS_DOWNLOAD_FILES_OPERATION = 'HasDownloadFilesOperation',
  HAS_UPLOAD_FILES_OPERATION = 'HasUploadFilesOperation',
  HAS_PARTITIONED_TABLES = 'HasPartitionedTables',
  HAS_STRUCTURED_FILES = 'HasStructuredFiles',
  HAS_ASSEMBLE_OPERATION = 'HasAssembleOperation',
  HAS_VOLUME = 'HasVolume',
  HAS_PREVIEW = 'HasPreview',
  HAS_SCHEMA = 'HasSchema',
}

const scannerTypeSupportedFeaturesMap: Map<ScannerType, ScannerTypeSupportedFeaturesMapped> = new Map();

export const initScannerTypesSupportedFeatures = async () => {
  try {
    if (scannerTypeSupportedFeaturesMap.size === 0 && loginService.isLoggedIn()) {
      const { data } = await httpService.fetch<FetchScannerTypesSupportedFeaturesResponse>(
        'data-sources-scan-features',
      );

      data.data.items.forEach(({ scanner_type, supported_features = [], unsupported_features = [] }) => {
        if (scanner_type) {
          scannerTypeSupportedFeaturesMap.set(scanner_type, { supported_features, unsupported_features });
        }
      });
    }
  } catch ({ message }) {
    console.error(`An error has occurred while fetching scanner types supported features: ${message}`);
  }
};

export const getIsScannerTypeFeatureSupported = (scannerType: ScannerType, feature: ScannerTypeFeature): boolean => {
  try {
    return !scannerTypeSupportedFeaturesMap.get(scannerType).unsupported_features.includes(feature);
  } catch {
    return false;
  }
};

export const isScannerTypeSupportFeature = (scannerType: ScannerType, feature: ScannerTypeFeature): boolean => {
  return !!scannerTypeSupportedFeaturesMap.get(scannerType)?.supported_features?.includes(feature);
};

export const getAllScannerTypesSupportedFeatures = (): Map<ScannerType, ScannerTypeSupportedFeaturesMapped> => {
  return scannerTypeSupportedFeaturesMap;
};

export const getAllSupportedFeaturesByScannerType = (scannerType: ScannerType): ScannerTypeSupportedFeaturesMapped => {
  return scannerTypeSupportedFeaturesMap.get(scannerType);
};

const initScannerTypesSupportedFeaturesListener = systemEventsEmitter.addEventListener(
  SystemEvents.LOGIN,
  initScannerTypesSupportedFeatures,
);

const clearScannerTypesSupportedFeaturesListener = systemEventsEmitter.addEventListener(SystemEvents.LOGOUT, () => {
  scannerTypeSupportedFeaturesMap.clear();
});

window.addEventListener('unload', () => {
  initScannerTypesSupportedFeaturesListener();
  clearScannerTypesSupportedFeaturesListener();
});
