import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidHeading3 } from '@bigid-ui/components';
import { BigidScanIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../translations';

const DiscoveryModalContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px 16px 16px;
`;

const ContentItemWrapper = styled('div')`
  display: flex;
`;

const HeaderItemWrapper = styled('div')`
  padding: 16px 0;
`;

export const DiscoveryInProgressModalContent: FC = () => {
  const { t } = useLocalTranslation('security.modals.discoveryInProgressModal');

  return (
    <DiscoveryModalContainer>
      <ContentItemWrapper>
        <BigidScanIllustration />
      </ContentItemWrapper>
      <HeaderItemWrapper>
        <BigidHeading3>{t('header')}</BigidHeading3>
      </HeaderItemWrapper>
      <ContentItemWrapper>
        <BigidBody1 textAlign="center">{t('body')}</BigidBody1>
      </ContentItemWrapper>
    </DiscoveryModalContainer>
  );
};
