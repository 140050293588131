import React, { FC, Fragment, ReactText, useEffect } from 'react';
import { BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, LayoutContentType } from '@bigid-ui/layout';
import { httpService } from '../../../services/httpService';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { $state } from '../../../services/angularServices';
import { isPermitted } from '../../../services/userPermissionsService';
import { DATA_MINIMIZATION_PERMISSIONS } from '@bigid/permissions';
import { FilterParams, showDeletionConfirmationDialog } from '../DataMinimizationObjects/DataMinimizationObjects';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { dataMinimizationDataSourcesDefaultColumns } from './DataMinimizationDataSourcesGridConfiguration';
import { ActionData, BigidFilter, BigidLoader } from '@bigid-ui/components';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { getDataMinimizationInsights, InsightsType } from '../DataMinimizationInsights/DataMinimizationInsights';
import { DataSourceModel } from '../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { cancelRequests } from '../DataMinimizationObjects/DataMinimizationObjectsService';
import { analyticsService } from '../../../services/analyticsService';
import { events } from '../DataMinimizationDSV2/constants';

export interface DataSourceDeletionModel extends DataSourceModel {
  states_str?: string;
}

export interface DeletionDataSourceResponse {
  dataMinimizationDataSources: DataSourceDeletionModel[];
  totalCount: number;
}

export const DataMinimizationDataSources: FC = () => {
  const { isReady, preferences, gridColumns } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: dataMinimizationDataSourcesDefaultColumns,
  });

  useEffect(() => {
    analyticsService.trackManualEvent(events.DATA_DELETION_DATA_SOURCES_TAB_OPEN);
  }, []);

  // check if the column exists for sorting from preferences. It may not exist if it's comming from the new filter
  const prefSorting =
    preferences?.grid?.sort?.filter(item =>
      dataMinimizationDataSourcesDefaultColumns.some(c => c.name === item.field),
    ) || [];

  const gridConfig: BigidGridProps<DataSourceDeletionModel> = {
    customRowIdName: 'name',
    columns: gridColumns,
    showSortingControls: true,
    showFilteringControls: false,
    defaultSorting: prefSorting?.length ? prefSorting : [{ field: 'updated_at', order: 'desc' }],
  };

  const getFilterForDeletion = ({ actionData, queryComponents }: FilterParams) => {
    const { filter: oldFilter = [], allSelected = false, selectedRowIds = [] } = { ...actionData, ...queryComponents };
    const filter: BigidFilter = buildDeletionFilter(oldFilter, allSelected, selectedRowIds);
    const gridConfigQuery = queryService.getGridConfigQuery({ ...queryComponents, filter });
    return { filter, gridConfigQuery };
  };

  const buildDeletionFilter = (filter: BigidFilter = [], allSelected = false, selectedRowIds: ReactText[] = []) => {
    const deletionFilter: BigidFilter = filter ? [...filter] : [];
    if (!allSelected && selectedRowIds?.length) {
      deletionFilter.push({ field: 'dataSourceName', operator: 'in', value: selectedRowIds });
    }
    return deletionFilter;
  };

  const fetchData: FetchDataFunction<DataSourceDeletionModel> = async queryComponents => {
    let data: DataSourceDeletionModel[] = [];
    let totalCount = 0;
    const progressCountEnabled = getApplicationPreference('DATA_MINIMIZATION_PROGRESS_COUNT_ENABLED');

    try {
      let { gridConfigQuery } = getFilterForDeletion({ queryComponents });
      if (progressCountEnabled) {
        gridConfigQuery = gridConfigQuery + '&requireObjectsStates=true';
      }
      const {
        data: {
          data: { dataMinimizationDataSources, totalCount: count },
        },
      } = await httpService.fetch<{ data: DeletionDataSourceResponse }>(`data-minimization/ds?${gridConfigQuery}`);

      data = dataMinimizationDataSources;
      totalCount = count;
    } catch (e) {
      console.error(e);
      notificationService.error('Could not fetch data. See logs for more information');
    }

    return {
      data,
      totalCount,
    };
  };

  const layoutConfig: BigidLayoutConfig = {
    filter: {
      search: { isQueryLanguage: false, getFreeSearchField: () => 'dataSourceName' },
    },
    content: {
      entityName: 'data sources',
      contentTypes: [LayoutContentType.GRID],
      defaultContentType: LayoutContentType.GRID,
      viewConfig: {
        fetchGridData: fetchData,
        gridConfig,
      },
      toolbarActions: [
        {
          label: 'Export Objects',
          execute: async (actionData: ActionData) => {
            try {
              const { gridConfigQuery } = getFilterForDeletion({ actionData });
              httpService.downloadFile(`data-minimization/objects/file-download/export?${gridConfigQuery}`);
            } catch (e) {
              console.error(e);
              notificationService.error('Could not export. See logs for more information');
            } finally {
              return Promise.resolve({ shouldGridReload: false });
            }
          },
          disable: ({ selectedRowIds }) => selectedRowIds.length === 0,
          show: () => isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXPORT.name),
        },
        {
          label: 'Execute Delete',
          execute: async (actionData: ActionData) => {
            let shouldExecute = false;
            try {
              const { filter: deletionFilter } = getFilterForDeletion({ actionData });
              const {
                data: {
                  data: { count: objectsCount },
                },
              } = await httpService.post(`data-minimization/objects/execute/count`, {
                query: { filter: deletionFilter, requireTotalCount: false },
              });

              shouldExecute = await showDeletionConfirmationDialog(objectsCount);
              if (shouldExecute) {
                await httpService.post(`data-minimization/objects/execute-async`, {
                  query: { filter: deletionFilter },
                });
                notificationService.success(`Execute requests confirmed`);
              }
            } catch (e) {
              console.error(e);
              notificationService.error('Could not execute. See logs for more information');
            } finally {
              return Promise.resolve({ shouldGridReload: shouldExecute, shouldClearSelection: shouldExecute });
            }
          },
          disable: ({ selectedRowIds }) => selectedRowIds.length === 0,
          show: () => isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXECUTE.name),
        },
        {
          label: 'Cancel Requests',
          execute: async (actionData: ActionData) => cancelRequests(actionData, getFilterForDeletion),
          disable: ({ selectedRowIds }) => selectedRowIds.length === 0,
          show: () =>
            isPermitted(DATA_MINIMIZATION_PERMISSIONS.CANCEL_REQUEST.name) &&
            getApplicationPreference('ENABLE_CANCEL_DATA_DELETION_REQUEST'),
        },
      ],
    },
    ...(getApplicationPreference('SHOW_DATA_DELETION_HIGHLIGHTS') && {
      insights: { component: getDataMinimizationInsights(InsightsType.DATA_SOURCES) },
    }),
  };

  return (
    <Fragment>
      {!isReady && <BigidLoader />}
      {isReady && <BigidLayout config={layoutConfig} />}
    </Fragment>
  );
};
