import React, { useEffect } from 'react';
import DataOverviewEmptyState from '../../../assets/icons/DataOverviewEmptyState.svg';
import { styled } from '@mui/material';
import { useLocalTranslation } from '../translations';
import { BigidHeading1, BigidHeading5 } from '@bigid-ui/components';
import { BigidCredentialsIllustration } from '@bigid-ui/icons';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { generateDataAid } from '@bigid-ui/utils';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const OverviewImageWrapper = styled('div')`
  height: 50vh;
  position: fixed;
  bottom: 0;
  overflow: hidden;
`;

const MessageContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const Content = styled('div')`
  display: flex;
  margin-top: 114px;
  margin-bottom: 94px;
  gap: 24px;
  flex-direction: column;
`;

export const CatalogDiscoveryNoPermission = () => {
  const { t } = useLocalTranslation('noPermissionsState');

  useEffect(() => {
    pageHeaderService.setIsHidden(true);
  }, []);

  return (
    <Root>
      <Content data-aid={generateDataAid('CatalogDiscoveryNoPermission', ['content'])}>
        <BigidHeading1>{t('title')}</BigidHeading1>
        <MessageContainer>
          <BigidCredentialsIllustration size="small" />
          <BigidHeading5 whiteSpace="break-spaces" textAlign="center">
            {t('body')}
          </BigidHeading5>
        </MessageContainer>
      </Content>

      <OverviewImageWrapper>
        <DataOverviewEmptyState />
      </OverviewImageWrapper>
    </Root>
  );
};
