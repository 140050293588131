import React, { FC } from 'react';
import { BigidWidgetContainer } from '@bigid-ui/components';
import { BigidNoDataIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';

export interface EmptyWidgetProps {
  title: string;
}

export const EmptyWidget: FC<EmptyWidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation();
  return (
    <BigidWidgetContainer
      content={<div></div>}
      titleConfig={{ title }}
      emptyStateConfig={{
        isEmpty: true,
        description: t('emptyStateWidget.noData'),
        illustration: BigidNoDataIllustration,
      }}
    />
  );
};
