import React, { useMemo } from 'react';
import { BigidBody1, BigidWidgetContainer } from '@bigid-ui/components';
import { BigidNoDataIllustration, DataSourceIconByDsType } from '@bigid-ui/icons';
import { orderBy } from 'lodash';
import { useLocalTranslation } from '../../../translations';
import { DescriptionWrapper } from '../WidgetsStyles';
import { BigidTreemapChart } from '@bigid-ui/visualisation';
import { MenuItems } from '../WidgetsContainer';

interface DataSourceWidgetProps {
  data: any[];
  dataAid: string;
}

export const DataSourceWidget = ({ data, dataAid }: DataSourceWidgetProps) => {
  const { t } = useLocalTranslation('compliance.widgets');
  const sortedDataByField = orderBy(data, 'failedControlsCount', 'desc').slice(0, 5);

  const WidgetList = useMemo(() => {
    return sortedDataByField.map(element => {
      return {
        id: element.dataSourceType,
        name: element.dataSourceType,
        value: element.failedControlsCount,
        icon: <DataSourceIconByDsType dataSourceType={element.dataSourceType} width={20} />,
      };
    });
  }, [sortedDataByField]);

  return (
    <BigidWidgetContainer
      dataAid={dataAid}
      titleConfig={{
        title: t('dataSource.title'),
      }}
      content={<BigidTreemapChart options={WidgetList} homeText="Data Sources" menuProps={{ items: MenuItems }} />}
      contentHeight="416px"
      emptyStateConfig={{
        isEmpty: data.length === 0,
        illustration: BigidNoDataIllustration,
        illustrationSize: 'small',
        description: (
          <DescriptionWrapper rowDirection="column">
            <BigidBody1>{t('emptyState.defaultTextTitle')}</BigidBody1>
            <BigidBody1>{t('emptyState.defaultTextDescription')}</BigidBody1>
          </DescriptionWrapper>
        ),
      }}
    />
  );
};
