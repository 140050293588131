import { BigidBody1, BigidLink } from '@bigid-ui/components';
import React from 'react';
import { useLocalTranslation } from '../../../../../translations';

type ShowMoreProps = {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  numOfExceededItems?: number;
};

export const ShowMore = ({ isExpanded, setIsExpanded, numOfExceededItems }: ShowMoreProps) => {
  const { t } = useLocalTranslation('sidePanel.overview.misc');
  return (
    <BigidBody1 alignSelf="flex-start" paddingLeft="5px">
      <BigidLink
        text={isExpanded ? t('showLess') : t('showMore', { count: numOfExceededItems })}
        onClick={() => setIsExpanded(!isExpanded)}
        underline="none"
      />
    </BigidBody1>
  );
};
