import React, { useMemo } from 'react';
import { useAutoDiscoverWizard } from '../../../AutoDiscovery/hooks/useAutoDiscoverWizard';
import { goToAutoDiscovery } from '../../../AutoDiscovery/utils';
import { openSelectDataSourceOnboarding } from '../../SelectDataSourceOnboardingType/selectDataSourceOnboardingTypeService';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { useLocalTranslation } from '../translations';
import { BigidAddIcon } from '@bigid-ui/icons';

export const useEmptyStateActions = (isAutoDiscoveryAvailable: boolean) => {
  const { openAutoDiscoveryModal } = useAutoDiscoverWizard();
  const { t } = useLocalTranslation();

  return useMemo(
    () => [
      {
        execute: async (): Promise<{ shouldGridReload: false; shouldClearSelection: false }> => {
          await goToAutoDiscovery(openAutoDiscoveryModal);
          return { shouldGridReload: false, shouldClearSelection: false };
        },
        label: 'Start Onboarding',
        show: () => isAutoDiscoveryAvailable && !getApplicationPreference('NEW_SELECT_DS_VIEW_ENABLED_FF'),
      },
      {
        execute: async () => {
          openSelectDataSourceOnboarding();
          return { shouldGridReload: false, shouldClearSelection: false };
        },
        label: t('Header.buttons.createDataSource'),
        show: () => true,
        icon: BigidAddIcon,
      },
    ],
    [isAutoDiscoveryAvailable, openAutoDiscoveryModal],
  );
};
