import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material';
import { BigidPieChartProps, BigidLoader, BigidTooltip, BigidBody1, PieChartSizeTypes } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { AggregationItemBase } from '../../catalogDiscoveryTypes';
import { BigidDonutChart, BigidDonutChartSliceData } from '@bigid-ui/visualisation';

export type PieChartWidgetSeries<ItemType = AggregationItemBase> = BigidDonutChartSliceData & {
  aggItem: ItemType;
  isAppliedFilter?: boolean;
};

export interface PieChartWidgetProps extends Pick<BigidPieChartProps, 'entityName'> {
  dataAid?: string;
  dataTourId?: string;
  height?: string;
  width?: string;
  data: BigidDonutChartSliceData[];
  tooltipText?: ReactNode;
  isBusy?: boolean;
  onLegendItemClick?: (item: BigidDonutChartSliceData) => void;
  onSectorClick?: (item: BigidDonutChartSliceData) => void;
  size?: PieChartSizeTypes;
}

const Root = styled('div')<Pick<PieChartWidgetProps, 'height' | 'width'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const TitleWrapper = styled(BigidBody1)`
  width: 140px;
  text-align: center;
`;

const Title = styled(BigidBody1)`
  font-weight: bold;
  margin-bottom: 16px;
  width: 100%;
`;

const Wrapper = styled('div')<
  {
    isTransparent?: boolean;
  } & Pick<PieChartWidgetProps, 'size'>
>(() => {
  return `
  display: flex;
  flex-direction: column;
  align-items: base-line;
  height: 100%;
  position: relative;
  `;
});

const ChartWrapper = styled('div')<{
  isTransparent?: boolean;
}>(({ isTransparent }) => {
  return `
    ${isTransparent && 'opacity: 0;'}
  `;
});

const spinnerSize = 42;
const spinnerThickness = 6;

export const PieChartWidget: FC<PieChartWidgetProps> = ({
  dataAid = 'PieChartsWidget',
  dataTourId = 'PieChartsWidget',
  height = '100%',
  width = '100%',
  data = [],
  entityName,
  onSectorClick,
  onLegendItemClick,
  tooltipText,
  isBusy,
  size = 'xl',
}) => {
  return (
    <Root data-aid={dataAid} data-tour-id={dataTourId} height={height} width={width}>
      <Wrapper isTransparent={isBusy} size={size} data-aid={generateDataAid(dataAid, ['chart-wrapper'])}>
        {isBusy ? (
          <BigidLoader
            data-aid={generateDataAid(dataAid, ['chart-loader'])}
            size={spinnerSize}
            thickness={spinnerThickness}
          />
        ) : (
          <>
            <TitleWrapper>
              <BigidTooltip title={tooltipText} isDisabled={!tooltipText}>
                <Title data-aid={generateDataAid(dataAid, ['entity-name'])}>{entityName}</Title>
              </BigidTooltip>
            </TitleWrapper>
            <ChartWrapper isTransparent={isBusy}>
              <BigidDonutChart
                data={data}
                legendProps={{
                  legendPosition: 'right',
                  onLegendClick: onLegendItemClick,
                  legendLabelFormatter: (dataItem: BigidDonutChartSliceData) => (
                    <BigidTooltip title={`${dataItem.category}: ${dataItem.value}`}>
                      <span data-aid={generateDataAid(dataAid, ['legend', dataItem.category])}>
                        {dataItem.category}
                      </span>
                    </BigidTooltip>
                  ),
                }}
                limit={6}
                showTooltip={true}
                showSumValue={true}
                onClick={onSectorClick}
                emptyStateProps={null}
                customTooltipTemplate={(category, value) => category + ': ' + value}
              />
            </ChartWrapper>
          </>
        )}
      </Wrapper>
    </Root>
  );
};
