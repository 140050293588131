import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidFormProps, BigidForm, BigidBody1 } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';

export interface EditViewDialogContentProps {
  newFilterDialogFormProps: BigidFormProps;
  isNewViewForced: boolean;
  currentFilterId?: string;
}

const DialogContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  & form {
    width: 100%;
  }
  & p {
    font-size: 0.875rem !important;
  }
  & .bigidFormFields {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  & fieldset {
    margin: 0;
  }
  & fieldset span,
  fieldset p {
    font-size: 0.875rem !important;
  }
`;

const BodyItemWrapper = styled('div')`
  padding-bottom: 8px;
`;

export const EditViewDialogContent: FC<EditViewDialogContentProps> = ({
  newFilterDialogFormProps,
  isNewViewForced,
  currentFilterId,
}) => {
  const { t } = useLocalTranslation();

  return (
    <DialogContainer>
      {!!currentFilterId && isNewViewForced && (
        <BodyItemWrapper>
          <BigidBody1 size="medium">{t('form.viewCreatedByAnotherUser')}</BigidBody1>
        </BodyItemWrapper>
      )}
      <BigidForm {...newFilterDialogFormProps} />
    </DialogContainer>
  );
};
