import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';

const DialogContainer = styled('div')`
  display: flex;
  flex-direction: 'column';
  align-items: 'center';
  & fieldset {
    margin: 0;
  }
`;

export const DeleteViewDialogContent: FC = () => {
  const { t } = useLocalTranslation();
  return (
    <DialogContainer>
      <BigidBody1>{t('bodyText.deleteViewMessage')}</BigidBody1>
    </DialogContainer>
  );
};
