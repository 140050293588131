import React, { useEffect, useState } from 'react';
import { BigidTabsAndContent, BigidTabsAndContentProps, BigidHorizontalBarItem } from '@bigid-ui/components';
import { $state, $transitions } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import makeStyles from '@mui/styles/makeStyles';
import { UIView } from '@uirouter/react';
import { ScansUITrackingEvent, trackEventScansView } from './ScansEventTrackerUtils';
import { Card, styled } from '@mui/material';
import { OPTIONS_FILTER } from './ScansDashboard/ScansDashboard';
import { PieChartSectionData } from './ScanInsights/ScanInsightTypes';
import { useLocalTranslation } from './translations';

const pageBottomMargin = 10;
const topSectionHeight = 17;

const ScansContainerContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100% - ${pageBottomMargin}px)`,
  width: '100%',
  overflow: 'hidden',
  boxShadow: theme.vars.tokens.bigid.shadow10,
  border: theme.vars.tokens.bigid.borderDefault,
}));

const ScansCardContent = styled(Card)({
  height: `calc(100% - ${topSectionHeight}px)`,
  boxShadow: 'none',
});

const useStyles = makeStyles({
  contentContainer: {
    overflow: 'hidden',
    display: 'none',
  },
  tabsContainer: {
    '& [class*="MuiTabs-root"]': {
      padding: '16px 0 0 24px',
    },
  },
});

const getScansContainerTabs = () => {
  return [CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS, CONFIG.states.SCANS_NEW_SCANS_COMPLETED];
};

interface IInitScansDashboardsData {
  horizontalBarData: BigidHorizontalBarItem[];
  pieChartData: PieChartSectionData[];
  horizontalBarTitle: string;
  isLoading: boolean;
}

const initScansDashboardsData: IInitScansDashboardsData = {
  horizontalBarData: [],
  pieChartData: [],
  horizontalBarTitle: '',
  isLoading: true,
};

export const ScansContainer: React.FC = () => {
  const scanTabs = getScansContainerTabs();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(scanTabs.indexOf($state.$current.name) || 0);
  const { contentContainer, tabsContainer } = useStyles({});
  const { t } = useLocalTranslation('scansContainer');

  useEffect(() => {
    const handleStateChange = (transition: any) => {
      if (transition.to().name === CONFIG.states.SCANS_NEW_SCANS) {
        window.history.back();
      }

      setSelectedTabIndex(scanTabs.indexOf(transition.to().name));
    };

    const deregister = $transitions.onSuccess({}, handleStateChange);

    return () => {
      deregister();
    };
  }, [$state, $transitions]);

  useEffect(() => {
    if ($state.current.name === CONFIG.states.SCANS_NEW_SCANS) {
      $state.go(CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS);
    }
  }, []);

  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: { tabsContainer, contentContainer },
    tabProps: {
      size: 'large',
      tabs: [
        {
          label: t('inProgressLabel'),
          bi: {
            eventType: ScansUITrackingEvent.SCAN_TAB_CLICK,
            eventData: {
              tabName: t('inProgressLabel'),
            },
          },
        },
        {
          label: t('completedLabel'),
          bi: {
            eventType: ScansUITrackingEvent.SCAN_TAB_CLICK,
            eventData: {
              tabName: t('completedLabel'),
            },
          },
        },
      ],
      onChange: tabIndex => {
        const state = scanTabs[tabIndex];
        trackEventScansView(ScansUITrackingEvent.SCAN_TAB_CLICK, { tabName: state });
        $state.go(state);
        setSelectedTabIndex(tabIndex);
      },
      selectedIndex: selectedTabIndex,
    },
  };

  return (
    <ScansContainerContent>
      <ScansCardContent>
        <BigidTabsAndContent {...tabsAndContentConfig} />
        <UIView />
      </ScansCardContent>
    </ScansContainerContent>
  );
};
