import { useCallback, useMemo } from 'react';
import {
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterUnion,
  BigidAdvancedToolbarProps,
  useCancelablePromise,
} from '@bigid-ui/components';
import { GetAggregatedDataResponse } from '../catalogDiscoveryService';
import { AggregationFilterOperand, AggregationType, NonAggregationType } from '../catalogDiscoveryTypes';
import {
  fetchFilterOptions,
  getFilterOptionsFetchMethodPayload,
  parseAggregationBooleanItemsToFilterOptions,
  parseAggregationItemsToFilterOptions,
  parseAggregationNestedItemsToFilterOptions,
} from './utils';
import { useLocalTranslation } from './translations';
import {
  aggregationFilterConfig,
  getAdvancedToolbarFilterConsumableConfig,
  mapAggregationToFilterTranslationKey,
} from './config';
import { getApplicationPreference } from '../../../services/appPreferencesService';

export function useFilterConfig() {
  const { t } = useLocalTranslation('filters');

  const dataSourceNameAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const dataSourceTypeAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const dataSourceLocationAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const objectOwnerAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const objectStatusAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const cloudTypeAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const fileTypeAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const tagsAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const sensitivityAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const attrNameAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const duplicatesAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const objectScanStatusAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const dataFormatAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const violatedPoliciesAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const containerNameAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const riskAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const accessTypeAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();
  const aiObjectTypeAggDataCancelablePromise = useCancelablePromise<GetAggregatedDataResponse>();

  const filtersAvailabilityMap: { [key in AggregationType]?: boolean } = useMemo(() => {
    return {
      [AggregationType.RISK]: getApplicationPreference('ENABLE_CATALOG_OBJECT_RISK'),
      [AggregationType.ACCESS_TYPE_FILTER]: getApplicationPreference('ACI_ENABLED'),
      [AggregationType.AI_OBJECT_TYPE_FILTER]: getApplicationPreference('AI_SECURITY_AND_GOVERNANCE_ENABLED'),
    };
  }, []);

  const filterConfig: BigidAdvancedToolbarProps = useMemo(() => {
    return {
      filters: [
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.DATA_SOURCE_NAME,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.DATA_SOURCE_NAME]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.DATA_SOURCE_NAME,
                filterOperand: AggregationFilterOperand.DATA_SOURCE_NAME,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                dataSourceNameAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.DATA_SOURCE_TYPE,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.DATA_SOURCE_TYPE]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.DATA_SOURCE_TYPE,
                filterOperand: AggregationFilterOperand.DATA_SOURCE_TYPE,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                dataSourceTypeAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.DATA_SOURCE_LOCATION,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.DATA_SOURCE_LOCATION]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.DATA_SOURCE_LOCATION,
                filterOperand: AggregationFilterOperand.DATA_SOURCE_LOCATION,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                dataSourceLocationAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.OBJECT_OWNER),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.OBJECT_OWNER]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.OBJECT_OWNER,
                filterOperand: AggregationFilterOperand.OBJECT_OWNER,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                objectOwnerAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.OBJECT_STATUS,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.OBJECT_STATUS]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.OBJECT_STATUS,
                filterOperand: AggregationFilterOperand.OBJECT_STATUS,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                objectStatusAggDataCancelablePromise,
                parseAggregationBooleanItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.CLOUD_TYPE),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.CLOUD_TYPE]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.CLOUD_TYPE,
                filterOperand: AggregationFilterOperand.CLOUD_TYPE,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                cloudTypeAggDataCancelablePromise,
                parseAggregationNestedItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.FILE_TYPE),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.FILE_TYPE]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.FILE_TYPE,
                filterOperand: AggregationFilterOperand.FILE_TYPE,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                fileTypeAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.DATA_FORMAT),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.DATA_FORMAT]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.DATA_FORMAT,
                filterOperand: AggregationFilterOperand.DATA_FORMAT,
                filter,
                value,
              });
              const filterOptionsPreprocessorFunc =
                aggregationFilterConfig[AggregationType.DATA_FORMAT]?.getFilterOptionsPreprocessed;

              return await fetchFilterOptions(
                payload,
                dataFormatAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
                filterOptionsPreprocessorFunc,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.APPLICATION),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.APPLICATION]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.APPLICATION,
                filterOperand: AggregationFilterOperand.APPLICATION,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                tagsAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.TAGS),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.TAGS]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.TAGS,
                filterOperand: AggregationFilterOperand.TAGS,
                filter,
                value,
                shouldFetchEverything: true,
              });

              return await fetchFilterOptions(
                payload,
                tagsAggDataCancelablePromise,
                parseAggregationNestedItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.SENSITIVITY_FILTER,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.SENSITIVITY_FILTER]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.SENSITIVITY_FILTER,
                filterOperand: AggregationFilterOperand.SENSITIVITY_FILTER,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                sensitivityAggDataCancelablePromise,
                parseAggregationNestedItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.ATTRIBUTE_CATEGORY,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.ATTRIBUTE_CATEGORY]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const valueComputed =
                aggregationFilterConfig[AggregationType.ATTRIBUTE_CATEGORY]?.getUserSearchQueryPreprocessed(value) ??
                value;
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.ATTRIBUTE_CATEGORY,
                filterOperand: AggregationFilterOperand.ATTRIBUTE_CATEGORY,
                filter,
                value: valueComputed,
              });

              return await fetchFilterOptions(
                payload,
                attrNameAggDataCancelablePromise,
                parseAggregationNestedItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.HAS_DUPLICATES,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.HAS_DUPLICATES]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.HAS_DUPLICATES,
                filterOperand: AggregationFilterOperand.HAS_DUPLICATES,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                duplicatesAggDataCancelablePromise,
                parseAggregationBooleanItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.OBJECT_SCAN_STATUS,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.OBJECT_SCAN_STATUS]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.OBJECT_SCAN_STATUS,
                filterOperand: AggregationFilterOperand.OBJECT_SCAN_STATUS,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                objectScanStatusAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.VIOLATED_POLICY,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.VIOLATED_POLICY]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.VIOLATED_POLICY,
                filterOperand: AggregationFilterOperand.POLICY,
                filter,
                value,
                shouldFetchEverything: true,
              });

              return await fetchFilterOptions(
                payload,
                violatedPoliciesAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDateRangeFilter>(
            NonAggregationType.SCAN_DATE,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[NonAggregationType.SCAN_DATE]}.name`),
          value: [],
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDateRangeFilter>(
            NonAggregationType.LAST_ACCESS_DATE,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[NonAggregationType.LAST_ACCESS_DATE]}.name`),
          value: [],
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDateRangeFilter>(
            NonAggregationType.MODIFIED_DATE,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[NonAggregationType.MODIFIED_DATE]}.name`),
          value: [],
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.CONTAINER_NAME,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.CONTAINER_NAME]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.CONTAINER_NAME,
                filterOperand: AggregationFilterOperand.CONTAINER_NAME,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                containerNameAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(AggregationType.RISK),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.RISK]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.RISK,
                filterOperand: AggregationFilterOperand.RISK,
                filter,
                value,
              });
              const filterOptionsPreprocessorFunc =
                aggregationFilterConfig[AggregationType.RISK]?.getFilterOptionsPreprocessed;

              return await fetchFilterOptions(
                payload,
                riskAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
                filterOptionsPreprocessorFunc,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.ACCESS_TYPE_FILTER,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.ACCESS_TYPE_FILTER]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.ACCESS_TYPE_FILTER,
                filterOperand: AggregationFilterOperand.ACCESS_TYPE_FILTER,
                filter,
                value,
              });

              return await fetchFilterOptions(
                payload,
                accessTypeAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
              );
            } catch {
              return [];
            }
          },
        },
        {
          ...getAdvancedToolbarFilterConsumableConfig<BigidAdvancedToolbarDropdownFilter>(
            AggregationType.AI_OBJECT_TYPE_FILTER,
          ),
          title: t(`${mapAggregationToFilterTranslationKey[AggregationType.AI_OBJECT_TYPE_FILTER]}.name`),
          value: [],
          asyncOptionsFetch: async (filter?: BigidAdvancedToolbarFilterUnion[], value?: string) => {
            try {
              const payload = getFilterOptionsFetchMethodPayload({
                aggName: AggregationType.AI_OBJECT_TYPE_FILTER,
                filterOperand: AggregationFilterOperand.AI_OBJECT_TYPE_FILTER,
                filter,
                value,
              });

              const filterOptionsPreprocessorFunc =
                aggregationFilterConfig[AggregationType.AI_OBJECT_TYPE_FILTER]?.getFilterOptionsPreprocessed;

              return await fetchFilterOptions(
                payload,
                aiObjectTypeAggDataCancelablePromise,
                parseAggregationItemsToFilterOptions,
                filterOptionsPreprocessorFunc,
              );
            } catch {
              return [];
            }
          },
        },
      ].filter(({ id }) => {
        //TODO: add isHidden parameter to the filter's config object on the BigidAdvancedToolbarFiltes level
        return (
          !filtersAvailabilityMap.hasOwnProperty(id) ||
          (filtersAvailabilityMap.hasOwnProperty(id) && filtersAvailabilityMap[id as AggregationType])
        );
      }),
    };
  }, [
    t,
    dataSourceNameAggDataCancelablePromise,
    dataSourceTypeAggDataCancelablePromise,
    dataSourceLocationAggDataCancelablePromise,
    objectOwnerAggDataCancelablePromise,
    objectStatusAggDataCancelablePromise,
    cloudTypeAggDataCancelablePromise,
    fileTypeAggDataCancelablePromise,
    dataFormatAggDataCancelablePromise,
    tagsAggDataCancelablePromise,
    sensitivityAggDataCancelablePromise,
    attrNameAggDataCancelablePromise,
    duplicatesAggDataCancelablePromise,
    objectScanStatusAggDataCancelablePromise,
    violatedPoliciesAggDataCancelablePromise,
    containerNameAggDataCancelablePromise,
    riskAggDataCancelablePromise,
    accessTypeAggDataCancelablePromise,
    aiObjectTypeAggDataCancelablePromise,
    filtersAvailabilityMap,
  ]);

  const getFilterConfigById = useCallback(
    (filterId: string | number) => {
      return filterConfig.filters.find(({ id }) => id === filterId);
    },
    [filterConfig],
  );

  return {
    filterConfig,
    getFilterConfigById,
  };
}
