import React, { FC, useMemo, useContext, useEffect, useCallback, useState } from 'react';
import { ActionableInsightsContext } from '../hooks/ActionableInsightsContext';
import { ReducerActions } from '../hooks/ActionableInsightsReducer';
import {
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridColumnTypes,
  BigidGridColumn,
  BigidGridQueryComponents,
} from '@bigid-ui/grid';
import {
  BigidLoader,
  ToolbarActionType,
  ToolbarAction,
  EntityEvents,
  entityEventsEmitter,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidSeverityBadge,
} from '@bigid-ui/components';
import { formatNumberCompact } from '../../../utilities/numericDataConverter';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import {
  CaseModel,
  getFilterConfig,
  fetchAllCasesData,
  ViewType,
  generateTabs,
  ACTIONABLE_INSIGHTS_PREFERENCE_IDENTIFIER_PREFIX,
  eventTracking,
  generateTicketGridField,
  CaseStatus,
  toolbarActions,
  caseStatusBadgeType,
} from '../actionableInsightsService';
import { useLocalTranslation } from '../translations';
import { BigidListIcon, BigidTreeViewIcon } from '@bigid-ui/icons';
import { ActionsDialogTypes, useCaseActions } from './CaseActionsModal/hooks/useCaseActions';
import { CaseActionsModal } from './CaseActionsModal/CaseActionsModal';
import { LoaderWrapper } from './actionableInsightsGridStyles';
import {
  ActionableInsightsPageViewsEvents,
  ActionableInsightsTrackingEvents,
  trackActionableInsightsEvent,
  trackActionableInsightsPageView,
} from '../actionableInsightsTrackingUtil';
import { CaseSidePanel } from '../CaseSidePanel/CaseSidePanel';
import { BigidGridWithToolbarViewToggle } from './BigidGridWithToolbarViewToggle';

export interface ActionableInsightsAllCasesProps {
  initialCaseSidePanelData: any;
}

export const ActionableInsightsAllCases: FC<ActionableInsightsAllCasesProps> = ({ initialCaseSidePanelData }) => {
  const { caseState, dispatch, viewType } = useContext(ActionableInsightsContext);
  const { t } = useLocalTranslation('Case');
  const {
    isOpen,
    isCaseSidePanelOpen,
    openModalWithData,
    modalType,
    closeModal,
    rowData,
    filters,
    openCaseSidePanelWithData,
    closeCaseSidePanel,
  } = useCaseActions();

  const onCaseAction = useCallback(async () => {
    const tabs = await generateTabs();
    dispatch({
      type: ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA,
      payload: { tabs },
    });
  }, [dispatch]);

  const columns = useMemo<BigidGridColumn<CaseModel>[]>(
    () => [
      {
        name: 'caseLabel',
        title: t('fields.case'),
        getCellValue: row => row.caseLabel,
        type: BigidGridColumnTypes.TEXT,
        width: 320,
        disableTooltip: false,
      },
      {
        name: 'dataSourceDisplayName',
        title: t('fields.dsType'),
        getCellValue: row => row.dataSourceDisplayName,
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        disableTooltip: true,
      },
      {
        name: 'created_at',
        title: t('fields.createdAt'),
        getCellValue: row => row.created_at,
        type: BigidGridColumnTypes.DATE,
        width: 180,
        disableTooltip: true,
      },
      {
        name: 'numberOfAffectedObjects',
        title: t('fields.affectedObjects'),
        getCellValue: row => formatNumberCompact(row.numberOfAffectedObjects),
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        disableTooltip: true,
      },
      {
        name: 'severityLevel',
        title: t('fields.severityLevel'),
        getCellValue: row => (
          <BigidSeverityBadge hasBackground={false} size={BigidStatusBadgeSize.SMALL} level={row.severityLevel} />
        ),
        type: BigidGridColumnTypes.CUSTOM,
        width: 120,
        disableTooltip: true,
      },
      {
        name: 'caseStatus',
        title: t('fields.caseStatus'),
        getCellValue: row => (
          <BigidStatusBadge
            type={caseStatusBadgeType(row.caseStatus)}
            size={BigidStatusBadgeSize.SMALL}
            label={row.caseStatus?.toLowerCase() as CaseStatus}
          />
        ),
        type: BigidGridColumnTypes.CUSTOM,
        width: 140,
        disableTooltip: true,
      },
      {
        name: 'assignee',
        title: t('fields.assignee'),
        getCellValue: row => row.assignee,
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        disableTooltip: true,
      },
      {
        name: 'dataSourceName',
        title: t('fields.dsName'),
        getCellValue: row => row.dataSourceName,
        type: BigidGridColumnTypes.TEXT,
        width: 180,
        disableTooltip: false,
      },
      {
        name: 'dataSourceOwner',
        title: t('fields.dsOwner'),
        getCellValue: row => row.dataSourceOwner,
        type: BigidGridColumnTypes.TEXT,
        width: 170,
        disableTooltip: true,
      },
      {
        name: 'jiraTicketUrl',
        title: t('fields.jiraTicketUrl'),
        type: BigidGridColumnTypes.LINK,
        formatter: {
          preventDefaultAndStopPropagation: false,
        },
        width: 170,
        disableTooltip: true,
        getCellValue: row => generateTicketGridField(row.ticketUrl, row.ticketMetadata),
      },
      {
        name: 'compliance',
        title: t('fields.compliance'),
        disableTooltip: true,
        type: BigidGridColumnTypes.CHIPS,
        width: 140,
        getCellValue: row => {
          return {
            chips: {
              value: row.compliance ? [{ label: row.compliance }] : [],
              isEditMode: false,
              isDisabled: true,
            },
          };
        },
      },
    ],
    [t],
  );

  const toolbarActionsViewSelectors: ToolbarAction[] = [
    {
      label: '',
      execute: async () => {
        dispatch({
          type: ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA,
          payload: { viewType: ViewType.GROUPED },
        });
        trackActionableInsightsEvent(ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_VIEW_CLICK, {
          VIEW_TYPE: ViewType.GROUPED,
        });
        return Promise.resolve({
          shouldGridReload: false,
          shouldClearSelection: false,
          shouldClearSelectedItem: false,
        });
      },
      show: () => true,
      placement: 'end',
      icon: BigidTreeViewIcon,
      isGlobal: true,
      disable: () => false,
      type: ToolbarActionType.ACTION_ICON,
    },
    {
      label: '',
      execute: async () => {
        return Promise.resolve({
          shouldGridReload: false,
          shouldClearSelection: false,
          shouldClearSelectedItem: false,
        });
      },
      show: () => true,
      placement: 'end',
      icon: () => <BigidListIcon selected={true} />,
      disable: () => true,
      isGlobal: true,
      type: ToolbarActionType.ACTION_ICON,
    },
  ];

  const { isReady, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: `${ACTIONABLE_INSIGHTS_PREFERENCE_IDENTIFIER_PREFIX}.${caseState}`,
    initialGridColumns: columns,
    getInitialFilterToolbarConfig: getFilterConfig,
  });

  const onModalCtaClick = (modifiedValue?: string, additionalFilters = {}, customModalType?: ActionsDialogTypes) => {
    const mergeFilters = { ...filters, ...additionalFilters };
    const type = modalType || customModalType;
    filters?.callback?.();

    switch (type) {
      case ActionsDialogTypes.SILENCE:
      case ActionsDialogTypes.ACKNOWLEDGE: {
        if (mergeFilters.caseIds.length === 1) {
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, mergeFilters?.caseIds[0], { caseStatus: modifiedValue });
        }

        entityEventsEmitter.emit(EntityEvents.DELETE, mergeFilters?.caseIds);
        entityEventsEmitter.emit(EntityEvents.RELOAD);
        break;
      }
      case ActionsDialogTypes.ASSIGN: {
        if (mergeFilters.caseIds.length === 1) {
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, mergeFilters?.caseIds[0], { assignee: modifiedValue });
        } else {
          entityEventsEmitter.emit(EntityEvents.UPDATE, mergeFilters.caseIds, { assignee: modifiedValue });
        }
        break;
      }
      default: {
        if (mergeFilters.caseIds?.length) {
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, mergeFilters?.caseIds[0], { caseStatus: modifiedValue });
        }

        entityEventsEmitter.emit(EntityEvents.RELOAD);
      }
    }

    onCaseAction();
  };

  const gridWithToolbarConfig: BigidGridWithToolbarProps<CaseModel> = {
    filterToolbarConfig,
    onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
    pagingMode: true,
    pageSize: 10,
    hideColumnChooser: false,
    foreignElementPlacement: 'right',
    rowClickShouldKeepSelection: true,
    columns: gridColumns,
    showSelectAll: false,
    forceSelectAll: false,
    fetchData: (queryComponents: BigidGridQueryComponents) => fetchAllCasesData(queryComponents, caseState),
    showSortingControls: true,
    displayActionToolbar: true,
    entityName: t('fields.flatViewEntityName'),
    toolbarActions: [...toolbarActions(openModalWithData, caseState, onModalCtaClick, true, ViewType.FLAT)],
    foreignElement: (
      <BigidGridWithToolbarViewToggle
        currentView={viewType}
        onToggle={viewType => {
          dispatch({
            type: ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA,
            payload: { viewType },
          });
        }}
      />
    ),
    shouldShowViewToggle: false,
    onRowClick: row => openCaseSidePanelWithData([row]),
  };

  useEffect(() => {
    trackActionableInsightsPageView(ActionableInsightsPageViewsEvents.DATA_RISK_MANAGEMENT_CASES_PAGE_VIEW);
  }, []);

  useEffect(() => {
    isOpen && eventTracking(modalType, ViewType.FLAT);
  }, [isOpen, modalType]);

  useEffect(() => {
    if (initialCaseSidePanelData) {
      openCaseSidePanelWithData([initialCaseSidePanelData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isOpen && eventTracking(modalType, ViewType.FLAT);
  }, [filters, isOpen, modalType]);

  return (
    <>
      {!isReady ? (
        <LoaderWrapper>
          <BigidLoader />
        </LoaderWrapper>
      ) : (
        <>
          <CaseActionsModal
            onCaseAction={onModalCtaClick}
            rowsData={rowData}
            type={modalType}
            closeModal={closeModal}
            isOpen={isOpen}
            viewType={ViewType.FLAT}
            filters={filters}
          />
          <CaseSidePanel
            caseData={rowData}
            isOpen={isCaseSidePanelOpen}
            onClose={closeCaseSidePanel}
            onCaseAction={onModalCtaClick}
          />
          <BigidGridWithToolbar {...gridWithToolbarConfig} />
        </>
      )}
    </>
  );
};
