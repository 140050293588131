import React from 'react';
import { HighlightedType, HightlightedValueResponse, ResultsEntityType, SearchEntityType } from './types';
import {
  BigidDataSourceOnlyIcon,
  BigidFileIcon,
  BigidOtherIcon,
  BigidPolicyIcon,
  BigidTableColumnsIcon,
} from '@bigid-ui/icons';
import { SearchResultsSortBy } from './contexts/dataCatalogSearchResultsContext';
import { styled } from '@mui/material/styles';

const HTML_TAG_REGEX = /(<([^>]+)>)/gi;

export const Highlight = styled('span')`
  background-color: ${({ theme }) => theme.vars.palette.bigid.blue100};
`;

export const extractEmTagValue = (text: string) => {
  const includesEmTag = text.includes('<em>');
  if (!includesEmTag) {
    return {
      before: '',
      highlight: '',
      after: text,
    };
  }

  const emStart = text.indexOf('<em>');
  const emEnd = text.indexOf('</em>');
  const before = text.substring(0, emStart);
  const highlight = text.substring(emStart + 4, emEnd);
  const after = text.substring(emEnd + 5);
  return {
    before,
    highlight,
    after,
  };
};

export const getCatalogEntityIconByType = (type: SearchEntityType) => {
  switch (type) {
    case 'file':
      return BigidFileIcon;
    case 'rdb':
      return BigidTableColumnsIcon;
    default:
      return BigidOtherIcon;
  }
};

export const getAlienIconByType = (type: ResultsEntityType) => {
  switch (type) {
    case 'policy':
      return BigidPolicyIcon;
    case 'datasource':
      return BigidDataSourceOnlyIcon;
    default:
      return BigidFileIcon;
  }
};

export const removeHtmlTags = (string: string) => {
  return string.replace(HTML_TAG_REGEX, '');
};

export const getHighlightedValue = (input: string): (string | JSX.Element)[] => {
  const elements: (string | JSX.Element)[] = [];

  // Split by the <em> tag but keep the content
  const parts = input.split(/<em>|<\/em>/);

  parts.forEach((part, index) => {
    if (index % 2 === 0) {
      // Even indices are regular text
      elements.push(part);
    } else {
      // Odd indices are content that was inside <em> tags
      elements.push(<Highlight key={index}>{part}</Highlight>);
    }
  });

  return elements;
};

export const replaceCodeWithColon = (value: string) => value.replace(/@#&/g, ' : ').replace(/<\/@#&>/g, '');

export const filterCardReasons = (reasons: HightlightedValueResponse[]) => {
  const supportedTypes: HighlightedType[] = ['tags', 'attributes', 'category', 'columns'];

  return reasons.filter(({ fieldName }) => supportedTypes.includes(fieldName));
};

export const entityTypeToApiType: Record<string, ResultsEntityType> = {
  datasource: 'datasource',
  catalog: 'catalog',
  policy: 'policy',
};

export const stringToSortByMap: Record<string, SearchResultsSortBy> = {
  // relevance: 'relevance', NOTE: bring back once the term of "relevance" is defined and is properly implemented
  name: 'name',
  scanDate: 'scanDate',
  // modified_date: 'modified_date', NOTE: bring back once sorting by "modified_date" works properly
};
