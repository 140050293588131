import React, { PropsWithChildren } from 'react';
import {
  AdvancedToolbarOverrideValue,
  BigidAdvancedToolbarFilterUnion,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormProps,
  BigidFormValidateOnTypes,
  BigidFormValues,
  BigidMenuItemProps,
  BigidTabsItem,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidXIcon } from '@bigid-ui/icons';
import { closeSystemDialog, openSystemDialog } from '../../../services/systemDialogService';
import { notificationService } from '../../../services/notificationService';
import { httpService } from '../../../services/httpService';
import { camelCase, isEqual } from 'lodash';
import { getCurrentUser } from '../../../utilities/systemUsersUtils';
import { EditViewDialogContent, DeleteViewDialogContent } from '../components';
import { getFixedT } from '../translations';
import { isNameValid } from '../../../utilities/validation';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { executiveDashboardEventEmitter, ExecutiveDashboardEvents } from './executiveDasboardEvents';
import { SavedViewsSubMenu } from '../components/SavedViewsSubMenu';
import { DashboardToolbarActions } from '../hooks/useDashboardToolbar';

export const SAVED_FILTERS_API_ROUTE = 'saved-filters';
export const OVERVIEW_TAB_ID = 'overview';
export const EXECUTIVE_DASHBOARDS_PREF_KEY = 'executiveDashboards';

const t = getFixedT();

export enum DashboardType {
  SECURITY = 'security',
  DSPM = 'dspm',
  DISCOVERY = 'discovery',
  COMPLIANCE = 'compliance',
}

export interface SavedFilterItem {
  activeWidgetIds: string[];
}

export type DashboardFilterTab = {
  id: string;
  label: string;
  filter: BigidAdvancedToolbarFilterUnion[];
  isNewView?: boolean;
};

export interface SavedViewItem {
  createdAt: string;
  filter: SavedFilterItem;
  id: string;
  name: string;
  query: string;
  text: string;
  type: string;
  updatedAt: string;
  userId: string;
}

export interface ExecutiveDashboardProps extends PropsWithChildren {
  dashboardType: DashboardType;
  dashboardId: string;
  toolbarActions: DashboardToolbarActions;
  toolbarFilters: BigidAdvancedToolbarFilterUnion[];
  activeFilters: BigidAdvancedToolbarFilterUnion[];
  activeWidgetIds: string[];
  widgetSelectionItems: BigidMenuItemProps[];
  isExportButtonDisplayed?: boolean;
  isSavedFiltersTabsDisplayed?: boolean;
  onExportButtonClick?: (event?: MouseEvent) => void;
  externalAppliedFilters?: AdvancedToolbarOverrideValue[];
  isLoading?: boolean;
}

export interface SavedViewData {
  filter: BigidAdvancedToolbarFilterUnion[];
  isHidden: boolean;
  owner: string;
  activeWidgetIds?: string[];
}

export interface DashboardsPreferences {
  dashboardId: string;
  lastViewSelected?: string;
  hiddenViews?: string[];
}

const isTrueRadioValue = (value: string | boolean) => {
  return value === true || value === 'true';
};

export const saveNewView = async (
  dashboardId: string,
  filterLabel: string,
  filterName: string,
  filter: BigidAdvancedToolbarFilterUnion[],
  activeWidgetIds: string[],
) => {
  const { name } = await getCurrentUser();
  const data = {
    name: filterName,
    text: filterLabel,
    filter: { activeWidgetIds },
    query: prepareFiltersForSaving(filter),
    type: dashboardId,
    userId: name,
  };
  return await httpService.post(SAVED_FILTERS_API_ROUTE, data);
};

export const updateView = async (filterId: string, updatedFields: Partial<SavedViewItem>) => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<{ data: SavedViewItem }>(`${SAVED_FILTERS_API_ROUTE}/${filterId}`);
    const currentFields = { name: data.name, text: data.text, query: data.query, type: data.type, userId: data.userId };
    const updatedFilter = { ...currentFields, ...updatedFields };
    await httpService.put(`${SAVED_FILTERS_API_ROUTE}/${filterId}`, updatedFilter);
    return updatedFilter;
  } catch (e) {
    notificationService.error(t('errorMessages.cantFetchFilters'));
    console.error(e);
  }
};

export const deleteView = async (filterId: string) =>
  await httpService.delete(`${SAVED_FILTERS_API_ROUTE}/${filterId}`);

export const getSavedViews = async (dashboardId: string) => {
  try {
    const {
      data: { data = [] },
    } = await httpService.fetch<{ data: SavedViewItem[] }>(`${SAVED_FILTERS_API_ROUTE}?type=${dashboardId}`);
    return data;
  } catch (e) {
    notificationService.error(t('errorMessages.cantFetchFilters'));
    console.error(e);
  }
};

export const isViewNameAlreadyTaken = (name: string, filterItems: BigidTabsItem<SavedViewData>[]) => {
  const isNameExist = (filter: BigidTabsItem<SavedViewData>) => filter.label.toLowerCase() === name.toLowerCase();
  return !!filterItems.find(isNameExist);
};

export const saveNewViewWithDialog = (
  filter: BigidAdvancedToolbarFilterUnion[],
  activeWidgetIds: string[],
  dashboardId: string,
  newFilterDialogFormProps: BigidFormProps,
  currentFilterId: string,
  shouldForceNewView: boolean,
) => {
  return new Promise<DashboardFilterTab[]>(resolve => {
    openSystemDialog({
      title: t('titles.saveViewDialog'),
      onClose: () => resolve([]),
      buttons: [
        {
          text: t('buttonLables.cancel'),
          onClick: () => {
            resolve([]);
            closeSystemDialog();
          },
          component: TertiaryButton,
        },
        {
          text: t('buttonLables.save'),
          onClick: () => {
            try {
              newFilterDialogFormProps?.stateAndHandlersRef?.current?.validateAndSubmit(
                async (values: BigidFormValues) => {
                  const isNewView = isTrueRadioValue(values?.isNewView);
                  const filterLabel = values.filterName.trim();
                  const filterName = camelCase(filterLabel);
                  if (isNewView) {
                    await saveNewView(dashboardId, filterLabel, filterName, filter, activeWidgetIds);
                  } else {
                    const fieldsToUpdate = {
                      name: filterName,
                      text: filterLabel,
                      query: prepareFiltersForSaving(filter),
                      filter: { activeWidgetIds },
                    };
                    await updateView(currentFilterId, fieldsToUpdate);
                  }
                  resolve([{ id: filterName, label: filterLabel, filter, isNewView }]);
                  closeSystemDialog();
                },
              );
            } catch (e) {
              notificationService.error(t('errorMessages.cantsaveView'));
              console.error(e);
              resolve([]);
            }
          },
          component: PrimaryButton,
        },
      ],
      content: EditViewDialogContent,
      contentProps: { newFilterDialogFormProps, isNewViewForced: shouldForceNewView, currentFilterId },
      borderTop: true,
      borderBottom: true,
      maxWidth: 'xs',
    });
  });
};

export const prepareFiltersForSaving = (filter: BigidAdvancedToolbarFilterUnion[]) => {
  const updatedFilters = filter.map(({ type, id, options }) => ({
    type,
    id,
    options,
  }));
  return JSON.stringify(updatedFilters);
};

export const deleteViewWithDialog = (filterId: string, filterName: string) => {
  return new Promise<boolean>(resolve => {
    openSystemDialog({
      title: t('titles.deleteViewDialog', { filterName }),
      onClose: () => resolve(false),
      buttons: [
        {
          text: t('buttonLables.cancel'),
          onClick: () => {
            resolve(false);
            closeSystemDialog();
          },
          component: TertiaryButton,
        },
        {
          text: t('buttonLables.delete'),
          onClick: async () => {
            try {
              await deleteView(filterId);
              resolve(true);
              closeSystemDialog();
            } catch (e) {
              notificationService.error(t('errorMessages.cantdeleteView'));
              console.error(e);
              resolve(false);
            }
          },
          component: PrimaryButton,
        },
      ],
      content: DeleteViewDialogContent,
      contentProps: { filterName },
      borderTop: true,
      borderBottom: true,
      maxWidth: 'xs',
    });
  });
};

export const getUpdatedTabItemsWithSelectedTab = (tabItems: BigidTabsItem<SavedViewData>[], selectedTabId: string) => {
  const matchedIndex = tabItems.findIndex(tab => tab.id === selectedTabId);
  return tabItems.map((item, index) => {
    if (matchedIndex && index === matchedIndex) {
      return {
        ...item,
        AdditionalLabelItem: {
          component: BigidXIcon,
          props: {
            size: 'small',
            onClick: () => executiveDashboardEventEmitter.emit(ExecutiveDashboardEvents.HIDE_CURRENT_VIEW),
          },
        },
      };
    } else {
      return { label: item.label, id: item.id, data: item.data };
    }
  });
};

export const createSavedViewMenuItems = (
  items: BigidTabsItem<SavedViewData>[],
  currentUserId: string,
  onShowTab: (tabId: string) => void,
  onDeleteView: (filterId: string, filterName: string) => Promise<void>,
  onRenameView: (filterId: string, filterName: string) => Promise<void>,
): BigidMenuItemProps[] => {
  return items.map(item => {
    const isOverviewTab = item.id === OVERVIEW_TAB_ID;
    const isUserAllowToEdit = item.data?.owner === currentUserId;
    return {
      label: item.label,
      onClick: () => onShowTab(item.id),
      ...(!isOverviewTab &&
        isUserAllowToEdit && {
          endItem: (
            <SavedViewsSubMenu
              onRename={() => onRenameView(item.id, item.label)}
              onDelete={() => onDeleteView(item.id, item.label)}
            />
          ),
        }),
    };
  });
};

export const getNewFilterDialogFormProps = (
  stateAndHandlersRef: React.MutableRefObject<any>,
  tabItems: BigidTabsItem<SavedViewData>[],
  shouldForceNewView: boolean,
  currentFilterLabel: string,
  selectedTabIndex: number,
): BigidFormProps => {
  const fields: BigidFormField[] = [
    {
      name: 'isNewView',
      type: BigidFormFieldTypes.RADIO,
      ...(shouldForceNewView && { render: () => null }),
      options: [
        {
          value: false,
          label: t('form.updateCurrentView'),
        },
        {
          value: true,
          label: t('form.saveAsNewView'),
        },
      ],
    },
    {
      name: 'filterName',
      label: t('form.filterName') as string,
      fieldProps: { defaultValue: currentFilterLabel },
      isRequired: true,
      validate: (name: string, values) => {
        if (!isNameValid(name)) {
          return t('errorMessages.invalidFilterName') as string;
        }
        const tabItemsToVerify = [...tabItems];
        if (selectedTabIndex && !isTrueRadioValue(values?.isNewView)) {
          tabItemsToVerify.splice(selectedTabIndex, 1);
        }
        if (isViewNameAlreadyTaken(name, tabItemsToVerify)) {
          return t('errorMessages.nameAlreadyTaken') as string;
        }
        return false;
      },
    },
  ];
  return {
    controlButtons: false,
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    stateAndHandlersRef,
    fields,
    initialValues: { isNewView: shouldForceNewView, ...(currentFilterLabel && { filterName: currentFilterLabel }) },
  };
};

export const getRenameDialogFormProps = (
  stateAndHandlersRef: React.MutableRefObject<any>,
  tabItems: BigidTabsItem<SavedViewData>[],
  filterName: string,
): BigidFormProps => {
  const fields: BigidFormField[] = [
    {
      name: 'filterName',
      label: t('form.newFilterName') as string,
      isRequired: true,
      validate: (name: string) => {
        if (!isNameValid(name)) {
          return t('errorMessages.invalidFilterName') as string;
        }
        if (isViewNameAlreadyTaken(name, tabItems)) {
          return t('errorMessages.nameAlreadyTakenOrNotModified') as string;
        }
        return false;
      },
    },
  ];
  return {
    controlButtons: false,
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    stateAndHandlersRef,
    fields,
    initialValues: { filterName },
  };
};

export const renameViewWithDialog = (
  filterId: string,
  filterName: string,
  renameFilterDialogFormProps: BigidFormProps,
) => {
  return new Promise<Partial<SavedViewItem>>(resolve => {
    openSystemDialog({
      title: t('titles.renameFilterDialog', { filterName }),
      onClose: () => resolve({}),
      buttons: [
        {
          text: t('buttonLables.cancel'),
          onClick: () => {
            resolve({});
            closeSystemDialog();
          },
          component: TertiaryButton,
        },
        {
          text: t('buttonLables.save'),
          onClick: () => {
            try {
              renameFilterDialogFormProps?.stateAndHandlersRef?.current?.validateAndSubmit(
                async (values: BigidFormValues) => {
                  const filterLabel = values.filterName.trim();
                  const filterName = camelCase(filterLabel);
                  const fieldsToUpdate = { name: filterName, text: filterLabel };
                  const updatedFilter = await updateView(filterId, fieldsToUpdate);
                  resolve(updatedFilter);
                  closeSystemDialog();
                  notificationService.success(t('successMessages.changesSavedMessage'), {
                    title: t('successMessages.changesSavedTitle'),
                  });
                },
              );
            } catch (e) {
              notificationService.error(t('errorMessages.cantsaveView'));
              console.error(e);
              resolve({});
            }
          },
          component: PrimaryButton,
        },
      ],
      content: EditViewDialogContent,
      contentProps: { newFilterDialogFormProps: renameFilterDialogFormProps, isNewViewForced: false },
      borderTop: true,
      borderBottom: true,
      maxWidth: 'xs',
    });
  });
};

export const toggleWidgetId = (widgetIds: string[], widgetId: string) => {
  const updatedArray = [...widgetIds];
  const index = updatedArray.indexOf(widgetId);
  if (index !== -1) {
    updatedArray.splice(index, 1);
  } else {
    updatedArray.push(widgetId);
  }
  return updatedArray;
};

export const getHiddenViews = (tabItems: BigidTabsItem<SavedViewData>[]) => {
  return tabItems.filter(item => item.data.isHidden).map(item => item.id);
};

export const getAllDashboardPreferences = async () => {
  const { data } = (await userPreferencesService.get<DashboardsPreferences[]>(EXECUTIVE_DASHBOARDS_PREF_KEY)) || {};
  return data || [];
};

export const getDashboardPreference = async (dashboardId: string) => {
  const allDashobardPreferences = await getAllDashboardPreferences();
  return allDashobardPreferences.find(item => item?.dashboardId === dashboardId);
};

export const updateDashboardPreferences = async (dashboardId: string, selectedView: string, hiddenViews: string[]) => {
  const dashboardPreferences: DashboardsPreferences[] = (await getAllDashboardPreferences()) || [];
  const updateDashboardItem: DashboardsPreferences = {
    dashboardId: dashboardId,
    lastViewSelected: selectedView,
    hiddenViews,
  };
  const index = dashboardPreferences.findIndex(item => item.dashboardId === dashboardId);
  if (index !== -1) {
    dashboardPreferences[index] = updateDashboardItem;
  } else {
    dashboardPreferences.push(updateDashboardItem);
  }
  await userPreferencesService.update({ preference: EXECUTIVE_DASHBOARDS_PREF_KEY, data: dashboardPreferences });
};

export const getDisplayedTabItems = (tabItems: BigidTabsItem<SavedViewData>[]) => {
  return [...tabItems].filter(tabItem => !tabItem.data?.isHidden);
};

export const getTabIndexById = (tabId: string, tabItems: BigidTabsItem<SavedViewData>[]) => {
  const tabIndex = tabItems.findIndex(tab => tab.id === tabId);
  return tabIndex >= 0 ? tabIndex : 0;
};

export const isSavedViewHasNoChanges = (
  activeView: BigidTabsItem<SavedViewData>,
  newFilter: BigidAdvancedToolbarFilterUnion[],
  newActiveWidgets: string[],
) => {
  const { data } = activeView || {};
  const newFilterForComparison = newFilter.map(({ type, id, options }) => ({ type, id, options }));
  const isWidgetsEqual = data?.activeWidgetIds?.length
    ? isEqual(data.activeWidgetIds.sort(), newActiveWidgets?.sort())
    : false;
  return isWidgetsEqual && isEqual(data?.filter, newFilterForComparison);
};

export const isCustomViewSelectedAndExist = (selectedViewId: string, savedViews: SavedViewItem[]) => {
  if (selectedViewId && selectedViewId !== OVERVIEW_TAB_ID) {
    return !!savedViews.find(view => view.id === selectedViewId);
  }

  return false;
};
