import React, { FC, ReactText, useEffect, useState } from 'react';
import { fetchScanChildInfo } from '../ScanInsightService';
import { SubscanStage } from '../ScanInsightTypes';
import { notificationService } from '../../../../services/notificationService';
import { BigidLoader, BigidSidePanel, BigidSidePanelActionItem, BigidSidePanelActions } from '@bigid-ui/components';
import { CompletedParentScanState } from '../../ScanTypes';
import { Content } from './Content';
import { BasicInfo } from './BasicInfo';
import { BigidDownloadIcon } from '@bigid-ui/icons';
import { downloadScanPartsReport, getFailedObjectReport, isScanInsightsAvailable } from '../../ScanUtils';
import { generateDataAid } from '@bigid-ui/utils';
import { BasicInfoFF } from './BasicInfoFF';

export interface ScanInsightsSidePanelProps {
  isOpen: boolean;
  onClose: () => void;
  scanId: ReactText;
  scanState: string;
  scanName: string;
}

const failedObjectReportMessage =
  'Sub Scan has no failed objects because it was rescanned and no failed objects were found.';
const scanPartsReportMessage =
  'Sub Scan has no failed or started scan parts because it was rescanned and no failed scan parts were found.';

export const ScanInsightsSidePanel: FC<ScanInsightsSidePanelProps> = ({
  scanId,
  scanName,
  scanState,
  isOpen,
  onClose,
}) => {
  const [scanDetails, setScanDetails] = useState<any>({});
  const [isFinishedToLoading, setIsFinishedToLoading] = useState(false);

  useEffect(() => {
    if (scanId) {
      getScanChildInfo(scanId as string);
    }
  }, [scanId]);

  const getScanChildInfo = async (id: string, shouldFetchAllScannerIds = false) => {
    try {
      setIsFinishedToLoading(false);
      const scanChildInfo = await fetchScanChildInfo(id, shouldFetchAllScannerIds);
      setScanDetails({ ...scanChildInfo, state: scanState });
    } catch (error) {
      notificationService.error('Failed to load Subscan data, see log for more information');
      console.error(error);
    } finally {
      setIsFinishedToLoading(true);
    }
  };

  const downloadFailedObjectReport = (scanId: string) => {
    if (scanDetails.isFailedObjectReportEmpty) {
      notificationService.warning(failedObjectReportMessage);
      return;
    }
    getFailedObjectReport(scanId);
  };

  const handleDownloadScanPartsReport = (scanId: string) => {
    if (scanDetails.isScanPartsReportEmpty) {
      notificationService.warning(scanPartsReportMessage);
      return;
    }
    downloadScanPartsReport(scanId);
  };

  const handleActionsComponent = (isFF?: boolean) => {
    const isDsScan = scanDetails?.type === SubscanStage.DATA_SOURCE_SCAN;
    if (!isDsScan) {
      return;
    }

    const hasFailedObjects = scanDetails?.Scan.objectsFailed > 0;
    const hasFailedOrstartedScanParts = scanDetails?.Scan.partsFailed + scanDetails?.Scan.partsInProgress > 0;
    const { state } = scanDetails;
    const isShowScanPartsReport =
      (state === CompletedParentScanState.COMPLETED_WITH_FAILURES || state === CompletedParentScanState.COMPLETED) &&
      hasFailedOrstartedScanParts;

    const isShowFailedObjectsReport = state === CompletedParentScanState.COMPLETED_WITH_FAILURES && hasFailedObjects;

    const actionItems: BigidSidePanelActionItem[] = [];
    if (isShowScanPartsReport) {
      actionItems.push({
        text: isFF ? 'Process Tasks Report' : 'Failed parts',
        onClick: () => handleDownloadScanPartsReport(scanDetails._id),
        size: 'medium',
        startIcon: <BigidDownloadIcon />,
        dataAid: generateDataAid('Actions', ['partsReport']),
      });
    }

    if (isShowFailedObjectsReport) {
      actionItems.push({
        text: isFF ? 'Objects Report' : 'Failed objects',
        onClick: () => downloadFailedObjectReport(scanDetails._id),
        size: 'medium',
        startIcon: <BigidDownloadIcon />,
        dataAid: generateDataAid('Actions', ['objectsReport']),
      });
    }

    if (actionItems.length > 0) {
      return {
        actions: <BigidSidePanelActions actions={actionItems} />,
      };
    }
  };

  const basicInfoProps = { type: scanDetails?.type };
  const basicInfoPropsFF = { updatedAt: scanDetails.updatedAt };

  return (
    <BigidSidePanel
      open={isOpen}
      title={scanName}
      onClose={onClose}
      content={isFinishedToLoading ? <Content {...scanDetails} /> : <BigidLoader />}
      basicInfo={<BasicInfo {...basicInfoProps} />}
      {...handleActionsComponent()}
    />
  );
};
