import './scans.component.scss';
import { parseCronExpression } from '../../common/services/localizationHelper.js';
import { BigidProgressBarStatus, SecondaryButton, PrimaryButton } from '@bigid-ui/components';
import { get as lodashGet, capitalize } from 'lodash';
import template from './scans.component.html';
import scanActivityInfoModalTemplate from './scanActivityInfoModal/scanActivityInfoModal.component.html';
import { intervalToDuration } from 'date-fns';
import { module } from 'angular';
import { smallIdLicenseService } from '../../react/services/smallIdLicenseService';
import { SCANS_PERMISSIONS, REPORTS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
import { showQuotaMessagesIfNeededAndReturnSummary } from '../../react/services/meteringService';
import { ScanConfirmationDialogContent } from '../../react/views/Scans/ScanConfirmationDialogContent/ScanConfirmationDialogContent';
import { openSystemDialog } from '../../react/services/systemDialogService';
import { httpService } from '../../react/services/httpService';
import { notificationService } from '../../react/services/notificationService';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { showDeleteACEntityDialog } from '../../react/views/ActionCenter/DeleteEntityDialog/DeleteEntityDialogContent';
import { EntityType } from '../../react/views/ActionCenter/ActionWorkflow/actionWorkflowTypes';
import '../../react/components/ProgressSteps/InfoForProgressSteps.tsx';
import { dateTimeService } from '@bigid-ui/i18n';
import { trackEventScansView, ScansUITrackingEvent } from '../../react/views/Scans/ScansEventTrackerUtils';
import { ScanProfileActionType } from '../../react/views/Scans/ScanProfiles/ScanProfileTypes';

const app = module('app');

export const TRANSLATION_REQUIRED = [
  'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_ENUMERATED_NOT_APPLICABLE',
  'SCANS:SCAN_ACTIVITY:FILTER:ALL',
  'SCANS:SCAN_ACTIVITY:FILTER:SCANS',
  'SCANS:SCAN_ACTIVITY:FILTER:DSAR',
  'SCANS:SCAN_ACTIVITY:FILTER:INVESTIGATION_SCAN',
  'SCANS:SCAN_ACTIVITY:FILTER:OTHERS',
  'SCANS:SCAN_ACTIVITY:FAILED_OBJECT_REPORT',
  'SCANS:SCAN_ACTIVITY:LABELER_REPORT',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:COLLECTING_METADATA',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:NEW',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:IN_PROGRESS',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:QUEUED',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:REMAINING',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:STOPPED',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:PAUSED',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:STARTED',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:FAILED',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:COMPLETED',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:STOP_REQUESTED',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:INITIATING_RETRY',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:PAUSE_REQUESTED',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:PAUSED',
  'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:INITIATING_RESUME',
  'SCANS:SCAN_ACTIVITY:INFO:SCANNING_OBJECT',
  'SCANS:SCAN_ACTIVITY:INFO:ROWS_SCANNED',
  'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_ENUMERATED',
  'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_SCANNED',
  'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_FAILED',
  'SCANS:SCAN_ACTIVITY:INFO:TOTAL_FINDINGS',
  'SCANS:SCAN_ACTIVITY:INFO:TOTAL_PARTS',
  'SCANS:SCAN_ACTIVITY:INFO:TOTAL_PARTS_NOT_APPLICABLE',
  'SCANS:SCAN_ACTIVITY:INFO:PARTS_COMPLETED',
  'SCANS:SCAN_ACTIVITY:INFO:PARTS_FAILED',
  'SCANS:SCAN_ACTIVITY:INFO:PARTS_IN_PROGRESS',
  'SCANS:SCAN_ACTIVITY:INFO:CHECK_REPORT',
  'SCANS:SCAN_ACTIVITY:INFO:SCANNING',
  'SCANS:SCAN_ACTIVITY:INFO:CORRELATION',
  'SCANS:SCAN_ACTIVITY:INFO:PII_SUMMARY',
  'SCANS',
];

const SCAN_STATUS_MAPPING = {
  COLLECTING_METADATA: 'CollectingMetadata',
  COMPLETED: 'Completed',
  STOPPED: 'Stopped',
  PAUSED: 'Paused',
  FAILED: 'Failed',
  QUEUED: 'Queued',
  STARTED: 'Started',
  NEW: 'New',
  IN_PROGRESS: 'InProgress',
  STOP_REQUESTED: 'StopRequested',
  RESUME_REQUESTED: 'ResumeRequested',
  PAUSE_REQUESTED: 'PauseRequested',
  INITIATING_RETRY: 'InitiatingRetry',
  INITIATING_RESUME: 'InitiatingResume',
  SUBMITTED: 'Submitted',
  ABORTED: 'Aborted',
  PENDING: 'Pending',
};

const SCAN_STATUS_CONFIG = {
  [SCAN_STATUS_MAPPING.COLLECTING_METADATA]: {
    status: BigidProgressBarStatus.PREPARING,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:COLLECTING_METADATA',
  },
  [SCAN_STATUS_MAPPING.NEW]: {
    status: BigidProgressBarStatus.PREPARING,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:NEW',
  },
  [SCAN_STATUS_MAPPING.QUEUED]: {
    status: BigidProgressBarStatus.PREPARING,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:QUEUED',
  },
  [SCAN_STATUS_MAPPING.STARTED]: {
    status: BigidProgressBarStatus.ACTING,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:IN_PROGRESS',
  },
  [SCAN_STATUS_MAPPING.IN_PROGRESS]: {
    status: BigidProgressBarStatus.ACTING,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:IN_PROGRESS',
  },
  [SCAN_STATUS_MAPPING.COMPLETED]: {
    status: BigidProgressBarStatus.COMPLETED,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:COMPLETED',
  },
  [SCAN_STATUS_MAPPING.STOPPED]: {
    status: BigidProgressBarStatus.STOPPED,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:STOPPED',
  },
  [SCAN_STATUS_MAPPING.FAILED]: {
    status: BigidProgressBarStatus.FAILED,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:FAILED',
  },
  [SCAN_STATUS_MAPPING.ABORTED]: {
    status: BigidProgressBarStatus.FAILED,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:ABORTED',
  },
  [SCAN_STATUS_MAPPING.STOP_REQUESTED]: {
    status: BigidProgressBarStatus.ACTING,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:STOP_REQUESTED',
  },
  [SCAN_STATUS_MAPPING.INITIATING_RETRY]: {
    status: BigidProgressBarStatus.PREPARING,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:INITIATING_RETRY',
  },
  [SCAN_STATUS_MAPPING.PAUSE_REQUESTED]: {
    status: BigidProgressBarStatus.ACTING,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:PAUSE_REQUESTED',
  },
  [SCAN_STATUS_MAPPING.PAUSED]: {
    status: BigidProgressBarStatus.STOPPED,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:PAUSED',
  },
  [SCAN_STATUS_MAPPING.PENDING]: {
    status: BigidProgressBarStatus.STOPPED,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:PENDING',
  },
  [SCAN_STATUS_MAPPING.INITIATING_RESUME]: {
    status: BigidProgressBarStatus.PREPARING,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:INITIATING_RESUME',
  },
  [SCAN_STATUS_MAPPING.SUBMITTED]: {
    status: BigidProgressBarStatus.PREPARING,
    title: 'SCANS:SCAN_ACTIVITY:PROGRESS_STATE:SUBMITTED',
  },
};

const SCAN_STATUS_TOOLTIP = {
  [SCAN_STATUS_MAPPING.PENDING]: 'The scan is waiting for the next scan window to open',
};

const SCAN_STEPS = ['scan', 'correlation', 'summary'];
const SCAN_BREAKDOWN_DETAILS_TYPE_MAP = {
  scan: 'sub_scan',
  correlation: 'correlation_scan',
  summary: 'pii_summary',
};

const SCAN_INFO_MAPPING = {
  IN_PROGRESS: [
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:SCANNING_OBJECT',
      path: ['latestCollectionScanned', 'fullyQualifiedName'],
      defaultValue: 0,
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:ROWS_SCANNED',
      path: ['latestCollectionScanned', 'totalRows'],
      defaultValue: 0,
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_ENUMERATED',
      path: ['totalEnumerated'],
      appendix: {
        replace: true,
        text: 'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_ENUMERATED_NOT_APPLICABLE',
        condition: {
          comparisonOperator: '=',
          valueToCompare: 0,
        },
      },
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_SCANNED',
      path: ['totalCollections'],
      defaultValue: 0,
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_FAILED',
      path: ['totalFailedCollections'],
      appendix: {
        text: 'SCANS:SCAN_ACTIVITY:INFO:CHECK_REPORT',
        condition: {
          comparisonOperator: '>',
          valueToCompare: 0,
        },
      },
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:TOTAL_FINDINGS',
      path: ['totalFindings'],
      defaultValue: 0,
    },
  ],
  NOT_IN_PROGRESS: [
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_ENUMERATED',
      path: ['totalEnumerated'],
      appendix: {
        replace: true,
        text: 'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_ENUMERATED_NOT_APPLICABLE',
        condition: {
          comparisonOperator: '=',
          valueToCompare: 0,
        },
      },
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_SCANNED',
      path: ['totalCollections'],
      defaultValue: 0,
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:OBJECTS_FAILED',
      path: ['totalFailedCollections'],
      appendix: {
        text: 'SCANS:SCAN_ACTIVITY:INFO:CHECK_REPORT',
        condition: {
          comparisonOperator: '>',
          valueToCompare: 0,
        },
      },
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:TOTAL_FINDINGS',
      path: ['totalFindings'],
      defaultValue: 0,
    },
  ],
  PARTS: [
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:TOTAL_PARTS',
      path: ['progress_state', 'Total'],
      appendix: {
        replace: true,
        text: 'SCANS:SCAN_ACTIVITY:INFO:TOTAL_PARTS_NOT_APPLICABLE',
        condition: {
          comparisonOperator: '<',
          valueToCompare: 0,
        },
      },
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:PARTS_COMPLETED',
      path: ['progress_state', 'Completed'],
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:PARTS_FAILED',
      path: ['progress_state', 'Failed'],
    },
    {
      label: 'SCANS:SCAN_ACTIVITY:INFO:PARTS_IN_PROGRESS',
      path: ['progress_state', 'InProgress'],
    },
  ],
};

const SCANS_INSIGHTS_TYPES = {
  fullScan: 'full_scan',
  lineageScan: 'lineageScan',
};

const JIT_SCAN_TYPE = 'jit_scan';

const RUN_SCAN_PROFILE_TEMPLATE = `
  <div
    class="img-button img-button--run tooltip"
    ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
    ng-click="grid.appScope.onRunScanNow(row.entity)"
    ng-if="grid.appScope.isRunScanProfilesPermitted"
  >
    <div class="tooltip-container">
      <span class="tooltip-text">
        {{ 'SCANS:ACTIONS:RUN_NOW' | translate }}
      </span>
    </div>
  </div>
`;

const EDIT_SCAN_PROFILE_TEMPLATE = `
  <div
    class="img-button img-button--edit tooltip"
    ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
    ng-click="grid.appScope.onEditScanProfile(row.entity)"
    ng-if="grid.appScope.isEditScanProfilesPermitted"
  >
    <div class="tooltip-container">
      <span class="tooltip-text" data-aid="scans-activity-actions-trigger-{{ 'SCANS:ACTIONS:EDIT' | translate }}-{{row.entity.id}}">
        {{ 'SCANS:ACTIONS:EDIT' | translate }}
      </span>
    </div>
  </div>
`;

const DELETE_SCAN_PROFILE_TEMPLATE = `
  <div
    class="img-button img-button--delete tooltip"
    ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
    ng-click="grid.appScope.onDeleteScanProfile(row.entity)"
    ng-if="grid.appScope.isDeleteScanProfilesPermitted"
  >
    <div class="tooltip-container">
      <span class="tooltip-text" data-aid="scans-activity-actions-trigger-{{ 'SCANS:ACTIONS:DELETE' | translate }}-{{row.entity.id}}">
        {{ 'SCANS:ACTIONS:DELETE' | translate }}
      </span>
    </div>
  </div>
`;

const STOP_SCAN_TEMPLATE = `
  <div
    class="img-button img-button--stop tooltip"
    ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
    ng-click="grid.appScope.onStopScan(row.entity)"
    ng-if="row.entity.isAbleToStop && grid.appScope.isEditScanActivityPermitted"
    data-aid="scan-activity-row-{{currRowIndex}}-action-stop"
  >
    <div class="tooltip-container">
      <span class="tooltip-text">
        {{ 'SCANS:ACTIONS:STOP' | translate }}
      </span>
    </div>
  </div>
`;

const RETRY_SCAN_TEMPLATE = `
  <div
    class="img-button img-button--retry tooltip"
    ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0, disabled: row.entity.retryRequested}"
    ng-click="grid.appScope.onRetryScan(row.entity)"
    ng-if="row.entity.isAbleToRetry && grid.appScope.isEditScanActivityPermitted"
    data-aid="scan-activity-row-{{currRowIndex}}-action-retry"
  >
    <div class="tooltip-container">
      <span class="tooltip-text">
        {{ 'SCANS:ACTIONS:RETRY' | translate }}
      </span>
    </div>
  </div>
`;

const INFO_SCAN_TEMPLATE = `
  <div
    class="img-button img-button--info tooltip"
    ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
    ng-click="grid.appScope.onShowScanActivityInfo(row.entity)"
    ng-if="grid.appScope.isScanActivityPermitted"
    data-aid="scan-activity-row-{{currRowIndex}}-action-info"
  >
    <div class="tooltip-container">
      <span class="tooltip-text">
        {{ 'SCANS:ACTIONS:INFO' | translate }}
      </span>
    </div>
  </div>
`;

const PAUSE_SCAN_TEMPLATE = `
  <div
    class="img-button img-button--pause tooltip"
    ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
    ng-click="grid.appScope.onPauseScan(row.entity)"
    ng-if="grid.appScope.isEditScanActivityPermitted && row.entity.isAbleToPause"
    data-aid="scan-activity-row-{{currRowIndex}}-action-pause"
  >
    <div class="tooltip-container">
      <span class="tooltip-text">
        {{ 'SCANS:ACTIONS:PAUSE' | translate }}
      </span>
    </div>
  </div>
`;

const RESUME_SCAN_TEMPLATE = `
  <div
    class="img-button img-button--run tooltip"
    ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
    ng-click="grid.appScope.onResumeScan(row.entity)"
    ng-if="grid.appScope.isEditScanActivityPermitted && row.entity.isAbleToResume"
    data-aid="scan-activity-row-{{currRowIndex}}-action-run"
  >
    <div class="tooltip-container">
      <span class="tooltip-text">
        {{ 'SCANS:ACTIONS:RESUME' | translate }}
      </span>
    </div>
  </div>
`;

const ARCHIVE_SCAN_TEMPLATE = `
  <div
    class="img-button img-button--archive tooltip"
    ng-class="{bottom: currRowIndex == 0, top: currRowIndex > 0}"
    ng-click="grid.appScope.onArchiveScan(row.entity)"
    ng-if="row.entity.isAbleToArchive"
    data-aid="scan-activity-row-{{currRowIndex}}-action-archive"
  >
    <div class="tooltip-container">
      <span class="tooltip-text">
        {{ 'SCANS:ACTIONS:HIDE' | translate }}
      </span>
    </div>
  </div>
`;

const PROGRESS_BAR_INTERFACE = {
  label: '',
  estimation: '',
  percentage: 0,
  displayPercentage: false,
  displayCustomValue: true,
  status: BigidProgressBarStatus.PREPARING,
  customValue: '',
  steps: [],
  tooltipText: '',
};

const SCANS_PROGRESS_LOADING = [
  SCAN_STATUS_MAPPING.QUEUED,
  SCAN_STATUS_MAPPING.STARTED,
  SCAN_STATUS_MAPPING.IN_PROGRESS,
  SCAN_STATUS_MAPPING.PENDING,
];

const STORAGE_IS_SCAN_PROFILE_EDITED_FLAG = 'isScanProfileEdited';

const STOP_SCAN_BTN_VISIBILITY_CONDITIONS = {
  stateIsNot: [SCAN_STATUS_MAPPING.COMPLETED, SCAN_STATUS_MAPPING.FAILED, SCAN_STATUS_MAPPING.STOPPED],
  typeIs: ['sub_scan', 'jit_scan', 'tagging_scan', 'conditional_search_scan'],
  allowStopFromParentOnly: ['identity_sub_scan'],
};

const RETRY_SCAN_BTN_VISIBILITY_CONDITIONS = {
  stateIs: [SCAN_STATUS_MAPPING.COMPLETED, SCAN_STATUS_MAPPING.FAILED, SCAN_STATUS_MAPPING.STOPPED],
  typeIs: ['sub_scan', 'prediction_scan'],
};

const PAUSE_SCAN_BUTTON = {
  hideButtonConditions: [SCAN_STATUS_MAPPING.PAUSE_REQUESTED, SCAN_STATUS_MAPPING.STOP_REQUESTED],
  parentConditions: [
    SCAN_STATUS_MAPPING.QUEUED,
    SCAN_STATUS_MAPPING.SUBMITTED,
    SCAN_STATUS_MAPPING.STARTED,
    SCAN_STATUS_MAPPING.IN_PROGRESS,
  ],
  scanTypes: ['sub_scan'],
};

const RESUME_SCAN_BUTTON = {
  visibleConditions: [SCAN_STATUS_MAPPING.PAUSED],
  scanTypes: ['sub_scan'],
};

const ARCHIVE_SCAN_BTN_VISIBILITY_CONDITIONS = {
  stateIs: [
    SCAN_STATUS_MAPPING.ABORTED,
    SCAN_STATUS_MAPPING.COMPLETED,
    SCAN_STATUS_MAPPING.STOPPED,
    SCAN_STATUS_MAPPING.FAILED,
  ],
};

const FINISHED_SCAN_STATES = [
  SCAN_STATUS_MAPPING.ABORTED,
  SCAN_STATUS_MAPPING.COMPLETED,
  SCAN_STATUS_MAPPING.STOPPED,
  SCAN_STATUS_MAPPING.FAILED,
];

const INTERVAL_VALUE = 30000;

const DEFAULT_RESPONSE_SIZE = 1000;

const AVAILABLE_SCAN_TYPES = [
  { value: 'investigation_scan', label: 'investigation_scan' },
  { value: 'dlp_scan', label: 'dlp_scan' },
  { value: 'full_scan', label: 'full_scan' },
  { value: 'identity_sub_scan', label: 'identity_sub_scan' },
  { value: 'sub_scan', label: 'sub_scan' },
  { value: 'fetch_events', label: 'fetch_events' },
  { value: 'breachInvestigationScan', label: 'breachInvestigationScan' },
  { value: 'lineage_scan', label: 'lineage_scan' },
  { value: 'lineage_base_tree', label: 'lineage_base_tree' },
  { value: 'dlp_subscan', label: 'dlp_subscan' },
  { value: 'import-from-file', label: 'import-from-file' },
  { value: 'jit_scan', label: 'jit_scan' },
];

const UNSUPPORTED_CONNECTORS_STOP_BUTTON = ['emr', 'hadoop'];

const SCAN_ACTIVITY_ROW_HEIGHT = 40; // row height
const SCAN_ACTIVITY_CHILDREN_VISIBLE_AMOUNT = 5; // maximum items per expanded row
const SCAN_ACTIVITY_EXPANDED_ROW_MAX_HEIGHT = 240;
const SCAN_ACTIVITY_EXPANDED_GRID_INDENT = 18; // corresponds to mScansChildGrid mixin (top/bottom)

const SCAN_PROFILE_ROW_HEIGHT = 40; // row height

const getReadableEta = estimatedCompletionTimeInMs => {
  const duration = intervalToDuration({ start: 0, end: estimatedCompletionTimeInMs });
  const days = duration.days;
  const hours = duration.hours;
  const minutes = duration.minutes;

  let eta = '';

  if (days >= 1) {
    eta += `${days}d `;
  }

  if (hours >= 1) {
    eta += `${hours}h `;
  }

  if (minutes >= 1) {
    eta += `${minutes}m`;
  }

  if (eta.length === 0 && minutes < 1) {
    eta = '1m';
  }

  return eta;
};

/**
 *   progress bar title, fallback to given state as label
 */
const getProgressBarLabel = (state, translations) =>
  SCAN_STATUS_CONFIG[state] ? translations[SCAN_STATUS_CONFIG[state].title] || state : state;

/**
 *   progress bar data with legacy progress_state object
 */
const getProgressBarDataLegacy = (scan, translations) => {
  const {
    progress_state: { Total: scansTotal, Completed: scansCompleted = 0, Failed: scansFailed = 0 },
    state,
    estimatedCompletionTimeInMs,
  } = scan;
  const scanProgressData = Object.assign({}, PROGRESS_BAR_INTERFACE);

  if (estimatedCompletionTimeInMs && !isNaN(estimatedCompletionTimeInMs)) {
    const eta = getReadableEta(estimatedCompletionTimeInMs);
    scanProgressData.estimation = `${translations['SCANS:SCAN_ACTIVITY:PROGRESS_STATE:REMAINING']}: ${eta}`;
  } else {
    scanProgressData.label = getProgressBarLabel(state, translations);
  }

  scanProgressData.status = SCAN_STATUS_CONFIG[state].status;

  const percentage = Math.round((100 * (scansCompleted + scansFailed)) / scansTotal);
  scanProgressData.percentage = isNaN(percentage) ? 0 : percentage;
  scanProgressData.tooltipText = getTooltipText(state);
  scanProgressData.progressRatio = `${scansCompleted}/${scansTotal} Completed`;
  return scanProgressData;
};

/**
 *   progress bar steps
 */
const getProgressSteps = (breakdown, translations) =>
  SCAN_STEPS.reduce((steps, stepName) => {
    const breakdownEntity = breakdown[stepName];

    if (breakdownEntity && SCAN_STATUS_CONFIG[breakdownEntity.state]) {
      const { estimatedCompletionTimeInMs, percentage, state, Completed, Total } = breakdownEntity;
      const displayStepName = capitalize(stepName.toLowerCase());
      const step = Object.assign(
        { stepName: displayStepName, stepInfo: null, stepType: stepName },
        PROGRESS_BAR_INTERFACE,
      );

      if (estimatedCompletionTimeInMs) {
        const eta = getReadableEta(estimatedCompletionTimeInMs);
        step.estimation = `${translations['SCANS:SCAN_ACTIVITY:PROGRESS_STATE:REMAINING']}: ${eta}`;
      } else {
        step.label = getProgressBarLabel(state, translations);
      }

      step.percentage = percentage;
      step.status = SCAN_STATUS_CONFIG[state].status;
      step.customValue = `${Completed}/${Total} Completed`;

      return [...steps, step];
    } else {
      return steps;
    }
  }, []);

const getTooltipText = status => {
  if (SCAN_STATUS_TOOLTIP[status] != null) {
    return SCAN_STATUS_TOOLTIP[status];
  }
  return '';
};

const isEnumerationStateCompleted = enumerationState => {
  return enumerationState !== 'Completed' && enumerationState !== undefined;
};

const getScanInfo = (scan, mapping, translations) => {
  let infoArr = [];

  const checkAppendixCondition = (appendixConf, actualValue) => {
    let canAppend;

    const { comparisonOperator, valueToCompare } = appendixConf;

    switch (comparisonOperator) {
      case '>':
        canAppend = actualValue > valueToCompare;
        break;
      case '<':
        canAppend = actualValue < valueToCompare;
        break;
      case '>=':
        canAppend = actualValue >= valueToCompare;
        break;
      case '<=':
        canAppend = actualValue <= valueToCompare;
        break;
      case '=':
        canAppend = actualValue === valueToCompare;
        break;
      case '!=':
        canAppend = actualValue !== valueToCompare;
        break;
      default:
        canAppend = false;
    }

    return canAppend;
  };

  for (const prop of mapping) {
    const { label, path, defaultValue, appendix } = prop;

    const value = lodashGet(scan, Array.isArray(path) ? path.join('.') : path, defaultValue);

    if (value !== undefined) {
      let info = `${translations[label]}: ${value}`;

      if (appendix) {
        const { condition, text, replace = false } = appendix;

        if (checkAppendixCondition(condition, value)) {
          info = replace ? `${translations[text]}` : `${info} (${translations[text]})`;
        }
      }
      if (info.length > 0) {
        infoArr = [...infoArr, info];
      }
    }
  }

  return infoArr;
};

export const showScanActivityInfoModal = (
  scan,
  $uibModal,
  translations,
  $translate,
  isJitParentScan,
  scansService,
  primaryWindowInstance = null,
) => {
  let windowClassValue = 'scan-activity-info-modal';

  if (primaryWindowInstance !== null) {
    windowClassValue = `${windowClassValue} scan-activity-info-modal--secondary`;
    primaryWindowInstance.setOverlapCoverEnabled();
  }

  $uibModal
    .open({
      animation: true,
      size: 'sm',
      windowClass: windowClassValue,
      template: scanActivityInfoModalTemplate,
      controllerAs: '$modalCtrl',
      backdrop: 'static',
      controller: [
        'scan',
        'translations',
        '$uibModalInstance',
        '$translate',
        function (scan, translations, $uibModalInstance, $translate) {
          this.header = '';
          this.isBreakdownDetails = false;

          const {
            state,
            scannerId,
            enumerationScannerId,
            scannerHostName,
            scanner_group,
            scan_progress_status,
            scanDetails,
            updated_at,
            started_at,
            breakdown_progress_state,
            useBreakdownProgress = false,
            classifiers,
            parent_scan_id,
            number_of_parsing_threads,
          } = scan;

          const SCAN_START_TIME_IMPORTANCE_ORDER = [
            SCAN_STATUS_MAPPING.STARTED,
            SCAN_STATUS_MAPPING.QUEUED,
            SCAN_STATUS_MAPPING.SUBMITTED,
          ];
          const SCAN_END_TIME_IMPORTANCE_ORDER = [
            SCAN_STATUS_MAPPING.FAILED,
            SCAN_STATUS_MAPPING.STOPPED,
            SCAN_STATUS_MAPPING.COMPLETED,
          ];

          const SCAN_DETAILS_LABELS_MAPPING = {
            sub_scan: 'SCANS:SCAN_ACTIVITY:INFO:SCANNING',
            correlation_scan: 'SCANS:SCAN_ACTIVITY:INFO:CORRELATION',
            pii_summary: 'SCANS:SCAN_ACTIVITY:INFO:PII_SUMMARY',
          };

          const getTimeStatusValue = (statusObject, order) => {
            const existingPropsIndexes = Object.keys(statusObject)?.reduce((aggregatedIndexes, prop) => {
              const index = order.findIndex(status => status === prop);

              if (index >= 0) {
                aggregatedIndexes = [...aggregatedIndexes, index];
              }

              return aggregatedIndexes;
            }, []);

            if (existingPropsIndexes.length > 0) {
              const mostImportantIndex = Math.min(...existingPropsIndexes);

              return dateTimeService.formatDate(statusObject[order[mostImportantIndex]]);
            } else {
              return null;
            }
          };

          const getScanDetailsInfo = (scanDetailsEntity, state) => {
            const { progress_state = {}, type } = scanDetailsEntity;
            let infoArr = [];

            if (type === 'sub_scan') {
              if (progress_state.Total > -1) {
                infoArr =
                  state === SCAN_STATUS_MAPPING.IN_PROGRESS
                    ? getScanInfo(
                        scanDetailsEntity,
                        [...SCAN_INFO_MAPPING.IN_PROGRESS, ...SCAN_INFO_MAPPING.PARTS],
                        translations,
                      )
                    : getScanInfo(
                        scanDetailsEntity,
                        [...SCAN_INFO_MAPPING.NOT_IN_PROGRESS, ...SCAN_INFO_MAPPING.PARTS],
                        translations,
                      );
              } else {
                infoArr =
                  state === SCAN_STATUS_MAPPING.IN_PROGRESS
                    ? getScanInfo(scanDetailsEntity, SCAN_INFO_MAPPING.IN_PROGRESS, translations)
                    : getScanInfo(scanDetailsEntity, SCAN_INFO_MAPPING.NOT_IN_PROGRESS, translations);
              }
            } else {
              if (progress_state.Total > -1) {
                infoArr = getScanInfo(scanDetailsEntity, SCAN_INFO_MAPPING.PARTS, translations);
              }
            }

            return infoArr;
          };

          if (scannerId) {
            this.scannerId = scannerId;
          }

          if (enumerationScannerId) {
            this.enumerationScannerId = enumerationScannerId;
          }

          if (parent_scan_id) {
            this.parent_scan_id = parent_scan_id;
          }

          if (number_of_parsing_threads) {
            this.number_of_parsing_threads = number_of_parsing_threads;
          }

          if (scanner_group) {
            this.scanner_group = scanner_group;
          }
          if (classifiers) {
            this.classifiers = classifiers;
          }

          if (scannerHostName) {
            this.scannerHostName = scannerHostName;
          }

          if (scan_progress_status) {
            this.startedAt = getTimeStatusValue(scan_progress_status, SCAN_START_TIME_IMPORTANCE_ORDER);
          }

          if (scan_progress_status) {
            this.endedAt = getTimeStatusValue(scan_progress_status, SCAN_END_TIME_IMPORTANCE_ORDER);
          }

          if (updated_at) {
            this.lastUpdatedAt = updated_at;
          }

          if (scan.type === 'sub_scan') {
            this.subscanId = scan.id;
            this.isSubscan = true;
          }

          started_at && (this.startedAt = started_at);

          if (isJitParentScan(scan)) {
            this.isJitFullScan = true;
            this.downloadFailedObjectJitScansReport = () => {
              scansService.downloadJitScanFailedObjectReport(scan.id);
            };
          }

          if (breakdown_progress_state && useBreakdownProgress) {
            this.steps = getProgressSteps(breakdown_progress_state, translations);
            this.stepsInfo = this.steps?.reduce((stepsInfo, step) => {
              let stepInfoUnstructured = '';
              const { stepType } = step;
              const scanDetailsEntity = scanDetails?.find(
                ({ type }) => type === SCAN_BREAKDOWN_DETAILS_TYPE_MAP[stepType],
              );

              if (scanDetailsEntity) {
                stepInfoUnstructured = getScanDetailsInfo(scanDetailsEntity, state);
              }

              return stepInfoUnstructured.length > 0 ? [...stepsInfo, { stepType, stepInfoUnstructured }] : stepsInfo;
            }, []);
            this.isBreakdownDetails = true;
          } else if (scanDetails) {
            this.steps = scanDetails.reduce((aggregatedScanDetails, scanDetailsEntity) => {
              const { type } = scanDetailsEntity;
              const infoArr = getScanDetailsInfo(scanDetailsEntity, state);

              return infoArr.length > 0
                ? [...aggregatedScanDetails, { type: translations[SCAN_DETAILS_LABELS_MAPPING[type]], info: infoArr }]
                : aggregatedScanDetails;
            }, []);
          }

          $translate('SCANS:INFO_MODAL:HEADER', { entityValue: scan.name }).then(translation => {
            this.header = translation;
          });

          this.onConfirmButtonClick = () => {
            $uibModalInstance.close();
          };

          this.onDownloadClassifiersSummaryClick = async () => {
            try {
              const params = {
                source: scan.ds_connection_name,
                subscan: scan.scanId,
                ml_scan_id: scan.id,
              };
              const url = `predictor/file-download/get-classifiers-summary`;
              return httpService.downloadFile(url, params);
            } catch (e) {
              console.error(e);
              notificationService.error('Could not export. See logs for more information');
            }
          };

          this.downloadScanPartsReport = async () => {
            try {
              const params = {
                subscan_id: scan.id,
              };
              const url = `scan-parts/file-download/report`;
              return httpService.downloadFile(url, params);
            } catch (e) {
              console.error(e);
              notificationService.error('Could not download. See logs for more information');
            }
          };

          this.downloadFailedObjectReport = async subScanId => {
            await scansService.getFailedObjectReport(subScanId);
          };

          this.onCloseIconClick = () => {
            $uibModalInstance.close();
          };
        },
      ],
      resolve: {
        scan: () => scan,
        translations: () => translations,
        $translate: () => $translate,
      },
    })
    .result.then(() => {
      if (primaryWindowInstance !== null) {
        primaryWindowInstance.setOverlapCoverDisabled();
      }
    });
};

function ScansComponentController(
  scansService,
  $scope,
  $translate,
  $interval,
  $timeout,
  $rootScope,
  notificationService,
  $state,
  DeleteConfirmation,
  uiGridConstants,
  $stateParams,
  $uibModal,
) {
  'ngInject';

  const RESPONSE_SIZE =
    typeof $stateParams.size != 'undefined' && $stateParams.size !== null ? $stateParams.size : DEFAULT_RESPONSE_SIZE;

  const getScanProfileActionsCellTemplate = function () {
    return `
      <div tabindex={0} aria-label="Actions" class="actions-container actions-container--profiles" ng-if="!grid.appScope.scanProfilesAllActionsNotPermitted">
        <div class="three-points"></div>
        <div
          class="img-container"
          ng-init="currRowIndex = grid.renderContainers.body.visibleRowCache.indexOf(row)"
        >
          ${RUN_SCAN_PROFILE_TEMPLATE}
          ${EDIT_SCAN_PROFILE_TEMPLATE}
          ${DELETE_SCAN_PROFILE_TEMPLATE}
        </div>
      </div>
      `;
  };

  const SCAN_ACTIVITY_BASIC_OPTIONS = {
    columnDefs: [
      {
        name: 'expand',
        field: '',
        headerCellTemplate: '<div></div>',

        cellTemplate: `
          <div class="cell-wrapper" tabindex="0" aria-label="More Info" aria-expanded="{{ row.isExpanded }}">
            <div
              class="toggle-children-icon"
              ng-attr-title="More Info"
              ng-class="{'toggle-children-icon--plus': !row.isExpanded, 'toggle-children-icon--minus': row.isExpanded}"
              ng-click="grid.appScope.onRowExpansionToggled(row)"
              ng-if="row.entity.children.data.length">
            </div>
          </div>
        `,
        cellClass: 'toggle-children-icons-cell',
        minWidth: 40,
        width: 40,
        enableFiltering: false,
        enableSorting: false,
      },
      {
        name: 'Name',
        field: 'name',
        minWidth: 100,
        maxWidth: 350,
        cellTooltip: row => {
          const scannerId = row.entity.scannerId ? `scanner ID: ${row.entity.scannerId}` : '';
          return typeof row.entity.name != 'undefined' ? `Name: ${row.entity.name} ${scannerId}` : '';
        },
        cellTemplate: `
          <div
            class="cell-wrapper"
            tabindex="0"
            aria-label="Name {{ row.entity.name }}
          >
            <span class="cell-inner" data-aid="scans-activity-cell-name-{{row.entity.id}}">{{ row.entity.name }}</span>
          </div>
        `,
        filter: {
          condition: uiGridConstants.filter.CONTAINS,
          type: uiGridConstants.filter.INPUT,
          flags: {
            caseSensitive: false,
          },
        },
      },
      {
        name: 'Type',
        field: 'type',
        width: 150,
        cellTooltip: row => (typeof row.entity.type != 'undefined' ? `Type: ${row.entity.type}` : ''),
        cellTemplate: `
          <div class="cell-wrapper" tabindex="0" aria-label="Type {{ row.entity.type }}">
            <span class="cell-inner">{{ row.entity.type }}</span>
          </div>
        `,
        filter: {
          type: uiGridConstants.filter.SELECT,
          selectOptions: AVAILABLE_SCAN_TYPES,
        },
      },
      {
        name: 'State',
        field: 'state',
        width: 300,
        cellTooltip: row => (typeof row.entity.state != 'undefined' ? `State: ${row.entity.state}` : ''),
        cellTemplate: `
          <div
            class="ui-grid-cell-contents"
            ng-if="row.entity.progressBarData === undefined || isEnumerationStateCompleted(row.entity.enumerationState)"
          >
            <span
              class="scan-state"
              ng-if="!grid.appScope.loadingStates.includes(row.entity.state)"
              ng-class="{'scan-state--failed': row.entity.state === 'Failed'}">
              {{row.entity.state}}
            </span>
            <span class="scan-state" ng-if="grid.appScope.loadingStates.includes(row.entity.state)">
              <bigid-dots-loader text="grid.appScope.getProgressBarLabel(row.entity.state, this.translations)"/>
            </span>
          </div>
          <div
            tabindex="0"
            aria-label="State {{ row.entity.state }}"
            class="ui-grid-cell-contents ui-grid-cell-contents--progress-bar"
            ng-if="row.entity.progressBarData !== undefined"
            data-aid="scans-activity-progress-bar-on-scan-{{row.entity.name}}"
          >
            <div class="progress-bar-wrapper">
              <progress-bar-detailed
                percentage="row.entity.progressBarData.percentage"
                estimation="row.entity.progressBarData.estimation"
                label="row.entity.progressBarData.label"
                status="row.entity.progressBarData.status"
                steps="row.entity.progressBarData.steps"
                tooltip-text="row.entity.progressBarData.tooltipText"
                custom-value="row.entity.progressBarData.progressRatio"
                display-percentage="false"
                display-custom-value="true"
              />
            </div>
          </div>
        `,
        filter: {
          type: uiGridConstants.filter.SELECT,
          selectOptions: Object.values(SCAN_STATUS_MAPPING).map(state => ({ value: state, label: state })),
        },
      },
      {
        name: 'Info',
        field: 'mergedInfo',
        minWidth: 200,
        cellTooltip: row => (typeof row.entity.mergedInfo != 'undefined' ? `Info: ${row.entity.mergedInfo}` : ''),
        cellTemplate: `
        <div data-aid="scans-activity-info-on-subscan-{{row.entity.name}}"  ng-if="row.entity.type === 'sub_scan'">
          <div class="cell-wrapper" tabindex="0" aria-label="Info {{ row.entity.infoForGrid }}">
            <span class="cell-inner" title="{{ row.entity.infoForGrid }}">{{ row.entity.infoForGrid }}</span>
          </div>
        </div>
        <div data-aid="scans-activity-info-on-scan-{{row.entity.name}}" ng-if="row.entity.type !== 'sub_scan'">
          <div class="cell-wrapper" tabindex="0" aria-label="Info {{ row.entity.mergedInfo }}">
            <span class="cell-inner" title="{{ row.entity.mergedInfo }}">{{ row.entity.mergedInfo }}</span>
          </div>
        </div>
        `,
        filter: {
          condition: uiGridConstants.filter.CONTAINS,
          type: uiGridConstants.filter.INPUT,
          flags: {
            caseSensitive: false,
          },
        },
      },
      {
        name: 'Origin',
        field: 'origin',
        width: 150,
        cellTooltip: row => (typeof row.entity.origin != 'undefined' ? `Origin: ${row.entity.origin}` : ''),
        cellTemplate: `
          <div class="cell-wrapper" tabindex="0" aria-label="Origin {{ row.entity.origin }}">
            <span class="cell-inner">{{ row.entity.origin }}</span>
          </div>
        `,
        filter: {
          condition: uiGridConstants.filter.CONTAINS,
          type: uiGridConstants.filter.INPUT,
          flags: {
            caseSensitive: false,
          },
        },
      },
      {
        name: 'Updated_at',
        field: 'updated_at',
        maxWidth: 200,
        type: 'date',
        cellTooltip: row => (typeof row.entity.created_at != 'undefined' ? `Created at: ${row.entity.created_at}` : ''),
        cellTemplate: `
          <div class="cell-wrapper" tabindex="0" aria-label="Update at {{ row.entity.created_at }}">
            <span class="cell-inner">{{ row.entity.created_at }}</span>
          </div>
        `,
        enableFiltering: false,
      },
      {
        name: 'Actions',
        field: '',
        cellEditableCondition: false,
        enableFiltering: false,
        enableSorting: false,
        cellTemplate: `
          <div
            aria-label="Actions"
            tabindex="0"
            class="actions-container actions-container--activity"
            ng-init="currRowIndex = grid.renderContainers.body.visibleRowCache.indexOf(row)"
          >
            <div class="three-points" data-aid="scans-activity-actions-trigger-{{currRowIndex}}"></div>
            <div class="img-container">
              ${PAUSE_SCAN_TEMPLATE}
              ${RESUME_SCAN_TEMPLATE}
              ${STOP_SCAN_TEMPLATE}
              ${RETRY_SCAN_TEMPLATE}
              ${ARCHIVE_SCAN_TEMPLATE}
              ${INFO_SCAN_TEMPLATE}
            <div>
          <div>`,
        cellClass: 'actions-cell',
        minWidth: 80,
        width: 100,
      },
    ],
  };

  let expandedRowsIdx = []; // expanded entities
  let gridRestoreRequired = false;

  /**
   *   group scans by 'type' property in order to provide dropdown filter functionality
   */
  const groupByScanType = data => {
    const groups = {};

    data.forEach(scan => {
      if (typeof scan.type != 'undefined') {
        if (this.scanActivityGroupsMapping['scans'].indexOf(scan.type) > -1) {
          groups['scans'] = typeof groups['scans'] != 'undefined' ? [...groups['scans'], scan] : [scan];
        } else if (this.scanActivityGroupsMapping['jit_scan'].indexOf(scan.type) > -1) {
          groups['jit_scan'] = typeof groups['jit_scan'] != 'undefined' ? [...groups['jit_scan'], scan] : [scan];
        } else if (this.scanActivityGroupsMapping['investigation_scan'].indexOf(scan.type) > -1) {
          groups['investigation_scan'] =
            typeof groups['investigation_scan'] != 'undefined' ? [...groups['investigation_scan'], scan] : [scan];
        } else {
          groups['others'] = typeof groups['others'] != 'undefined' ? [...groups['others'], scan] : [scan];
        }
      }
    });

    return groups;
  };

  /**
   *   scans aggregation into tree structure using grid config object based on parent.id === child.parent_scan_id
   */
  const prepareScanTrees = data => {
    let rootItems = [];
    const lookup = {};

    // NOTE: temporarily disabled
    // const getParentScanProgressState = (parentScanState, childScanState) => {
    //   const parentScanStateAggregated = Object.assign({}, parentScanState);
    //   const counters = Object.keys(childScanState);

    //   for (const counter of counters) {
    //     parentScanStateAggregated[counter] = parentScanStateAggregated[counter]
    //       ? parentScanStateAggregated[counter] + childScanState[counter]
    //       : childScanState[counter];
    //   }

    //   return parentScanStateAggregated;
    // };

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const itemId = item['id'];

      const parentId =
        typeof item['parent_scan_id'] != 'undefined'
          ? data.filter(scan => scan.id == item['parent_scan_id']).length > 0
            ? item['parent_scan_id']
            : null
          : null;

      if (!Object.prototype.hasOwnProperty.call(lookup, itemId)) {
        lookup[itemId] = {
          children: Object.assign(
            {
              enableHorizontalScrollbar: 0,
              enableVerticalScrollbar: 0,
              showHeader: false,
              enablePaginationControls: false,
              enablePaging: false,
              enableExpandableRowHeader: false,
              expandableRowTemplate: 'scanActivityChildGrid.template.html',
              expandableRowHeight: SCAN_ACTIVITY_EXPANDED_ROW_MAX_HEIGHT,
              data: [],
              allData: [],
              dataStorage: [],
              appScopeProvider: this,
              expandableRowScope: this,
              rowHeight: SCAN_ACTIVITY_ROW_HEIGHT,
              showMoreVisibleLimit: SCAN_ACTIVITY_CHILDREN_VISIBLE_AMOUNT,
              onChildGridShowMoreClick: row => this.onChildGridShowMoreClick(row),
            },
            angular.copy(SCAN_ACTIVITY_BASIC_OPTIONS),
          ),
        };
      }

      lookup[itemId] = Object.assign(lookup[itemId], item);

      const treeItem = lookup[itemId];

      const isLineageBaseTree = treeItem.type === 'lineage_base_tree';
      if (parentId === null && isLineageBaseTree === false) {
        rootItems = [...rootItems, treeItem];
      } else {
        if (!Object.prototype.hasOwnProperty.call(lookup, parentId)) {
          lookup[parentId] = {
            children: Object.assign(
              {
                enableHorizontalScrollbar: 0,
                enableVerticalScrollbar: 0,
                showHeader: false,
                enablePaginationControls: false,
                enablePaging: false,
                enableExpandableRowHeader: false,
                expandableRowTemplate: 'scanActivityChildGrid.template.html',
                expandableRowHeight: SCAN_ACTIVITY_EXPANDED_ROW_MAX_HEIGHT,
                data: [],
                allData: [],
                dataStorage: [],
                appScopeProvider: this,
                expandableRowScope: this,
                rowHeight: SCAN_ACTIVITY_ROW_HEIGHT,
                showMoreVisibleLimit: SCAN_ACTIVITY_CHILDREN_VISIBLE_AMOUNT,
                onChildGridShowMoreClick: row => this.onChildGridShowMoreClick(row),
              },
              angular.copy(SCAN_ACTIVITY_BASIC_OPTIONS),
            ),
          };
        }
        if (lookup[parentId].children.data.length < SCAN_ACTIVITY_CHILDREN_VISIBLE_AMOUNT) {
          lookup[parentId].children.data = [...lookup[parentId].children.data, treeItem];
        }

        lookup[parentId].children.allData = [...lookup[parentId].children.allData, treeItem];
        lookup[parentId].children.dataStorage = [...lookup[parentId].children.dataStorage, treeItem];
        lookup[parentId].children.expandableRowHeight = getExpandedRowHeight(lookup[parentId]);
        lookup[parentId].children.onRegisterApi = gridApi => {
          if (this.scanActivityGridApi) {
            this.scanActivityGridApi.core.on.scrollEnd(null, () => {
              $timeout(() => {
                const { element } = gridApi.grid.parentRow.grid;
                const isSpinnerRendered = Boolean(element[0].querySelector('.scrollFiller'));

                if (isSpinnerRendered && !gridApi.grid.isRefreshing) {
                  gridApi.grid.isRefreshing = true;
                  gridApi.core.refreshRows().then(() => {
                    gridApi.grid.isRefreshing = false;
                  });
                }
              }, 0);
            });
          }
        };
      }
    }

    return rootItems;
  };

  /**
   *   scan objects sorting by the date of creation
   */
  const sortByDateOfCreation = (a, b) => {
    const aTimeStamp = new Date(a['created_at']).getTime();
    const bTimeStamp = new Date(b['created_at']).getTime();

    const delta = bTimeStamp - aTimeStamp;

    if (delta > 0) {
      return 1;
    } else if (delta < 0) {
      return -1;
    } else {
      return 0;
    }
  };

  /**
   *   grid row entities sorting by the date of creation
   */
  const sortGridEntityByDateOfCreation = (a, b) => {
    const aTimeStamp = new Date(a['entity']['created_at']).getTime();
    const bTimeStamp = new Date(b['entity']['created_at']).getTime();

    const delta = bTimeStamp - aTimeStamp;

    if (delta > 0) {
      return 1;
    } else if (delta < 0) {
      return -1;
    } else {
      return 0;
    }
  };

  /**
   *   progress bar data
   */
  const getProgressBarData = scan => {
    const { state, breakdown_progress_state } = scan;
    const scanProgressData = Object.assign({}, PROGRESS_BAR_INTERFACE);
    const {
      estimatedCompletionTimeInMs,
      percentage,
      Completed: scansCompleted,
      Total: scansTotal,
    } = breakdown_progress_state;

    if (estimatedCompletionTimeInMs && state !== SCAN_STATUS_MAPPING.PENDING) {
      const eta = getReadableEta(estimatedCompletionTimeInMs);
      scanProgressData.estimation = `${this.translations['SCANS:SCAN_ACTIVITY:PROGRESS_STATE:REMAINING']}: ${eta}`;
    } else {
      scanProgressData.label = getProgressBarLabel(state, this.translations);
    }

    scanProgressData.status = SCAN_STATUS_CONFIG[state].status;
    scanProgressData.steps = getProgressSteps(breakdown_progress_state, this.translations);
    scanProgressData.percentage = Number(percentage);
    scanProgressData.tooltipText = getTooltipText(state);
    scanProgressData.progressRatio = `${scansCompleted}/${scansTotal} Completed`;
    return scanProgressData;
  };

  /**
   *   API endpoints calls section --start
   */
  const getFailedObjectReport = () => scansService.getFailedObjectReport();

  const getScanActivityData = (size, isUpdate = false) => {
    if (!this.isScanActivityPermitted) {
      return;
    }
    this.scanActivityGridDataLoading = true;
    this.progressBarSupport = {};

    let availableScanTypes = [];
    let availableScanStates = [];

    scansService.getScansData(size, this.scanActivitySelectedOption.fetchFilter).then(
      result => {
        const { scans } = result;

        let parents = [];
        let children = [];

        for (const scan of scans) {
          const {
            type,
            state,
            created_at,
            updated_at,
            parent_scan_id,
            progress_state,
            breakdown_progress_state,
            useBreakdownProgress = false,
            enumerationState,
          } = scan;

          if (availableScanTypes.indexOf(type) < 0) {
            availableScanTypes = [...availableScanTypes, type];
          }

          if (availableScanStates.indexOf(state) < 0) {
            availableScanStates = [...availableScanStates, state];
          }

          scan.created_at = dateTimeService.formatDate(created_at);
          scan.updated_at = dateTimeService.formatDate(updated_at);

          if (SCAN_STATUS_CONFIG[state]) {
            if (breakdown_progress_state && useBreakdownProgress) {
              scan.progressBarData = getProgressBarData(scan, this.translations);
            } else {
              //NOTE: progress bar is currently unavailable for scan.type === pii_summary , temporal solution
              if (progress_state && type !== 'pii_Summary') {
                if (progress_state.Total > 0) {
                  scan.progressBarData = getProgressBarDataLegacy(scan, this.translations);
                } else {
                  scan.progressBarData = getProgressBarDataLegacy({ ...scan, progress_state: {} }, this.translations);
                }
              }
            }
          }

          scan.name = changeNameIfNeeded(scan);

          if (!parent_scan_id) {
            parents = [...parents, scan];
            // in order to support equal grid config for parent/sub scan Info cell
            scan.mergedInfo = scan.info;
            scan.isAbleToArchive = isScanAbleToArchive(scan);
          } else {
            if (!this.progressBarSupport[parent_scan_id]) {
              this.progressBarSupport[parent_scan_id] = [];
            }

            if (scan.type === 'sub_scan') {
              let mergedInfo = [];
              let infoForGrid = [];
              const isInProgress = state === SCAN_STATUS_MAPPING.IN_PROGRESS;
              const enumeratedAndStartedState = enumerationState === SCAN_STATUS_MAPPING.STARTED && isInProgress;
              const toShowInfo = (progress_state && progress_state.Total > 0) || enumeratedAndStartedState;
              if (toShowInfo) {
                infoForGrid = getScanInfo(scan, [...SCAN_INFO_MAPPING.PARTS], this.translations);
                mergedInfo = isInProgress
                  ? getScanInfo(scan, [...SCAN_INFO_MAPPING.IN_PROGRESS, ...SCAN_INFO_MAPPING.PARTS], this.translations)
                  : getScanInfo(
                      scan,
                      [...SCAN_INFO_MAPPING.NOT_IN_PROGRESS, ...SCAN_INFO_MAPPING.PARTS],
                      this.translations,
                    );
              } else {
                mergedInfo = isInProgress
                  ? getScanInfo(scan, SCAN_INFO_MAPPING.IN_PROGRESS, this.translations)
                  : getScanInfo(scan, SCAN_INFO_MAPPING.NOT_IN_PROGRESS, this.translations);
              }
              scan.infoForGrid = scan.state == SCAN_STATUS_MAPPING.FAILED ? scan.errorMessage : infoForGrid.join('; ');
              scan.mergedInfo = mergedInfo.reduce((aggregatedString, string) => {
                return aggregatedString ? `${aggregatedString}; ${string}` : `${string}`;
              }, '');
            } else {
              scan.mergedInfo = scan.info;
            }

            scan.isAbleToStop = isScanAbleToStop(scan);
            scan.isAbleToRetry = isScanAbleToRetry(scan);
            scan.isAbleToResume = isScanAbleToResume(scan);
            scan.isAbleToPause = isScanAbleToPause(scan);

            children = [...children, scan];
          }
        }

        const stateColumnDefIndex = this.scanActivityGridConfig.columnDefs.findIndex(col => col.field === 'state');
        if (stateColumnDefIndex > 0) {
          this.scanActivityGridConfig.columnDefs[stateColumnDefIndex].filter.selectOptions = availableScanStates.map(
            state => ({ value: state, label: state }),
          );
        }

        const typeColumnDefIndex = this.scanActivityGridConfig.columnDefs.findIndex(col => col.field === 'type');
        if (typeColumnDefIndex > 0) {
          this.scanActivityGridConfig.columnDefs[typeColumnDefIndex].filter.selectOptions = availableScanTypes.map(
            type => ({ value: type, label: type }),
          );
        }

        this.scanActivity = [...parents, ...children];

        const structuredScans = prepareScanTrees(this.scanActivity);
        this.scanActivityGroups = groupByScanType(structuredScans);

        if (isUpdate) {
          gridRestoreRequired = true;
        }

        this.scanActivityGridDataLoading = false;

        this.scanActivityGridConfig['data'] = getGroupData(
          this.scanActivitySelectedOption['value'],
          this.scanActivityGroups,
        ).sort(sortByDateOfCreation);
      },
      () => {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });

        this.scanActivityGridDataLoading = false;
      },
    );
  };

  function filterOutScanTypes(scanProfiles, scanType) {
    return scanProfiles.filter(scanProfile => scanProfile.scanType !== scanType);
  }

  function changeNameIfNeeded(scan) {
    if (scan.name === 'JIT Full Scan') {
      return `JIT - Request ID ${scan.id}`;
    }
    return scan.name;
  }

  const getScanProfilesData = () => {
    this.scanProfilesGridDataLoading = true;

    scansService.getScanProfilesData().then(
      result => {
        let { scanProfiles } = result;

        for (let i = 0, len = scanProfiles.length; i < len; i++) {
          scanProfiles[i]['type'] = scansService.getScanTypeNameById(scanProfiles[i]['scanType']);
        }

        if (!getApplicationPreference('SCAN_API_ENABLED')) {
          scanProfiles = filterOutScanTypes(scanProfiles, scansService.scanTypes.SCAN_API.id);
        }

        this.scanProfilesGridConfig.data = scanProfiles;

        this.scanProfilesGridDataLoading = false;
      },
      () => {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });

        this.scanProfilesGridDataLoading = false;
      },
    );
  };

  const getPiiFindings = () => {
    scansService.getPiiFindings().then(result => {
      this.piiFindingsCount = result;
      this.piiFindingsCountTranslate = { entityValue: this.piiFindingsCount };
    });
  };

  /**
   *   API endpoints calls section --end
   */

  /**
   *   interval function definition every INTERVAL_VALUE sec
   */
  const updateScansIntervalFunc = () => {
    if (!this.isScanActivityPermitted) {
      return;
    }
    getScanActivityData(RESPONSE_SIZE, true);
    getPiiFindings();
    this.lastUpdate = dateTimeService.formatDate(new Date());
    this.lastUpdateTranslate = {
      entityValue:
        typeof this.lastUpdate == 'object'
          ? this.lastUpdate.toISOString().replace(/^"(.*)"$/, '$1')
          : this.lastUpdate.replace(/^"(.*)"$/, '$1'),
    };
  };

  const runScanNow = async (scanParams, { isEmptyIDSourcesList, isAllEnabledDS, numberOfDS }) => {
    $interval.cancel(this.updateScansInterval);
    this.updateScansInterval = $interval(updateScansIntervalFunc, INTERVAL_VALUE);

    const shouldRunScan = await showScanConfirmationDialog(
      scanParams,
      isEmptyIDSourcesList,
      isAllEnabledDS,
      numberOfDS,
    );

    if (!shouldRunScan) {
      return;
    }

    scansService.runScan(scanParams).then(
      result => {
        if (result.result.info !== 'Scan Is Already Running. Skipping...') {
          $translate('SCANS:RUN_SCAN_MODAL:MESSAGE:SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          getScanActivityData(RESPONSE_SIZE, true);
        } else {
          $translate('SCANS:RUN_SCAN_MODAL:MESSAGE:ALREADY_RUNNING').then(translation => {
            notificationService.warning(translation);
          });
        }
      },
      e => {
        console.error(e);
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(e?.data?.error || translation);
        });
      },
    );
  };

  const showScanConfirmationDialog = async (scanParams, isEmptyIDSourcesList, isAllEnabledDS = true, numberOfDS) => {
    const isEmptyIdsModal =
      isEmptyIDSourcesList && scanParams.scanType != 'metadataScan' && scanParams.scanType != 'dsTag';
    const closeButtonText = isEmptyIdsModal ? await $translate('BUTTONS:NO') : await $translate('BUTTONS:CANCEL');
    const actionButtonText = isEmptyIdsModal
      ? await $translate('BUTTONS:YES')
      : await $translate('SCANS:RUN_SCAN_MODAL:SUBMIT');
    const headerText = isEmptyIdsModal
      ? await $translate('SCANS:RUN_SCAN_MODAL_NO_ID:HEADER', { entityValue: scanParams.scanProfileName })
      : await $translate('SCANS:RUN_SCAN_MODAL:HEADER', { entityValue: scanParams.scanProfileName });
    const bodyWithoutDsCount = isEmptyIdsModal
      ? await $translate('SCANS:RUN_SCAN_MODAL_NO_ID:BODY', { entityValue: scanParams.scanProfileName })
      : await $translate('SCANS:RUN_SCAN_MODAL:BODY', { entityValue: scanParams.scanProfileName });

    // TODO: remove condition when BDT-21099 is fixed
    const noteText = isAllEnabledDS
      ? await $translate('SCANS:RUN_SCAN_MODAL:BODY:NUMBER_OF_DS_NOTE', {
          numberOfDS: isAllEnabledDS
            ? await $translate('SCANS:RUN_SCAN_MODAL:BODY:ALL_ENABLED_DATA_SOURCES')
            : numberOfDS,
          suffix: isAllEnabledDS || numberOfDS === 0 || numberOfDS > 1 ? 's' : '',
        })
      : '';

    return new Promise(resolve => {
      openSystemDialog({
        title: headerText,
        onClose: () => resolve(),
        buttons: [
          {
            text: closeButtonText,
            onClick: () => resolve(false),
            component: SecondaryButton,
            isClose: true,
          },
          {
            text: actionButtonText,
            onClick: () => resolve(true),
            component: PrimaryButton,
            isClose: true,
            dataAid: 'runScanDialogButton',
          },
        ],
        content: ScanConfirmationDialogContent,
        contentProps: { bodyLine1: bodyWithoutDsCount, bodyLine2: noteText },
        borderTop: true,
        maxWidth: 'sm',
      });
    });
  };

  const stopScan = async scan => {
    const closeButtonText = await $translate('BUTTONS:CANCEL');
    const actionButtonText = await $translate('SCANS:STOP_SUB_SCAN_MODAL:SUBMIT');
    const headerText = await $translate('SCANS:STOP_SUB_SCAN_MODAL:HEADER');
    const bodyText = await $translate('SCANS:STOP_SUB_SCAN_MODAL:BODY', { entityValue: scan.ds_connection_name });

    DeleteConfirmation.showModal({}, { closeButtonText, actionButtonText, headerText, bodyText }).then(async () => {
      try {
        if (scan.type === 'Labeling' || scan.type === 'tagging_scan') {
          await scansService.stopLabelingScan(scan._id);
        } else {
          await scansService.stopSubScan(scan._id);
        }
        $translate('SCANS:STOP_SUB_SCAN_MODAL:MESSAGE:SUCCESS').then(translation => {
          notificationService.success(translation);
        });

        scan.stopRequested = true;

        getScanActivityData(RESPONSE_SIZE, true);
      } catch (e) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });
      }
    });
  };

  const retryScan = async scan => {
    const closeButtonText = await $translate('BUTTONS:CANCEL');
    const actionButtonText = await $translate('SCANS:RETRY_SUB_SCAN_MODAL:SUBMIT');
    const headerText = await $translate('SCANS:RETRY_SUB_SCAN_MODAL:HEADER');
    const bodyText = await $translate('SCANS:RETRY_SUB_SCAN_MODAL:BODY', { entityValue: scan.name });

    DeleteConfirmation.showModal({}, { closeButtonText, actionButtonText, headerText, bodyText }).then(() => {
      scansService.retryScan(scan._id).then(
        () => {
          $translate('SCANS:RETRY_SUB_SCAN_MODAL:MESSAGE:SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          scan.retryRequested = true;

          getScanActivityData(RESPONSE_SIZE, true);
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    });
  };

  const retryPredict = async scan => {
    const closeButtonText = await $translate('BUTTONS:CANCEL');
    const actionButtonText = await $translate('SCANS:RETRY_PREDICTION_SCAN_MODAL:SUBMIT');
    const headerText = await $translate('SCANS:RETRY_PREDICTION_SCAN_MODAL:HEADER');
    const bodyText = await $translate('SCANS:RETRY_PREDICTION_SCAN_MODAL:BODY', { entityValue: scan.name });

    DeleteConfirmation.showModal({}, { closeButtonText, actionButtonText, headerText, bodyText }).then(() => {
      scansService.retryPredict(scan.scanId).then(
        () => {
          $translate('SCANS:RETRY_PREDICTION_SCAN_MODAL:MESSAGE:SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          scan.retryRequested = true;

          getScanActivityData(RESPONSE_SIZE, true);
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    });
  };

  const pauseScan = async scan => {
    const closeButtonText = await $translate('BUTTONS:CANCEL');
    const actionButtonText = await $translate('SCANS:PAUSE_SUB_SCAN_MODAL:SUBMIT');
    const headerText = await $translate('SCANS:PAUSE_SUB_SCAN_MODAL:HEADER');
    const bodyText = await $translate('SCANS:PAUSE_SUB_SCAN_MODAL:BODY', { entityValue: scan.name });

    DeleteConfirmation.showModal({}, { closeButtonText, actionButtonText, headerText, bodyText }).then(() => {
      scansService.pauseScan(scan._id).then(
        () => {
          $translate('SCANS:PAUSE_SUB_SCAN_MODAL:MESSAGE:SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          scan.pauseRequested = true;

          getScanActivityData(RESPONSE_SIZE, true);
        },
        () => {
          //NOTE: the error message has been suppressed intentionally as the temporal solution, @itamar/@ben
          // $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          //   notificationService.error(translation);
          // });
        },
      );
    });
  };

  const resumeScan = async scan => {
    const closeButtonText = await $translate('BUTTONS:CANCEL');
    const actionButtonText = await $translate('SCANS:RESUME_SUB_SCAN_MODAL:SUBMIT');
    const headerText = await $translate('SCANS:RESUME_SUB_SCAN_MODAL:HEADER');
    const bodyText = await $translate('SCANS:RESUME_SUB_SCAN_MODAL:BODY', { entityValue: scan.name });

    DeleteConfirmation.showModal({}, { closeButtonText, actionButtonText, headerText, bodyText }).then(() => {
      scansService.resumeScan(scan._id).then(
        () => {
          $translate('SCANS:RESUME_SUB_SCAN_MODAL:MESSAGE:SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          scan.resumeRequested = true;

          getScanActivityData(RESPONSE_SIZE, true);
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    });
  };

  const archiveScan = async scan => {
    const closeButtonText = await $translate('BUTTONS:CANCEL');
    const actionButtonText = await $translate('SCANS:ARCHIVE_SCAN_MODAL:SUBMIT');
    const headerText = await $translate('SCANS:ARCHIVE_SCAN_MODAL:HEADER');
    const bodyText = await $translate('SCANS:ARCHIVE_SCAN_MODAL:BODY', { entityValue: scan.name });

    DeleteConfirmation.showModal({}, { closeButtonText, actionButtonText, headerText, bodyText }).then(() => {
      scansService
        .archiveScan(scan._id)
        .then(() => {
          $translate('SCANS:ARCHIVE_SCAN_MODAL:SUCCESS').then(translation => {
            notificationService.success(translation);
          });
          getScanActivityData(RESPONSE_SIZE, true);
        })
        .catch(({ status }) => {
          if (status === 409) {
            notificationService.error("Scan can't be archived because it's not in a final phase");
          } else {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          }
        });
    });
  };

  /**
   *   fetch all rows by each column filter value (registerRowsProcessor)
   */
  const scanActivityGridFilter = (scans, filters) => {
    let filteredScans = [];
    const lookup = {};

    const columnsToFilter = Object.keys(filters);

    for (let i = 0; i < scans.length; i++) {
      const item = scans[i];
      const itemId = item['id'];

      const passedVerification = columnsToFilter
        .reduce(
          (aggregatedMatch, col) =>
            (aggregatedMatch = [
              ...aggregatedMatch,
              typeof item[col] != 'undefined' && item[col].toLowerCase().includes(filters[col].toLowerCase()),
            ]),
          [],
        )
        .every(item => item);

      const parentId =
        typeof item['parent_scan_id'] != 'undefined'
          ? scans.filter(scan => scan.id == item['parent_scan_id']).length > 0
            ? item['parent_scan_id']
            : null
          : null;

      if (!Object.prototype.hasOwnProperty.call(lookup, itemId)) {
        lookup[itemId] = { children: [], match: passedVerification, scanId: itemId };
      }

      const treeItem = lookup[itemId];

      if (parentId === null) {
        filteredScans = [...filteredScans, treeItem];
      } else {
        if (passedVerification) {
          if (!Object.prototype.hasOwnProperty.call(lookup, parentId)) {
            lookup[parentId] = { children: [], match: passedVerification, scanId: itemId };
          }

          lookup[parentId]['children'] = [...lookup[parentId]['children'], treeItem];
        }
      }
    }

    return filteredScans;
  };

  /**
   *   aggregate children into visible data and allData with filter (registerRowsProcessor)
   */
  const groupChildrenWithFilter = (source, filter) => {
    return source.reduce(
      (aggregatedData, sourceScan) => {
        const passedVerification = filter.children.findIndex(scan => scan.match && scan.scanId === sourceScan.id);

        if (passedVerification > -1) {
          if (aggregatedData['data'].length < SCAN_ACTIVITY_CHILDREN_VISIBLE_AMOUNT) {
            aggregatedData['data'] = [...aggregatedData['data'], sourceScan];
          }

          aggregatedData['allData'] = [...aggregatedData['allData'], sourceScan];
        }

        return aggregatedData;
      },
      {
        data: [],
        allData: [],
      },
    );
  };

  /**
   *   aggregate children into visible data and allData (registerRowsProcessor)
   */
  const groupChildren = source => {
    return source.reduce(
      (aggregatedData, sourceScan) => {
        if (aggregatedData['data'].length < SCAN_ACTIVITY_CHILDREN_VISIBLE_AMOUNT) {
          aggregatedData['data'] = [...aggregatedData['data'], sourceScan];
        }

        aggregatedData['allData'] = [...aggregatedData['allData'], sourceScan];

        return aggregatedData;
      },
      {
        data: [],
        allData: [],
      },
    );
  };

  /**
   *   setting grid rows by dropdown filter value (groups)
   */
  const getGroupData = (groupName, groups) => {
    let result = [];

    if (groupName === 'all') {
      result = Object.keys(groups).reduce((aggregated, group) => [...aggregated, ...groups[group]], []);
    } else {
      const groupData = groups[groupName];

      result = typeof groupData == 'undefined' ? [] : groupData;
    }

    return result;
  };

  /**
   *   expanded row height calculation based on children rows amount + SCAN_ACTIVITY_EXPANDED_GRID_INDENT
   */
  const getExpandedRowHeight = row => {
    const visibleChildrenAmount = row['children']['data'].length;
    const allChildrenAmount = row['children']['allData'].length;

    let height;

    if (allChildrenAmount > 0) {
      if (visibleChildrenAmount === SCAN_ACTIVITY_CHILDREN_VISIBLE_AMOUNT) {
        height = SCAN_ACTIVITY_EXPANDED_ROW_MAX_HEIGHT;
      } else {
        height = SCAN_ACTIVITY_ROW_HEIGHT * visibleChildrenAmount + SCAN_ACTIVITY_EXPANDED_GRID_INDENT;
      }
    } else {
      height = SCAN_ACTIVITY_ROW_HEIGHT * visibleChildrenAmount + SCAN_ACTIVITY_EXPANDED_GRID_INDENT;
    }

    return height;
  };

  /**
   *   check whether the scan/subScan can be stopped
   */
  const isScanAbleToStop = scan => {
    let isAbleToStop = false;

    const { parent_scan_id, state, type, isStopAvailable, children = {} } = scan;

    if (UNSUPPORTED_CONNECTORS_STOP_BUTTON.includes(scan.connectorType)) {
      return false;
    }
    if (parent_scan_id) {
      isAbleToStop =
        scan.ds_connection_name !== null &&
        !STOP_SCAN_BTN_VISIBILITY_CONDITIONS.stateIsNot.includes(state) &&
        STOP_SCAN_BTN_VISIBILITY_CONDITIONS.typeIs.includes(type);
    } else {
      if (isParentScanQueued(state)) {
        isAbleToStop = true;
      } else {
        const subScans = children.allData || [];

        for (const subScan of subScans) {
          if (isStopAvailable && (isScanAbleToStop(subScan) || canParentStopBecauseOfNoStopChild(subScan))) {
            isAbleToStop = true;
            break;
          }
        }
      }
    }

    return isAbleToStop;
  };

  const isParentScanQueued = state => state === SCAN_STATUS_MAPPING.QUEUED;

  /**
   *   check whether the scan/subScan can be launched again
   */
  const isScanAbleToRetry = scan => {
    let isAbleToRetry = false;

    const { state, type, parent_scan_id, scan_parts_counter = {}, children = {}, totalFailedCollections = 0 } = scan;

    if (parent_scan_id) {
      if (scan.type === 'prediction_scan') {
        isAbleToRetry =
          RETRY_SCAN_BTN_VISIBILITY_CONDITIONS.stateIs.includes(state) &&
          RETRY_SCAN_BTN_VISIBILITY_CONDITIONS.typeIs.includes(type);
      } else
        isAbleToRetry =
          RETRY_SCAN_BTN_VISIBILITY_CONDITIONS.stateIs.includes(state) &&
          RETRY_SCAN_BTN_VISIBILITY_CONDITIONS.typeIs.includes(type) &&
          (totalFailedCollections > 0 || scan_parts_counter.Failed > 0);
    } else {
      if (RETRY_SCAN_BTN_VISIBILITY_CONDITIONS.stateIs.includes(state)) {
        const subScans = children.allData || [];

        for (const subScan of subScans) {
          if (isScanAbleToRetry(subScan) && subScan.type !== 'prediction_scan') {
            isAbleToRetry = true;
            break;
          }
        }
      }
    }

    return isAbleToRetry;
  };

  /**
   *   check whether the scan/subScan can be resumed
   */
  const isScanAbleToResume = scan => {
    let isAbleToResume = false;

    const { state, type, children = {}, parent_scan_id, enumerationState, scan_parts_counter = {} } = scan;
    const scanIsPausedOrStopped =
      RESUME_SCAN_BUTTON.visibleConditions.includes(state) &&
      (scan_parts_counter.Stopped > 0 ||
        scan_parts_counter.Aborted > 0 ||
        scan_parts_counter.New > 0 ||
        scan.no_parts_pause);
    const enumerationIsComplete = FINISHED_SCAN_STATES.includes(enumerationState);

    if (parent_scan_id) {
      isAbleToResume = scanIsPausedOrStopped && RESUME_SCAN_BUTTON.scanTypes.includes(type) && enumerationIsComplete;
    } else {
      if (RESUME_SCAN_BUTTON.visibleConditions.includes(state)) {
        const subScans = children.allData || [];

        for (const subScan of subScans) {
          if (isScanAbleToResume(subScan)) {
            isAbleToResume = true;
            break;
          }
        }
      }
    }

    return isAbleToResume;
  };

  /**
   *   check whether the scan/subScan can be paused
   */
  const isScanAbleToPause = scan => {
    let isAbleToPause = false;

    const { state, type, children = {}, parent_scan_id, pauseRequested, enumerationState } = scan;

    const scanIsPending = state === SCAN_STATUS_MAPPING.PENDING;
    const parentScanIsWorking = PAUSE_SCAN_BUTTON.parentConditions.includes(state);
    const notPaused = pauseRequested != null ? pauseRequested === false : true;
    const scanIsInProgress =
      (state === SCAN_STATUS_MAPPING.STARTED || state === SCAN_STATUS_MAPPING.IN_PROGRESS) &&
      enumerationState !== SCAN_STATUS_MAPPING.INITIATING_RETRY &&
      !PAUSE_SCAN_BUTTON.hideButtonConditions.includes(state) &&
      notPaused;

    if (parent_scan_id) {
      isAbleToPause = PAUSE_SCAN_BUTTON.scanTypes.includes(type) && (scanIsPending || scanIsInProgress);
    } else {
      if (parentScanIsWorking) {
        const subScans = children.allData || [];

        for (const subScan of subScans) {
          if (isScanAbleToPause(subScan)) {
            isAbleToPause = true;
            break;
          }
        }
      }
    }

    return isAbleToPause;
  };

  /**
   *   check whether the scan can be archived
   */
  const isScanAbleToArchive = scan => {
    let isAbleToArchive = false;

    const { state, parent_scan_id } = scan;

    if (!parent_scan_id) {
      isAbleToArchive = ARCHIVE_SCAN_BTN_VISIBILITY_CONDITIONS.stateIs.includes(state);
    }

    return isAbleToArchive;
  };

  const canParentStopBecauseOfNoStopChild = subScan => {
    return (
      !STOP_SCAN_BTN_VISIBILITY_CONDITIONS.stateIsNot.includes(subScan.state) &&
      STOP_SCAN_BTN_VISIBILITY_CONDITIONS.allowStopFromParentOnly.includes(subScan.type)
    );
  };

  const isGridRestorationRequired = () => {
    return expandedRowsIdx.length > 0 && gridRestoreRequired && this.scanActivityGridCurrFilters === null;
  };

  this.accordionGroups = {
    profiles: {
      isCollapsed: !(sessionStorageService.get(STORAGE_IS_SCAN_PROFILE_EDITED_FLAG) || false),
    },
    activity: {
      isCollapsed: false,
    },
  };

  sessionStorageService.remove(STORAGE_IS_SCAN_PROFILE_EDITED_FLAG);

  this.scanActivityFilterOptions = [];

  this.loadingStates = SCANS_PROGRESS_LOADING;

  this.scanActivityGroupsMapping = {
    scans: ['full_scan', 'lineageScan', 'correlation_scan', 'labeling'],
    jit_scan: ['jit_scan'],
    investigation_scan: ['investigation_scan'],
    others: [],
  };

  this.scanActivityGroups = {
    scans: [],
    jit_scan: [],
    investigation_scan: [],
    others: [],
  };

  this.lastUpdate = dateTimeService.formatDate(new Date());

  this.progressBarSupport = {};

  this.lastUpdateTranslate = {
    entityValue:
      typeof this.lastUpdate == 'object'
        ? this.lastUpdate.toISOString().replace(/^"(.*)"$/, '$1')
        : this.lastUpdate.replace(/^"(.*)"$/, '$1'),
  };

  this.piiFindingsCount = 0;
  this.piiFindingsCountTranslate = {
    entityValue: this.piiFindingsCount,
  };

  this.scanActivity = {};
  this.scanProfiles = [];

  this.scanActivityGridDataLoading = false;
  this.scanProfilesGridDataLoading = false;

  this.scanActivityFilteredState = {};

  this.scanActivityGridConfig = Object.assign(
    {
      enableSorting: true,
      enableFiltering: true,
      enablePaginationControls: true,
      paginationPageSizes: [25, 50, 75, 100],
      paginationPageSize: 50,
      appScopeProvider: this,
      expandableRowTemplate: 'scanActivityChildGrid.template.html',
      enableExpandableRowHeader: false,
      enableExpandable: true,
      expandableRowScope: this,
      showExpandAllButton: true,
      rowHeight: SCAN_ACTIVITY_ROW_HEIGHT,
      data: [],
    },
    angular.copy(SCAN_ACTIVITY_BASIC_OPTIONS),
  );

  this.scanProfilesGridConfig = {
    enableColumnMenus: false,
    enableSorting: true,
    enableFiltering: true,
    enablePaginationControls: true,
    paginationPageSizes: [25, 50, 75, 100],
    paginationPageSize: 50,
    appScopeProvider: this,
    rowHeight: SCAN_PROFILE_ROW_HEIGHT,
    data: [],
    columnDefs: [
      {
        name: 'fake',
        headerCellTemplate: '<div></div>',
        cellTemplate: '<div></div>',
        minWidth: 40,
        width: 40,
        enableFiltering: false,
        enableSorting: false,
      },
      {
        name: 'Name',
        field: 'name',
        cellEditableCondition: false,
        cellTooltip: row => (typeof row.entity.name != 'undefined' ? `Name: ${row.entity.name}` : ''),
        cellTemplate:
          '<div tabindex="0" class="ui-grid-cell-contents" aria-label="Name {{row.entity.name}}">{{row.entity.name}}</div>',
        minWidth: 200,
        maxWidth: 300,
      },
      {
        name: 'Description',
        field: 'description',
        cellTooltip: row =>
          typeof row.entity.description != 'undefined' ? `Description: ${row.entity.description}` : '',
        cellTemplate:
          '<div tabindex="0" class="ui-grid-cell-contents" aria-label="Description {{row.entity.description}}">{{row.entity.description}}</div>',
        cellEditableCondition: false,
        minWidth: 300,
      },
      {
        name: 'Type',
        field: 'type',
        cellTooltip: row => (typeof row.entity.type != 'undefined' ? `Type: ${row.entity.type}` : ''),
        minWidth: 100,
        maxWidth: 150,
        cellTemplate: `<div tabindex="0" aria-label="Type {{row.entity.type}}" class='scan-type-cell'><i class="ac-search-icon fa fa-bolt" ng-show="row.entity.scanType === 'hyper_scan'"></i><span>{{row.entity.type}}</span><div>`,
      },
      {
        name: 'Schedule',
        field: 'scheduleStr',
        cellEditableCondition: false,
        cellClass: 'cron-cell',
        cellTooltip: row => (typeof row.entity.scheduleStr != 'undefined' ? `Schedule: ${row.entity.scheduleStr}` : ''),
        cellTemplate:
          '<div tabindex="0" class="ui-grid-cell-contents schedule-str" aria-label="Schedule {{row.entity.scheduleStr}}">{{row.entity.scheduleStr}}</div>',
        minWidth: 150,
        maxWidth: 250,
      },
      {
        name: 'Scheduled',
        field: 'active',
        editableCellTemplate: 'ui-grid/dropdownEditor',
        cellTemplate: `<div tabindex="0" aria-label="Scheduled {{row.entity.active}}" class="ui-grid-cell-contents" ng-bind="grid.appScope.parseToBoolean(row.entity.active)"></div>`,
        editDropdownValueLabel: 'enabled',
        cellClass: 'enable-cell-outer',
        minWidth: 100,
        maxWidth: 150,
        editDropdownOptionsArray: [
          {
            id: true,
            enabled: 'Yes',
          },
          {
            id: false,
            enabled: 'No',
          },
        ],
      },
      {
        name: 'Action',
        field: '',
        cellEditableCondition: false,
        headerCellTemplate: '<div></div>',
        cellTemplate: getScanProfileActionsCellTemplate(),
        cellClass: 'actions-cell',
        minWidth: 100,
        width: 100,
      },
    ],
  };

  this.scanProfilesGridApi = null;

  this.scanProfilesGridConfig.onRegisterApi = gridApi => {
    this.scanProfilesGridApi = gridApi;
  };

  this.scanActivityGridApi = null;
  this.scanActivityGridCurrFilters = null;

  this.scanActivityGridConfig.onRegisterApi = gridApi => {
    this.scanActivityGridApi = gridApi;

    this.scanActivityGridApi.core.on.rowsRendered(null, () => {
      this.scanActivityGridDataLoading = true;

      const { grid } = this.scanActivityGridApi;

      for (const row of grid.rows) {
        row.entity.isAbleToStop = isScanAbleToStop(row.entity);
        row.entity.isAbleToRetry = isScanAbleToRetry(row.entity);
        row.entity.isAbleToResume = isScanAbleToResume(row.entity);
        row.entity.isAbleToPause = isScanAbleToPause(row.entity);
        row.entity.isAbleToArchive = isScanAbleToArchive(row.entity);

        if (isGridRestorationRequired() && expandedRowsIdx.indexOf(row['entity']['id']) > -1 && row['visible']) {
          row['expandedRowHeight'] = getExpandedRowHeight(row['entity']);
          this.scanActivityGridApi.expandable.expandRow(row['entity']);
        }
      }

      if (this.scanActivityGridCurrFilters !== null) {
        if (Object.keys(this.scanActivityGridCurrFilters).length > 0) {
          const visibleRowsAmount = this.scanActivityGridApi.core.getVisibleRows(grid);

          let expandedRowsAmount = 0;

          for (const row of grid.rows) {
            if (expandedRowsAmount === visibleRowsAmount) break;

            if (row['visible']) {
              if (row['entity']['children']['data'].length > 0) {
                this.scanActivityGridApi.expandable.expandRow(row['entity']);
                expandedRowsAmount++;
              }
            } else {
              continue;
            }
          }
        } else {
          this.scanActivityGridCurrFilters = null;
          this.scanActivityGridApi.expandable.collapseAllRows();
        }
      }

      gridRestoreRequired = false;

      this.scanActivityGridDataLoading = false;
    });

    this.scanActivityGridApi.core.on.filterChanged(null, () => {
      const { columns } = this.scanActivityGridApi.grid;

      const filters = {};

      for (let i = 0, len = columns.length; i < len; i++) {
        const column = columns[i];

        if (
          typeof column['colDef']['field'] != 'undefined' &&
          column['field'] !== '' &&
          typeof column['filters'][0]['term'] != 'undefined'
        ) {
          if (column['filters'][0]['term'] !== '' && column['filters'][0]['term'] !== null) {
            filters[column['field']] = column['filters'][0]['term'];
          }
        }
      }

      this.scanActivityGridCurrFilters = filters;
    });

    this.getProgressBarLabel = state => getProgressBarLabel(state, this.translations);

    // NOTE: refactoring required
    this.scanActivityGridApi.grid.registerRowsProcessor(rows => {
      if (rows.length > 0 && this.scanActivityGridCurrFilters !== null) {
        const allRows = this.scanActivityGridApi.grid.rows;

        const columnsToFilter = Object.keys(this.scanActivityGridCurrFilters);

        if (columnsToFilter.length > 0) {
          this.scanActivityGridDataLoading = true;

          this.scanActivityFilteredState = scanActivityGridFilter(this.scanActivity, this.scanActivityGridCurrFilters);

          allRows.forEach(row => {
            const rowEntity = row['entity'];
            const filteredRowState = this.scanActivityFilteredState.find(state => state.scanId === rowEntity['id']);

            if (typeof filteredRowState != 'undefined') {
              if (filteredRowState['match']) {
                row['visible'] = true;

                // NOTE: recursive function required
                const { data, allData } = groupChildren(row['entity']['children']['dataStorage']);
                row['entity']['children']['data'] = data.sort(sortByDateOfCreation);
                row['entity']['children']['allData'] = allData.sort(sortByDateOfCreation);
                row['expandedRowHeight'] = getExpandedRowHeight(row['entity']);
              } else {
                if (filteredRowState['children'].length > 0) {
                  row['visible'] = true;

                  // NOTE: recursive function required
                  const { data, allData } = groupChildrenWithFilter(
                    row['entity']['children']['dataStorage'],
                    filteredRowState,
                  );
                  row['entity']['children']['data'] = data.sort(sortByDateOfCreation);
                  row['entity']['children']['allData'] = allData.sort(sortByDateOfCreation);
                  row['expandedRowHeight'] = getExpandedRowHeight(row['entity']);
                } else {
                  row['visible'] = false;

                  row['entity']['children']['data'] = [];
                  row['entity']['children']['allData'] = [];
                }
              }
            }
          });
        } else {
          allRows.forEach(row => {
            const { data, allData } = row['entity']['children']['dataStorage'].reduce(
              (aggregatedData, scan) => {
                if (aggregatedData['data'].length < SCAN_ACTIVITY_CHILDREN_VISIBLE_AMOUNT) {
                  aggregatedData['data'] = [...aggregatedData['data'], scan];
                }

                aggregatedData['allData'] = [...aggregatedData['allData'], scan];

                return aggregatedData;
              },
              {
                data: [],
                allData: [],
              },
            );

            row['entity']['children']['data'] = data;
            row['entity']['children']['allData'] = allData;

            row['visible'] = true;
          });

          this.scanActivityFilteredState = {};
        }

        rows = allRows.sort(sortGridEntityByDateOfCreation);
      }

      return rows;
    }, 200);
  };

  this.cronEditMode = {};
  this.cronEditModeGlobal = false;
  this.cronCurrentEditableId = null;
  this.cronEntityTmpString = '';

  this.translations = [];

  this.activityExportButtonItems = [];

  this.updateScansInterval = $interval(updateScansIntervalFunc, INTERVAL_VALUE);

  this.isSmallID = smallIdLicenseService.isSmallIDLicense();

  this.$onInit = () => {
    $rootScope.pageHeaderService.setTitle({
      pageTitle: 'Scans',
    });

    this.isScanProfilesPermitted = isPermitted(SCANS_PERMISSIONS.READ_SCAN_PROFILES.name);
    this.isEditScanProfilesPermitted = isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name);
    this.isCreateScanProfilesPermitted = isPermitted(SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name);
    this.isDeleteScanProfilesPermitted = isPermitted(SCANS_PERMISSIONS.DELETE_SCAN_PROFILES.name);
    this.isRunScanProfilesPermitted = isPermitted(SCANS_PERMISSIONS.RUN_SCAN_PROFILES.name);
    this.scanProfilesAllActionsNotPermitted =
      !this.isEditScanProfilesPermitted && !this.isRunScanProfilesPermitted && !this.isDeleteScanProfilesPermitted;

    this.isScanActivityPermitted = isPermitted(SCANS_PERMISSIONS.READ_SCAN_ACTIVITY.name);
    this.isEditScanActivityPermitted = isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_ACTIVITY.name);

    $translate(TRANSLATION_REQUIRED).then(translations => {
      $rootScope.$broadcast('changePage', translations['SCANS'], false, false);
      $stateParams.showLicenseQuotaMessage !== false && showQuotaMessagesIfNeededAndReturnSummary();
      this.translations = Object.assign({}, translations);

      if (isPermitted(REPORTS_PERMISSIONS.DOWNLOAD_FAILED_OBJECT.name)) {
        this.activityExportButtonItems = [
          {
            name: translations['SCANS:SCAN_ACTIVITY:FAILED_OBJECT_REPORT'],
            action: 'onFailedObjectReportClick',
          },
        ];
      }

      this.scanActivityFilterOptions = [
        {
          value: 'all',
          fetchFilter: 'scans',
          label: translations['SCANS:SCAN_ACTIVITY:FILTER:SCANS'],
        },
        {
          value: 'jit_scan',
          fetchFilter: 'dsar',
          label: translations['SCANS:SCAN_ACTIVITY:FILTER:DSAR'],
        },
        {
          value: 'investigation_scan',
          fetchFilter: 'investigation',
          label: translations['SCANS:SCAN_ACTIVITY:FILTER:INVESTIGATION_SCAN'],
        },
      ];

      this.scanActivitySelectedOption = this.scanActivityFilterOptions[0];

      this.isScanActivityPermitted && getScanActivityData(RESPONSE_SIZE);
      this.isScanProfilesPermitted && getScanProfilesData();
      getPiiFindings();
    });
  };

  this.onRowExpansionToggled = row => {
    const index = expandedRowsIdx.findIndex(id => id === row['entity']['id']);

    if (index > -1) {
      expandedRowsIdx = [...expandedRowsIdx.slice(0, index), ...expandedRowsIdx.slice(index + 1)];
    } else {
      expandedRowsIdx = [...expandedRowsIdx, row['entity']['id']];
    }

    row['expandedRowHeight'] = getExpandedRowHeight(row['entity']);
    this.scanActivityGridApi.expandable.toggleRowExpansion(row['entity']);
  };

  this.onAccordionSectionToggle = group => {
    this.accordionGroups[group]['isCollapsed'] = !this.accordionGroups[group]['isCollapsed'];
  };

  this.onFailedObjectReportClick = async () => {
    const closeButtonText = await $translate('BUTTONS:CANCEL');
    const actionButtonText = await $translate('BUTTONS:DOWNLOAD');
    const headerText = await $translate('SIDEBAR:REPORTS:UNSTRUCTURED_CLASSIFICATION_MODAL:HEADER');
    const bodyText = await $translate('SIDEBAR:REPORTS:UNSTRUCTURED_CLASSIFICATION_MODAL:BODY');

    const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

    DeleteConfirmation.showModal({}, modalOptions).then(() => {
      getFailedObjectReport();
    });
  };

  this.scanInsightsOnClose = () => {
    this.scanInsightsReportId = undefined;
    this.scanInsightsData = undefined;

    $timeout(() => {
      this.scanActivityGridApi.core.handleWindowResize();
    }, 10);
  };

  this.onShowScanActivityInfo = row => {
    // scan insights available only for specific scan types and is not available backward,
    if (isInsightsType(row.type)) {
      const scanId = row.id;
      scansService
        .getScanInsights(scanId)
        .then(result => {
          this.scanInsightsData = undefined;
          this.scanInsightsReportId = undefined;
          const { scanInsight } = result;
          if (scanInsight && scanInsight.scanProfile) {
            const { scanSummary, scanProfile } = scanInsight;
            const { attributes, classifications } = scanInsight.attributes || {};
            this.scanInsightsReportId = scanId;
            this.scanInsightsData = {
              scanProfile,
              scanSummary,
              attributes: {
                attributes,
                classifications,
              },
            };

            $timeout(() => {
              this.scanActivityGridApi.core.handleWindowResize();
            }, 10);
          } else {
            // if the response is OK but there is NO scanProfile data available,
            // it means API returned an empty object,
            // because it is either
            //   old scan report id (before scan-insights implementation)
            //   or insights report that is not ready yet
            showScanActivityInfoModal(row, $uibModal, this.translations, $translate, isJitParentScan, scansService);
          }
        })
        .catch(err => {
          this.scanInsightsData = undefined;
          this.scanInsightsReportId = undefined;
          if (err) {
            window.console.error('/scans/scan-insights API returned an error', err);
          }
        });
    } else {
      this.scanInsightsReportId = undefined;
      this.scanInsightsData = undefined;
      showScanActivityInfoModal(row, $uibModal, this.translations, $translate, isJitParentScan, scansService);
      // showScanActivityInfoModal(row, $uibModal, this.translations, $translate, isJitParentScan);
    }
  };

  this.onChildGridShowMoreClick = row => {
    $uibModal.open({
      animation: true,
      size: 'lg',
      scope: $scope,
      windowClass: 'scan-activity-children-modal',
      templateUrl: 'scanActivityChildrenModal.template.html',
      controllerAs: '$modalCtrl',
      controller: [
        'row',
        '$uibModalInstance',
        function (row, $uibModalInstance) {
          // i would rather copy the specific props into this child component instead inheriting the parent scope
          // to avoid any mutations for the parent controller (@baryosef)
          const $scansComponentCtrl = $scope.$ctrl;
          this.isScanProfilesPermitted = $scansComponentCtrl.isScanProfilesPermitted;
          this.isEditScanProfilesPermitted = $scansComponentCtrl.isEditScanProfilesPermitted;
          this.isCreateScanProfilesPermitted = $scansComponentCtrl.isCreateScanProfilesPermitted;
          this.isDeleteScanProfilesPermitted = $scansComponentCtrl.isDeleteScanProfilesPermitted;
          this.isRunScanProfilesPermitted = $scansComponentCtrl.isRunScanProfilesPermitted;
          this.isScanActivityPermitted = $scansComponentCtrl.isScanActivityPermitted;
          this.isEditScanActivityPermitted = $scansComponentCtrl.isEditScanActivityPermitted;
          this.scanProfilesAllActionsNotPermitted = $scansComponentCtrl.scanProfilesAllActionsNotPermitted;
          this.translations = $scansComponentCtrl.translations;

          this.loadingStates = SCANS_PROGRESS_LOADING;
          this.getProgressBarLabel = state => getProgressBarLabel(state, this.translations);

          const rowId = row.id;

          const scanActivityDataWatcher = $scope.$watch(
            () => $scope.$ctrl.scanActivityGridConfig['data'],
            data => {
              const row = data.find(scan => scan.id === rowId);

              if (typeof row != 'undefined') {
                this.gridConfig.data = row.children.allData;
              }
            },
          );

          this.header = row.name;
          this.isOverlapCoverEnabled = false;

          $uibModalInstance['setOverlapCoverEnabled'] = () => {
            this.isOverlapCoverEnabled = true;
          };

          $uibModalInstance['setOverlapCoverDisabled'] = () => {
            this.isOverlapCoverEnabled = false;
          };

          this.gridConfig = Object.assign(
            {
              enableSorting: true,
              enableFiltering: true,
              showHeader: true,
              enablePaginationControls: true,
              enablePaging: true,
              paginationPageSizes: [25, 50, 75, 100],
              paginationPageSize: 50,
              data: row.children.allData,
              enableExpandableRowHeader: false,
              expandableRowTemplate: 'scanActivityChildGrid.template.html',
              expandableRowHeight: getExpandedRowHeight(row),
              appScopeProvider: this,
              expandableRowScope: this,
              rowHeight: SCAN_ACTIVITY_ROW_HEIGHT,
              showMoreVisibleLimit: SCAN_ACTIVITY_CHILDREN_VISIBLE_AMOUNT,
              onChildGridShowMoreClick: row => this.onChildGridShowMoreClick(row),
            },
            angular.copy(SCAN_ACTIVITY_BASIC_OPTIONS),
          );

          this.gridConfig.columnDefs[0]['visible'] = false; // in order to hide +/- column

          this.gridApi = null;

          this.gridConfig.onRegisterApi = api => {
            this.gridApi = api;
          };

          this.onHideButtonClick = () => {
            scanActivityDataWatcher();
            $uibModalInstance.close();
          };

          this.onCloseIconClick = () => {
            scanActivityDataWatcher();
            $uibModalInstance.close();
          };

          this.onShowScanActivityInfo = row => {
            showScanActivityInfoModal(
              row,
              $uibModal,
              this.translations,
              $translate,
              isJitParentScan,
              $uibModalInstance,
            );
          };

          this.onStopScan = scan => {
            stopScan(scan);
          };

          this.onRetryScan = scan => {
            if (scan.retryRequested) return;

            retryScan(scan);
          };

          this.onPauseScan = scan => {
            if (scan.pauseRequested) return;

            pauseScan(scan);
          };

          this.onResumeScan = scan => {
            if (scan.resumeRequested) return;

            resumeScan(scan);
          };
        },
      ],
      resolve: {
        row: () => row,
      },
    });
  };

  this.onStopScan = scan => {
    stopScan(scan);
  };

  this.onRetryScan = scan => {
    if (scan.retryRequested) return;

    if ('prediction_scan' === scan.type) {
      retryPredict(scan);
    } else {
      retryScan(scan);
    }
  };

  this.onPauseScan = scan => {
    if (scan.pauseRequested) return;

    pauseScan(scan);
  };

  this.onResumeScan = scan => {
    if (scan.resumeRequested) return;

    resumeScan(scan);
  };

  this.onArchiveScan = scan => {
    archiveScan(scan);
  };

  this.onScanActivityFilterChanged = option => {
    getScanActivityData(RESPONSE_SIZE, true);
    this.scanActivityGridConfig['data'] = getGroupData(option['value'], this.scanActivityGroups).sort(
      sortByDateOfCreation,
    );
  };

  this.onDeleteScanProfile = async scanProfile => {
    const { _id } = scanProfile;

    showDeleteACEntityDialog({
      ids: [_id],
      entityNameSingular: 'scan profile',
      entityType: EntityType.SCAN_PROFILE,
    })
      .then(shouldDelete => {
        if (shouldDelete) {
          scansService.deleteScanProfile(_id).then(finishedState => {
            if (finishedState.data.deleted === 'Failed') {
              $translate('SCANS:RUN_SCAN_MODAL:MESSAGE:CANNOT DELETE WHILE RUNNING').then(translation => {
                notificationService.error(translation);
              });
            } else {
              $translate('API:MESSAGE:COMMON_DELETE_SUCCESS').then(translation =>
                notificationService.success(translation),
              );
            }
            getScanProfilesData();
          });
        }
      })
      .catch(() =>
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        }),
      );
  };
  this.onEditScanProfile = data => {
    $state.go('editScanProfile', { data });
  };

  this.onCreateScanProfile = () => {
    $state.go('createScanProfile');
  };

  this.onRunScanNow = async scanProfile => {
    const scanParams = {
      scanType: scanProfile.scanType,
      scanProfileName: scanProfile.name,
      scanOrigin: 'Invoked manually',
    };

    const { allEnabledDs: isAllEnabledDS } = scanProfile;
    const numberOfDS = isAllEnabledDS ? 0 : scanProfile.dataSourceList?.length;

    const isEmptyIDSourcesList =
      !scanProfile.allEnabledIdSor && !(scanProfile.idsorList && scanProfile.idsorList.length > 0);
    trackEventScansView(ScansUITrackingEvent.SCAN_OLD_UI_ACTION_CLICK, {
      actionName: ScanProfileActionType.RUN_SCAN,
      ...scanProfile,
    });

    runScanNow(scanParams, { isEmptyIDSourcesList, isAllEnabledDS, numberOfDS });
  };

  this.parseToBoolean = value => (value ? 'Yes' : 'No');

  this.onActivityExportButtonItemClick = item => {
    if (typeof item.action != 'undefined' && typeof this[item.action] == 'function') {
      this[item.action]();
    }
  };

  $scope.$on('$destroy', () => {
    $interval.cancel(this.updateScansInterval);
  });

  function isInsightsType(type) {
    return Object.values(SCANS_INSIGHTS_TYPES).includes(type);
  }

  function isJitParentScan(scan) {
    return scan && scan.type === JIT_SCAN_TYPE && !scan.parent_scan_id;
  }
}

app.component('scans', {
  template,
  controller: ScansComponentController,
});
