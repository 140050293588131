import React from 'react';
import { noop } from 'lodash';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { getFixedT } from '../../translations';
import { PrimaryButton, TertiaryButton, BigidBody1 } from '@bigid-ui/components';
import styled from '@emotion/styled';

const Content = styled(BigidBody1)({
  whiteSpace: 'pre-line',
});

export const showSampleDialog = (dsName: string): Promise<boolean> => {
  const t = getFixedT(`CuratedDataSources.sampleDialog`);

  return new Promise(resolve => {
    openSystemDialog({
      title: t('title', { dsName: dsName?.toLowerCase() }),
      onClose: noop,
      buttons: [
        {
          text: t('close'),
          onClick: () => resolve(false),
          component: TertiaryButton,
          isClose: true,
          dataAid: 'close',
        },
        {
          text: t('sampleButton'),
          onClick: () => resolve(true),
          component: PrimaryButton,
          isClose: true,
          dataAid: 'sampleButton',
        },
      ],
      content: () => <Content>{t('description') as string}</Content>,
      borderTop: true,
      maxWidth: 'xs',
    });
  });
};
