import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import {
  BigidDialog,
  PrimaryButton,
  BigidColorsV2,
  BigidTextField,
  BigidBody1,
  BigidButtonIcon,
  TertiaryButton,
  BigidTooltip,
} from '@bigid-ui/components';
import { LegalEntityLevel } from './LegalEntitiesTypes';
import { BigidAddIcon, BigidDeleteIcon } from '@bigid-ui/icons';
import { notificationService } from '../../services/notificationService';
import { getLevels, updateLevels } from './LegalEntitiesService';
import { LegalEntitiesTrackingEvents, trackLegalEntities } from './utils/analytics';

const FormSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 12px;
`;

const LevelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 8px;
`;

const LevelInputWrapper = styled('div')(({ index }: { index: number }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  marginLeft: `${index * 37}px`,
  position: 'relative',
}));

const LinkBetweenLevels = styled.div`
  width: 16px;
  height: 30px;
  position: absolute;
  border: 1px solid transparent;
  left: -15px;
  top: -8px;
  border-bottom-left-radius: 8px;
  border-left-color: ${BigidColorsV2.gray[400]};
  border-bottom-color: ${BigidColorsV2.gray[400]};
`;

const AddLevelButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 44px;
`;

const DeleteButtonWrapper = styled.div`
  min-width: 32px;
`;

interface LegalEntitiesEditHierarchyLevelsDialogProps {
  isOpen: boolean;
  onSave?: (props: { updatedParent: boolean }) => void;
  onClose?: () => void;
}
type Errors = {
  generic?: string;
  [key: number]: string;
};

const DUPLICATES_ERROR_MESSAGE = 'The label you entered is already in use.';
const EMPTY_ERROR_MESSAGE = "Label can't be empty";
const ERROR_INITIAL_STATE: Errors = {};
const MAX_LEVELS = 5;

export const LegalEntitiesEditLevelsDialog = ({
  isOpen,
  onSave,
  onClose,
}: LegalEntitiesEditHierarchyLevelsDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [levels, setLevels] = useState<LegalEntityLevel[]>();
  const [errors, setErrors] = useState<Errors>(ERROR_INITIAL_STATE);
  const levelsTempState = useRef([]);
  const shouldShowAddLevelButton = levels?.length < MAX_LEVELS;
  const validate = (index: number) => errors[index];

  useEffect(() => {
    if (!isOpen) return;

    (async () => {
      const levels = await fetchLevels();
      setLevels(levels);
      levelsTempState.current = levels;
    })();
  }, [isOpen]);

  const handleOnClose = (event?: React.MouseEvent) => {
    onClose?.();
    setLevels([]);
    levelsTempState.current = [];
    if (event) {
      trackLegalEntities(LegalEntitiesTrackingEvents.EDIT_HIERARCHY_CANCEL_CLICK);
    }
  };

  const handleOnSave = async () => {
    try {
      setIsLoading(true);
      const errors = validateLevels(levelsTempState.current);
      setErrors(errors);
      if (Object.keys(errors).length) {
        return;
      }
      trackLegalEntities(LegalEntitiesTrackingEvents.EDIT_HIERARCHY_SAVE_CLICK);
      const payload = { levels: levelsTempState.current.map(level => ({ name: level.name })) };
      await updateLevels(payload);
      handleOnClose();
      onSave?.({ updatedParent: true });
    } catch (err) {
      const errorMessage = err.response.data.errors?.length
        ? err.response.data.errors[0]?.message
        : err.response.data.message;
      setErrors(errors => ({ ...errors, generic: errorMessage }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLevel = () => {
    levelsTempState.current = levelsTempState.current.slice(0, -1);
    setLevels(levelsTempState.current.slice());
  };

  const addNewLevel = () => {
    levelsTempState.current = [...levelsTempState.current, { name: `Level ${++levelsTempState.current.length}` }];
    setLevels(levelsTempState.current.slice());
  };

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    levelsTempState.current[index] = { ...levelsTempState.current[index], name: event.target.value };
    setErrors(errors => (validate(index) ? validateLevels(levelsTempState.current) : errors));
  };

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={handleOnClose}
      borderBottom
      maxWidth="xs"
      title="Edit hierarchy levels"
      showCloseIcon
      isLoading={isLoading}
      buttons={[
        {
          component: TertiaryButton,
          onClick: handleOnClose,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: handleOnSave,
          text: 'Save changes',
        },
      ]}
    >
      <FormSectionWrapper>
        <BigidBody1>{'You can rename hierarchy levels and add up to 5 levels.'}</BigidBody1>
        <LevelsWrapper>
          {levels?.map((level, i) => (
            <LevelInputWrapper key={i} index={i}>
              {i > 0 && <LinkBetweenLevels />}
              <BigidTextField
                placeholder="Enter Level Name"
                size="large"
                onChange={event => onInputChange(event, i)}
                defaultValue={level.name}
                name={level.name}
                errorMessage={validate(i)}
                isError={!!validate(i)}
              />
              <BigidTooltip
                placement="top"
                title={
                  level.hasEntities ? 'Cannot delete this level while\n legal entities are attached' : 'Delete level'
                }
              >
                <DeleteButtonWrapper>
                  {i === levels.length - 1 && (
                    <BigidButtonIcon
                      disabled={level.hasEntities}
                      icon={BigidDeleteIcon}
                      size="small"
                      onClick={() => handleDeleteLevel()}
                    />
                  )}
                </DeleteButtonWrapper>
              </BigidTooltip>
            </LevelInputWrapper>
          ))}
        </LevelsWrapper>
        {errors.generic && <BigidBody1 color="red">{errors.generic}</BigidBody1>}
        {shouldShowAddLevelButton && (
          <AddLevelButtonWrapper>
            <TertiaryButton startIcon={<BigidAddIcon />} onClick={addNewLevel} size="medium" text="Add level" />
          </AddLevelButtonWrapper>
        )}
      </FormSectionWrapper>
    </BigidDialog>
  );
};

const fetchLevels = async (): Promise<LegalEntityLevel[]> => {
  try {
    const { legalEntitiesLevel } = await getLevels('', true);
    return legalEntitiesLevel;
  } catch (error) {
    notificationService.error(`Failed to fetch Levels`);
    console.error(`Failed to fetch Levels: ${JSON.stringify(error?.response)}`);
    return [];
  }
};

const validateLevels = (levels: LegalEntityLevel[]) => {
  const levelNames = levels.map(level => level.name);
  return levelNames.reduce((acc, level, index) => {
    if (!level?.trim()) {
      acc[index] = EMPTY_ERROR_MESSAGE;
    } else if (levelNames.indexOf(level) !== index) {
      const currentLevel = levelNames[index];
      levelNames.forEach((level, index) => {
        if (level === currentLevel) {
          acc[index] = DUPLICATES_ERROR_MESSAGE;
        }
      });
    }
    return acc;
  }, {} as Record<number, string>);
};

export interface LegalEntitiesEditHierarchyLevelsDialog {
  dialogProps: LegalEntitiesEditHierarchyLevelsDialogProps;
  openDialog: () => Promise<boolean>;
  key: number;
}

const initialState: LegalEntitiesEditHierarchyLevelsDialogProps = {
  isOpen: false,
};

export const useLegalEntitiesEditLevelsDialog = (): LegalEntitiesEditHierarchyLevelsDialog => {
  const [dialogProps, setDialogProps] = useState<LegalEntitiesEditHierarchyLevelsDialogProps>(initialState);
  const onClose = useCallback(() => setDialogProps(initialState), []);
  const [key, setKey] = useState(0);

  const openDialog = useCallback<LegalEntitiesEditHierarchyLevelsDialog['openDialog']>(() => {
    return new Promise<boolean>(resolve => {
      setDialogProps({
        isOpen: true,
        onSave: ({ updatedParent }) => {
          setDialogProps(initialState);
          setKey(key => (updatedParent ? ++key : key));
          resolve(true);
        },
        onClose,
      });
    });
  }, [onClose]);

  return {
    openDialog,
    dialogProps,
    key,
  };
};
