import React, { FC, useRef, useState } from 'react';
import { BigidButtonIcon, BigidMenu } from '@bigid-ui/components';
import { BigidMenuMoreIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';

export interface SavedViewsSubMenuProps {
  onRename: () => void;
  onDelete: () => void;
}

export const SavedViewsSubMenu: FC<SavedViewsSubMenuProps> = ({ onRename, onDelete }) => {
  const [open, setOpen] = useState(false);
  const iconRef = useRef<HTMLButtonElement>(null);
  const { t } = useLocalTranslation();

  const menuProps = {
    open,
    onMenuClose: () => setOpen(false),
    anchorEl: iconRef.current as Element,
    items: [
      {
        id: 'rename',
        label: t('buttonLables.rename'),
        onClick: () => onRename(),
      },
      {
        id: 'delete',
        label: t('buttonLables.delete'),
        onClick: () => onDelete(),
      },
    ],
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      <BigidButtonIcon
        onClick={() => setOpen(!open)}
        size="xsmall"
        icon={BigidMenuMoreIcon}
        variant="tertiary"
        selected={open}
        tooltipText={t('tooltipTexts.moreOptions')}
        ref={iconRef}
      />
      <BigidMenu
        {...menuProps}
        paperStyle={{ marginLeft: '8px', marginTop: '-8px' }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      />
    </div>
  );
};
