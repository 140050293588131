import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  BigidGrid,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridProps,
  BigidGridQueryComponents,
  useFetch,
  UseFetchActions,
} from '@bigid-ui/grid';
import { getActivityMonitoringDetails, ObjectActivityDetails } from './activityMonitoringService';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidNoDataIllustration } from '@bigid-ui/icons';
import { capitalize } from 'lodash';
import { useLocalTranslation } from './translations';
import { styled } from '@mui/material';
import { notificationService } from '../../services/notificationService';
import { ActivityMonitoringDetailsContext } from './hooks/ActivityMonitoringDetailsContext';

const NoData = styled('td')`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ActivityMonitoringDetailsGrid: FC = () => {
  const { t } = useLocalTranslation('ActivityMonitoringGrid');
  const { fullyQualifiedName, filters } = useContext(ActivityMonitoringDetailsContext);

  const columns = useMemo<BigidGridColumn<ObjectActivityDetails>[]>(
    () => [
      {
        name: 'accountName',
        title: t('activityMonitoringGridColumns.accountName'),
        getCellValue: ({ accountName }) => capitalize(accountName),
        width: 200,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'accountIdentifier',
        title: t('activityMonitoringGridColumns.accountIdentifier'),
        getCellValue: ({ accountIdentifier }) => accountIdentifier,
        width: 200,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'lastActivity',
        title: t('activityMonitoringGridColumns.lastActivity'),
        getCellValue: ({ lastActivity }) => lastActivity,
        width: 200,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'operation',
        title: t('activityMonitoringGridColumns.operation'),
        getCellValue: ({ operation }) => operation.join(', '),
        width: 200,
        type: BigidGridColumnTypes.TEXT,
      },
    ],
    [t],
  );

  const { dispatch: dispatchUseFetch, ...useFetchState } = useFetch({
    fetchDataFunction: async (queryComponents: BigidGridQueryComponents) => {
      try {
        const { details, totalCount } = await getActivityMonitoringDetails(
          fullyQualifiedName,
          filters,
          queryComponents.skip,
          queryComponents.limit,
        );
        return {
          data: details,
          totalCount,
        };
      } catch (err) {
        if (err.response.status !== 404) {
          notificationService.error(t('activityMonitoringGridEmpty.emptyMessage'));
          console.error(err);
        }
      }
    },
  });

  const refreshGrid = useCallback(
    () => dispatchUseFetch({ type: UseFetchActions.FORCE_RELOAD, payload: {} }),
    [dispatchUseFetch],
  );

  useEffect(() => {
    refreshGrid();
  }, [refreshGrid, fullyQualifiedName, filters]);

  const gridConfig: BigidGridProps<ObjectActivityDetails> = {
    columns,
    pageSize: 25,
    pagingMode: true,
    showFilteringControls: false,
    showSortingControls: false,
    rows: useFetchState.rows as ObjectActivityDetails[],
    skip: useFetchState.skip,
    onPagingChanged: useFetchState.onPagingChanged,
    onSortingChanged: useFetchState.onSortingChanged,
    totalRowsCount: useFetchState.totalRowsCount,
    onFiltersChange: useFetchState.onFiltersChanged,
    apiRef: useFetchState.apiRef,
    loading: useFetchState.isLoading,
    noDataContent: (
      <NoData colSpan={5}>
        <BigidLayoutEmptyState
          illustration={BigidNoDataIllustration}
          description={t('activityMonitoringGridEmpty.emptyMessage')}
        />
      </NoData>
    ),
  };

  return <BigidGrid {...gridConfig} />;
};
