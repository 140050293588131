import { BigidChip, BigidColorsV2 } from '@bigid-ui/components';
import { styled, useTheme } from '@mui/material';
import React from 'react';
import { OverviewOwnersProps } from './OverviewOwners';

const Root = styled('div')`
  display: inline-flex;
  flex-shrink: 0;
`;

export const ExcessiveNumberChip = ({
  threshold,
  totalNumberOfItems,
  tooltipList,
}: Pick<OverviewOwnersProps, 'threshold' | 'totalNumberOfItems' | 'tooltipList'>) => {
  const theme = useTheme();
  const displayMore = totalNumberOfItems - threshold;

  if (!displayMore || displayMore <= 0) {
    return null;
  }

  return (
    <Root>
      <BigidChip
        bgColor={theme.vars.tokens.bigid.backgroundPrimary}
        color={theme.vars.tokens.bigid.foregroundPrimary}
        label={`+${displayMore}`}
        outlineColor={BigidColorsV2.gray[200]}
        outline="solid"
        variant="outlined"
        size="small"
        tooltipProps={{
          title: tooltipList?.join('\n'),
        }}
      />
    </Root>
  );
};
