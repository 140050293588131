import React, { FC, Suspense, lazy } from 'react';
import { BigidLoader } from '@bigid-ui/components';
import { CatalogDiscoveryGuidedTourProps } from './CatalogDiscoveryGuidedTour';

const CatalogDiscoveryGuidedTourComponent = lazy(() =>
  import(/* webpackChunkName: "CatalogDiscoveryGuidedTour" */ './CatalogDiscoveryGuidedTour').then(module => ({
    default: module.CatalogDiscoveryGuidedTour,
  })),
);

export const CatalogDiscoveryGuidedTourLazy: FC<CatalogDiscoveryGuidedTourProps> = ({
  status,
  onGuidedTourFinished,
}) => {
  return (
    <Suspense fallback={<BigidLoader position="fixed" />}>
      <CatalogDiscoveryGuidedTourComponent status={status} onGuidedTourFinished={onGuidedTourFinished} />
    </Suspense>
  );
};
