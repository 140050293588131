import { DateISO8601 } from '../../../types/types';
import { DataCatalogObjectType } from '../../DataCatalog/DataCatalogService';
import { TagEntity } from '../../TagsManagement/TagsManagementService';
import { FieldType } from './SarProfileSettingsTypes';

export interface SarProfile {
  _id: string;
  name: string;
  created_at: DateISO8601;
  updated_at: DateISO8601;
  allEnabledEs: boolean;
  allEnabledDs: boolean;
  dsConnections: DataSource[];
  esConnections: EntitySource[];
  profileId: string;
  hasSelectedEs: boolean;
  hasSelectedDs: boolean;
  isCustom?: boolean;
  scopes?: string[];
  shouldAttributesEnrichment?: boolean;
  synchronization?: SynchronizationEntity;
}

export enum FieldsEnrichmentActionType {
  START = 'start',
  END = 'end',
  FAILURE = 'failure',
  ON_PROGRESS = 'on progress',
}

export interface SynchronizationEntity {
  status: FieldsEnrichmentActionType;
  message: string;
  progress?: number;
  broadcastEvent?: string;
  objectsTotal?: number;
  objectsCompleted?: number;
  objectsFailed?: number;
  total?: number;
  completed?: number;
  failed?: number;
  startedAt?: Date;
  completedAt?: Date;
}

export interface SarProfileConnection {
  name: string;
  sourceType: string;
  enabled?: boolean;
  isCaseInSensitive?: boolean;
  isCaseInSensitiveSupported?: boolean;
  isReferentialIntegrity?: boolean;
  isJitRecordDiscovery?: boolean;
  isExactMatch?: boolean;
  customAttributeMapping: CustomAttributeMapping[];
  xmlFieldParser?: XmlFieldParser;
}

export interface SarProfilesGetAllModel extends Pick<SarProfile, '_id' | 'name' | 'allEnabledDs' | 'allEnabledEs'> {}

export interface SarProfilePutResponseModel {
  isModified: boolean;
}

export interface SarProfilePutModel
  extends Partial<Pick<SarProfile, 'allEnabledDs' | 'allEnabledEs' | 'dsConnections' | 'esConnections'>> {}

export interface SarProfileConnectionModel {
  connections: SarProfileConnection[];
  profileId: string;
}

export interface ConnectionStatus {
  is_success: boolean;
  last_connection: DateISO8601;
  num_of_object: number;
}

export interface DataSource {
  id: string;
  name: string;
  type: string;
  dsConnection: string;
  sourceType: string;
  enabled?: boolean;
  profileEnabled: boolean;
  connectionStatusTest: ConnectionStatus;
  connectionStatusScan: ConnectionStatus;
  isCaseInSensitive: boolean;
  isCaseInSensitiveSupported: boolean;
  sourceEnabled: boolean;
  isReferentialIntegrity: boolean;
  isReferentialIntegritySupported: boolean;
  isJitRecordDiscoverySupported: boolean;
  isJitRecordDiscovery: boolean;
  isExactMatch: boolean;
  isExactMatchSupported: boolean;
  customAttributeMapping: CustomAttributeMapping[];
  xmlFieldParser?: XmlFieldParser;
}

export interface CustomAttributeMapping {
  containerName: string;
  objectName: string;
  mappings: { attribute: string; fieldName: string }[];
}

export interface XmlFieldParser {
  fieldXmlParserConfiguration: {
    objectName?: string;
    fieldName: string;
    xmlGroupLevel: number;
    attributesToPath: string[];
  };
}

export interface EntitySource extends DataSource {}

export interface SarFieldsSettings {
  _id: string;
  id: string;
  field_name: string;
  field_friendly_name: string;
  purpose_of_use: string;
  category: string;
  data_source: string;
  schema: string;
  table: string;
  attribute_type: string;
  sample_values: string;
  mask_it: boolean;
  mask_operation: MaskOptions;
  is_included_in_report: boolean;
  is_included_in_proximity_category?: boolean;
  fully_qualified_name: string;
  field_name_lower: string;
  is_primary?: boolean;
  category_glossary_id?: string;
  purpose_glossary_id?: string;
  friendly_name_glossary_id?: string;
  correlation_id: null;
}

export interface OptionDto {
  glossaryId: string;
  glossaryValue: string;
  priority: number;
  sourceType: string;
  calcConfidenceLevel: number;
}

export interface SarFieldsSettingsV2 {
  id: string;
  dsarProfileId: string;
  dataSource: string;
  fullyQualifiedObjectName: string;
  fullObjectName: string;
  fieldName: string;
  attributeType: string;
  isIncludedInReport: boolean;
  isIncludedInProximityCategory: boolean;
  maskIt: boolean;
  maskOperation: MaskOptions;
  customFriendlyNameId: string;
  customFriendlyNameValue: string;
  customCategoryId: string;
  customCategoryValue: string;
  customPurposeOfUseId: string;
  customPurposeOfUseValue: string;
  sampleValues: string[];
  defaultCategoryId: string;
  defaultCategoryValue: string;
  defaultFriendlyNameId: string;
  defaultFriendlyNameValue: string;
  defaultPurposeOfUseId: string;
  defaultPurposeOfUseValue: string;
  friendlyNames?: OptionDto[];
  categories?: OptionDto[];
  purposesOfUse?: OptionDto[];
  isFriendlyNameWarning?: boolean;
  isPurposeOfUseWarning?: boolean;
  isCategoryWarning?: boolean;
  dictionaryId?: string;
  dictionaryName?: string;
}

export enum SourceType {
  NONE = 'NONE',
  CUSTOM = 'CUSTOM',
  CONNECTED = 'CONNECTED',
}

export interface DsarManualRecords {
  id: string;
  fieldName: string;
  value: string;
  type: FieldType;
  isIncluded: boolean;
  categoryId?: string;
  categoryValue?: string;
  purposeOfUseId?: string;
  purposeOfUseValue?: string;
  dataSourceType: SourceType;
  dataSourceConnectionType?: string;
  dataSource?: string;
  dataSourceLocation?: string;
}

export interface DsarObjects {
  dataSource: string;
  fullObjectName: string;
  fullyQualifiedObjectName: string;
  id: string;
  included: boolean;
  objectDetailedType: string;
  objectName: string;
  objectType: string;
  dataSourceType: string;
  extendedObjectType: DataCatalogObjectType;
  tags: TagEntity[];
  attributes: string[];
}

export interface DsarObjectsColumns {
  id: string;
  columnType: string;
  columnName: string;
  isPrimaryKey: boolean;
  isIncluded: boolean;
  attributeList: DsarObjectsColumnsAttributeList[];
}

export interface DsarObjectsColumnsAttributeList {
  rank: string;
  attributeId: string;
  attributeOriginalName: string;
  attributeName: string;
  attributeType: string;
  calcConfidenceLevel: number;
  isIncluded: boolean;
}

export interface DsarObjectsUpdateModel {
  includedObjectToDsarScan: boolean;
}

export interface DsarObjectsColumnsUpdateModel {
  excludeColumns?: {
    columns: string[];
  };
  includeColumns?: {
    columns: string[];
  };
}

export interface DsarObjectsColumnsAttributesUpdateModel
  extends Partial<Pick<DsarObjectsColumnsAttributeList, 'attributeName' | 'isIncluded'>[]> {}

export enum MaskOptions {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  NO = 'NO',
}

export enum MaskOptionsValue {
  FULL_MASKING = 'Full Masking',
  PARTIAL_MASKING = 'Partial Masking',
  NO_MASKING = 'No Masking',
}

export interface SarFieldsSettingsPutBatchModel
  extends Partial<
    Pick<
      SarFieldsSettings,
      | 'mask_it'
      | 'is_included_in_report'
      | 'is_included_in_proximity_category'
      | 'category_glossary_id'
      | 'purpose_glossary_id'
      | 'friendly_name_glossary_id'
      | 'mask_operation'
    >
  > {}

export interface SarFieldsSettingsBatchUpdate
  extends Partial<
    Pick<
      SarFieldsSettingsV2,
      | 'maskIt'
      | 'isIncludedInReport'
      | 'isIncludedInProximityCategory'
      | 'customCategoryId'
      | 'customPurposeOfUseId'
      | 'customFriendlyNameId'
      | 'maskOperation'
      | 'dictionaryId'
    >
  > {}

export interface ExcludeConnectionsOptions {
  excludeDsConnections?: boolean;
  excludeEsConnections?: boolean;
  exposeSyncData?: boolean;
}

export interface IncludedDataSources {
  names: {
    name: string;
  }[];
}
