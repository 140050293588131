import React from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidButtonIcon, BigidTooltip } from '@bigid-ui/components';
import { BigidResetIcon, BigidArrowLeftCircleIcon } from '@bigid-ui/icons';
import { frameworkIconGenerator } from '../../../../../react/views/Frameworks/utils';
import { useTheme } from '@mui/material';
import { ContentView, MappedControlRowProps } from '../../utils';

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
  width: 100%;
  padding: 0 16px;
  position: absolute;
  border-bottom: 1px solid rgb(230, 230, 230);
  top: 0;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DecriptionText = styled.div`
  width: 75%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  width: 90%;
`;

const Divider = styled.span`
  height: 18px;
`;

const BackIcon = styled(BigidArrowLeftCircleIcon)`
  cursor: pointer;
  margin: 0 2px;
`;

interface MappedControlsDialogHeaderProps {
  selectedItem: MappedControlRowProps;
  currentView: ContentView;
  selectedCategory: string;
  handleContentView: (value: ContentView) => void;
  handleResetDialog: (value: boolean, frameworkName?: string) => void;
}

export const MappedControlsDialogHeader = ({
  selectedItem,
  currentView,
  selectedCategory,
  handleContentView,
  handleResetDialog,
}: MappedControlsDialogHeaderProps): JSX.Element => {
  const Icon = frameworkIconGenerator(selectedItem?.framework_name);
  const Theme = useTheme();
  return (
    <Header>
      {selectedItem &&
        (currentView === ContentView.LIST ? (
          <HeaderWrapper>
            <IconsWrapper>
              <Icon height={24} width={24} />
              <BigidBody1 noWrap>{selectedItem?.framework_name}</BigidBody1>
              <BigidBody1
                size="small"
                color={Theme.palette.bigid.gray500}
              >{`${selectedItem?.number_of_controls} controls`}</BigidBody1>
            </IconsWrapper>
            <BigidButtonIcon
              icon={BigidResetIcon}
              onClick={() => handleResetDialog(true, selectedItem.framework_name)}
            />
          </HeaderWrapper>
        ) : (
          <HeaderWrapper>
            <IconsWrapper>
              <BackIcon onClick={() => handleContentView(ContentView.LIST)} />
              <BigidBody1 noWrap>{selectedItem?.framework_name}</BigidBody1>
              <Divider>/</Divider>
              <BigidTooltip title={selectedCategory}>
                <DecriptionText>
                  <BigidBody1 noWrap>{selectedCategory}</BigidBody1>
                </DecriptionText>
              </BigidTooltip>
            </IconsWrapper>
            <BigidButtonIcon
              icon={BigidResetIcon}
              onClick={() => handleResetDialog(true, selectedItem.framework_name)}
            />
          </HeaderWrapper>
        ))}
    </Header>
  );
};
