import React, { Dispatch, FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  BigidFieldFilter,
  BigidFieldFilterOperator,
  BigidFilter,
  BigidFilterOptionValue,
  BigidFilterType,
  BigidHeading5,
  BigidInlineNotification,
  BigidLoader,
  BigidPrimaryCheckbox,
  objectToQueryString,
  PrimaryButton,
  QueryParams,
} from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridDataFetchResult,
  BigidGridProps,
  BigidGridQueryComponents,
  BigidGridWithToolbarProps,
  ChipsFormatterProps,
  FetchDataFunction,
  FetchTotalCount,
} from '@bigid-ui/grid';
import {
  BigidLayout,
  BigidLayoutConfig,
  BigidMasterDetailsContentProps,
  LayoutContentType,
  parseFieldFiltersToSearchQuery,
} from '@bigid-ui/layout';
import { getDistinctDs } from '../UsersPermissions/UsersPermissionsService';
import { notificationService } from '../../../services/notificationService';
import { DataCatalogDetailsForGrid } from '../../DataCatalog/DataCatalogDetails';
import { DataCatalogAttributes } from '../../DataCatalog/DataCatalogAttributes';
import { getDistinctAttributes, HierarchyType } from '../../DataCatalog/DataCatalogService';
import { UsersPermissionsRecord } from '../UsersPermissions';
import {
  Annotations,
  getCSVByQuery,
  getOpenAccessFiles,
  getOpenAccessFilesCount,
  getOpenAccessFilesCountAfterItsFinal,
  getWithPermissionsCSVByQuery,
  OpenAccessFilesData,
  sleep,
} from './OpenAccessFilesService';
import { FilePermissions, GrantType } from './FilePermissions';
import { getLabels } from '../Settings/Configuration/LabelConfigurationService';
import makeStyles from '@mui/styles/makeStyles';
import { openSystemDialog } from '../../../../react/services/systemDialogService';
import { AciEntityType } from '../InsightCharts/AccessIntelligenceInsightsService';
import { $stateParams } from '../../../services/angularServices';
import { isPermitted } from '../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { ACIDetailsForTheGrid } from '../ACIDetails';
import { BigidFileIcon, BigidFolderClosedIcon, BigidOtherIcon } from '@bigid-ui/icons';

export interface OpenAccessFilesProps {
  aciEntityId?: string;
  aciEntityType?: AciEntityType;
  dataSource?: string;
}

export interface UserAccess {
  openAccessFiles: number;
  internalFiles: number;
  openAndInternalFiles: number;
}

export interface UserFileRecord {
  id: string;
  name: string;
  fileSource: string;
  source: string;
  shortenedFQN?: string;
  attributes?: ChipsFormatterProps;
  annotations?: Annotations;
}

const lookupFilters = ['field'];

const DEFAULT_LIMIT = 100;
const ACI_TOTAL_OBJECTS_COUNT_THRESHOLD = 5000000;

export const getFileNameFromFQN = (fullyQualifiedName: string) => {
  const index = fullyQualifiedName.lastIndexOf('/');
  return fullyQualifiedName.slice(index + 1);
};

export const getFileName = (isAciSupportForFoldersEnabled: boolean, userFile: OpenAccessFilesData) =>
  isAciSupportForFoldersEnabled && userFile.shortenedFQN
    ? userFile.shortenedFQN
    : getFileNameFromFQN(userFile.fullyQualifiedName);

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px 4px 0px 0px',
    boxShadow: '0 0 6px rgba(0, 0, 0, 0.16)',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
  },
});

interface ExportFileReportContextProps {
  fileAccessWithAnnotations: boolean;
  setFileAccessWithAnnotations: Dispatch<boolean>;
  fileAccessWithAnnotationsAndPermissions: boolean;
  setFileAccessWithAnnotationsAndPermissions: Dispatch<boolean>;
}

export enum AppId {
  FILES = 'acl',
  FOLDERS = 'foldersACL',
}

export enum ShowFolderOrFiles {
  FILES = 'files',
  FOLDERS = 'folders',
}

const getLabelForHierarchyType = (showFolderOrFiles: ShowFolderOrFiles): string => {
  switch (showFolderOrFiles) {
    case ShowFolderOrFiles.FILES:
      return 'File';
    case ShowFolderOrFiles.FOLDERS:
      return 'Folder (SMB only)';
    default:
      return '';
  }
};

const getLabelFromHierarchyType = (hierarchyType: HierarchyType): string => {
  switch (hierarchyType) {
    case undefined:
    case HierarchyType.LEAF_DATA_OBJECT:
      return 'File';
    case HierarchyType.CONTAINER:
    case HierarchyType.SUB_CONTAINER:
      return 'Folder';
    default:
      return '';
  }
};

const getHierarchyTypeFromAnnotations = (annotations: Annotations): HierarchyType => {
  return annotations['hierarchyType']?.[0] as HierarchyType;
};

const getIconFromHierarchyType = (hierarchyType: HierarchyType) => {
  switch (hierarchyType) {
    case HierarchyType.SUB_CONTAINER:
    case HierarchyType.CONTAINER:
      return BigidFolderClosedIcon;
    case undefined:
    case HierarchyType.LEAF_DATA_OBJECT:
      return BigidFileIcon;
    default:
      return BigidOtherIcon;
  }
};

const getAppId = (showFolderOrFiles: ShowFolderOrFiles): AppId => {
  switch (showFolderOrFiles) {
    case ShowFolderOrFiles.FILES:
      return AppId.FILES;
    case ShowFolderOrFiles.FOLDERS:
      return AppId.FOLDERS;
  }
};

const objectTypeColumn: BigidGridColumn<UserFileRecord> = {
  title: 'Object type',
  name: 'hierarchyType',
  width: 300,
  isListColumn: true,
  type: BigidGridColumnTypes.ICON,
  getCellValue: ({ annotations }) => ({
    icon: {
      icon: getIconFromHierarchyType(getHierarchyTypeFromAnnotations(annotations)),
      label: getLabelFromHierarchyType(getHierarchyTypeFromAnnotations(annotations)),
    },
  }),
};

export const ExportFileReportContentDialog: FC<ExportFileReportContextProps> = ({
  fileAccessWithAnnotations,
  setFileAccessWithAnnotations,
  fileAccessWithAnnotationsAndPermissions,
  setFileAccessWithAnnotationsAndPermissions,
}) => {
  return (
    <>
      <div>
        <BigidHeading5 gutterBottom>Please choose one or more files to download</BigidHeading5>
      </div>
      <BigidPrimaryCheckbox
        label={'File access with annotations'}
        checked={fileAccessWithAnnotations}
        onChange={(event, checked) => {
          setFileAccessWithAnnotations(checked);
        }}
      />
      <BigidPrimaryCheckbox
        label={'File access with annotations and permissions'}
        checked={fileAccessWithAnnotationsAndPermissions}
        onChange={(event, checked) => setFileAccessWithAnnotationsAndPermissions(checked)}
      />
    </>
  );
};

export type DataManagerTabFilters = {
  sources?: string[];
  accessTypes?: string[];
  attributes?: string[];
};

const HIERARCHY_TYPE_FIELD = 'annotations.hierarchyType';

const getAppIdFromFilter = (filter: BigidFilter, defaultValue: AppId.FILES): AppId.FILES =>
  (filter.find(filterItem => filterItem.field === HIERARCHY_TYPE_FIELD)?.value as AppId.FILES) ?? defaultValue;

const CATALOG_PERMISSIONS_TITLE = 'This page requires Catalog Read permissions. Please contact your administrator.';
const CATALOG_PERMISSIONS_TEXT =
  'Your administrator needs to assign you a role that includes Catalog Read permissions. User settings are located in the Access Management section, accessible from the left-side panel.';
export const OpenAccessFiles: FC<OpenAccessFilesProps> = ({ aciEntityId, aciEntityType, dataSource }) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterToolbarConfig, setFilterToolbarConfig] =
    useState<BigidGridWithToolbarProps<UsersPermissionsRecord>['filterToolbarConfig']>();

  const [fileAccessWithAnnotations, setFileAccessWithAnnotations] = useState<boolean>(true);
  const [fileAccessWithAnnotationsAndPermissions, setFileAccessWithAnnotationsAndPermissions] =
    useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [downloadQuery, setDownloadQuery] = useState<string>('');

  const isCatalogPermitted = useMemo(() => isPermitted(CATALOG_PERMISSIONS.READ.name), []);
  const isSmbV2Enabled = getApplicationPreference('SMB_V2_ACI_ENABLED');
  const MESSAGE_TOTAL_COUNT_THRESHOLD_EXCEEDED = 'Counted millions of';
  const isAciSupportForFoldersEnabled: boolean =
    getApplicationPreference('ACI_SUPPORT_FOR_FOLDERS_ENABLED')?.toString() === 'true';

  const objectTypeFilter: BigidFilterType = useMemo(
    () => ({
      title: 'Object Type',
      field: HIERARCHY_TYPE_FIELD,
      operator: 'equal' as BigidFieldFilterOperator,
      options: [
        {
          label: getLabelForHierarchyType(ShowFolderOrFiles.FILES),
          value: getAppId(ShowFolderOrFiles.FILES),
          isSelected: true,
        },
        {
          label: getLabelForHierarchyType(ShowFolderOrFiles.FOLDERS),
          value: getAppId(ShowFolderOrFiles.FOLDERS),
          isSelected: false,
        },
      ],
      value: getAppId(ShowFolderOrFiles.FILES),
      single: true,
      onCloseDisabled: isAciSupportForFoldersEnabled,
    }),
    [isAciSupportForFoldersEnabled],
  );

  useEffect(() => {
    const getFilterConfig = async () => {
      if (!isCatalogPermitted) {
        console.error(CATALOG_PERMISSIONS_TITLE);
        return;
      }

      setIsLoading(true);
      const attributes = await getDistinctAttributes();
      const dsList = await getDistinctDs();
      const accessTypes = await getLabels();

      const filtersPassedFromRouter = $stateParams.filters as DataManagerTabFilters;

      let sourcesFiltersPassedFromRouter: string[];

      if (!filtersPassedFromRouter || !filtersPassedFromRouter.sources) {
        sourcesFiltersPassedFromRouter = [];
      } else if (filtersPassedFromRouter.sources.length > 0) {
        sourcesFiltersPassedFromRouter = filtersPassedFromRouter.sources;
      } else {
        sourcesFiltersPassedFromRouter = dsList.map(({ value }) => value);
      }

      const filterDataManagerConfig: BigidGridWithToolbarProps<UsersPermissionsRecord>['filterToolbarConfig'] = {
        filters: [
          ...(isAciSupportForFoldersEnabled ? [objectTypeFilter] : []),
          ...(!dataSource && !aciEntityId
            ? [
                {
                  title: 'Data Sources',
                  field: 'source',
                  operator: 'in' as BigidFieldFilterOperator,
                  options: dsList.map(({ value }) => ({
                    label: value,
                    value: value,
                    isSelected: sourcesFiltersPassedFromRouter.includes(value),
                  })),
                  value: sourcesFiltersPassedFromRouter || [],
                  onCloseDisabled: isAciSupportForFoldersEnabled,
                },
              ]
            : []),
          {
            title: 'Attributes',
            field: 'field',
            operator: 'in',
            options: attributes.map(({ value }) => ({
              label: value,
              value: value,
              isSelected: filtersPassedFromRouter?.attributes?.includes(value),
            })),
            value: filtersPassedFromRouter?.attributes || [],
            onCloseDisabled: isAciSupportForFoldersEnabled,
          },
          {
            title: 'Access Type',
            field: 'annotations.openAccess',
            operator: 'in',
            options: accessTypes.map(({ label_name }) => ({
              label: label_name,
              value: label_name,
              isSelected: filtersPassedFromRouter?.accessTypes?.includes(label_name),
            })),
            value: filtersPassedFromRouter?.accessTypes || [],
            onCloseDisabled: isAciSupportForFoldersEnabled,
          },
        ],
      };
      setFilterToolbarConfig(filterDataManagerConfig);
      setIsLoading(false);
    };
    getFilterConfig();
  }, [aciEntityId, dataSource, isCatalogPermitted, objectTypeFilter, isAciSupportForFoldersEnabled]);

  const flatFilter = (filter: BigidFilter = []): BigidFieldFilter[] => {
    const flattenedFilters = filter.reduce<BigidFieldFilter[]>((aggregation, filter: BigidFieldFilter) => {
      if (Array.isArray(filter.value)) {
        const filterValues = filter.value.map((filterValue: BigidFilterOptionValue) => ({
          field: filter.field,
          operator: filter.operator,
          value: `"${filterValue}"`,
        }));
        return [...aggregation, ...filterValues];
      }

      return [...aggregation];
    }, []);

    return flattenedFilters;
  };

  const generateQuery = useCallback(
    (queryComponents: BigidGridQueryComponents, useLimit: boolean): string => {
      const flattenedFilters = flatFilter(
        queryComponents.filter.filter(fieldFilter => !lookupFilters.includes(fieldFilter.field)),
      );
      const flattenedLookupFilters = flatFilter(
        queryComponents.filter.filter(fieldFilter => lookupFilters.includes(fieldFilter.field)),
      );
      aciEntityId &&
        flattenedFilters.push({
          field: `annotations.sharedWith${aciEntityType === AciEntityType.Groups ? 'Group' : ''}`,
          operator: 'equal',
          value: `"${aciEntityId}"`,
        });
      dataSource &&
        flattenedFilters.push({
          field: `source`,
          operator: 'in',
          value: `"${dataSource}"`,
        });
      const filter = parseFieldFiltersToSearchQuery(flattenedFilters);
      const lookupFilter = parseFieldFiltersToSearchQuery(flattenedLookupFilters);
      const params: QueryParams = {
        ...(queryComponents as QueryParams),
        filter,
        limit: useLimit ? DEFAULT_LIMIT : ACI_TOTAL_OBJECTS_COUNT_THRESHOLD,
        lookup_filter: lookupFilter,
      };

      if (isAciSupportForFoldersEnabled) {
        params.app_id = getAppIdFromFilter(queryComponents.filter, AppId.FILES);
      }
      const query = objectToQueryString(params);
      const downloadQuery = objectToQueryString({ ...params, limit: null });
      setDownloadQuery(downloadQuery);
      return query;
    },
    [aciEntityId, aciEntityType, dataSource, isAciSupportForFoldersEnabled],
  );

  const fetchGridData: FetchDataFunction<UserFileRecord> = useCallback(
    async queryComponents => {
      const query = generateQuery(queryComponents, true);
      // Fetching the files
      const { data } = await getOpenAccessFiles(query);

      const { totalCount, fetchTotalCount } = await fetchTotalCounters(queryComponents, data);

      const mappedData: UserFileRecord[] = data.map(userFile => {
        const attributes: ChipsFormatterProps = {
          chips: {
            value: userFile.objectDetails?.attribute
              ? userFile.objectDetails?.attribute.map(attr => ({
                  label: attr,
                }))
              : [],
            isDisabled: true,
          },
        };
        const name = getFileName(isAciSupportForFoldersEnabled, userFile);
        return {
          ...userFile,
          id: userFile._id,
          name,
          fileSource: userFile.fullyQualifiedName,
          attributes,
          annotations: userFile.annotations,
          shortenedFQN: userFile.shortenedFQN,
          hierarchyType: userFile.annotations.hierarchyType,
        };
      });

      const result: BigidGridDataFetchResult<UserFileRecord> = {
        totalCount: totalCount,
        data: mappedData,
        fetchCount: fetchTotalCount,
      };

      return result;
    },
    [generateQuery, isAciSupportForFoldersEnabled],
  );

  const gridConfig: BigidGridProps<UserFileRecord> = useMemo(
    () => ({
      showSortingControls: false,
      showSelectionColumn: true,
      columns: [
        {
          title: isAciSupportForFoldersEnabled ? 'Object' : 'File Name',
          name: 'name',
          width: 300,
          isListColumn: true,
          type: BigidGridColumnTypes.TEXT,
          getCellValue: ({ name }) => name,
        },
        ...(isAciSupportForFoldersEnabled ? [objectTypeColumn] : []),
        {
          title: isAciSupportForFoldersEnabled ? 'Object Source' : 'File Source',
          name: 'fileSource',
          width: 300,
          type: BigidGridColumnTypes.TEXT,
          getCellValue: ({ fileSource }) => fileSource,
        },
        {
          title: 'Data Source',
          name: 'dataSource',
          width: 200,
          type: BigidGridColumnTypes.TEXT,
          getCellValue: ({ source }) => source,
        },

        {
          title: 'Attributes',
          name: 'attributes',
          type: BigidGridColumnTypes.CHIPS,
          width: 300,
          getCellValue: ({ attributes }) => attributes,
        },
        {
          title: 'Access Types',
          name: 'accessTypes',
          type: BigidGridColumnTypes.TEXT,
          width: 300,
          getCellValue: ({ annotations }) => annotations?.openAccess?.join(', ') || '',
        },
      ],
    }),
    [isAciSupportForFoldersEnabled],
  );

  const masterDetailsConfig: BigidMasterDetailsContentProps = useMemo(
    () => ({
      shouldReloadGridOnClose: true,
      tabsAndContent: {
        classes: {
          contentContainer: classes.contentContainer,
        },
        tabProps: {
          tabs: [
            {
              label: 'Details',
              data: { component: ACIDetailsForTheGrid },
            },
            {
              label: 'Attributes',
              data: { component: DataCatalogAttributes },
              ...(isAciSupportForFoldersEnabled
                ? {
                    getIsAvailable: ({ annotations }) =>
                      getHierarchyTypeFromAnnotations(annotations) === HierarchyType.LEAF_DATA_OBJECT ||
                      getHierarchyTypeFromAnnotations(annotations) === undefined,
                  }
                : {}),
            },
            {
              label: `Permissions`,
              data: { component: props => FilePermissions({ ...props }) },
              getIsAvailable: () => !isSmbV2Enabled,
            },
            {
              label: `Effective Permissions`,
              data: { component: props => FilePermissions({ ...props, grantType: GrantType.EFFECTIVE }) },
              getIsAvailable: () => isSmbV2Enabled,
            },
            {
              label: 'Direct Permissions',
              data: { component: props => FilePermissions({ ...props, grantType: GrantType.DIRECT }) },
              getIsAvailable: () => isSmbV2Enabled,
            },
          ],
          selectedIndex: 0,
        },
      },
    }),
    [classes.contentContainer, isSmbV2Enabled, isAciSupportForFoldersEnabled],
  );

  const downloadReports = useCallback(
    async (opts: { query: string; withPermissions?: boolean; withAnnotations?: boolean }) => {
      try {
        const downloads = [];

        if (opts.withAnnotations) {
          downloads.push(getCSVByQuery(opts.query));
        }

        if (opts.withPermissions) {
          downloads.push(getWithPermissionsCSVByQuery(opts.query));
        }

        await Promise.all(downloads);
      } catch (err) {
        const errMsg = `An error has occurred`;
        notificationService.error(errMsg);
        console.error(`${errMsg} ${err}`);
      }
    },
    [],
  );

  const openExportDialog = useCallback(
    (query: string) => {
      setIsOpen(true);

      openSystemDialog<ExportFileReportContextProps>({
        title: 'Export Access Files',
        onClose: () => {
          setIsOpen(false);
        },
        contentProps: {
          fileAccessWithAnnotations,
          setFileAccessWithAnnotations,
          fileAccessWithAnnotationsAndPermissions,
          setFileAccessWithAnnotationsAndPermissions,
        },
        content: ExportFileReportContentDialog,
        buttons: [
          {
            text: 'Download',
            component: PrimaryButton,
            onClick: async () =>
              downloadReports({
                query,
                withAnnotations: fileAccessWithAnnotations,
                withPermissions: fileAccessWithAnnotationsAndPermissions,
              }),
            isClose: true,
          },
        ],
      });
    },
    [downloadReports, fileAccessWithAnnotations, fileAccessWithAnnotationsAndPermissions],
  );

  useEffect(() => {
    if (isOpen) {
      openExportDialog(downloadQuery);
    }
  }, [isOpen, fileAccessWithAnnotations, fileAccessWithAnnotationsAndPermissions, openExportDialog, downloadQuery]);

  const layoutConfig: BigidLayoutConfig = useMemo(
    () => ({
      content: {
        entityName: 'Objects',
        totalRowsThreshold: ACI_TOTAL_OBJECTS_COUNT_THRESHOLD,
        getCustomThresholdDescription: () => MESSAGE_TOTAL_COUNT_THRESHOLD_EXCEEDED,
        contentTypes: [LayoutContentType.MASTER_DETAILS],
        toolbarActions: [
          {
            label: 'Export',
            isGlobal: true,
            execute: async ({ filter }) => {
              const query = generateQuery({ filter: filter }, false);
              openExportDialog(query);

              return Promise.resolve({ shouldGridReload: false });
            },
            disable: () => false,
            show: () => true,
          },
        ],
        viewConfig: {
          masterDetailsConfig,
          fetchGridData,
          gridConfig,
          filterToolbarConfig,
          selectedItemPropsMapping: {
            id: 'id',
            name: 'name',
            fullyQualifiedName: 'fullyQualifiedName',
            source: 'source',
            annotations: 'annotations',
            hierarchyType: 'hierarchyType',
          },
        },
      },
    }),
    [masterDetailsConfig, fetchGridData, gridConfig, filterToolbarConfig, generateQuery, openExportDialog],
  );

  async function fetchTotalCounters(queryComponents: BigidGridQueryComponents, data: any) {
    const query = generateQuery(queryComponents, false);
    let totalCount: number;
    let isFinal = true;
    if (queryComponents.requireTotalCount) {
      if (data.length < queryComponents.limit) {
        // no need to get the count from the server, we already know the total number of records
        totalCount = data.length;
        isFinal = true;
      } else {
        const firstFetchCountResponse = await getOpenAccessFilesCount(query);
        isFinal = firstFetchCountResponse[0].isFinal;
        if (isFinal) {
          totalCount = firstFetchCountResponse[0].count;
        } else {
          // Until we will get the final count we will present the user that we have "X out many files"
          // where X is queryComponents.limit
          totalCount = queryComponents.limit;
        }
      }
    }

    let fetchTotalCount: FetchTotalCount;
    if (!isFinal && data.length >= queryComponents.limit) {
      let fetchCountResponse = await getOpenAccessFilesCountAfterItsFinal(query.length > 0 ? query : '');
      const fetchCount: FetchTotalCount = {
        fetchCountCanceler: fetchCountResponse.cancel,
        fetchCountFunction: async () => {
          try {
            while (true) {
              const response = await fetchCountResponse.promise;
              const filesCount = response.data.data[0];
              if (filesCount.isFinal) {
                return {
                  totalCount: filesCount.count,
                };
              }
              await sleep(2000);
              fetchCountResponse = await getOpenAccessFilesCountAfterItsFinal(query.length > 0 ? query : '');
            }
          } catch ({ message }) {
            return {
              totalCount: 0,
            };
          }
        },
      };
      fetchTotalCount = fetchCount;
    }
    return { totalCount, fetchTotalCount };
  }

  const layout = useCallback(
    () =>
      isCatalogPermitted ? (
        <BigidLayout config={layoutConfig} />
      ) : (
        <BigidInlineNotification
          title={CATALOG_PERMISSIONS_TITLE}
          type="warning"
          isExpandable={true}
          text={[
            {
              subText: CATALOG_PERMISSIONS_TEXT,
            },
          ]}
          isClosable={false}
        />
      ),
    [isCatalogPermitted, layoutConfig],
  );

  return <>{isLoading ? <BigidLoader /> : layout()}</>;
};
