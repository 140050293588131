import { BigidSystemDialogOptions, openSystemDialog } from '../../../services/systemDialogService';
import { isNumOpenCasesThresholdExceeded } from './utils/gettingStartedCheckingUtils';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { GoToSecurityDashboardModalContent } from './GoToSecurityDashboardModalContent';
import { getSharedUserPreferenceValue } from '../utils/gettingStartedUtils';
import { isGettingStartedSecurityEnabled } from '../../../utilities/featureFlagUtils';

export const GO_TO_SECURITY_DASHBOARD_MODAL_SHOWN = 'goToSecurityDashboardModalShown';

export const shouldShowGoToSecurityDashboardModal = async () => {
  try {
    if (!isGettingStartedSecurityEnabled()) {
      return false;
    }
    const modalAlreadyShown = await wasModalAlreadyShown();
    if (modalAlreadyShown) {
      return false;
    }

    const autoDiscoveryTriggeredByGettingStarted = await getSharedUserPreferenceValue(
      userPreferencesService.SHARED_PREFERENCES.AUTO_DISCOVERY_TRIGGERED_BY_SECURITY_GETTING_STARTED,
    );
    const securityDashboardVisited = await getSharedUserPreferenceValue(
      userPreferencesService.SHARED_PREFERENCES.SECURITY_DASHBOARD_WAS_VISITED_AFTER_CASES_CREATION,
    );

    return (
      autoDiscoveryTriggeredByGettingStarted && !securityDashboardVisited && (await isNumOpenCasesThresholdExceeded())
    );
  } catch (error) {
    console.error(`Failed checking if shouldShowGoToSecurityDashboardModal. Error: ${error}`);
    return false;
  }
};

export const generateGoToSecurityDashboardModalOptions = (): BigidSystemDialogOptions => {
  return {
    title: '',
    content: GoToSecurityDashboardModalContent,
    maxWidth: 'xs',
    onClose: () => null,
    borderTop: false,
  };
};

export const openGoToSecurityDashboardModal = () => {
  const goToSecurityDashboardModalOptions = generateGoToSecurityDashboardModalOptions();
  openSystemDialog(goToSecurityDashboardModalOptions);
};

const wasModalAlreadyShown = async () => {
  const goToSecurityDashboardSharedPreference = await userPreferencesService.get(GO_TO_SECURITY_DASHBOARD_MODAL_SHOWN);
  return goToSecurityDashboardSharedPreference?.data;
};
