import React, { FC, useState, useEffect } from 'react';
import {
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidMenuItemProps,
} from '@bigid-ui/components';
import { ExecutiveDashboard, DashboardType } from '../../components/ExecutiveDashboard';
import { useDashboardToolbar } from '../../components/ExecutiveDashboard/hooks/useDashboardToolbar';
import { DataCoverageWidget, EmptyWidget, DataRiskWidget } from './widgets';
import { styled } from '@mui/material';
import { DisocveryDashboardContext } from './DiscoveryDashboardContext';

export const DISCOVERY_DASHBOARD_ID = 'discoveryDashboard';

const WidgetsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SplitSection = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const WidgetWrapper = styled('div')<{ isHalfWidth?: boolean }>`
  width: ${({ isHalfWidth }) => (isHalfWidth ? '50%' : '100%')};
`;

export enum DiscoveryDashboardWidget {
  DATA_COVERAGE = 'dataCoverage',
  DATA_RISK = 'dataRisk',
  SENSITIVE_DATA = 'sensitiveData',
  DATA_DELETION = 'dataDeletion',
}

export const DiscoveryDashboard: FC = () => {
  // TODO: POC Code, to be replaced with real filters creation
  const toolbarFilters: BigidAdvancedToolbarFilterUnion[] = [
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'sensitivity',
      field: 'sensitivity',
      title: 'Sensitivity',
      parentId: 'sensitivity',
      operator: 'in',
      options: [],
      asyncOptionsFetch: async () => [
        {
          id: 'restricted',
          value: 'restricted',
          displayValue: 'Restricted',
        },
        {
          id: 'confidential',
          value: 'confidential',
          displayValue: 'Confidential',
        },
        {
          id: 'internalUse',
          value: 'internalUse',
          displayValue: 'Internal Use',
        },
        {
          id: 'public',
          value: 'public',
          displayValue: 'Public',
        },
      ],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'dsType',
      field: 'dsType',
      title: 'DS Type',
      operator: 'in',
      asyncOptionsFetch: async () => [
        {
          id: 's3-v2',
          value: 's3-v2',
          displayValue: 'S3',
        },
        {
          id: 'azure',
          value: 'azure',
          displayValue: 'Azure',
        },
        {
          id: 'mysql',
          value: 'mysql',
          displayValue: 'MySQL',
        },
      ],
      options: [],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'tags',
      field: 'tags',
      title: 'Tags',
      operator: 'in',
      asyncOptionsFetch: async () => [
        {
          id: 'dsRiskHigh',
          value: 'dsRiskHigh',
          displayValue: 'DS Risk - High',
        },
        {
          id: 'dsRiskMedium',
          value: 'dsRiskMedium',
          displayValue: 'DS Risk - Medium',
        },
        {
          id: 'dsRiskLow',
          value: 'dsRiskLow',
          displayValue: 'DS Risk - Low',
        },
      ],
      options: [],
    },
  ];
  const widgetIds = [
    DiscoveryDashboardWidget.DATA_COVERAGE,
    DiscoveryDashboardWidget.DATA_RISK,
    DiscoveryDashboardWidget.SENSITIVE_DATA,
    DiscoveryDashboardWidget.DATA_DELETION,
  ];
  const [isDataCoverageShown, setIsDataCoverageShown] = useState(false);
  const [isDataRiskShown, setIsDataRiskShown] = useState(false);
  const [isSensitiveDataShown, setIsSensitiveDataShown] = useState(false);
  const [isDataDeletionShown, setIsDataDeletionShown] = useState(false);
  const { activeFilters, activeWidgetIds, externalAppliedFilters, toolbarActions, isSavedViewsReady } =
    useDashboardToolbar(widgetIds, toolbarFilters, DISCOVERY_DASHBOARD_ID, true);

  useEffect(() => {
    const isActiveWidget = (widgetId: DiscoveryDashboardWidget) => !!activeWidgetIds?.includes(widgetId);
    setIsDataCoverageShown(isActiveWidget(DiscoveryDashboardWidget.DATA_COVERAGE));
    setIsDataRiskShown(isActiveWidget(DiscoveryDashboardWidget.DATA_RISK));
    setIsSensitiveDataShown(isActiveWidget(DiscoveryDashboardWidget.SENSITIVE_DATA));
    setIsDataDeletionShown(isActiveWidget(DiscoveryDashboardWidget.DATA_DELETION));
  }, [activeWidgetIds]);

  const widgetSelectionItems: BigidMenuItemProps[] = [
    { id: DiscoveryDashboardWidget.DATA_COVERAGE, label: 'Data Coverage' },
    { id: DiscoveryDashboardWidget.DATA_RISK, label: 'Data Risk' },
    { id: DiscoveryDashboardWidget.SENSITIVE_DATA, label: 'Sensitive Data By Policy' },
    { id: DiscoveryDashboardWidget.DATA_DELETION, label: 'Data Deletion & Retention' },
  ];

  const isSplitSectionApply = isSensitiveDataShown && isDataDeletionShown;

  return (
    <ExecutiveDashboard
      dashboardId={DISCOVERY_DASHBOARD_ID}
      dashboardType={DashboardType.DISCOVERY}
      toolbarFilters={toolbarFilters}
      activeFilters={activeFilters}
      activeWidgetIds={activeWidgetIds}
      widgetSelectionItems={widgetSelectionItems}
      toolbarActions={toolbarActions}
      externalAppliedFilters={externalAppliedFilters}
      isSavedFiltersTabsDisplayed={true}
      isExportButtonDisplayed={true}
      isLoading={!isSavedViewsReady}
    >
      <DisocveryDashboardContext.Provider value={{ activeFilters }}>
        <WidgetsContainer>
          {isDataCoverageShown && (
            <WidgetWrapper>
              <DataCoverageWidget title="Data Coverage" />
            </WidgetWrapper>
          )}
          {isDataRiskShown && (
            <WidgetWrapper>
              <DataRiskWidget title="Data Risk" />
            </WidgetWrapper>
          )}

          <SplitSection>
            {isSensitiveDataShown && (
              <WidgetWrapper isHalfWidth={isSplitSectionApply}>
                <EmptyWidget title="Sensitive Data By Policy" />
              </WidgetWrapper>
            )}
            {isDataDeletionShown && (
              <WidgetWrapper isHalfWidth={isSplitSectionApply}>
                <EmptyWidget title="Data Deletion & Retention" />
              </WidgetWrapper>
            )}
          </SplitSection>
        </WidgetsContainer>
      </DisocveryDashboardContext.Provider>
    </ExecutiveDashboard>
  );
};
