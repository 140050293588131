import { createContext } from 'react';
import {
  ActivityMonitoringDetailsReducerAction,
  ActivityMonitoringDetailsReducerState,
} from './ActivityMonitoringDetailsReducer';

export interface ActivityMonitoringDetailsState extends ActivityMonitoringDetailsReducerState {
  dispatch: React.Dispatch<ActivityMonitoringDetailsReducerAction>;
}

export const ActivityMonitoringDetailsContext = createContext<ActivityMonitoringDetailsState>(null);
