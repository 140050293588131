import React, { FC, useMemo, useState } from 'react';
import {
  BigidGridColumn,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  CardSize,
  ViewType,
} from '@bigid-ui/grid';
import { PrimaryButton, ToolbarActionType } from '@bigid-ui/components';
import {
  convertClassifierToScanClassifier,
  DEFAULT_CATEGORY_SORT,
  DEFAULT_SORTING,
  fetchClassifiersByNames,
  getClassifierIds,
  getClassifierIdsOfBulkSelected,
  getClassifiersByCategoryForGrid,
  getClassifiersByRegulationForGrid,
  getListOfCountriesForFilter,
  TEXT_SEARCH_FILTER,
} from './classificationService';
import { BulkClassifierSelectionGridRow, ClassificationStepTypesProps } from './classificationStepTypes';
import {
  ClassificationStepCardHeader,
  ClassificationStepContent,
  ClassificationStepFooter,
} from './ClassificationStepCard';
import { BigidAddIcon } from '@bigid-ui/icons';
import { toLower, uniqBy } from 'lodash';
import { BulkClassifierSelectionMethod } from '../../../scanTemplateTypes';
import { openManualClassifierSelection } from './ClassificationManualSelectionGrid';
import { showConfirmationDialog } from '../../../../../../services/confirmationDialogService';
import { notificationService } from '../../../../../../services/notificationService';
import { CATEGORIES, REGULATIONS } from '../../../../ScanUtils';
import { getNormalizedClassifiersViaPost } from '../../../../../Classification/ClassifiersService';

const ClassificationSelectionGridColumns: BigidGridColumn<BulkClassifierSelectionGridRow>[] = [];

let manuallySelectedClassifiers: string[] = [];

export const ClassificationSelectionGrid: FC<ClassificationStepTypesProps> = ({
  scanTemplateFormData,
  setScanTemplateFormData,
  selectedTabLabel,
}) => {
  const [searchText, setSearchText] = useState('');
  const isCategoryView = scanTemplateFormData.bulkClassifierSelectionMethod === BulkClassifierSelectionMethod.CATEGORY;
  const countryFilterOptions = useMemo(getListOfCountriesForFilter, []);

  const pluralSelectedTabLabel =
    toLower(selectedTabLabel) === toLower(BulkClassifierSelectionMethod.CATEGORY) ? CATEGORIES : REGULATIONS;

  const clearAllDialogProps = {
    actionName: 'Clear all',
    cancelButtonName: 'No',
    actionButtonName: 'Yes',
    entityNameSingular: `selected ${pluralSelectedTabLabel}/classifiers`,
    customDescription: `Are you sure you wish to clear all selected ${pluralSelectedTabLabel} and classifiers?`,
  };

  const buttons = useMemo(() => {
    const handleManualClassificationSelectionSave = async () => {
      try {
        const { classifiers } = await getNormalizedClassifiersViaPost(
          {
            filter: [
              {
                field: 'name',
                operator: 'in',
                value: manuallySelectedClassifiers,
              },
            ],
          },
          manuallySelectedClassifiers,
        );
        const classifiersForProfile = convertClassifierToScanClassifier(classifiers);
        setScanTemplateFormData(prevState => {
          const manuallySelectedClassifierIds = getClassifierIds(classifiersForProfile);
          const classifiersFromBulkSelection = getClassifierIdsOfBulkSelected(prevState.bulkClassifierSelection || []);
          const currClassifiers =
            prevState.classifiers?.filter(
              ({ name }) => classifiersFromBulkSelection.includes(name) || manuallySelectedClassifierIds.includes(name),
            ) || [];
          return {
            ...prevState,
            classifiers: uniqBy([...currClassifiers, ...classifiersForProfile], 'name'),
          };
        });
      } catch (e) {
        notificationService.error('Failed to fetch selected classifiers. See logs for more information');
      }
    };

    return [
      {
        text: 'Save',
        component: PrimaryButton,
        onClick: handleManualClassificationSelectionSave,
        dataAid: 'saveClassifierSelectionButton',
        isClose: true,
      },
    ];
  }, [setScanTemplateFormData]);

  const saveTextFilterValue = (textSearchFilterValue: string) => {
    if (textSearchFilterValue) {
      setSearchText(textSearchFilterValue);
    } else {
      setSearchText('');
    }
  };

  const classifiersSelectionGridConfig: BigidGridWithToolbarProps<BulkClassifierSelectionGridRow> = useMemo(() => {
    const setManuallySelectedClassifiers = (classifiers: string[]): void => {
      manuallySelectedClassifiers = classifiers;
    };

    return {
      columns: ClassificationSelectionGridColumns,
      pageSize: 100,
      customRowIdName: 'name',
      entityName: isCategoryView ? 'Categories' : 'Regulations',
      defaultSorting: isCategoryView ? DEFAULT_CATEGORY_SORT : DEFAULT_SORTING,
      showSortingControls: true,
      hideColumnChooser: true,
      showSelectAll: false,
      filterToolbarConfig: {
        searchConfig: {
          searchFilterKeys: ['name'],
          placeholder: `${
            isCategoryView ? BulkClassifierSelectionMethod.CATEGORY : BulkClassifierSelectionMethod.REGULATION
          } name`,
          initialValue: searchText,
          operator: TEXT_SEARCH_FILTER,
          autoSubmit: true,
        },
        filters: isCategoryView
          ? []
          : [
              {
                title: 'Location',
                field: 'flagCode',
                operator: 'in',
                options: countryFilterOptions,
                value: [],
                displayLimit: countryFilterOptions.length,
              },
            ],
      },
      toolbarActions: [
        {
          label: 'Add Classifiers',
          icon: () => <BigidAddIcon />,
          execute: async () => {
            await openManualClassifierSelection({
              isBulkSelection: false,
              setManuallySelectedClassifiers,
              buttons,
              scanTemplateContext: { setScanTemplateFormData, scanTemplateFormData },
            });
            return {};
          },
          disable: () => false,
          show: () => true,
          placement: 'end',
          type: ToolbarActionType.TERTIARY,
        },
        {
          label: 'Clear all',
          execute: async () => {
            const shouldDelete = await showConfirmationDialog(clearAllDialogProps);
            shouldDelete &&
              setScanTemplateFormData(prevState => {
                return { ...prevState, classifiers: [], bulkClassifierSelection: [] };
              });
            return {};
          },
          disable: () => false,
          show: () => true,
          placement: 'end',
          type: ToolbarActionType.TERTIARY,
        },
      ],
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        const bulkSelectedClassifiers = scanTemplateFormData.bulkClassifierSelection || [];
        if (isCategoryView) {
          return getClassifiersByCategoryForGrid(bulkSelectedClassifiers, queryComponents);
        } else {
          return getClassifiersByRegulationForGrid(bulkSelectedClassifiers, queryComponents, saveTextFilterValue);
        }
      },
      defaultView: ViewType.CARD,
      customViewConfiguration: {
        [ViewType.CARD]: {
          cardSize: CardSize.EXTRA_SMALL,
          renderHeader: (row: BulkClassifierSelectionGridRow) => (
            <ClassificationStepCardHeader
              classificationStepContext={{ setScanTemplateFormData, scanTemplateFormData }}
              bulkSelectionData={row}
            />
          ),
          renderContent: (row: BulkClassifierSelectionGridRow) => <ClassificationStepContent bulkSelectionData={row} />,
          renderFooter: (row: BulkClassifierSelectionGridRow) => (
            <ClassificationStepFooter
              classificationStepContext={{ setScanTemplateFormData, scanTemplateFormData }}
              bulkSelectionData={row}
            />
          ),
        },
      },
    };
  }, [buttons, countryFilterOptions, isCategoryView, scanTemplateFormData, searchText, setScanTemplateFormData]);

  return <BigidGridWithToolbar {...classifiersSelectionGridConfig} />;
};
