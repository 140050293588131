import { intersection, isEmpty, isEqual, isNull, omit, random, uniqBy } from 'lodash';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import {
  BigidFilter,
  BigidFilterOptionValueType,
  BigidMenuItemProps,
  EntityEvents,
  entityEventsEmitter,
  BigidDropdownValue,
  BigidColorsV2,
  BigidDropdownOption,
} from '@bigid-ui/components';
import { getFixedT } from '../translations';
import {
  Category,
  CLASSIFIER_TYPES,
  ClassifierGridRow,
  AddClassifierActionTypes,
  DocClassifier,
  FILTER_KEYS,
  NerClassifier,
  NERPayload,
  REGEX_FORM_TYPES,
  RegexFormFields,
  GeneralActionTypes,
  ClassifierVersionState,
} from '../types/ClassifierTypes';
import { ASSIGN_CATEGORIES_TYPE, AssignCategories, AssignCategoryRequest } from '../types/AssignCategories';
import { notificationService } from '../../../services/notificationService';
import { Attributes } from '../types/Attributes';
import { fetchAttributes, fetchRegulations, getCategoriesDetails } from '../services/classifiersManagementService';
import { queryService } from '../../../services/queryService';
import { CuratedAttributeType } from '../../Curation/curationService';
import { isPermitted } from '../../../services/userPermissionsService';
import { REVIEW_FINDINGS_PERMISSIONS } from '@bigid/permissions';
import { CURATION_ATTRIBUTE_PREFIX } from '../../Curation/useCurationState';

const BASE_CLASSIFIER_PREFIX = 'classifier.';
const MD_PREFIX = 'MD::';

export const navigateToForbiddenPageIfFeatureFlagOff = () => {
  const isNewAttributePageEnabled = getApplicationPreference('NEW_CLASSIFIERS_ATTRIBUTES_PAGE_ENABLED');

  if (!isNewAttributePageEnabled) {
    $state.go(CONFIG.states.FORBIDDEN);
  }
};

export const isClassfierExcludeListFF = () => getApplicationPreference('CLASSIFIER_EXCLUDE_LIST_FF');
export const showNegativeSupporTermFields = () => getApplicationPreference('NEGATIVE_SUPPORT_TERM_FF');
export const isMetadataClassifierEnabled = () => getApplicationPreference('METADATA_CLASSIFIER_ENABLED');
export const isImportExportSupportedFFEnabled = () => getApplicationPreference('CLASSIFICATION_IMPORT_EXPORT_ENABLED');
export const isClassifierTesterEnabled = () => getApplicationPreference('CLASSIFIER_TESTER_ENABLED');
export const getIsNerClassifierFFEnabled = () => getApplicationPreference('NER_CLASSIFIER_ENABLED_FEATURE_FLAG');
export const getIsClassifyFileNamesEnabled = () => getApplicationPreference('CLASSIFY_FILE_NAMES_ENABLED');
export const getIsClassifiersAutoUpgradeDisable = () => getApplicationPreference('DISABLE_AUTO_UPGRADE_OF_CLASSIFIERS');
export const getIsShowGuideClassifierManagement = () =>
  getApplicationPreference('SHOW_DIALOG_GUIDE_CLASSIFIER_MANAGEMENT');
export const getIsClassifiersTuningEnabled = () => getApplicationPreference('CLASSIFIER_TUNING_ENABLED');

export const getActionsMenuItems = ({
  handleImport,
  handleReviewAvailableUpdates,
  isImportPermitted,
  isReviewAvailableUpdates,
}: GeneralActionTypes): BigidMenuItemProps[] => {
  const t = getFixedT('header');

  return [
    ...(isImportPermitted ? [{ label: t('import'), onClick: handleImport }] : []),
    ...(isReviewAvailableUpdates
      ? [{ label: t('reviewAvailableUpdates'), onClick: handleReviewAvailableUpdates }]
      : []),
  ];
};

export const getAddClassifierMenuItems = ({
  handleCreateRegex,
  handleCreateDOC,
  handleCreateNER,
  isDocMenuItemVisible,
  isCreatePermitted,
  isNerEnabled,
}: AddClassifierActionTypes): BigidMenuItemProps[] => {
  const t = getFixedT('header');

  return [
    ...(isCreatePermitted ? [{ label: t('createRegex'), onClick: handleCreateRegex }] : []),
    ...(isDocMenuItemVisible ? [{ label: t('createDOC'), onClick: handleCreateDOC }] : []),
    ...(isNerEnabled ? [{ label: t('createNER'), onClick: handleCreateNER }] : []),
  ];
};

export const isNer = (classifierType: CLASSIFIER_TYPES) => classifierType === CLASSIFIER_TYPES.NER;
export const isDoc = (classifierType: CLASSIFIER_TYPES) => classifierType === CLASSIFIER_TYPES.DOC;
export const isCorrelation = (classifierType: CLASSIFIER_TYPES) => classifierType === CLASSIFIER_TYPES.CORRELATION;
export const isMetadata = (classifierType: CLASSIFIER_TYPES) => classifierType === CLASSIFIER_TYPES.METADATA;
export const isRegex = (classifierType: CLASSIFIER_TYPES) =>
  REGEX_FORM_TYPES.includes(classifierType) || isNull(classifierType);

export const isRequestSucceeded = (response: any) => response?.data?.result === 'OK';

// Grid actions
export const updateGridItemById = (_id: string, payload: Partial<ClassifierGridRow>) =>
  entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, _id, payload);

export const deleteGridItemById = (classifier: ClassifierGridRow) =>
  entityEventsEmitter.emit(EntityEvents.DELETE, [classifier]);

export const addGridItem = (classifier: ClassifierGridRow, id: string) =>
  entityEventsEmitter.emit(EntityEvents.ADD, [{ ...classifier, _id: id, id }]);

export const reloadGrid = () => entityEventsEmitter.emit(EntityEvents.RELOAD);

export const clearFilters = () => entityEventsEmitter.emit(EntityEvents.CLEAR_FILTERS);

export const getClassifierTypeValue = (type: CLASSIFIER_TYPES) => {
  const t = getFixedT('classifierTypes');

  return type === CLASSIFIER_TYPES.CONTENT || isNull(type) ? t(CLASSIFIER_TYPES.CONTENT) : t(type);
};

export const getNerClassifierTitle = (classifierName: string, classifierModel: string) => {
  return classifierModel === 'default' ? classifierName : `${classifierName}(${classifierModel})`;
};

export const getClassificationNameByAdvencedClassifier = (classifier: NerClassifier | DocClassifier) => {
  if (classifier.type === CLASSIFIER_TYPES.DOC) {
    return classifier._id;
  }

  if (classifier.type === CLASSIFIER_TYPES.NER) {
    return (classifier as NerClassifier)['classifierName'];
  }
};

export const customizeClassifier = (classifiers: NerClassifier[] | DocClassifier[]) => {
  return classifiers.map(classifier => {
    let isOutOfTheBox;

    if (classifier.type === CLASSIFIER_TYPES.DOC) {
      isOutOfTheBox = classifier.modelId === 'default';
    }
    if (classifier.type === CLASSIFIER_TYPES.NER) {
      isOutOfTheBox = classifier.isOutOfTheBox;
    }

    return {
      classification_name: getClassificationNameByAdvencedClassifier(classifier),
      classifierType: classifier.type,
      isAdvanced: true,
      isOutOfTheBox,
      original_name: 'classifier.LineageGuid',
      categories: [
        {
          _id: 'cat_item_fhA6',
          color: '#51b749',
          display_name: 'testCategory001',
          unique_name: 'cat_item_fhA6',
          description: 'description',
          glossary_id: 'cat_item_fhA6',
        },
      ],
      ...classifier,
    };
  });
};

export const getFilteredClassifications = (classifications: ClassifierGridRow[]) => {
  if (getIsClassifyFileNamesEnabled()) {
    return classifications;
  }

  return classifications?.filter(
    classifier => classifier[RegexFormFields.classifierType] !== CLASSIFIER_TYPES.CONTENT_AND_METADATA,
  );
};

export const getUpdatedNerOrDocPayload = (classifier: ClassifierGridRow): Partial<NerClassifier> => {
  return omit(classifier, ['classification_name', 'classifierType', 'categories', 'isAdvanced']);
};

const getFilteredClassifiersByType = (
  classifiers: ClassifierGridRow[],
  typeFilterValue: BigidFilterOptionValueType[],
) => {
  if (typeFilterValue.find(filterValiue => filterValiue === 'data')) {
    typeFilterValue.push(null);
  }

  return classifiers.filter(classifier => {
    return typeFilterValue.includes(classifier.classifierType);
  });
};

const getFilteredClassifiersByOrigin = (
  classifiers: ClassifierGridRow[],
  originFilterValue: BigidFilterOptionValueType[],
) => {
  return classifiers.filter(classifier => {
    return originFilterValue.includes(classifier.isOutOfTheBox);
  });
};

const getFilteredClassifiersByEnabled = (
  classifiers: ClassifierGridRow[],
  enabledFilterValue: BigidFilterOptionValueType[],
) => {
  return classifiers.filter(classifier => {
    return enabledFilterValue.includes(classifier.enabled);
  });
};

const getFilteredClassifiersByDataType = (
  classifiers: ClassifierGridRow[],
  dataTypeFilterValue: BigidFilterOptionValueType[],
) => {
  return classifiers.filter(classifier => !isEmpty(intersection(dataTypeFilterValue, classifier.dataType)));
};

const getFilteredClassifiersByName = (classifiers: ClassifierGridRow[], nameFilterValue: string) => {
  return classifiers.filter(classifier => {
    return (
      classifier?.classification_name?.toLowerCase().includes(nameFilterValue.toLowerCase()) ||
      classifier?.description?.toLowerCase().includes(nameFilterValue.toLowerCase())
    );
  });
};

const getFilteredClassifiersByCategories = (
  classifiers: ClassifierGridRow[],
  nameFilterValue: BigidFilterOptionValueType[],
) => {
  return classifiers.filter(classifier =>
    classifier.categories?.find(category => nameFilterValue.includes(category._id ?? category.unique_name)),
  );
};

export const getFilteredClassifiersByQuery = (classifiers: ClassifierGridRow[], filter: BigidFilter) => {
  let filteredClassifiers = [...classifiers];
  filter.forEach(currentFilter => {
    switch (currentFilter.field) {
      case FILTER_KEYS.type:
        filteredClassifiers = getFilteredClassifiersByType(
          filteredClassifiers,
          currentFilter.value as BigidFilterOptionValueType[],
        );
        break;
      default:
      case FILTER_KEYS.isOutOfTheBox:
        filteredClassifiers = getFilteredClassifiersByOrigin(
          filteredClassifiers,
          currentFilter.value as BigidFilterOptionValueType[],
        );
        break;
      case FILTER_KEYS.enabled:
        filteredClassifiers = getFilteredClassifiersByEnabled(
          filteredClassifiers,
          currentFilter.value as BigidFilterOptionValueType[],
        );
        break;
      case FILTER_KEYS.dataType:
        filteredClassifiers = getFilteredClassifiersByDataType(
          filteredClassifiers,
          currentFilter.value as BigidFilterOptionValueType[],
        );
        break;
      case FILTER_KEYS.name:
        filteredClassifiers = getFilteredClassifiersByName(filteredClassifiers, currentFilter.value as string);
        break;
      case FILTER_KEYS.categories:
        filteredClassifiers = getFilteredClassifiersByCategories(
          filteredClassifiers,
          currentFilter.value as BigidFilterOptionValueType[],
        );
        break;
    }
  });

  return filteredClassifiers;
};

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const mapCategoriesToDropdownValues = (categories: Category[]): BigidDropdownValue => {
  return (
    categories?.map(category => {
      const { display_name, unique_name } = category;

      return {
        value: category,
        id: unique_name,
        displayValue: display_name,
      };
    }) || []
  );
};

export const mapLegacyCategoriesToDropdownValues = (categories: Category[]) => {
  return (
    categories?.map(category => {
      const { display_name, glossary_id } = category;

      return {
        value: category,
        id: glossary_id,
        displayValue: display_name,
      };
    }) || []
  );
};

export const getUniqueCategoriesList = (categories: Category[]) => uniqBy(categories, ({ unique_name }) => unique_name);

export const getAssignCategoryPayload = (
  attributes: AssignCategories[],
  selectedCategories: BigidDropdownValue,
  assignType: ASSIGN_CATEGORIES_TYPE,
): AssignCategoryRequest[] => {
  const selected = selectedCategories.map(({ id, displayValue }) => ({ unique_name: id, display_name: displayValue }));

  return attributes.map(({ original_name, categories = [] }) => ({
    original_name,
    categories: getUniqueCategoriesList([
      ...categories?.map(({ unique_name, display_name }) => ({
        unique_name: unique_name,
        display_name: display_name,
      })),
      ...selected,
    ] as Category[]),
  }));
};

export const getMappedAssignedCategoriesInGridDataFormat = (
  initialGridData: AssignCategories[],
  selectedCategories: BigidDropdownOption[],
  assignType: ASSIGN_CATEGORIES_TYPE,
  newCategory?: Category,
) => {
  const previousSelectedCategories = selectedCategories.map(({ displayValue, id, value }) => ({
    color: value.color,
    description: value.description,
    display_name: displayValue,
    unique_name: id,
  })) as Category[];

  const allCategories = newCategory ? previousSelectedCategories.concat(newCategory) : previousSelectedCategories;

  return initialGridData?.map(attr => {
    return {
      ...attr,
      categories: getUniqueCategoriesList([...(attr?.categories?.length ? attr.categories : []), ...allCategories]),
    };
  });
};

export const handleBulkNotifications = (enabled: boolean, isUpdatesMoreThanOne: boolean) => {
  const t = getFixedT('');

  if (enabled) {
    if (isUpdatesMoreThanOne) {
      notificationService.success(t('notifications.globalsEnabledSuccessfully'));
    } else {
      notificationService.success(t('notifications.globalEnabledSuccessfully'));
    }

    return;
  }

  if (!enabled) {
    if (isUpdatesMoreThanOne) {
      notificationService.success(t('notifications.globalsDisabledSuccessfully'));
    } else {
      notificationService.success(t('notifications.globalDisabledSuccessfully'));
    }

    return;
  }
};

interface NerNERPayloadWithType extends NERPayload {
  type: CLASSIFIER_TYPES;
}

export const getDefaultAttributeName = (classifier: ClassifierGridRow | NerNERPayloadWithType): string => {
  if ('classifierType' in classifier) {
    if (
      classifier.classifierType === null ||
      classifier.classifierType === CLASSIFIER_TYPES.CONTENT ||
      classifier.classifierType === CLASSIFIER_TYPES.CONTENT_AND_METADATA
    ) {
      return BASE_CLASSIFIER_PREFIX + classifier.classification_name;
    }

    if (classifier.classifierType === CLASSIFIER_TYPES.METADATA) {
      return BASE_CLASSIFIER_PREFIX + MD_PREFIX + classifier.classification_name;
    }
  }

  if ('type' in classifier) {
    if (classifier.type === CLASSIFIER_TYPES.NER) {
      if (classifier.modelName !== 'default') {
        return BASE_CLASSIFIER_PREFIX + 'NER::' + classifier.modelName + '::' + classifier.classifierName;
      }
      return BASE_CLASSIFIER_PREFIX + 'NER::' + classifier.classifierName;
    }
  }

  return '';
};

export const getCategoriesGridFormat = (categories: BigidDropdownOption[]): Category[] => {
  return categories.map(({ displayValue, id, value }) => ({
    color: value.color,
    description: value.description,
    display_name: displayValue,
    unique_name: id,
  })) as Category[];
};

export const getDefaultColorCategory = () => {
  const colors = [
    BigidColorsV2.purple[500],
    BigidColorsV2.purple[300],
    BigidColorsV2.magenta[300],
    BigidColorsV2.red[300],
    BigidColorsV2.orange[300],
    BigidColorsV2.yellow[300],
    BigidColorsV2.green[300],
    BigidColorsV2.cyan[300],
    BigidColorsV2.blue[300],
    BigidColorsV2.blue[500],
  ];

  const randomIndex = random(0, colors.length);
  return colors[randomIndex];
};

export const mapAttributesToDropdownValues = (attributes: Attributes[]): BigidDropdownOption[] => {
  return (
    attributes?.map(attr => {
      const { name, glossary_id } = attr;

      return {
        value: attr,
        id: glossary_id,
        displayValue: name,
      };
    }) || []
  );
};

export const getCategoriesFilterDropdown = async () => {
  const categoriesDetails = await getCategoriesDetails();
  const categoriesList = categoriesDetails.data.map(category => ({
    label: category.display_name,
    value: category.glossary_id,
    isSelected: false,
  }));
  return categoriesList;
};

export const getAttributesFilterDropdown = async () => {
  const attributesResponse = await fetchAttributes();
  const attributesList = attributesResponse?.data.map(attribute => ({
    label: attribute.name,
    value: attribute.glossary_id,
    isSelected: false,
  }));
  return attributesList;
};

export const getRegulationsFilterDropdown = async () => {
  const params = queryService.getGridConfigQuery({
    limit: 2000,
    requireTotalCount: true,
  });
  const { regulations } = await fetchRegulations(params);

  const regulationsList = regulations.map(regulation => ({
    label: regulation.name,
    value: regulation._id,
    isSelected: false,
  }));
  return regulationsList;
};

export const isClassifierNeedsToBeUpdated = ({ version, version_state }: ClassifierGridRow) =>
  getIsClassifiersAutoUpgradeDisable() && version && version_state === ClassifierVersionState.NEEDS_UPDATE;

export const isClassifierVersionDowngradeAvailable = ({ version, version_state }: ClassifierGridRow) =>
  getIsClassifiersAutoUpgradeDisable() && version && version_state === ClassifierVersionState.DOWNGRADE_AVAILABLE;

export const getDocAppUrl = (appId: string) => `#/customApp/${appId}/actions`;

export const isEqualAttributesAndCategories = (
  classifier: ClassifierGridRow,
  classifierToCompare: Record<string, any>,
) => {
  const isEqualAttributes = isEqual(
    classifier?.friendly_name ?? '',
    classifierToCompare?.attribute?.friendly_name ?? '',
  );

  const isEqualCategories = isEqual(
    classifier?.categories?.map(category => category.unique_name).sort() ?? [],
    classifierToCompare?.categories == null || classifierToCompare?.categories == ''
      ? []
      : classifierToCompare?.categories?.map((category: Category) => category.unique_name).sort(),
  );

  return { isEqualAttributes, isEqualCategories };
};

export const isRegexNotIncludeMetadata = (classifierType: CLASSIFIER_TYPES) =>
  classifierType === CLASSIFIER_TYPES.CONTENT ||
  classifierType === CLASSIFIER_TYPES.CONTENT_AND_METADATA ||
  isNull(classifierType);

export const getPayloadForAssignCategoriesAndAttributes = async (
  classifier: ClassifierGridRow,
  newClassifierName: string,
) => {
  const { friendly_name, categories, glossary_id } = classifier;
  const isDefaultClassifierAttribute = getDefaultAttributeName(classifier) === friendly_name;
  const newOriginalName = getDefaultAttributeName({ ...classifier, classification_name: newClassifierName });

  let glossary_id_value = glossary_id;

  if (!glossary_id_value && !isDefaultClassifierAttribute) {
    const attributesResponse = await fetchAttributes();
    glossary_id_value = (attributesResponse?.data as Attributes[])?.find(
      attr => attr?.name === friendly_name,
    )?.glossary_id;
  }

  const attribute = {
    friendly_name: isDefaultClassifierAttribute ? newOriginalName : friendly_name,
    original_name: newOriginalName,
    glossary_id: glossary_id_value || '',
  };

  return {
    categories,
    attribute,
  };
};

export const isEqualClassifierCreationMode = (
  formValues: Record<string, any>,
  initialValues: Partial<ClassifierGridRow>,
) => {
  if (formValues.classifierType === CLASSIFIER_TYPES.NER) {
    if (formValues?.extraFields?.length !== 1) {
      return false;
    }

    if (formValues?.extraFields?.length === 1) {
      const extraFiedClassificationName = formValues?.extraFields[0].value?.classification_name;
      const extraFiedNerEntityMap = formValues?.extraFields[0].value?.nerEntityMap;
      if (!isEmpty(extraFiedClassificationName) || !isEmpty(extraFiedNerEntityMap)) {
        return false;
      }
    }
  }

  const formValuesOmit = omit(formValues, ['__valuesCounter', 'attribute', 'categories', 'extraFields']);
  const initialValuesOmit = omit(initialValues, ['attribute', 'categories', 'extraFields']);

  const isClassifierEqual = isEqual(formValuesOmit, initialValuesOmit);
  const isAttributeChanged = formValues?.attribute?.friendly_name && formValues?.attribute?.friendly_name !== '';
  const isCategoriesChanged = formValues?.categories && !isEmpty(formValues?.categories);

  return isClassifierEqual && !Boolean(isAttributeChanged) && !Boolean(isCategoriesChanged);
};

export const getShouldShowClassifierTuning = (totalFields: number) => {
  const hasAccess = isPermitted(REVIEW_FINDINGS_PERMISSIONS.ACCESS.name);
  const hasFindings = totalFields > 0;
  return hasAccess && hasFindings;
};

export const goToClassifierTuning = (
  classifierName: string,
  attributeType: CuratedAttributeType,
  displayName: string,
) => {
  $state.go(CONFIG.states.CLASSIFIER_FINDINGS, {
    attributeName: `${CURATION_ATTRIBUTE_PREFIX}${classifierName}`,
    attributeType,
    structuredOnly: false,
    displayName,
  });
};

export const getValueOrDefault = (value: any, defaultValue?: any) => {
  return value === undefined ? defaultValue : value;
};

export const isClassifierEnabled = ({ enabled }: ClassifierGridRow) => {
  return enabled === undefined ? true : enabled;
};
