import React from 'react';
import { BigidFilter, BigidStatusBadge, BigidStatusBadgeSize, BigidStatusBadgeType } from '@bigid-ui/components';
import { BigidGridQueryComponents, BigidGridSorting } from '@bigid-ui/grid';
import { JiraTicketMetadata } from '../ActionableInsights/actionableInsightsService';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { httpService } from '../../services/httpService';
import { Filters } from '../../types/actionableInsights';
import { RiskMatrixMetadata } from '../Risks/RiskMatrixDefaults';
import { ApplicationEntity } from '../ApplicationSetup/types';
import { queryService } from '../../services/queryService';

export const STORED_SERVICE_NAME = 'SelectedObject';

export interface FiltersFromServer {
  totalCount: number;
  value: IdWithName | string | number;
}

export enum RiskState {
  OPEN = 'open',
  CLOSED = 'closed',
  RESOLVED = 'resolved',
}

export enum RiskSeverityLevel {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export interface PrivacyRisk {
  id: string;
  name: string;
  description?: string;
  category: string;
  owner?: string | null;
  controls?: IdWithName[];
}

export interface RiskLevelDto {
  value: number;
  name: string;
  color: string;
}

export interface ProbabilityAndImpactDto {
  value: number;
  name: string;
}

export interface EmbedVendorAndLegalEntity {
  id: string;
  name: string;
  type?: string;
}

export interface Question {
  id: string;
  name: string;
  description?: string;
}

export interface QuestionDto extends Question {
  status?: string;
  answer?: string;
  answeredBy?: string;
}

export interface UserModelDto {
  name: string;
  email: string;
}

type CaseActionBody = {
  type: string;
  subType: string;
  additionalProperties: {
    field: string;
    newValue: string;
    casesFilters?: {
      filterField: string;
      filterValues: string[];
    }[];
    auditReason?: string;
    allCases?: boolean;
  };
};
export interface ReadPrivacyRiskCaseDto {
  id: string;
  privacyRisk: PrivacyRisk;
  caseLabel: string;
  riskLevel: RiskLevelDto;
  probability: ProbabilityAndImpactDto;
  impact: ProbabilityAndImpactDto;
  source: IdWithName;
  sourceType: string;
  asset: IdWithName;
  vendors: EmbedVendorAndLegalEntity[];
  legalEntities: EmbedVendorAndLegalEntity[];
  questionResponse: QuestionDto;
  controls: IdWithName[];
  createdBy?: UserModelDto;
  updatedBy?: UserModelDto;
  caseId: string;
  assignee: UserModelDto;
  caseStatus: IdWithName;
  caseType: string;
  note: string;
  updatedAt: Date;
  createdAt: Date;
}

export interface PrivacyRiskCasesResult {
  riskCases: ReadPrivacyRiskCaseDto[];
  totalCount?: number;
  hasAnyRiskCases?: boolean;
}

export interface RiskCasesDto {
  value: string;
  name: string;
  numOfCases: number;
  cases: ReadPrivacyRiskCaseDto[];
}

export interface GroupedPrivacyRiskCasesResult {
  riskCases: RiskCasesDto[];
  totalCount?: number;
  hasAnyRiskCases?: boolean;
}

export interface RiskData {
  caseStatus: IdWithName;
  caseLabel: string;
  vendors?: EmbedVendorAndLegalEntity[];
  assets: string;
  risk: string;
  source: IdWithName;
  assignee?: UserModelDto;
  riskCategory: string;
  severityLevel: RiskLevelDto;
  created_at: Date;
  id: string;
  sourceType: string;
}

export interface IdWithName {
  id: string;
  name: string;
}

export enum CaseStatusLabel {
  OPEN = 'open',
  REMEDIATED = 'remediated',
  ACKNOWLEDGED = 'acknowledged',
  ARCHIVED = 'archived',
  SILENCED = 'silenced',
}

export enum SourceType {
  PIA = 'PIA',
  ROPA = 'RoPA',
}

export enum ServiceTicketingType {
  JIRA = 'jira',
  SERVICE_NOW = 'tpa',
}

export enum JiraValidationErrorType {
  CANT_VERIFY = 'cantVerify',
  REQUEST_FAILURE = 'requestFailure',
}

export enum ContentTypes {
  CONFIGURATION = 'configuration',
  NO_CONFIGURATION_WITHOUT_ACCESS = 'noConfigurationWithoutAccess',
  TICKET_CREATION = 'ticketCreation',
  LOADING = 'loading',
  NO_CONFIGURATION = 'noConfiguration',
}

export type CaseStatusCode = 101 | 201 | 202 | 203 | 301;
export const caseStatusCodeToStatusMap: Map<CaseStatusCode, CaseStatusLabel> = new Map([
  [101, CaseStatusLabel.OPEN],
  [201, CaseStatusLabel.SILENCED],
  [202, CaseStatusLabel.ACKNOWLEDGED],
  [203, CaseStatusLabel.ARCHIVED],
  [301, CaseStatusLabel.REMEDIATED],
]);

export interface UserModel {
  email: string;
  name: string;
}

export interface RiskValue {
  value: number;
  name: string;
  color: string;
}

export interface PrivacyRiskCaseResponse {
  id: string;
  caseId: string; // readable id
  caseLabel: string;
  createdAt: string;
  riskLevel: RiskValue;
  probability: {
    value: number;
    name: string;
  };
  impact: {
    value: number;
    name: string;
  };
  privacyRisk: {
    id: string;
    name: string;
    description?: string;
    category?: string;
  };
  controls?: IdWithName[];
  caseStatus: IdWithName;
  assignee: UserModel;
  vendors?: {
    id: string;
    name: string;
    type: string;
  }[];
  asset?: IdWithName;
  legalEntities?: {
    id: string;
    name: string;
    type: string;
  }[];
  source: IdWithName; // assessment/bp
  sourceType: SourceType;
  questionResponse: {
    question: string;
    description?: string;
    answer?: string;
    answeredBy?: UserModel;
  };
  createdBy: UserModel;
  note?: string;
  ticketUrl?: string;
  ticketMetadata?: JiraTicketMetadata;
}

export enum ActivityLogActionTypes {
  STATUS_CHANGED = 'statusChanged',
  TICKET_CREATED = 'jiraTicketCreated',
  SEVERITY_CHANGED = 'severityChanged',
  SEVERITY_ATTACHED = 'severityAttached',
  ASSIGNEE_UPDATED = 'assigneeUpdated',
  ASSIGNEE_ATTACHED = 'assigneeAttached',
  NOTE_UPDATED = 'noteUpdated',
  NOTE_ATTACHED = 'noteAttached',
  CASE_CREATED = 'caseCreated',
  ASSET_ATTACHED = 'assetAttached',
  ASSET_CHANGED = 'assetChanged',
  CASE_DELETED = 'caseDeleted',
}

export interface ActivityLogData {
  activityLogs: {
    action: ActivityLogActionTypes;
    createdAt: string;
    caseId: string;
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    status?: number;
    reason?: string;
    updatedBy: UserModel;
  }[];
  totalCount: number;
}

const sanitizeSearch = (str: string) => (str ? str.trim() : '');

const sanitizeFiltersForServer = (filters?: BigidFilter): BigidFilter => {
  const newFilter = filters?.map(filterToMap =>
    filterToMap.operator === 'graterThenOrEqual'
      ? {
          operator: 'greaterThanOrEqual',
          id: filterToMap.id,
          field: filterToMap.field,
          value: filterToMap.value,
          caseSensitive: filterToMap.caseSensitive,
        }
      : filterToMap,
  );
  return newFilter as BigidFilter;
};

export const getGridConfigBody = ({ skip, limit, sort, filter }: BigidGridQueryComponents): unknown => {
  const search = filter?.filter(f => f.field == 'search');
  const filters = filter?.filter(f => f.field !== 'search');
  const newFilter = sanitizeFiltersForServer(filters);

  return {
    skip,
    limit,
    sort,
    filter: newFilter,
    search: !!search && search.length > 0 ? sanitizeSearch(search[0].value as string) : '',
  };
};

export const getRiskStatusBadge = (caseStatus: IdWithName) => {
  let type: BigidStatusBadgeType = BigidStatusBadgeType.INFO;
  let label = 'Open';
  switch (parseInt(caseStatus?.id)) {
    case 201:
      type = BigidStatusBadgeType.PENDING;
      label = 'Silenced';
      break;
    case 202:
      type = BigidStatusBadgeType.PENDING;
      label = 'Acknowledged';
      break;
    case 203:
      type = BigidStatusBadgeType.DISABLED;
      label = 'Archived';
      break;
    case 301:
      type = BigidStatusBadgeType.SUCCESS;
      label = 'Remediated';
      break;
  }
  return <BigidStatusBadge size={BigidStatusBadgeSize.SMALL} label={label} type={type} />;
};

export const convertUserNameToString = (
  username: string,
  firstName: string | undefined,
  lastName: string | undefined,
  hideUsername?: boolean,
) => {
  return (
    `${firstName ? `${firstName} ` : ''}${lastName ?? ''} ${hideUsername ? '' : `(${username})`}`.trim() || username
  );
};

export const isUrl = (str: string) => {
  return str && str.includes('http');
};

const removeStartingSlash = (path: string) => (path?.startsWith('/') ? path.slice(1) : path);

export const navigateTo = (path: string, appId: string) => () => {
  $state.go(CONFIG.states.CUSTOM_APP, {
    id: appId,
    appRoute: removeStartingSlash(path),
  });
};

const buildFilters = (filters: Omit<Filters, 'callback'>) => {
  const casesFilters = [];
  if (filters.caseIds?.length) {
    casesFilters.push({
      filterField: 'caseId',
      filterValues: filters.caseIds,
    });
  }
  return casesFilters;
};

export const updateCaseData = async (
  filters: Omit<Filters, 'callback'>,
  fields: { field: string; newValue: any; auditReason?: string }[],
) => {
  const { caseIds } = filters;

  const additionalProperties: any = {
    casesFilters: buildFilters({ caseIds }),
    caseTypes: ['privacyRiskCase'],
    fields,
  };
  const requestBody = {
    type: 'CasesDB',
    subType: 'multiFieldUpdateCases',
    additionalProperties,
  };
  await httpService.patch(`privacy/risk-cases/cases:action`, requestBody);
};

export const getRiskMatrix = async (): Promise<RiskMatrixMetadata> => {
  const {
    data: { data },
  } = await httpService.fetch('risk-matrix');
  const matrix: RiskMatrixMetadata = {
    cellData: data.cellData,
    impactLabel: data.yAxisLabel,
    impactLabels: data.yAxisLabels,
    matrixSize: data.matrixSize,
    probabilityLabel: data.xAxisLabel,
    probabilityLabels: data.xAxisLabels,
    levelLabel: data.levelLabel,
  };

  return matrix;
};

export const createAsset = async (newAssetName: string): Promise<{ id: string; _id: string }> => {
  const { data } = await httpService.post<{ id: string; _id: string }>(`applications?exposeSystemId=true`, {
    name: newAssetName,
  });
  return data;
};

export const getApplications = async (): Promise<ApplicationEntity[]> => {
  const {
    data: { applications },
  } = await httpService.fetch('applications');

  return applications;
};

export const getFilterConfiguration = async (): Promise<{ data: Record<string, FiltersFromServer[]> }> => {
  const res = await httpService.fetch<{ data: Record<string, FiltersFromServer[]> }>('/privacy/risk-cases/filters');
  return res.data;
};

export const searchGroupedRisks = async (gridConfigQuery: string): Promise<GroupedPrivacyRiskCasesResult> => {
  const res = await httpService.fetch<{ data: GroupedPrivacyRiskCasesResult }>(
    `/privacy/risk-cases/grouped-search?${gridConfigQuery}`,
  );

  return {
    ...res.data.data,
    riskCases: res.data.data.riskCases.map(item => ({ ...item, cases: item.cases.map(c => ({ ...c, id: c.caseId })) })),
  };
};

export const getRisks = async (gridConfigQuery: string): Promise<PrivacyRiskCasesResult> => {
  const res = await httpService.fetch<{ data: PrivacyRiskCasesResult }>(`/privacy/risk-cases?${gridConfigQuery}`);
  return { ...res.data.data, riskCases: res.data.data.riskCases.map(risk => ({ ...risk, id: risk.caseId })) };
};

export const getCaseActivityLogData = async (caseId: string, queryComponents: BigidGridQueryComponents) => {
  const sort: BigidGridSorting[] = [
    {
      field: 'created_at',
      order: 'desc',
    },
  ];
  const gridConfigQuery = queryService.getGridConfigQuery({
    ...queryComponents,
    sort,
    requireTotalCount: true,
  });
  const {
    data: { data },
  } = await httpService.fetch(`privacy/risk-cases/${caseId}/activity-logs?${gridConfigQuery}`);
  return data;
};
