export enum DataExplorerEventsEnum {
  SIDE_FILTER = 'Side_filter',
  EXPORT = 'Export',
  ASSIGN_TAG = 'Assign_Tag',
  ASSIGN_BULK_TAG = 'Assign_Bulk_Tag',
  SEARCH = 'Search',
  FOLLOW_OBJECT = 'Follow_Object',
  REPORT_AN_ISSUE = 'Report_an_Issue',
  ATTRIBUTE_TAB = 'Attribute_Tab',
  ATTRIBUTE_TAB_FETCH_VALUES = 'ATTRIBUTE_TAB_FETCH_VALUES',
  DUPLICATE_TAB = 'DUPLICATE_TAB',
  COLUMNS_TAB = 'Columns_Tab',
  ADD_BUSINESS_ATTRIBUTE = 'Add_BusinessAttribute',
  REMOVE_BUSINESS_ATTRIBUTE = 'Remove_BusinessAttribute',
  ADD_ATTRIBUTE = 'Add_Attribute',
  REMOVE_ATTRIBUTE_EVENT = 'Remove_Attribute_event',
  VIEW_SIMILAR_COLUMNS_EVENT = 'View_Similar_Columns_event',
  VIEW_SIMILAR_COLUMNS_LINKED_COLUMNS = 'View_Similar_Columns_Linked_columns',
  COLUMN_PROFILE_EVENT = 'ColumnProfile_event',
  PREVIEW_FILE_EVENT = 'Preview_File_event',
  PREVIEW_FILE_EVENT_DISPLAYONLY = 'Preview_File_event_Displayonly',
  VIEW_PREVIEW_TABLE = 'View_Preview_Table',
  ADD_TAG_EVENT = 'Add_Tag_event',
  REMOVE_TAG_EVENT = 'Remove_Tag_event',
  CUSTOMIZE_COLUMNS_EVENT = 'Customize_Columns_event',
  CLICK_WIDGET_EVENT = 'click_widget_event',
  ADD_DATASOURCE_OWNER_EVENT = 'Add_DatasourceOwner_event',
  VIEW_CLUSTER_EVENT = 'View_cluster_event',
  REMOVE_DATASOURCE_OWNER_EVENT = 'remove_DatasourceOwner_event',
  OBJECT_IGNORE_CONFIDENCE = 'object_ignore_confidence',
  OBJECT_REVERT_CONFIDENCE = 'object_revert_confidence',
  UNSTRUCTURED_PREVIEW_ATTRIBUTE_FILTER_TYPE = 'unstructured_preview_attribute_filter_type',
  UNSTRUCTURED_PREVIEW_ATTRIBUTE_FILTER_NAME = 'unstructured_preview_attribute_filter_name',
  GOTODISCOVERYINSIGHTS_CLICK = 'GOTODISCOVERYINSIGHTS_CLICK',
  ATTRIBUTE_PREVIEW_EXPIRED_MSG = 'attribute_preview_expired_msg',
}

const prefix = 'DataExplorer_';

export const getBiEventName = (event_type: DataExplorerEventsEnum): string => `${prefix}${event_type}`;
