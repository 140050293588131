import React from 'react';
import { FooterSectionRoot } from './FooterSection';
import { DataSourceIconByDsType } from '@bigid-ui/icons';
import { useTheme } from '@mui/styles';
import { BigidBody1 } from '@bigid-ui/components';

type FooterDsTypeProps = {
  dsType: string;
};
export const FooterDsType = ({ dsType }: FooterDsTypeProps) => {
  const { vars } = useTheme();

  return (
    <FooterSectionRoot>
      <DataSourceIconByDsType width={24} height={24} dataSourceType={dsType} />
      <BigidBody1 color={vars.tokens.bigid.foregroundTertiary}>{dsType}</BigidBody1>
    </FooterSectionRoot>
  );
};
