import React from 'react';
import styled from '@emotion/styled';
import { BigidBody1, PrimaryButton, TertiaryButton } from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  column-gap: 12px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
`;

interface MappedControlsDialogResetProps {
  handleResetDialog: (value: boolean, frameworkName?: string) => void;
  handleResetControls: (frameworkName: string) => void;
  currentFrameworkName: string;
  dataAid: string;
}

export const MappedControlsDialogReset = ({
  handleResetDialog,
  handleResetControls,
  currentFrameworkName,
  dataAid,
}: MappedControlsDialogResetProps) => {
  const { t } = useLocalTranslation('reset');
  return (
    <Main data-aid={dataAid}>
      <BigidBody1>
        {currentFrameworkName ? t('titleForFramework', { frameworkName: currentFrameworkName }) : t('title')}
      </BigidBody1>
      <ButtonsWrapper>
        <TertiaryButton size="medium" onClick={() => handleResetDialog(false)}>
          {t('discardButton')}
        </TertiaryButton>
        <PrimaryButton
          size="medium"
          onClick={() => {
            handleResetControls(currentFrameworkName);
          }}
        >
          {t('resetButton')}
        </PrimaryButton>
      </ButtonsWrapper>
    </Main>
  );
};
