import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { startCase } from 'lodash';
import { CompletedParentScanState, MapScanStatusToProgressStatus, ParentScanType, ScansGridRow } from '../ScanTypes';
import {
  calculateParentScanRightFilter,
  calculateProgressBarPercentage,
  calculateRightFilterColor,
  getScanOwnerChips,
  getScanTemplateRowIfEnabled,
  getChipPropsByState,
  isScanNameClickable,
  HYPERSCAN,
} from '../ScanUtils';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { ScansUITrackingEvent, trackEventScansView } from '../ScansEventTrackerUtils';

export const getInitialCompletedScansGridColumns: () => BigidGridColumn<ScansGridRow>[] = () => [
  {
    name: 'name',
    title: 'Scan Name',
    sortingEnabled: true,
    width: 350,
    formatter: {
      onClick: async ({ value, row }) => {
        const { link } = value;
        trackEventScansView(ScansUITrackingEvent.COMPLETED_SCANS_SCAN_INSIGHT_LINK_CLICK, { ...row });
        $state.go(CONFIG.states.SCANS_SCAN_INSIGHTS, link.linkParams);
        return {};
      },
    },
    getCellValue: row => ({
      link: {
        text: row.name,
        disabled: !isScanNameClickable(row),
        linkParams: {
          scanProfileName: row.name,
          scanId: row._id,
          fromState: CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
        },
      },
    }),
    type: BigidGridColumnTypes.LINK,
  },
  {
    name: 'type',
    title: 'Type',
    sortingEnabled: true,
    getCellValue: row => {
      if (row.type !== ParentScanType.HYPERSCAN) {
        return startCase(row.type);
      }
      return HYPERSCAN;
    },
    type: BigidGridColumnTypes.TEXT,
    width: 200,
  },
  {
    name: 'state',
    title: 'Status',
    sortingEnabled: false,
    getCellValue: ({ state }) => {
      return {
        chip: {
          ...getChipPropsByState(state),
        },
      };
    },
    type: BigidGridColumnTypes.CHIP,
    width: 225,
  },
  {
    name: 'progress',
    title: 'Success Rate',
    width: 400,
    getCellValue: row => {
      const { state, progress, type, isFailedObjects, enumerationStatus, isRbacPermitted, isRetryAvailable } = row;
      const { complete, failed, total } = progress;
      const hasFailedWhenCompleted = state === CompletedParentScanState.COMPLETED && failed > 0;
      const stateByFailures = hasFailedWhenCompleted ? CompletedParentScanState.COMPLETED_WITH_FAILURES : state;

      return {
        progressBar: {
          percentage: calculateProgressBarPercentage({ complete, failed, state, total, type, isSubScan: false }),
          status: MapScanStatusToProgressStatus[stateByFailures],
          width: '200px',
          size: 'large',
          color: calculateRightFilterColor(stateByFailures),
          rightFilter: calculateParentScanRightFilter({
            complete,
            failed,
            total,
            state,
            isFailedObjects,
            enumerationStatus,
            scan: row,
            isShouldDisplayRetryButton: isRbacPermitted && isRetryAvailable,
          }),
        },
      };
    },
    type: BigidGridColumnTypes.PROGRESS_BAR,
  },
  {
    name: 'runningTime',
    title: 'Running Time',
    getCellValue: row => row.runningTime,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
    width: 150,
  },
  {
    name: 'id',
    title: 'Scan Id',
    getCellValue: row => row.id,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    width: 300,
  },
  {
    name: 'owners',
    title: 'Scan Owners',
    getCellValue: ({ owners }) => {
      if (!owners?.length) {
        return;
      }
      return {
        chips: getScanOwnerChips(owners),
      };
    },
    type: BigidGridColumnTypes.CHIPS,
    sortingEnabled: false,
    isHiddenByDefault: true,
    width: 200,
  },
  {
    name: 'completedAt',
    title: 'Completed At',
    getCellValue: row => row.completedAt,
    type: BigidGridColumnTypes.DATE,
    sortingEnabled: true,
    isHiddenByDefault: true,
    width: 200,
  },
  {
    name: 'origin',
    title: 'Origin',
    getCellValue: row => row.origin,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    width: 300,
  },
  {
    name: 'executedBy',
    title: 'Executed By',
    getCellValue: row => row.executedBy,
    type: BigidGridColumnTypes.TEXT,
    isHiddenByDefault: true,
    width: 300,
  },
  ...getScanTemplateRowIfEnabled(),
];
