import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidBody1,
  BigidButtonIcon,
  BigidEditableChipsArea,
  BigidHeading3,
  BigidHeading5,
  BigidLoader,
  BigidMenu,
  BigidSeverityBadge,
  BigidSidePanel,
  BigidStatusButton,
  BigidStatusButtonItem,
  BigidStatusButtonItemType,
  TertiaryButton,
} from '@bigid-ui/components';
import { v4 as uuid } from 'uuid';
import { RISK_REGISTER_PERMISSIONS } from '@bigid/permissions';
import {
  CaseStatusLabel,
  getRiskStatusBadge,
  // isUrl,
  PrivacyRiskCaseResponse,
  SourceType,
  UserModel,
  updateCaseData,
  getRiskMatrix,
} from './RiskRegisterService';
import { httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';
import {
  // BigidCreateTicketIcon,
  BigidMoreActionIcon,
  BigidNoDataIllustration,
  // BigidOpenTicketIcon,
  BigidUserIcon,
  DataSourceIconByDsType,
} from '@bigid-ui/icons';
import { dateUtils } from '../../services/angularServices';
import { RiskSidePanelActivityLog } from './RiskSidePanelActivityLog';
import { AssignUserDialog } from './AssignUserDialog';
import { SystemUser } from '../AccessManagement/types';
import { ChangeStatusDialog } from './ChangeStatusDialog';
import { EditRiskCaseDialog, EditRiskCasePayloadData } from './EditRiskCaseDialog';
import {
  AccordionContent,
  AccordionHeader,
  ActivityLogContainer,
  AnsweredByTitle,
  AnsweredByWrapper,
  CaseSidePanelContainer,
  ContentDetailsSection,
  ContentTopSection,
  Description,
  DescriptionContentWrapper,
  DetailItem,
  DetailsItems,
  FlaggedByUser,
  FlaggedByWrapper,
  HeaderBottomInfoWrapper,
  InstanceQuestionSection,
  JiraTicketAssigneeAndStatusButtonWrapper,
  LegalEntitiesAndVendors,
  LegalEntitiesAndVendorsContainer,
  Link,
  MoreActionsButton,
  NoAnswerContainer,
  QuestionAnswer,
  RiskDescriptionWrapper,
  RiskNote,
  RiskStatusAndCreatedAtWrapper,
  ShowMoreText,
  SidePanelContentContainer,
  SidePanelHeader,
  TitleWrapper,
  TopSectionWrapper,
  TriggeredInInstanceWrapper,
} from './RiskSidePanel.styles';
import { getFixedT, useLocalTranslation } from './translations';
import { CaseStatus } from '../ActionableInsights/actionableInsightsService';
import { isPermitted } from '../../services/userPermissionsService';
import { CreateRiskTicketDialog } from './CreateRiskTicketDialog';
import { RiskMatrixMetadata } from '../Risks/RiskMatrixDefaults';

export interface RiskSidePanelProps {
  isOpen: boolean;
  onClose: () => void;
  caseId: string;
  onStatusChanged: () => void;
}

export const RiskSidePanel: FC<RiskSidePanelProps> = ({ isOpen, onClose, caseId, onStatusChanged }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSidePanelActive, setIsSidePanelActive] = useState(true);
  const [systemUsers, setSystemUsers] = useState<SystemUser[]>([]);
  const [riskMatrixMetadata, setRiskMatrixMetadata] = useState<RiskMatrixMetadata | undefined>();
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [shouldOpenMoreActionsMenu, setShouldOpenMoreActionsMenu] = useState(false);
  const [shouldOpenAssignUserDialog, setShouldOpenAssignUserDialog] = useState(false);
  const [shouldOpenStatusChangeDialog, setShouldOpenStatusChangeDialog] = useState(false);
  const [shouldOpenCreateTicketDialog, setShouldOpenCreateTicketDialog] = useState(false);
  const [newStatus, setNewStatus] = useState<CaseStatusLabel | undefined>();
  const [shouldEditRiskCaseDialog, setShouldOpenEditRiskCaseDialog] = useState(false);
  const [riskCaseData, setRiskCaseData] = useState<PrivacyRiskCaseResponse | undefined>();
  const anchorEl = useRef(null);
  const showMoreElementRef = useRef();
  const [displayShowMoreButton, setDisplayShowMoreButton] = useState(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [activityLogKey, setActivityLogKey] = useState(uuid());
  const { t } = useLocalTranslation('SidePanel');
  const notificationMessages = getFixedT('Notifications');

  const fetchRiskCaseData = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await httpService.fetch<{ data: PrivacyRiskCaseResponse }>(`privacy/risk-cases/${caseId}`);
      setRiskCaseData(data);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      notificationService.error(t('Errors.fetchDataError'));
    }
  };

  const fetchUsersList = async () => {
    try {
      const usersResponse = (await httpService.fetch('access-management/users'))?.data?.data?.users;
      setSystemUsers(usersResponse);
    } catch (err: any) {
      notificationService.error(t('Errors.fetchUsersError'));
    }
  };

  const fetchMatrixData = async () => {
    try {
      const matrix = await getRiskMatrix();
      setRiskMatrixMetadata(matrix);
    } catch (err: any) {
      notificationService.error(t('Errors.fetchUsersError'));
    }
  };

  useEffect(() => {
    fetchRiskCaseData();
    fetchUsersList();
    fetchMatrixData();
  }, []);

  useEffect(() => {
    if (showMoreElementRef.current) {
      const { scrollHeight } = showMoreElementRef.current;
      if (scrollHeight > 64) {
        setDisplayShowMoreButton(true);
      }
    }
  }, [riskCaseData?.privacyRisk.description]);

  const showSidePanelTopSection = useMemo(
    () =>
      riskCaseData?.privacyRisk?.description ||
      !!riskCaseData?.vendors?.length ||
      !!riskCaseData?.legalEntities?.length,
    [riskCaseData?.privacyRisk?.description, riskCaseData?.vendors?.length, riskCaseData?.legalEntities?.length],
  );

  const FieldDescription: FC<{ description: string | undefined }> = ({ description }) => {
    const DescriptionWrapperStyles = {
      fontWeight: 400,
      width: '410px',
      textAlign: 'start',
      fontSize: '14px',
      color: 'var(--text-icons-foreground-tertiary, #949494)',
      '& > p:firstChild': {
        margin: '0',
      },
    };
    if (!description?.startsWith('<p>')) {
      description = `<p style="margin: 0;">${description}</p>`;
    } else {
      description = description.replace('<p>', '<p style="margin: 0;">');
    }

    const descriptionWrapper = React.createElement('div', {
      dangerouslySetInnerHTML: { __html: description ?? '' },
      key: description,
      style: DescriptionWrapperStyles,
    });

    return descriptionWrapper;
  };

  const onAssignUserDialogCloseHandler = () => {
    setShouldOpenAssignUserDialog(false);
  };

  const updateAssignedUserClickHandler = async (user: UserModel) => {
    try {
      await updateCaseData({ caseIds: [caseId] }, [{ field: 'assignee', newValue: user.email }]);
      notificationService.success(notificationMessages('assignUserSuccess', { userName: user.name }));
      setShouldOpenAssignUserDialog(false);
      setActivityLogKey(uuid());
      setRiskCaseData({
        ...riskCaseData,
        assignee: { email: user.email, name: user.name },
      });
    } catch (err: any) {
      console.error(err);
      notificationService.error(notificationMessages('assignUserError', { userName: user.name }));
    }
  };

  const onStatusChangeCloseHandler = () => {
    setShouldOpenStatusChangeDialog(false);
  };

  const getNewStatusCode = () => {
    switch (newStatus) {
      case CaseStatusLabel.ACKNOWLEDGED:
        return 202;
      case CaseStatusLabel.SILENCED:
        return 201;
      case CaseStatusLabel.REMEDIATED:
        return 301;
      default:
        return 101;
    }
  };

  const onChangeStatusSubmitClickHandler = async (reason?: string) => {
    const caseStatus = (newStatus as unknown as CaseStatus) ?? CaseStatus.OPEN;
    try {
      await updateCaseData({ caseIds: [caseId] }, [{ field: 'caseStatus', newValue: caseStatus, auditReason: reason }]);
      onStatusChanged();
      notificationService.success(
        notificationMessages('caseStatusChangeSuccess', {
          caseStatus: caseStatus !== CaseStatus.OPEN ? caseStatus : 'opened',
        }),
      );
      setShouldOpenStatusChangeDialog(false);
      setActivityLogKey(uuid());
      setRiskCaseData({
        ...riskCaseData,
        caseStatus: { id: getNewStatusCode().toString(), name: newStatus ?? 'open' },
      });
      setNewStatus(undefined);
    } catch (err: any) {
      console.error(err);
      notificationService.error(notificationMessages('statusUpdateChangeError', { caseStatus }));
    }
  };

  // const onCreateTicketButtonClick = () => {
  //   if (isUrl(riskCaseData?.ticketUrl)) {
  //     window.open(riskCaseData.ticketUrl, '_blank');
  //   } else {
  //     setShouldOpenCreateTicketDialog(true);
  //   }
  // };

  const getSidePanelHeader = useCallback(() => {
    return (
      <SidePanelHeader>
        <BigidBody1 size="medium" fontWeight={600}>
          {riskCaseData?.caseId}
        </BigidBody1>
        <BigidHeading3>{riskCaseData?.caseLabel}</BigidHeading3>
        <HeaderBottomInfoWrapper>
          <RiskStatusAndCreatedAtWrapper>
            {riskCaseData?.riskLevel && (
              <BigidSeverityBadge
                customLabel={riskCaseData?.riskLevel?.name}
                customColor={riskCaseData?.riskLevel?.color ?? '#FFFFFF'}
                level={'custom'}
                hasBackground={true}
                size="medium"
              />
            )}
            <BigidBody1 size="medium" fontWeight={400}>
              {dateUtils.formatDate(riskCaseData?.createdAt, { formatString: 'MMM do, yyyy, HH:mm' })}
            </BigidBody1>
          </RiskStatusAndCreatedAtWrapper>
          <JiraTicketAssigneeAndStatusButtonWrapper>
            {/* {![203, 301].includes(parseInt(riskCaseData?.caseStatus?.id)) && isPermitted(RISK_REGISTER_PERMISSIONS.EDIT.name) && (
              <TertiaryButton
                startIcon={
                  riskCaseData?.ticketMetadata?.ticketType === 'Jira' ? (
                    <BigidOpenTicketIcon />
                  ) : riskCaseData?.ticketMetadata?.ticketType === 'ServiceNow' ? (
                    <BigidOpenTicketIcon />
                  ) : (
                    <BigidCreateTicketIcon />
                  )
                }
                size={'medium'}
                onClick={onCreateTicketButtonClick}
                text={riskCaseData?.ticketMetadata?.key || t('Labels.createTicket')}
              />
            )} */}
            {![203, 301].includes(parseInt(riskCaseData?.caseStatus?.id)) &&
              isPermitted(RISK_REGISTER_PERMISSIONS.EDIT.name) && (
                <TertiaryButton
                  startIcon={<BigidUserIcon />}
                  size={'medium'}
                  onClick={() => setShouldOpenAssignUserDialog(true)}
                  text={riskCaseData?.assignee?.name}
                />
              )}
            {![203, 301].includes(parseInt(riskCaseData?.caseStatus?.id)) &&
              isPermitted(RISK_REGISTER_PERMISSIONS.EDIT.name) && (
                <MoreActionsButton ref={anchorEl}>
                  <BigidButtonIcon
                    onClick={() => setShouldOpenMoreActionsMenu(true)}
                    size="medium"
                    icon={BigidMoreActionIcon}
                  />
                </MoreActionsButton>
              )}
            {![203, 301].includes(parseInt(riskCaseData?.caseStatus?.id)) &&
              isPermitted(RISK_REGISTER_PERMISSIONS.EDIT.name) && (
                <BigidMenu
                  open={shouldOpenMoreActionsMenu}
                  items={[
                    {
                      label: t('Labels.editCase'),
                      onClick: () => {
                        setShouldOpenEditRiskCaseDialog(true);
                        setIsSidePanelActive(false);
                      },
                    },
                  ]}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: -100,
                  }}
                  anchorEl={anchorEl.current}
                  onMenuClose={() => {
                    setShouldOpenMoreActionsMenu(false);
                  }}
                />
              )}
            {![203, 301].includes(parseInt(riskCaseData?.caseStatus?.id)) &&
              isPermitted(RISK_REGISTER_PERMISSIONS.EDIT.name) && (
                <BigidStatusButton
                  statusItems={[
                    ...(![203, 301].includes(parseInt(riskCaseData?.caseStatus?.id))
                      ? [
                          {
                            label: t('Statuses.open'),
                            type: 'info' as BigidStatusButtonItemType,
                            isSelected: parseInt(riskCaseData?.caseStatus?.id) === 101,
                          },
                        ]
                      : []),
                    ...(parseInt(riskCaseData?.caseStatus?.id) === 101 || parseInt(riskCaseData?.caseStatus?.id) === 202
                      ? [
                          {
                            label: t('Statuses.acknowledged'),
                            type: 'pending' as BigidStatusButtonItemType,
                            isSelected: parseInt(riskCaseData?.caseStatus?.id) === 202,
                          },
                        ]
                      : []),
                    ...(parseInt(riskCaseData?.caseStatus?.id) === 101 || parseInt(riskCaseData?.caseStatus?.id) === 201
                      ? [
                          {
                            label: t('Statuses.silenced'),
                            type: 'pending' as BigidStatusButtonItemType,
                            isSelected: parseInt(riskCaseData?.caseStatus?.id) === 201,
                          },
                        ]
                      : []),
                    ...(parseInt(riskCaseData?.caseStatus?.id) === 101
                      ? [
                          {
                            label: t('Statuses.remediated'),
                            type: 'success' as BigidStatusButtonItemType,
                            isSelected: parseInt(riskCaseData?.caseStatus?.id) === 301,
                          },
                        ]
                      : []),
                  ]}
                  onStatusChange={async (updatedItems: BigidStatusButtonItem[]) => {
                    const selectedStatus = updatedItems.find(option => option.isSelected);
                    const selectedStatusLabel = selectedStatus.label.toLocaleLowerCase() as CaseStatusLabel;
                    if (selectedStatusLabel !== CaseStatusLabel.OPEN) {
                      setNewStatus(selectedStatus.label.toLocaleLowerCase() as CaseStatusLabel);
                      setShouldOpenStatusChangeDialog(true);
                    } else {
                      await onChangeStatusSubmitClickHandler();
                      onStatusChanged();
                    }
                  }}
                />
              )}
            {([203, 301].includes(parseInt(riskCaseData?.caseStatus?.id)) ||
              !isPermitted(RISK_REGISTER_PERMISSIONS.EDIT.name)) &&
              getRiskStatusBadge(riskCaseData?.caseStatus)}
          </JiraTicketAssigneeAndStatusButtonWrapper>
        </HeaderBottomInfoWrapper>
      </SidePanelHeader>
    );
  }, [
    riskCaseData?.caseId,
    riskCaseData?.caseLabel,
    riskCaseData?.riskLevel,
    riskCaseData?.createdAt,
    riskCaseData?.caseStatus,
    shouldOpenMoreActionsMenu,
    shouldEditRiskCaseDialog,
    onChangeStatusSubmitClickHandler,
    newStatus,
  ]);

  const getVendorsAndLegalEntities = (type: keyof PrivacyRiskCaseResponse) => {
    if (Array.isArray(riskCaseData?.[type])) {
      return (
        <BigidEditableChipsArea
          value={(riskCaseData?.[type] as any[])?.map(item => {
            return {
              id: item?.id,
              label: item.name,
              icon: type === 'vendors' ? <DataSourceIconByDsType dataSourceType={item.type} width={24} /> : undefined,
            };
          })}
          isDisabled
        />
      );
    }
  };

  const onEditRiskCaseDialogSaveButtonClick = async (payload: EditRiskCasePayloadData) => {
    try {
      await updateCaseData({ caseIds: [caseId] }, [
        {
          field: 'riskLevel',
          newValue: {
            impact: payload.newImpact,
            matrixSize: payload.matrixSize,
            probability: payload.newProbability,
            riskLevel: payload.newImpact * payload.newProbability,
          },
          auditReason: payload.reason,
        },
        {
          field: 'asset',
          newValue: payload.asset?.id,
          auditReason: payload.reason,
        },
      ]);
      notificationService.success(notificationMessages('editCaseDataSuccess'));
      setShouldOpenEditRiskCaseDialog(false);
      setIsSidePanelActive(true);
      setActivityLogKey(uuid());
      fetchRiskCaseData();
    } catch (err: any) {
      notificationService.error(t('Errors.updateSRiskDataError'));
    }
  };

  return (
    <>
      <CaseSidePanelContainer isSidePanelActive={isSidePanelActive}>
        <BigidSidePanel
          isShowBackdrop={true}
          maxWidth="large"
          open={isOpen}
          title={''}
          customTitleComponent={getSidePanelHeader()}
          onClose={onClose}
          content={
            isLoading ? (
              <BigidLoader />
            ) : (
              <SidePanelContentContainer>
                <ContentTopSection>
                  {showSidePanelTopSection && (
                    <TopSectionWrapper>
                      {riskCaseData?.privacyRisk?.description && (
                        <RiskDescriptionWrapper>
                          <TitleWrapper>{t('Labels.riskDescription')}</TitleWrapper>
                          <DescriptionContentWrapper>
                            <Description ref={showMoreElementRef} shouldShowMore={displayShowMoreButton && !showMore}>
                              {riskCaseData?.privacyRisk?.description}
                            </Description>
                            {displayShowMoreButton && (
                              <ShowMoreText
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                  event.preventDefault();
                                  setShowMore(!showMore);
                                }}
                              >
                                {showMore ? t('Labels.showLess') : t('Labels.showMore')}
                              </ShowMoreText>
                            )}
                          </DescriptionContentWrapper>
                        </RiskDescriptionWrapper>
                      )}
                      {(!!riskCaseData?.vendors?.length || !!riskCaseData?.legalEntities?.length) && (
                        <LegalEntitiesAndVendorsContainer>
                          {!!riskCaseData?.legalEntities?.length && (
                            <LegalEntitiesAndVendors>
                              <TitleWrapper>{t('Labels.legalEntities')}</TitleWrapper>
                              {getVendorsAndLegalEntities('legalEntities')}
                            </LegalEntitiesAndVendors>
                          )}
                          {!!riskCaseData?.vendors?.length && (
                            <LegalEntitiesAndVendors>
                              <TitleWrapper>{t('Labels.vendors')}</TitleWrapper>
                              {getVendorsAndLegalEntities('vendors')}
                            </LegalEntitiesAndVendors>
                          )}
                        </LegalEntitiesAndVendorsContainer>
                      )}
                    </TopSectionWrapper>
                  )}
                  <TriggeredInInstanceWrapper>
                    <BigidHeading5>{`${t('Labels.triggeredIn')} ${
                      riskCaseData?.sourceType === SourceType.PIA ? t('Labels.assessment') : t('Labels.process')
                    }`}</BigidHeading5>
                    <BigidAccordion hasShadow={false}>
                      <BigidAccordionSummary
                        onClick={() => setIsAccordionExpanded(!isAccordionExpanded)}
                        style={
                          isAccordionExpanded
                            ? { borderBottom: '1px solid #E6E6E6', borderRadius: 'unset' }
                            : { borderBottom: '1px solid transparent' }
                        }
                        size={AccordionSummarySizeEnum.large}
                      >
                        <AccordionHeader>
                          <BigidBody1>
                            {parseInt(riskCaseData?.caseStatus?.id) !== 203 ? (
                              <Link
                                data-aid="instance-link"
                                onClick={() => {
                                  window.open(
                                    `${window.location.origin}/#/customApp/${riskCaseData?.sourceType}/workflow/${riskCaseData?.source?.id}`,
                                    '_blank',
                                  );
                                }}
                              >
                                {`"${riskCaseData?.source?.name}"`}
                              </Link>
                            ) : (
                              `"${riskCaseData?.source?.name}"`
                            )}
                          </BigidBody1>
                          <FlaggedByWrapper>
                            <TitleWrapper>{t('Labels.flaggedBy')}</TitleWrapper>
                            <FlaggedByUser>
                              <BigidBody1>{riskCaseData?.createdBy?.name}</BigidBody1>
                            </FlaggedByUser>
                          </FlaggedByWrapper>
                        </AccordionHeader>
                      </BigidAccordionSummary>
                      <BigidAccordionDetails>
                        <AccordionContent>
                          <InstanceQuestionSection>
                            <div>
                              <BigidBody1> {riskCaseData?.questionResponse?.question} </BigidBody1>
                              {riskCaseData?.questionResponse?.description && (
                                <FieldDescription description={riskCaseData?.questionResponse?.description} />
                              )}
                            </div>
                            {
                              <QuestionAnswer>
                                {riskCaseData?.questionResponse?.answer && (
                                  <BigidBody1
                                    alignSelf={'flex-start'}
                                  >{`"${riskCaseData?.questionResponse?.answer}"`}</BigidBody1>
                                )}
                                {!riskCaseData?.questionResponse?.answer && (
                                  <NoAnswerContainer>
                                    <BigidNoDataIllustration style={{ width: '80px', height: '80px' }} />
                                    <BigidBody1>{'No answer provided'}</BigidBody1>
                                  </NoAnswerContainer>
                                )}
                                <AnsweredByWrapper>
                                  {riskCaseData?.questionResponse?.answer && (
                                    <AnsweredByTitle>{t('Labels.answeredBy')}</AnsweredByTitle>
                                  )}
                                  {!riskCaseData?.questionResponse?.answer && (
                                    <AnsweredByTitle>{t('Labels.markedAsDoneBy')}</AnsweredByTitle>
                                  )}
                                  <BigidBody1 size="small">
                                    {riskCaseData?.questionResponse?.answeredBy?.name}
                                  </BigidBody1>
                                </AnsweredByWrapper>
                              </QuestionAnswer>
                            }
                          </InstanceQuestionSection>
                          {riskCaseData?.note && (
                            <RiskNote>
                              <TitleWrapper>{t('Labels.riskNote')}</TitleWrapper>
                              <BigidBody1>{riskCaseData?.note}</BigidBody1>
                            </RiskNote>
                          )}
                        </AccordionContent>
                      </BigidAccordionDetails>
                    </BigidAccordion>
                  </TriggeredInInstanceWrapper>
                </ContentTopSection>
                <ContentDetailsSection>
                  <BigidHeading5>{t('Labels.details')}</BigidHeading5>
                  <DetailsItems>
                    {riskCaseData?.probability?.name && (
                      <DetailItem>
                        <TitleWrapper>{riskMatrixMetadata?.probabilityLabel}</TitleWrapper>
                        <BigidBody1>{riskCaseData?.probability?.name}</BigidBody1>
                      </DetailItem>
                    )}
                    {riskCaseData?.impact?.name && (
                      <DetailItem>
                        <TitleWrapper>{riskMatrixMetadata?.impactLabel}</TitleWrapper>
                        <BigidBody1>{riskCaseData?.impact?.name}</BigidBody1>
                      </DetailItem>
                    )}
                    {riskCaseData?.riskLevel && (
                      <DetailItem>
                        <TitleWrapper>{t('Labels.severity')}</TitleWrapper>
                        <BigidSeverityBadge
                          customLabel={riskCaseData?.riskLevel?.name}
                          customColor={riskCaseData?.riskLevel?.color ?? '#FFFFFF'}
                          level={'custom'}
                          hasBackground={false}
                          size="medium"
                        />
                      </DetailItem>
                    )}
                    {riskCaseData?.asset?.name && (
                      <DetailItem>
                        <TitleWrapper>{t('Labels.asset')}</TitleWrapper>
                        <BigidBody1>{riskCaseData?.asset?.name}</BigidBody1>
                      </DetailItem>
                    )}
                    {riskCaseData?.privacyRisk?.category && (
                      <DetailItem>
                        <TitleWrapper>{t('Labels.riskCategory')}</TitleWrapper>
                        <BigidBody1>{riskCaseData?.privacyRisk?.category}</BigidBody1>
                      </DetailItem>
                    )}
                    {!!riskCaseData?.controls?.length && (
                      <DetailItem>
                        <TitleWrapper>{t('Labels.controls')}</TitleWrapper>
                        <BigidEditableChipsArea
                          value={riskCaseData?.controls?.map(control => {
                            return { id: control?.id, label: control.name };
                          })}
                          isDisabled
                        />
                      </DetailItem>
                    )}
                  </DetailsItems>
                </ContentDetailsSection>
                <ActivityLogContainer key={activityLogKey}>
                  <BigidHeading5>{t('Labels.activityLog')}</BigidHeading5>
                  <RiskSidePanelActivityLog caseId={caseId} />
                </ActivityLogContainer>
              </SidePanelContentContainer>
            )
          }
        />

        {shouldOpenAssignUserDialog && (
          <AssignUserDialog
            isOpen={shouldOpenAssignUserDialog}
            assignedUser={riskCaseData?.assignee}
            onDialogClose={onAssignUserDialogCloseHandler}
            onAssignButtonClickHandler={updateAssignedUserClickHandler}
            users={systemUsers}
          />
        )}
        {shouldOpenStatusChangeDialog && (
          <ChangeStatusDialog
            isOpen={shouldOpenStatusChangeDialog}
            onDialogClose={onStatusChangeCloseHandler}
            onSubmitButtonClickHandler={onChangeStatusSubmitClickHandler}
            caseNewStatus={newStatus}
          />
        )}
      </CaseSidePanelContainer>
      {shouldEditRiskCaseDialog && (
        <EditRiskCaseDialog
          isOpen={shouldEditRiskCaseDialog}
          onClose={() => {
            setShouldOpenEditRiskCaseDialog(false);
            setIsSidePanelActive(true);
          }}
          editRiskCaseMetadata={{
            riskLevel: riskCaseData?.riskLevel,
            probability: riskCaseData?.probability,
            impact: riskCaseData?.impact,
            asset: riskCaseData?.asset,
          }}
          onSaveButtonClick={onEditRiskCaseDialogSaveButtonClick}
        />
      )}
      {shouldOpenCreateTicketDialog && (
        <CreateRiskTicketDialog riskCaseData={riskCaseData} onClose={() => setShouldOpenCreateTicketDialog(false)} />
      )}
    </>
  );
};
