import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { BigidLoader } from '@bigid-ui/components';
import { DisocveryDashboardContext } from '../../DiscoveryDashboardContext';
import { DataCoverageAreaChart } from './DataCoverageAreaChart';
import { DataCoverageProgressChart } from './DataCoverageProgressChart';

const ChartsContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 264px;
`;

const ProgressChartWrapper = styled('div')`
  padding: 0 24px;
  margin-right: 80px;
  width: 275px;
  z-index: 1;
`;

const AreaChartWrapper = styled('div')`
  flex-grow: 1;
  max-width: 1300px;
`;

export const DataCoverageCharts = () => {
  // TODO: Check passing filters in context. Update filters only with REAL change.
  const { activeFilters } = useContext(DisocveryDashboardContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //Demonstarte a filter change and fetching data
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 200);
  }, [activeFilters]);

  return (
    <ChartsContainer>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <ProgressChartWrapper>
            <DataCoverageProgressChart />
          </ProgressChartWrapper>
          <AreaChartWrapper>
            <DataCoverageAreaChart />
          </AreaChartWrapper>
        </>
      )}
    </ChartsContainer>
  );
};
